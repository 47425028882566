import SelectFieldBoxes from '../../../../../components/form-components/SelectFieldBoxes'
import {StepProps} from './_models'

const EntityGroupStep = ({data, updateData, hasError}: StepProps) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxes
          name='Data Type'
          optionNames={['Asset Statuses', 'Sensor Statuses', 'Gateway Statuses']}
          optionValues={['asset', 'sensor', 'gateway']}
          data={data}
          updateData={updateData}
          dataField='entity_group'
          required
          hasError={hasError}
          lastField
        />
      </div>
    </div>
  )
}

export default EntityGroupStep
