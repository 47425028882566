import React from 'react'
import {CopyClipboardText} from '../../components/content/CopyClipboardText'
import EntityInlineTextFieldForm from '../../components/form-components/EntityInlineTextFieldForm'
import {ProductionLine} from '../../core/_models'
import {deleteProductionLine, useUpdateProductionLineMutation} from '../../core/requests/factory'
import {NameImageCell} from '../../pages/overview/core/columns/ImageStringCell'
import useEntityDeleteModal from '../entity-delete/useEntityDeleteModal'
import OverviewDetailsModal, {BaseOverviewDetailsModalProps} from './OverviewDetailsModal'

const ProductionLineOverviewDetailsModal = ({
  entity,
  onHide,
  show = true,
}: BaseOverviewDetailsModalProps<ProductionLine>) => {
  const deleteModal = useEntityDeleteModal({
    entity,
    deleteHandler: deleteProductionLine,
    queryToInvalidate: 'productionLines',
  })
  return (
    <OverviewDetailsModal
      show={show}
      onHide={onHide}
      title={
        <>
          <span className={`text-nowrap`}>Production Line Details</span> ・
          <EntityInlineTextFieldForm
            data={entity}
            dataField={'name'}
            useMutation={useUpdateProductionLineMutation}
            queryToInvalidate={'productionLines'}
          />
          {deleteModal.handler}
        </>
      }
      objectId={entity._id}
      timelineId={entity._id}
      attributes={[
        ['ID', <CopyClipboardText text={entity._id} />],
        [
          'Gallery',
          <NameImageCell
            index={1}
            str={''}
            images={
              entity.gallery
                ? [entity.gallery.profile_image, ...(entity.gallery.images ?? [])]
                : undefined
            }
            entityId={entity._id}
            queryToInvalidate='productionLines'
          />,
        ],
        ['Organization Name', entity.organization.name],
        ['Factory Name', entity.factory.name],
        ['Area Name', entity.area.name],
        ['Name', entity.name],
        ['Manufacturing Type', entity.manufacturing_type],
        ['Autostop detection tolerance', entity.auto_stop_detection_tolerance],
      ]}
      displayProductionLineTree={true}
    />
  )
}

export {ProductionLineOverviewDetailsModal}
