import {Reference} from '../../../../core/_models'
import {useProductionLine} from '../../../../core/requests/factory'
import useModalState from '../../../../hooks/UseModalState'
import {ProductionLineOverviewDetailsModal} from '../../../../modals/overview/ProductionLineOverviewDetailsModal'

type ProductionLineCellProps = {
  productionLineReference: Reference
}

const ProductionLineCell = ({productionLineReference}: ProductionLineCellProps) => {
  const detailsModal = useModalState()

  const {data: productionLine} = useProductionLine(productionLineReference._id)

  return (
    <>
      <div className='d-flex align-items-center'>
        <a
          className='text-gray-800 text-hover-primary mb-1 cursor-pointer'
          onClick={detailsModal.open}
        >
          {productionLineReference.name}
        </a>
      </div>

      {productionLine && (
        <ProductionLineOverviewDetailsModal
          entity={productionLine}
          show={detailsModal.isOpen}
          onHide={detailsModal.close}
        />
      )}
    </>
  )
}

export default ProductionLineCell
