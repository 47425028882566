import * as am5 from '@amcharts/amcharts5'
import {AxisRendererCircular} from '@amcharts/amcharts5/.internal/charts/radar/AxisRendererCircular'
import {ClockHand} from '@amcharts/amcharts5/.internal/charts/radar/ClockHand'
import {RadarChart} from '@amcharts/amcharts5/.internal/charts/radar/RadarChart'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import {showNoDataDisplay} from '../../../../core/chart-util'

export function gaugeConfig(chartId: string, value?: number) {
  const root = am5.Root.new(chartId)

  if (value === undefined) return showNoDataDisplay(chartId, root)

  root.setThemes([am5themes_Animated.new(root)])

  let chart = root.container.children.push(
    RadarChart.new(root, {
      panX: false,
      panY: false,
      startAngle: 180,
      endAngle: 360,
    })
  )

  let axisRenderer = AxisRendererCircular.new(root, {
    innerRadius: -40,
  })
  axisRenderer.grid.template.setAll({
    stroke: root.interfaceColors.get('background'),
    visible: true,
    strokeOpacity: 0.8,
  })

  let xAxis = chart.xAxes.push(
    am5xy.ValueAxis.new(root, {
      maxDeviation: 0,
      min: 0,
      max: 100,
      strictMinMax: true,
      renderer: axisRenderer,
    })
  )

  let axisDataItem = xAxis.makeDataItem({})

  let clockHand = ClockHand.new(root, {
    pinRadius: am5.percent(20),
    radius: am5.percent(100),
    bottomWidth: 40,
  })

  let bullet = axisDataItem.set(
    'bullet',
    am5xy.AxisBullet.new(root, {
      sprite: clockHand,
    })
  )

  xAxis.createAxisRange(axisDataItem)

  let label = chart.radarContainer.children.push(
    am5.Label.new(root, {
      fill: am5.color(0xffffff),
      centerX: am5.percent(50),
      textAlign: 'center',
      centerY: am5.percent(50),
      fontSize: '1.5em',
    })
  )

  const roundedValue = value % 1 !== 0 ? parseFloat(value.toFixed(2)) : value
  const limitedValue = Math.min(Math.max(roundedValue, 0), 100)

  axisDataItem.set('value', limitedValue)
  bullet.get('sprite').on('rotation', function () {
    // let text = Math.round(axisDataItem.get('value') as number).toString()
    let fill = am5.color(0x000000)
    xAxis.axisRanges.each(function (axisRange) {
      if (
        limitedValue >= (axisRange.get('value') as number) &&
        limitedValue <= (axisRange.get('endValue') as number)
      ) {
        fill = axisRange.get('axisFill')?.get('fill') as am5.Color
      }
    })

    label.set('text', `${limitedValue}%`)

    clockHand.pin.animate({
      key: 'fill',
      to: fill,
      duration: 500,
      easing: am5.ease.out(am5.ease.cubic),
    })
    clockHand.hand.animate({
      key: 'fill',
      to: fill,
      duration: 500,
      easing: am5.ease.out(am5.ease.cubic),
    })
  })

  // setInterval(function () {
  //   axisDataItem.animate({
  //     key: 'value',
  //     to: Math.round(Math.random() * 140 - 40),
  //     duration: 500,
  //     easing: am5.ease.out(am5.ease.cubic),
  //   })
  // }, 2000)

  chart.bulletsContainer.set('mask', undefined)

  let bandsData = [
    {
      title: 'Low',
      color: '#ee1f25',
      lowScore: 0,
      highScore: 50,
    },
    {
      title: 'Acceptable',
      color: '#54b947',
      lowScore: 50,
      highScore: 80,
    },
    {
      title: 'Great',
      color: '#0f9747',
      lowScore: 80,
      highScore: 100,
    },
  ]

  am5.array.each(bandsData, function (data) {
    let axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}))

    axisRange.setAll({
      value: data.lowScore,
      endValue: data.highScore,
    })

    axisRange.get('axisFill')?.setAll({
      visible: true,
      fill: am5.color(data.color),
      fillOpacity: 0.8,
    })

    axisRange.get('label')?.setAll({
      text: data.title,
      inside: true,
      // radius: 15,
      fontSize: '0.9em',
      fill: root?.interfaceColors.get('background'),
    })

    chart.appear(1000, 100)
  })

  return root
}
