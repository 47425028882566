import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {ServerError} from '../../../core/_models'
import ModalErrorWrapper from '../../../components/ModalErrorWrapper'
import EnergyPiechartWidgetModal from './widget-forms/category/EnergyPiechartWidgetModal'
import LineWidgetModal from './widget-forms/line/LineWidgetModal'
import WorkOrdersWidgetModal from './widget-forms/work-orders/WorkOrdersWidgetModal'
import {WidgetType, getDisplayDescription, getDisplayName} from './widgets/_models'
import OeeWidgetModal from './widget-forms/oee/OeeWidgetModal'
import EnergyWidgetModal from './widget-forms/energy/EnergyWidgetModal'
import TextWidgetModal from './widget-forms/text/TextWidgetModal'
import ConditionWidgetModal from './widget-forms/condition/ConditionWidgetModal'
import PieWidgetModal from './widget-forms/pie/PieWidgetModal'
import PowerConsumptionWidgetModal from './widget-forms/energy/power-consumption/PowerConsumptionModal'
import TagWidgetModal from './widget-forms/tag/TagWidgetModal'

const modalsRoot = document.getElementById('root-modals') || document.body

const WIDGET_CATEGORIES: {
  name: string
  icon: string
  types: WidgetType[]
}[] = [
  {
    name: 'OEE',
    icon: 'fa-solid fa-chart-line',
    types: [
      'OEE Availability',
      'OEE Performance',
      'OEE Quality',
      'OEE',
      'Timeline',
      'Pareto',
      'Stop Registration',
      'Operational State',
      'Operational Stats',
      'Production Stats',
      'Event Logs',
    ],
  },
  {
    name: 'Energy',
    icon: 'fa-solid fa-plug',
    types: [
      // 'Power Factor',
      'Power Consumption',
      'Energy Consumption',
      'Standby Consumption',
      'Potential Savings',
      'CO2 Emissions',
      'Energy Piechart',
    ],
  },
  {
    name: 'Condition',
    icon: 'fa-solid fa-heart-pulse',
    types: ['Health', 'Anomaly'],
  },
  {
    name: 'Miscellaneous',
    icon: 'fa-solid fa-chart-line',
    types: ['Line', 'Pie', 'Text', 'Tag'],
  },
  {
    name: 'Maintenance',
    icon: 'fa-solid fa-clipboard-list',
    types: ['Work Orders'],
  },
]

interface Props {
  show: boolean
  open: () => void
  hide: () => void
  widgetType?: WidgetType
}

const AddWidgetModal: FC<Props> = ({show, open, hide, widgetType}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasServerError, setHasServerError] = useState(false)
  const [serverError, setServerError] = useState<ServerError | null>(null)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)
  const [selectedWidget, setSelectedWidget] = useState<WidgetType | undefined>(widgetType)

  function splitToPairs<T>(arr: T[]): T[][] {
    return arr.reduce((acc: T[][], curr: T, index: number) => {
      if (index % 2 === 0) {
        acc.push([curr])
      } else {
        acc[acc.length - 1].push(curr)
      }
      return acc
    }, [])
  }

  function hideWidgetCreation() {
    setHasServerError(false)
    setServerError(null)
    hide()
  }

  function hideIndividualWidget() {
    setSelectedWidget(undefined)
    open()
  }

  return (
    <>
      <Modal
        id='add_widget_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='xl'
        show={show}
        onHide={hideWidgetCreation}
        backdrop={isSubmitting ? 'static' : true}
        container={modalsRoot}
      >
        <ModalErrorWrapper
          hasError={hasServerError}
          errorData={serverError || undefined}
          hide={hide}
        >
          <>
            {/* Header */}
            <div className='modal-header'>
              <h2>Add Widget</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hide}>
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>

            {/* Body */}
            <div className='modal-body'>
              <div className='d-flex flex-column flex-lg-row '>
                {/* Sidebar Categories */}
                <div className='d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-200px me-5'>
                  <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary'>
                    {WIDGET_CATEGORIES.map((category, index) => (
                      <div className='menu-item mb-3' key={index}>
                        <span
                          className={`menu-link ${index === selectedCategoryIndex ? 'active' : ''}`}
                          onClick={() => setSelectedCategoryIndex(index)}
                        >
                          <span className='menu-icon'>
                            <i className={category.icon} />
                          </span>
                          <span className='menu-title fw-bold'>{category.name}</span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Widgets */}
                <div className='flex-lg-row-fluid'>
                  {splitToPairs(WIDGET_CATEGORIES[selectedCategoryIndex].types).map(
                    (widgetsPair: WidgetType[], index) => (
                      <div className='row mb-6' key={index}>
                        {widgetsPair.map((widget) => (
                          <div className='col-6' key={widget}>
                            <div className='card border h-100'>
                              <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                <a
                                  href='#'
                                  className='text-gray-800 d-flex flex-column text-hover-primary'
                                  onClick={() => {
                                    setSelectedWidget(widget)
                                    hideWidgetCreation()
                                  }}
                                >
                                  <KTSVG
                                    className='svg-icon-1 mb-5'
                                    path='/media/icons/duotune/graphs/gra001.svg'
                                  />
                                  <div className='fs-5 fw-bold mb-2'>{getDisplayName(widget)}</div>
                                </a>
                                <div className='fs-7 fw-semibold text-gray-400'>
                                  {getDisplayDescription(widget)}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </>
        </ModalErrorWrapper>
      </Modal>

      {selectedWidget && (
        <>
          <LineWidgetModal
            show={selectedWidget === 'Line'}
            hide={() => setSelectedWidget(undefined)}
            cancel={hideIndividualWidget}
          />
          <OeeWidgetModal
            show={
              selectedWidget === 'OEE Availability' ||
              selectedWidget === 'OEE Performance' ||
              selectedWidget === 'OEE Quality' ||
              selectedWidget === 'OEE' ||
              selectedWidget === 'Timeline' ||
              selectedWidget === 'Pareto' ||
              selectedWidget === 'Stop Registration' ||
              selectedWidget === 'Operational State' ||
              selectedWidget === 'Operational Stats' ||
              selectedWidget === 'Production Stats' ||
              selectedWidget === 'Event Logs'
            }
            hide={() => setSelectedWidget(undefined)}
            type={selectedWidget}
            cancel={hideIndividualWidget}
          />
          <EnergyWidgetModal
            show={
              selectedWidget === 'Power Factor' ||
              // selectedWidget === 'Power Consumption' ||
              selectedWidget === 'Energy Consumption' ||
              selectedWidget === 'Standby Consumption' ||
              selectedWidget === 'Potential Savings' ||
              selectedWidget === 'CO2 Emissions'
            }
            hide={() => setSelectedWidget(undefined)}
            type={selectedWidget}
            cancel={hideIndividualWidget}
          />
          <PowerConsumptionWidgetModal
            show={selectedWidget === 'Power Consumption'}
            hide={() => setSelectedWidget(undefined)}
            cancel={hideIndividualWidget}
          />
          <TextWidgetModal
            show={selectedWidget === 'Text'}
            hide={() => setSelectedWidget(undefined)}
            cancel={hideIndividualWidget}
          />
          <ConditionWidgetModal
            show={selectedWidget === 'Health' || selectedWidget === 'Anomaly'}
            hide={() => setSelectedWidget(undefined)}
            type={selectedWidget}
            cancel={hideIndividualWidget}
          />
          <PieWidgetModal
            show={selectedWidget === 'Pie'}
            hide={() => setSelectedWidget(undefined)}
            cancel={hideIndividualWidget}
          />
          <WorkOrdersWidgetModal
            show={selectedWidget === 'Work Orders'}
            hide={() => setSelectedWidget(undefined)}
          />
          <EnergyPiechartWidgetModal
            show={selectedWidget === 'Energy Piechart'}
            hide={() => setSelectedWidget(undefined)}
          />
          <TagWidgetModal
            show={selectedWidget === 'Tag'}
            hide={() => setSelectedWidget(undefined)}
          />
        </>
      )}
    </>
  )
}

export default AddWidgetModal
