import QuantityWidget from '../dashboard-grid/widgets/utility-widgets/QuantityWidget'
import {CardWrapper} from './CardWrapper'

type QuantityCardProps = {
  title?: string
  value?: string | number
  unit?: string
}

const QuantityCard = ({title, value, unit}: QuantityCardProps) => (
  <CardWrapper title={title}>
    <div className='my-3'>
      <QuantityWidget value={value} unit={unit} />
    </div>
  </CardWrapper>
)

export {QuantityCard}
