import {Widget as WidgetModel} from '../../../../core/_models'
import {usePlacement, useSection} from '../../../../core/requests/factory'
import {useMachineProductionStats} from '../../../../core/requests/oee'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'

interface Props {
  widget: WidgetModel
}

const useProductProcessedDivider = ({widget}: Props) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const {data: section} = useSection(widget.entity, {
    enabled: widget.entity_group === 'section',
  })

  const {data: placement} = usePlacement(widget.entity, {
    enabled: widget.entity_group === 'placement',
  })

  let productionLine
  switch (widget.entity_group) {
    case 'production_line':
      productionLine = widget.entity
      break
    case 'section':
      productionLine = section?.production_line?._id
      break
    case 'placement':
      productionLine = placement?.production_line?._id
      break
    default:
      break
  }

  const {data: stats} = useMachineProductionStats({
    id: productionLine as string,
    startTime: startTime,
    endTime: endTime,
    options: {
      enabled: widget.by_product === true && !!productionLine,
    },
  })

  let divider = 1
  if (widget.by_product && stats && stats.product_processed.length > 0) {
    divider = stats.product_processed[0][1]
  }

  return divider
}

export default useProductProcessedDivider
