interface OverviewTabProps {
  attributes: Array<[string | undefined, string | React.ReactNode | React.ReactNode[] | undefined]>
}

const OverviewTab = ({attributes}: OverviewTabProps) => (
  <>
    {attributes.map((attribute, i) => (
      <div key={`attribute-${i}`} className={'row gap-7'}>
        {attribute[0] !== undefined && (
          <div className={'col fw-semibold text-muted'}>{attribute[0]}</div>
        )}
        {attribute[1] !== undefined && (
          <div
            className={`col d-flex ${
              attribute[0] !== undefined ? 'justify-content-end' : 'flex-column'
            }`}
          >
            {typeof attribute[1] === 'string' ? <strong>{attribute[1]}</strong> : attribute[1]}
          </div>
        )}
        <div className='w-100 d-none d-md-block'></div>
      </div>
    ))}
  </>
)

export default OverviewTab
