import {useState} from 'react'
import {useCreateModals} from '../CreateModalsProvider'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {ICreatePlc, defaultCreatePlc} from './_models'
import {Plc} from '../../core/_models'
import {createPlc} from '../../core/requests/factory'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

const CreatePlcModal = () => {
  const [data, setData] = useState<ICreatePlc>(defaultCreatePlc)
  const {showCreatePlcModal, closeCreatePlcModal} = useCreateModals()

  const steps: Step<ICreatePlc>[] = [
    {
      title: 'Name',
      description: 'Name Your PLC',
      component: Step1,
      validation: (data) => !data.name,
    },
    {
      title: 'Location',
      description: 'Specify PLC Location',
      component: Step2,
      validation: (data) => !data.locationChoice,
    },
    {
      title: 'Specifics',
      description: 'Specify PLC Location',
      component: Step3,
      validation: (data) =>
        (data.locationChoice === 'area' && !data.area) ||
        (data.locationChoice === 'production_line' && !data.production_line) ||
        (data.locationChoice === 'section' && !data.section) ||
        (data.locationChoice === 'asset' && !data.asset),
    },
  ]

  return (
    <StepModalWrapper<ICreatePlc, Plc>
      id='hs_create_plc'
      objectName='Plc'
      show={showCreatePlcModal}
      close={closeCreatePlcModal}
      steps={steps}
      data={data}
      defaultData={defaultCreatePlc}
      setData={setData}
      submitData={createPlc}
    />
  )
}

export default CreatePlcModal
