import clsx from 'clsx'
import LoadingWrapper from '../../../../../../components/LoadingWrapper'
import {useEventCategories, useEventCategoryGroups} from '../../../../../../core/requests/oee'
import {StepProps} from '../_models'
import {EventCategory} from '../../../../../../core/_models'

const EventCategorySelectionStep = ({data, updateData, hasError}: StepProps) => {
  const {data: eventCategoryGroups, isLoading: isEventCategoryGroupsLoading} =
    useEventCategoryGroups({
      entities: data.entity ? [data.entity] : data.entities ? data.entities : [],
      options: {enabled: data.entity !== undefined || data.entities !== undefined},
    })
  const {data: activeCategoryGroups, isLoading: activeCategoryGroupsIsLoading} =
    useEventCategoryGroups({
      isActive: true,
      options: {enabled: eventCategoryGroups?.items.length === 0},
    })

  const activeGroup = eventCategoryGroups?.items.at(0) || activeCategoryGroups?.items.at(0)

  const {data: eventCategories, isLoading: isEventCategoriesLoading} = useEventCategories({
    eventCategoryGroups: activeGroup ? [activeGroup._id] : [],
    options: {enabled: activeGroup !== undefined},
  })

  const oeeWidgetTypes = ['OEE', 'OEE Performance', 'OEE Availability', 'OEE Quality']
  const isOeeWidget = oeeWidgetTypes.includes(data.type || '')
  const filterNonOperational = (category: EventCategory) => !category.operational
  const filteredCategories = eventCategories?.items.filter(
    isOeeWidget ? filterNonOperational : () => true
  )

  const loading =
    isEventCategoryGroupsLoading || activeCategoryGroupsIsLoading || isEventCategoriesLoading

  return (
    <div data-kt-stepper-element='content'>
      <LoadingWrapper loading={loading}>
        <div className='w-100'>
          <div className='mb-10'>
            <h2 className='fw-bold d-flex align-items-center text-dark'>
              Optional: Select Event Categories
            </h2>
            <div className='text-muted fw-semibold fs-6'>
              Select the categories that should be included. Only data from these categories will be
              considered. If no categories are selected, data from all categories will be
              automatically included.
            </div>
          </div>

          <div className='row mb-8'>
            {filteredCategories?.map((eventCategory, index) => (
              <div className={`${index !== 0 ? 'mt-3' : ''} col-6`} key={eventCategory._id}>
                <div
                  className={clsx(
                    'btn btn-outline btn-outline-dashed p-5 d-flex align-items-center h-100',
                    data.operational_event_categories?.includes(eventCategory._id) && 'active'
                  )}
                  onClick={() => {
                    const selectedCategories = [...(data.operational_event_categories || [])]
                    const index = selectedCategories.indexOf(eventCategory._id)
                    if (index === -1) selectedCategories.push(eventCategory._id)
                    else selectedCategories.splice(index, 1)
                    updateData({
                      operational_event_categories: selectedCategories.length
                        ? selectedCategories
                        : undefined,
                    })
                  }}
                >
                  <div
                    className='me-2 shadow'
                    style={{
                      backgroundColor: eventCategory.color,
                      width: '1em',
                      height: '1em',
                      borderRadius: '0.25em',
                    }}
                  />
                  <span className='text-dark fw-bold d-block me-1'>{eventCategory.title}</span>
                  <i className={eventCategory.icon} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default EventCategorySelectionStep
