import {Priority} from '../../../core/_models'
import Select, {OptionProps, SingleValue, SingleValueProps, components} from 'react-select'

type Props = {
  initial?: Priority
  name?: string
  onchange: (value?: Priority) => void
  className?: string
}

const PrioritySelectField = ({initial, name = 'Priority', onchange, className}: Props) => {
  const handleChange = (selected: SingleValue<OptionType> | null) => onchange(selected?.value)

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={options.find((option) => option.value === initial)}
        options={options}
        components={{Option: CustomOption, SingleValue: CustomSingleValue}}
        onChange={handleChange as any}
        isSearchable={false}
        isClearable
      />
    </>
  )
}

export interface OptionType {
  value: Priority
  label: string
  color: string
}

const options: OptionType[] = [
  {value: 'LOW', label: 'Low', color: '#D8D8D8'},
  {value: 'MEDIUM', label: 'Medium', color: '#6FDDFF'},
  {value: 'HIGH', label: 'High', color: '#FFCC00'},
  {value: 'URGENT', label: 'Urgent', color: '#F50000'},
]

const CustomOption: React.FC<OptionProps<OptionType>> = (props) => {
  return (
    <components.Option {...props}>
      <i className='fa-solid fa-flag fs-7 me-2' style={{color: props.data.color}} />
      {props.data.label}
    </components.Option>
  )
}

const CustomSingleValue: React.FC<SingleValueProps<OptionType>> = (props) => {
  return (
    <components.SingleValue {...props}>
      <i className='fa-solid fa-flag fs-7 me-2' style={{color: props.data.color}} />
      {props.data.label}
    </components.SingleValue>
  )
}

export default PrioritySelectField
