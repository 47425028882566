import Select from 'react-select'
import {Area} from '../../../../core/_models'
import {useAreasPaginated} from '../../../../core/requests/factory'

type AreaSelectProps = {
  onChange: (area?: Area) => void
}

const AreaSelect = ({onChange}: AreaSelectProps) => {
  const {
    items: areas,
    isFetching: areasFetching,
    fetchNextPage: areasNextPage,
  } = useAreasPaginated()
  return (
    <Select
      placeholder={`Area`}
      onChange={(e) => onChange(areas?.find((a) => a._id === e?.value))}
      options={
        areas?.map((a) => ({
          value: a._id,
          label: a.name,
        })) || []
      }
      isClearable
      className={'me-3'}
      isLoading={areasFetching}
      onMenuScrollToBottom={() => areasNextPage()}
    />
  )
}

export default AreaSelect
