import React from 'react'
import {Modal} from 'react-bootstrap'
import {useMutation, useQueryClient} from 'react-query'
import {KTSVG} from '../../../_metronic/helpers'
import LoadingOverlayWrapper from '../../components/LoadingOverlayWrapper'
import {BaseModel, Batch} from '../../core/_models'
import {deleteBatch, updateBatch, useBatch} from '../../core/requests/oee'
import useEntityDeleteModal from '../entity-delete/useEntityDeleteModal'
import EditBatchForm from './EditBatchForm'

interface EditBatchModalProps {
  id: string
  show: boolean
  handleClose: () => void
}

const EditBatchModal = ({id, show, handleClose}: EditBatchModalProps) => {
  const cache = useQueryClient()

  const {data: batch, isLoading: isBatchLoading} = useBatch({
    batch: id,
  })

  const {handler} = useEntityDeleteModal({
    entity: batch as Batch & BaseModel,
    deleteHandler: deleteBatch,
    queryToInvalidate: ['batches'],
    onSuccess: handleClose,
  })

  const updateMutation = useMutation({
    mutationFn: (data: Partial<Batch>) => updateBatch(id, data),
    onSuccess: async () => {
      await cache.invalidateQueries(['batches'])
      await cache.invalidateQueries(['batch', id])
      handleClose()
    },
  })

  const update = async (data: Partial<Batch>) => {
    await updateMutation.mutateAsync(data)
  }

  const isLoading = isBatchLoading || updateMutation.isLoading

  return (
    <Modal
      dialogClassName='modal-dialog modal-dialog-centered'
      size='lg'
      show={show}
      backdrop={true}
      onHide={handleClose}
    >
      <LoadingOverlayWrapper loading={isLoading}>
        <div className='modal-header'>
          <h2>
            Edit batch {batch?.name} {handler}
          </h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body'>
          {batch && (
            <EditBatchForm batch={batch} updateHandler={update} cancelHandler={handleClose} />
          )}
        </div>
      </LoadingOverlayWrapper>
    </Modal>
  )
}

export default EditBatchModal
