import {useEffect, useRef, useState} from 'react'
import {useOrganizationsPaginated, useProfile} from '../../../core/requests/auth'
import Select, {MultiValue} from 'react-select'

type Props = {
  initial?: string[]
  name?: string
  onchange: (values: string[]) => void
  errorOccurred?: boolean
  className?: string
}

const OrganizationsSelectField = ({
  initial = [],
  name = 'Organizations',
  onchange,
  errorOccurred,
  className,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([])
  const initialOptionsSet = useRef(false)

  const {data: profile} = useProfile()
  const {
    items: organizations,
    isFetching: organizationsFetching,
    fetchNextPage: organizationsNextPage,
  } = useOrganizationsPaginated(
    {},
    {
      enabled: !!profile?.is_staff,
    }
  )

  useEffect(() => {
    if (!organizations || !initial.length || initialOptionsSet.current) return
    const initialOptions = initial.map((organizationId) => {
      const organization = organizations.find((organization) => organization._id === organizationId)
      return {value: organizationId, label: organization?.name || 'Unknown organization'}
    })
    setSelectedOptions(initialOptions)
    initialOptionsSet.current = true
  }, [organizations, initial])

  const handleChange = (selected: MultiValue<OptionType>) => {
    const values = selected.map((option) => option.value)
    onchange(values)
    setSelectedOptions(selected)
  }

  const error = errorOccurred && !selectedOptions.length

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={selectedOptions}
        options={
          organizations?.map((organization) => ({
            label: organization.name,
            value: organization._id,
          })) || []
        }
        onChange={handleChange}
        isLoading={organizationsFetching}
        isDisabled={organizationsFetching}
        isMulti
        isClearable
        onMenuScrollToBottom={() => organizationsNextPage()}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? 'red' : baseStyles.borderColor,
          }),
        }}
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}
    </>
  )
}

type OptionType = {
  value: string
  label: string
}

export default OrganizationsSelectField
