import {Link} from 'react-router-dom'
import {Column} from 'react-table'
import {StringCell} from '../core/columns/StringCell'
import {SelectionCell} from '../core/columns/SelectionCell'
import {CustomHeader} from '../core/columns/CustomHeader'
import {SelectionHeader} from '../core/columns/SelectionHeader'
import {ProductionLine} from '../../../core/_models'
import {BadgeCell} from '../core/columns/BadgeCell'
import {CellClickContext} from '../core/columns/TableBody'
import {ProductionLineStartStopCell} from './ProductionLineStartStopCell'
import {ScheduleCell} from './ScheduleCell'
import {NameImageCell} from '../core/columns/ImageStringCell'
import FactoryCell from '../core/columns/FactoryCell'
import AreaCell from '../core/columns/AreaCell'

const Columns: ReadonlyArray<Column<ProductionLine>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} tableId='hs_production_lines_table' />,
    id: 'selection',
    Cell: ({...props}) => {
      const {_id} = props.data[props.row.index]
      return <SelectionCell id={_id} tableId='hs_production_lines_table' />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => {
      const {name, gallery, _id} = props.data[props.row.index]
      const images = gallery ? [gallery.profile_image, ...(gallery.images ?? [])] : undefined
      return (
        <CellClickContext.Consumer>
          {({onCellClick}) => (
            <NameImageCell
              str={name}
              images={images}
              index={props.row.index}
              entityId={_id}
              queryToInvalidate='productionLines'
              onClick={() =>
                onCellClick ? onCellClick(props.data[props.row.index]._id) : undefined
              }
            />
          )}
        </CellClickContext.Consumer>
      )
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Area' className='min-w-125px' />,
    id: 'area',
    Cell: ({...props}) => {
      const {area} = props.data[props.row.index]
      return <AreaCell areaReference={area} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Factory' className='min-w-125px' />,
    id: 'factory',
    Cell: ({...props}) => {
      const {factory} = props.data[props.row.index]
      return <FactoryCell factoryReference={factory} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Type' className='min-w-125px' />,
    id: 'status',
    Cell: ({...props}) => {
      const {manufacturing_type} = props.data[props.row.index]
      return <BadgeCell str={manufacturing_type || ''} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Log Event' />,
    id: 'log-event',
    Cell: ({...props}) => {
      const {_id} = props.data[props.row.index]
      return <ProductionLineStartStopCell productionLineId={_id} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Schedule' />,
    id: 'schedule',
    Cell: ({...props}) => {
      const productionLine = props.data[props.row.index]
      return <ScheduleCell productionLine={productionLine} />
    },
  },
  // {
  //   Header: (props) => <CustomHeader tableProps={props} className={`w-50px`} />,
  //   id: '_id',
  //   Cell: ({...props}) => {
  //     const {_id} = props.data[props.row.index]
  //     return (
  //       <Link to={`/production-lines/${_id}`} className={`btn btn-primary`}>
  //         <span className={`fa fa-chevron-right`} />
  //       </Link>
  //     )
  //   },
  // },
]

export {Columns}
