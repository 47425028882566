import {AxiosResponse} from 'axios'
import React from 'react'
import {toast} from 'react-toastify'

interface FileUploadButtonProps {
  uploadHandler: (file: any) => Promise<AxiosResponse<any, any>>
  text?: string
  className?: string
  contentType?: string
  onSuccess?: (response?: AxiosResponse<any, any>) => void
}

const FileUploadButton = ({
  text = 'Upload',
  className,
  contentType,
  uploadHandler,
  onSuccess,
}: FileUploadButtonProps) => {
  const [file, setFile] = React.useState<File | null>(null)
  const [uploading, setUploading] = React.useState(false)

  React.useEffect(() => {
    if (file) {
      setUploading(true)
      ;(async () => {
        try {
          const response = await uploadHandler(file)
          toast.success('File was uploaded successfully')
          if (onSuccess) {
            onSuccess(response)
          }
        } catch (e) {
          toast.error('There was an error during uploading')
        }
        setUploading(false)
        setFile(null)
      })()
    }
  }, [file])

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  return uploading ? (
    <div className={`badge badge-outline text-primary`}>uploading...</div>
  ) : (
    <>
      <label htmlFor={'file'} className={`btn btn-primary ${className}`}>
        {text}
      </label>
      <input
        id='file'
        type='file'
        className={`d-none`}
        onChange={handleFileChange}
        accept={contentType}
      />
    </>
  )
}

export default FileUploadButton
