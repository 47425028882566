import {IWidget, defaultCreateWidget, defaultLayout} from '../../widgets/_models'

export type ILineWidget = IWidget & {
  timeFrame?: 'dateRange' | 'interval'
}

export const defaultCreateLineWidget: ILineWidget = {
  ...defaultCreateWidget,
  type: 'Line',
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
  },
}

export type StepProps = {
  data: ILineWidget
  updateData: (fieldsToUpdate: Partial<ILineWidget>) => void
  hasError: boolean
}
