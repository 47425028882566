import {AutomationEvent} from '../../core/_models'
import Select from 'react-select'
import {useEvents} from '../../core/requests/automations'

type Props = {
  event?: string
  setEvent: (event?: string) => void
  errorOccurred?: boolean
}

const EventCard = ({event, setEvent, errorOccurred}: Props) => {
  const {data: events, isFetching: eventsFetching} = useEvents()

  const groupedEvents = events?.reduce((acc, event) => {
    const groupName = event[0].split('.')[0]
    ;(acc[groupName] ||= []).push(event)
    return acc
  }, {} as Record<string, AutomationEvent[]>)

  const eventOptions = Object.entries(groupedEvents || {}).map(([group, event]) => ({
    label: group,
    options: event.map((ev) => ({label: ev[1].title, value: ev[0]})),
  }))

  const initialEvent = eventOptions
    .flatMap((group) => group.options)
    .find((ev) => ev.value === event)

  const error = errorOccurred && !event

  return (
    <div className='card border border-secondary shadow-sm w-75'>
      <div className='card-body p-5'>
        <Select
          placeholder='Select event...'
          options={eventOptions}
          value={initialEvent}
          onChange={(e) => setEvent((e as any)?.value)}
          isLoading={eventsFetching}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderColor: error ? 'red' : baseStyles.borderColor,
            }),
          }}
        />
        {error && <div className='text-danger fs-8'>This field is required</div>}
      </div>
    </div>
  )
}

export default EventCard
