import {Reference} from '../../../../core/_models'
import {useAsset} from '../../../../core/requests/factory'
import useModalState from '../../../../hooks/UseModalState'
import {AssetOverviewDetailsModal} from '../../../../modals/overview/AssetOverviewDetailsModal'

type AssetCellProps = {
  assetReference: Reference
}

const AssetCell = ({assetReference}: AssetCellProps) => {
  const detailsModal = useModalState()

  const {data: asset} = useAsset({assetId: assetReference._id})

  return (
    <>
      <div className='d-flex align-items-center'>
        <a
          className='text-gray-800 text-hover-primary mb-1 cursor-pointer'
          onClick={detailsModal.open}
        >
          {assetReference.name}
        </a>
      </div>

      {asset && (
        <AssetOverviewDetailsModal
          entity={asset}
          show={detailsModal.isOpen}
          onHide={detailsModal.close}
        />
      )}
    </>
  )
}

export default AssetCell
