import {useEffect, useState} from 'react'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {BaseModel, Factory} from '../../core/_models'
import {useCreateModals} from '../CreateModalsProvider'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import {defaultCreateFactory, ICreateFactory} from './_models'
import {useProfile} from '../../core/requests/auth'
import {createFactory} from '../../core/requests/factory'

const CreateFactoryModal = () => {
  const [data, setData] = useState<ICreateFactory>(defaultCreateFactory)
  const {showCreateFactoryModal, closeCreateFactoryModal, openCreateAreaModal} = useCreateModals()

  const {data: profile} = useProfile()

  useEffect(() => {
    if (data.organization !== profile?.organization?._id) {
      setData((data) => ({...data, organization: profile?.organization?._id}))
    }
  }, [profile, data])

  const steps: Step<ICreateFactory>[] = [
    {
      title: 'Name',
      description: 'Name Your Factory',
      component: Step1,
      validation: (data) => data.name === undefined,
    },
    {
      title: 'Location',
      description: 'Country and Region',
      component: Step2,
    },
    {
      title: 'Currency',
      description: 'Currency Details',
      component: Step3,
      validation: (data) => data.useFixedPowerPrice && !data.fixedPowerPrice,
    },
  ]

  return (
    <StepModalWrapper<ICreateFactory, Factory & BaseModel>
      id='hs_create_factory'
      objectName='Factory'
      show={showCreateFactoryModal}
      close={closeCreateFactoryModal}
      steps={steps}
      data={data}
      defaultData={defaultCreateFactory}
      setData={setData}
      submitData={createFactory}
      openNextModals={[openCreateAreaModal]}
      nextObjectNames={['Area']}
      loading={data.organization === undefined}
    />
  )
}

export default CreateFactoryModal
