import {format} from 'date-fns'
import React from 'react'
import {CopyClipboardText} from '../../components/content/CopyClipboardText'
import {Gateway} from '../../core/_models'
import {deleteGateway} from '../../core/requests/factory'
import {ColorCell} from '../../pages/overview/core/columns/ColorCell'
import {NameImageCell} from '../../pages/overview/core/columns/ImageStringCell'
import useEntityDeleteModal from '../entity-delete/useEntityDeleteModal'
import OverviewDetailsModal, {BaseOverviewDetailsModalProps} from './OverviewDetailsModal'

const GatewayOverviewDetailsModal = ({
  entity,
  onHide,
  show = true,
}: BaseOverviewDetailsModalProps<Gateway>) => {
  const deleteModal = useEntityDeleteModal({
    entity: {
      _id: entity._id,
      name: entity.device_id,
    },
    deleteHandler: deleteGateway,
    queryToInvalidate: 'gateways',
  })

  return (
    <OverviewDetailsModal
      show={show}
      onHide={onHide}
      title={
        <>
          Gateway Details ・ {entity.device_id} {deleteModal.handler}
        </>
      }
      objectId={entity._id}
      attributes={[
        ['ID', <CopyClipboardText text={entity._id} />],
        [
          'Gallery',
          <NameImageCell
            index={1}
            str={''}
            images={
              entity.gallery
                ? [entity.gallery.profile_image, ...(entity.gallery.images ?? [])]
                : undefined
            }
            entityId={entity._id}
            queryToInvalidate='gateways'
          />,
        ],
        ['Organization Name', entity.organization.name],
        ['Factory Name', entity.factory.name],
        ['Area Name', entity.area.name],
        [
          'State',
          <div className={`container`}>
            <div className={`row`}>
              <div className='col'>
                <ColorCell color={`status-${entity.is_offline ? 'offline' : 'online'}`} />
              </div>
            </div>
            <div className={`row`}>
              <div className='col col-5 m-2'>First Record</div>
              <div className='col m-2 d-flex justify-content-end'>
                {entity.first_record_at ? format(new Date(entity.first_record_at), 'PPpp') : '-'}
              </div>
            </div>
            <div className={`row`}>
              <div className='col col-5 m-2'>Latest Record</div>
              <div className='col m-2 d-flex justify-content-end'>
                {entity.latest_record_at ? format(new Date(entity.latest_record_at), 'PPpp') : '-'}
              </div>
            </div>
          </div>,
        ],
      ]}
    />
  )
}

export {GatewayOverviewDetailsModal}
