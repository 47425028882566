import {useQueryClient} from 'react-query'
import {initiateWorkOrder} from '../../core/requests/maintenance'
import {ContentProps, FormModalWrapper} from '../../components/FormModalWrapper'

export interface IInitiateWorkOrderData {
  start_time?: number
}

type Props = {
  show: boolean
  handleClose: () => void
  workOrderId: string
}

const WorkOrderStartModal = ({show, handleClose, workOrderId}: Props) => {
  const queryClient = useQueryClient()

  const submit = async (data: IInitiateWorkOrderData) => {
    await initiateWorkOrder(workOrderId)
    queryClient.invalidateQueries('workOrders')
  }

  return (
    <FormModalWrapper<IInitiateWorkOrderData>
      id='hs_work_order_start_modal'
      title='Start Work Order'
      show={show}
      close={handleClose}
      defaultData={{start_time: undefined}}
      submit={submit}
      body={{content: Content}}
    />
  )
}

const Content = ({data, updateData, hasError}: ContentProps<IInitiateWorkOrderData>) => {
  return <></>
}

export {WorkOrderStartModal}
