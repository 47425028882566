import {
  Action,
  actionTypes,
  actionTypesMap,
  defaultAppendToLogForm,
  defaultCreateWorkOrderForm,
  defaultSendNotificationForm,
} from './actions/actions'
import Select from 'react-select'
import SendNotificationAction from './actions/SendNotification'
import CreateWorkOrderAction from './actions/CreateWorkOrder'
import AppendToLog from './actions/AppendToLog'
import {useEvents} from '../../core/requests/automations'

type Props = {
  action: Action | null
  setAction: (action: Action | null) => void
  removeAction?: () => void
  selectedEvent?: string
  errorOccurred?: boolean
  className?: string
}

const ActionCard = ({
  action,
  setAction,
  removeAction,
  selectedEvent,
  errorOccurred,
  className,
}: Props) => {
  const {data: events} = useEvents()
  const event = events?.find((e) => e[0] === selectedEvent)

  return (
    <div className={`card border border-secondary shadow-sm w-75 ${className}`}>
      <div className='card-body p-5'>
        <div className='d-flex align-items-center'>
          <Select
            placeholder='Select action...'
            className='w-100'
            value={action ? {label: actionTypesMap[action.type], value: action.type} : null}
            options={actionTypes.map((a) => ({label: actionTypesMap[a], value: a}))}
            onChange={(e) => {
              if (e === null) setAction(null)
              else
                setAction({
                  type: e.value,
                  enabled: true,
                  form:
                    e.value === 'send_notification'
                      ? defaultSendNotificationForm
                      : e.value === 'create_work_order'
                      ? defaultCreateWorkOrderForm
                      : e.value === 'append_to_log'
                      ? defaultAppendToLogForm
                      : {},
                })
            }}
          />

          {removeAction && (
            <i
              className='fa-solid fa-trash fs-5 cursor-pointer ps-5 pe-2'
              onClick={removeAction}
              style={{color: '#EE2D41'}}
            />
          )}
        </div>

        {action?.type === 'send_notification' ? (
          <SendNotificationAction
            action={action}
            setAction={setAction}
            eventVariables={event?.[1].variables}
            errorOccurred={errorOccurred}
          />
        ) : action?.type === 'create_work_order' ? (
          <CreateWorkOrderAction
            action={action}
            setAction={setAction}
            eventVariables={event?.[1].variables}
            errorOccurred={errorOccurred}
          />
        ) : action?.type === 'append_to_log' ? (
          <AppendToLog
            action={action}
            setAction={setAction}
            eventVariables={event?.[1].variables}
            errorOccurred={errorOccurred}
          />
        ) : null}
      </div>
    </div>
  )
}

export default ActionCard
