import {IWidget, defaultCreateWidget, defaultLayout} from '../../widgets/_models'

export type IEnergyPiechartWidget = IWidget & {
  running_minimum?: number
  running_maximum?: number | null
  standby_minimum?: number
  standby_maximum?: number | null
  shutdown_minimum?: number
  shutdown_maximum?: number | null
}

export const defaultCreateEnergyPiechartWidget: IEnergyPiechartWidget = {
  ...defaultCreateWidget,
  type: 'Energy Piechart',
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
    min_w: 4,
    min_h: 7,
  },
  operational_states: ['running', 'standby'],
}

export type StepProps = {
  data: IEnergyPiechartWidget
  updateData: (fieldsToUpdate: Partial<IEnergyPiechartWidget>) => void
  hasError: boolean
  current?: boolean
}
