import {useEffect, useState} from 'react'
import {Widget} from '../../../../core/_models'
import {KTSVG} from '../../../../../_metronic/helpers'
import {updateWidget} from '../../../../core/requests/template'
import {useQueryClient} from 'react-query'
import LoadingOverlayWrapper from '../../../../components/LoadingOverlayWrapper'

const WidgetTitle = ({widget, enableNameEdit}: {widget: Widget; enableNameEdit?: boolean}) => {
  const [widgetName, setWidgetName] = useState(widget.name)
  const [submitting, setSubmitting] = useState(false)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (enableNameEdit === true) setWidgetName(widget.name)
  }, [enableNameEdit, widget.name])

  const handleBlur = async () => {
    if (widgetName === widget.name || !enableNameEdit || widgetName === '') return
    try {
      setSubmitting(true)
      await updateWidget(widget._id, {name: widgetName})
      await queryClient.invalidateQueries('widgets')
    } catch (e) {
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    // Prevents widget from being dragged when clicking on the input
    event.stopPropagation()
  }

  if (widget.type === 'Text') return null

  return (
    <h3 className='card-title'>
      <div className='align-items-center'>
        {!enableNameEdit && <span className='fw-bold text-dark fs-3 me-1'>{widget.name}</span>}
        {enableNameEdit && (
          <>
            <LoadingOverlayWrapper loading={submitting}>
              <input
                type='text'
                className='form-control form-control-solid text-center fs-3 fw-bold'
                name='name'
                value={widgetName}
                onChange={(e) => setWidgetName(e.target.value)}
                onBlur={handleBlur}
                onMouseDown={handleMouseDown}
              />
            </LoadingOverlayWrapper>
          </>
        )}
      </div>
    </h3>
  )
}

export {WidgetTitle}
