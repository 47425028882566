import {NotificationChannel, Placement, SensorType} from '../../core/_models'
import {ICreateSubscription} from '../create-subscription/_models'

export type ICreatePlacementAlert = ICreateSubscription & {
  topic?: 'point-anomaly-detected' | 'threshold-crossed'
  // Non-api fields
  placement?: Placement
}

export const defaultCreatePlacementAlert: ICreatePlacementAlert = {
  topic: undefined,
  channels: undefined,
  is_enabled: true,
  additional_message: undefined,
  cooldown_hours: undefined,
  entities: undefined,
  zones: undefined,
  data_types: undefined,
  axis: undefined,
  name: undefined,
  minimum: undefined,
  maximum: undefined,
  n_neighbours: undefined,
  placement: undefined,
}

export type StepProps = {
  data: ICreatePlacementAlert
  updateData: (fieldsToUpdate: Partial<ICreatePlacementAlert>) => void
  hasError: boolean
}
