import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {useDisplayMode} from '../../../../_metronic/partials/layout/display-mode/DisplayModeProvider'
import PageNavigationBar, {Breadcrumb} from '../../../components/PageNavigationBar'
import {SelectedRowsProvider} from '../../../providers/SelectedRowsProvider'
import {OeeDashboardWrapper} from '../../dashboard/OeeDashboardWrapper'
import {PowerMonitoringDashboardWrapper} from '../../dashboard/PowerMonitoringDashboardWrapper'
import OverviewModeSwitch from '../core/components/overview/OverviewModeSwitch'
import {ProductionLinesWrapper} from './ProductionLinesWrapper'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Production Lines',
    path: '/production-lines/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductionLinesPage = () => {
  const {displayMode, updateDisplayMode} = useDisplayMode()

  React.useEffect(() => {
    if (displayMode !== 'energy' && displayMode !== 'oee') updateDisplayMode('oee')
  }, [displayMode])

  return (
    <Routes>
      <Route
        path='overview/grid?'
        element={
          <SelectedRowsProvider>
            <PageNavigationBar title={`Overview`} breadcrumbs={breadcrumbs}>
              <OverviewModeSwitch switchUrl={`/production-lines/overview`} />
            </PageNavigationBar>
            <ProductionLinesWrapper />
          </SelectedRowsProvider>
        }
      />
      <Route
        path=':id'
        element={
          <>
            {displayMode === 'energy' && <PowerMonitoringDashboardWrapper />}
            {displayMode === 'oee' && <OeeDashboardWrapper />}
            {displayMode === 'condition' && <></>}
          </>
        }
      />
      <Route index element={<Navigate to='/production-lines/overview' />} />
    </Routes>
  )
}

export default ProductionLinesPage
