import React, {useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import clsx from 'clsx'
import {EventCategory} from '../../../core/_models'
import {useCategorySelection} from '../CategorySelectionContext'
import {areArraysEqual} from '../../../core/array-util'

interface EventCategoriesSidebarSubItemProps {
  eventCategory: EventCategory
  subEventCategoryPathIds: string[]
}

const EventCategoriesSidebarSubItem: React.FC<EventCategoriesSidebarSubItemProps> = ({
  eventCategory,
  subEventCategoryPathIds,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {
    activeCategory,
    setActiveCategory,
    findSubEventCategoryByPath,
    activeSubEventCategoryPathIds,
  } = useCategorySelection()

  const category =
    subEventCategoryPathIds.length === 0
      ? eventCategory
      : findSubEventCategoryByPath(eventCategory, subEventCategoryPathIds)

  if (!category) {
    throw new Error('Category not found')
  }

  const hasSubCategories = category.sub_event_categories.length > 0

  const handleClick = (): void => {
    setActiveCategory(eventCategory, subEventCategoryPathIds)
    setIsExpanded(!isExpanded)
  }

  const isActive =
    activeCategory?._id === eventCategory._id &&
    areArraysEqual(activeSubEventCategoryPathIds, subEventCategoryPathIds)

  return (
    <>
      <div
        className={`ms-${
          subEventCategoryPathIds.length * 3 + 3
        } d-flex align-items-center cursor-pointer`}
        onClick={handleClick}
      >
        {!hasSubCategories && <div className='me-1 fa-solid fa-minus' />}
        {hasSubCategories && (
          <motion.i
            className='me-2 fa-solid fa-chevron-right'
            variants={{
              open: {rotate: 90},
              collapsed: {rotate: 0},
            }}
            animate={isExpanded ? 'open' : 'collapsed'}
            transition={{duration: 0.2}}
          />
        )}

        <span className={clsx('fs-3 ps-1 flex-fill', isActive && 'bg-primary rounded')}>
          {category.title}
        </span>
      </div>
      {hasSubCategories && (
        <motion.div
          initial='collapsed'
          animate={isExpanded ? 'open' : 'collapsed'}
          variants={{
            open: {opacity: 1, height: 'auto', display: 'block'},
            collapsed: {opacity: 0, height: 0, transitionEnd: {display: 'none'}},
          }}
          transition={{duration: 0.2}}
        >
          {category.sub_event_categories.map((subEventCategory) => (
            <EventCategoriesSidebarSubItem
              eventCategory={eventCategory}
              key={subEventCategory._id}
              subEventCategoryPathIds={[...subEventCategoryPathIds, subEventCategory._id!]}
            />
          ))}
        </motion.div>
      )}
    </>
  )
}

export default EventCategoriesSidebarSubItem
