import {FC} from 'react'

type Props = {
  str: string
  color?: string
}

const BadgeCell: FC<Props> = ({str, color = 'primary'}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <div className='text-end pe-12'>
        <span className={`badge py-3 px-4 fs-7 badge-light-${color}`}>{str}</span>
      </div>
    </div>
  </div>
)

export {BadgeCell}
