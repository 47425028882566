import {useEffect, useState} from 'react'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {Placement} from '../../core/_models'
import {useCreateModals} from '../CreateModalsProvider'
import Step2 from './Step2'
import Step1 from './Step1'
import {defaultCreatePlacement, ICreatePlacement} from './_models'
import {createPlacement} from '../../core/requests/factory'

const CreatePlacementModal = () => {
  const [data, setData] = useState<ICreatePlacement>(defaultCreatePlacement)
  const {
    showCreatePlacementModal,
    closeCreatePlacementModal,
    openCreateSensorModal,
    initialPart,
    initialProductionLine,
    initialArea,
  } = useCreateModals()

  useEffect(() => {
    const locationChoice = initialPart
      ? 'part'
      : initialProductionLine
      ? 'production_line'
      : initialArea
      ? 'area'
      : undefined
    setData({
      ...data,
      locationChoice,
      part: initialPart?._id,
      production_line: initialProductionLine?._id,
      area: initialArea?._id,
    })
  }, [initialPart, initialArea, initialProductionLine])

  const steps: Step<ICreatePlacement>[] = [
    {
      title: 'Location',
      description: 'Specify Placement Location',
      component: Step1,
      validation: (data) =>
        data.locationChoice === undefined ||
        (data.locationChoice === 'area' && !data.area) ||
        (data.locationChoice === 'production_line' && !data.production_line) ||
        (data.locationChoice === 'section' && !data.section) ||
        (data.locationChoice === 'part' && !data.part),
    },
    {
      title: 'Name Your Placement',
      description: 'Assign an Identifier',
      component: Step2,
      validation: (data) => !data.name,
    },
  ]

  return (
    <StepModalWrapper<ICreatePlacement, Placement>
      id='hs_create_placement'
      objectName='Placement'
      show={showCreatePlacementModal}
      close={closeCreatePlacementModal}
      steps={steps}
      data={data}
      defaultData={defaultCreatePlacement}
      setData={setData}
      submitData={createPlacement}
      openNextModals={[(placement) => openCreateSensorModal({placement})]}
      nextObjectNames={['Sensor']}
    />
  )
}

export default CreatePlacementModal
