import React from 'react'

type Props = {
  status: string
  className?: string
}

const WorkOrderStatusBadge = ({status, className}: Props) => (
  <span
    className={`badge badge-light-${
      status === 'pending' ? 'primary' : status === 'in progress' ? 'info' : 'success'
    } ${className}`}
  >
    {status
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')}
  </span>
)

export default WorkOrderStatusBadge
