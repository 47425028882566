import * as am5 from '@amcharts/amcharts5'
import {useEffect, useRef} from 'react'
import {ColumnData, columnConfig} from '../../core/_chartConfigs/columnConfig'
import {ChartLoader} from '../ChartLoader'

type ColumnGraphProps = {
  id: string
  data?: ColumnData
  height: string
}

const ColumnGraph = ({id, data, height}: ColumnGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)

  const loading = data === undefined

  useEffect(() => {
    if (loading) return

    chartRef.current = columnConfig(id, data)

    return () => {
      if (chartRef.current) chartRef.current.dispose()
    }
  }, [data, loading])

  return <ChartLoader loading={loading} chartId={id} height={height} />
}

export default ColumnGraph
