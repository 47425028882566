import {useIntl} from 'react-intl'
import TextField from '../../components/form-components/TextField'
import {ICreateAsset, StepProps} from './_models'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* Asset Name */}
        <TextField
          name='Asset Name'
          data={data}
          updateData={updateData}
          dataField='name'
          hasError={hasError}
          tooltip={intl.formatMessage({id: 'TOOLTIP.NAMED_ENTITY.ASSET'})}
          required
        />

        {/* <div className='d-flex justify-content-end'>
          <label className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={data.auto_enable_notifications}
              onChange={(e) =>
                updateData({auto_enable_notifications: e.target.checked} as Partial<ICreateAsset>)
              }
            />
            <span className='form-check-label fw-semibold text-muted'>
              Auto Enable Notifications
            </span>
          </label>
        </div> */}
      </div>
    </div>
  )
}

export default Step1
