import {Dispatch, SetStateAction, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import AssetsStateVerify from './AssetsStateVerify'

type AssetsStateVerifyModalProps = {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  onCancel: () => void
  onSubmit: () => void
}

const AssetsStateVerifyModal = ({
  show,
  setShow,
  onCancel,
  onSubmit,
}: AssetsStateVerifyModalProps) => (
  <Modal
    show={show}
    tabIndex={-1}
    aria-hidden='true'
    dialogClassName='modal-dialog modal-dialog-centered'
    size='xl'
    onHide={() => setShow(false)}
    backdrop={true}
  >
    <div className='modal-header'>
      <h2>Confirm Verify Machine Data</h2>
      <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={onCancel}>
        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
      </div>
    </div>
    <div className='modal-body py-lg-10 px-lg-10'>
      <AssetsStateVerify onCancel={onCancel} onSubmit={onSubmit} />
    </div>
  </Modal>
)

type useAssetsStateVerifyModalProps = {
  onCancel: () => void
  onSubmit: () => void
}

const useAssetsStateVerifyModal = ({onCancel, onSubmit}: useAssetsStateVerifyModalProps) => {
  const [show, setShow] = useState(false)
  const modal = AssetsStateVerifyModal({
    show,
    setShow,
    onCancel,
    onSubmit,
  })
  return {
    modal,
    setShow,
  }
}

export {useAssetsStateVerifyModal, AssetsStateVerifyModal}
