import {KTSVG} from '../../../_metronic/helpers'
import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'
import {StepProps} from './_models'

const Step2 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxes
          name='Where is the asset located?'
          optionNames={['Area', 'Section']}
          optionValues={['area', 'section']}
          data={data}
          updateData={updateData}
          dataField='locationChoice'
          updatesOnChange={{area: undefined, section: undefined}}
          hasError={hasError}
          required
          lastField
        />
      </div>
    </div>
  )
}

export default Step2
