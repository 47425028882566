import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {showNoDataDisplay} from '../../../../core/chart-util'

export type PieData = {
  label: string
  value: number
  color?: am5.Color
}

export function pieConfig(chartId: string, data: PieData[]) {
  const root = am5.Root.new(chartId)
  root.setThemes([am5themes_Animated.new(root)])

  const chart = root.container.children.push(
    am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
    })
  )

  const series = chart.series.push(
    am5percent.PieSeries.new(root, {
      valueField: 'value',
      categoryField: 'label',
    })
  )

  series.slices.template.setAll({
    stroke: am5.color('#fff'),
    templateField: 'settings',
  })

  series.data.setAll(
    data.map((d) => ({...d, settings: d.color !== undefined ? {fill: d.color} : undefined}))
  )

  if (!data.length) return showNoDataDisplay(chartId, root, chart)

  chart.appear(1000, 100)

  return root
}
