import NumberField from '../../../../../../components/form-components/NumberField'
import SelectField from '../../../../../../components/form-components/SelectField'
import {timespans} from '../../../../../../core/_models'
import {StepProps} from './_models'

const Step4 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='mb-10'>
          <h2 className='fw-bold d-flex align-items-center text-dark'>
            Optional: Data Aggregation Settings
          </h2>
          <div className='text-muted fw-semibold fs-6'>
            Optionally, select a base timespan and define a multiplier to customize data aggregation
            into intervals. If skipped, data will be displayed in its most granular form without
            aggregation.
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <SelectField
              name='Timespan'
              tooltip='The timespan sets the basic unit for data grouping. Your chart will display one data point for each timespan unit.'
              optionNames={timespans.map((s) => s.charAt(0).toUpperCase() + s.slice(1))}
              optionValues={timespans}
              data={data}
              updateData={updateData}
              dataField='timespan'
            />
          </div>
          <div className='col'>
            <NumberField
              name='Multiplier'
              tooltip='The multiplier extends the timespan by an integer factor. For instance, a timespan of "hour" with a multiplier of "4" will group data in 4-hour intervals.'
              data={data}
              updateData={updateData}
              dataField='multiplier'
              isInteger
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step4
