import {useEffect, useRef, useState} from 'react'
import * as am5 from '@amcharts/amcharts5'
import {
  TransformedEventData,
  productionStatusTimelineConfig,
} from '../../core/_chartConfigs/productionStatusTimelineConfig'
import {ChartLoader} from '../ChartLoader'
import useModalState from '../../../../hooks/UseModalState'
import {EventCategory, OeeEventLog, SubEventCategory} from '../../../../core/_models'
import {
  useEventCategories,
  useEventLogs,
  useMachineProductionStats,
} from '../../../../core/requests/oee'
import LogEventModal from '../../../../modals/log-event/LogEventModal'

enum TimelineGraphDataTypes {
  ProductRate = 'Product Rate',
  OperationalEvents = 'Operational Events',
}

type ProductionStatusTimelineGraphProps = {
  productionLineId: string
  startTime?: string
  endTime?: string
  height: string
  dataTypes?: TimelineGraphDataTypes[]
  chartId?: string
  editMode?: boolean
}

const ProductionStatusTimelineGraph = ({
  productionLineId,
  startTime,
  endTime,
  height,
  dataTypes = [TimelineGraphDataTypes.ProductRate, TimelineGraphDataTypes.OperationalEvents],
  chartId = `production-status-timeline-${productionLineId}`,
  editMode = true,
}: ProductionStatusTimelineGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)
  const eventModal = useModalState()
  const [eventLogToEdit, setEventLogToEdit] = useState<OeeEventLog>()
  const [categoryOfEventLogToEdit, setCategoryOfEventLogToEdit] = useState<
    EventCategory | SubEventCategory
  >()

  const includeProductRate = dataTypes.includes(TimelineGraphDataTypes.ProductRate)
  const includeOperationalEvents = dataTypes.includes(TimelineGraphDataTypes.OperationalEvents)

  const fetchDataParams = {
    productionLine: productionLineId,
    startTime,
    endTime,
    options: {refetchOnWindowFocus: false},
  }

  const {data: eventLogs} = useEventLogs({
    entities: [productionLineId],
    startTime,
    endTime,
    options: {...fetchDataParams.options, enabled: includeOperationalEvents},
  })
  const {data: eventCategories} = useEventCategories({
    ids: eventLogs?.items.map((e) => e.categories).flat() || [],
    options: {
      ...fetchDataParams.options,
      enabled: includeOperationalEvents && eventLogs !== undefined && eventLogs.items.length > 0,
    },
  })
  const {data: productionStats} = useMachineProductionStats({
    id: productionLineId,
    startTime,
    endTime,
    options: {...fetchDataParams.options, enabled: includeProductRate},
  })

  const openEditEventModal = (eventData: TransformedEventData) => {
    if (!editMode) return
    setEventLogToEdit(eventData.eventLog)
    setCategoryOfEventLogToEdit(eventData.eventCategory)
    eventModal.open()
  }

  const loading =
    (includeOperationalEvents && !eventLogs && !eventCategories) ||
    (includeProductRate && !productionStats)

  useEffect(() => {
    if (loading) return

    const eventLogData = eventLogs?.items || []
    const productRateData = (productionStats?.rolling_product_rate.filter((r) => r[1] !== null) ||
      []) as [number, number][]
    const categoryMap = mapEventCategories(eventCategories?.items || [])

    chartRef.current = productionStatusTimelineConfig(
      chartId,
      eventLogData,
      categoryMap,
      productRateData,
      endTime,
      openEditEventModal
    )
    return () => {
      if (chartRef.current) chartRef.current.dispose()
    }
  }, [eventLogs, eventCategories, productionStats, loading, editMode])

  return (
    <>
      <ChartLoader loading={loading} chartId={chartId} height={height} />

      {editMode && eventLogToEdit && (
        <LogEventModal
          show={eventModal.isOpen}
          handleClose={() => {
            setEventLogToEdit(undefined)
            eventModal.close()
          }}
          productionLineId={productionLineId}
          isEditMode={true}
          eventLogToEdit={eventLogToEdit}
          categoryOfEventLogToEdit={categoryOfEventLogToEdit}
        />
      )}
    </>
  )
}

export function mapEventCategories(
  eventCategories: EventCategory[]
): Map<string, EventCategory | SubEventCategory> {
  const categoryMap = new Map<string, EventCategory | SubEventCategory>()

  const addSubCategories = (subCategories: SubEventCategory[]) => {
    subCategories.forEach((subCategory) => {
      if (subCategory._id) categoryMap.set(subCategory._id, subCategory)

      addSubCategories(subCategory.sub_event_categories)
    })
  }

  eventCategories.forEach((category) => {
    categoryMap.set(category._id, category)
    addSubCategories(category.sub_event_categories)
  })

  return categoryMap
}
export {ProductionStatusTimelineGraph}
