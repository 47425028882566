import {Modal} from 'react-bootstrap'
import {Placement, SensorType, Subscription} from '../../core/_models'
import {KTSVG} from '../../../_metronic/helpers'
import {
  deleteSubscription,
  updateSubscription,
  useSubscriptions,
} from '../../core/requests/notification'
import LoadingWrapper from '../../components/LoadingWrapper'
import {useQueryClient} from 'react-query'
import {useState} from 'react'
import CreatePlacementAlertModal from '../create-placement-alert/CreatePlacementAlertModal'
import {ConfirmDeletionModal} from '../../pages/overview/core/columns/TableSelectionToolbar'
import {formatSubscriptionTopic} from '../../core/name-util'

type Props = {
  placement: Placement
  show: boolean
  handleClose: () => void
}

const PlacementAlertsModal = ({placement, show, handleClose}: Props) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [editingSubscription, setEditingSubscription] = useState<Subscription>()
  const [deletingSubscription, setDeletingSubscription] = useState<Subscription>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const queryClient = useQueryClient()

  const {data: subscriptions} = useSubscriptions({
    entities: [placement._id],
    options: {enabled: show},
  })

  const toggleAlert = async (subscriptionId: string, isEnabled: boolean) => {
    try {
      await updateSubscription(subscriptionId, {is_enabled: isEnabled})
      queryClient.invalidateQueries('subscriptions')
    } catch (error) {
      console.error('Failed to update subscription:', error)
    }
  }

  const deleteAlert = async () => {
    try {
      await deleteSubscription(deletingSubscription!._id)
      queryClient.invalidateQueries('subscriptions')
      setDeletingSubscription(undefined)
      setShowDeleteModal(false)
    } catch (error) {
      console.error('Failed to delete subscription:', error)
    }
  }

  const renderThreshold = (
    subscription: Subscription,
    value: number | undefined,
    directionIcon: string
  ) => {
    if (!value) return null

    const dataType = subscription.data_types?.[0]
    const unit = dataType ? sensorTypeToUnitMap[dataType] : ''

    return (
      <div className='d-flex align-items-center'>
        <i className={`fa-solid ${directionIcon} me-2`} />
        <span>{`${value} ${unit}`}</span>
      </div>
    )
  }

  return (
    <>
      <Modal
        id='hs_placement_alerts_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered'
        size='xl'
        show={show && !showCreateModal && !showDeleteModal}
        onHide={handleClose}
        container={document.getElementById('root-modals')}
      >
        <div className='modal-header'>
          <h2>{`${placement.name} Alerts`}</h2>
          <div>
            <a
              className='btn btn-sm fw-bold btn-primary me-5'
              onClick={() => setShowCreateModal(true)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add Alert
            </a>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
        </div>

        <div className='modal-body m-5'>
          <LoadingWrapper loading={subscriptions === undefined}>
            <div className='table-responsive'>
              <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                <thead>
                  <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                    <th className='p-0 pb-3'>NAME</th>
                    <th className='p-0 pb-3'>TYPE</th>
                    <th className='p-0 pb-3'>THRESHOLDS</th>
                    <th className='p-0 pb-3'>CHANNELS</th>
                    <th className='p-0 pb-3'>COOLDOWN</th>
                    <th className='p-0 pb-3 text-end pe-3'>ENABLED</th>
                    <th className='p-0 pb-3 w-90px'></th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptions?.items?.map((subscription) => (
                    <tr key={subscription._id}>
                      <td>
                        <span className='fw-bold fs-5'>{subscription.name}</span>
                      </td>
                      <td className='ps-0'>
                        <span className='fw-bold fs-5'>
                          {formatSubscriptionTopic(subscription.topic)}
                        </span>
                      </td>
                      <td className='ps-0'>
                        <div className='d-flex flex-column fw-bold'>
                          {renderThreshold(subscription, subscription.maximum, 'fa-arrow-up')}
                          {renderThreshold(subscription, subscription.minimum, 'fa-arrow-down')}
                        </div>
                      </td>
                      <td className='ps-0'>
                        <span className='fw-bold fs-5'>{subscription.channels.join(', ')}</span>
                      </td>
                      <td className='ps-0'>
                        <span className='fw-bold fs-5'>
                          {subscription.cooldown_hours
                            ? `${subscription.cooldown_hours} hours`
                            : ''}
                        </span>
                      </td>
                      <td>
                        <div
                          className='d-flex justify-content-end align-items-center'
                          style={{height: '100%'}}
                        >
                          <label className='form-check form-switch form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={subscription.is_enabled}
                              onChange={() =>
                                toggleAlert(subscription._id, !subscription.is_enabled)
                              }
                            />
                          </label>
                        </div>
                      </td>
                      <td className='ps-0'>
                        <button
                          type='button'
                          className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                          onClick={() => {
                            setEditingSubscription(subscription)
                            setShowCreateModal(true)
                          }}
                        >
                          <i className='fa-solid fs-3 fa-pen' />
                        </button>
                        <button
                          type='button'
                          className='btn btn-icon btn-color-gray-400 btn-active-color-danger'
                          onClick={() => {
                            setDeletingSubscription(subscription)
                            setShowDeleteModal(true)
                          }}
                        >
                          <i className='fa-solid fs-3 fa-trash' />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </LoadingWrapper>
        </div>
      </Modal>

      <CreatePlacementAlertModal
        show={showCreateModal}
        hide={() => {
          setShowCreateModal(false)
          setEditingSubscription(undefined)
        }}
        placement={placement}
        edit={editingSubscription}
      />

      <ConfirmDeletionModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        deleteSelections={deleteAlert}
      />
    </>
  )
}

const sensorTypeToUnitMap: {[type in SensorType]: string} = {
  vibration: 'mm/s',
  temperature: '°C',
  output: '',
  ampere: 'A',
  h2s: 'ppm',
}

export default PlacementAlertsModal
