import {useQueryClient} from 'react-query'
import EntityInlineTextFieldForm from '../../../components/form-components/EntityInlineTextFieldForm'
import {EventCategory, SubEventCategory} from '../../../core/_models'
import ContentTitle from './ContentTitle'
import {
  UpdateEventCategoryProps,
  updateEventCategory,
  useEventCategoryGroups,
  useUpdateEventCategoryMutation,
} from '../../../core/requests/oee'
import {useCategorySelection} from '../CategorySelectionContext'
import React, {useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {MAX_DEPTH, PROTECTED_CATEGORIES} from '../EventCategoriesModal'
import {ColorPicker} from '../../../components/ColorPicker'
import IconPicker from '../../../components/IconPicker'

type DisplayCategoryProps = {
  eventCategory: EventCategory
  subEventCategoryPathIds: string[]
}

const DisplayCategory = ({eventCategory, subEventCategoryPathIds}: DisplayCategoryProps) => {
  const {
    setActiveCategory,
    setActiveCategoryGroup,
    findSubEventCategoryByPath,
    updateCategory,
    openDeleteModal,
  } = useCategorySelection()
  const {data: eventCategoryGroups} = useEventCategoryGroups()

  const [activeNavItem, setActiveNavItem] = useState<string>('Sub Categories')
  const [showColorPicker, setShowColorPicker] = useState(false)

  const category =
    subEventCategoryPathIds.length === 0
      ? eventCategory
      : findSubEventCategoryByPath(eventCategory, subEventCategoryPathIds)
  if (!category) throw new Error('Category not found')

  const changeTitle = async (newTitle: string) => {
    await updateCategory(eventCategory, subEventCategoryPathIds, {title: newTitle})
  }
  const changeColor = async (newColor: string) =>
    await updateCategory(eventCategory, subEventCategoryPathIds, {color: newColor})

  const handleReturn = () => {
    if (subEventCategoryPathIds.length === 0) {
      const parentGroup = eventCategoryGroups?.items.find((group) =>
        eventCategory.event_category_groups.includes(group._id)
      )
      if (parentGroup) setActiveCategoryGroup(parentGroup)
    } else {
      setActiveCategory(eventCategory, subEventCategoryPathIds.slice(0, -1))
    }
  }

  return (
    <>
      <div className='d-flex align-items-center mb-5'>
        <i className='fa-solid fa-reply fs-1 cursor-pointer me-5' onClick={handleReturn} />
        <div
          className='me-2 shadow cursor-pointer'
          style={{
            backgroundColor: category.color,
            width: '1.5em',
            height: '1.5em',
            borderRadius: '0.25em',
          }}
          onClick={(e) => {
            e.stopPropagation()
            setShowColorPicker(true)
          }}
        />
        <ColorPicker
          color={eventCategory.color}
          show={showColorPicker}
          setShow={setShowColorPicker}
          onClose={(color) => changeColor(color)}
        />
        <ContentTitle
          title={category.title}
          onEdit={!PROTECTED_CATEGORIES.includes(category.title) ? changeTitle : undefined}
          protectedNames={PROTECTED_CATEGORIES}
          className='flex-fill'
        />
      </div>

      {subEventCategoryPathIds.length === 0 ? (
        <div className={`d-flex align-content-center align-items-center`}>
          <EntityInlineTextFieldForm
            data={eventCategory}
            mutateData={true}
            dataField={'description'}
            useMutation={useUpdateEventCategoryMutation}
            queryToInvalidate={'eventCategories'}
            defaultValue={`description`}
          />
        </div>
      ) : null}

      <ul className='nav nav-stretch nav-pills nav-pills-custom d-flex mb-5'>
        <li className='nav-item' onClick={() => setActiveNavItem('Sub Categories')}>
          <a
            className={clsx(
              'nav-link btn btn-color-gray-400 flex-center px-1',
              activeNavItem === 'Sub Categories' && 'active'
            )}
          >
            <span className='nav-text fw-semibold fs-4'>Sub Categories</span>
            <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
          </a>
        </li>
      </ul>

      <div className='container border border-secondary rounded py-1'>
        <div className='row p-1'>
          <div className='col-6 text-muted'>Title</div>
          <div className='col-6 text-muted'>Sub Categories</div>
        </div>
        {category.sub_event_categories.map((subCategory) => (
          <EventSubCategoryRow
            key={subCategory._id}
            eventCategory={eventCategory}
            subEventCategoryPathIds={subEventCategoryPathIds}
            subCategory={subCategory}
          />
        ))}
        {subEventCategoryPathIds.length < MAX_DEPTH && (
          <NewEventSubCategoryRow
            eventCategory={eventCategory}
            subEventCategoryPathIds={subEventCategoryPathIds}
          />
        )}
        {subEventCategoryPathIds.length >= MAX_DEPTH && (
          <div className='row py-2 border-top border-secondary'>
            <div className='col-12 fw-bold text-center'>No more sub categories allowed</div>
          </div>
        )}
      </div>

      <span
        className='d-inline-block align-self-end mt-auto'
        tabIndex={0}
        {...(PROTECTED_CATEGORIES.includes(category.title)
          ? {
              'data-bs-toggle': 'tooltip',
              title:
                'This is a protected category essential for system operations and cannot be deleted.',
            }
          : {})}
      >
        <button
          className={clsx(
            'd-flex btn btn-danger fw-semibold align-items-center',
            PROTECTED_CATEGORIES.includes(category.title) && 'disabled'
          )}
          onClick={() => openDeleteModal(eventCategory, subEventCategoryPathIds)}
        >
          <i className='me-1 fa-solid fa-trash' />
          {`Delete ${category.title}`}
        </button>
      </span>
    </>
  )
}

type NewEventSubCategoryRowProps = {
  eventCategory: EventCategory
  subEventCategoryPathIds: string[]
}

const NewEventSubCategoryRow = ({
  eventCategory,
  subEventCategoryPathIds,
}: NewEventSubCategoryRowProps) => {
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [color, setColor] = useState('#FF0000')
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [icon, setIcon] = useState('fa-solid fa-atom')

  const {setActiveCategory, findSubEventCategoryByPath} = useCategorySelection()

  const rowRef = useRef<HTMLDivElement | null>(null)

  const queryClient = useQueryClient()

  const resetState = () => {
    setIsActive(false)
    setTitle('')
    setColor('#FF0000')
    setShowColorPicker(false)
  }

  useEffect(() => {
    const handleClickOutsideRow = (event: MouseEvent) => {
      if (!isLoading && rowRef.current && !rowRef.current.contains(event.target as Node))
        resetState()
    }
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (!isLoading && event.key === 'Escape') resetState()
    }

    if (isActive && !isLoading) {
      document.addEventListener('mousedown', handleClickOutsideRow)
      document.addEventListener('keydown', handleEscapeKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideRow)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [isActive, isLoading])

  const onSubmit = async () => {
    if (isLoading || title === '') {
      setIsActive(false)
      return
    }
    setIsLoading(true)
    try {
      if (subEventCategoryPathIds.length === 0) {
        const updatedEventCategory = await updateEventCategory({
          id: eventCategory._id,
          subEventCategories: [
            ...eventCategory.sub_event_categories,
            {title, color, icon, sub_event_categories: []},
          ],
        })
        await queryClient.invalidateQueries('eventCategories')
        setActiveCategory(updatedEventCategory, [])
      } else {
        const eventCategoryCopy = {...eventCategory}
        const subCategory = findSubEventCategoryByPath(eventCategoryCopy, subEventCategoryPathIds)
        subCategory!.sub_event_categories.push({title, color, icon, sub_event_categories: []})
        const updatedEventCategory = await updateEventCategory({
          id: eventCategoryCopy._id,
          subEventCategories: eventCategoryCopy.sub_event_categories,
        })
        await queryClient.invalidateQueries('eventCategories')
        setActiveCategory(updatedEventCategory, subEventCategoryPathIds)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      resetState()
    }
  }

  if (isActive) {
    return (
      <div ref={rowRef} className='row py-2 border-top border-secondary'>
        <div className='col-12 d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <div
              className='me-1 shadow cursor-pointer'
              style={{
                backgroundColor: color,
                width: '1em',
                height: '1em',
                borderRadius: '0.25em',
              }}
              onClick={() => setShowColorPicker(true)}
            />
            <ColorPicker
              color={color}
              show={showColorPicker}
              setShow={setShowColorPicker}
              setColor={setColor}
            />

            <IconPicker className='me-1' icon={icon} setIcon={setIcon} />

            <input
              type='text'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className='col-10 m-0 p-0 ps-1 fw-bold'
              style={{border: 'none', background: 'transparent', outline: 'none'}}
              placeholder='Enter category title...'
              autoFocus
              disabled={isLoading}
            />
          </div>
          {!isLoading && (
            <button
              type='button'
              className={clsx(
                'btn btn-primary btn-sm d-flex align-items-center px-2 py-1 float-end',
                title === '' && 'disabled'
              )}
              onClick={onSubmit}
            >
              Save
              <i className='fa-solid fa-floppy-disk ms-1' />
            </button>
          )}
          {isLoading && <i className='fa-solid fa-spinner fa-spin me-2' />}
        </div>
      </div>
    )
  }

  if (!isActive)
    return (
      <div
        className='row py-2 border-top border-secondary cursor-pointer bg-hover-secondary'
        onClick={() => setIsActive(true)}
      >
        <div className='d-flex align-items-center'>
          <i className='fa-solid fa-plus me-3' />
          <div className='text-muted'>Add Sub Category</div>
        </div>
      </div>
    )

  return null
}

type EventSubCategoryRowProps = {
  eventCategory: EventCategory
  subEventCategoryPathIds: string[]
  subCategory: SubEventCategory
}

const EventSubCategoryRow = ({
  eventCategory,
  subEventCategoryPathIds,
  subCategory,
}: EventSubCategoryRowProps) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const {setActiveCategory, updateCategory} = useCategorySelection()

  const handleRowClick = () => {
    if (showColorPicker) return
    setActiveCategory(eventCategory, [...subEventCategoryPathIds, subCategory._id!])
  }

  const handleChange = async (data: Partial<UpdateEventCategoryProps>) => {
    const updatedCategory = await updateCategory(
      eventCategory,
      [...subEventCategoryPathIds, subCategory._id!],
      data,
      false
    )
    setActiveCategory(updatedCategory, subEventCategoryPathIds)
  }

  return (
    <div
      className='row py-2 border-top border-secondary cursor-pointer bg-hover-secondary'
      onClick={handleRowClick}
    >
      <div className='col-6'>
        <div className='d-flex align-items-center'>
          {/* Color */}
          <div
            className='me-2 shadow'
            style={{
              backgroundColor: subCategory.color,
              width: '1em',
              height: '1em',
              borderRadius: '0.25em',
            }}
            onClick={(e) => {
              e.stopPropagation()
              setShowColorPicker(true)
            }}
          />
          <ColorPicker
            className='cursor-default'
            color={eventCategory.color}
            show={showColorPicker}
            setShow={setShowColorPicker}
            onClose={(color) => handleChange({color})}
          />

          {/* Title */}
          <div className='fw-bold me-1'>{subCategory.title}</div>

          {/* Icon */}
          <IconPicker
            className='cursor-default'
            icon={subCategory.icon}
            setIcon={(icon) => {
              handleChange({icon})
            }}
          />
        </div>
      </div>
      <div className='col-6'>{subCategory.sub_event_categories.map((e) => e.title).join(', ')}</div>
    </div>
  )
}

export default DisplayCategory
