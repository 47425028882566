export type ICreateAsset = {
  area?: string
  section?: string
  name?: string
  auto_start: boolean
  auto_stop: boolean
  auto_enable_notifications: boolean
  auto_stop_detection_tolerance?: number
  // Not model fields
  locationChoice?: 'area' | 'section'
}

export const defaultCreateAsset: ICreateAsset = {
  area: undefined,
  name: undefined,
  auto_start: true,
  auto_stop: true,
  auto_enable_notifications: false,
  auto_stop_detection_tolerance: 5,
  locationChoice: undefined,
}

export type StepProps = {
  data: ICreateAsset
  updateData: (fieldsToUpdate: Partial<ICreateAsset>) => void
  hasError: boolean
}
