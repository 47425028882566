import {Step} from '../../../../../../components/StepModalWrapper'
import {IOeeWidget} from '../_models'
import DataTypesSelectionStep from './DataTypesSelectionStep'
import TimeFrameStep from './TimeFrameStep'
import ProductionLineOrAssetSelectionStep from './ProductionLineOrAssetSelectionStep'
import ProductionLineSelectionStep from './ProductionLineSelectionStep'
import SettingsStep from './SettingsStep'
import StopRegistrationTypeSelectionStep from './StopRegistrationTypeSelectionStep'
import NameStep from './NameStep'
import EventCategorySelectionStep from './EventCategorySelectionStep'
import ProductionLinesAndAssetsSelectionStep from './ProductionLinesAndAssetsSelectionStep'
import TimeRangeStep from './TimeRangeStep'

export const paretoSteps: Step<IOeeWidget>[] = [
  {
    title: 'Entity',
    description: 'Select Entity',
    component: ProductionLineSelectionStep,
    validation: (data) => data.entity === undefined,
  },
  {
    title: 'Event Categories',
    description: 'Select specific event categories to include in the Pareto chart',
    component: EventCategorySelectionStep,
  },
  {
    title: 'Time Frame Settings',
    description: 'Configure Time Frame Settings',
    component: TimeRangeStep,
    validation: (data) => (!!data.start_time || !!data.end_time) && !!data.dynamic_time_range,
  },
  {
    title: 'Choose Name',
    description: 'Select the name of the widget',
    component: NameStep,
    validation: (data) => data.name === undefined,
  },
]

export const timelineSteps: Step<IOeeWidget>[] = [
  {
    title: 'Entity',
    description: 'Select Entity',
    component: ProductionLineSelectionStep,
    validation: (data) => data.entity === undefined,
  },
  {
    title: 'Data Types',
    description: 'Select Data Types',
    component: DataTypesSelectionStep,
    validation: (data) => data.data_types === undefined || data.data_types.length === 0,
  },
  {
    title: 'Time Frame Settings',
    description: 'Configure Time Frame Settings',
    component: TimeRangeStep,
    validation: (data) => (!!data.start_time || !!data.end_time) && !!data.dynamic_time_range,
  },
  {
    title: 'Choose Name',
    description: 'Select the name of the widget',
    component: NameStep,
    validation: (data) => data.name === undefined,
  },
]

export const oeeMetricsSteps: Step<IOeeWidget>[] = [
  {
    title: 'Entity',
    description: 'Select Entity',
    component: ProductionLineSelectionStep,
    validation: (data) => data.entity === undefined,
  },
  {
    title: 'Event Categories',
    description: 'Select specific event categories to include in the OEE calculation',
    component: EventCategorySelectionStep,
  },
  {
    title: 'Time Frame Settings',
    description: 'Configure Time Frame Settings',
    component: TimeRangeStep,
    validation: (data) => (!!data.start_time || !!data.end_time) && !!data.dynamic_time_range,
  },
  {
    title: 'Choose Name',
    description: 'Select the name of the widget',
    component: NameStep,
    validation: (data) => data.name === undefined,
  },
]

export const stopRegistrationSteps: Step<IOeeWidget>[] = [
  {
    title: 'Choose Entity',
    description: 'Select the production line or asset to register events for',
    component: ProductionLineOrAssetSelectionStep,
    validation: (data) => data.entity === undefined,
  },
  {
    title: 'Widget Configuration',
    description: 'Choose between a general or a specific event-focused widget',
    component: StopRegistrationTypeSelectionStep,
    validation: (data) =>
      data.eventRegistrationType === undefined ||
      (data.eventRegistrationType === 'Pre-configured Event Registration' &&
        (data.operational_event_categories === undefined ||
          data.operational_event_categories.length === 0)),
  },
  {
    title: 'Choose Name',
    description: 'Select the name of the widget',
    component: NameStep,
    validation: (data) => data.name === undefined,
  },
]

export const operationalStateSteps: Step<IOeeWidget>[] = [
  {
    title: 'Entity',
    description: 'Select Entity',
    component: ProductionLineOrAssetSelectionStep,
    validation: (data) => data.entity === undefined,
  },
]

export const operationalStatsSteps: Step<IOeeWidget>[] = [
  {
    title: 'Entity',
    description: 'Select Entity',
    component: ProductionLineSelectionStep,
    validation: (data) => data.entity === undefined,
  },
]

export const productionStatsSteps: Step<IOeeWidget>[] = [
  {
    title: 'Entity',
    description: 'Select Entity',
    component: ProductionLineSelectionStep,
    validation: (data) => data.entity === undefined,
  },
]

export const eventLogsSteps: Step<IOeeWidget>[] = [
  {
    title: 'Entities',
    description: 'Select Entities',
    component: ProductionLineOrAssetSelectionStep,
    validation: (data) => data.entity === undefined,
  },
  {
    title: 'Event Categories',
    description: 'Select event categories to include',
    component: EventCategorySelectionStep,
  },
  {
    title: 'Time Frame Settings',
    description: 'Configure Time Frame Settings',
    component: TimeRangeStep,
    validation: (data) => (!!data.start_time || !!data.end_time) && !!data.dynamic_time_range,
  },
  {
    title: 'Choose Name',
    description: 'Select the name of the widget',
    component: NameStep,
    validation: (data) => data.name === undefined,
  },
]
