import {useQuery} from 'react-query'
import {graphql} from '../../../../../gql'
import {MachineStatuses} from '../../../../../gql/graphql'
import useConditionClient from '../UseConditionClient'

const getAreaHealthDistributionWithVariablesQueryDocument = graphql(`
  query AreaHealthDistribution($id: ID, $statuses: [MachineStatuses!]) {
    areaHealthDistribution(id: $id, statuses: $statuses) {
      count
      title
    }
  }
`)

interface Props {
  id?: string | null
  statuses?: MachineStatuses[] | null
}

const useAreaHealthDistribution = ({id = null, statuses = null}: Props = {}) => {
  const client = useConditionClient()
  return useQuery({
    queryKey: ['condition.AreaHealthDistribution', id, ...(statuses ?? [])],
    queryFn: async () =>
      (
        await client.request(getAreaHealthDistributionWithVariablesQueryDocument, {
          id,
          statuses,
        })
      ).areaHealthDistribution,
  })
}

export default useAreaHealthDistribution
