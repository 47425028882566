import {createContext, useContext, useEffect, useState} from 'react'

export type DisplayModeType = 'energy' | 'oee' | 'condition'
export const DISPLAY_MODE_KEY = 'hs_display_mode_value'
export const DEFAULT_DISPLAY_MODE: DisplayModeType = 'oee'

type DisplayModeContextType = {
  displayMode: DisplayModeType
  updateDisplayMode: (_mode: DisplayModeType) => void
}

const getDisplayModeFromLocalStorage = (key: string): DisplayModeType => {
  if (!localStorage) {
    return DEFAULT_DISPLAY_MODE
  }

  const data = localStorage.getItem(key)
  if (data === 'energy' || data === 'oee' || data === 'condition') {
    return data
  }

  return DEFAULT_DISPLAY_MODE
}

const defaultDisplayMode: DisplayModeContextType = {
  displayMode: getDisplayModeFromLocalStorage(DISPLAY_MODE_KEY),
  updateDisplayMode: (_mode: DisplayModeType) => {},
}

const DisplayModeContext = createContext<DisplayModeContextType>({
  displayMode: defaultDisplayMode.displayMode,
  updateDisplayMode: (_mode: DisplayModeType) => {},
})

const useDisplayMode = () => useContext(DisplayModeContext)

const DisplayModeProvider = ({children}: {children: React.ReactNode}) => {
  const [displayMode, setDisplayMode] = useState<DisplayModeType>(defaultDisplayMode.displayMode)

  const updateDisplayMode = (_mode: DisplayModeType) => {
    setDisplayMode(_mode)
    localStorage && localStorage.setItem(DISPLAY_MODE_KEY, _mode)
  }

  useEffect(() => {
    updateDisplayMode(displayMode)
  }, [])

  return (
    <DisplayModeContext.Provider value={{displayMode, updateDisplayMode}}>
      {children}
    </DisplayModeContext.Provider>
  )
}

export {DisplayModeProvider, useDisplayMode}
