import {Channel} from 'pusher-js'
import React from 'react'
import {toast, ToastContainer} from 'react-toastify'
import {ToastOptions} from 'react-toastify/dist/types'
import {EntityGroup} from '../core/_models'
import {useAuth} from '../modules/auth'

import Pusher from 'pusher-js/with-encryption'

import 'react-toastify/dist/ReactToastify.css'

const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY as string

interface PushNotificationMessage {
  entity: string
  entity_group: EntityGroup
  level: number
  message: string
  require_click: boolean
  timeout: number
  title: string
  type: string
}

interface PushNotificationsContextProps {}

const PushNotificationsContext = React.createContext<PushNotificationsContextProps>({})

const PushNotificationsProvider = ({children}: {children: React.ReactNode}) => {
  const {currentProfile} = useAuth()

  const messageHandler = (msg: PushNotificationMessage) => {
    const payload = {
      content: msg.message,
      options: {
        autoClose: msg.require_click ? false : msg.timeout || 5000,
      } as ToastOptions<{}> | undefined,
    }

    switch (msg.level) {
      case 0:
        toast.success(payload.content, payload.options)
        break
      case 1:
        toast.info(payload.content, payload.options)
        break
      case 2:
        toast.warn(payload.content, payload.options)
        break
      case 3:
        toast.error(payload.content, payload.options)
        break
      default:
        console.warn('Unknown level', msg)
        break
    }
  }

  const channel = React.useRef<Channel>()
  React.useEffect(() => {
    if (currentProfile && currentProfile.email) {
      const pusher = new Pusher(PUSHER_KEY, {
        cluster: 'eu',
      })
      channel.current = pusher.subscribe(currentProfile.email)
      channel.current.bind('notification', messageHandler)
    }
    return () => {
      if (channel.current !== undefined) {
        channel.current.unbind('notification', messageHandler)
      }
    }
  }, [currentProfile, currentProfile?.email])

  return (
    <>
      <ToastContainer />
      <PushNotificationsContext.Provider value={{}}>{children}</PushNotificationsContext.Provider>
    </>
  )
}

export {PushNotificationsProvider}
