import React from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'

interface Props {
  show: boolean
  setShow: (state: boolean) => void
  form: React.ReactNode
}

const SetTimeRangeModal = ({show, setShow, form}: Props) => (
  <Modal
    show={show}
    tabIndex={-1}
    aria-hidden='true'
    dialogClassName='modal-dialog modal-dialog-centered'
    onHide={() => setShow(false)}
    backdrop={true}
  >
    <div className='modal-header'>
      <h2>Set Time Range</h2>
      <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => setShow(false)}>
        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
      </div>
    </div>
    <div className='modal-body py-lg-10 px-lg-10'>{form}</div>
  </Modal>
)

export default SetTimeRangeModal
