import {WidgetType} from '../../widgets/_models'
import {StepProps} from './_models'
import StepScopeType from './StepScopeType'

const Step1 = (props: StepProps) => {
  const displayByProduct =
    (['Energy Consumption', 'Standby Consumption', 'CO2 Emissions'] as WidgetType[]).indexOf(
      props.data.type as WidgetType
    ) > -1

  const displayDisclaimer = props.data.type === 'CO2 Emissions' && props.current
  const disclaimer = (
    <div className={`my-5`}>
      Disclaimer: Data is retrieved from{' '}
      <a href='https://www.energidataservice.dk/' target='_top'>
        https://www.energidataservice.dk/
      </a>{' '}
      delivered by Energinet
    </div>
  )
  return (
    <>
      <StepScopeType {...props} displayByProduct={displayByProduct} />
      {displayDisclaimer ? disclaimer : null}
    </>
  )
}

export default Step1
