import {useState} from 'react'
import {useAssets, useProductionLines} from '../../../core/requests/factory'
import Select from 'react-select'
import {useEventCategories, useEventCategoryGroups} from '../../../core/requests/oee'

type UseEventLogsFilterProps = {
  entity?: string
  categories?: string[]
}

const useEventLogsFilter = (props: UseEventLogsFilterProps = {}) => {
  const [entity, setEntity] = useState<string | null>(props.entity || null)
  const [categories, setCategories] = useState<string[]>(props.categories || [])

  const {data: productionLines, isLoading: isProductionLinesLoading} = useProductionLines()
  const {data: assets, isLoading: isAssetsLoading} = useAssets()

  const {data: eventCategoryGroups, isLoading: isEventCategoryGroupsLoading} =
    useEventCategoryGroups({
      entities: entity ? [entity] : undefined,
      options: {enabled: entity !== null},
    })
  const {data: globalEventCategoryGroup, isLoading: isGlobalEventCategoryGroupLoading} =
    useEventCategoryGroups({
      isActive: true,
      options: {enabled: entity === null || eventCategoryGroups?.items.length === 0},
    })
  const categoryGroupToUse =
    eventCategoryGroups?.items.at(0) || globalEventCategoryGroup?.items.at(0)

  const {data: eventCategories, isLoading: isEventCategoriesLoading} = useEventCategories()
  const filteredEventCategories = eventCategories?.items.filter((category) =>
    category.event_category_groups.includes(categoryGroupToUse?._id || '')
  )

  const categoriesSelect = (
    <Select
      placeholder='Categories'
      isMulti
      options={filteredEventCategories?.map((category) => ({
        value: category._id,
        label: category.title,
      }))}
      value={
        eventCategories?.items
          .filter((category) => categories.includes(category._id))
          .map((category) => ({
            value: category._id,
            label: category.title,
          })) || []
      }
      onChange={(e) => setCategories(e.map((category) => category.value) || [])}
      isLoading={
        isEventCategoryGroupsLoading ||
        isGlobalEventCategoryGroupLoading ||
        isEventCategoriesLoading
      }
    />
  )

  const entityOptions = [...(productionLines?.items || []), ...(assets?.items || [])]
  const entityValue = entityOptions.find((option) => option._id === entity)

  const entitySelect = (
    <Select
      placeholder='Entity'
      options={entityOptions.map((entity) => ({
        value: entity._id,
        label: entity.name,
      }))}
      value={entityValue ? {value: entityValue._id, label: entityValue.name} : null}
      onChange={(e) => {
        setEntity(e?.value || null)
        setCategories([])
      }}
      isLoading={isProductionLinesLoading || isAssetsLoading}
      className='me-3'
      isClearable
    />
  )

  return {
    entity,
    entitySelect,
    categories,
    categoriesSelect,
  }
}

export default useEventLogsFilter
