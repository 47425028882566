import React, {ReactNode} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

interface TreeNodeProps {
  name: string
  icon?: string
  iconPath?: string
  opened: boolean
  isExpandable: boolean
  setOpen: (state: boolean) => void
  nodes?: ReactNode[]
}

const TreeNode = ({name, icon, iconPath, opened, isExpandable, setOpen, nodes}: TreeNodeProps) => {
  return (
    <div className={`tree-node tree-node-${opened ? 'opened' : 'closed'}`}>
      {isExpandable ? (
        <i
          onClick={() => setOpen(!opened)}
          className={`tree-node-toggle fs fa-regular fa-${
            !opened ? 'plus-square' : 'minus-square'
          } cursor-pointer`}
        />
      ) : null}
      {iconPath ? (
        <KTSVG path={iconPath} className='svg-icon-2 pe-2 cursor-pointer' />
      ) : (
        <i className={`fs-3 fa-solid ${icon} pe-2 cursor-pointer`} />
      )}
      {name}
      {opened && nodes}
    </div>
  )
}

export default TreeNode
