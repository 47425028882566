import Select from 'react-select'
import {PartType, partTypes} from '../../../../core/_models'

type PartTypeSelectProps = {
  onChange: (partType?: PartType) => void
}

const PartTypeSelect = ({onChange}: PartTypeSelectProps) => (
  <Select
    placeholder={`Part Type`}
    onChange={(e) => onChange(e?.value)}
    options={
      partTypes.map((p) => ({
        value: p,
        label: p,
      })) || []
    }
    isClearable
    className={'me-3'}
  />
)

export default PartTypeSelect
