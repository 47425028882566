import {useEffect, useRef, useState} from 'react'
import Select, {MultiValue} from 'react-select'
import {useSensorsPaginated} from '../../../core/requests/factory'
import {sensorToString} from '../../../core/name-util'

type Props = {
  initial?: string[]
  name?: string
  onchange: (values: string[]) => void
  errorOccurred?: boolean
  className?: string
}

const SensorsSelectField = ({
  initial = [],
  name = 'Sensors',
  onchange,
  errorOccurred,
  className,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([])
  const initialOptionsSet = useRef(false)

  const {items: sensors, isFetching, fetchNextPage} = useSensorsPaginated()

  useEffect(() => {
    if (!sensors || !initial.length || initialOptionsSet.current) return
    const initialOptions = initial.map((id) => {
      const sensor = sensors.find((sensor) => sensor._id === id)
      return {
        value: id,
        label: sensor ? sensorToString(sensor) : 'Unknown sensor',
      }
    })
    setSelectedOptions(initialOptions)
    initialOptionsSet.current = true
  }, [sensors, initial])

  const handleChange = (selected: MultiValue<OptionType>) => {
    const values = selected.map((option) => option.value)
    onchange(values)
    setSelectedOptions(selected)
  }

  const error = errorOccurred && !selectedOptions.length

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={selectedOptions}
        options={
          sensors?.map((sensor) => ({
            label: sensorToString(sensor),
            value: sensor._id,
          })) || []
        }
        onChange={handleChange}
        isLoading={isFetching}
        isDisabled={isFetching}
        isMulti
        isClearable
        onMenuScrollToBottom={() => fetchNextPage()}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? 'red' : baseStyles.borderColor,
          }),
        }}
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}
    </>
  )
}

type OptionType = {
  value: string
  label: string
}

export default SensorsSelectField
