import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'
import {StepProps} from './_models'

const Step2 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxes
          name='Where is the PLC located?'
          optionNames={['Area', 'Production Line', 'Section', 'Asset']}
          optionValues={['area', 'production_line', 'section', 'asset']}
          colSpace={6}
          data={data}
          updateData={updateData}
          dataField='locationChoice'
          updatesOnChange={{
            area: undefined,
            production_line: undefined,
            section: undefined,
            asset: undefined,
          }}
          hasError={hasError}
          required
          lastField
        />
      </div>
    </div>
  )
}

export default Step2
