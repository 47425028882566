import clsx from 'clsx'
import LoadingWrapper from '../../../../../../components/LoadingWrapper'
import SelectBoxes from '../../../../../../components/form-components/SelectBoxes'
import {useEventCategories, useEventCategoryGroups} from '../../../../../../core/requests/oee'
import {StepProps} from '../_models'
import {useEffect} from 'react'
import {EventCategory, SubEventCategory} from '../../../../../../core/_models'

const StopRegistrationTypeSelectionStep = ({data, updateData, hasError}: StepProps) => {
  const {data: eventCategoryGroups, isLoading: isEventCategoryGroupsLoading} =
    useEventCategoryGroups({
      entities: data.entity ? [data.entity] : [],
      options: {enabled: data.entity !== undefined},
    })
  const {data: activeCategoryGroups, isLoading: activeCategoryGroupsIsLoading} =
    useEventCategoryGroups({
      isActive: true,
      options: {enabled: eventCategoryGroups?.items.length === 0},
    })

  const activeGroup = eventCategoryGroups?.items.at(0) || activeCategoryGroups?.items.at(0)

  const {data: eventCategories, isLoading: isEventCategoriesLoading} = useEventCategories({
    eventCategoryGroups: activeGroup ? [activeGroup._id] : [],
    options: {enabled: activeGroup !== undefined},
  })

  const loading =
    isEventCategoryGroupsLoading || activeCategoryGroupsIsLoading || isEventCategoriesLoading

  const onExpand = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    category: EventCategory | SubEventCategory
  ) => {
    e.stopPropagation()
    updateData({
      expandedCategoryTrace: [...data.expandedCategoryTrace, category],
      operational_event_categories: [],
      selectedCategory: undefined,
    })
  }

  const onContract = () => {
    updateData({
      expandedCategoryTrace: data.expandedCategoryTrace.slice(0, -1),
      operational_event_categories: [],
      selectedCategory: undefined,
    })
  }

  const onCategorySelect = (category: EventCategory | SubEventCategory) => {
    updateData({
      operational_event_categories: [...data.expandedCategoryTrace, category].map((c) => c._id!),
      selectedCategory: category,
    })
  }

  const reset = () => {
    updateData({
      operational_event_categories: [],
      selectedCategory: undefined,
      expandedCategoryTrace: [],
    })
  }

  useEffect(() => {
    reset()
  }, [data.entity])

  return (
    <div data-kt-stepper-element='content'>
      <LoadingWrapper loading={loading}>
        <div className='w-100'>
          <div className='mb-10'>
            <h2 className='fw-bold d-flex align-items-center text-dark'>Configure Your Widget</h2>
            <div className='text-muted fw-semibold fs-6'>
              Customize how your widget registers events. Choose between a versatile registration
              process or a pre-configured quick log for frequent events.
            </div>
          </div>

          <SelectBoxes
            required
            optionNames={['General Event Registration', 'Pre-configured Event Registration']}
            optionDescriptions={[
              'Select this option for flexibility in logging different types of events. Each time you register an event, you’ll choose the relevant category',
              'Choose this for speed in logging a specific type of event. Configure your widget now to bypass category selection for future registrations',
            ]}
            optionValues={['General Event Registration', 'Pre-configured Event Registration']}
            data={data}
            dataField='eventRegistrationType'
            updateData={updateData}
            updatesOnChange={{
              operational_event_categories: [],
              selectedCategory: undefined,
              expandedCategoryTrace: [],
            }}
            hasError={hasError}
          />

          {data.eventRegistrationType === 'Pre-configured Event Registration' && (
            <>
              <div className='fs-5 fw-semibold mb-2'>
                <span>Choose an Event Category</span>
              </div>
              {data.expandedCategoryTrace.length > 0 && (
                <div className='d-flex'>
                  <i
                    className='fa-solid fa-reply fs-2 cursor-pointer mb-2 me-2'
                    onClick={onContract}
                  />
                  <div className='fst-italic fw-normal'>
                    {`Showing: ${data.expandedCategoryTrace
                      .map((category) => category.title)
                      .join(' > ')}`}
                  </div>
                </div>
              )}
              <div className='row'>
                {(
                  data.expandedCategoryTrace.at(-1)?.sub_event_categories || eventCategories?.items
                )?.map((eventCategory) => (
                  <div className={`col-6 mb-2`} key={eventCategory._id}>
                    <div
                      className={clsx(
                        'btn btn-outline btn-outline-dashed p-5 d-flex align-items-center h-100',
                        data.selectedCategory?._id === eventCategory._id && 'active'
                      )}
                      onClick={() => onCategorySelect(eventCategory)}
                    >
                      <div
                        className='me-2 shadow'
                        style={{
                          backgroundColor: eventCategory.color,
                          width: '1em',
                          height: '1em',
                          borderRadius: '0.25em',
                        }}
                      />
                      <span className='text-dark fw-bold d-block me-1'>{eventCategory.title}</span>
                      <i className={eventCategory.icon} />
                      {eventCategory.sub_event_categories.length > 0 && (
                        <button
                          className='btn btn-outline-secondary ms-auto p-2'
                          onClick={(e) => onExpand(e, eventCategory)}
                        >
                          <i
                            className='fa-solid fa-arrow-right-long fs-3'
                            style={{color: 'rgb(161, 165, 183)'}}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {hasError &&
                data.eventRegistrationType === 'Pre-configured Event Registration' &&
                (data.operational_event_categories === undefined ||
                  data.operational_event_categories.length === 0) && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>This field is required!</div>
                  </div>
                )}
            </>
          )}
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default StopRegistrationTypeSelectionStep
