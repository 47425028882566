import {
  EntityGroup,
  Layout,
  OperationalState,
  Timespan,
  WorkOrderCategory,
  WorkOrderStatus,
} from '../../../../core/_models'

export const widgetTypes = [
  'Pareto',
  'Pie',
  'Line',
  // 'Bar',
  'OEE',
  'OEE Performance',
  'OEE Availability',
  'OEE Quality',
  'Timeline',
  'Stop Registration',
  'Operational State',
  'Operational Stats',
  'Production Stats',
  'Power Factor',
  'Power Consumption',
  'Energy Consumption',
  'Standby Consumption',
  'Potential Savings',
  'CO2 Emissions',
  'Text',
  'Health',
  'Anomaly',
  'Work Orders',
  'Energy Piechart',
  'Event Logs',
  'Tag',
] as const
export type WidgetType = typeof widgetTypes[number]
export const widgetDataTypes = [
  // SENSOR
  'RMS X',
  'RMS Y',
  'RMS Z',
  'Temperature',
  'Output',
  'H2S',
  // OEE
  'OEE',
  'OEE Performance',
  'OEE Availability',
  'OEE Quality',
  'Operational Events',
  'Product Rate',
  'Stop Causes',
  // ENERGY
  'Power Factor',
  'Power Consumption',
  'Power Consumption Column',
  'Energy Consumption',
  'Standby Consumption',
  'Potential Savings',
  'CO2 Emissions',
  // CONDITION
  'Simple',
  'Advanced',
  // Energy
  'Ampere', // kilowatt
  'Phase 1', // ampere
  'Phase 2', // ampere
  'Phase 3', // ampere
  'Average', // ampere
]
export type WidgetDataType = typeof widgetDataTypes[number]

export const dataTypeUnitMapping: {[dataType in WidgetDataType]: string | undefined} = {
  // SENSOR
  'rms x': 'mm/s',
  'rms y': 'mm/s',
  'rms z': 'mm/s',
  temperature: '°C',
  output: 'units',
  ampere: 'A',
  h2s: 'ppm',
  // ENERGY
  'power factor': '%',
  'power consumption': 'kW',
  'energy consumption': 'kWh',
  'standby consumption': 'kWh',
  'potential savings': 'DKK', // TODO: check if currency comes from backend
  'co2 emissions': 'g/kWh',
}

export type IWidget = {
  _id?: string
  dashboard?: string
  name?: string
  type?: WidgetType
  entity_group?: EntityGroup
  entity?: string
  entities: string[]
  data_types?: WidgetDataType[]
  operational_event_categories?: string[]
  dynamic_time_range?: string
  start_time?: string
  end_time?: string
  time_interval?: string
  layout?: Layout
  timespan?: Timespan
  multiplier?: number
  by_product?: boolean
  quantity?: number
  category?: WorkOrderCategory
  status?: WorkOrderStatus
  operational_states?: OperationalState[]
  operational_state_intervals?: [
    [number, number | null], // running
    [number, number | null], // standby
    [number, number | null] // shutdown
  ]
}

export const defaultCreateWidget: IWidget = {
  dashboard: undefined,
  name: undefined,
  type: undefined,
  entity_group: undefined,
  entities: [],
  start_time: undefined,
  end_time: undefined,
  time_interval: undefined,
  layout: undefined,
  data_types: undefined,
  operational_event_categories: undefined,
}

export const defaultLayout: Layout = {
  i: '',
  x: 0,
  y: 0,
  w: 4,
  h: 4,
}

const widgetDisplayNames: Partial<{[key in WidgetType]: string}> = {
  'OEE Availability': 'Availability',
  'OEE Performance': 'Performance',
  'OEE Quality': 'Quality',
  'CO2 Emissions': 'Estimated CO2 Emissions',
}

export const getDisplayName = (type: WidgetType) => widgetDisplayNames[type] || type

const widgetDisplayDescriptions: Partial<{[key in WidgetType]: string}> = {
  'OEE Availability': 'See the availability of a machine',
  'OEE Performance': 'See the performance of a machine',
  'OEE Quality': 'See the production quality of a machine',
  OEE: 'See the total OEE of a machine',
  Timeline: 'See a timeline of operational events of a machine',
  Pareto:
    'See a distribution of stop causes of a machine with the ability to drill down into each sub-cause',
  'Stop Registration': 'Register start & stop events on your machine with this widget',
  'Operational State': 'Indicates the current operational status of a machine',
  'Operational Stats': 'Indicates the current operational stats of a machine',
  'Production Stats': 'Indicates the current production stats of a machine',
  'Power Factor': 'See the raw power factor data on a machine',
  'Power Consumption': 'See the power consumption on a machine',
  'Energy Consumption': 'See the energy consumption on a machine',
  'Standby Consumption': 'See the standby energy consumption on a machine',
  'Potential Savings':
    'See the savings that can potentially be achieved for a machine based on its standby energy consumption',
  'CO2 Emissions':
    'See the estimated CO2 emissions for a machine based on its standby energy consumption',
  Health: 'See the health of a machine',
  Anomaly: 'See if any anomalies has been detected in the data of a machine',
  Line: 'Visualize data in a line chart',
  Pie: 'Visualize data in a pie chart',
  'Work Orders': ' ',
  Tag: 'View and quick edit the value of a specific tag',
}

export const getDisplayDescription = (type: WidgetType) =>
  widgetDisplayDescriptions[type] || `${type} chart`
