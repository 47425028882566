import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'
import {useState} from 'react'
import LoadingWrapper from '../LoadingWrapper'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string

const DENMARK_CENTER = {lat: 55.676098, lng: 12.568337}

interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  width?: string
  height?: string
}

const LocationField = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
  width = '100%',
  height = '400px',
}: Props<DataType>) => {
  const {isLoaded} = useJsApiLoader({googleMapsApiKey: GOOGLE_MAPS_API_KEY})
  const [center, setCenter] = useState(DENMARK_CENTER)

  const onClick = (e: google.maps.MapMouseEvent) => {
    const lat = e.latLng?.lat()
    const lng = e.latLng?.lng()

    if (lat !== undefined && lng !== undefined)
      updateData({[dataField]: {type: 'Point', coordinates: [lng, lat]}} as Partial<DataType>)
  }

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>
        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>
      <LoadingWrapper loading={!isLoaded}>
        <GoogleMap mapContainerStyle={{width, height}} center={center} zoom={6} onClick={onClick}>
          {data[dataField] && (
            <Marker
              position={{lng: data[dataField].coordinates[0], lat: data[dataField].coordinates[1]}}
            />
          )}
        </GoogleMap>
      </LoadingWrapper>
      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export default LocationField
