import {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {axes, Axis} from '../../core/_models'
import {StepProps} from './_models'
import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'
import SelectField from '../../components/form-components/SelectField'

const Step4 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* Dot-based Mounting */}
        {data.mountingChoice === 'dot-based' && (
          <SelectFieldBoxes
            name='Choose Axis Alignment'
            optionNames={['Vertical', 'Horizontal']}
            optionValues={[true, false]}
            data={data}
            updateData={updateData}
            dataField='is_vertical'
            lastField
            hasError={hasError}
            required
            colSpace={6}
          />
        )}

        {/* Manual Mounting */}
        {data.mountingChoice === 'manual' && (
          <>
            {/* X */}
            <SelectField
              name='X Axis'
              optionNames={axes.filter((axis) => axis !== data.y && axis !== data.z)}
              optionValues={axes.filter((axis) => axis !== data.y && axis !== data.z)}
              data={data}
              updateData={updateData}
              dataField='x'
              required
              hasError={hasError}
            />

            {/* Y */}
            <SelectField
              name='Y Axis'
              optionNames={axes.filter((axis) => axis !== data.x && axis !== data.z)}
              optionValues={axes.filter((axis) => axis !== data.x && axis !== data.z)}
              data={data}
              updateData={updateData}
              dataField='y'
              required
              hasError={hasError}
            />

            {/* Z */}
            <SelectField
              name='Z Axis'
              optionNames={axes.filter((axis) => axis !== data.x && axis !== data.y)}
              optionValues={axes.filter((axis) => axis !== data.x && axis !== data.y)}
              data={data}
              updateData={updateData}
              dataField='z'
              required
              hasError={hasError}
              lastField
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Step4
