import React from 'react'
import DateTimeField from '../../components/form-components/DateTimeField'
import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'
import TimeIntervalField from '../../components/form-components/TimeIntervalField'
import {IWidget} from '../../pages/dashboard/dashboard-grid/widgets/_models'

export type WidgetTimeRange = Pick<IWidget, 'start_time' | 'end_time' | 'dynamic_time_range'>

type TimeFrame = 'dateRange' | 'interval'

interface Props {
  onSubmit: (data: WidgetTimeRange) => void
  onCancel: () => void
}

const SetTimeRangeForm = ({onSubmit, onCancel}: Props) => {
  const [timeFrame, setTimeFrame] = React.useState<TimeFrame>('dateRange')
  const [formData, setFormData] = React.useState<WidgetTimeRange>({})

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(formData)
      }}
    >
      <div className='row'>
        <SelectFieldBoxes
          name='Time Frame'
          optionNames={['Custom Date Range', 'Predefined Interval']}
          optionValues={['dateRange', 'interval']}
          data={{
            timeFrame,
          }}
          updateData={(fieldsToUpdate) => setTimeFrame(fieldsToUpdate['timeFrame'] || 'dateRange')}
          dataField='timeFrame'
          required
          colSpace={12}
        />
        {timeFrame === 'dateRange' && (
          <>
            <div className='col-6'>
              <DateTimeField
                name='Start Time'
                data={formData}
                updateData={(data) =>
                  setFormData({
                    ...formData,
                    dynamic_time_range: undefined,
                    start_time: data.start_time
                      ? new Date(data.start_time).toISOString()
                      : undefined,
                  })
                }
                dataField='start_time'
                id='create-widget-start-time'
              />
            </div>

            <div className='col-6'>
              <DateTimeField
                name='End Time'
                data={formData}
                updateData={(data) =>
                  setFormData({
                    ...formData,
                    dynamic_time_range: undefined,
                    end_time: data.end_time ? new Date(data.end_time).toISOString() : undefined,
                  })
                }
                dataField='end_time'
                id='create-widget-end-time'
              />
            </div>
          </>
        )}
        {timeFrame === 'interval' && (
          <TimeIntervalField
            name='Interval'
            data={formData}
            updateData={(fieldsToUpdate) =>
              setFormData({
                start_time: undefined,
                end_time: undefined,
                ...fieldsToUpdate,
              })
            }
            dataField='dynamic_time_range'
            required
          />
        )}
      </div>
      <div className={`d-flex justify-content-between`}>
        <button type={`button`} className={'btn btn-secondary'} onClick={onCancel}>
          Cancel
        </button>
        <button className={'btn btn-primary'}>Submit</button>
      </div>
    </form>
  )
}

export default SetTimeRangeForm
