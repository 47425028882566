import LoadingWrapper from '../../../components/LoadingWrapper'
import DateTimeField from '../../../components/form-components/DateTimeField'
import MultiSelectFieldSymbols from '../../../components/form-components/MultiSelectFieldSymbols'
import MultiTextField from '../../../components/form-components/MultiTextField'
import TextAreaField from '../../../components/form-components/TextAreaField'
import TextField from '../../../components/form-components/TextField'
import {useProfile, useUsers} from '../../../core/requests/auth'
import {IWorkOrder, StepProps} from './_models'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const {data: profile} = useProfile()
  const {data: users} = useUsers({
    organization: profile?.organization?._id,
    options: {
      enabled: !!profile?.organization,
    },
  })

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={users === undefined}>
        <div className='w-100'>
          <div className='row justify-content-between'>
            <div className='col'>
              <TextField
                name='Name'
                data={data}
                updateData={updateData}
                dataField='title'
                hasError={hasError}
                required
              />
            </div>
            <div className='col-md-auto'>
              <MultiSelectFieldSymbols
                name='Assignees'
                optionNames={users?.items?.map((user) => user.email) || []}
                optionValues={users?.items?.map((user) => user.email) || []}
                data={data}
                updateData={updateData}
                dataField='assignees'
              />
            </div>
          </div>

          <TextAreaField
            name='Description'
            data={data}
            updateData={updateData}
            dataField='description'
            placeholder='Add a description'
          />

          <div className='row'>
            <div className='col-6'>
              <DateTimeField
                name='Planned Start Time'
                data={data}
                updateData={updateData}
                dataField='planned_start_time'
                id='create_work_order_planned_start_time'
                hasError={hasError}
                required
              />
            </div>
            <div className='col-6'>
              <DateTimeField
                name='Expected End Time'
                data={data}
                updateData={updateData}
                dataField='expected_end_time'
                id='create_work_order_expected_end_time'
                customErrorFunction={expectedEndTimeIsAtLeastOneMinuteAfterPlannedStartTime}
                hasError={hasError}
                required
              />
            </div>
          </div>

          <MultiTextField
            name='Actions'
            data={data}
            updateData={updateData}
            dataField='actions'
            lastField
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}

export const expectedEndTimeIsAtLeastOneMinuteAfterPlannedStartTime = (
  data: IWorkOrder
): string | undefined => {
  if (data.planned_start_time === undefined || data.expected_end_time === undefined)
    return undefined

  const plannedStartTime = new Date(data.planned_start_time)
  const expectedEndTime = new Date(data.expected_end_time)
  const oneMinute = 60 * 1000
  return expectedEndTime > plannedStartTime &&
    expectedEndTime.getTime() - plannedStartTime.getTime() >= oneMinute
    ? undefined
    : 'Expected End Time must be at least one minute after Planned Start Time'
}

export default Step1
