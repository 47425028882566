import {FC, useMemo} from 'react'
import {useSelectedRows} from '../../../../providers/SelectedRowsProvider'

type Props = {
  id: string
  tableId: string
}

const SelectionCell: FC<Props> = ({id, tableId}) => {
  const {selected, onSelect} = useSelectedRows()
  const isSelected = useMemo(() => selected.includes(id), [id, selected])
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target={`#${tableId} .form-check-input`}
        checked={isSelected}
        onChange={() => onSelect(id)}
      />
    </div>
  )
}

export {SelectionCell}
