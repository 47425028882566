import * as am5 from '@amcharts/amcharts5'
import {useEffect, useRef} from 'react'
import {ChartLoader} from './ChartLoader'

type Props = {
  height: string
  data?: any
  chartId: string
  configureGraph: (chartId: string, data: any) => am5.Root
  noData?: boolean
  loading?: boolean
}

const GraphBare = ({
  height,
  data,
  chartId,
  configureGraph,
  noData = false,
  loading = false,
}: Props) => {
  const chartRef = useRef<am5.Root | null>(null)

  useEffect(() => {
    if (data === undefined || loading || noData) return
    chartRef.current = configureGraph(chartId, data)
    return () => {
      if (chartRef.current) chartRef.current.dispose()
    }
  }, [data, loading, noData, chartId, configureGraph])

  return (
    <>
      {noData ? (
        <div style={{height: height}}>
          <h1 className='fw-semibold text-gray-800 text-center lh-lg'>No data yet</h1>
        </div>
      ) : (
        <ChartLoader loading={!data || loading} chartId={chartId} height={height} />
      )}
    </>
  )
}
export {GraphBare}
