import {useState} from 'react'

type Props = {
  initial?: string[]
  name?: string
  placeholder?: string
  onchange: (values: string[]) => void
  buttonText?: string
  className?: string
}

const MultiTextInputField = ({
  initial = [],
  name,
  placeholder,
  onchange,
  buttonText = '+',
  className,
}: Props) => {
  const [values, setValues] = useState(initial)

  const handleChange = (index: number, value: string) => {
    const newValues = [...values]
    newValues[index] = value
    setValues(newValues)
    onchange(newValues)
  }

  const addField = () => setValues([...values, ''])

  const removeField = (index: number) => {
    const newValues = values.filter((_, i) => i !== index)
    setValues(newValues)
    onchange(newValues)
  }

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>

      {values.map((value, index) => (
        <div key={index} className='d-flex align-items-center pb-1'>
          <input
            type='text'
            placeholder={placeholder}
            className='form-control fw-normal text-dark p-3 me-2'
            style={{height: '38px'}}
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
          />
          <i
            className='fa-solid fa-trash fs-5 cursor-pointer px-2 text-danger'
            onClick={() => removeField(index)}
          />
        </div>
      ))}

      <div className='d-flex justify-content-end pt-1'>
        <button type='button' className='btn btn-secondary btn-sm px-2 py-1' onClick={addField}>
          {buttonText}
        </button>
      </div>
    </>
  )
}

export default MultiTextInputField
