import {formatDistance} from 'date-fns'
import _ from 'lodash'
import {Health, MachineStatuses} from '../../gql/graphql'

const getAssetStatus = (health: Health) => {
  const title = _.capitalize(health.title)
  const distance = health.lastStateChangeAt
    ? formatDistance(new Date(health.lastStateChangeAt * 1000), new Date(), {addSuffix: true})
    : null
  const status =
    health.lastStateChangeAt && health.title !== MachineStatuses.Offline
      ? `${title} since ${distance}`
      : title
  return status
}

export {getAssetStatus}
