import {Batch, WorkOrder} from '../../core/_models'
import {ProductionLineStartStopCell} from '../overview/production-lines/ProductionLineStartStopCell'
import {WorkOrderStartModal} from './WorkOrderStartModal'
import {WorkOrderCompleteModal} from './WorkOrderCompleteModal'
import BatchStartModal from './BatchStartModal'
import useModalState from '../../hooks/UseModalState'
import BatchCompleteModal from './BatchCompleteModal'

type Props = {
  item: Batch | WorkOrder
  type: 'batch' | 'workOrder'
  otherItemRunning: boolean
}

const ActionButtonCell = ({item, type, otherItemRunning}: Props) => {
  const modal = useModalState()

  if (item.status === 'done') return <></>

  if (item.status === 'pending') {
    return (
      <>
        <div className='d-flex align-items-end'>
          <button
            type='button'
            className='btn btn-lg btn-primary'
            onClick={modal.open}
            disabled={otherItemRunning}
          >
            Start
          </button>
        </div>

        {type === 'batch' && (
          <BatchStartModal show={modal.isOpen} handleClose={modal.close} batchId={item._id} />
        )}
        {type === 'workOrder' && (
          <WorkOrderStartModal
            show={modal.isOpen}
            handleClose={modal.close}
            workOrderId={item._id}
          />
        )}
      </>
    )
  }

  return (
    <>
      {type === 'batch' && (
        <BatchCompleteModal show={modal.isOpen} handleClose={modal.close} batchId={item._id} />
      )}
      {type === 'workOrder' && (
        <>
          <button type='button' className='btn btn-lg btn-primary' onClick={modal.open}>
            Complete
          </button>
          <WorkOrderCompleteModal
            show={modal.isOpen}
            handleClose={modal.close}
            workOrder={item as WorkOrder}
          />
        </>
      )}
    </>
  )
}

export {ActionButtonCell}
