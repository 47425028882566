import * as am5 from '@amcharts/amcharts5'
import {useEffect, useRef} from 'react'
import {ChartLoader} from './ChartLoader'

type Props = {
  title: string
  height: string
  data: any
  loading: boolean
  chartId: string
  configureGraph: (chartId: string, data: any) => am5.Root
  noData?: boolean
}

const GraphCard = ({
  title,
  height,
  data,
  loading,
  chartId,
  configureGraph,
  noData = false,
}: Props) => {
  const chartRef = useRef<am5.Root | null>(null)

  useEffect(() => {
    if (chartRef.current) chartRef.current.dispose()
    if (data === undefined || loading || noData) return
    chartRef.current = configureGraph(chartId, data)
  }, [data, loading, noData])

  useEffect(() => {
    return () => {
      if (chartRef.current) chartRef.current.dispose()
    }
  }, [])

  return (
    <div className='card card-flush overflow-hidden h-lg-100'>
      <div className='card-header justify-content-center mb-n10'>
        <h3 className='card-title align-items-center flex-column'>
          <span className='fw-bold text-dark fs-3 text-center'>{title}</span>
        </h3>
      </div>
      <div className='card-body p-0'>
        {noData ? (
          <div style={{height: height}}>
            <h1 className='fw-semibold text-gray-800 text-center lh-lg'>No data yet</h1>
          </div>
        ) : (
          <ChartLoader loading={loading} chartId={chartId} height={height} />
        )}
      </div>
    </div>
  )
}
export {GraphCard}
