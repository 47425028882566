import {MachineStatuses} from '../../../gql/graphql'

type AssetStateProps = {
  state: MachineStatuses
  className?: string
}

const AssetStateBadge = ({state, className}: AssetStateProps) => (
  <div className={`badge bg-state-${state.toLowerCase()} ${className}`}>{state}</div>
)

export default AssetStateBadge
