import React from 'react'
import {Modal} from 'react-bootstrap'
import WorkOrderDetails from '../views/WorkOrderDetails'

type Props = {
  show: boolean
  hide: () => void
  workOrderId: string
}

const ViewWorkOrderModal = ({show, hide, workOrderId}: Props) => (
  <Modal
    id='hs_view_work_order_modal'
    tabIndex={-1}
    aria-hidden='true'
    dialogClassName='modal-dialog modal-dialog-centered'
    size='lg'
    show={show}
    onHide={hide}
    container={document.getElementById('root-modals')}
  >
    <WorkOrderDetails workOrderId={workOrderId} modalCallback={hide} />
  </Modal>
)

export default ViewWorkOrderModal
