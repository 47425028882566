import {useEventCategoryGroups} from '../../../core/requests/oee'
import AddEventCategorySidebarItem from './AddEventCategorySidebarItem'
import EventCategoriesSidebarHeader from './EventCategoriesSidebarHeader'
import EventCategoriesSidebarItem from './EventCategoriesSidebarItem'

type EventCategoriesSidebarProps = {
  className?: string
}

const EventCategoriesSidebar = ({className = ''}: EventCategoriesSidebarProps) => {
  const {data: eventCategoryGroups} = useEventCategoryGroups()

  return (
    <div
      className={`d-flex flex-column mh-800px scroll-y ${className}`}
      style={{backgroundColor: '#1E1E2D', overflowY: 'auto'}}
    >
      <EventCategoriesSidebarHeader />
      {eventCategoryGroups?.items
        .filter((eventCategoryGroup) => !eventCategoryGroup.template)
        .map((eventCategoryGroup) => (
          <EventCategoriesSidebarItem eventCategoryGroup={eventCategoryGroup} />
        ))}

      <AddEventCategorySidebarItem />
    </div>
  )
}

export default EventCategoriesSidebar
