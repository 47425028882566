import {useEffect, useRef} from 'react'
import * as am5 from '@amcharts/amcharts5'
import {CategoryData, paretoConfig} from '../../core/_chartConfigs/paretoConfig'
import {ChartLoader} from '../ChartLoader'
import {useEventCategories, useMachineOperationalStats} from '../../../../core/requests/oee'
import {EventCategory, MachineOperationalStats, SubEventCategory} from '../../../../core/_models'

type ParetoGraphProps = {
  productionLineId: string
  eventCategoryIds?: string[]
  startTime?: string
  endTime?: string
  height: string
  chartId?: string
}

const ParetoGraph = ({
  productionLineId,
  eventCategoryIds,
  startTime,
  endTime,
  height,
  chartId = `pareto-${productionLineId}`,
}: ParetoGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)

  const {data: operationalStats} = useMachineOperationalStats({
    id: productionLineId,
    startTime,
    endTime,
    options: {refetchOnWindowFocus: false},
  })

  const eventCategoriesToFetch = Object.keys(operationalStats ?? {}).filter(
    (key) => !eventCategoryIds || eventCategoryIds.includes(key)
  )

  const {data: eventCategories} = useEventCategories({
    ids: eventCategoriesToFetch,
    options: {
      enabled: operationalStats !== undefined && eventCategoriesToFetch.length > 0,
      refetchOnWindowFocus: false,
    },
  })

  const loading =
    operationalStats === undefined ||
    (eventCategories === undefined && eventCategoriesToFetch.length > 0)

  useEffect(() => {
    if (loading) return

    const data = transformData(operationalStats, eventCategories?.items)
    chartRef.current = paretoConfig(chartId, data)

    return () => chartRef.current?.dispose()
  }, [operationalStats, loading])

  return <ChartLoader loading={loading} chartId={chartId} height={height} />
}

function transformData(
  operationalStats: MachineOperationalStats,
  eventCategories?: EventCategory[]
): CategoryData[] {
  if (!eventCategories) return []

  return eventCategories.map((category) => {
    const stats = operationalStats[category._id]
    return {
      id: category._id,
      category: category.title,
      occurrences: stats?.count || 0,
      duration: stats?.duration || 0,
      color: category.color,
      subcategories: category.sub_event_categories.map((sub) => ({
        id: sub._id!,
        subcategory: sub.title,
        occurrences: operationalStats[sub._id!]?.count || 0,
        duration: operationalStats[sub._id!]?.duration || 0,
        color: sub.color,
      })),
    }
  })
}

export {ParetoGraph}
