import {useInfiniteQuery} from 'react-query'
import {QueryKey} from 'react-query/types/core/types'
import {PaginatedRequestParams, PaginatedResponse, ReferenceUUID} from '../../core/_models'

type FetchFnType<
  T extends ReferenceUUID = ReferenceUUID,
  P extends PaginatedRequestParams | undefined = PaginatedRequestParams
> = (params?: P) => Promise<PaginatedResponse<T>>

interface UsePaginatedInfiniteQueryProps<
  T extends ReferenceUUID,
  P extends PaginatedRequestParams | undefined = PaginatedRequestParams
> {
  queryKey?: QueryKey
  params?: P
  enabled?: boolean
  fetchFn: (params?: P) => Promise<PaginatedResponse<T>>
}

export interface UsePaginatedInfiniteQueryOptions {
  enabled?: boolean
}

export const usePaginatedInfiniteQuery = <
  T extends ReferenceUUID,
  Q extends FetchFnType<T> = FetchFnType<T>
>({
  queryKey,
  params,
  fetchFn,
  enabled,
}: UsePaginatedInfiniteQueryProps<T, Parameters<Q>[0]>) => {
  const infiniteQuery = useInfiniteQuery<PaginatedResponse<T>, Error>({
    queryKey,
    queryFn: async (context) =>
      await fetchFn({
        ...params,
        page: context.pageParam,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined,
    getPreviousPageParam: (lastPage, pages) => (lastPage.page > 1 ? lastPage.page - 1 : undefined),
    enabled,
  })
  const items = infiniteQuery.data?.pages.map((d) => d.items).reduce((p, c) => [...p, ...c], [])
  return {
    ...infiniteQuery,
    items,
  }
}
