import React from 'react'
import ReactDOM from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './sass/hexastate/style.scss'
import 'flatpickr/dist/themes/material_blue.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import {PendoScript} from './app/modules/pendo/PendoScript'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
// import * as Sentry from '@sentry/react'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

// Sentry.init({
//   dsn: 'https://b952ced77f914bf4871e1e3ffdc01cea@o4504576009371648.ingest.sentry.io/4505510020055040',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

const container = document.getElementById('root')
if (container) {
  const queryClient = new QueryClient()

  const router = createBrowserRouter([
    {
      path: '*',
      element: <AppRoutes />,
    },
  ])

  ReactDOM.createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <RouterProvider router={router} />
          <PendoScript />
        </AuthProvider>
      </MetronicI18nProvider>
      {process.env.REACT_APP_NODE_ENV === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  )
}
