import OperationalStatsBox from '../../../../components/OperationalStatsBox'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {Machine} from '../../../../core/_models'
import {useAsset, useProductionLine} from '../../../../core/requests/factory'
import {WidgetProps} from './Widget'

const OperationalStatsWidget = ({widget}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const {data: asset} = useAsset({
    assetId: widget.entity,
    options: {
      enabled: widget.entity_group === 'asset',
    },
  })

  const {data: productionLine} = useProductionLine(widget.entity, {
    options: {
      enabled: widget.entity_group === 'production_line',
    },
  })

  const machine: Machine | undefined = asset || productionLine
  return (
    <div className='d-flex align-items-center justify-content-center h-100 mx-5'>
      {machine ? (
        <OperationalStatsBox machine={machine} startTime={startTime} endTime={endTime} />
      ) : (
        'Machine not found'
      )}
    </div>
  )
}

export default OperationalStatsWidget
