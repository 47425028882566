import {useQuery} from 'react-query'
import {graphql} from '../../../../../gql'
import useConditionClient from '../UseConditionClient'

const getAnomalyDetectionHistoryWithVariablesQueryDocument = graphql(`
  query AnomalyDetectionHistory($id: String!, $startTime: DateTime, $endTime: DateTime) {
    anomalyDetectionHistory(id: $id, startTime: $startTime, endTime: $endTime) {
      time
      value
    }
  }
`)

interface Props {
  id?: string | null
  startTime?: string | null
  endTime?: string | null
}

const useAnomalyDetectionHistory = ({id = null, startTime = null, endTime = null}: Props = {}) => {
  const client = useConditionClient()
  return useQuery({
    queryKey: ['condition.AnomalyDetectionHistory', id, startTime, endTime],
    queryFn: async () =>
      (
        await client.request(getAnomalyDetectionHistoryWithVariablesQueryDocument, {
          id,
          startTime,
          endTime,
        })
      ).anomalyDetectionHistory,
    enabled: !!id,
  })
}

export default useAnomalyDetectionHistory
