import LogEventButton from '../../../components/LogEventButton'

type Props = {
  productionLineId: string
}

const ProductionLineStartStopCell = ({productionLineId}: Props) => {
  return (
    <div className='d-flex align-items-end'>
      <LogEventButton productionLineId={productionLineId} />
    </div>
  )
}

export {ProductionLineStartStopCell}
