import {Link} from 'react-router-dom'
import useIsSidebarMinimized from '../../../../app/hooks/UseIsSidebarMinimized'

const SidebarFooter = () => {
  const isMinimized = useIsSidebarMinimized()

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-8 px-7' id='kt_app_sidebar_footer'>
      <div className='d-flex align-items-center'>
        <Link className='menu-link without-sub' to='/support'>
          <span className='menu-icon'>
            <i className='fa-solid fs-1 fa-envelope-open-text' />
          </span>
        </Link>
        {!isMinimized && (
          <div className='ms-3' style={{color: '#9d9da6'}}>
            Support
          </div>
        )}
      </div>
    </div>
  )
}

export {SidebarFooter}
