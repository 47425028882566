import AxesSelectField from '../fields/AxesSelectField'
import NumberInputField from '../fields/NumberInputField'
import SensorTypesSelectField from '../fields/SensorTypesSelectField'
import {ThresholdCrossedCondition} from './conditions'

type Props = {
  condition: ThresholdCrossedCondition
  setCondition: (condition: ThresholdCrossedCondition) => void
  errorOccurred?: boolean
}

const ThresholdCrossed = ({condition, setCondition, errorOccurred}: Props) => {
  return (
    <>
      <NumberInputField
        name='Minimum'
        initial={condition.minimum}
        onchange={(value) => setCondition({...condition, minimum: value})}
        className='pt-3'
      />

      <NumberInputField
        name='Maximum'
        initial={condition.maximum}
        onchange={(value) => setCondition({...condition, maximum: value})}
        className='pt-3'
      />

      <SensorTypesSelectField
        name='Sensor Types*'
        initial={condition.data_types || undefined}
        onchange={(values) => setCondition({...condition, data_types: values})}
        className='pt-3'
        errorOccurred={errorOccurred}
      />

      <AxesSelectField
        name='Axes'
        initial={condition.axes || undefined}
        onchange={(values) =>
          setCondition({...condition, axes: values.length ? values : undefined})
        }
        className='pt-3'
      />
    </>
  )
}

export default ThresholdCrossed
