import {PartType} from '../../core/_models'

export type ICreatePart = {
  production_line?: string
  section?: string
  asset?: string
  type?: PartType
  manufacturer?: string
  model_number?: string
  // Not model fields
  locationChoice?: 'production_line' | 'section' | 'asset'
}

export const defaultCreatePart: ICreatePart = {
  production_line: undefined,
  section: undefined,
  asset: undefined,
  type: undefined,
  manufacturer: undefined,
  model_number: undefined,
  locationChoice: undefined,
}

export type StepProps = {
  data: ICreatePart
  updateData: (fieldsToUpdate: Partial<ICreatePart>) => void
  hasError: boolean
}
