import axios, {AxiosError} from 'axios'
import {BaseModel, MachineVerificationRequest, SensorRecord, SensorType, Timespan} from '../_models'
import {UseMutationOptions, UseQueryOptions, useMutation, useQuery} from 'react-query'

const CONDITION_API_BASE_URL = process.env.REACT_APP_WA_CONDITION_URL

// ----- SENSORS ----- //

type UseSensorRecordsProps = {
  sensorId?: string
  dataType?: SensorType
  axis?: 'X' | 'Y' | 'Z'
  amperageType?: 'Phase 1' | 'Phase 2' | 'Phase 3' | 'Average'
  startTime?: string
  endTime?: string
  timespan?: Timespan
  multiplier?: number
  options?: UseQueryOptions<SensorRecord[], Error>
}

type UseSensorRecordsRequestParams = {
  data_type?: SensorType
  axis?: 'X' | 'Y' | 'Z'
  amperage_type?: 'Phase 1' | 'Phase 2' | 'Phase 3' | 'Average'
  start_time?: string
  end_time?: string
  timespan?: Timespan
  multiplier?: number
}

export const useSensorRecords = (props: UseSensorRecordsProps) => {
  const {
    sensorId,
    dataType,
    axis,
    amperageType,
    startTime,
    endTime,
    timespan,
    multiplier,
    options,
  } = props
  const params: UseSensorRecordsRequestParams = {
    data_type: dataType,
    axis,
    amperage_type: amperageType,
    start_time: startTime,
    end_time: endTime,
    timespan,
    multiplier,
  }
  return useQuery<SensorRecord[], Error>(
    ['sensorRecords', sensorId, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<SensorRecord[]>(
        `${CONDITION_API_BASE_URL}/sensors/${sensorId}/records`,
        {params}
      )
      return data
    },
    {enabled: !!sensorId && !!dataType, ...options}
  )
}

type GetSensorRecordsProps = Partial<Omit<UseSensorRecordsProps, 'options'>> & {
  sensorId: string
  dataType: SensorType
}

export const getSensorRecords = async (
  props: GetSensorRecordsProps,
  attempt = 0
): Promise<SensorRecord[]> => {
  const {sensorId, dataType, amperageType, axis, startTime, endTime, timespan, multiplier} = props
  const params: UseSensorRecordsRequestParams = {
    data_type: dataType,
    amperage_type: amperageType,
    axis,
    start_time: startTime,
    end_time: endTime,
    timespan,
    multiplier,
  }
  try {
    const {data} = await axios.get<SensorRecord[]>(
      `${CONDITION_API_BASE_URL}/sensors/${sensorId}/records`,
      {params}
    )
    return data
  } catch (e) {
    console.warn(`getSensorRecords error, attempt: ${attempt}`, e)
    if (attempt < 2) {
      return getSensorRecords(props, ++attempt)
    }
  }
  return []
}

// ----- MACHINES ----- //

type CreateMachineVerificationRequestProps = {
  id: string
  note?: string
}

export async function createMachineVerificationRequest({
  id,
  note,
}: CreateMachineVerificationRequestProps) {
  const props = {note}
  const {data} = await axios.post<MachineVerificationRequest & BaseModel>(
    `${CONDITION_API_BASE_URL}/machines/${id}/verifications`,
    props
  )
  return data
}

type UseCreateMachineVerificationRequestMutationProps = {
  options?: UseMutationOptions<
    MachineVerificationRequest & BaseModel,
    AxiosError,
    CreateMachineVerificationRequestProps
  >
}

export const useCreateMachineVerificationRequestMutation = ({
  options,
}: UseCreateMachineVerificationRequestMutationProps = {}) => {
  return useMutation<
    MachineVerificationRequest & BaseModel,
    AxiosError,
    CreateMachineVerificationRequestProps
  >({
    ...options,
    mutationFn: createMachineVerificationRequest,
  })
}
