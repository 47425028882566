import {useEffect} from 'react'
import LoadingWrapper from '../../../../../../components/LoadingWrapper'
import SelectFieldTable from '../../../../../../components/form-components/SelectFieldTable'
import {useAssets, useProductionLines} from '../../../../../../core/requests/factory'
import {StepProps} from '../_models'
import SelectFieldBoxes from '../../../../../../components/form-components/SelectFieldBoxes'

const ProductionLineOrAssetSelectionStep = ({data, updateData, hasError}: StepProps) => {
  const {data: assets} = useAssets({options: {enabled: data.entity_group === 'asset'}})
  const {data: productionLines} = useProductionLines({
    options: {enabled: data.entity_group === 'production_line'},
  })

  const loading =
    (data.entity_group === 'asset' && assets === undefined) ||
    (data.entity_group === 'production_line' && productionLines === undefined)

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={loading}>
        <div className='w-100'>
          <div className='mb-5'>
            <h2 className='fw-bold d-flex align-items-center text-dark'>Select Your Entity</h2>
            <div className='text-muted fw-semibold fs-6'>
              Begin by choosing the type of entity you want to focus on. Then, select the specific
              entity from the list.
            </div>
          </div>

          <SelectFieldBoxes
            name='Entity Group'
            optionNames={['Production Line', 'Asset']}
            optionValues={['production_line', 'asset']}
            data={data}
            updateData={updateData}
            dataField='entity_group'
            required
            hasError={hasError}
            updatesOnChange={{entity_filter: undefined, entity: undefined}}
          />

          {data.entity_group === 'asset' && assets !== undefined && (
            <SelectFieldTable
              name='Asset'
              columnNames={['Name', 'Area']}
              optionRows={assets.items.map((asset) => [asset.name, asset.area.name])}
              optionValues={assets.items.map((asset) => asset._id)}
              updatesOnChange={(id) => {
                const asset = assets?.items.find((asset) => asset._id === id)
                if (asset)
                  return {name: asset.name + ` - ${data.type}`, operational_event_categories: []}
                return {operational_event_categories: []}
              }}
              data={data}
              updateData={updateData}
              dataField='entity'
              required
              hasError={hasError}
              height='400px'
              lastField
            />
          )}
          {data.entity_group === 'production_line' && productionLines !== undefined && (
            <SelectFieldTable
              name='Production Line'
              columnNames={['Name', 'Area']}
              optionRows={productionLines.items.map((productionLine) => [
                productionLine.name,
                productionLine.area.name,
              ])}
              optionValues={productionLines.items.map((productionLine) => productionLine._id)}
              updatesOnChange={(id) => {
                const productionLine = productionLines?.items.find((pl) => pl._id === id)
                if (productionLine)
                  return {
                    name: productionLine.name + ` - ${data.type}`,
                    operational_event_categories: [],
                  }
                return {operational_event_categories: []}
              }}
              data={data}
              updateData={updateData}
              dataField='entity'
              required
              hasError={hasError}
              height='400px'
              lastField
            />
          )}
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default ProductionLineOrAssetSelectionStep
