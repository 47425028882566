import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  fontAwesomeIcon?: string
  buttonIcon?: string
  buttonTheme?: string
  handleButtonClick?: () => void
}

const SidebarMenuItemWithSub: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  fontAwesomeIcon,
  buttonIcon,
  buttonTheme = 'success',
  handleButtonClick = () => {},
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  const handleButtonClickWrapper = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation() // Stop the click event from propagating
    handleButtonClick()
  }

  const expandIfMinimized = () => {
    // Show sidebar if it is currently minimized
    const appBody = document.getElementById('kt_app_body')
    const sidebarIsMinimized = appBody?.getAttribute('data-kt-app-sidebar-minimize') === 'on'
    if (sidebarIsMinimized) {
      document.getElementById('kt_app_sidebar_toggle')?.click()
    }
  }

  return (
    <div
      className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
      data-kt-menu-trigger='click'
      onClick={expandIfMinimized}
    >
      <span className='menu-link'>
        {fontAwesomeIcon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <>
            <span className='menu-icon'>
              {' '}
              <i className={`fa-solid fs-2 ${fontAwesomeIcon}`}></i>
            </span>
          </>
        )}
        <span className='menu-title'>{title}</span>
        {buttonIcon && (
          <span className='menu-badge' onClick={handleButtonClickWrapper}>
            <span className={`badge badge-${buttonTheme}`}>
              <i className={`fa-solid fs-6 ${buttonIcon}`} style={{color: '#ffffff'}} />
            </span>
          </span>
        )}
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  )
}

export {SidebarMenuItemWithSub}
