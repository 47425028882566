import React, {CSSProperties, ReactNode} from 'react'

interface Props {
  disabled: boolean
  children: ReactNode
  className?: string
}

const DisabledWrapper: React.FC<Props> = ({disabled, children, className = ''}) => {
  const disabledStyle: CSSProperties = disabled ? {opacity: 0.5, pointerEvents: 'none'} : {}

  return (
    <div className={`disabled-wrapper ${className}`} style={disabledStyle}>
      {children}
    </div>
  )
}

export default DisabledWrapper
