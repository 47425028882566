import Select from 'react-select'
import {ManufacturingProcess, manufacturingTypes} from '../../../../core/_models'

type ManufacturingTypeSelectProps = {
  onChange: (manufacturingType?: ManufacturingProcess) => void
}

const ManufacturingTypeSelect = ({onChange}: ManufacturingTypeSelectProps) => (
  <Select
    placeholder={`Manufacturing Type`}
    onChange={(e) => onChange(e?.value)}
    options={
      manufacturingTypes.map((m) => ({
        value: m,
        label: m,
      })) || []
    }
    isClearable
    className={'me-3'}
  />
)

export default ManufacturingTypeSelect
