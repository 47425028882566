import usePagination, {UsePaginationProps} from '../../../../components/pagination/usePagination'
import {Area, ManufacturingProcess, Organization} from '../../../../core/_models'
import {UseProductionLinesProps} from '../../../../core/requests/factory'
import {useFilterState} from './useFilterState'

const useProductionLinesFilter = (props: UsePaginationProps = {}) => {
  const {activePage, setActivePage, pageSize} = usePagination({
    initialPage: props.initialPage,
    size: props.size,
  })

  const filterState = {
    organization: useFilterState<Organization>(),
    area: useFilterState<Area>(),
    manufacturingType: useFilterState<ManufacturingProcess>(),
  }

  const productionLineFilterProps: UseProductionLinesProps = {
    organization: filterState.organization.value?._id,
    area: filterState.area.value?._id,
    manufacturingType: filterState.manufacturingType.value,
    page: activePage,
    size: pageSize,
    options: {
      keepPreviousData: true,
    },
  }
  return {
    filterState,
    filterCount: Object.values(filterState).filter((v) => v.value !== undefined).length,
    productionLineFilterProps,
    activePage,
    setActivePage,
    pageSize,
  }
}

export default useProductionLinesFilter
