import {EntityGroup} from '../../../../../core/_models'
import {IWidget, defaultCreateWidget, defaultLayout} from '../../widgets/_models'

export type IPieWidget = IWidget & {
  entity_group_filter?: EntityGroup
  entity_filter?: string
  timeFrame?: 'dateRange' | 'interval'
}

export const defaultCreatePieWidget: IPieWidget = {
  ...defaultCreateWidget,
  type: 'Pie',
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
  },
}

export type StepProps = {
  data: IPieWidget
  updateData: (fieldsToUpdate: Partial<IPieWidget>) => void
  hasError: boolean
}
