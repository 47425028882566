import usePagination, {UsePaginationProps} from '../../../../components/pagination/usePagination'
import {Factory, Organization} from '../../../../core/_models'
import {UseAreasProps} from '../../../../core/requests/factory'
import {useFilterState} from './useFilterState'

const useAreasFilter = (props: UsePaginationProps = {}) => {
  const {activePage, setActivePage, pageSize} = usePagination({
    initialPage: props.initialPage,
    size: props.size,
  })

  const filterState = {
    organization: useFilterState<Organization>(),
    factory: useFilterState<Factory>(),
  }

  const areasFilterProps: UseAreasProps = {
    organization: filterState.organization.value?._id,
    factory: filterState.factory.value?._id,
    page: activePage,
    size: pageSize,
    options: {
      keepPreviousData: true,
    },
  }

  return {
    filterState,
    filterCount: Object.values(filterState).filter((v) => v.value !== undefined).length,
    areasFilterProps,
    activePage,
    setActivePage,
    pageSize,
  }
}

export default useAreasFilter
