import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {useState} from 'react'
import {Automation} from '../../core/_models'
import {Action, defaultSendNotificationForm, validateActions} from './actions/actions'
import ActionCard from './ActionCard'
import EventCard from './EventCard'
import {createAutomation, updateAutomation} from '../../core/requests/automations'
import {useQueryClient} from 'react-query'
import clsx from 'clsx'
import {ClipLoader} from 'react-spinners'
import {Condition, validateConditions} from './conditions/conditions'
import ConditionCard from './ConditionCard'

type Props = {
  show: boolean
  handleClose: () => void
  automation?: Automation
}

const defaultActions: Action[] = [
  {type: 'send_notification', enabled: true, form: defaultSendNotificationForm},
]

const CreateAutomationModal = ({show, handleClose, automation}: Props) => {
  const [selectedEvent, setSelectedEvent] = useState<string | undefined>(automation?.event)
  const [actions, setActions] = useState<(Action | null)[]>(automation?.actions || defaultActions)
  const [conditions, setConditions] = useState<(Condition | null)[]>(automation?.conditions || [])
  const [validationErrorOccurred, setValidationErrorOccurred] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const queryClient = useQueryClient()

  const onSubmit = async () => {
    setValidationErrorOccurred(false)

    const eventSelected = selectedEvent !== undefined
    const nonNullActions = actions.filter((a) => a !== null) as Action[]
    const nonNullConditions = conditions.filter((c) => c !== null) as Condition[]
    const actionsValid = validateActions(nonNullActions)
    const conditionsValid = validateConditions(nonNullConditions)
    if (!eventSelected || !actionsValid || !conditionsValid) {
      setValidationErrorOccurred(true)
      return
    }

    setSubmitting(true)
    try {
      if (automation)
        await updateAutomation({
          id: automation._id,
          event: selectedEvent,
          actions: nonNullActions,
          conditions: nonNullConditions,
        })
      else
        await createAutomation({
          enabled: true,
          event: selectedEvent,
          actions: nonNullActions,
          conditions: nonNullConditions,
        })
      await queryClient.invalidateQueries('automations')
    } catch (e) {
      console.error(e)
    } finally {
      setSubmitting(false)
      handleClose()
    }
  }

  return (
    <>
      <Modal
        id='hs_create_automation_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered'
        size='xl'
        show={show}
        onHide={submitting ? undefined : handleClose}
        container={document.getElementById('root-modals')}
      >
        <div className='modal-header'>
          <h2>{automation ? 'Edit Automation' : 'Create Automation'}</h2>
          <div
            className={clsx(
              'btn btn-sm btn-icon btn-active-color-primary',
              submitting && 'disabled'
            )}
            onClick={handleClose}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div
          className='modal-body d-flex p-0'
          style={{
            height: 'calc(100vh - 250px)',
            pointerEvents: submitting ? 'none' : 'auto',
          }}
        >
          {submitting && (
            <div
              className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
              style={{
                top: 0,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                zIndex: 10,
              }}
            >
              <ClipLoader size={50} color={'#123abc'} loading={submitting} />
            </div>
          )}

          <div
            className='d-flex flex-column flex-grow-1 align-items-center pt-5 gap-5 overflow-auto'
            style={{flex: '1 1 0'}}
          >
            <div className='card border border-secondary shadow-sm w-75'>
              <div className='card-body d-flex flex-column p-5'>
                <div className='fw-bold fs-3'>When</div>
                <div>This happens...</div>
              </div>
            </div>

            <EventCard
              event={selectedEvent}
              setEvent={(e) => {
                setSelectedEvent(e)
                setConditions([])
              }}
              errorOccurred={validationErrorOccurred}
            />

            {selectedEvent &&
              conditions.map((condition, i) => (
                <ConditionCard
                  key={i}
                  condition={condition}
                  setCondition={(condition) => {
                    const newConditions = [...conditions]
                    newConditions[i] = condition
                    setConditions(newConditions)
                  }}
                  removeCondition={() => setConditions(conditions.filter((_, j) => j !== i))}
                  selectedEvent={selectedEvent}
                  errorOccurred={validationErrorOccurred}
                />
              ))}
            {selectedEvent && (
              <button
                type='button'
                className='btn btn-primary py-1 px-4'
                onClick={() => setConditions([...conditions, null])}
              >
                <div className='fs-4'>Add condition +</div>
              </button>
            )}
          </div>
          <div
            className='d-flex align-items-stretch'
            style={{width: '1px', backgroundColor: 'rgb(239, 242, 245)'}}
          />
          <div
            className='d-flex flex-column flex-grow-1 align-items-center py-5 gap-5 overflow-auto'
            style={{flex: '1 1 0'}}
          >
            <div className='card border border-secondary shadow-sm w-75'>
              <div className='card-body d-flex flex-column p-5'>
                <div className='fw-bold fs-3'>Then</div>
                <div>Do this</div>
              </div>
            </div>

            {actions.map((action, i) => (
              <ActionCard
                key={i}
                action={action}
                setAction={(action) => {
                  const newActions = [...actions]
                  newActions[i] = action
                  setActions(newActions)
                }}
                removeAction={
                  i === 0 ? undefined : () => setActions(actions.filter((_, j) => j !== i))
                }
                selectedEvent={selectedEvent}
                errorOccurred={validationErrorOccurred}
              />
            ))}

            <button
              type='button'
              className='btn btn-primary py-1 px-4'
              onClick={() => setActions([...actions, null])}
            >
              <div className='fs-4'>Add action +</div>
            </button>
          </div>
        </div>

        <div className='modal-footer'>
          <button
            type='button'
            className={clsx('btn btn-light-primary', submitting && 'disabled')}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            id='submit'
            type='button'
            className={clsx('btn btn-primary', submitting && 'disabled')}
            onClick={onSubmit}
          >
            {automation ? 'Save Changes' : 'Create'}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default CreateAutomationModal
