import {AxiosError} from 'axios'
import React from 'react'
import {useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {StepperComponent} from '../../_metronic/assets/ts/components'
import {KTSVG} from '../../_metronic/helpers'
import {ServerError} from '../core/_models'
import LoadingWrapper from './LoadingWrapper'
import ModalErrorWrapper from './ModalErrorWrapper'

type StepProps<ICreateObject> = {
  data: ICreateObject
  updateData: (fieldsToUpdate: Partial<ICreateObject>) => void
  hasError: boolean
  current?: boolean
}

type SkipTo<ICreateObject> = {
  step: number
  condition: (data: ICreateObject) => boolean
  action: (data: ICreateObject) => void
}

export type Step<ICreateObject> = {
  title: string
  description: string
  validation?: (data: ICreateObject) => boolean
  component: ({data, updateData, hasError}: StepProps<ICreateObject>) => JSX.Element
  skipTo?: SkipTo<ICreateObject>[]
  done?: (data: ICreateObject) => boolean
  disabled?: (data: ICreateObject) => boolean
}

interface Props<ICreateObject, IObject> {
  id: string
  objectName?: string
  show: boolean
  close: () => void
  cancel?: () => void
  openNextModals?: ((arg0?: IObject) => void)[]
  nextObjectNames?: string[]
  steps: Step<ICreateObject>[]
  data: ICreateObject
  defaultData: ICreateObject
  setData: (data: ICreateObject) => void
  submitData: (data: ICreateObject) => Promise<IObject>
  loading?: boolean
  title?: string
}

const StepModalWrapper = <ICreateObject, IObject>({
  id,
  objectName,
  show,
  close,
  cancel,
  openNextModals,
  nextObjectNames,
  steps,
  data,
  defaultData,
  setData,
  submitData,
  loading = false,
  title,
}: Props<ICreateObject, IObject>) => {
  const [currentStep, setCurrentStep] = useState(1)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [hasValidationError, setHasValidationError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasServerError, setHasServerError] = useState(false)
  const [serverError, setServerError] = useState<ServerError | null>(null)

  const updateData = (fieldsToUpdate: Partial<ICreateObject>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
  }

  const enabledSteps = React.useMemo(
    () => steps.filter((step) => !(step.disabled ? step.disabled(data) : false)),
    [data, steps]
  )

  React.useEffect(() => {
    if (stepper.current) {
      stepper.current.totalStepsNumber = enabledSteps.length
    }
  }, [enabledSteps])

  const prevStep = () => {
    if (!stepper.current) return
    stepper.current.goPrev()
    setCurrentStep(stepper.current.getCurrentStepIndex())
  }

  const nextStep = () => {
    setHasValidationError(false)
    if (!stepper.current) {
      return
    }

    const step = enabledSteps[stepper.current.getCurrentStepIndex() - 1]
    if (step.validation && step.validation(data)) {
      setHasValidationError(true)
      return
    }

    if (step.skipTo) {
      for (const skipTo of step.skipTo) {
        if (skipTo.condition(data)) {
          skipTo.action(data)
          stepper.current.goto(skipTo.step + 1)
          return
        }
      }
    }

    stepper.current.goNext()
    setCurrentStep(stepper.current.getCurrentStepIndex())
  }

  const submit = async () => {
    const step = enabledSteps[enabledSteps.length - 1]
    if (step.validation && step.validation(data)) {
      setHasValidationError(true)
      return
    }

    setIsSubmitting(true)
    try {
      const obj = await submitData(data)
      setIsSubmitting(false)
      hide()
      return obj
    } catch (error) {
      setHasServerError(true)
      const errorData: ServerError = (error as AxiosError).response?.data as ServerError
      setServerError(errorData)
      setIsSubmitting(false)
    }
  }

  const nextModal = async (index: number) => {
    const obj = await submit()
    if (obj && openNextModals) openNextModals[index](obj)
  }

  const hide = () => {
    close()
    setHasValidationError(false)
    setHasServerError(false)
    setServerError(null)
    setData(defaultData)
  }

  const hideAndCancel =
    cancel &&
    (() => {
      cancel()
      setHasValidationError(false)
      setHasServerError(false)
      setServerError(null)
      setData(defaultData)
    })

  return (
    <Modal
      id={`${id}_modal`}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered'
      size='xl'
      show={show}
      onHide={hideAndCancel || hide}
      onShow={loadStepper}
      backdrop={isSubmitting ? 'static' : true}
      container={document.getElementById('root-modals')}
    >
      <ModalErrorWrapper hasError={hasServerError} errorData={serverError || undefined} hide={hide}>
        {/* Header */}
        <div className='modal-header'>
          {title ? <h2>{title}</h2> : objectName ? <h2>{`Create a New ${objectName}`}</h2> : <></>}
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={hideAndCancel || hide}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        {/* Body */}
        <div className='modal-body py-lg-10 px-lg-10'>
          <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id={`${id}_stepper`}
          >
            {/* Side Stepper */}
            <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
              <div className='stepper-nav ps-lg-10'>
                {enabledSteps.map((step, index) => (
                  <div
                    className={`stepper-item ${index === 0 ? 'current' : ''}`}
                    data-kt-stepper-element='nav'
                    key={index}
                  >
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{index + 1}</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>{step.title}</h3>
                        <div className='stepper-desc'>{step.description}</div>
                      </div>
                    </div>
                    {index !== enabledSteps.length - 1 && (
                      <div className='stepper-line h-40px'></div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Content */}
            <LoadingWrapper loading={loading || isSubmitting}>
              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <form noValidate id={`${id}_form`}>
                  {enabledSteps.map((step, index) => (
                    <step.component
                      data={data}
                      updateData={updateData}
                      hasError={hasValidationError}
                      key={index}
                      current={currentStep === index + 1}
                    />
                  ))}

                  {/* Navigation Buttons */}
                  <div className='d-flex flex-stack pt-10'>
                    {/* Previous Step */}
                    {enabledSteps.length === 1 ? (
                      <div />
                    ) : (
                      <div className='me-2'>
                        <button
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                          onClick={prevStep}
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-3 me-1'
                          />{' '}
                          Previous
                        </button>
                      </div>
                    )}
                    <div>
                      {/* Submit */}
                      <button
                        type='button'
                        className={`btn btn-lg btn${openNextModals ? '-light' : ''}-primary me-3`}
                        data-kt-stepper-action={enabledSteps.length === 1 ? '' : 'submit'}
                        onClick={submit}
                      >
                        Finish
                      </button>

                      {/* const nextStep = () => {
    setHasValidationError(false)
    if (!stepper.current) {
      return
    }

    const step = steps[stepper.current.getCurrentStepIndex() - 1]
    if (step.validation && step.validation(data)) {
      setHasValidationError(true)
      return
    } */}

                      {stepper.current &&
                      !(
                        enabledSteps[stepper.current.getCurrentStepIndex() - 1].validation &&
                        (
                          enabledSteps[stepper.current.getCurrentStepIndex() - 1].validation as (
                            data: ICreateObject
                          ) => boolean
                        )(data)
                      ) &&
                      enabledSteps[stepper.current.getCurrentStepIndex() - 1].done !== undefined &&
                      (
                        enabledSteps[stepper.current.getCurrentStepIndex() - 1].done as (
                          data: ICreateObject
                        ) => boolean
                      )(data) ? (
                        <button
                          type='button'
                          className={`btn btn-lg btn${openNextModals ? '-light' : ''}-primary me-3`}
                          onClick={submit}
                        >
                          Finish
                        </button>
                      ) : (
                        <>
                          {/* Next Modal */}
                          {openNextModals &&
                            openNextModals.map((_openNextModal, index) => (
                              <button
                                type='button'
                                className='btn btn-lg btn-primary me-3'
                                data-kt-stepper-action={enabledSteps.length === 1 ? '' : 'submit'}
                                onClick={() => nextModal(index)}
                                key={index}
                              >
                                {`${nextObjectNames![index]}`}
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon svg-icon-4 ms-1 me-0'
                                />
                              </button>
                            ))}

                          {/* Next Step */}
                          {enabledSteps.length > 1 && (
                            <button
                              type='button'
                              className='btn btn-lg btn-primary'
                              data-kt-stepper-action='next'
                              onClick={nextStep}
                            >
                              Next{' '}
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon svg-icon-4 ms-1 me-0'
                              />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </ModalErrorWrapper>
    </Modal>
  )
}

export default StepModalWrapper
