import {useState} from 'react'
import {Part} from '../../core/_models'
import PartDetailModal from './PartDetailModal'

const usePartDetailModal = () => {
  const [part, setPart] = useState<Part>()
  const modal = part
    ? PartDetailModal({
        show: true,
        setShow: () => setPart(undefined),
        part,
      })
    : undefined
  return {
    modal,
    setPart,
  }
}

export default usePartDetailModal
