import {
  Area,
  Asset,
  Factory,
  FactoryEntity,
  Gateway,
  Part,
  Placement,
  Plc,
  ProductionLine,
  Section,
  Sensor,
} from './_models'

export const isFactory = (entity: FactoryEntity): entity is FactoryEntity & {entity: Factory} =>
  entity.type === 'factory'

export const isArea = (entity: FactoryEntity): entity is FactoryEntity & {entity: Area} =>
  entity.type === 'area'

export const isProductionLine = (
  entity: FactoryEntity
): entity is FactoryEntity & {entity: ProductionLine} => entity.type === 'production_line'

export const isSection = (entity: FactoryEntity): entity is FactoryEntity & {entity: Section} =>
  entity.type === 'section'

export const isAsset = (entity: FactoryEntity): entity is FactoryEntity & {entity: Asset} =>
  entity.type === 'asset'

export const isPart = (entity: FactoryEntity): entity is FactoryEntity & {entity: Part} =>
  entity.type === 'part'

export const isPlacement = (entity: FactoryEntity): entity is FactoryEntity & {entity: Placement} =>
  entity.type === 'placement'

export const isSensor = (entity: FactoryEntity): entity is FactoryEntity & {entity: Sensor} =>
  entity.type === 'sensor'

export const isGateway = (entity: FactoryEntity): entity is FactoryEntity & {entity: Gateway} =>
  entity.type === 'gateway'

export const isPlc = (entity: FactoryEntity): entity is FactoryEntity & {entity: Plc} =>
  entity.type === 'plc'
