import {useState} from 'react'
import {updateSensor, usePlacementsPaginated} from '../../core/requests/factory'
import {Placement, Sensor, ServerError} from '../../core/_models'
import Select from 'react-select'
import useModalState from '../../hooks/UseModalState'
import {Modal} from 'react-bootstrap'
import {AxiosError} from 'axios'
import ModalErrorWrapper from '../ModalErrorWrapper'
import LoadingOverlayWrapper from '../LoadingOverlayWrapper'
import {KTSVG} from '../../../_metronic/helpers'
import {useQueryClient} from 'react-query'

type Props = {
  sensor: Sensor
}

const SensorMoveSelector = ({sensor}: Props) => {
  const {
    items: openPlacements = [],
    fetchNextPage,
    isLoading,
  } = usePlacementsPaginated({has_sensor_mounted: false})

  const confirmModal = useModalState()
  const [selectedPlacement, setSelectedPlacement] = useState<{value: string; label: string} | null>(
    null
  )

  const moveSensor = async () => {
    if (!selectedPlacement) return
    await updateSensor({sensor: sensor._id, placement: selectedPlacement.value})
  }

  return (
    <>
      <div className='d-flex gap-2'>
        <Select
          placeholder='Select Placement...'
          data-hide-search='true'
          options={openPlacements.map((placement) => ({
            value: placement._id,
            label: `${placement.name}${
              placement.production_line?.name
                ? ` | ${placement.production_line.name}`
                : placement.asset?.name
                ? ` | ${placement.asset.name}`
                : ''
            }`,
          }))}
          onChange={(newValue) => {
            setSelectedPlacement(newValue)
          }}
          value={selectedPlacement}
          isLoading={isLoading}
          onMenuScrollToBottom={() => fetchNextPage()}
          isClearable
        />
        <button
          className='btn btn-sm btn-primary px-3'
          disabled={!selectedPlacement}
          onClick={confirmModal.open}
        >
          Move
        </button>
      </div>

      <ConfirmModal
        show={confirmModal.isOpen}
        hide={() => {
          setSelectedPlacement(null)
          confirmModal.close()
        }}
        onConfirm={moveSensor}
        sensor={sensor}
        placement={openPlacements.find((placement) => placement._id === selectedPlacement?.value)}
      />

      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 1050,
          display: confirmModal.isOpen ? 'block' : 'none',
        }}
      />
    </>
  )
}

type ConfirmModalProps = {
  show: boolean
  hide: () => void
  onConfirm: () => Promise<void>
  sensor: Sensor
  placement?: Placement
}

const ConfirmModal = ({show, hide, onConfirm, sensor, placement}: ConfirmModalProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [serverError, setServerError] = useState<ServerError | null>(null)

  const queryClient = useQueryClient()

  const close = () => {
    setSubmitting(false)
    setServerError(null)
    hide()
  }

  const submit = async () => {
    setSubmitting(true)
    setServerError(null)
    try {
      await onConfirm()
      await queryClient.invalidateQueries('sensors')
      close()
    } catch (e) {
      setServerError((e as AxiosError).response?.data as ServerError)
    }
  }

  return (
    <Modal
      dialogClassName='modal-dialog modal-dialog-centered'
      show={show}
      onHide={close}
      backdrop={submitting ? 'static' : true}
      style={{zIndex: 1100}}
    >
      <ModalErrorWrapper hasError={!!serverError} errorData={serverError || undefined} hide={close}>
        <LoadingOverlayWrapper loading={submitting}>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={close}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>

          <div className='modal-body mx-5 mx-xl-18 pt-0'>
            <h1 className='text-center mb-13'>Confirm Sensor Move</h1>

            <div className='notice bg-light-warning rounded border-warning border border-dashed p-6 mb-3'>
              <div className='d-flex'>
                <KTSVG
                  className='svg-icon-2tx svg-icon-warning me-4'
                  path='/media/icons/duotune/general/gen044.svg'
                />
                <div className='d-flex flex-row flex-stack flex-grow-1'>
                  <div className='fw-semibold'>
                    <h5 className='text-gray-900 fw-bold'>{`You are about to move ${
                      sensor.device_id
                    } from ${sensor.placement.name} to ${
                      placement?.name || '-'
                    }. Please confirm.`}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-center pt-5'>
              <button className='btn btn-light me-3' onClick={hide}>
                Cancel
              </button>
              <button className='btn btn-primary' onClick={submit}>
                Confirm
              </button>
            </div>
          </div>
        </LoadingOverlayWrapper>
      </ModalErrorWrapper>
    </Modal>
  )
}

export default SensorMoveSelector
