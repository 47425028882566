import {
  Asset,
  Gateway,
  Part,
  Placement,
  ProductionLine,
  Section,
  Sensor,
} from '../../../core/_models'

export type ICreateMaintenancePlan = {
  name?: string
  factory_entities?: string[]
  description?: string

  // non-API fields
  selected_entity_type?: string
  factory_entity?: string
  factory_entities_full?: (ProductionLine | Section | Asset | Part | Placement | Sensor | Gateway)[]
}

export const defaultCreateMaintenancePlan: ICreateMaintenancePlan = {
  name: undefined,
  factory_entities: undefined,
  description: undefined,

  // non-API fields
  selected_entity_type: undefined,
  factory_entity: undefined,
}

export type StepProps = {
  data: ICreateMaintenancePlan
  updateData: (fieldsToUpdate: Partial<ICreateMaintenancePlan>) => void
  hasError: boolean
}
