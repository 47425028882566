import Select from 'react-select'
import {ProductionLine} from '../../../../core/_models'
import {useProductionLinesPaginated} from '../../../../core/requests/factory'

type ProductionLineSelectProps = {
  onChange: (productionLine?: ProductionLine) => void
  isDisabled?: boolean
  filterFunction?: (productionLine: ProductionLine) => boolean
}

const ProductionLineSelect = ({
  onChange,
  isDisabled = false,
  filterFunction = () => true,
}: ProductionLineSelectProps) => {
  const {
    items: productionLines,
    isFetching: productionLinesFetching,
    fetchNextPage: productionLinesNextPage,
  } = useProductionLinesPaginated()

  const filteredProductionLines = productionLines?.filter(filterFunction) || []

  return (
    <Select
      placeholder={`Production Line`}
      onChange={(e) => onChange(filteredProductionLines.find((p) => p._id === e?.value))}
      options={
        filteredProductionLines.map((p) => ({
          value: p._id,
          label: p.name,
        })) || []
      }
      isClearable
      className={'me-3'}
      isLoading={productionLinesFetching}
      onMenuScrollToBottom={() => productionLinesNextPage()}
      isDisabled={isDisabled}
    />
  )
}

export default ProductionLineSelect
