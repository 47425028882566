import {CreateWorkOrderAction} from './actions'
import TextInputField from '../fields/TextInputField'
import NumberInputField from '../fields/NumberInputField'
import MultiTextInputField from '../fields/MultiTextInputField'
import PrioritySelectField from '../fields/PrioritySelectField'
import WorkOrderCategorySelectField from '../fields/WorkOrderCategorySelectField'
import UsersSelectField from '../fields/UsersSelectField'
import FactoryEntitySelectField from '../fields/FactoryEntitySelectField'

type Props = {
  action: CreateWorkOrderAction
  setAction: (action: CreateWorkOrderAction) => void
  eventVariables?: string[]
  errorOccurred?: boolean
}

const CreateWorkOrder = ({action, setAction, eventVariables, errorOccurred}: Props) => {
  return (
    <>
      <TextInputField
        name='Title*'
        initial={action.form.title}
        onchange={(value) =>
          setAction({...action, form: {...action.form, title: value || undefined}})
        }
        variables={eventVariables}
        className='pt-3'
        errorOccurred={errorOccurred}
      />

      <NumberInputField
        name='Expected duration (m)*'
        initial={action.form.expected_duration}
        onchange={(value) =>
          setAction({...action, form: {...action.form, expected_duration: value}})
        }
        className='pt-3'
        errorOccurred={errorOccurred}
      />

      <WorkOrderCategorySelectField
        initial={action.form.category}
        onchange={(value) => setAction({...action, form: {...action.form, category: value}})}
        className='pt-3'
      />

      <PrioritySelectField
        initial={action.form.priority}
        onchange={(value) => setAction({...action, form: {...action.form, priority: value}})}
        className='pt-3'
      />

      <UsersSelectField
        name='Assignees'
        initial={action.form.assignees || undefined}
        onchange={(values) =>
          setAction({
            ...action,
            form: {...action.form, assignees: !values.length ? undefined : values},
          })
        }
        className='pt-3'
      />

      <FactoryEntitySelectField
        initial={action.form.factory_entity || undefined}
        onchange={(value) => setAction({...action, form: {...action.form, factory_entity: value}})}
        className='pt-3'
      />

      <MultiTextInputField
        name='Actions'
        initial={action.form.actions || undefined}
        onchange={(values) =>
          setAction({
            ...action,
            form: {...action.form, actions: !values.length ? undefined : values},
          })
        }
        buttonText='Add action +'
        className='pt-3'
      />
    </>
  )
}

export default CreateWorkOrder
