import {useEffect, useRef} from 'react'
import {PieData, pieConfig} from '../../core/_chartConfigs/pieConfig'
import * as am5 from '@amcharts/amcharts5'
import {ChartLoader} from '../ChartLoader'

type PieGraphProps = {
  id: string
  data?: PieData[]
  height: string
}

const PieGraph = ({id, data, height}: PieGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)

  const loading = data === undefined

  useEffect(() => {
    if (loading) return

    chartRef.current = pieConfig(id, data)

    return () => {
      if (chartRef.current) chartRef.current.dispose()
    }
  }, [data, loading])

  return <ChartLoader loading={loading} chartId={id} height={height} />
}

export {PieGraph}
