import {useQueryClient} from 'react-query'
import {ContentProps, FormModalWrapper} from '../../components/FormModalWrapper'
import {createInvitation, useRoles} from '../../core/requests/auth'
import {useAuth} from '../../modules/auth'

interface IInviteUsers {
  invitations: {
    email: string
    role: string
  }[]
}

type InviteUsersModalProps = {
  show: boolean
  hide: () => void
}

const InviteUsersModal = ({show, hide}: InviteUsersModalProps) => {
  const user = useAuth()
  const {data: roles} = useRoles({
    organization: user.currentProfile?.organization?._id,
  })
  const cache = useQueryClient()

  const submit = async (data: IInviteUsers) => {
    await Promise.all(
      data.invitations.map((invitation) =>
        createInvitation({role: invitation.role, email: invitation.email})
      )
    )
    await cache.invalidateQueries(['invitations'])
  }

  return (
    <FormModalWrapper<IInviteUsers>
      id='hs_invite_users_modal'
      title='Invite users to the organization'
      show={show}
      close={hide}
      defaultData={{invitations: []}}
      submit={submit}
      body={{content: Content}}
      validation={(data) =>
        data.invitations?.length > 0 &&
        // check for duplicate emails
        data.invitations?.every((invitation) => invitation.role !== '')
      }
      loading={roles === undefined}
    />
  )
}

const Content = ({data, updateData, hasError}: ContentProps<IInviteUsers>) => {
  const user = useAuth()
  const {data: roles} = useRoles({
    organization: user.currentProfile?.organization?._id,
  })

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let emails = e.target.value.split(/[\s,]+/).map((email) => email.trim())
    emails = emails.filter((email) => isValidEmail(email))
    updateData({invitations: emails.map((email) => ({email, role: ''}))})
  }

  return (
    <>
      <div className='d-flex flex-column fv-row mb-8'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Emails</span>
          <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip' title='...' />
        </label>
        <textarea
          className='form-control form-control-solid'
          rows={3}
          placeholder='example@hexastate.com,example2@hexastate.com'
          onChange={handleEmailsChange}
        />
        {data.invitations?.length === 0 && hasError && (
          <div className='fv-plugins-message-container'>
            <div data-field='category' data-validator='notEmpty' className='fv-help-block'>
              Please enter at least one valid email
            </div>
          </div>
        )}
      </div>

      {data.invitations?.length > 0 && (
        <div className='mb-10'>
          <div className='fs-6 fw-semibold mb-2'>Your Invitations</div>
          <div className='mh-300px scroll-y me-n7 pe-7'>
            {data.invitations?.map((invitation, index) => (
              <div className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'>
                <div className='ms-5'>
                  <a className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                    {invitation.email}
                  </a>
                </div>
                <div className='ms-2 w-200px'>
                  <select
                    className='form-select form-select-solid form-select-sm'
                    data-hide-search='true'
                    value={invitation.role}
                    onChange={(e) => {
                      const invitations = [...data.invitations]
                      invitations[index].role = e.target.value
                      updateData({invitations})
                    }}
                  >
                    <option value=''>Select Role</option>
                    {roles?.items?.map((role) => (
                      <option key={role._id} value={role._id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
          {!data.invitations?.every((invitation) => invitation.role !== '') && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='category' data-validator='notEmpty' className='fv-help-block'>
                Please select a role for each invitation
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default InviteUsersModal
