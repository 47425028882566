import {LookbackValue} from '../../_metronic/partials/layout/lookback/LookbackProvider'
import {IntervalOption} from '../components/form-components/TimeIntervalField'

export const timeStampToString = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const formatter = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  })
  return formatter.format(date)
}

export const rfc3339ToString = (rfc3339: string) => {
  const date = new Date(rfc3339)
  const formatter = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  })
  return formatter.format(date)
}

export function convertSecondsToHoursMinutesSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  let timeString = ''
  if (hours > 0) timeString += hours === 1 ? '1 hour' : `${hours} hours`
  if (minutes > 0) {
    if (timeString !== '') {
      timeString += ', '
    }
    timeString += minutes === 1 ? '1 minute' : `${minutes} minutes`
  }
  if (remainingSeconds > 0) {
    if (timeString !== '') {
      timeString += ', '
    }
    timeString += remainingSeconds === 1 ? '1 second' : `${remainingSeconds} seconds`
  }
  if (timeString === '') timeString = '0 seconds'
  return timeString
}

export function convertSecondsToHoursMinutesSecondsShort(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.round(seconds % 60)

  let timeString = ''
  if (hours > 0) timeString += hours === 1 ? '1h' : `${hours}h`
  if (minutes > 0) {
    if (timeString !== '') {
      timeString += ' '
    }
    timeString += minutes === 1 ? '1m' : `${minutes}m`
  }
  if (remainingSeconds > 0) {
    if (timeString !== '') {
      timeString += ' '
    }
    timeString += remainingSeconds === 1 ? '1s' : `${remainingSeconds}s`
  }
  if (timeString === '') timeString = '0s'
  return timeString
}

export function lookbackValueToTime(lookback: LookbackValue) {
  const now = new Date()

  switch (lookback) {
    case 'last day':
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toISOString()
    case 'last week':
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).toISOString()
    case 'last month':
      return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).toISOString()
    // case 'all time':
    //   return undefined
  }
}

export function toIso8601Duration(duration: number, interval: IntervalOption) {
  switch (interval) {
    case 'hours':
      return `PT${duration}H`
    case 'days':
      return `P${duration}D`
    case 'weeks':
      return `P${duration}W`
    case 'months':
      return `P${duration}M`
  }
}

export function fromIso8601Duration(isoDuration: string): {
  number: number
  interval: IntervalOption
} {
  const match = isoDuration.match(/\d+/)
  if (match === null) throw new Error(`Invalid ISO8601 duration: ${isoDuration}`)

  const number = parseInt(match[0])

  if (isoDuration.includes('H')) return {number, interval: 'hours'}
  if (isoDuration.includes('D')) return {number, interval: 'days'}
  if (isoDuration.includes('W')) return {number, interval: 'weeks'}
  if (isoDuration.includes('M')) return {number, interval: 'months'}

  throw new Error(`Invalid ISO8601 duration: ${isoDuration}`)
}

export function startTimeFromIso8601Duration(isoDuration: string) {
  const {number, interval} = fromIso8601Duration(isoDuration)
  const now = new Date()

  const year = now.getFullYear()
  const month = now.getMonth()
  const date = now.getDate()
  const hours = now.getHours()
  const minutes = now.getMinutes()

  switch (interval) {
    case 'hours':
      return new Date(year, month, date, hours - number, minutes).toISOString()
    case 'days':
      return new Date(year, month, date - number, hours, minutes).toISOString()
    case 'weeks':
      return new Date(year, month, date - number * 7, hours, minutes).toISOString()
    case 'months':
      return new Date(year, month - number, date, hours, minutes).toISOString()
  }
}
