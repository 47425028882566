export function sanitizeUndefinedFields<T extends Record<string, any>>(data: T): T {
  const sanitizedData: {[key in keyof T]: T[key] | null} = {...data}

  for (const key in sanitizedData) {
    if (Object.prototype.hasOwnProperty.call(sanitizedData, key)) {
      if (sanitizedData[key] === undefined) {
        sanitizedData[key] = null
      }
    }
  }

  return sanitizedData as T
}

export const patchObjectDates = <T extends Object>(obj: T, fields: Array<keyof T>) => {
  fields.map((fieldName) => {
    if (typeof obj[fieldName] === 'string' && !!obj[fieldName]) {
      // @ts-ignore
      if (!new RegExp('\\+\\d{2}:\\d{2}$|Z$').test(obj[fieldName])) {
        // @ts-ignore
        obj[fieldName] = `${obj[fieldName]}+00:00`
      }
    }
  })
  return obj
}

export const patchObjectsDates = <T extends Object>(
  objs: T[],
  fields: Array<keyof T>
): typeof objs => {
  return objs.map((o) => patchObjectDates(o, fields))
}
