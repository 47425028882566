export type ICreatePlacement = {
  area?: string
  production_line?: string
  section?: string
  part?: string
  name?: string
  preferred_index?: number
  // Not model fields
  locationChoice?: 'area' | 'production_line' | 'section' | 'part'
}

export const defaultCreatePlacement: ICreatePlacement = {
  area: undefined,
  production_line: undefined,
  part: undefined,
  name: undefined,
  preferred_index: undefined,
  locationChoice: undefined,
}

export type StepProps = {
  data: ICreatePlacement
  updateData: (fieldsToUpdate: Partial<ICreatePlacement>) => void
  hasError: boolean
}
