import {useIntl} from 'react-intl'
import {StepProps} from './_models'
import TextField from '../../components/form-components/TextField'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* PLC Name */}
        <TextField
          name='PLC Name'
          data={data}
          updateData={updateData}
          dataField='name'
          hasError={hasError}
          tooltip={intl.formatMessage({id: 'TOOLTIP.NAMED_ENTITY.PLC'})}
          required
          lastField
        />
      </div>
    </div>
  )
}

export default Step1
