import React from 'react'
import {useQueryClient} from 'react-query'
import {KTSVG} from '../../../_metronic/helpers'
import {DashboardToolbar} from '../../../_metronic/layout/components/toolbar/DashboardToolbar'
import {DashboardPageTitle} from '../../../_metronic/layout/components/toolbar/page-title/DashboardPageTitle'
import GridLayout from 'react-grid-layout'
import PageNavigationBar from '../../components/PageNavigationBar'
import {patchObjectDates} from '../../core/request-util'
import {useDashboardGrid} from '../../providers/DashboardGridProvider'
import {Route, Routes, useParams} from 'react-router-dom'
import {BaseModel, Layout, Widget as WidgetModel} from '../../core/_models'
import LoadingWrapper from '../../components/LoadingWrapper'
import {useDashboard, useWidgets, deleteWidget} from '../../core/requests/template'
import {ConfirmDeletionModal} from '../overview/core/columns/TableSelectionToolbar'
import EnergyPiechartWidgetModal from './dashboard-grid/widget-forms/category/EnergyPiechartWidgetModal'
import ConditionWidgetModal from './dashboard-grid/widget-forms/condition/ConditionWidgetModal'
import EnergyWidgetModal from './dashboard-grid/widget-forms/energy/EnergyWidgetModal'
import LineWidgetModal from './dashboard-grid/widget-forms/line/LineWidgetModal'
import OeeWidgetModal from './dashboard-grid/widget-forms/oee/OeeWidgetModal'
import PieWidgetModal from './dashboard-grid/widget-forms/pie/PieWidgetModal'
import TextWidgetModal from './dashboard-grid/widget-forms/text/TextWidgetModal'
import {WidgetType} from './dashboard-grid/widgets/_models'
import Widget from './dashboard-grid/widgets/Widget'
import {SizeMe} from 'react-sizeme'
import PowerConsumptionWidgetModal from './dashboard-grid/widget-forms/energy/power-consumption/PowerConsumptionModal'
import WorkOrdersWidgetModal from './dashboard-grid/widget-forms/work-orders/WorkOrdersWidgetModal'
import TagWidgetModal from './dashboard-grid/widget-forms/tag/TagWidgetModal'

const ROW_HEIGHT = 30
const MARGIN = 10

type DashboardPageProps = {
  id: string
}

const lockedWidgetTypes: WidgetType[] = ['Health', 'Anomaly']

const DashboardPage = ({id}: DashboardPageProps) => {
  const queryClient = useQueryClient()

  const {
    layouts,
    heights,
    setLayouts,
    setHeights,
    handleGridResize,
    updateLayouts,
    handleChangeStart,
    widgetsLocked,
  } = useDashboardGrid()

  const {data: widgets = [], isLoading} = useWidgets({
    dashboards: [id],
    options: {
      onSuccess: async (widgets) => {
        const newLayouts: GridLayout.Layout[] = widgets.map((widget) => ({
          ...convertLayout(widget.layout),
          isDraggable: !widgetsLocked,
          isResizable: !widgetsLocked,
          i: widget._id,
          minW: undefined,
          maxW: undefined,
          minH: undefined,
          maxH: undefined,
        }))
        await setLayouts(newLayouts)

        const newHeights: number[] = widgets.map(
          (widget) => ROW_HEIGHT * widget.layout.h + MARGIN * (widget.layout.h - 1)
        )
        await setHeights(newHeights)
      },
    },
  })

  const [mode, setMode] = React.useState<'edit' | 'delete'>()
  const [selectedWidgetUUID, setSelectedWidgetUUID] = React.useState<string>()
  const _selectedWidget = widgets?.find((w) => w._id === selectedWidgetUUID)
  const selectedWidget = _selectedWidget
    ? patchObjectDates<WidgetModel & BaseModel>(_selectedWidget, ['start_time', 'end_time'])
    : undefined

  const handleDelete = async () => {
    if (selectedWidget) {
      await deleteWidget(selectedWidget._id)
      await queryClient.invalidateQueries(['widgets'])
    }
  }

  return (
    <LoadingWrapper loading={isLoading || widgets.length !== layouts.length}>
      <SizeMe refreshRate={16} refreshMode='debounce' key={widgets?.toString()}>
        {({size}) => (
          <div id='dashboard-grid'>
            <GridLayout
              key={widgets?.toString()}
              className='layout'
              cols={12}
              layout={layouts}
              rowHeight={ROW_HEIGHT}
              onResize={handleGridResize}
              onDrag={handleGridResize}
              onResizeStart={handleChangeStart}
              onDragStart={handleChangeStart}
              onResizeStop={updateLayouts}
              onDragStop={updateLayouts}
              width={size.width ?? 0}
            >
              {widgets?.map((widget, index) => (
                <div key={widget._id}>
                  {!widgetsLocked && (
                    <>
                      {lockedWidgetTypes.indexOf(widget.type) === -1 && (
                        <div
                          className='z-index-1 position-absolute btn btn-sm btn-icon btn-active-color-primary'
                          onClick={() => {
                            setMode('edit')
                            setSelectedWidgetUUID(widget._id)
                          }}
                        >
                          <KTSVG
                            className='svg-icon-1'
                            path='/media/icons/duotune/general/gen055.svg'
                          />
                        </div>
                      )}
                      <div
                        className='z-index-1 position-absolute end-0 btn btn-sm btn-icon btn-active-color-primary'
                        onClick={() => {
                          setMode('delete')
                          setSelectedWidgetUUID(widget._id)
                        }}
                      >
                        <KTSVG
                          className='svg-icon-1'
                          path='/media/icons/duotune/arrows/arr061.svg'
                        />
                      </div>
                    </>
                  )}
                  <Widget
                    widget={widget}
                    height={`${heights[index] - 50}px`}
                    enableNameEdit={!widgetsLocked}
                  />
                </div>
              ))}
            </GridLayout>
          </div>
        )}
      </SizeMe>
      {selectedWidget && (
        <>
          {mode === 'delete' && (
            <ConfirmDeletionModal
              show={true}
              hide={() => {
                setMode(undefined)
              }}
              deleteSelections={handleDelete}
            />
          )}
          {mode === 'edit' && (
            <>
              <LineWidgetModal
                show={selectedWidget.type === 'Line'}
                hide={() => setMode(undefined)}
                widgetData={selectedWidget}
              />
              <OeeWidgetModal
                show={
                  selectedWidget.type === 'OEE Availability' ||
                  selectedWidget.type === 'OEE Performance' ||
                  selectedWidget.type === 'OEE Quality' ||
                  selectedWidget.type === 'OEE' ||
                  selectedWidget.type === 'Timeline' ||
                  selectedWidget.type === 'Pareto' ||
                  selectedWidget.type === 'Stop Registration' ||
                  selectedWidget.type === 'Event Logs'
                }
                hide={() => setMode(undefined)}
                type={selectedWidget.type}
                widgetData={selectedWidget}
              />
              <EnergyWidgetModal
                show={
                  selectedWidget.type === 'Power Factor' ||
                  // selectedWidget.type === 'Power Consumption' ||
                  selectedWidget.type === 'Energy Consumption' ||
                  selectedWidget.type === 'Standby Consumption' ||
                  selectedWidget.type === 'Potential Savings' ||
                  selectedWidget.type === 'CO2 Emissions'
                }
                hide={() => setMode(undefined)}
                type={selectedWidget.type}
                widgetData={selectedWidget}
              />
              <PowerConsumptionWidgetModal
                show={selectedWidget.type === 'Power Consumption'}
                hide={() => setMode(undefined)}
                widgetData={selectedWidget}
              />
              <TextWidgetModal
                show={selectedWidget.type === 'Text'}
                hide={() => setMode(undefined)}
                widgetData={selectedWidget}
              />
              <ConditionWidgetModal
                show={selectedWidget.type === 'Health' || selectedWidget.type === 'Anomaly'}
                hide={() => setMode(undefined)}
                type={selectedWidget.type}
                widgetData={selectedWidget}
              />
              <PieWidgetModal
                show={selectedWidget.type === 'Pie'}
                hide={() => setMode(undefined)}
                widgetData={selectedWidget}
              />
              <WorkOrdersWidgetModal
                show={selectedWidget.type === 'Work Orders'}
                hide={() => setMode(undefined)}
                widgetData={selectedWidget}
              />
              <EnergyPiechartWidgetModal
                show={selectedWidget.type === 'Energy Piechart'}
                hide={() => setMode(undefined)}
                widgetData={selectedWidget}
              />
              <TagWidgetModal
                show={selectedWidget.type === 'Tag'}
                hide={() => setMode(undefined)}
                widgetData={selectedWidget}
              />
            </>
          )}
        </>
      )}
    </LoadingWrapper>
  )
}

function convertLayout(layout: Layout): GridLayout.Layout {
  const convertedLayout: GridLayout.Layout = {
    i: layout.i,
    x: layout.x,
    y: layout.y,
    w: layout.w,
    h: layout.h,
    minW: layout.min_w ?? undefined,
    maxW: layout.max_w ?? undefined,
    minH: layout.min_h ?? undefined,
    maxH: layout.max_h ?? undefined,
    moved: layout.moved ?? undefined,
    static: layout.static ?? undefined,
    isDraggable: layout.is_draggable ?? undefined,
    isResizable: layout.is_resizable ?? undefined,
    resizeHandles: layout.resize_handles ?? undefined,
    isBounded: layout.is_bounded ?? undefined,
  }

  return convertedLayout
}

export const DashboardWrapper = () => {
  const {id} = useParams()
  const {data: dashboard, isLoading} = useDashboard({
    dashboard: id as string,
    options: {
      enabled: !!id,
    },
  })
  const {setActiveDashboardId} = useDashboardGrid()

  React.useEffect(() => {
    if (!setActiveDashboardId) return

    setActiveDashboardId(id)

    return () => {
      setActiveDashboardId(undefined)
    }
  }, [id, setActiveDashboardId])

  return (
    <LoadingWrapper loading={isLoading || !dashboard}>
      {dashboard && (
        <>
          <PageNavigationBar
            title={
              <div className={`d-flex text-nowrap`}>
                {dashboard?.name}
                <DashboardPageTitle />
              </div>
            }
          >
            <DashboardToolbar />
          </PageNavigationBar>
          <DashboardPage id={dashboard._id} />
        </>
      )}
    </LoadingWrapper>
  )
}

const DashboardsPage = () => (
  <Routes>
    <Route path=':id' element={<DashboardWrapper />} />
  </Routes>
)

export {DashboardsPage}
