import {StepProps} from './_models'
import RichTextField from '../../../../../components/form-components/RichTextField'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <RichTextField
          name='Text'
          data={data}
          updateData={updateData}
          dataField='name'
          required
          hasError={hasError}
          lastField
        />
      </div>
    </div>
  )
}

export default Step1
