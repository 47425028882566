import {useRef, useState} from 'react'
import {createEventCategoryGroup} from '../../../core/requests/oee'
import {useQueryClient} from 'react-query'
import {useCategorySelection} from '../CategorySelectionContext'

type AddEventCategorySidebarItemProps = {}

const AddEventCategorySidebarItem = ({}: AddEventCategorySidebarItemProps) => {
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [newCategoryGroupTitle, setNewCategoryGroupTitle] = useState('')
  const enterPressedRef = useRef(false) // Using ref instead of state

  const queryClient = useQueryClient()
  const {setActiveCategoryGroup} = useCategorySelection()

  const handleAddCategoryGroup = () => {
    setIsActive(true)
  }

  const onSubmit = async () => {
    if (isLoading || newCategoryGroupTitle === '') {
      setIsActive(false)
      return
    }
    setIsLoading(true)
    try {
      const newCategoryGroup = await createEventCategoryGroup({
        title: newCategoryGroupTitle,
        template: false,
      })
      await queryClient.invalidateQueries('eventCategoryGroups')
      setActiveCategoryGroup(newCategoryGroup)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      setIsActive(false)
      setNewCategoryGroupTitle('')
      enterPressedRef.current = false
    }
  }

  return (
    <div className='my-2 text-muted'>
      <div className='d-flex align-items-center cursor-pointer' onClick={handleAddCategoryGroup}>
        <i className='me-1 fa-solid fa-plus' style={{opacity: isActive ? 0 : 1}} />
        {!isActive && <span className='fs-3 ps-1 flex-fill'>Add Group</span>}
        {isActive && (
          <div className='row ms-0 align-items-center'>
            <input
              type='text'
              value={newCategoryGroupTitle}
              onChange={(e) => setNewCategoryGroupTitle(e.target.value)}
              onBlur={() => {
                if (!enterPressedRef.current) onSubmit() // Check the ref value instead of state
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  enterPressedRef.current = true // Update the ref immediately
                  onSubmit()
                }
              }}
              className='col-10 fs-3 m-0 p-0 ps-1 text-muted'
              style={{border: 'none', background: 'transparent', outline: 'none'}}
              autoFocus
              disabled={isLoading}
            />
            {!isLoading && <i className='col-2 fs-3 fa-solid fa-check' onClick={onSubmit} />}
            {isLoading && (
              <div className='col-2'>
                <i className='fs-3 fa-solid fa-spinner fa-spin' />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AddEventCategorySidebarItem
