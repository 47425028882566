export type ICreateDashboard = {
  name?: string
  tags?: string[]
  shared_with?: string[]
}

export const defaultCreateDashboard: ICreateDashboard = {
  name: undefined,
  tags: undefined,
  shared_with: undefined,
}

export type StepProps = {
  data: ICreateDashboard
  updateData: (fieldsToUpdate: Partial<ICreateDashboard>) => void
  hasError: boolean
}
