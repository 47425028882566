import {WorkOrderCategory, WorkOrderStatus} from '../../../../../core/_models'
import {IWidget, defaultCreateWidget, defaultLayout} from '../../widgets/_models'

export type IWorkOrdersWidget = IWidget & {
  category?: WorkOrderCategory
  status?: WorkOrderStatus
}

export const defaultCreateTextWidget: IWorkOrdersWidget = {
  ...defaultCreateWidget,
  type: 'Work Orders',
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
    min_w: 4,
    min_h: 7,
  },
}

export type StepProps = {
  data: IWorkOrdersWidget
  updateData: (fieldsToUpdate: Partial<IWorkOrdersWidget>) => void
  hasError: boolean
}
