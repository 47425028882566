import usePagination, {UsePaginationProps} from '../../../../components/pagination/usePagination'
import {Area, Organization, Section} from '../../../../core/_models'
import {UseAssetsProps} from '../../../../core/requests/factory'
import {useFilterState} from './useFilterState'

const useAssetsFilter = (props: UsePaginationProps = {}) => {
  const {activePage, setActivePage, pageSize} = usePagination({
    initialPage: props.initialPage,
    size: props.size,
  })

  const filterState = {
    organization: useFilterState<Organization>(),
    area: useFilterState<Area>(),
    section: useFilterState<Section>(),
  }

  const assetFilterProps: UseAssetsProps = {
    organization: filterState.organization.value?._id,
    area: filterState.area.value?._id,
    section: filterState.section.value?._id,
    page: activePage,
    size: pageSize,
    options: {
      keepPreviousData: true,
    },
  }
  return {
    filterState,
    filterCount: Object.values(filterState).filter((v) => v.value !== undefined).length,
    assetFilterProps,
    activePage,
    setActivePage,
    pageSize,
  }
}

export default useAssetsFilter
