import * as am5 from '@amcharts/amcharts5'
import useAdvancedHealthHistory from '../../../../core/graphql/condition/queries/AdvancedHealthHistory'
import useSimpleHealthHistory from '../../../../core/graphql/condition/queries/SimpleHealthHistory'
import {ChartLoader} from '../ChartLoader'
import {healthChartConfig} from '../../core/_chartConfigs/healthChartConfig'
import React from 'react'

type HealthGraphProps = {
  assetId: string
  type: 'simple' | 'advanced'
  startTime?: string
  endTime?: string
  height: string
  chartId?: string
}

const HealthGraph = ({
  assetId,
  type,
  startTime,
  endTime,
  height,
  chartId = `${type}-health-${assetId}`,
}: HealthGraphProps) => {
  const chartRef = React.useRef<am5.Root | null>(null)

  const {data: simpleHistory = [], isLoading: simpleHistoryLoading} = useSimpleHealthHistory({
    id: type === 'simple' ? assetId : undefined,
    startTime,
    endTime,
  })

  const {data: advancedHistory = [], isLoading: advancedHistoryLoading} = useAdvancedHealthHistory({
    id: type === 'advanced' ? assetId : undefined,
    startTime,
    endTime,
  })

  const data = React.useMemo(
    () =>
      (type === 'simple' ? simpleHistory : advancedHistory).map((h) => ({
        time: h.time * 1000,
        value: h.value,
      })),
    [advancedHistory, simpleHistory, type]
  )

  const isLoading = advancedHistoryLoading || simpleHistoryLoading

  React.useEffect(() => {
    if (isLoading) return

    chartRef.current = healthChartConfig(chartId, data)

    return () => {
      chartRef.current?.dispose()
    }
  }, [chartId, data, isLoading])

  return <ChartLoader loading={isLoading} chartId={chartId} height={height} />
}

export {HealthGraph}
