import {useEffect, useRef, useState} from 'react'
import LoadingWrapper from '../../../../components/LoadingWrapper'
import {updateTag, useTag} from '../../../../core/requests/factory'
import {WidgetProps} from './Widget'
import clsx from 'clsx'
import {useQueryClient} from 'react-query'

const TagWidget = ({widget}: WidgetProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [tagValue, setTagValue] = useState('')
  const widgetRef = useRef<HTMLDivElement | null>(null)
  const queryClient = useQueryClient()

  const {data: tag} = useTag({id: widget.entity})

  useEffect(() => {
    setTagValue(tag?.value || '')
  }, [tag?.value])

  const editing = tagValue !== tag?.value

  const cancelEdit = () => {
    setTagValue(tag?.value || '')
  }

  const onSubmit = async () => {
    if (!tag || isSubmitting) return
    setIsSubmitting(true)
    try {
      await updateTag({id: tag._id, value: tagValue})
      await queryClient.invalidateQueries(['tag', tag._id])
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    const handleClickOutsideRow = (event: MouseEvent) => {
      if (!isSubmitting && widgetRef.current && !widgetRef.current.contains(event.target as Node))
        cancelEdit()
    }

    if (!isSubmitting && editing) {
      document.addEventListener('mousedown', handleClickOutsideRow)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideRow)
    }
  }, [editing])

  return (
    <LoadingWrapper loading={tag === undefined}>
      <div ref={widgetRef} className='d-flex align-items-center justify-content-center h-100 px-10'>
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex align-items-center gap-5'>
            <div className='fs-3 fw-bold'>{`${tag?.key}:`}</div>
            <input
              type='text'
              className='form-control fs-3 form-control-sm form-control-solid-flush border'
              placeholder='Tag Value'
              value={tagValue}
              onChange={(e) => {
                setTagValue(e.target.value)
              }}
              disabled={isSubmitting}
            />
          </div>
          <div className='d-flex justify-content-center align-items-center gap-2'>
            <a
              className={clsx('btn btn-sm fw-bold btn-secondary', !editing && 'disabled')}
              onClick={cancelEdit}
            >
              Cancel
            </a>
            <a
              className={clsx('btn btn-sm fw-bold btn-primary', !editing && 'disabled')}
              onClick={onSubmit}
            >
              Save
            </a>
            {isSubmitting && <i className='fa-solid fa-spinner fa-spin me-7 float-end' />}
          </div>
        </div>
      </div>
    </LoadingWrapper>
  )
}

export default TagWidget
