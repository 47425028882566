import React, {useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {useMutation, useQueryClient} from 'react-query'
import {QueryKey} from 'react-query/types/core/types'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../_metronic/helpers'
import LoadingOverlayWrapper from '../../components/LoadingOverlayWrapper'
import {DeletionConfirmation, Reference} from '../../core/_models'
import useModalState from '../../hooks/UseModalState'

interface UseEntityDeleteModalProps {
  entity?: Reference
  deleteHandler: (_id: string) => Promise<DeletionConfirmation>
  onSuccess?: () => void
  queryToInvalidate?: QueryKey
  message?: string
}

const useEntityDeleteModal = ({
  entity,
  deleteHandler,
  onSuccess,
  queryToInvalidate,
  message,
}: UseEntityDeleteModalProps) => {
  const modalState = useModalState()
  const cache = useQueryClient()
  const [entityObject, setEntityObject] = React.useState<typeof entity>(entity)

  useEffect(() => {
    setEntityObject(entity)
  }, [entity])

  const mutation = useMutation({
    mutationFn: () => deleteHandler(entityObject?._id as string),
    onSuccess: async () => {
      toast.success(`Entity "${entityObject?.name as string}" was deleted successfully`)
      modalState.close()
      if (queryToInvalidate) {
        await cache.invalidateQueries(queryToInvalidate)
      }
      if (onSuccess) {
        onSuccess()
      }
    },
    onError: (error) => {
      toast.error(`Can't delete entity "${entityObject?.name as string}"`)
      console.warn(error)
    },
  })

  if (!entityObject) {
    return {
      handler: null,
      setEntity: setEntityObject,
    }
  }

  const modal = (
    <Modal
      dialogClassName='modal-dialog modal-dialog-centered'
      tabIndex={-1}
      aria-hidden='true'
      size='lg'
      show={modalState.isOpen}
      backdrop={true}
      onHide={modalState.close}
    >
      <div className='modal-header'>
        <h2>{message ? message : `Confirm to delete ${entityObject.name}`}</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={modalState.close}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <LoadingOverlayWrapper loading={mutation.isLoading}>
        <div className='modal-footer py-3'>
          <button type='button' className='btn btn-secondary' onClick={modalState.close}>
            Cancel
          </button>
          <button type='button' className='btn btn-danger' onClick={() => mutation.mutate()}>
            Confirm
          </button>
        </div>
      </LoadingOverlayWrapper>
    </Modal>
  )
  const handler = (
    <>
      <button className='btn btn-icon btn-active-color-danger' onClick={modalState.open}>
        <i className='fa fa-trash-can fs-3' />
      </button>
      {modal}
    </>
  )
  return {
    handler,
    setEntity: setEntityObject,
  }
}

export default useEntityDeleteModal
