import React from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'

type ListFilterProps = {
  count: number
  filters: React.ReactNode[]
}

const ListFilter = ({filters, count}: ListFilterProps) => {
  React.useEffect(() => {
    document.querySelectorAll('.kt-list-filter').forEach((menuElement) => {
      const menu = MenuComponent.getInstance(menuElement as HTMLElement)
      menu?.reset(menuElement as HTMLElement)
    })
    MenuComponent.createInstances(`.kt-list-filter`)
  }, [])
  return (
    <div>
      <button
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <span className='svg-icon ms-0 me-3'>
          <div className={`fa-duotone fa-solid fa-filter`}></div>
        </span>
        Filters
        {count > 0 && <span className={`badge ms-2 fs-7 badge-light-success`}>{count}</span>}
      </button>

      <div
        className='kt-list-filter menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-auto min-w-200 mw-300px'
        data-kt-menu='true'
        style={{zIndex: 100000}}
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bold'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          {filters.map((filter, i) => (
            <div className='mb-2' key={`table-filter-${i}`}>
              {filter}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListFilter
