import React from 'react'
import {Factory} from '../../../core/_models'
import {useAreas, useAreasPaginated} from '../../../core/requests/factory'
import AreaNode from './AreaNode'
import TreeNode from './TreeNode'

interface FactoryNodeProps {
  factory: Factory
}

export const FactoryNode = ({factory}: FactoryNodeProps) => {
  const [opened, setOpen] = React.useState(false)
  const {data: areas} = useAreas({factory: factory._id})
  const {items, isFetching, hasNextPage, fetchNextPage} = useAreasPaginated(
    {factory: factory._id},
    {
      enabled: opened,
    }
  )

  React.useEffect(() => {
    if (!isFetching && hasNextPage) {
      ;(async () => {
        await fetchNextPage()
      })()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  const nodes = items?.map((area) => <AreaNode area={area} />)

  return (
    <TreeNode
      name={factory.name}
      icon={`fa-industry`}
      opened={opened}
      setOpen={setOpen}
      nodes={nodes}
      isExpandable={!!areas?.total}
    />
  )
}

export default FactoryNode
