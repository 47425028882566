import {useQueryClient} from 'react-query'
import {createProduct, editProduct} from '../../core/requests/oee'
import {ContentProps, FormModalWrapper} from '../../components/FormModalWrapper'
import TextField from '../../components/form-components/TextField'
import {useProfile} from '../../core/requests/auth'
import {Product} from '../../core/_models'
import {useProductionLines} from '../../core/requests/factory'
import LoadingWrapper from '../../components/LoadingWrapper'
import SelectFieldBoxesMultiple from '../../components/form-components/SelectFieldBoxesMultiple'
import {useIntl} from 'react-intl'

export interface ICreateProduct {
  organization?: string
  name?: string
  sku?: string
  compatible_production_lines?: string[]
}

export interface IEditProduct {
  name?: string
  sku?: string
  compatible_production_lines?: string[]
}

const defaultCreateProductData: ICreateProduct = {
  organization: undefined,
  name: undefined,
  sku: undefined,
  compatible_production_lines: undefined,
}

type Props = {
  show: boolean
  hide: () => void
  edit?: Product
}

const CreateProductsModal = ({show, hide, edit}: Props) => {
  const queryClient = useQueryClient()
  const {data: profile} = useProfile()

  const submitCreate = async (productData: ICreateProduct) => {
    if (!profile?.organization?._id) return

    productData.organization = profile.organization._id
    await createProduct(productData)
    queryClient.invalidateQueries('products')
  }

  const submitEdit = async (productData: ICreateProduct) => {
    const editData: IEditProduct = {
      name: productData.name,
      sku: productData.sku,
      compatible_production_lines: productData.compatible_production_lines || [],
    }
    await editProduct(edit!._id, editData)
    queryClient.invalidateQueries('products')
  }

  return (
    <FormModalWrapper<ICreateProduct>
      id='hs_create_product'
      title={edit === undefined ? 'Create Product' : 'Edit Product'}
      show={show}
      close={hide}
      defaultData={
        edit
          ? {
              organization: undefined,
              name: edit.name,
              sku: edit.sku,
              compatible_production_lines: edit.compatible_production_lines,
            }
          : defaultCreateProductData
      }
      submit={edit === undefined ? submitCreate : submitEdit}
      validation={(data) =>
        data.name !== undefined &&
        data.sku !== undefined &&
        data.compatible_production_lines !== undefined &&
        data.compatible_production_lines.length > 0
      }
      body={{content: Content}}
      loading={profile?.organization === undefined}
      // size='sm'
    />
  )
}

const Content = ({data, updateData, hasError}: ContentProps<ICreateProduct>) => {
  const intl = useIntl()

  const {data: productionLines} = useProductionLines()

  return (
    <div className='w-100'>
      <LoadingWrapper loading={productionLines === undefined}>
        <TextField
          name='Name'
          data={data}
          updateData={updateData}
          dataField='name'
          hasError={hasError}
          required
        />
        <TextField
          name='SKU'
          data={data}
          updateData={updateData}
          dataField='sku'
          hasError={hasError}
          required
          tooltip={intl.formatMessage({id: 'TOOLTIP.SKU'})}
        />
        <SelectFieldBoxesMultiple
          name='Compatible Production Lines'
          optionNames={productionLines?.items.map((pl) => pl.name) || []}
          optionValues={productionLines?.items.map((pl) => pl._id) || []}
          data={data}
          updateData={updateData}
          dataField='compatible_production_lines'
          colSpace={4}
          required
          hasError={hasError}
          lastField
        />
      </LoadingWrapper>
    </div>
  )
}

export default CreateProductsModal
