/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import {EditUserModal} from '../../../../app/modals/edit-user/EditUserModal'
import ChangePasswordModal from '../../../../app/modals/change-password/ChangePasswordModal'
import useModalState from '../../../../app/hooks/UseModalState'
import AutomationsModal from '../../../../app/modals/automations/AutomationsModal'

const HeaderUserMenu: FC = () => {
  const {currentProfile: currentUser, logout} = useAuth()

  const editUserModal = useModalState()
  const automationsModal = useModalState()
  const changePasswordModal = useModalState()

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex px-3'>
            <div className='symbol symbol-50px me-5'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/icons/duotune/communication/com006.svg')}
              />
            </div>

            <div className='d-flex flex-column overflow-hidden'>
              <a href='#' className='fw-bold text-black text-hover-primary fs-5'>
                {currentUser?.email}
              </a>
              {currentUser?.phone_number ? (
                <div className='text-muted fw-bold fs-6'>
                  {currentUser.phone_number}
                  <button
                    type='button'
                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary ms-n2'
                    onClick={editUserModal.open}
                  >
                    <i className='fa-solid fa-pen fs-5' />
                  </button>
                </div>
              ) : (
                <div
                  className='text-muted fw-bold text-hover-primary fs-6'
                  style={{cursor: 'pointer'}}
                  onClick={editUserModal.open}
                >
                  <button
                    type='button'
                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary ms-n5 me-n3'
                  >
                    <i className='fa-solid fa-plus fs-5' />
                  </button>
                  Add phone number
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='menu-item px-5'>
          <a onClick={automationsModal.open} className='menu-link px-5'>
            Automations
          </a>
        </div>

        <div className='separator my-2' />

        <div className='menu-item px-5'>
          <a onClick={changePasswordModal.open} className='menu-link px-5'>
            Change Password
          </a>
        </div>

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            Sign Out
          </a>
        </div>
      </div>

      <AutomationsModal show={automationsModal.isOpen} handleClose={automationsModal.close} />

      <ChangePasswordModal
        show={changePasswordModal.isOpen}
        handleClose={changePasswordModal.close}
      />

      {currentUser && (
        <EditUserModal
          user={currentUser}
          show={editUserModal.isOpen}
          handleClose={editUserModal.close}
        />
      )}
    </>
  )
}

export {HeaderUserMenu}
