import {useQueryClient} from 'react-query'
import {Asset, EventCategory, EventCategoryGroup, ProductionLine} from '../../../core/_models'
import {useCategorySelection} from '../CategorySelectionContext'
import {
  UpdateEventCategoryGroupProps,
  UpdateEventCategoryProps,
  createEventCategory,
  updateEventCategory,
  updateEventCategoryGroup,
  useEventCategories,
  useEventCategoryGroups,
} from '../../../core/requests/oee'
import React, {useEffect, useRef, useState} from 'react'
import ContentTitle from './ContentTitle'
import clsx from 'clsx'
import {useProfile} from '../../../core/requests/auth'
import {ColorPicker} from '../../../components/ColorPicker'
import {PROTECTED_CATEGORIES} from '../EventCategoriesModal'
import {useFactoryEntities} from '../../../core/requests/factory'
import {isAsset, isProductionLine} from '../../../core/entity-type-util'
import ProductionLineSelect from '../../../pages/overview/core/components/ProductionLineSelect'
import Select from 'react-select'
import AssetSelect from '../../../pages/overview/core/components/AssetSelect'
import IconPicker from '../../../components/IconPicker'

const DisplayCategoryGroup = ({activeCategoryGroup}: {activeCategoryGroup: EventCategoryGroup}) => {
  const queryClient = useQueryClient()
  const {setActiveCategoryGroup, openDeleteModal} = useCategorySelection()
  const {data: eventCategories} = useEventCategories()
  const {data: activeCategoryGroups} = useEventCategoryGroups({isActive: true})

  const [activeNavItem, setActiveNavItem] = useState<string>('Categories')

  const updateGroup = async (updates: Partial<UpdateEventCategoryGroupProps>) => {
    try {
      const updatedCategoryGroup = await updateEventCategoryGroup({
        id: activeCategoryGroup._id,
        ...updates,
      })
      await queryClient.invalidateQueries('eventCategoryGroups')
      setActiveCategoryGroup(updatedCategoryGroup)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <ContentTitle
        title={activeCategoryGroup.title}
        onEdit={(newTitle: string) => updateGroup({title: newTitle})}
        className='mb-5'
      />

      <ul className='nav nav-stretch nav-pills nav-pills-custom d-flex mb-5'>
        <li className='nav-item' onClick={() => setActiveNavItem('Categories')}>
          <a
            className={clsx(
              'nav-link btn btn-color-gray-400 flex-center px-1',
              activeNavItem === 'Categories' && 'active'
            )}
          >
            <span className='nav-text fw-semibold fs-4'>Categories</span>
            <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
          </a>
        </li>
        <li className='nav-item' onClick={() => setActiveNavItem('Entities')}>
          <a
            className={clsx(
              'nav-link btn btn-color-gray-400 flex-center px-1',
              activeNavItem === 'Entities' && 'active'
            )}
          >
            <span className='nav-text fw-semibold fs-4'>Entities</span>
            <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
          </a>
        </li>
      </ul>

      {activeNavItem === 'Categories' && (
        <div className='container border border-secondary rounded py-1'>
          <div className='row p-1'>
            <div className='col-4 text-muted'>Title</div>
            <div className='col-4 text-muted'>Sub Categories</div>
            <div className='col-2 text-muted'>Unscheduled?</div>
            <div className='col-2 text-muted'>Operational?</div>
          </div>
          {eventCategories?.items
            .filter((eventCategory) =>
              eventCategory.event_category_groups.includes(activeCategoryGroup._id)
            )
            .map((eventCategory) => (
              <EventCategoryRow eventCategory={eventCategory} key={eventCategory._id} />
            ))}
          <NewEventCategoryRow activeCategoryGroup={activeCategoryGroup} />
        </div>
      )}

      {activeNavItem === 'Entities' && <EntitiesTable group={activeCategoryGroup} />}

      <div className='d-flex justify-content-between mt-auto align-items-center'>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={activeCategoryGroup.is_active}
            disabled={
              (activeCategoryGroups === undefined || activeCategoryGroups.items.length !== 0) &&
              !activeCategoryGroup.is_active
            }
            onChange={(e) => updateGroup({isActive: e.target.checked})}
          />
          <span className='form-check-label fw-semibold text-muted'>Active</span>
        </label>

        <button
          className='d-flex btn btn-danger fw-semibold align-items-center'
          onClick={() => openDeleteModal(activeCategoryGroup)}
        >
          <i className='me-1 fa-solid fa-trash' />
          {`Delete ${activeCategoryGroup.title}`}
        </button>
      </div>
    </>
  )
}

const EntitiesTable = ({group}: {group: EventCategoryGroup}) => {
  const [isLoading, setIsLoading] = useState(false)

  const {setActiveCategoryGroup} = useCategorySelection()
  const queryClient = useQueryClient()
  const {data: factoryEntities} = useFactoryEntities({
    entityIds: group.entities,
    options: {enabled: !!group.entities},
  })

  const entities = factoryEntities?.items
    .filter((e) => isProductionLine(e) || isAsset(e))
    .map((e) => e.entity) as (Asset | ProductionLine)[] | undefined

  const handleDelete = async (entityId: string) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const updatedCategory = await updateEventCategoryGroup({
        id: group._id,
        entities: group.entities?.filter((e) => e !== entityId),
      })
      await queryClient.invalidateQueries('eventCategoryGroups')
      setActiveCategoryGroup(updatedCategory)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='container border border-secondary rounded py-1'>
      <div className='row p-1'>
        <div className='col-4 text-muted'>Name</div>
        <div className='col-4 text-muted'>Factory</div>
        <div className='col-4 text-muted'>Area</div>
      </div>
      {entities?.map((entity) => (
        <>
          <div className='row py-2 border-top border-secondary align-items-center'>
            <div className='col-4'>{entity.name}</div>
            <div className='col-4'>{entity.factory.name}</div>
            <div className='col-3'>{entity.area.name}</div>
            <div className='col-1'>
              <button
                className='d-flex btn btn-sm btn-danger align-self-end align-items-center justify-content-center px-2 py-4 mx-0'
                disabled={isLoading}
                onClick={() => handleDelete(entity._id)}
              >
                <i className='ms-1 fa-solid fa-trash' />
              </button>
            </div>
          </div>
        </>
      ))}
      <NewEntityRow group={group} />
    </div>
  )
}

const NewEntityRow = ({group}: {group: EventCategoryGroup}) => {
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [entityType, setEntityType] = useState<'production_line' | 'asset'>()
  const [entityId, setEntityId] = useState<string>()

  const {setActiveCategoryGroup} = useCategorySelection()
  const rowRef = useRef<HTMLDivElement | null>(null)
  const queryClient = useQueryClient()

  const {data: eventCategoryGroups} = useEventCategoryGroups()
  const alreadyAssignedEntities = new Set(
    eventCategoryGroups?.items.flatMap((categoryGroup) => categoryGroup.entities || [])
  )

  const resetState = () => {
    setIsActive(false)
    setEntityType(undefined)
    setEntityId('')
  }

  useEffect(() => {
    const handleClickOutsideRow = (event: MouseEvent) => {
      if (!isLoading && rowRef.current && !rowRef.current.contains(event.target as Node))
        resetState()
    }
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (!isLoading && event.key === 'Escape') resetState()
    }

    if (isActive && !isLoading) {
      document.addEventListener('mousedown', handleClickOutsideRow)
      document.addEventListener('keydown', handleEscapeKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideRow)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [isActive, isLoading])

  const onSubmit = async () => {
    if (isLoading || !entityId) {
      setIsActive(false)
      return
    }
    const existingEntities = group.entities || []
    if (entityId && existingEntities.includes(entityId)) {
      resetState()
      return
    }
    setIsLoading(true)
    try {
      const updatedCategory = await updateEventCategoryGroup({
        id: group._id,
        entities: [...existingEntities, entityId],
      })
      await queryClient.invalidateQueries('eventCategoryGroups')
      setActiveCategoryGroup(updatedCategory)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      resetState()
    }
  }

  if (!isActive)
    return (
      <div
        className='row py-2 border-top border-secondary cursor-pointer bg-hover-secondary'
        onClick={() => setIsActive(true)}
      >
        <div className='d-flex align-items-center'>
          <i className='fa-solid fa-plus me-3' />
          <div className='text-muted'>Add Entity</div>
        </div>
      </div>
    )

  return (
    <div ref={rowRef} className='row py-2 border-top border-secondary align-items-center'>
      <div className='col-4'>
        <Select
          placeholder='Entity Type'
          onChange={(e) => {
            setEntityType(e?.value as 'production_line' | 'asset' | undefined)
            setEntityId('')
          }}
          options={
            [
              {value: 'production_line', label: 'Production Line'},
              {value: 'asset', label: 'Asset'},
            ] || []
          }
          isDisabled={isLoading}
          isClearable
        />
      </div>
      <div className='col-6'>
        {entityType === 'production_line' && (
          <ProductionLineSelect
            onChange={(productionLine) => setEntityId(productionLine?._id)}
            filterFunction={(productionLine) => !alreadyAssignedEntities.has(productionLine._id)}
            isDisabled={isLoading}
          />
        )}
        {entityType === 'asset' && (
          <AssetSelect
            onChange={(asset) => setEntityId(asset?._id)}
            isDisabled={isLoading}
            filterFunction={(asset) => !alreadyAssignedEntities.has(asset._id)}
          />
        )}
      </div>
      <div className='col-2'>
        {!isLoading && (
          <button
            type='button'
            className={clsx('btn btn-primary btn-sm d-flex align-items-center px-2 py-1 float-end')}
            disabled={!entityId}
            onClick={onSubmit}
          >
            Save
            <i className='fa-solid fa-floppy-disk ms-1' />
          </button>
        )}
        {isLoading && <i className='fa-solid fa-spinner fa-spin me-7 float-end' />}
      </div>
    </div>
  )
}

const NewEventCategoryRow = ({activeCategoryGroup}: {activeCategoryGroup: EventCategoryGroup}) => {
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [title, setTitle] = useState('')
  const [unscheduled, setUnscheduled] = useState(false)
  const [operational, setOperational] = useState(false)
  const [color, setColor] = useState('#FF0000')
  const [icon, setIcon] = useState('fa-solid fa-atom')

  const [showColorPicker, setShowColorPicker] = useState(false)

  const rowRef = useRef<HTMLDivElement | null>(null)

  const queryClient = useQueryClient()
  const {data: profile} = useProfile()

  const submitDisabled = title === '' || PROTECTED_CATEGORIES.includes(title)

  const resetState = () => {
    setIsActive(false)
    setTitle('')
    setUnscheduled(false)
    setOperational(false)
    setColor('#FF0000')
    setShowColorPicker(false)
  }

  useEffect(() => {
    const handleClickOutsideRow = (event: MouseEvent) => {
      if (!isLoading && rowRef.current && !rowRef.current.contains(event.target as Node))
        resetState()
    }
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (!isLoading && event.key === 'Escape') resetState()
    }

    if (isActive && !isLoading) {
      document.addEventListener('mousedown', handleClickOutsideRow)
      document.addEventListener('keydown', handleEscapeKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideRow)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [isActive, isLoading])

  const onSubmit = async () => {
    if (isLoading || submitDisabled) {
      setIsActive(false)
      return
    }
    setIsLoading(true)
    try {
      await createEventCategory({
        template: false,
        organization: profile!.organization!._id,
        eventCategoryGroups: [activeCategoryGroup._id],
        unscheduled,
        operational,
        title,
        color,
        icon,
        subEventCategories: [],
      })
      await queryClient.invalidateQueries('eventCategories')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      resetState()
    }
  }

  if (isActive) {
    return (
      <div ref={rowRef} className='row py-2 border-top border-secondary'>
        <div className='col-5'>
          <div className='d-flex align-items-center'>
            <div
              className='me-2 shadow cursor-pointer'
              style={{
                backgroundColor: color,
                width: '1em',
                height: '1em',
                borderRadius: '0.25em',
              }}
              onClick={() => setShowColorPicker(true)}
            />
            <ColorPicker
              color={color}
              show={showColorPicker}
              setShow={setShowColorPicker}
              setColor={setColor}
            />

            <IconPicker className='me-1' icon={icon} setIcon={setIcon} />

            <input
              type='text'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className='col-10 m-0 p-0 ps-1 fw-bold'
              style={{border: 'none', background: 'transparent', outline: 'none'}}
              placeholder='Enter category title...'
              autoFocus
              disabled={isLoading}
            />
          </div>
        </div>
        <div className='col-3 d-flex align-items-center'>
          <div className='fs-8 me-1'>Unscheduled?</div>
          <input
            type='checkbox'
            className='form-check-input w-15px h-15px'
            checked={unscheduled}
            onChange={() => setUnscheduled(!unscheduled)}
            disabled={isLoading || operational}
          />
        </div>
        <div className='col-4 d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <div className='fs-8 me-1'>Operational?</div>
            <input
              type='checkbox'
              className='form-check-input w-15px h-15px'
              checked={operational}
              onChange={() => setOperational(!operational)}
              disabled={isLoading || unscheduled}
            />
          </div>
          {!isLoading && (
            <button
              type='button'
              className={clsx(
                'btn btn-primary btn-sm d-flex align-items-center px-2 py-1 float-end',
                submitDisabled && 'disabled'
              )}
              onClick={onSubmit}
            >
              Save
              <i className='fa-solid fa-floppy-disk ms-1' />
            </button>
          )}
          {isLoading && <i className='fa-solid fa-spinner fa-spin me-2' />}
        </div>
      </div>
    )
  }

  if (!isActive)
    return (
      <div
        className='row py-2 border-top border-secondary cursor-pointer bg-hover-secondary'
        onClick={() => setIsActive(true)}
      >
        <div className='d-flex align-items-center'>
          <i className='fa-solid fa-plus me-3' />
          <div className='text-muted'>Add Category</div>
        </div>
      </div>
    )

  return null
}

const EventCategoryRow = ({eventCategory}: {eventCategory: EventCategory}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false)

  const {setActiveCategory} = useCategorySelection()
  const queryClient = useQueryClient()

  const handleChange = async (updates: Partial<UpdateEventCategoryProps>) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      await updateEventCategory({id: eventCategory._id, ...updates})
      await queryClient.invalidateQueries('eventCategories')
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleRowClick = () => {
    if (isLoading || showColorPicker) return
    setActiveCategory(eventCategory, [])
  }

  const isProtected = PROTECTED_CATEGORIES.includes(eventCategory.title)

  return (
    <div
      className={clsx(
        'row py-2 border-top border-secondary cursor-pointer bg-hover-secondary',
        isLoading && 'text-muted'
      )}
      onClick={handleRowClick}
    >
      <div className='col-4'>
        <div className='d-flex align-items-center'>
          {/* Color */}
          <div
            className='me-2 shadow'
            style={{
              backgroundColor: eventCategory.color,
              width: '1em',
              height: '1em',
              borderRadius: '0.25em',
            }}
            onClick={(e) => {
              e.stopPropagation()
              if (isLoading) return
              setShowColorPicker(true)
            }}
          />
          <ColorPicker
            className='cursor-default'
            color={eventCategory.color}
            show={showColorPicker}
            setShow={setShowColorPicker}
            onClose={(color) => handleChange({color})}
          />

          {/* Title */}
          <div className='fw-bold me-1'>{eventCategory.title}</div>

          {/* Icon */}
          <IconPicker
            className='cursor-default'
            icon={eventCategory.icon}
            setIcon={(icon) => {
              console.log('icon', icon)
              handleChange({icon})
            }}
          />
        </div>
      </div>
      <div className='col-4'>
        {eventCategory.sub_event_categories.map((e) => e.title).join(', ')}
      </div>
      <div className='col-2 d-flex align-items-center'>
        <span
          className='d-inline-block align-self-end mt-auto'
          tabIndex={0}
          {...(isProtected
            ? {
                'data-bs-toggle': 'tooltip',
                title: 'This category is essential for system operations and cannot be changed',
              }
            : {})}
        >
          <input
            type='checkbox'
            className='form-check-input w-15px h-15px'
            checked={eventCategory.unscheduled}
            disabled={isProtected || isLoading || eventCategory.operational}
            onClick={(e) => {
              e.stopPropagation()
              handleChange({unscheduled: !eventCategory.unscheduled})
            }}
          />
        </span>
      </div>
      <div className='col-2'>
        <span
          className='d-inline-block align-self-end mt-auto'
          tabIndex={0}
          {...(isProtected
            ? {
                'data-bs-toggle': 'tooltip',
                title: 'This category is essential for system operations and cannot be changed',
              }
            : {})}
        >
          <input
            type='checkbox'
            className='form-check-input w-15px h-15px'
            checked={eventCategory.operational}
            disabled={isProtected || isLoading || eventCategory.unscheduled}
            onClick={(e) => {
              e.stopPropagation()
              handleChange({operational: !eventCategory.operational})
            }}
          />
        </span>
      </div>
    </div>
  )
}

export default DisplayCategoryGroup
