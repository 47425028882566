import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import Pagination from '../../../components/pagination/Pagination'
import {deleteProductionLine, useProductionLines} from '../../../core/requests/factory'
import useGridSwitch from '../../../hooks/UseGridSwitch'
import {useCreateModals} from '../../../modals/CreateModalsProvider'
import {ProductionLineOverviewDetailsModal} from '../../../modals/overview/ProductionLineOverviewDetailsModal'
import {useSelectedRows} from '../../../providers/SelectedRowsProvider'
import {TableBody} from '../core/columns/TableBody'
import {TableSelectionToolbar} from '../core/columns/TableSelectionToolbar'
import {TableToolbar} from '../core/columns/TableToolbar'
import AreaSelect from '../core/components/AreaSelect'
import ListFilter from '../core/components/ListFilter'
import ManufacturingTypeSelect from '../core/components/ManufacturingTypeSelect'
import OrganizationSelect from '../core/components/OrganizationSelect'
import {OverviewGridItem} from '../core/components/overview/OverviewGridItem'
import useProductionLinesFilter from '../core/hooks/useProductionLinesFilter'
import {Columns} from './Columns'
import {ProductionLineStartStopCell} from './ProductionLineStartStopCell'

export const ProductionLinesWrapper = () => {
  const {isGridView} = useGridSwitch()

  const {selected, setDataItems} = useSelectedRows()
  const {openCreateProductionLineModal} = useCreateModals()
  const {filterState, filterCount, activePage, setActivePage, productionLineFilterProps} =
    useProductionLinesFilter()
  const {data: productionLines, isLoading: productionLinesLoading} =
    useProductionLines(productionLineFilterProps)
  const columns = React.useMemo(() => Columns, [])

  const [uuid, setUUID] = React.useState<string>()
  const productionLine = productionLines?.items.find((p) => p._id === uuid)

  React.useEffect(() => {
    if (productionLines?.items !== undefined) {
      setDataItems(productionLines?.items)
    }
  }, [productionLines?.items])

  return (
    <KTCard className={`${isGridView ? 'bg-transparent' : undefined}`}>
      {/* Header */}
      <div
        className={`card-header border-0 justify-content-end pt-6 bg-white ${
          isGridView ? 'mb-5 pb-5 rounded' : ''
        }`}
      >
        {/* <ListSearchComponent /> */}
        <div className='card-toolbar'>
          <ListFilter
            count={filterCount}
            filters={[
              <OrganizationSelect onChange={filterState.organization.setValue} />,
              <ManufacturingTypeSelect onChange={filterState.manufacturingType.setValue} />,
              <AreaSelect onChange={filterState.area.setValue} />,
            ]}
          />

          {/* Standard toolbar */}
          {selected.length === 0 && (
            <TableToolbar buttonText='Add' onButtonClick={openCreateProductionLineModal} />
          )}

          {/* Selection toolbar */}
          {selected.length > 0 && (
            <TableSelectionToolbar onDelete={deleteProductionLine} queryKey='productionLines' />
          )}
        </div>
      </div>

      {isGridView ? (
        <div className={`row g-6 g-xl-9 mb-4`} id='hs_production_lines_table'>
          {productionLines?.items.map((item) => (
            <OverviewGridItem
              item={item}
              title={item.name}
              description={`${item.area.name} - ${item.factory.name}`}
              actions={<ProductionLineStartStopCell productionLineId={item._id} />}
              imageUrl={item.gallery?.profile_image.thumbnail_url}
              onClick={setUUID}
            />
          ))}
        </div>
      ) : (
        <TableBody
          tableId='hs_production_lines_table'
          columns={columns}
          dataList={productionLines?.items}
          loading={productionLinesLoading}
          onCellClick={(value) => setUUID(value)}
        />
      )}

      {productionLines?.total ? (
        <Pagination
          page={activePage}
          total={productionLines.total}
          size={productionLines.size}
          onClick={(p) => setActivePage(p)}
        />
      ) : null}

      {productionLine && (
        <ProductionLineOverviewDetailsModal
          entity={productionLine}
          onHide={() => setUUID(undefined)}
        />
      )}
    </KTCard>
  )
}
