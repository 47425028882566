export type ICreateGateway = {
  area?: string
  device_id?: string
}

export const defaultCreateGateway: ICreateGateway = {
  area: undefined,
  device_id: undefined,
}

export type StepProps = {
  data: ICreateGateway
  updateData: (fieldsToUpdate: Partial<ICreateGateway>) => void
  hasError: boolean
}
