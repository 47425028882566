import {useState} from 'react'
import {Batch, ServerError} from '../../core/_models'
import {useQueryClient} from 'react-query'
import {AxiosError} from 'axios'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import ModalErrorWrapper from '../../components/ModalErrorWrapper'
import LoadingWrapper from '../../components/LoadingWrapper'
import {KTSVG} from '../../../_metronic/helpers'
import {completeBatch} from '../../core/requests/oee'

const modalsRoot = document.getElementById('root-modals') || document.body

const ForceStopModal = ({show, hide, batch}: {show: boolean; hide: () => void; batch: Batch}) => {
  const [submitting, setSubmitting] = useState(false)
  const [hasServerError, setHasServerError] = useState(false)
  const [serverError, setServerError] = useState<ServerError | null>(null)
  const queryClient = useQueryClient()

  const close = () => {
    setSubmitting(false)
    setHasServerError(false)
    setServerError(null)
    hide()
  }

  const handleDelete = async () => {
    setSubmitting(true)
    try {
      await completeBatch({batchId: batch._id})
      queryClient.invalidateQueries('batches')
      queryClient.invalidateQueries('productionLineOperationalEvents')
      close()
    } catch (error) {
      setHasServerError(true)
      const errorData: ServerError = (error as AxiosError).response?.data as ServerError
      setServerError(errorData)
    }
  }

  return createPortal(
    <Modal
      id='hs_force_stop_modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='lg'
      show={show}
      onHide={close}
      backdrop={submitting ? 'static' : true}
    >
      <ModalErrorWrapper
        hasError={hasServerError}
        errorData={serverError || undefined}
        hide={close}
      >
        <LoadingWrapper loading={submitting}>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={close}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>

          <div className='modal-body mx-5 mx-xl-18 pt-0'>
            <h1 className='text-center mb-13'>Confirm Stop</h1>

            <div className='notice bg-light-danger rounded border-danger border border-dashed p-6 mb-3'>
              <div className='d-flex'>
                <KTSVG
                  className='svg-icon-2tx svg-icon-danger me-4'
                  path='/media/icons/duotune/general/gen044.svg'
                />
                <div className='d-flex flex-row flex-stack flex-grow-1'>
                  <div className='fw-semibold'>
                    <h4 className='text-gray-900 fw-bold'>{`You are about to stop ${batch.name}`}</h4>
                    <div className='fw-bold text-danger'>This action cannot be undone!</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-center pt-5'>
              <button className='btn btn-light me-3' onClick={hide}>
                Cancel
              </button>
              <button className='btn btn-danger' onClick={handleDelete}>
                Stop
              </button>
            </div>
          </div>
        </LoadingWrapper>
      </ModalErrorWrapper>
    </Modal>,
    modalsRoot
  )
}

export default ForceStopModal
