import {useQuery} from 'react-query'
import {graphql} from '../../../../../gql'
import {
  GetPlacementStandbyConsumptionQuery,
  GetProductionLineStandbyConsumptionQuery,
  GetSectionStandbyConsumptionQuery,
  Timespan,
} from '../../../../../gql/graphql'
import {EntityGroup} from '../../../_models'
import useEnergyClient from '../UseEnergyClient'

const getPlacementStandbyConsumptionWithVariablesQueryDocument = graphql(`
  query GetPlacementStandbyConsumption(
    $id: ID!
    $startTime: DateTime
    $endTime: DateTime
    $timespan: Timespan
    $multiplier: Int!
  ) {
    getPlacementStandbyConsumption(
      id: $id
      startTime: $startTime
      endTime: $endTime
      timespan: $timespan
      multiplier: $multiplier
    ) {
      costs
      currency
      emissions
      energy
      power {
        time
        value
      }
    }
  }
`)

const getProductionLineStandbyConsumptionWithVariablesQueryDocument = graphql(`
  query GetProductionLineStandbyConsumption(
    $id: ID!
    $startTime: DateTime
    $endTime: DateTime
    $timespan: Timespan
    $multiplier: Int!
  ) {
    getProductionLineStandbyConsumption(
      id: $id
      startTime: $startTime
      endTime: $endTime
      timespan: $timespan
      multiplier: $multiplier
    ) {
      costs
      currency
      emissions
      energy
      power {
        time
        value
      }
    }
  }
`)

const getSectionStandbyConsumptionWithVariablesQueryDocument = graphql(`
  query GetSectionStandbyConsumption(
    $id: ID!
    $startTime: DateTime
    $endTime: DateTime
    $timespan: Timespan
    $multiplier: Int!
  ) {
    getSectionStandbyConsumption(
      id: $id
      startTime: $startTime
      endTime: $endTime
      timespan: $timespan
      multiplier: $multiplier
    ) {
      costs
      currency
      emissions
      energy
      power {
        time
        value
      }
    }
  }
`)

type StandbyConsumptionHistory =
  | typeof getPlacementStandbyConsumptionWithVariablesQueryDocument
  | typeof getProductionLineStandbyConsumptionWithVariablesQueryDocument
  | typeof getSectionStandbyConsumptionWithVariablesQueryDocument

type StandbyConsumptionQuery =
  | GetPlacementStandbyConsumptionQuery
  | GetProductionLineStandbyConsumptionQuery
  | GetSectionStandbyConsumptionQuery

type StandbyConsumption =
  | GetPlacementStandbyConsumptionQuery['getPlacementStandbyConsumption']
  | GetProductionLineStandbyConsumptionQuery['getProductionLineStandbyConsumption']
  | GetSectionStandbyConsumptionQuery['getSectionStandbyConsumption']

interface Props {
  entityGroup: EntityGroup
  id?: string
  startTime: string
  endTime?: string
  multiplier?: number
  timespan?: Timespan
}

const useGetStandbyConsumption = (props: Props) => {
  const {entityGroup, id, startTime, endTime, multiplier = 1, timespan} = props
  const client = useEnergyClient()
  let getStandbyConsumptionWithVariablesQueryDocument: StandbyConsumptionHistory
  let queryKey: string
  switch (entityGroup) {
    case 'placement':
      queryKey = 'GetPlacementStandbyConsumption'
      getStandbyConsumptionWithVariablesQueryDocument =
        getPlacementStandbyConsumptionWithVariablesQueryDocument
      break
    case 'production_line':
      queryKey = 'GetProductionLineStandbyConsumption'
      getStandbyConsumptionWithVariablesQueryDocument =
        getProductionLineStandbyConsumptionWithVariablesQueryDocument
      break
    case 'section':
      queryKey = 'GetSectionStandbyConsumption'
      getStandbyConsumptionWithVariablesQueryDocument =
        getSectionStandbyConsumptionWithVariablesQueryDocument
      break
    default:
      throw new Error(`Wrong entityGroup: ${entityGroup}`)
  }
  const query = useQuery({
    queryKey: [`energy.${queryKey}`, ...Object.values(props)],
    queryFn: async () =>
      await client.request<StandbyConsumptionQuery>(
        getStandbyConsumptionWithVariablesQueryDocument,
        {
          id,
          startTime,
          endTime,
          multiplier,
          timespan,
        }
      ),
    enabled: !!id,
  })

  let consumption: StandbyConsumption | undefined
  if (query?.data) {
    if ('getPlacementStandbyConsumption' in query.data) {
      consumption = query.data.getPlacementStandbyConsumption
    }
    if ('getProductionLineStandbyConsumption' in query.data) {
      consumption = query.data.getProductionLineStandbyConsumption
    }
    if ('getSectionStandbyConsumption' in query.data) {
      consumption = query.data.getSectionStandbyConsumption
    }
  }

  return {
    ...query,
    consumption,
  }
}

export default useGetStandbyConsumption
