import LoadingWrapper from '../../../../../components/LoadingWrapper'
import SelectField from '../../../../../components/form-components/SelectField'
import SelectFieldBoxes from '../../../../../components/form-components/SelectFieldBoxes'
import SelectFieldTable from '../../../../../components/form-components/SelectFieldTable'
import {useAreasPaginated, useAssets} from '../../../../../core/requests/factory'
import {StepProps} from './_models'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const {
    items: areas,
    isLoading: areasLoading,
    isFetching: areasFetching,
    fetchNextPage: areasNextPage,
  } = useAreasPaginated({}, {enabled: data.entity_group_filter === 'area'})

  const {data: assets} = useAssets({
    area: data.entity_group_filter === 'area' ? data.entity_filter : undefined,
    options: {enabled: data.entity_group_filter !== undefined},
  })

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* Entity Group filter */}
        <SelectFieldBoxes
          name='Entity Group'
          optionNames={['Area', 'Asset']}
          optionValues={['area', 'asset']}
          data={data}
          updateData={updateData}
          dataField='entity_group_filter'
          required
          hasError={hasError}
          colSpace={6}
          updatesOnChange={{entity_filter: undefined, entity: undefined}}
        />

        {/* Specific filter entity */}
        {data.entity_group_filter === 'area' && (
          <LoadingWrapper loading={areasLoading}>
            {areas && areas?.length <= 3 && (
              <SelectFieldBoxes
                name='Area'
                optionNames={areas?.map((area) => area.name) || []}
                optionValues={areas?.map((area) => area._id) || []}
                data={data}
                updateData={updateData}
                dataField='entity_filter'
                required
                hasError={hasError}
                colSpace={4}
                updatesOnChange={{entity: undefined}}
              />
            )}
            {areas && areas.length > 3 && (
              <SelectField
                name='Area'
                optionNames={areas?.map((area) => area.name) || []}
                optionValues={areas?.map((area) => area._id) || []}
                data={data}
                updateData={updateData}
                dataField='entity_filter'
                required
                hasError={hasError}
                updatesOnChange={() => ({entity: undefined})}
                isLoading={areasFetching}
                gotoNextPage={areasNextPage}
              />
            )}
          </LoadingWrapper>
        )}

        {/* Specific asset */}
        {(data.entity_group_filter === 'asset' || data.entity_filter !== undefined) && (
          <LoadingWrapper loading={assets?.items === undefined}>
            <SelectFieldTable
              name='Asset'
              columnNames={['Name', 'Area', 'Section']}
              optionRows={
                assets?.items.map((asset) => [asset.name, asset.area.name, asset.section?.name]) ||
                []
              }
              optionValues={assets?.items.map((asset) => asset._id) || []}
              data={data}
              updateData={updateData}
              dataField='entity'
              required
              hasError={hasError}
              height='400px'
              lastField
            />
          </LoadingWrapper>
        )}
      </div>
    </div>
  )
}

export default Step1
