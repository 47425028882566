import React from 'react'
import LoadingWrapper from '../../../components/LoadingWrapper'
import {useProductionLine} from '../../../core/requests/factory'
import ProductionLineNode from '../../../modules/factoryTree/nodes/ProductionLineNode'
import TreeNode from '../../../modules/factoryTree/nodes/TreeNode'

interface ProductionLineTreeTabProps {
  productionLineId: string
}

const ProductionLineTreeTab = ({productionLineId}: ProductionLineTreeTabProps) => {
  const {data: productionLine, isLoading} = useProductionLine(productionLineId)
  const area = productionLine?.area
  const areaNode = area ? (
    <TreeNode
      name={area.name}
      iconPath={`/media/icons/duotune/maps/map001.svg`}
      opened={true}
      setOpen={() => {}}
      nodes={[<ProductionLineNode productionLine={productionLine} />]}
      isExpandable={false}
    />
  ) : undefined
  const factory = productionLine?.factory
  const factoryNode = factory ? (
    <TreeNode
      name={factory.name}
      icon={`fa-industry`}
      opened={true}
      setOpen={() => {}}
      nodes={[areaNode]}
      isExpandable={false}
    />
  ) : undefined
  return (
    <LoadingWrapper loading={isLoading || !productionLine}>
      <div className={`factory-tree`}>{factoryNode}</div>
    </LoadingWrapper>
  )
}

export default ProductionLineTreeTab
