import ReactQuill from 'react-quill'
import {WidgetProps} from './Widget'
import 'react-quill/dist/quill.snow.css'

const TextWidget = ({widget}: WidgetProps) => (
  <div className='d-flex align-items-center justify-content-center h-100'>
    <ReactQuill value={widget.name} readOnly theme='bubble' modules={{toolbar: false}} />
  </div>
)

export default TextWidget
