import {uniq} from 'lodash'
import SelectFieldBoxesMultiple from '../../../../../components/form-components/SelectFieldBoxesMultiple'
import {StepProps} from './_models'

const SpecificTypesStep = ({data, updateData, hasError}: StepProps) => {
  const ampereOptions = ['Phase 1', 'Phase 2', 'Phase 3', 'Average']
  const vibrationOptions = ['RMS X', 'RMS Y', 'RMS Z']

  const updateSpecificTypes = (
    newData?: {data_types?: typeof data['data_types']},
    type?: 'ampere' | 'vibration'
  ) => {
    const dataTypes = data['data_types']
    ;(type === 'ampere' ? ampereOptions : vibrationOptions).forEach((e) =>
      dataTypes?.splice(dataTypes?.indexOf(e), 1)
    )
    updateData({
      data_types: uniq([...(dataTypes ?? []), ...(newData?.data_types ?? [])]),
    })
  }

  const displayAmpereTypes = (data['data_types'] || []).indexOf('Ampere') > -1
  const displayVibrationTypes = (data['data_types'] || []).indexOf('Vibration') > -1

  return (
    <div data-kt-stepper-element='content'>
      <div className={`w-100`}>
        {displayAmpereTypes ? (
          <SelectFieldBoxesMultiple
            name='Specify Ampere Types'
            optionNames={ampereOptions || []}
            optionValues={ampereOptions || []}
            data={data}
            updateData={(newData) => updateSpecificTypes(newData, 'ampere')}
            dataField='data_types'
            required
            hasError={hasError}
            colSpace={12}
            lastField={true}
          />
        ) : null}
        {hasError &&
        data.data_types?.includes('Ampere') &&
        !data.data_types?.some((e) => ampereOptions.includes(e)) ? (
          <div className='fv-plugins-message-container mb-10'>
            <div className='fv-help-block'>You must specify ampere types</div>
          </div>
        ) : null}
        {displayVibrationTypes ? (
          <SelectFieldBoxesMultiple
            name='Specify Vibration Types'
            optionNames={vibrationOptions || []}
            optionValues={vibrationOptions || []}
            data={data}
            updateData={(newData) => updateSpecificTypes(newData, 'vibration')}
            dataField='data_types'
            required
            hasError={hasError}
            colSpace={12}
            lastField
          />
        ) : null}
        {hasError &&
        data.data_types?.includes('Vibration') &&
        !data.data_types?.some((e) => vibrationOptions.includes(e)) ? (
          <div className='fv-plugins-message-container mb-10'>
            <div className='fv-help-block'>You must specify vibration types</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SpecificTypesStep
