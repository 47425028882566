import AttachmentField from '../../../components/form-components/AttachmentField'
import {StepProps} from './_models'

const Step4 = ({data, updateData, hasError}: StepProps) => (
  <div data-kt-stepper-element='content'>
    <div className='w-100'>
      <AttachmentField
        name='Attachments'
        updateData={updateData}
        dataField='attachments'
        hasError={hasError}
        data={data}
      />
    </div>
  </div>
)

export default Step4
