import clsx from 'clsx'
import {useRef, useState} from 'react'
import {variableToString} from '../conditions/conditions'

type Props = {
  initial?: string
  name?: string
  placeholder?: string
  onchange: (value: string) => void
  variables?: string[]
  errorOccurred?: boolean
  className?: string
}

const TextInputField = ({
  initial = '',
  name,
  placeholder,
  onchange,
  variables,
  errorOccurred,
  className,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState(initial)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    onchange(e.target.value)
  }

  const insertVariable = (variable: string) => {
    const input = inputRef.current
    if (!input) return

    const cursorPos = input.selectionStart ?? 0
    const textBeforeCursor = value.substring(0, cursorPos)
    const textAfterCursor = value.substring(cursorPos, value.length)

    const newValue = `${textBeforeCursor}$${variable}${textAfterCursor}`
    setValue(newValue)
    onchange(newValue)

    const newCursorPos = cursorPos + variable.length + 1
    setTimeout(() => {
      input.setSelectionRange(newCursorPos, newCursorPos)
      input.focus()
    }, 0)
  }

  const error = errorOccurred && !value

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <input
        ref={inputRef}
        type='text'
        placeholder={placeholder}
        className={clsx('form-control fw-normal text-dark p-3', error && 'is-invalid')}
        style={{height: '38px'}}
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}
      {isFocused && variables && (
        <div className='pt-1'>
          {variables.map((variable) => (
            <button
              type='button'
              className='btn btn-secondary py-0 px-1 me-1'
              onMouseDown={(e) => {
                e.preventDefault()
                insertVariable(variable)
              }}
            >
              <div className='fs-8'>{variableToString(variable, false)}</div>
            </button>
          ))}
        </div>
      )}
    </>
  )
}

export default TextInputField
