import {PulseLoader} from 'react-spinners'

type Props = {
  loading: boolean
  chartId: string
  height: string
}

const ChartLoader = ({loading, chartId, height}: Props) => {
  return (
    <>
      {loading ? (
        <div className='row' style={{width: '100%', height: height}}>
          <PulseLoader className='align-items-center justify-content-center' color='#009ef7' />
        </div>
      ) : (
        <div id={chartId} style={{width: '100%', height: loading ? '0px' : height}} />
      )}
    </>
  )
}
export {ChartLoader}
