import axios from 'axios'
import {useQuery} from 'react-query'
import {
  BaseModel,
  DeletionConfirmation,
  EventLog,
  NotificationGroup,
  OrganizationQueryProps,
  OrganizationRequestParams,
  PaginatedQueryProps,
  PaginatedRequestParams,
  PaginatedResponse,
  Subscription,
  SubscriptionTopic,
} from '../_models'
import {Notification} from '../_models'
import qs from 'qs'
import {sanitizeUndefinedFields} from '../request-util'
import {ICreateSubscription} from '../../modals/create-subscription/_models'
import {
  UsePaginatedInfiniteQueryOptions,
  usePaginatedInfiniteQuery,
} from '../../components/pagination/usePaginatedInfiniteQuery'

const NOTIFICATION_API_BASE_URL = process.env.REACT_APP_WA_NOTIFICATIONS_URL

// ----- SUBSCRIPTIONS ----- //

const SUBSCRIPTIONS_URL = `${NOTIFICATION_API_BASE_URL}/subscriptions`

export type UseSubscriptionsProps = PaginatedQueryProps<Subscription & BaseModel> & {
  topics?: string[]
  entities?: string[]
  isEnabled?: boolean
} & OrganizationQueryProps

type UseSubscriptionsRequestParams = PaginatedRequestParams & {
  topics?: string[]
  entities?: string[]
  is_enabled?: boolean
} & OrganizationRequestParams

export function useSubscriptions(props: UseSubscriptionsProps = {}) {
  const {topics, entities, isEnabled, organization, page, size, options} = props
  const params: UseSubscriptionsRequestParams = {
    topics,
    entities,
    is_enabled: isEnabled,
    organization,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Subscription & BaseModel>, Error>(
    ['subscriptions', ...Object.values(params)],
    async () =>
      (
        await axios.get<PaginatedResponse<Subscription & BaseModel>>(SUBSCRIPTIONS_URL, {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
        })
      ).data,
    options
  )
}

export async function createSubscription(subscription: ICreateSubscription) {
  return (await axios.post<Subscription & BaseModel>(SUBSCRIPTIONS_URL, subscription)).data
}

export async function updateSubscription(id: string, subscription: Partial<ICreateSubscription>) {
  console.log('updateSubscription', id, subscription)
  const sanitizedSubscription = sanitizeUndefinedFields(subscription)
  console.log('sanitizedSubscription', sanitizedSubscription)
  return (
    await axios.put<Subscription & BaseModel>(`${SUBSCRIPTIONS_URL}/${id}`, sanitizedSubscription)
  ).data
}

export async function deleteSubscription(id: string) {
  return (await axios.delete<DeletionConfirmation>(`${SUBSCRIPTIONS_URL}/${id}`)).data
}

// ----- NOTIFICATIONS ----- //

const NOTIFICATIONS_URL = `${NOTIFICATION_API_BASE_URL}/notifications`

export type UseNotificationsProps = PaginatedQueryProps<Notification & BaseModel> & {
  receiver?: string
  organization?: string
  subscription?: string
  topics?: string[]
  startTime?: string
  endTime?: string
  entity?: string
}

type UseNotificationsRequestParams = PaginatedRequestParams & {
  receiver?: string
  organization?: string
  subscription?: string
  topics?: string[]
  start_time?: string
  end_time?: string
  entity?: string
}

export function useNotifications(props: UseNotificationsProps = {}) {
  const {
    receiver,
    organization,
    subscription,
    topics,
    startTime,
    endTime,
    entity,
    page,
    size,
    options,
  } = props
  const params: UseNotificationsRequestParams = {
    receiver,
    organization,
    subscription,
    topics,
    start_time: startTime,
    end_time: endTime,
    entity,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Notification & BaseModel>, Error>(
    ['notifications', ...Object.values(params)],
    async () =>
      (
        await axios.get<PaginatedResponse<Notification & BaseModel>>(NOTIFICATIONS_URL, {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
        })
      ).data,
    options
  )
}

// ----- NOTIFICATION GROUPS ----- //

const NOTIFICATION_GROUPS_URL = `${NOTIFICATION_API_BASE_URL}/notification-groups`

export type UseNotificationGroupsProps = PaginatedQueryProps<NotificationGroup & BaseModel> &
  OrganizationQueryProps & {
    users?: string[]
  }

type UseNotificationGroupsRequestParams = PaginatedRequestParams &
  OrganizationRequestParams & {
    users?: string[]
  }

export const fetchNotificationGroups = async <T = NotificationGroup & BaseModel>(
  params?: UseNotificationGroupsProps
) => (await axios.get<PaginatedResponse<T>>(NOTIFICATION_GROUPS_URL, {params})).data

export const useNotificationGroups = (props: UseNotificationGroupsProps = {}) => {
  const {users, organization, page, size, options} = props
  const params: UseNotificationGroupsRequestParams = {users, organization, page, size}
  return useQuery<PaginatedResponse<NotificationGroup & BaseModel>, Error>(
    ['notification-groups', ...Object.values(params)],
    async () => fetchNotificationGroups(params),
    options
  )
}

export const useNotificationGroupsPaginated = (
  params: UseNotificationGroupsRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<NotificationGroup & BaseModel, typeof fetchNotificationGroups>({
    queryKey: ['notification-groups', ...Object.values(params)],
    fetchFn: fetchNotificationGroups,
    params,
    enabled: options?.enabled,
  })

// ----- EVENT LOGS ----- //

const EVENT_LOGS_URL = `${NOTIFICATION_API_BASE_URL}/event-logs`

export type UseEventsLogProps = PaginatedQueryProps<EventLog & BaseModel> &
  OrganizationQueryProps & {entities?: string[]}

type UseEventsLogRequestParams = PaginatedRequestParams &
  OrganizationRequestParams & {
    entities?: string[]
  }

export function useEventsLog(props: UseEventsLogProps = {}) {
  const {entities, page, size, options} = props
  const params: UseEventsLogRequestParams = {entities, page, size}
  return useQuery<PaginatedResponse<EventLog & BaseModel>, Error>(
    ['event-logs', ...Object.values(params)],
    async () =>
      (
        await axios.get<PaginatedResponse<EventLog & BaseModel>>(EVENT_LOGS_URL, {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
        })
      ).data,
    options
  )
}
