import React from 'react'
import * as am5 from '@amcharts/amcharts5'
import {LineData, lineConfig} from '../../core/_chartConfigs/lineConfig'
import {ChartLoader} from '../ChartLoader'

type LineGraphProps = {
  id: string
  data: Record<string, LineData[]>
  height: string
  loading?: boolean
}

const LineGraph = ({id, data, height, loading = false}: LineGraphProps) => {
  const chartRef = React.useRef<am5.Root | null>(null)

  const isLoading = data === undefined || loading

  React.useEffect(() => {
    if (isLoading) return

    chartRef.current = lineConfig(id, data)

    return () => {
      if (chartRef.current) chartRef.current.dispose()
    }
  }, [data, isLoading])

  return <ChartLoader loading={isLoading} chartId={id} height={height} />
}

export {LineGraph}
