import {useEffect, useRef} from 'react'
import * as am5 from '@amcharts/amcharts5'
import {gaugeConfig} from '../../core/_chartConfigs/gaugeConfig'
import {ChartLoader} from '../ChartLoader'

type GaugeGraphProps = {
  id: string
  value?: number
  height: string
  loading: boolean
}

const GaugeGraph = ({id, value, height, loading}: GaugeGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)

  useEffect(() => {
    if (loading) return

    chartRef.current = gaugeConfig(id, value)

    return () => {
      if (chartRef.current) chartRef.current.dispose()
    }
  }, [value, loading])

  return <ChartLoader loading={loading} chartId={id} height={height} />
}

export {GaugeGraph}
