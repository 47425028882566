import React, {ReactNode} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ReferenceUUID} from '../../../../../core/_models'
import {SelectionCell} from '../../columns/SelectionCell'

interface OverviewGridProps {
  item: ReferenceUUID
  onClick?: (_id: string) => void
  title: string
  description: string
  actions?: ReactNode
  imageUrl?: string
}

export const OverviewGridItem = ({
  item,
  onClick,
  title,
  description,
  actions,
  imageUrl = toAbsoluteUrl('/media/blank.jpg'),
}: OverviewGridProps) => {
  return (
    <div className='col-md-6 col-xxl-4' id={item._id}>
      <div className='card'>
        <div className={`d-flex justify-content-end p-2`}>
          <SelectionCell id={item._id} tableId='hs_factories_table' />
        </div>
        <Link
          onClick={() => (onClick ? onClick(item._id) : null)}
          to={`#`}
          className='card-body d-flex flex-center flex-column p-9 text-center'
        >
          {imageUrl ? (
            <div className='mb-5'>
              <div className='symbol symbol-75px symbol-circle cursor-pointer'>
                <img alt='Pic' src={imageUrl} />
              </div>
            </div>
          ) : null}

          <div className='fs-4 text-gray-800 text-hover-primary fw-bold mb-0'>{title}</div>
          <div className='fw-semibold text-gray-500 mb-6'>{description}</div>
        </Link>
        {actions ? (
          <div className='card-footer d-flex justify-content-center'>{actions}</div>
        ) : null}
      </div>
    </div>
  )
}
