import React, {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {MachineStatuses} from '../../../gql/graphql'
import Beta from '../../components/badge/Beta'
import LoadingWrapper from '../../components/LoadingWrapper'
import AssetStateBadge from '../../components/asset/AssetStateBadge'
import PageNavigationBar, {Breadcrumb} from '../../components/PageNavigationBar'
import {Asset, Sensor} from '../../core/_models'
import useGetAssetStates from '../../core/graphql/condition/queries/GetAssetStates'
import {useAsset, useParts, useSensors} from '../../core/requests/factory'
import {useAssetsStateVerifyModal} from '../../modals/assets-state-verify/AssetsStateVerifyModal'
import usePartDetailModal from '../../modals/part-detail/usePartDetailModal'
import useRegisterMaintenanceModal from '../../modals/register-maintenance/useRegisterMaintenanceModal'
import FFTGraph from './components/charts/FFTGraph'
import Notifications from './components/notifications/Notifications'
import {useCreateModals} from '../../modals/CreateModalsProvider'
import {convertSensorToNameValuePairs} from '../../core/entity-conversion-util'
import {HealthGraph} from './components/charts/HealthGraph'
import {AnomalyGraph} from './components/charts/AnomalyGraph'
import AssetChecklists from '../../modals/asset-checklist/AssetChecklists'
import {TemperatureGraph} from './components/charts/TemperatureGraph'
import {RmsGraph} from './components/charts/RmsGraph'
import {BatteryGraph} from './components/charts/BatteryGraph'
import {useCreateMachineVerificationRequestMutation} from '../../core/requests/condition'

type MainTab = 'Health' | 'Anomaly' | 'Temp' | 'RMS' | 'FFT' | 'Battery'

let tabs: {tab: MainTab; subTabs: (Sensor | string)[]}[] = [
  {tab: 'Health', subTabs: ['Simple', 'Advanced']},
  {tab: 'Anomaly', subTabs: []},
  {tab: 'Temp', subTabs: []},
  {tab: 'RMS', subTabs: []},
  {tab: 'FFT', subTabs: []},
  {tab: 'Battery', subTabs: []},
]

type Props = {
  asset: Asset
}

const ConditionDashboardPage: FC<Props> = ({asset}) => {
  const [tab, setTab] = useState<MainTab>(tabs[0].tab)
  const [subTab, setSubTab] = useState<Sensor | string | undefined>(tabs[0].subTabs[0])
  const [showChecklist, setShowChecklist] = useState(false)

  const {data: healths = []} = useGetAssetStates({ids: [asset._id]})
  const health = healths[0] ?? null
  const {data: parts} = useParts({asset: asset?._id, options: {enabled: !!asset?._id}})
  const {data: sensors, isLoading: sensorsLoading} = useSensors({
    asset: asset._id,
    options: {enabled: !!asset._id},
  })

  const {modal: registerMaintenanceModal, setShow: showRegisterMaintenanceModal} =
    useRegisterMaintenanceModal({asset})

  const {modal: partDetailModal, setPart: setModalPart} = usePartDetailModal()

  const {openEntityDetailModal, openCreateSensorModal, openCreatePartModal} = useCreateModals()

  function setTabValues(tab: MainTab) {
    setTab(tab)
    const tabObj = tabs.find((t) => t.tab === tab)
    if (subTab && tabObj?.subTabs.length !== 0 && tabObj?.subTabs.includes(subTab)) return
    setSubTab(tabObj?.subTabs[0])
  }

  useEffect(() => {
    if (!sensors) return
    tabs[2].subTabs = sensors.items
    tabs[3].subTabs = sensors.items
    tabs[4].subTabs = sensors.items
    tabs[5].subTabs = sensors.items
  }, [sensors])

  const isCalibrating = health?.title === MachineStatuses.Calibrating
  const calibratingPlaceholder = (
    <img
      className='w-100'
      src={toAbsoluteUrl('/media/hexastate/graphs-placeholder.png')}
      alt={'Initializing'}
    />
  )

  const isVerifyEnabled =
    health?.title === MachineStatuses.Unacceptable ||
    health?.title === MachineStatuses.Unsatisfactory
  const {
    mutateAsync: assetsStateVerify,
    isSuccess: isAssetsStateVerifySuccess,
    isLoading: isAssetsStateVerifyLoading,
  } = useCreateMachineVerificationRequestMutation()

  const {setShow: setShowVerifyModal, modal: verifyModal} = useAssetsStateVerifyModal({
    onCancel: () => setShowVerifyModal(false),
    onSubmit: async () => {
      if (asset?._id) await assetsStateVerify({id: asset._id})
      setShowVerifyModal(false)
    },
  })

  return (
    <div className='row'>
      <div className='col-xl-9 mb-md-5 mb-xl-10'>
        {/* Title Card */}
        <div className='card card-flush mb-5'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
              <div className='d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4'>
                <img
                  className='w-100'
                  src={
                    asset?.gallery?.profile_image.url ||
                    toAbsoluteUrl('/media/hexastate/gallery-placeholder.jpg')
                  }
                  alt={asset?.name}
                />
              </div>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <a className='text-gray-800 fs-2 fw-bold me-3'>{asset?.name}</a>
                    </div>
                    <div className='d-flex flex-wrap fw-semibold mb-4'>
                      {parts?.items.map((p) => (
                        <span
                          className='cursor-pointer badge badge-light-primary me-1'
                          key={p._id}
                          onClick={() => setModalPart(p)}
                        >
                          {p.type}
                        </span>
                      ))}
                      <button
                        className={`btn btn-tip px-0 py-0 small-font-size badge`}
                        onClick={() => openCreatePartModal(asset)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr075.svg'
                          className='svg-icon-2'
                        />
                        <span className={'tip'}>Add part</span>
                      </button>
                      {partDetailModal}
                    </div>
                  </div>

                  <div className='d-flex mb-4'>
                    {sensors?.items?.map((sensor) => (
                      <button
                        key={sensor.device_id}
                        className='btn btn-sm btn-bg-light btn-active-color-primary me-3 px-3'
                        onClick={() => openEntityDetailModal(convertSensorToNameValuePairs(sensor))}
                      >
                        <div className='d-flex'>
                          <span
                            className={`badge badge-small py-1 px-1 fs-9 me-2 badge-light-${
                              sensor.state === 'Online' ? 'success' : 'danger'
                            }`}
                          >
                            {sensor.state}
                          </span>
                          <div>{sensor.device_id}</div>
                        </div>
                      </button>
                    ))}
                    <button
                      className='btn btn-sm btn-primary me-3'
                      onClick={() => openCreateSensorModal({asset})}
                    >
                      Add Sensor
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Graph Picker */}
        <div className='card card-flush mb-5'>
          <div className='card-header position-relative py-0 border-bottom-2'>
            {/* Nav */}
            <ul className='nav nav-stretch nav-pills nav-pills-custom d-flex mt-3'>
              {tabs.map((tabValue) => (
                <li className='nav-item p-0 ms-0 me-8' key={`tab_${tabValue.tab}`}>
                  <button
                    className={`nav-link btn btn-color-muted px-0${
                      tabValue.tab === tab ? ' active' : ''
                    }`}
                    onClick={() => setTabValues(tabValue.tab)}
                  >
                    <span className='nav-text fw-semibold fs-4 mb-3'>{tabValue.tab}</span>
                    <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded' />
                  </button>
                </li>
              ))}
            </ul>

            <div className='card-toolbar'>
              <ul className='nav'>
                {tabs
                  .find((t) => t.tab === tab)
                  ?.subTabs.map((subTabValue) => {
                    const tabId = typeof subTabValue === 'string' ? subTabValue : subTabValue._id
                    const selectedTabId = typeof subTab === 'string' ? subTab : subTab?._id
                    return (
                      <li className='nav-item' key={tabId}>
                        <button
                          className={`position-relative nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1${
                            tabId === selectedTabId ? ' active' : ''
                          }`}
                          onClick={() => setSubTab(subTabValue)}
                        >
                          {typeof subTabValue === 'string'
                            ? subTabValue
                            : subTabValue.placement.name}

                          {tab === 'Health' && subTabValue === 'Advanced' && <Beta />}
                        </button>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>

          <div className='card-body pt-1'>
            {(tab === 'Health' || tab === 'Anomaly') && isCalibrating
              ? calibratingPlaceholder
              : null}

            {/* Health - Simple */}
            {tab === 'Health' && subTab === 'Simple' && !isCalibrating && (
              <HealthGraph assetId={asset._id} type='simple' height='400px' />
            )}

            {/* Health - Advanced */}
            {tab === 'Health' && subTab === 'Advanced' && !isCalibrating && (
              <HealthGraph assetId={asset._id} type='advanced' height='400px' />
            )}

            {/* Anomaly */}
            {tab === 'Anomaly' && !isCalibrating && (
              <AnomalyGraph assetId={asset._id} height='400px' />
            )}

            {/* Temp */}
            {tab === 'Temp' && subTab !== undefined && typeof subTab !== 'string' && (
              <TemperatureGraph sensor={subTab} height='400px' />
            )}

            {/* RMS */}
            {tab === 'RMS' && subTab !== undefined && typeof subTab !== 'string' && (
              <RmsGraph sensor={subTab} height='400px' />
            )}

            {/* FFT */}
            {tab === 'FFT' && subTab !== undefined && (
              <FFTGraph sensor={subTab as Sensor} loading={sensorsLoading} />
            )}

            {/* Battery */}
            {tab === 'Battery' && subTab !== undefined && typeof subTab !== 'string' && (
              <BatteryGraph sensor={subTab} height='400px' />
            )}
          </div>
        </div>
      </div>

      {/* Right Side */}
      <div className='col-xl-3 mb-md-5 mb-xl-10'>
        {/* Perform Maintence */}
        <div className='card mb-5'>
          <div className='card-header'>
            <div className='card-title'>
              <h3 className='fw-bold m-0 text-gray-800'>Register Maintenance</h3>
            </div>
            <div className='card-toolbar'>
              <button
                className='btn btn-sm btn-primary me-3'
                onClick={() => showRegisterMaintenanceModal(true)}
              >
                {'->'}
              </button>
            </div>
          </div>
          {registerMaintenanceModal}
        </div>

        {/* Fault Trend Detection */}
        <div className='card mb-5'>
          <div className='card-header'>
            <div className='card-title'>
              <h3 className='fw-bold m-0 text-gray-800'>Fault Trend Detection</h3>
            </div>
          </div>

          <div className='card-body'>
            <p className='text-gray-800 fw-normal'>
              If you are about to perform maintence and inspect the machine, you can check the fault
              trend details and see a checklist by pressing the button below. This will provide an
              indication of the faults that the system has detected symptoms of with an associated
              checklist of actions that can be worked through.
            </p>
            <a className='btn btn-primary' onClick={() => setShowChecklist(true)}>
              Go to detailed view and checklist
            </a>
          </div>
        </div>

        {/* Verify Machine Data */}
        <div className='card mb-5'>
          <div className='card-header'>
            <LoadingWrapper loading={isAssetsStateVerifyLoading}>
              {isAssetsStateVerifySuccess ? (
                <div className='card-title text-success'>Verify request was sent</div>
              ) : (
                <>
                  <div className='card-title'>
                    <h3 className='fw-bold m-0 text-gray-800'>Verify Machine Data</h3>
                  </div>
                  <div className='card-toolbar'>
                    <button
                      disabled={!isVerifyEnabled}
                      className='btn btn-sm btn-primary me-3'
                      onClick={() => setShowVerifyModal(true)}
                    >
                      Verify
                    </button>
                  </div>
                  {verifyModal}
                </>
              )}
            </LoadingWrapper>
          </div>
        </div>

        {/* Notifications */}
        <div className='card'>
          <div className='card-header card-header-stretch'>
            <div className='card-title'>
              <h3 className='fw-bold m-0 text-gray-800'>Notifications</h3>
            </div>
          </div>
          <div className='card-body'>
            <Notifications asset={asset} />
          </div>
        </div>
      </div>

      <AssetChecklists
        asset={asset}
        show={showChecklist}
        handleClose={() => setShowChecklist(false)}
      />
    </div>
  )
}

const ConditionDashboardWrapper: FC = () => {
  let {id} = useParams()
  const {data: asset} = useAsset({assetId: id})
  const {data: healths = []} = useGetAssetStates({ids: [id]})
  const health = healths[0] ?? null

  function breadcrumbs(): Breadcrumb[] {
    if (!asset || !asset.area) return []

    return [
      {
        title: asset.area.name,
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]
  }

  return (
    <>
      <PageNavigationBar title={asset?.name} breadcrumbs={breadcrumbs()}>
        {health ? <AssetStateBadge state={health.title} /> : null}
      </PageNavigationBar>
      <LoadingWrapper loading={asset === undefined}>
        <ConditionDashboardPage asset={asset!} />
      </LoadingWrapper>
    </>
  )
}

export {ConditionDashboardWrapper}
