import {useState} from 'react'
import {Placement} from '../../../core/_models'
import CreatePlacementAlertModal from '../../../modals/create-placement-alert/CreatePlacementAlertModal'
import PlacementAlertsModal from '../../../modals/placement-alerts/PlacementAlertsModal'

type Props = {
  placement: Placement
}

const AlertsCell = ({placement}: Props) => {
  const [showAlertsModal, setShowAlertsModal] = useState(false)

  return (
    <>
      <div className='d-flex align-items-end'>
        <button
          type='button'
          className='btn btn-lg btn-primary'
          onClick={() => setShowAlertsModal(true)}
        >
          Alerts
        </button>
      </div>

      <PlacementAlertsModal
        placement={placement}
        show={showAlertsModal}
        handleClose={() => setShowAlertsModal(false)}
      />
    </>
  )
}

export default AlertsCell
