import {useState} from 'react'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {Asset} from '../../core/_models'
import {useCreateModals} from '../CreateModalsProvider'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import {defaultCreateAsset, ICreateAsset} from './_models'
import {createAsset} from '../../core/requests/factory'
import Step4 from './Step4'

const CreateAssetModal = () => {
  const [data, setData] = useState<ICreateAsset>(defaultCreateAsset)
  const {
    showCreateAssetModal,
    closeCreateAssetModal,
    openCreatePartModal,
    initialSection,
    initialArea,
  } = useCreateModals()

  const steps: Step<ICreateAsset>[] = [
    {
      title: 'Name',
      description: 'Name Your Asset',
      component: Step1,
      validation: (data) => !data.name,
      skipTo: [
        {
          step: 2,
          condition: () => initialSection !== undefined,
          action: (data) =>
            setData({...data, locationChoice: 'section', section: initialSection?._id}),
        },
        {
          step: 2,
          condition: () => initialArea !== undefined,
          action: (data) => setData({...data, locationChoice: 'area', area: initialArea?._id}),
        },
      ],
    },
    {
      title: 'Location',
      description: 'Area or Section',
      component: Step2,
      validation: (data) => !data.locationChoice,
    },
    {
      title: 'Specific',
      description: 'Area or Section',
      component: Step3,
      validation: (data) =>
        (data.locationChoice === 'area' && !data.area) ||
        (data.locationChoice === 'section' && !data.section),
    },
    {
      title: 'Auto-Detection',
      description: 'Opt For Automatic or Manual Control',
      component: Step4,
    },
  ]

  return (
    <StepModalWrapper<ICreateAsset, Asset>
      id='hs_create_asset'
      objectName='Asset'
      show={showCreateAssetModal}
      close={closeCreateAssetModal}
      steps={steps}
      data={data}
      defaultData={defaultCreateAsset}
      setData={setData}
      submitData={createAsset}
      openNextModals={[openCreatePartModal]}
      nextObjectNames={['Part']}
    />
  )
}

export default CreateAssetModal
