import {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import {Asset} from '../../core/_models'
import {
  RegisterMaintenanceData,
  useRegisterMaintenanceMutation,
} from '../../core/requests/maintenance'

type RegisterMaintenanceProps = {
  asset: Asset
  onCancel: () => void
  onComplete: () => void
}

export const RegisterMaintenance = ({onCancel, onComplete, asset}: RegisterMaintenanceProps) => {
  const [startTime, setStartTime] = useState<Date>()
  const [endTime, setEndTime] = useState<Date>()
  const [note, setNote] = useState<string>('')
  const {mutate, isLoading, isSuccess, error} = useRegisterMaintenanceMutation()

  useEffect(() => {
    if (isSuccess) {
      onComplete()
    }
  }, [isSuccess, onComplete])

  const submitForm = () => {
    if (startTime && endTime) {
      const data: RegisterMaintenanceData = {
        uuid: asset._id,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        note: note || undefined,
      }
      mutate(data)
    }
  }

  const startTimeElement = (
    <div>
      <label className='required form-label'>Start date time</label>
      <Flatpickr
        data-enable-time
        placeholder={'Start date time'}
        className='form-control form-control-solid me-5'
        value={startTime}
        required={true}
        onChange={([date]) => setStartTime(date)}
      />
    </div>
  )
  const endTimeElement = (
    <div>
      <label className='required form-label'>End date time</label>
      <Flatpickr
        data-enable-time
        placeholder={'End date time'}
        className='form-control form-control-solid'
        value={endTime}
        required={true}
        onChange={([date]) => setEndTime(date)}
      />
    </div>
  )
  const noteElement = (
    <div>
      <label className='form-label'>Note</label>
      <textarea
        className='form-control form-control-solid'
        placeholder={'Note'}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        rows={1}
      />
    </div>
  )
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        submitForm()
      }}
    >
      {error?.response?.data?.detail ? (
        <div className='alert alert-danger d-flex align-items-center p-5'>
          {error.response.data.detail}
        </div>
      ) : null}
      <div className={`d-flex align-items-end justify-content-between mb-5`}>
        {startTimeElement}
        {endTimeElement}
        {noteElement}
        <div className={`d-flex justify-content-end`}>
          <button className='btn btn-secondary me-5' onClick={() => onCancel()}>
            Cancel
          </button>
          <button className='btn btn-primary' disabled={!startTime || !endTime || isLoading}>
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}
