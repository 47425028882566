import React from 'react'
import {format} from 'date-fns'
import {CopyClipboardText} from '../../components/content/CopyClipboardText'
import {Sensor, sensorTypesMap} from '../../core/_models'
import {deleteSensor} from '../../core/requests/factory'
import {useAuth} from '../../modules/auth'
import {ColorCell} from '../../pages/overview/core/columns/ColorCell'
import {NameImageCell} from '../../pages/overview/core/columns/ImageStringCell'
import useEntityDeleteModal from '../entity-delete/useEntityDeleteModal'
import OverviewDetailsModal, {BaseOverviewDetailsModalProps} from './OverviewDetailsModal'
import SensorMoveSelector from '../../components/form-components/SensorMoveSelector'

const SensorOverviewDetailsModal = ({
  entity,
  onHide,
  show = true,
}: BaseOverviewDetailsModalProps<Sensor>) => {
  const profile = useAuth()
  const additionalAttributes: [string, React.ReactNode][] = []
  if (entity.data_types.find((d) => d === sensorTypesMap.vibration)) {
    additionalAttributes.push(['X, Y, Z Fields', `${entity.x} ${entity.y} ${entity.z}`])
  }

  if (profile.currentProfile?.is_staff) {
    additionalAttributes.push(['Is Demo', `${entity.is_demo}`])
  }

  const deleteModal = useEntityDeleteModal({
    entity: {
      _id: entity._id,
      name: entity.device_id,
    },
    deleteHandler: deleteSensor,
    queryToInvalidate: 'sensors',
  })

  return (
    <OverviewDetailsModal
      show={show}
      onHide={onHide}
      title={
        <>
          Sensor Details ・ {entity.device_id} {deleteModal.handler}
        </>
      }
      objectId={entity._id}
      attributes={[
        ['ID', <CopyClipboardText text={entity._id} />],
        [
          'Gallery',
          <NameImageCell
            index={1}
            str={''}
            images={
              entity.gallery
                ? [entity.gallery.profile_image, ...(entity.gallery.images ?? [])]
                : undefined
            }
            entityId={entity._id}
            queryToInvalidate='sensors'
          />,
        ],
        ['Organization Name', entity.organization.name],
        ['Factory Name', entity.factory.name],
        ['Area Name', entity.area.name],
        ['Production Line Name', entity.production_line?.name || '-'],
        ['Section Name', entity.section?.name || '-'],
        ['Asset Name', entity.asset?.name || '-'],
        [
          'Part manufacturer and model number',
          `${entity.part?.manufacturer || '-'} ${entity.part?.model_number || '-'}`,
        ],
        ['Placement Name', entity.placement?.name || '-'],
        ['Device ID', entity.device_id],
        [
          'Data Types',
          <>
            {entity.data_types.map((dataType) => (
              <span className={`badge badge-sm bg-primary ms-1`}>{dataType}</span>
            ))}
          </>,
        ],
        [
          'Track Scrap',
          entity.data_types.find((d) => d === sensorTypesMap.output)
            ? entity.track_scrap || '-'
            : '-',
        ],
        [
          'Manufacturer and model',
          <>
            <span className={`badge badge-sm bg-primary ms-1`}>{entity.manufacturer}</span>
            <span className={`badge badge-sm bg-primary ms-1`}>{entity.model}</span>
          </>,
        ],
        ['Offline Tolerance (minutes)', entity.offline_tolerance_minutes],
        [
          'State',
          <div className={`container`}>
            <div className={`row`}>
              <div className='col'>
                <ColorCell color={`status-${entity.state.toLowerCase()}`} />
              </div>
            </div>
            <div className={`row`}>
              <div className='col col-5 m-2'>First Record</div>
              <div className='col m-2 d-flex justify-content-end'>
                {entity.first_record_at ? format(new Date(entity.first_record_at), 'PPpp') : '-'}
              </div>
            </div>
            <div className={`row`}>
              <div className='col col-5 m-2'>Latest Record</div>
              <div className='col m-2 d-flex justify-content-end'>
                {entity.latest_record_at ? format(new Date(entity.latest_record_at), 'PPpp') : '-'}
              </div>
            </div>
            <div className={`row`}>
              <div className='col col-5 m-2'>Battery</div>
              <div className='col m-2 d-flex justify-content-end'>
                <div
                  className={`fa-duotone fa-solid  ${
                    !entity.battery_low
                      ? 'fa-battery-full text-success'
                      : 'fa-battery-empty text-danger'
                  }`}
                ></div>
              </div>
            </div>
          </div>,
        ],
        ['Move to Placement', <SensorMoveSelector sensor={entity} />],
        ...additionalAttributes,
      ]}
    />
  )
}

export {SensorOverviewDetailsModal}
