import React from 'react'
import {useIntl} from 'react-intl'
import SelectField from '../../components/form-components/SelectField'
import TextField from '../../components/form-components/TextField'
import LoadingWrapper from '../../components/LoadingWrapper'
import {useFactoriesPaginated} from '../../core/requests/factory'
import {useCreateModals} from '../CreateModalsProvider'
import {StepProps} from './_models'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  const {initialFactory} = useCreateModals()

  const {items, isLoading, isFetching, fetchNextPage} = useFactoriesPaginated()

  React.useEffect(() => {
    if (!data.factory && initialFactory) updateData({factory: initialFactory._id})
  }, [initialFactory])

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={isLoading}>
        <div className='w-100'>
          {/* Area Name */}
          <TextField
            name='Area Name'
            data={data}
            updateData={updateData}
            dataField='name'
            hasError={hasError}
            tooltip={intl.formatMessage({id: 'TOOLTIP.NAMED_ENTITY.AREA'})}
            required
          />

          {/* Factory */}
          <SelectField
            name='Factory'
            optionNames={items?.map((factory) => factory.name) || []}
            optionValues={items?.map((factory) => factory._id) || []}
            data={data}
            updateData={updateData}
            dataField='factory'
            hasError={hasError}
            tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.FACTORY'})}
            required
            isLoading={isFetching}
            gotoNextPage={fetchNextPage}
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step1
