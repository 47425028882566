import {Link} from 'react-router-dom'
import {Column} from 'react-table'
import {Batch, WorkOrder} from '../../core/_models'
import {convertSecondsToHoursMinutesSeconds, rfc3339ToString} from '../../core/time-util'
import useEditBatchModal from '../../modals/edit-batch/useEditBatchModal'
import {BadgeCell} from '../overview/core/columns/BadgeCell'
import {CustomHeader} from '../overview/core/columns/CustomHeader'
import {StringCell} from '../overview/core/columns/StringCell'
import {ActionButtonCell} from './ActionButtonCell'

function isBatch(item: Batch | WorkOrder) {
  return 'production_line' in item
}

function anyItemRunning(items: ReadonlyArray<Batch | WorkOrder>, skipIndex: number) {
  return items.some((item, index) => {
    if (index === skipIndex) return false
    return item.status !== 'pending' && item.status !== 'done'
  })
}

const Columns: ReadonlyArray<Column<Batch | WorkOrder>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => {
      const record = props.data[props.row.index]
      const editModal = useEditBatchModal()
      const icon = (
        <span className={`fa me-2 fs-1 ${isBatch(record) ? 'fa-toolbox' : 'fa-clipboard-list'}`} />
      )
      const title = isBatch(record) ? (record as Batch).name : (record as WorkOrder).title
      return (
        <div className='d-flex align-items-center'>
          {icon}
          <Link
            onClick={() => editModal.setId(isBatch(record) ? record._id : undefined)}
            to={`#`}
            className='text-gray-800 text-hover-primary mb-0'
          >
            {title}
          </Link>
          {editModal.modal}
        </div>
      )
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({...props}) => <BadgeCell str={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Planned Start Time' className='min-w-125px' />
    ),
    id: 'planned_start_time',
    Cell: ({...props}) => (
      <StringCell str={rfc3339ToString(props.data[props.row.index].planned_start_time)} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Expected Duration' className='min-w-125px' />
    ),
    id: 'expected_duration',
    Cell: ({...props}) => (
      <StringCell
        str={convertSecondsToHoursMinutesSeconds(
          isBatch(props.data[props.row.index])
            ? (props.data[props.row.index] as Batch).planned_production_time
            : (props.data[props.row.index] as WorkOrder).expected_duration * 60
        )}
      />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Start Time' className='min-w-125px' />
    ),
    id: 'start_time',
    Cell: ({...props}) => (
      <StringCell
        str={
          props.data[props.row.index].start_time
            ? rfc3339ToString(props.data[props.row.index].start_time)
            : '-'
        }
      />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='End Time' className='min-w-125px' />,
    id: 'end_time',
    Cell: ({...props}) => (
      <StringCell
        str={
          props.data[props.row.index].end_time
            ? rfc3339ToString(props.data[props.row.index].end_time)
            : '-'
        }
      />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Action' className='min-w-125px' />,
    id: 'action',
    Cell: ({...props}) => (
      <ActionButtonCell
        item={props.data[props.row.index]}
        type={isBatch(props.data[props.row.index]) ? 'batch' : 'workOrder'}
        otherItemRunning={anyItemRunning(props.data, props.row.index)}
      />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} className='min-w-125px' />,
    id: 'details',
    Cell: ({...props}) => {
      const record = props.data[props.row.index]
      return isBatch(record) ? (
        <Link
          to={`/production-lines/${(record as Batch).production_line}?batch=${record._id}`}
          className={`btn btn-primary`}
        >
          <span className={`fa fa-chevron-right`} />
        </Link>
      ) : null
    },
  },
]

export default Columns
