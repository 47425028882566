import React, {FC} from 'react'
import PageNavigationBar from '../../components/PageNavigationBar'

const SupportWrapper: FC = () => {
  return (
    <>
      <PageNavigationBar title={`Support`} />
      <iframe
        title='clickup'
        className='clickup-embed'
        src='https://forms.clickup.com/6634470/f/6aez6-7025/8HQD7SPIG4QE22NR7G'
        width='100%'
        height='920px'
        style={{background: 'transparent', border: '1px solid #ccc'}}
      />
      <script async src='https://app-cdn.clickup.com/assets/js/forms-embed/v1.js'></script>
    </>
  )
}

export {SupportWrapper}
