import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import {showNoDataDisplay} from '../../../../core/chart-util'
import {Optional} from '@amcharts/amcharts5/.internal/core/util/Type'

export type CategoryData = {
  id: string
  category: string
  occurrences: number
  duration: number
  color?: string
  subcategories: {
    id: string
    subcategory: string
    occurrences: number
    duration: number
    color?: string
  }[]
}

type TransformedCategorySubcategoryData = {
  category: string
  occurrences: number
  occurrencesPercent: number
  duration: number
  color?: string
}

export type TransformedCategoryData = {
  category: string
  occurrences: number
  occurrencesPercent: number
  duration: number
  color?: string
  subcategories: TransformedCategorySubcategoryData[]
}

export function paretoConfig(chartId: string, categoryData: CategoryData[]) {
  const transformedData = transformCategoryData(categoryData)

  const root = am5.Root.new(chartId)
  root.setThemes([am5themes_Animated.new(root)])

  // Create chart
  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'panX',
      wheelY: 'zoomX',
      layout: root.verticalLayout,
    })
  )

  // Setup axes
  const xRenderer = am5xy.AxisRendererX.new(root, {
    minGridDistance: 30,
  })

  const xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      categoryField: 'category',
      renderer: xRenderer,
    })
  )

  xRenderer.grid.template.setAll({location: 1})
  xRenderer.labels.template.setAll({paddingTop: 20})

  xAxis.get('renderer').labels.template.setup = function (target) {
    target.set(
      'background',
      am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 0,
      })
    )
    // target.set('cursorOverStyle', 'pointer')
  }

  xAxis.get('renderer').labels.template.setAll({
    text: '{dataItem.dataContext.displayCategory}',
  })

  xAxis.data.setAll(transformedData)

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1,
      }),
      numberFormat: "#' minutes'",
    })
  )

  const paretoAxisRenderer = am5xy.AxisRendererY.new(root, {opposite: true})
  const paretoAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: paretoAxisRenderer,
      min: 0,
      max: 100,
      strictMinMax: true,
      numberFormat: "#'%'",
    })
  )

  paretoAxisRenderer.grid.template.set('forceHidden', true)

  // Add series
  const series = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'duration',
      categoryXField: 'category',
    })
  )

  series.columns.template.setAll({
    tooltipText: '[bold]{dataItem.dataContext.displayCategory}:[/] {valueY} minutes',
    tooltipY: 0,
    strokeOpacity: 0,
    cornerRadiusTL: 6,
    cornerRadiusTR: 6,
    // cursorOverStyle: 'pointer',
  })

  series.columns.template.adapters.add('fill', (_fill, target) => {
    const color = (target.dataItem!.dataContext as TransformedCategoryData).color
    if (color) return am5.color(color)
    else return chart.get('colors')!.getIndex(series.dataItems.indexOf(target.dataItem!))
  })

  series.columns.template.adapters.add('cursorOverStyle', (_cursor, target) => {
    const dataItem = target.dataItem?.dataContext as TransformedCategoryData
    if (dataItem && dataItem.subcategories !== undefined && dataItem.subcategories.length > 0)
      return 'pointer'
    else return 'default'
  })

  xAxis.get('renderer').labels.template.adapters.add('cursorOverStyle', (_cursor, target) => {
    const dataItem = target.dataItem?.dataContext as TransformedCategoryData
    if (dataItem && dataItem.subcategories !== undefined && dataItem.subcategories.length > 0)
      return 'pointer'
    else return 'default'
  })

  series.bullets.push(function () {
    return am5.Bullet.new(root, {
      locationY: 1,
      sprite: am5.Label.new(root, {
        text: 'Occurrences: {occurrences}',
        populateText: true,
        centerY: am5.percent(50),
        centerX: am5.percent(50),
        fontSize: 16,
        dy: -15,
        // oversizedBehavior: 'wrap',
      }),
    })
  })

  // pareto series
  const paretoSeries = chart.series.push(
    am5xy.LineSeries.new(root, {
      xAxis: xAxis,
      yAxis: paretoAxis,
      valueYField: 'occurrencesPercent',
      categoryXField: 'category',
      stroke: root.interfaceColors.get('alternativeBackground'),
      maskBullets: false,
    })
  )

  paretoSeries.bullets.push(function () {
    return am5.Bullet.new(root, {
      locationY: 1,
      sprite: am5.Circle.new(root, {
        radius: 5,
        fill: series.get('fill'),
        stroke: root.interfaceColors.get('alternativeBackground'),
      }),
    })
  })

  // Interaction
  let viewingSubcategories = false

  const onCategoryClick = (categoryData: TransformedCategoryData) => {
    if (!categoryData.subcategories.length) return
    backButton.show()
    viewingSubcategories = true

    xAxis.data.setAll(categoryData.subcategories)
    series.data.setAll(categoryData.subcategories)
    paretoSeries.data.setAll(categoryData.subcategories)
  }

  const backButton = chart.plotContainer.children.push(
    am5.Button.new(root, {
      dx: 10,
      dy: 10,
      label: am5.Label.new(root, {
        text: 'Back',
      }),
    })
  )
  backButton.hide()

  const backButtonClick = () => {
    backButton.hide()
    xAxis.data.setAll(transformedData)
    series.data.setAll(transformedData)
    paretoSeries.data.setAll(transformedData)
    viewingSubcategories = false
  }

  backButton.events.on('click', backButtonClick)

  series.columns.template.events.on('click', (ev) => {
    const dataItem = ev.target.dataItem?.dataContext as Optional<
      TransformedCategoryData | TransformedCategorySubcategoryData
    >
    if (!dataItem || viewingSubcategories) return
    onCategoryClick(dataItem as TransformedCategoryData)
  })

  xAxis.get('renderer').labels.template.events.on('click', (ev) => {
    const dataItem = ev.target.dataItem?.dataContext as Optional<
      TransformedCategoryData | TransformedCategorySubcategoryData
    >
    if (!dataItem || viewingSubcategories) return
    onCategoryClick(dataItem as TransformedCategoryData)
  })

  series.data.setAll(transformedData)
  paretoSeries.data.setAll(transformedData)

  if (!categoryData.length) return showNoDataDisplay(chartId, root)

  series.appear()
  chart.appear(1000, 100)

  return root
}

function transformCategoryData(categoryData: CategoryData[]): TransformedCategoryData[] {
  const totalOccurrences = categoryData.reduce((acc, {occurrences}) => acc + occurrences, 0)
  let totalOccurrencesSum = 0

  categoryData.sort((a, b) => (a.duration > b.duration ? -1 : 1))

  return categoryData.map(({id, category, duration, occurrences, color, subcategories}) => {
    totalOccurrencesSum += occurrences
    let subcategoriesOccurrencesSum = 0

    return {
      category: id,
      displayCategory: category,
      occurrences,
      occurrencesPercent: (totalOccurrencesSum / totalOccurrences) * 100,
      duration: Number((duration / 60).toFixed(2)),
      color,
      subcategories: subcategories.map(({id, subcategory, occurrences, duration, color}) => {
        subcategoriesOccurrencesSum += occurrences
        return {
          category: id,
          displayCategory: subcategory,
          occurrences,
          occurrencesPercent: (subcategoriesOccurrencesSum / occurrences) * 100,
          duration: Number((duration / 60).toFixed(2)),
          color,
        }
      }),
    }
  })
}
