import {useState} from 'react'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {ProductionLine} from '../../core/_models'
import {useCreateModals} from '../CreateModalsProvider'
import Step1 from './Step1'
import {defaultCreateProductionLine, ICreateProductionLine} from './_models'
import Step2 from './Step2'
import {createProductionLine} from '../../core/requests/factory'
import Step3 from './Step3'

const CreateProductionLineModal = () => {
  const [data, setData] = useState<ICreateProductionLine>(defaultCreateProductionLine)
  const {
    showCreateProductionLineModal,
    closeCreateProductionLineModal,
    openCreateSectionModal,
    openCreatePlacementModalFromProductionLine,
  } = useCreateModals()

  const steps: Step<ICreateProductionLine>[] = [
    {
      title: 'Name',
      description: 'Name Your Production Line',
      component: Step1,
      validation: (data) => data.name === undefined || data.area === undefined,
    },
    {
      title: 'Type',
      description: 'Select Production Line Type',
      component: Step2,
    },
    {
      title: 'Auto-Detection',
      description: 'Opt For Automatic or Manual Control',
      component: Step3,
    },
  ]

  return (
    <StepModalWrapper<ICreateProductionLine, ProductionLine>
      id='hs_create_production_line'
      objectName='Production Line'
      show={showCreateProductionLineModal}
      close={closeCreateProductionLineModal}
      steps={steps}
      data={data}
      defaultData={defaultCreateProductionLine}
      setData={setData}
      submitData={createProductionLine}
      openNextModals={[openCreateSectionModal, openCreatePlacementModalFromProductionLine]}
      nextObjectNames={['Section', 'Placement']}
    />
  )
}

export default CreateProductionLineModal
