import {useCategorySelection} from '../CategorySelectionContext'
import EventCategoryGroupDeleteModal from './EventCategoryGroupDeleteModal'
import EventCategoryDeleteModal from './EventCategoryDeleteModal'

const EventRegistrationDeleteModal = () => {
  const {showDeleteModal, closeDeleteModal, categoryToDelete, subEventCategoryPathIdsToDelete} =
    useCategorySelection()

  if (categoryToDelete === undefined) return null

  if ('color' in categoryToDelete)
    return (
      <EventCategoryDeleteModal
        show={showDeleteModal}
        close={closeDeleteModal}
        eventCategory={categoryToDelete}
        subEventCategoryPathIds={subEventCategoryPathIdsToDelete}
      />
    )

  return (
    <EventCategoryGroupDeleteModal
      show={showDeleteModal}
      close={closeDeleteModal}
      eventCategoryGroup={categoryToDelete}
    />
  )

  return null
}

export default EventRegistrationDeleteModal
