import React from 'react'
import DateTimeField from '../../components/form-components/DateTimeField'
import NumberField from '../../components/form-components/NumberField'
import TextField from '../../components/form-components/TextField'
import {Batch} from '../../core/_models'

interface EditBatchFormProps {
  batch: Batch
  updateHandler: (data: Partial<Batch>) => void
  cancelHandler?: () => void
}

const EditBatchForm = ({batch, updateHandler, cancelHandler}: EditBatchFormProps) => {
  const [data, setData] = React.useState({
    name: batch.name,
    planned_start_time: new Date(batch.planned_start_time).valueOf(),
    start_time: batch?.start_time ? new Date(batch.start_time) : undefined,
    end_time: batch?.end_time ? new Date(batch.end_time) : undefined,
    total_product_quantity: batch.total_product_quantity,
  })

  const submitData = () => {
    updateHandler({
      name: data.name,
      planned_start_time: new Date(data.planned_start_time).toISOString(),
      start_time: data.start_time ? new Date(data.start_time).toISOString() : undefined,
      end_time: data.end_time ? new Date(data.end_time).toISOString() : undefined,
      total_product_quantity: data.total_product_quantity,
    })
  }

  const form = (
    <form>
      <TextField
        name='Name'
        data={data}
        updateData={(fieldsToUpdate) =>
          setData({
            ...data,
            name: fieldsToUpdate['name'] as string,
          })
        }
        dataField='name'
      />
      <DateTimeField
        id='planned_start_time'
        name='Planned Start time'
        data={data}
        updateData={(fieldsToUpdate) =>
          setData({
            ...data,
            planned_start_time: fieldsToUpdate['planned_start_time'] as number,
          })
        }
        dataField='planned_start_time'
      />
      <DateTimeField
        id='start_time'
        name='Start time'
        data={data}
        updateData={(fieldsToUpdate) =>
          setData({
            ...data,
            start_time: fieldsToUpdate['start_time'],
          })
        }
        dataField='start_time'
      />
      <DateTimeField
        id='end_time'
        name='End time'
        data={data}
        updateData={(fieldsToUpdate) =>
          setData({
            ...data,
            end_time: fieldsToUpdate['end_time'],
          })
        }
        dataField='end_time'
      />
      <NumberField
        name='Total Product Quantity'
        data={data}
        updateData={(fieldsToUpdate) =>
          setData({
            ...data,
            total_product_quantity: fieldsToUpdate['total_product_quantity'] as number,
          })
        }
        dataField='total_product_quantity'
      />
      <div className='d-flex justify-content-between'>
        {cancelHandler ? (
          <button type='button' className='btn btn-secondary' onClick={cancelHandler}>
            Cancel
          </button>
        ) : null}
        <button type='button' className='btn btn-primary' onClick={submitData}>
          Update
        </button>
      </div>
    </form>
  )

  return <>{form}</>
}

export default EditBatchForm
