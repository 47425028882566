import clsx from 'clsx'

interface Props<DataType extends Record<string, any>> {
  name?: string
  required?: boolean
  tooltip?: string
  optionNames: string[]
  optionDescriptions?: string[]
  optionIcons?: string[]
  optionValues: any[]
  data: DataType
  dataField: Extract<keyof DataType, string>
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  updatesOnChange?: Partial<DataType>
  colSpace?: 1 | 2 | 3 | 4 | 6 | 12
  hasError?: boolean
  lastField?: boolean
}

const SelectBoxes = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  optionNames,
  optionDescriptions,
  optionIcons,
  optionValues,
  data,
  dataField,
  updateData,
  updatesOnChange,
  colSpace,
  hasError = false,
  lastField = false,
}: Props<DataType>) => {
  const handleChange = (value: string) => {
    updateData({
      [dataField]: value !== data[dataField] ? value : undefined,
      ...(updatesOnChange || {}),
    } as Partial<DataType>)
  }

  return (
    <div className={clsx({'mb-10': !lastField})}>
      {name && (
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className={clsx({required: required})}>{name}</span>
          {tooltip && (
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title={tooltip}
            />
          )}
        </label>
      )}
      <div className='row'>
        {optionNames.map((optionName, index) => (
          <div
            className={`${index !== 0 ? 'mt-3' : ''} col${colSpace ? `-${colSpace}` : ''}`}
            key={index}
          >
            <label
              className={clsx(
                'btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center h-100',
                {active: data[dataField] === optionValues[index]}
              )}
              onClick={() => handleChange(optionValues[index])}
            >
              {optionIcons && (
                <span className='me-5'>
                  <i className={`fa-solid ${optionIcons[index]}`} style={{fontSize: '2.5rem'}} />
                </span>
              )}

              <span className='d-block fw-semibold text-start'>
                <span
                  className={clsx('text-dark fw-bold d-block fs-4', {'mb-2': optionDescriptions})}
                >
                  {optionName}
                </span>
                {optionDescriptions && (
                  <span className='text-muted fw-semibold fs-6'>{optionDescriptions[index]}</span>
                )}
              </span>
            </label>
          </div>
        ))}
      </div>

      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name || 'This field'} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export default SelectBoxes
