import {Checklist} from '../../../gql/graphql'
import {Asset} from '../../core/_models'
import {useState} from 'react'
import useChecklist from '../../core/graphql/condition/queries/Checklist'
import AssetChecklistConfirmationModal from './AssetChecklistConfirmationModal'
import AssetChecklistModal from './AssetChecklistModal'
import {IWorkOrder} from '../../pages/work-orders/modal/_models'
import WorkOrderModal from '../../pages/work-orders/modal/WorkOrderModal'

type Props = {
  asset: Asset
  show: boolean
  handleClose: () => void
}

const AssetChecklists = ({asset, show, handleClose}: Props) => {
  const [confirmed, setConfirmed] = useState(false)
  const [workOrder, setWorkOrder] = useState<Partial<IWorkOrder>>()

  const {data: checklists = []} = useChecklist({id: confirmed ? asset._id : undefined})

  const close = () => {
    handleClose()
    setConfirmed(false)
    setWorkOrder(undefined)
  }

  const setWorkOrderFromChecklists = (checklists: Checklist[]) => {
    setWorkOrder({
      title: `${asset.name} Checklist`,
      description: `Checklist for ${asset.name}`,
      factory_entity: asset._id,
      actions: checklists.flatMap((checklist) => checklist.actions),
      selected_entity_type: 'asset',
    })
  }

  return (
    <>
      <AssetChecklistConfirmationModal
        show={show && !workOrder && !confirmed}
        handleClose={close}
        handleConfirm={() => setConfirmed(true)}
      />

      <AssetChecklistModal
        checklists={checklists}
        show={show && !workOrder && confirmed}
        handleClose={close}
        handleCreateWorkOrder={() => setWorkOrderFromChecklists(checklists!)}
      />

      <WorkOrderModal
        show={show && workOrder !== undefined}
        hide={close}
        initialValues={workOrder}
      />
    </>
  )
}

export default AssetChecklists
