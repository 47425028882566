import OperationalStateBox from '../../../../components/OperationalStateBox'
import {WidgetProps} from './Widget'

const OperationalStateWidget = ({widget}: WidgetProps) => (
  <div className='d-flex align-items-center justify-content-center h-100 m-5'>
    <OperationalStateBox machineId={widget.entity} />
  </div>
)

export default OperationalStateWidget
