import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import useModalState from '../../hooks/UseModalState'
import CreateAutomationModal from './CreateAutomationModal'
import {updateAutomation, useAutomations, useEvents} from '../../core/requests/automations'
import {BarLoader} from 'react-spinners'
import {actionTypesMap} from './actions/actions'
import React, {useState} from 'react'
import {Automation} from '../../core/_models'
import {useQueryClient} from 'react-query'
import DeleteAutomationModal from './DeleteAutomationModal'

type Props = {
  show: boolean
  handleClose: () => void
}

const AutomationsModal = ({show, handleClose}: Props) => {
  const [editingDescriptionIndex, setEditingDescriptionIndex] = useState<number | null>(null)
  const [editingDescription, setEditingDescription] = useState<string>('')
  const [deletingAutomation, setDeletingAutomation] = useState<Automation | null>(null)
  const [editingAutomation, setEditingAutomation] = useState<Automation | null>(null)

  const createAutomationModal = useModalState()
  const deleteAutomationModal = useModalState()

  const queryClient = useQueryClient()
  const {data: automations, isLoading: automationsLoading} = useAutomations()
  const {data: events, isLoading: eventsLoading} = useEvents()

  const handleDescriptionChange = async (automation: Automation, description: string) => {
    if (description === automation.description) return
    try {
      await updateAutomation({id: automation._id, description})
      await queryClient.invalidateQueries('automations')
    } catch (e) {
      console.error(e)
    }
  }

  const handleEnabledChange = async (automation: Automation, enabled: boolean) => {
    if (enabled === automation.enabled) return
    try {
      await updateAutomation({id: automation._id, enabled})
      await queryClient.invalidateQueries('automations')
    } catch (e) {
      console.error(e)
      queryClient.invalidateQueries('automations')
    }
  }

  const loading = automationsLoading || eventsLoading

  return (
    <>
      <Modal
        id='hs_automations_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered'
        size='xl'
        show={show && !createAutomationModal.isOpen}
        onHide={handleClose}
        container={document.getElementById('root-modals')}
      >
        <div className='modal-header'>
          <h2>Automations</h2>
          <div className='d-flex gap-10 align-items-center'>
            <button type='button' className='btn btn-primary' onClick={createAutomationModal.open}>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Automation
            </button>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
        </div>

        <div className='modal-body py-2 overflow-auto' style={{height: 'calc(100vh - 200px)'}}>
          {loading ? (
            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
              <BarLoader className='' color='#009ef7' />
            </div>
          ) : (
            <div className='d-flex flex-column w-100'>
              {automations?.items.map((automation, i) => (
                <div key={i} className='py-5 border-bottom d-flex flex-column gap-5'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-6 fw-bold'>
                      <span>When </span>
                      <span className='d-inline-block px-2 py-1 mx-1 bg-secondary rounded'>
                        {events?.find((ev) => ev[0] === automation.event)?.[1].title ||
                          automation.event}
                      </span>
                      <span> then </span>
                      {automation.actions.map((a, index) => (
                        <React.Fragment key={index}>
                          <span className='d-inline-block px-2 py-1 mx-1 bg-secondary rounded'>
                            {actionTypesMap[a.type]}
                          </span>
                          {index < automation.actions.length - 1 && <span> and </span>}
                        </React.Fragment>
                      ))}
                    </div>
                    <div>
                      <label className='form-check form-switch form-check-custom'>
                        <span className='me-2'>Enabled</span>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={automation.enabled}
                          onChange={(e) => handleEnabledChange(automation, e.target.checked)}
                        />
                      </label>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between align-items-center'>
                    {editingDescriptionIndex === i ? (
                      <input
                        type='text'
                        className='form-control-plaintext p-0 m-0 fs-6'
                        style={{
                          fontSize: 'inherit',
                          border: 'none',
                          background: 'none',
                          width: '100%',
                        }}
                        value={editingDescription}
                        onChange={(e) => setEditingDescription(e.target.value)}
                        onBlur={() => {
                          handleDescriptionChange(automation, editingDescription)
                          setEditingDescriptionIndex(null)
                        }}
                        autoFocus
                      />
                    ) : (
                      <div
                        className='fs-6 fst-italic cursor-pointer'
                        onClick={() => {
                          setEditingDescriptionIndex(i)
                          setEditingDescription(automation.description || '')
                        }}
                      >
                        {automation.description || 'Enter description...'}
                      </div>
                    )}
                    <div className='d-flex gap-3'>
                      <i
                        className='fa-solid fa-pen fs-6 cursor-pointer text-primary'
                        onClick={() => {
                          setEditingAutomation(automation)
                          createAutomationModal.open()
                        }}
                      />
                      <i
                        className='fa-solid fa-trash fs-6 cursor-pointer text-danger'
                        onClick={() => {
                          setDeletingAutomation(automation)
                          deleteAutomationModal.open()
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {deletingAutomation && (
          <DeleteAutomationModal
            show={deleteAutomationModal.isOpen}
            handleClose={() => {
              setDeletingAutomation(null)
              deleteAutomationModal.close()
            }}
            automation={deletingAutomation}
          />
        )}
      </Modal>

      {createAutomationModal.isOpen && (
        <CreateAutomationModal
          show={createAutomationModal.isOpen}
          handleClose={() => {
            setEditingAutomation(null)
            createAutomationModal.close()
          }}
          automation={editingAutomation || undefined}
        />
      )}
    </>
  )
}

export default AutomationsModal
