type PaginationProps = {
  page: number
  total: number
  size: number
  onClick: (page: number) => void
}

const Pagination = ({total, size, page, onClick}: PaginationProps) => (
  <ul className='pagination mb-5'>
    {[...Array(Math.ceil(total / size))].map((_, p) => (
      <li key={p} className={`page-item ${page === p + 1 ? 'active' : ''}`}>
        <a href='#' className='page-link' onClick={() => onClick(p + 1)}>
          {p + 1}
        </a>
      </li>
    ))}
  </ul>
)

export default Pagination
