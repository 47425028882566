import useGetStandbyConsumption from '../../../../core/graphql/energy/queries/GetStandbyConsumption'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import QuantityWidget from './utility-widgets/QuantityWidget'
import {WidgetProps} from './Widget'

const PotentialSavingsWidget = ({widget}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const {consumption} = useGetStandbyConsumption({
    entityGroup: widget.entity_group,
    id: widget.entity,
    startTime,
    endTime,
  })

  return <QuantityWidget value={consumption?.costs} unit={consumption?.currency} />
}

export default PotentialSavingsWidget
