import React from 'react'
import NumberField from '../../../../../components/form-components/NumberField'
import SwitchField from '../../../../../components/form-components/SwitchField'
import LoadingWrapper from '../../../../../components/LoadingWrapper'
import {
  usePlacement,
  usePlacementsPaginated,
  useProductionLine,
  useProductionLinesPaginated,
  useSection,
  useSectionsPaginated,
} from '../../../../../core/requests/factory'
import AreaSelect from '../../../../overview/core/components/AreaSelect'
import ListFilter from '../../../../overview/core/components/ListFilter'
import OrganizationSelect from '../../../../overview/core/components/OrganizationSelect'
import PartSelect from '../../../../overview/core/components/PartSelect'
import ProductionLineSelect from '../../../../overview/core/components/ProductionLineSelect'
import SectionSelect from '../../../../overview/core/components/SectionSelect'
import usePlacementsFilter from '../../../../overview/core/hooks/usePlacementsFilter'
import {StepProps} from './_models'
import SelectBoxes from '../../../../../components/form-components/SelectBoxes'
import SelectFieldTable from '../../../../../components/form-components/SelectFieldTable'
import {EntityGroup, Placement, ProductionLine, Section} from '../../../../../core/_models'

interface Props extends StepProps {
  displayByProduct?: boolean
}

const StepScopeType = ({
  data,
  updateData,
  hasError,
  current = false,
  displayByProduct = false,
}: Props) => {
  const {data: productionLine} = useProductionLine(data.entity, {
    options: {
      enabled: data.entity_group === 'production_line' && !!data.entity,
    },
  })
  const {data: section} = useSection(data.entity, {
    enabled: data.entity_group === 'section' && !!data.entity,
  })
  const {data: placement} = usePlacement(data.entity, {
    enabled: data.entity_group === 'placement' && !!data.entity,
  })

  React.useEffect(() => {
    if (data.entity === undefined || !data.nameIsDefault) return

    let entityName
    switch (data.entity_group) {
      case 'production_line':
        entityName = productionLine?.name
        break
      case 'section':
        entityName = section?.name
        break
      case 'placement':
        entityName = placement?.name
        break
      default:
        break
    }
    updateData({...data, name: `${data.type} - ${entityName}`})
  }, [data.entity_group, section, placement, productionLine])

  const {
    filterState: placementsFilterState,
    filterCount: placementsFilterCount,
    placementFilterProps,
  } = usePlacementsFilter()

  const {
    items: productionLines,
    isLoading: productionLinesLoading,
    fetchNextPage: productionLinesFetchNextPage,
  } = useProductionLinesPaginated(
    {},
    {
      enabled: data.entity_group === 'production_line',
    }
  )

  const {
    items: sections,
    isLoading: sectionsLoading,
    fetchNextPage: sectionsFetchNextPage,
  } = useSectionsPaginated(
    {},
    {
      enabled: data.entity_group === 'section',
    }
  )

  const {
    items: placements = [],
    isLoading: placementsLoading,
    fetchNextPage: placementsFetchNextPage,
  } = usePlacementsPaginated(placementFilterProps, {
    enabled: data.entity_group === 'placement',
  })

  const optionData = getOptionData({
    entityGroup: data.entity_group,
    placements: placements,
    sections: sections,
    productionLines: productionLines,
  })

  const loading = productionLinesLoading || sectionsLoading || placementsLoading

  let fetchNextPage, filterComponent
  switch (data.entity_group) {
    case 'placement':
      fetchNextPage = placementsFetchNextPage
      filterComponent = (
        <ListFilter
          count={placementsFilterCount}
          filters={[
            <OrganizationSelect onChange={placementsFilterState.organization.setValue} />,
            <AreaSelect onChange={placementsFilterState.area.setValue} />,
            <ProductionLineSelect onChange={placementsFilterState.productionLine.setValue} />,
            <SectionSelect onChange={placementsFilterState.section.setValue} />,
            <PartSelect onChange={placementsFilterState.part.setValue} />,
          ]}
        />
      )
      break
    case 'production_line':
      fetchNextPage = productionLinesFetchNextPage
      break
    case 'section':
      fetchNextPage = sectionsFetchNextPage
      break
    default:
      break
  }

  return (
    <div className={current ? 'current' : undefined} data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='mb-10'>
          <h2 className='fw-bold d-flex align-items-center text-dark'>Scope Type</h2>
          <div className='text-muted fw-semibold fs-6'>
            Select whether you want to monitor an entire production line or a specific section.
          </div>
        </div>

        {displayByProduct ? (
          <>
            <SwitchField
              name={'By Product'}
              data={data}
              updateData={updateData}
              dataField={'by_product'}
            />
            {data['by_product'] && (
              <NumberField
                name={'Number Of Products'}
                data={data}
                updateData={updateData}
                dataField={'quantity'}
              />
            )}
          </>
        ) : null}

        <SelectBoxes
          required
          optionNames={['Production Line', 'Section', 'Placement']}
          optionDescriptions={[
            'Monitor an entire production line',
            'Focus on a specific section within a production line',
            'Focus on a specific placement of a production line',
          ]}
          optionValues={['production_line', 'section', 'placement']}
          data={data}
          dataField='entity_group'
          updateData={updateData}
          updatesOnChange={{entity: undefined}}
          hasError={hasError}
          colSpace={12}
        />

        {data.entity_group && (
          <LoadingWrapper loading={loading}>
            {filterComponent}
            <SelectFieldTable
              name='Choose Specific Entity'
              required
              columnNames={optionData.columns}
              optionRows={optionData.rows}
              optionValues={optionData.values}
              data={data}
              dataField='entity'
              updateData={updateData}
              hasError={hasError}
              height='400px'
              lastField
              loading={loading}
              gotoNextPage={fetchNextPage}
            />
          </LoadingWrapper>
        )}
      </div>
    </div>
  )
}

const PRODUCTION_LINE_COLUMNS = ['Name', 'Area']
const SECTION_COLUMNS = ['Name', 'Area', 'Production Line']
const PLACEMENT_COLUMNS = ['Name', 'Factory', 'Area', 'Production Line', 'Section']

interface OptionDataProps {
  entityGroup?: EntityGroup
  placements?: Placement[]
  sections?: Section[]
  productionLines?: ProductionLine[]
}

const getOptionData = ({entityGroup, placements, sections, productionLines}: OptionDataProps) => {
  switch (entityGroup) {
    case 'section':
      return {
        columns: SECTION_COLUMNS,
        rows:
          sections?.map((section) => [
            section.name,
            section.area.name,
            section.production_line.name,
          ]) || [],
        values: sections?.map((section) => section._id) || [],
      }
    case 'production_line':
      return {
        columns: PRODUCTION_LINE_COLUMNS,
        rows:
          productionLines?.map((productionLine) => [
            productionLine.name,
            productionLine.area.name,
          ]) || [],
        values: productionLines?.map((productionLine) => productionLine._id) || [],
      }
    case 'placement':
      return {
        columns: PLACEMENT_COLUMNS,
        rows:
          placements?.map((placement) => [
            placement.name,
            placement.factory.name,
            placement.area.name,
            placement.production_line?.name,
            placement.section?.name,
          ]) || [],
        values: placements?.map((placement) => placement._id) || [],
      }
    default:
      return {columns: [], rows: [], values: []}
  }
}

export default StepScopeType
