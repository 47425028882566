type AssetsStateVerifyProps = {
  onCancel: () => void
  onSubmit: () => void
}

const AssetsStateVerify = ({onCancel, onSubmit}: AssetsStateVerifyProps) => {
  return (
    <div className={`d-flex justify-content-between`}>
      <button className={`btn btn-secondary`} onClick={onCancel}>
        Cancel
      </button>
      <button className={`btn btn-primary`} onClick={onSubmit}>
        Verify
      </button>
    </div>
  )
}

export default AssetsStateVerify
