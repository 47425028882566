import {useProductionLinePowerFactor} from '../../../../core/requests/factory'
import {LineData} from '../../core/_chartConfigs/lineConfig'
import {LineGraph} from './LineGraph'

type PowerFactorGraphProps = {
  productionLineId: string
  startTime?: string
  endTime?: string
  height: string
  chartId?: string
}

const PowerFactorGraph = ({
  productionLineId,
  startTime,
  endTime,
  height,
  chartId = `power-factor-${productionLineId}`,
}: PowerFactorGraphProps) => {
  const {data: powerFactor} = useProductionLinePowerFactor({
    productionLine: productionLineId,
    startTime,
    endTime,
    options: {refetchOnWindowFocus: false},
  })

  const lineData: LineData[] | undefined =
    powerFactor !== undefined ? [{dataType: 'Power', unit: '%', data: powerFactor}] : []

  return <LineGraph id={chartId} height={height} data={{[productionLineId]: lineData}} />
}

export {PowerFactorGraph}
