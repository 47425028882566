import {SensorType} from '../../../core/_models'

export type ConditionType = 'health_match' | 'object_match' | 'threshold_crossed'
export const conditionTypes: ConditionType[] = ['health_match', 'object_match', 'threshold_crossed']
export const conditionTypesMap: Record<ConditionType, string> = {
  health_match: 'Health Match',
  object_match: 'Object Match',
  threshold_crossed: 'Threshold Crossed',
}

// Health Match

export const healthStates = [
  'Good',
  'Satisfactory',
  'Unsatisfactory',
  'Unacceptable',
  'Offline',
  'Calibrating',
] as const

export type HealthState = typeof healthStates[number]

export type HealthMatchCondition = {
  type: 'health_match'
  enabled?: boolean
  states?: HealthState[]
}

// Object Match

export type ObjectMatchCondition = {
  type: 'object_match'
  enabled?: boolean
  entities?: {key: string; value: string}[]
}

// Threshold Crossed

export type ThresholdCrossedCondition = {
  type: 'threshold_crossed'
  enabled?: boolean
  minimum?: number
  maximum?: number
  data_types?: SensorType[]
  axes?: ('X' | 'Y' | 'Z')[]
}

export type Condition = HealthMatchCondition | ObjectMatchCondition | ThresholdCrossedCondition

export const validateCondition = (condition: Condition): boolean => {
  switch (condition.type) {
    case 'health_match':
      return condition.states !== undefined && condition.states.length > 0
    case 'object_match':
      return condition.entities !== undefined && condition.entities.length > 0
    case 'threshold_crossed':
      return condition.data_types !== undefined && condition.data_types.length > 0
  }
}

export const validateConditions = (conditions: Condition[]): boolean =>
  conditions.every(validateCondition)

export const variableToString = (variable: string, removeId: boolean = true): string => {
  const baseString = removeId ? variable.replace(/_ID$/, '') : variable
  const words = baseString.split('_')
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  })
  return capitalizedWords.join(' ')
}
