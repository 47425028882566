import {useQueryClient} from 'react-query'
import {useDashboardGrid} from '../../../../../providers/DashboardGridProvider'
import {useState} from 'react'
import {IConditionWidget, defaultCreateConditionWidget} from './_models'
import {WidgetType, getDisplayName} from '../../widgets/_models'
import StepModalWrapper, {Step} from '../../../../../components/StepModalWrapper'
import {createWidget, updateWidget} from '../../../../../core/requests/template'
import {BaseModel, Widget} from '../../../../../core/_models'
import Step1 from './Step1'
import HealthTypeStep from './HealthTypeStep'
import TimeRangeStep from './TimeRangeStep'
import NameStep from './NameStep'

type Props = {
  show: boolean
  hide: () => void
  cancel?: () => void
  type: WidgetType
  widgetData?: Widget & BaseModel
}

const ConditionWidgetModal = ({show, hide, cancel, type, widgetData}: Props) => {
  const queryClient = useQueryClient()
  const {activeDashboardId} = useDashboardGrid()
  const [data, setData] = useState<IConditionWidget>({
    ...defaultCreateConditionWidget,
    ...widgetData,
  })

  const steps: Step<IConditionWidget>[] = [
    {
      title: 'Asset',
      description: 'Select Asset',
      component: Step1,
      validation: (data) => data.entity === undefined,
    },
    {
      title: 'Time Frame Settings',
      description: 'Configure Time Frame Settings',
      component: TimeRangeStep,
      validation: (data) => (!!data.start_time || !!data.end_time) && !!data.dynamic_time_range,
    },
    {
      title: 'Choose Name',
      description: 'Select the name of the widget',
      component: NameStep,
      validation: (data) => data.name === undefined,
    },
  ]

  if (type === 'Health')
    steps.splice(1, 0, {
      title: 'Heath Type',
      description: 'Select Health Type',
      component: HealthTypeStep,
      validation: (data) => data.health_type === undefined,
    })

  const submit = async (data: IConditionWidget) => {
    data.dashboard = activeDashboardId!
    data.type = type
    if (type === 'Health') data.data_types = [data.health_type!]
    const widget = data._id ? await updateWidget(data._id, data) : await createWidget(data)
    await queryClient.invalidateQueries({queryKey: 'widgets'})
    return widget
  }

  return (
    <StepModalWrapper<IConditionWidget, Widget>
      id='condition_widget_modal'
      title={`${data._id ? 'Update' : 'Create'} ${getDisplayName(type)} Widget`}
      show={show}
      close={hide}
      cancel={cancel}
      steps={steps}
      data={data}
      defaultData={defaultCreateConditionWidget}
      setData={setData}
      submitData={submit}
    />
  )
}

export default ConditionWidgetModal
