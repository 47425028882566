import {EntityGroup, Priority, RecurringPeriod, WorkOrderCategory} from '../../../core/_models'

export type ICreateWorkOrderTemplate = {
  title?: string
  expected_duration?: number
  actions?: string[]
  maintenance_plan?: string
  factory_entity?: string
  assignees?: string[]
  recurring_hourly_interval?: number
  recurring_periods?: RecurringPeriod[]
  priority?: Priority
  category?: WorkOrderCategory
  sub_category?: string
  description?: string
  max_future_jobs?: 1 | 2 | 3 | 4 | 5
  // non-api fields
  selected_entity_type?: EntityGroup
  recurrence_pattern?: RecurrencePattern
  recurring_dates?: number[]
  recurring_months?: number[]
  recurring_weekdays?: number[]
  recurring_start_time?: string
}

export type RecurrencePattern = 'operational_hours' | 'calendar_days'

export const defaultCreateWorkOrderTemplate: ICreateWorkOrderTemplate = {
  title: undefined,
  expected_duration: undefined,
  actions: undefined,
  maintenance_plan: undefined,
  factory_entity: undefined,
  assignees: undefined,
  recurring_hourly_interval: undefined,
  recurring_periods: undefined,
  priority: undefined,
  category: undefined,
  sub_category: undefined,
  description: undefined,

  // non-api fields
  selected_entity_type: undefined,
  recurrence_pattern: undefined,
  recurring_dates: undefined,
  recurring_months: undefined,
  recurring_weekdays: undefined,
  recurring_start_time: undefined,
}

export type StepProps = {
  data: ICreateWorkOrderTemplate
  updateData: (fieldsToUpdate: Partial<ICreateWorkOrderTemplate>) => void
  hasError: boolean
}
