import {useParams} from 'react-router-dom'

const useGridSwitch = () => {
  const {'*': splat} = useParams()
  const isGridView = splat?.split('/')[1] === 'grid'

  return {
    isGridView,
  }
}

export default useGridSwitch
