import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import Pagination from '../../../components/pagination/Pagination'
import {deleteArea, useAreas} from '../../../core/requests/factory'
import useGridSwitch from '../../../hooks/UseGridSwitch'
import {useCreateModals} from '../../../modals/CreateModalsProvider'
import {AreaOverviewDetailsModal} from '../../../modals/overview/AreaOverviewDetailsModal'
import {useSelectedRows} from '../../../providers/SelectedRowsProvider'
import {TableBody} from '../core/columns/TableBody'
import {TableSelectionToolbar} from '../core/columns/TableSelectionToolbar'
import {TableToolbar} from '../core/columns/TableToolbar'
import FactorySelect from '../core/components/FactorySelect'
import ListFilter from '../core/components/ListFilter'
import OrganizationSelect from '../core/components/OrganizationSelect'
import {OverviewGridItem} from '../core/components/overview/OverviewGridItem'
import useAreasFilter from '../core/hooks/useAreasFilter'
import {Columns} from './Columns'

export const AreasWrapper = () => {
  const {isGridView} = useGridSwitch()

  const {selected, setDataItems} = useSelectedRows()
  const {openCreateAreaModal} = useCreateModals()
  const {filterState, filterCount, activePage, setActivePage, areasFilterProps} = useAreasFilter()
  const {data: areas, isLoading: areasLoading} = useAreas(areasFilterProps)

  React.useEffect(() => {
    if (areas?.items !== undefined) {
      setDataItems(areas?.items)
    }
  }, [areas?.items])

  const columns = React.useMemo(() => Columns, [])

  const [uuid, setUUID] = React.useState<string>()
  const area = areas?.items.find((a) => a._id === uuid)

  return (
    <KTCard className={`${isGridView ? 'bg-transparent' : undefined}`}>
      <div
        className={`card-header border-0 justify-content-end pt-6 bg-white ${
          isGridView ? 'mb-5 pb-5 rounded' : ''
        }`}
      >
        {/* <ListSearchComponent /> */}
        <div className='card-toolbar'>
          <ListFilter
            count={filterCount}
            filters={[
              <OrganizationSelect onChange={filterState.organization.setValue} />,
              <FactorySelect onChange={filterState.factory.setValue} />,
            ]}
          />

          {selected.length === 0 && (
            <TableToolbar buttonText='Add' onButtonClick={openCreateAreaModal} />
          )}

          {selected.length > 0 && <TableSelectionToolbar onDelete={deleteArea} queryKey='areas' />}
        </div>
      </div>

      {isGridView ? (
        <div className={`row g-6 g-xl-9 mb-4`} id='hs_areas_table'>
          {areas?.items.map((item) => (
            <OverviewGridItem
              item={item}
              title={item.name}
              description={item.organization.name}
              imageUrl={item.gallery?.profile_image.thumbnail_url}
              onClick={setUUID}
            />
          ))}
        </div>
      ) : (
        <TableBody
          tableId='hs_areas_table'
          columns={columns}
          dataList={areas?.items}
          loading={areasLoading}
          onCellClick={(value) => setUUID(value)}
        />
      )}
      {areas?.total ? (
        <Pagination
          page={activePage}
          total={areas.total}
          size={areas.size}
          onClick={(p) => setActivePage(p)}
        />
      ) : null}

      {area && <AreaOverviewDetailsModal entity={area} onHide={() => setUUID(undefined)} />}
    </KTCard>
  )
}
