import {ProductionLine} from '../../../core/_models'

type Props = {
  productionLine: ProductionLine
}

const ScheduleCell = ({productionLine}: Props) => {
  return (
    <a href={`/schedule/${productionLine._id}`}>
      <button type='button' className='btn btn-lg btn-primary'>
        Schedule
      </button>
    </a>
  )
}

export {ScheduleCell}
