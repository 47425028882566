import {useQueryClient} from 'react-query'
import StepModalWrapper, {Step} from '../../../../../components/StepModalWrapper'
import {BaseModel, Widget} from '../../../../../core/_models'
import {ITagWidget, defaultCreateTextWidget} from './_models'
import {useDashboardGrid} from '../../../../../providers/DashboardGridProvider'
import {useState} from 'react'
import {createWidget, updateWidget} from '../../../../../core/requests/template'
import TagSelectStep from './TagSelectStep'
import NameStep from './NameStep'

interface Props {
  show: boolean
  hide: () => void
  cancel?: () => void
  widgetData?: Widget & BaseModel
}

const TagWidgetModal = ({show, hide, cancel, widgetData}: Props) => {
  const queryClient = useQueryClient()
  const {activeDashboardId} = useDashboardGrid()
  const [data, setData] = useState<ITagWidget>({
    ...defaultCreateTextWidget,
    ...widgetData,
  })

  const steps: Step<ITagWidget>[] = [
    {
      title: 'Tag',
      description: 'Select Tag',
      component: TagSelectStep,
      validation: (data) => data.entity === undefined,
    },
    {
      title: 'Name',
      description: 'Select Name',
      component: NameStep,
      validation: (data) => data.name === undefined,
    },
  ]

  const submit = async (data: ITagWidget) => {
    data.dashboard = activeDashboardId!
    const widget = data._id ? await updateWidget(data._id, data) : await createWidget(data)
    await queryClient.invalidateQueries({queryKey: 'widgets'})
    return widget
  }

  return (
    <StepModalWrapper<ITagWidget, Widget>
      id='text_widget_modal'
      title={`${data._id ? 'Update' : 'Create'} Text Widget`}
      show={show}
      close={hide}
      cancel={cancel}
      steps={steps}
      data={data}
      defaultData={defaultCreateTextWidget}
      setData={setData}
      submitData={submit}
    />
  )
}

export default TagWidgetModal
