import SelectFieldBoxes from '../../../../../components/form-components/SelectFieldBoxes'
import SelectFieldTable from '../../../../../components/form-components/SelectFieldTable'
import {useAreas} from '../../../../../core/requests/factory'
import {StepProps} from './_models'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const {data: areas} = useAreas()

  const selectedEntityGroup = data.entity_group

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldTable
          name={`Filter ${selectedEntityGroup}s by Area`}
          columnNames={['Name', 'Factory']}
          optionRows={areas?.items.map((area) => [area.name, area.factory.name]) || []}
          optionValues={areas?.items.map((area) => area._id) || []}
          data={data}
          updateData={updateData}
          dataField='entity'
          height='400px'
          lastField
        />
      </div>
    </div>
  )
}

export default Step1
