import {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import useSetTimeRangeModal from '../../../../app/modals/dashboard/useSetTimeRangeModal'
import {useDashboardGrid} from '../../../../app/providers/DashboardGridProvider'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import AddWidgetModal from '../../../../app/pages/dashboard/dashboard-grid/AddWidget'
import useModalState from '../../../../app/hooks/UseModalState'
import ShareDashboardModal from '../../../../app/modals/share-dashboard/ShareDashboardModal'
import {useDashboard} from '../../../../app/core/requests/template'
import {useProfile} from '../../../../app/core/requests/auth'
import CopyDashboardModal from '../../../../app/modals/copy-dashboard/CopyDashboardModal'

const DashboardToolbar = () => {
  const [exportingPDF, setExportingPDF] = useState(false)
  const {widgetsLocked, toggleWidgetsLocked, activeDashboardId} = useDashboardGrid()
  const widgetModal = useModalState()
  const shareModal = useModalState()
  const copyModal = useModalState()
  const {handler: setTimeRangeHandler} = useSetTimeRangeModal({dashboard: activeDashboardId})
  const {data: dashboard} = useDashboard({dashboard: activeDashboardId})
  const {data: profile} = useProfile()

  const profileIsOwner = dashboard && profile && dashboard.user === profile.email

  const createPDF = async () => {
    setExportingPDF(true)
    let pdf = new jsPDF('portrait', 'px', 'a4')
    pdf.setFillColor(245, 248, 250)
    const element = document.querySelector('#kt_app_content') as HTMLElement
    const pageWidth = pdf.internal.pageSize.getWidth()
    const pageHeight = pdf.internal.pageSize.getHeight() * 4 // 4x for retina
    const pages = Math.ceil(element.offsetHeight / pageHeight)
    for (let i = 0; i < pages; i++) {
      pdf.rect(0, pageHeight * i, pdf.internal.pageSize.getWidth(), pageHeight, 'F')
      const bgColor = '#F5F8FA'
      const canvas = await html2canvas(element, {
        backgroundColor: bgColor,
        y: pageHeight * i,
        width: element.offsetWidth,
        height: pageHeight,
        windowWidth: element.offsetWidth,
        windowHeight: element.offsetHeight,
      })
      const imgData = canvas.toDataURL('image/png')
      if (i > 0) {
        pdf = pdf.addPage()
      }
      pdf.addImage(imgData, 'PNG', pageWidth / 8, 0, pageWidth * 0.75, pageHeight / 4, '', 'FAST')
    }
    pdf.save('grid.pdf')
    setExportingPDF(false)
  }

  return (
    <>
      <div className='d-flex align-items-center gap-5'>
        <a className='btn btn-sm btn-icon btn-color-muted me-n4' onClick={toggleWidgetsLocked}>
          <span className='svg-icon svg-icon-1'>
            {widgetsLocked && <i className='fa-solid fa-lock' />}
            {!widgetsLocked && <i className='fa-solid fa-lock-open' />}
          </span>
        </a>
        {profileIsOwner && (
          <a className='btn btn-sm btn-icon btn-color-muted me-n4' onClick={shareModal.open}>
            <span className='svg-icon svg-icon-1'>
              <i className='fa-solid fa-share-from-square' />
            </span>
          </a>
        )}
        <a className='btn btn-sm btn-icon btn-color-muted' onClick={copyModal.open}>
          <span className='svg-icon svg-icon-1'>
            <i className='fa-solid fa-copy' />
          </span>
        </a>
        {setTimeRangeHandler}
        <a
          className={`btn btn-sm fw-bold btn-secondary ${exportingPDF ? 'disabled' : ''}`}
          onClick={createPDF}
        >
          PDF export
        </a>
        <a className='btn btn-sm fw-bold btn-primary' onClick={widgetModal.open}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Widget
        </a>
      </div>

      <AddWidgetModal show={widgetModal.isOpen} hide={widgetModal.close} open={widgetModal.open} />
      {activeDashboardId && (
        <ShareDashboardModal
          show={shareModal.isOpen}
          hide={shareModal.close}
          dashboardId={activeDashboardId}
        />
      )}
      {activeDashboardId && (
        <CopyDashboardModal
          show={copyModal.isOpen}
          hide={copyModal.close}
          dashboardId={activeDashboardId}
        />
      )}
    </>
  )
}

export {DashboardToolbar}
