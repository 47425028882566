import Select from 'react-select'
import {Part} from '../../../../core/_models'
import {usePartsPaginated} from '../../../../core/requests/factory'

type PartSelectProps = {
  onChange: (part?: Part) => void
}

const PartSelect = ({onChange}: PartSelectProps) => {
  const {
    items: parts,
    isFetching: partsFetching,
    fetchNextPage: partsNextPage,
  } = usePartsPaginated()
  return (
    <Select
      placeholder={`Part`}
      onChange={(e) => onChange(parts?.find((p) => p._id === e?.value))}
      options={
        parts?.map((p) => ({
          value: p._id,
          label: p.type,
        })) || []
      }
      isClearable
      className={'me-3'}
      isLoading={partsFetching}
      onMenuScrollToBottom={() => partsNextPage()}
    />
  )
}

export default PartSelect
