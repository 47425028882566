import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {useQueryClient} from 'react-query'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthTokenModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {Profile} from '../../../core/_models'
import {getProfile} from '../../../core/requests/auth'

type AuthContextProps = {
  authToken: AuthTokenModel | undefined
  saveAuthToken: (auth: AuthTokenModel | undefined) => void
  currentProfile: Profile | undefined
  setCurrentProfile: Dispatch<SetStateAction<Profile | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  authToken: authHelper.getAuthToken(),
  saveAuthToken: () => {},
  currentProfile: undefined,
  setCurrentProfile: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => useContext(AuthContext)

const AuthProvider: FC<WithChildren> = ({children}) => {
  const queryClient = useQueryClient()
  const [authToken, setAuth] = useState<AuthTokenModel | undefined>(authHelper.getAuthToken())
  const [currentProfile, setCurrentProfile] = useState<Profile>()

  const saveAuthToken = (authToken?: AuthTokenModel) => {
    setAuth(authToken)
    if (authToken) {
      authHelper.setAuthToken(authToken)
    } else {
      authHelper.removeAuthToken()
    }
    queryClient.getQueryCache().clear()
  }

  const logout = () => {
    saveAuthToken(undefined)
    setCurrentProfile(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        authToken,
        saveAuthToken,
        currentProfile,
        setCurrentProfile,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {authToken, logout, setCurrentProfile} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestProfile = async () => {
      try {
        if (!didRequest.current) {
          const profile = await getProfile()
          if (profile) {
            setCurrentProfile(profile)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (authToken && authToken.access_token) {
      requestProfile()
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
