import {format} from 'date-fns'
import React from 'react'
import {CopyClipboardText} from '../../components/content/CopyClipboardText'
import EntityInlineTextFieldForm from '../../components/form-components/EntityInlineTextFieldForm'
import {Placement} from '../../core/_models'
import {deletePlacement, useUpdatePlacementMutation} from '../../core/requests/factory'
import {NameImageCell} from '../../pages/overview/core/columns/ImageStringCell'
import useEntityDeleteModal from '../entity-delete/useEntityDeleteModal'
import OverviewDetailsModal, {BaseOverviewDetailsModalProps} from './OverviewDetailsModal'

const PlacementOverviewDetailsModal = ({
  entity,
  onHide,
  show = true,
}: BaseOverviewDetailsModalProps<Placement>) => {
  const deleteModal = useEntityDeleteModal({
    entity,
    deleteHandler: deletePlacement,
    queryToInvalidate: 'placements',
  })
  const sensorLogs = entity.sensor_logs.map((log) => (
    <div key={log.attached_at} className={`timeline-item`}>
      <div className='timeline-line'></div>
      <div className='timeline-icon'>
        <i className='far fa-lightbulb text-secondary'></i>
      </div>
      <div className={`timeline-content mb-10 mt-n1`}>
        <div className={'pe-3 mb-5'}>
          <div className='fs-5 fw-semibold mb-2'>{log.sensor.device_id}</div>
          <div className={`d-flex align-items-center mt-1 fs-6`}>
            <div className={`text-muted me-2 fs-7`}>
              <div>{log.sensor.uuid}</div>
            </div>
          </div>
        </div>
        <div className='overflow-auto pb-5'>
          <div
            className={`d-flex align-items-center border border-dashed border-gray-300 rounded min-w-175px px-7 py-3 mb-5`}
          >
            <div
              className={`fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px`}
            >
              Attached At
            </div>
            <div className={`min-w-175px pe-2`}>{format(new Date(log.attached_at), 'PPpp')}</div>
          </div>
          {log.detached_at && (
            <div
              className={`d-flex align-items-center border border-dashed border-gray-300 rounded min-w-175px px-7 py-3 mb-5`}
            >
              <div
                className={`fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px`}
              >
                Detached At
              </div>
              <div className={`min-w-175px pe-2`}>{format(new Date(log.detached_at), 'PPpp')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  ))

  return (
    <OverviewDetailsModal
      show={show}
      onHide={onHide}
      title={
        <>
          <span className={`text-nowrap`}>Placement Details</span> ・
          <EntityInlineTextFieldForm
            data={entity}
            dataField={'name'}
            useMutation={useUpdatePlacementMutation}
            queryToInvalidate={'placements'}
          />
          {deleteModal.handler}
        </>
      }
      objectId={entity._id}
      attributes={[
        ['ID', <CopyClipboardText text={entity._id} />],
        [
          'Gallery',
          <NameImageCell
            index={1}
            str={''}
            images={
              entity.gallery
                ? [entity.gallery.profile_image, ...(entity.gallery.images ?? [])]
                : undefined
            }
            entityId={entity._id}
            queryToInvalidate='placements'
          />,
        ],
        ['Organization Name', entity.organization.name],
        ['Factory Name', entity.factory.name],
        ['Area Name', entity.area.name],
        ['Production Line Name', entity.production_line?.name || '-'],
        ['Section Name', entity.section?.name || '-'],
        ['Asset Name', entity.asset?.name || '-'],
        [
          'Part manufacturer and model number',
          `${entity.part?.manufacturer || '-'} ${entity.part?.model_number || '-'}`,
        ],
        ['Name', entity.name],
        [undefined, <div className={`timeline timeline-border-dashed`}>{sensorLogs}</div>],
      ]}
    />
  )
}

export {PlacementOverviewDetailsModal}
