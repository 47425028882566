import {useEffect, useRef, useState} from 'react'
import Select, {MultiValue} from 'react-select'
import {useInvitationsPaginated} from '../../../core/requests/auth'

type Props = {
  initial?: string[]
  name?: string
  onchange: (values: string[]) => void
  errorOccurred?: boolean
  className?: string
}

const InvitationsSelectField = ({
  initial = [],
  name = 'Invitations',
  onchange,
  errorOccurred,
  className,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([])
  const initialOptionsSet = useRef(false)

  const {items: invitations, isFetching, gotoNextPage} = useInvitationsPaginated()

  useEffect(() => {
    if (!invitations || !initial.length || initialOptionsSet.current) return
    const initialOptions = initial.map((id) => {
      const invitation = invitations.find((invitation) => invitation._id === id)
      return {
        value: id,
        label: invitation ? `${invitation.user} | ${invitation.role.name}` : 'Unknown invitation',
      }
    })
    setSelectedOptions(initialOptions)
    initialOptionsSet.current = true
  }, [invitations, initial])

  const handleChange = (selected: MultiValue<OptionType>) => {
    const values = selected.map((option) => option.value)
    onchange(values)
    setSelectedOptions(selected)
  }

  const error = errorOccurred && !selectedOptions.length

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={selectedOptions}
        options={
          invitations?.map((invitation) => ({
            label: `${invitation.user} | ${invitation.role.name}`,
            value: invitation._id,
          })) || []
        }
        onChange={handleChange}
        isLoading={isFetching}
        isDisabled={isFetching}
        isMulti
        isClearable
        onMenuScrollToBottom={() => gotoNextPage()}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? 'red' : baseStyles.borderColor,
          }),
        }}
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}
    </>
  )
}

type OptionType = {
  value: string
  label: string
}

export default InvitationsSelectField
