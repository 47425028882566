import {useMachineOee} from '../../../../core/requests/oee'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {GaugeGraph} from '../../components/charts/GaugeGraph'
import {WidgetProps} from './Widget'

const OeeAvailabilityWidget = ({widget, height}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const {data: oee, isLoading} = useMachineOee({
    id: widget.entity,
    startTime,
    endTime,
    customCategories: widget.operational_event_categories,
    options: {refetchOnWindowFocus: false},
  })

  return (
    <GaugeGraph
      id={widget._id}
      height={height}
      value={oee ? oee.availability * 100 : undefined}
      loading={isLoading}
    />
  )
}

export default OeeAvailabilityWidget
