import LoadingWrapper from '../../components/LoadingWrapper'
import NumberField from '../../components/form-components/NumberField'
import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'
import SelectFieldBoxesMultiple from '../../components/form-components/SelectFieldBoxesMultiple'
import {useSensors} from '../../core/requests/factory'
import {StepProps} from './_models'

const Step2 = ({data, updateData, hasError}: StepProps) => {
  const {data: sensors} = useSensors({placement: data.placement?._id})

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <LoadingWrapper loading={sensors?.items === undefined}>
          <>
            <SelectFieldBoxesMultiple
              name='Data Types'
              optionNames={sensors?.items.at(0)?.data_types ?? []}
              optionValues={sensors?.items.at(0)?.data_types ?? []}
              data={data}
              updateData={updateData}
              dataField='data_types'
              required
              hasError={hasError}
            />

            {data.data_types?.includes('vibration') && (
              <SelectFieldBoxes
                name='Axis'
                optionNames={['X', 'Y', 'Z']}
                optionValues={['X', 'Y', 'Z']}
                data={data}
                updateData={updateData}
                dataField='axis'
                required
                hasError={hasError}
              />
            )}

            {data.topic === 'threshold-crossed' && (
              <>
                <div className='row'>
                  <div className='col-6'>
                    <NumberField
                      name='Minimum'
                      data={data}
                      updateData={updateData}
                      dataField={'minimum'}
                      lastField
                    />
                  </div>
                  <div className='col-6'>
                    <NumberField
                      name='Maximum'
                      data={data}
                      updateData={updateData}
                      dataField={'maximum'}
                      lastField
                    />
                  </div>
                </div>
                {hasError && data.minimum === undefined && data.maximum === undefined && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>'Minimum' or 'Maximum' is required</div>
                  </div>
                )}
              </>
            )}
          </>
        </LoadingWrapper>
      </div>
    </div>
  )
}

export default Step2
