import {ManufacturingProcess} from '../../core/_models'

export type ICreateProductionLine = {
  area?: string
  name?: string
  auto_start: boolean
  auto_stop: boolean
  manufacturing_type?: ManufacturingProcess
  auto_stop_detection_tolerance?: number
}

export const defaultCreateProductionLine: ICreateProductionLine = {
  area: undefined,
  name: undefined,
  auto_start: true,
  auto_stop: true,
  manufacturing_type: 'continuous',
  auto_stop_detection_tolerance: 5,
}

export type StepProps = {
  data: ICreateProductionLine
  updateData: (fieldsToUpdate: Partial<ICreateProductionLine>) => void
  hasError: boolean
}
