import {Html5QrcodeScanner, Html5QrcodeScanType} from 'html5-qrcode'
import {Html5QrcodeResult} from 'html5-qrcode/src/core'
import React from 'react'

type QRReaderProps = {
  onSuccess: (decodedText: string, decodedResult: Html5QrcodeResult) => void
  onFailure?: (error: string) => void
}

const QRReader = ({onSuccess, onFailure = (e) => {}}: QRReaderProps) => {
  const containerId = React.useId()

  const renderHtml5QrcodeScanner = () => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      `${containerId}-qr-reader`,
      {
        fps: 10,
        qrbox: {width: 250, height: 250},
        rememberLastUsedCamera: false,
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        showZoomSliderIfSupported: true,
        defaultZoomValueIfSupported: 2,
      },
      false
    )
    html5QrcodeScanner.render((decodedText, result) => {
      onSuccess(decodedText, result)
      html5QrcodeScanner.pause(true)
      window.setTimeout(() => {
        ;(async () => {
          try {
            await html5QrcodeScanner.clear()
            renderHtml5QrcodeScanner()
          } catch (e) {
            console.log(e)
          }
        })()
      }, 2000)
    }, onFailure)
    return html5QrcodeScanner
  }

  React.useEffect(() => {
    let html5QrcodeScanner = renderHtml5QrcodeScanner()
    return () => {
      ;(async () => {
        try {
          await html5QrcodeScanner.clear()
        } catch (e) {
          console.log(e)
        }
      })()
    }
  }, [])

  return <div className={`w-100 qr-reader`} id={`${containerId}-qr-reader`} />
}

export default QRReader
