interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  isInteger?: boolean
}

const NumberField = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
  isInteger = false,
}: Props<DataType>) => {
  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>

        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>
      <input
        type='number'
        className='form-control form-control-lg form-control-solid'
        name={dataField}
        value={data[dataField]}
        onChange={(e) => {
          if (e.target.value === '')
            return updateData({[dataField]: undefined} as Partial<DataType>)
          const parsedValue = isInteger ? parseInt(e.target.value) : parseFloat(e.target.value)
          if (!isNaN(parsedValue))
            return updateData({[dataField]: parsedValue} as Partial<DataType>)
        }}
      />
      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export default NumberField
