import {useQuery} from 'react-query'
import {graphql} from '../../../../../gql'
import {
  GetPlacementTotalConsumptionQuery,
  GetProductionLineTotalConsumptionQuery,
  GetSectionTotalConsumptionQuery,
  Timespan,
} from '../../../../../gql/graphql'
import {EntityGroup} from '../../../_models'
import {TotalConsumption} from '../entities'
import useEnergyClient from '../UseEnergyClient'

const getPlacementTotalConsumptionWithVariablesQueryDocument = graphql(`
  query GetPlacementTotalConsumption(
    $id: ID!
    $startTime: DateTime
    $endTime: DateTime
    $timespan: Timespan
    $multiplier: Int!
  ) {
    getPlacementTotalConsumption(
      id: $id
      startTime: $startTime
      endTime: $endTime
      timespan: $timespan
      multiplier: $multiplier
    ) {
      costs
      currency
      emissions
      energy
      power {
        time
        value
      }
    }
  }
`)

const getProductionLineTotalConsumptionWithVariablesQueryDocument = graphql(`
  query GetProductionLineTotalConsumption(
    $id: ID!
    $startTime: DateTime
    $endTime: DateTime
    $timespan: Timespan
    $multiplier: Int!
  ) {
    getProductionLineTotalConsumption(
      id: $id
      startTime: $startTime
      endTime: $endTime
      timespan: $timespan
      multiplier: $multiplier
    ) {
      costs
      currency
      emissions
      energy
      power {
        time
        value
      }
    }
  }
`)

const getSectionTotalConsumptionWithVariablesQueryDocument = graphql(`
  query GetSectionTotalConsumption(
    $id: ID!
    $startTime: DateTime
    $endTime: DateTime
    $timespan: Timespan
    $multiplier: Int!
  ) {
    getSectionTotalConsumption(
      id: $id
      startTime: $startTime
      endTime: $endTime
      timespan: $timespan
      multiplier: $multiplier
    ) {
      costs
      currency
      emissions
      energy
      power {
        time
        value
      }
    }
  }
`)

type TotalConsumptionHistory =
  | typeof getPlacementTotalConsumptionWithVariablesQueryDocument
  | typeof getProductionLineTotalConsumptionWithVariablesQueryDocument
  | typeof getSectionTotalConsumptionWithVariablesQueryDocument

type TotalConsumptionQuery =
  | GetPlacementTotalConsumptionQuery
  | GetProductionLineTotalConsumptionQuery
  | GetSectionTotalConsumptionQuery

interface Props {
  entityGroup: EntityGroup
  id?: string
  startTime: string
  endTime?: string
  multiplier?: number
  timespan?: Timespan
}

const useGetTotalConsumption = (props: Props) => {
  const {entityGroup, id, startTime, endTime, multiplier = 1, timespan} = props
  const client = useEnergyClient()
  let getTotalConsumptionWithVariablesQueryDocument: TotalConsumptionHistory
  let queryKey: string
  switch (entityGroup) {
    case 'placement':
      queryKey = 'GetPlacementTotalConsumption'
      getTotalConsumptionWithVariablesQueryDocument =
        getPlacementTotalConsumptionWithVariablesQueryDocument
      break
    case 'production_line':
      queryKey = 'GetProductionLineTotalConsumption'
      getTotalConsumptionWithVariablesQueryDocument =
        getProductionLineTotalConsumptionWithVariablesQueryDocument
      break
    case 'section':
      queryKey = 'GetSectionTotalConsumption'
      getTotalConsumptionWithVariablesQueryDocument =
        getSectionTotalConsumptionWithVariablesQueryDocument
      break
    default:
      throw new Error(`Wrong entityGroup: ${entityGroup}`)
  }
  const query = useQuery({
    queryKey: [`energy.${queryKey}`, ...Object.values(props)],
    queryFn: async () =>
      await client.request<TotalConsumptionQuery>(getTotalConsumptionWithVariablesQueryDocument, {
        id,
        startTime,
        endTime,
        multiplier,
        timespan,
      }),
    enabled: !!id,
  })

  let consumption: TotalConsumption | undefined
  if (query?.data) {
    if ('getPlacementTotalConsumption' in query.data) {
      consumption = query.data.getPlacementTotalConsumption
    }
    if ('getProductionLineTotalConsumption' in query.data) {
      consumption = query.data.getProductionLineTotalConsumption
    }
    if ('getSectionTotalConsumption' in query.data) {
      consumption = query.data.getSectionTotalConsumption
    }
  }

  return {
    ...query,
    consumption,
  }
}

export default useGetTotalConsumption
