import LoadingWrapper from '../../../../../components/LoadingWrapper'

type QuantityWidgetProps = {
  value?: string | number
  unit?: string
}

const QuantityWidget = ({value, unit}: QuantityWidgetProps) => (
  <div className='d-flex align-items-center justify-content-center h-100'>
    <LoadingWrapper loading={value === undefined}>
      <div className='d-flex flex-column justify-items-center'>
        <span className='fs-1 fw-bold text-center text-dark'>{value}</span>
        {unit && <span className='text-gray-400 fw-semibold fs-2 text-center'>{unit}</span>}
      </div>
    </LoadingWrapper>
  </div>
)

export default QuantityWidget
