import {StepProps} from './_models'
import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'
import NumberField from '../../components/form-components/NumberField'
import {useIntl} from 'react-intl'

const Step2 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* Type */}
        <SelectFieldBoxes
          name='Manufacturing Type'
          optionNames={['Continuous', 'Discrete']}
          optionValues={['continuous', 'discrete']}
          data={data}
          updateData={updateData}
          dataField='manufacturing_type'
          colSpace={6}
          required
        />
      </div>
    </div>
  )
}

export default Step2
