import {createPortal} from 'react-dom'
import {Sensor} from '../../core/_models'
import {Modal} from 'react-bootstrap'
import {useCreateModals} from '../CreateModalsProvider'

const modalsRoot = document.getElementById('root-modals') || document.body

const EntityDetailModal = () => {
  const {showEntityDetailModal, detailEntity, closeEntityDetailModal} = useCreateModals()

  return createPortal(
    <Modal
      id='hs_view_sensor_modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='lg'
      show={showEntityDetailModal}
      onHide={closeEntityDetailModal}
    >
      <div className='modal-header'>
        <div></div>
        <button type='button' className='btn btn-icon btn-color-gray-400 btn-active-color-primary'>
          <i className={`fa-solid fs-2 fa-pen`}></i>
        </button>
      </div>
      <div className='modal-body m-5'>
        {detailEntity.map((field) => (
          <div className='row mb-7'>
            <label className='col fw-semibold text-muted'>{field.name}</label>
            <div className='col-md-auto'>
              <span className='fw-bold fs-6 text-gray-800'>{field.value}</span>
            </div>
          </div>
        ))}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default EntityDetailModal
