import {useQueryClient} from 'react-query'
import {WorkOrderTemplate} from '../../core/_models'
import {useState} from 'react'
import {useFactoryEntities} from '../../core/requests/factory'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {entityToString} from '../../core/name-util'
import {convertSecondsToHoursMinutesSeconds, rfc3339ToString} from '../../core/time-util'
import {AddWorkOrderTemplateModal} from './templateModal/AddWorkOrderTemplateModal'
import {ConfirmDeletionModal} from '../overview/core/columns/TableSelectionToolbar'
import {
  deleteWorkOrderTemplate,
  useWorkOrderTemplatesNextOccurrences,
} from '../../core/requests/maintenance'

type Props = {
  show: boolean
  hide: () => void
  workOrderTemplate: WorkOrderTemplate
}

const ViewWorkOrderTemplateModal = ({show, hide, workOrderTemplate}: Props) => {
  const queryClient = useQueryClient()
  const [showEditingModal, setShowEditingModal] = useState(false)
  const [showDeletingModal, setShowDeletingModal] = useState(false)

  const {data: factoryEntities} = useFactoryEntities({
    entityIds: [workOrderTemplate.factory_entity!],
    options: {enabled: !!workOrderTemplate.factory_entity},
  })

  const nextOccurrence =
    useWorkOrderTemplatesNextOccurrences([workOrderTemplate._id]).map(
      (nextOccurrence) => nextOccurrence.data
    )[0] || undefined

  const onDelete = async () => {
    await deleteWorkOrderTemplate(workOrderTemplate._id)
    await queryClient.invalidateQueries({
      queryKey: ['workOrderTemplates'],
    })
    hide()
  }

  return (
    <>
      <Modal
        id='hs_view_work_order_template_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={show}
        onHide={hide}
        container={document.getElementById('root-modals')}
      >
        {/* Header */}
        <div className='modal-header border-0 justify-content-end'>
          <div
            className='btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-primary me-2'
            title='Edit Work Order'
            onClick={() => setShowEditingModal(true)}
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
          </div>

          <div
            className='btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger me-2'
            title='Delete Work Order'
            onClick={() => setShowDeletingModal(true)}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2' />
          </div>
          <div
            className='btn btn-icon btn-sm btn-color-gray-500 btn-active-icon-primary'
            title='Hide Work Order'
            onClick={hide}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
          </div>
        </div>

        {/* Body */}
        <div className='modal-body pt-0 pb-20 px-lg-17'>
          <div className='row'>
            {/* Work Order Info */}
            <div className={`col${!workOrderTemplate.actions ? '' : '-6 border-end'}`}>
              <div className='d-flex'>
                {workOrderTemplate.priority && (
                  <i
                    className='fa-solid fa-flag fs-2 me-5 pt-1'
                    style={{
                      color:
                        workOrderTemplate.priority === 'LOW'
                          ? '#D8D8D8'
                          : workOrderTemplate.priority === 'MEDIUM'
                          ? '#6FDDFF'
                          : workOrderTemplate.priority === 'HIGH'
                          ? '#FFCC00'
                          : '#F50000',
                    }}
                  />
                )}

                <div className='mb-9'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='fs-3 fw-bold me-3'>{workOrderTemplate.title}</span>
                  </div>
                  <div className='fs-6'>{workOrderTemplate.description || ''}</div>
                </div>
              </div>

              {/* Duration */}
              <div className='d-flex align-items-center mb-2'>
                <KTSVG path='/media/icons/duotune/general/gen013.svg' className='svg-icon-1 me-5' />
                <div className='fs-6'>
                  <span className='fw-bold me-2'>Expected Duration</span>
                  <span>
                    {convertSecondsToHoursMinutesSeconds(workOrderTemplate.expected_duration * 60)}
                  </span>
                </div>
              </div>

              {/* Next Occurrence */}
              {nextOccurrence && (
                <div className='d-flex align-items-center mb-2'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen014.svg'
                    className='svg-icon-1 me-5'
                  />
                  <div className='fs-6'>
                    <span className='fw-bold me-2'>Next Occurrence</span>
                    <span>{rfc3339ToString(nextOccurrence.time)}</span>
                  </div>
                </div>
              )}

              {/* Location */}
              {workOrderTemplate.factory_entity && (
                <div className='d-flex align-items-center'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen018.svg'
                    className='svg-icon-1 svg-icon-muted me-5'
                  />
                  <div className='fs-6'>
                    <span className='fw-bold me-4'>At</span>
                    {factoryEntities?.items && factoryEntities.items[0] && (
                      <span>{entityToString(factoryEntities.items[0])}</span>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Actions */}
            {workOrderTemplate.actions && (
              <div className='col-6'>
                <div className='ms-5'>
                  <div className='fs-6 fw-bold'>Actions</div>
                  <ul className=''></ul>
                  {workOrderTemplate.actions.map((action, index) => (
                    <li className='d-flex align-items-center' key={index}>
                      <div className='fs-6'>
                        <span>{action}</span>
                      </div>
                    </li>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <AddWorkOrderTemplateModal
        show={showEditingModal}
        hide={() => setShowEditingModal(false)}
        maintenancePlanId={workOrderTemplate.maintenance_plan!}
        edit={workOrderTemplate}
      />

      <ConfirmDeletionModal
        show={showDeletingModal}
        hide={() => setShowDeletingModal(false)}
        deleteSelections={onDelete}
      />
    </>
  )
}

export default ViewWorkOrderTemplateModal
