import {useState} from 'react'

export type UsePaginationProps = {
  initialPage?: number
  size?: number
}

const usePagination = (props: UsePaginationProps = {}) => {
  const {initialPage = 1, size = 15} = props
  const [activePage, setActivePage] = useState(initialPage)
  const [pageSize, setPageSize] = useState(size)
  return {
    activePage,
    setActivePage,
    pageSize,
    setPageSize,
  }
}

export default usePagination
