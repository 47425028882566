import React from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import LoadingWrapper from '../../components/LoadingWrapper'
import {updateWidget, useWidgets} from '../../core/requests/template'
import useModalState from '../../hooks/UseModalState'
import SetTimeRangeForm, {WidgetTimeRange} from './SetTimeRangeForm'
import SetTimeRangeModal from './SetTimeRangeModal'

interface Props {
  dashboard?: string
}

const useSetTimeRangeModal = ({dashboard}: Props) => {
  const [dashboardId, setDashboardId] = React.useState(dashboard)
  const {data: widgets} = useWidgets({
    dashboards: dashboardId ? [dashboardId] : undefined,
    options: {
      enabled: !!dashboardId,
    },
  })
  const modalState = useModalState()
  const cache = useQueryClient()

  React.useEffect(() => {
    setDashboardId(dashboard)
  }, [dashboard])

  const mutation = useMutation({
    mutationFn: async (data: WidgetTimeRange) => {
      if (widgets?.length) {
        await Promise.all(widgets.map(async (widget) => await updateWidget(widget._id, data)))
      }
    },
    onSuccess: async () => {
      toast.success(`Dashboard was updated`)
      if (widgets?.length) {
        await Promise.all(
          widgets.map(async (widget) => await cache.invalidateQueries(['widget', widget._id]))
        )
        await cache.invalidateQueries('widgets')
      }
      modalState.close()
    },
    onError: (error) => {
      toast.error(`Can't update dashboard`)
      console.warn(error)
    },
  })

  const form = <SetTimeRangeForm onSubmit={mutation.mutateAsync} onCancel={modalState.close} />

  const modal = (
    <SetTimeRangeModal
      show={modalState.isOpen}
      setShow={(state) => {
        state ? modalState.open() : modalState.close()
      }}
      form={<LoadingWrapper loading={mutation.isLoading}>{form}</LoadingWrapper>}
    />
  )

  const handler = (
    <>
      <button className='btn btn-primary btn-sm' onClick={modalState.open}>
        Set Time Range
      </button>
      {modal}
    </>
  )

  return {
    handler: dashboardId ? handler : null,
  }
}

export default useSetTimeRangeModal
