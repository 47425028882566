import {useEffect, useRef, useState} from 'react'
import Select, {MultiValue} from 'react-select'

type Props = {
  initial?: ('X' | 'Y' | 'Z')[]
  name?: string
  onchange: (value: ('X' | 'Y' | 'Z')[]) => void
  errorOccurred?: boolean
  className?: string
}

const AxesSelectField = ({
  initial = [],
  name = 'Axes',
  onchange,
  errorOccurred,
  className,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([])
  const initialOptionsSet = useRef(false)

  const handleChange = (selected: MultiValue<OptionType>) => {
    onchange(selected.map((option) => option.value))
    setSelectedOptions(selected)
  }

  useEffect(() => {
    if (!initial.length || initialOptionsSet.current) return
    setSelectedOptions(options.filter((option) => initial.includes(option.value)))
    initialOptionsSet.current = true
  }, [initial])

  const error = errorOccurred && !selectedOptions.length

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={selectedOptions}
        options={options}
        onChange={handleChange}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? 'red' : baseStyles.borderColor,
          }),
        }}
        isMulti
        isClearable
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}
    </>
  )
}

type OptionType = {
  value: 'X' | 'Y' | 'Z'
  label: string
}

const options: OptionType[] = [
  {value: 'X', label: 'X'},
  {value: 'Y', label: 'Y'},
  {value: 'Z', label: 'Z'},
]

export default AxesSelectField
