import {useLookback} from '../../_metronic/partials/layout/lookback/LookbackProvider'
import {useProductionLine} from '../core/requests/factory'
import {useBatch, useBatches, useMachineProductionStats} from '../core/requests/oee'
import {lookbackValueToTime} from '../core/time-util'
import LoadingWrapper from './LoadingWrapper'

type Props = {
  productionLineId: string
  batchId?: string
}

const ProductionMetricsBox = ({productionLineId, batchId}: Props) => {
  const {values} = useLookback()
  const viewingSpecificBatch = batchId !== undefined

  const {data: productionLine} = useProductionLine(productionLineId)

  const {data: activeBatch} = useBatch({batch: batchId})
  const {data: batches} = useBatches({
    productionLine: productionLineId,
    status: ['running'],
    options: {enabled: !viewingSpecificBatch && productionLine?.manufacturing_type === 'discrete'},
  })

  const lineIsContinuous =
    productionLine === undefined ? undefined : productionLine.manufacturing_type === 'continuous'

  const batch = viewingSpecificBatch ? activeBatch : batches?.items.at(0)

  const startTime =
    lineIsContinuous === undefined
      ? undefined
      : !lineIsContinuous
      ? batch?.start_time
      : lookbackValueToTime(values.productionLine)
  const endTime = viewingSpecificBatch ? batch?.end_time : undefined

  const {data: stats} = useMachineProductionStats({
    id: productionLine?._id as string,
    startTime,
    endTime,
    options: {
      enabled: !!productionLine?._id,
    },
  })

  const productProcessed = stats?.product_processed.at(-1)
  const productProcessedAmount = productProcessed?.at(1) || 0
  const avgProductRate = stats?.avg_product_rate
  const avgCycleTime = stats?.cycle_time
  const scrap = stats?.scrap.at(-1)
  const scrapValue = scrap?.at(1)
  const avgScrapRate = stats?.avg_scrap_rate

  return (
    <div className='d-flex flex-column justify-content-between'>
      <LoadingWrapper loading={stats === undefined}>
        <div>
          <div className='d-flex align-items-center mb-2'>
            <a className='text-gray-800 fs-7 fw-bold me-1'>Parts Produced:</a>
            <div className='d-flex flex-column mw-200px'>
              {batch ? (
                <>
                  <div className='d-flex align-items-center mb-0'>
                    <span className='fs-8'>
                      {stats ? productProcessedAmount : '-'} pcs/
                      {batch.total_product_quantity} pcs
                    </span>
                  </div>
                  <div className='progress h-6px w-150px'>
                    <div
                      className='progress-bar bg-primary'
                      role='progressbar'
                      style={{
                        width: `${
                          stats ? (productProcessedAmount / batch.total_product_quantity) * 100 : 0
                        }%`,
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className='d-flex align-items-center mb-0'>
                  <span className='fs-8'>
                    {productProcessedAmount ? productProcessedAmount : '-'} pcs
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='d-flex align-items-center mb-1'>
            <a className='text-gray-800 fs-7 fw-bold me-1'>Average Production Rate:</a>
            <span className='badge py-2 px-2 fs-7 badge-light-primary'>
              {avgProductRate || '-'} pcs/min
            </span>
          </div>
          <div className='d-flex align-items-center'>
            <a className='text-gray-800 fs-7 fw-bold me-1'>Average Cycle Time:</a>
            <span className='badge py-2 px-2 fs-7 badge-light-primary'>
              {avgCycleTime || '-'} seconds
            </span>
          </div>
        </div>
        <div>
          <div className='d-flex align-items-center mb-1'>
            <a className='text-gray-800 fs-7 fw-bold me-1'>Scrap:</a>
            <span className='badge py-2 px-2 fs-7 badge-light-primary'>
              {scrapValue?.toFixed(2) || '-'} pcs
            </span>
          </div>
          <div className='d-flex align-items-center'>
            <a className='text-gray-800 fs-7 fw-bold me-1'>Scrap Rate:</a>
            <span className='badge py-2 px-2 fs-7 badge-light-primary'>
              {avgScrapRate?.toFixed(2) || '-'}%
            </span>
          </div>
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default ProductionMetricsBox
