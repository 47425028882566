import clsx from 'clsx'
import {Row} from 'react-table'
import {User} from '../../../core/_models'
import {InvitationProfile} from '../OrganizationPage'

type Props = {
  row: Row<User | InvitationProfile>
}

const InvitationProfileTableRow: React.FC<Props> = ({row}) => {
  const rowProps = row.getRowProps()

  if (!row.original.role?.name) {
    rowProps.className = `alert alert-primary border border-primary border-dashed`
  }
  return (
    <tr {...rowProps}>
      {row.cells.map((cell) => (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'}, 'border-0')}
        >
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  )
}

export {InvitationProfileTableRow}
