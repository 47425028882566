import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import Pagination from '../../../components/pagination/Pagination'
import {deleteGateway, useGateways} from '../../../core/requests/factory'
import useGridSwitch from '../../../hooks/UseGridSwitch'
import {useCreateModals} from '../../../modals/CreateModalsProvider'
import {GatewayOverviewDetailsModal} from '../../../modals/overview/GatewayOverviewDetailsModal'
import {useSelectedRows} from '../../../providers/SelectedRowsProvider'
import {TableBody} from '../core/columns/TableBody'
import {TableSelectionToolbar} from '../core/columns/TableSelectionToolbar'
import {TableToolbar} from '../core/columns/TableToolbar'
import AreaSelect from '../core/components/AreaSelect'
import DeviceStateSelect from '../core/components/DeviceStateSelect'
import ListFilter from '../core/components/ListFilter'
import OrganizationSelect from '../core/components/OrganizationSelect'
import {OverviewGridItem} from '../core/components/overview/OverviewGridItem'
import useGatewaysFilter from '../core/hooks/useGatewaysFilter'
import {ListSearchComponent} from '../core/TableSearchComponent'
import {Columns} from './Columns'

export const GatewaysWrapper = () => {
  const {isGridView} = useGridSwitch()
  const {selected, setDataItems} = useSelectedRows()
  const {openCreateGatewayModal} = useCreateModals()
  const {filterState, filterCount, activePage, setActivePage, gatewayFilterProps} =
    useGatewaysFilter()
  const {data: gateways, isLoading: gatewaysLoading} = useGateways(gatewayFilterProps)

  React.useEffect(() => {
    if (gateways?.items !== undefined) {
      setDataItems(gateways?.items)
    }
  }, [gateways?.items])

  const columns = React.useMemo(() => Columns, [])

  const [uuid, setUUID] = React.useState<string>()
  const gateway = gateways?.items.find((g) => g._id === uuid)

  return (
    <KTCard className={`${isGridView ? 'bg-transparent' : undefined}`}>
      {/* Header */}
      <div
        className={`card-header border-0 justify-content-end pt-6 bg-white ${
          isGridView ? 'mb-5 pb-5 rounded' : ''
        }`}
      >
        {/* <ListSearchComponent /> */}
        <div className='card-toolbar'>
          <ListFilter
            count={filterCount}
            filters={[
              <OrganizationSelect onChange={filterState.organization.setValue} />,
              <DeviceStateSelect onChange={filterState.state.setValue} />,
              <AreaSelect onChange={filterState.area.setValue} />,
            ]}
          />

          {/* Standard toolbar */}
          {selected.length === 0 && (
            <TableToolbar buttonText='Add' onButtonClick={openCreateGatewayModal} />
          )}

          {/* Selection toolbar */}
          {selected.length > 0 && (
            <TableSelectionToolbar onDelete={deleteGateway} queryKey='gateways' />
          )}
        </div>
      </div>

      {isGridView ? (
        <div className={`row g-6 g-xl-9 mb-4`} id='hs_gateways_table'>
          {gateways?.items.map((item) => (
            <OverviewGridItem
              item={item}
              title={item.device_id}
              description={item.area.name}
              actions={
                <div
                  className={`badge py-3 px-4 fs-7 badge-light-${
                    item.state === 'Online' ? 'success' : 'danger'
                  }`}
                >
                  {item.state}
                </div>
              }
              imageUrl={item.gallery?.profile_image.thumbnail_url}
              onClick={setUUID}
            />
          ))}
        </div>
      ) : (
        <TableBody
          tableId='hs_gateways_table'
          columns={columns}
          dataList={gateways?.items}
          loading={gatewaysLoading}
          onCellClick={(value) => setUUID(value)}
        />
      )}
      {gateways?.total ? (
        <Pagination
          page={activePage}
          total={gateways.total}
          size={gateways.size}
          onClick={(p) => setActivePage(p)}
        />
      ) : null}
      {gateway && (
        <GatewayOverviewDetailsModal entity={gateway} onHide={() => setUUID(undefined)} />
      )}
    </KTCard>
  )
}
