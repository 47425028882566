import {CmmsRelation, GeoCoordinates} from '../../core/_models'

export const COUNTRIES = ['DK', 'DE', 'FR', 'FI', 'US', 'UK', 'UA', 'SE', 'ES', 'NO'] as const
export const REGIONS = ['DK1', 'DK2'] as const
export const CURRENCIES = [
  'DKK',
  'EUR',
  'USD',
  'GBP',
  'NZD',
  'CHF',
  'PLN',
  'SEK',
  'NOK',
  'ISK',
  'AUD',
  'CAD',
] as const

export type Country = typeof COUNTRIES[number]
export type Currency = typeof CURRENCIES[number]
export type Region = typeof REGIONS[number]

export type ICreateFactory = {
  organization?: string
  name?: string
  location?: GeoCoordinates
  currency?: Currency
  fixedPowerPrice?: number

  // Logic fields
  useFixedPowerPrice: boolean
}

export const defaultCreateFactory: ICreateFactory = {
  organization: undefined,
  name: undefined,
  location: undefined,
  currency: undefined,
  fixedPowerPrice: undefined,

  // Logic fields
  useFixedPowerPrice: false,
}

export type StepProps = {
  data: ICreateFactory
  updateData: (fieldsToUpdate: Partial<ICreateFactory>) => void
  hasError: boolean
}
