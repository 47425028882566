import NumberField from '../../components/form-components/NumberField'
import SelectFieldBoxesMultiple from '../../components/form-components/SelectFieldBoxesMultiple'
import TextAreaField from '../../components/form-components/TextAreaField'
import TextField from '../../components/form-components/TextField'
import {NotificationChannels} from '../../core/_models'
import {StepProps} from './_models'

const Step3 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <TextField name='Name' data={data} updateData={updateData} dataField='name' />

        <SelectFieldBoxesMultiple
          name='Channels'
          optionNames={NotificationChannels}
          optionValues={NotificationChannels}
          data={data}
          updateData={updateData}
          dataField='channels'
          required
          hasError={hasError}
        />

        <NumberField
          name='Cooldown (hours)'
          data={data}
          updateData={updateData}
          dataField='cooldown_hours'
        />

        <TextAreaField
          name='Additional Message'
          data={data}
          updateData={updateData}
          dataField='additional_message'
          rows={3}
        />

        <div className='d-flex justify-content-end'>
          <label className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={data.is_enabled}
              onChange={() => {
                updateData({...data, is_enabled: !data.is_enabled})
              }}
            />
            <span className='form-check-label fw-semibold text-muted'>Enabled</span>
          </label>
        </div>
      </div>
    </div>
  )
}

export default Step3
