import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {confirmNewPassword} from '../../../core/requests/auth'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

const newPasswordSchema = Yup.object().shape({
  token: Yup.string().required('Token is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  repeatPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Passwords do not match. Please re-enter your passwords'
      ),
    }),
})

export function NewPassword() {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const formik = useFormik({
    initialValues: {token: '', password: '', repeatPassword: ''},
    validationSchema: newPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await confirmNewPassword({token: values.token, newPassword: values.password})
        setSuccess(true)
      } catch (error) {
        console.error(error)
        setStatus('Request failed. Please check your token and try again')
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  if (success) {
    return (
      <>
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Success</h1>
          <div className='text-gray-500 fw-semibold fs-6'>Your password has been reset</div>
        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <Link to='/auth'>
            <button className='btn btn-primary'>
              <span className='indicator-label'>Sign In</span>
            </button>
          </Link>
        </div>
      </>
    )
  }

  return (
    <form className='form w-100' noValidate onSubmit={formik.handleSubmit}>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder'>Set Up New Password</h1>
      </div>

      {formik.status && (
        <div className='mb-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* Token Input */}
      <div className='fv-row mb-8'>
        <label className='form-label required'>Token</label>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='Enter your token received via email'
        />
        <input
          placeholder='Token'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('token')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.token && formik.errors.token},
            {
              'is-valid': formik.touched.token && !formik.errors.token,
            }
          )}
        />
        {formik.touched.token && formik.errors.token && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.token}</span>
            </div>
          </div>
        )}
      </div>

      {/* Password Input */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <div className='position-relative mb-3'>
            <label className='form-label required'>Password</label>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </div>

        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>

      {/* Repeat Password Input */}
      <div className='fv-row mb-8'>
        <label className='form-label required'>Repeat Password</label>
        <input
          type='password'
          placeholder='Repeat Password'
          autoComplete='off'
          {...formik.getFieldProps('repeatPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.repeatPassword && formik.errors.repeatPassword,
            },
            {
              'is-valid': formik.touched.repeatPassword && !formik.errors.repeatPassword,
            }
          )}
        />
        {formik.touched.repeatPassword && formik.errors.repeatPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.repeatPassword}</span>
            </div>
          </div>
        )}
      </div>

      {/* Submit */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Set New Password</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Already reset your password?{' '}
        <Link to='/auth' className='link-primary fw-semibold'>
          Sign in
        </Link>
      </div>
    </form>
  )
}
