import {Modal} from 'react-bootstrap'
import {updateDashboard, useDashboard} from '../../core/requests/template'
import {KTSVG} from '../../../_metronic/helpers'
import LoadingOverlayWrapper from '../../components/LoadingOverlayWrapper'
import {useEffect, useState} from 'react'
import {User} from '../../core/_models'
import {useProfile, useUsers} from '../../core/requests/auth'
import {useQueryClient} from 'react-query'

type UserShareStatus = {user: User; isShared: boolean}

type ShareDashboardModalProps = {
  show: boolean
  hide: () => void
  dashboardId: string
}

const ShareDashboardModal = ({show, hide, dashboardId}: ShareDashboardModalProps) => {
  const queryClient = useQueryClient()
  const [userShares, setUserShares] = useState<UserShareStatus[]>([])
  const [submitting, setSubmitting] = useState(false)

  const {data: profile} = useProfile()

  const {data: dashboard} = useDashboard({dashboard: dashboardId})
  const {data: users} = useUsers({
    organization: profile?.organization?._id,
    options: {enabled: !!profile?.organization},
  })

  const otherUsers = users?.items.filter((user) => user.email !== profile?.email)

  const onUserShareChange = (user: User, isShared: boolean) => {
    setUserShares((prevUserShares) => {
      const index = prevUserShares.findIndex((u) => u.user.email === user.email)
      if (index === -1) return prevUserShares
      const newUserShares = [...prevUserShares]
      newUserShares[index].isShared = isShared
      return newUserShares
    })
  }

  useEffect(() => {
    if (otherUsers === undefined || dashboard === undefined) return
    const newUserShares = otherUsers.map((user) => ({
      user,
      isShared: dashboard.shared_with?.includes(user.email) || false,
    }))
    setUserShares(newUserShares)
  }, [users, dashboard])

  const userSharesChanged = userShares.some((user) => {
    const isShared = dashboard?.shared_with?.includes(user.user.email) || false
    return isShared !== user.isShared
  })

  const onSave = async () => {
    if (otherUsers === undefined) return
    try {
      setSubmitting(true)
      const sharedWith = userShares.filter((u) => u.isShared).map((u) => u.user.email)
      await updateDashboard(dashboardId, {shared_with: sharedWith})
      await queryClient.invalidateQueries(['dashboard', dashboardId])
    } catch (e) {
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Modal
        id='hs_custom_stop_causes_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={show}
        onHide={hide}
        backdrop
        container={document.getElementById('root-modals')}
      >
        <div className='modal-header'>
          <h2>{`Share ${dashboard?.name || 'Dashboard'}`}</h2>
          <div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hide}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
        </div>
        <div className='modal-body mx-5'>
          <LoadingOverlayWrapper loading={users === undefined || submitting}>
            {userShares !== undefined && (
              <div className='mh-400px scroll-y'>
                <div className='d-flex flex-stack p-4 border-bottom border-gray-300 border-bottom-dashed'>
                  <div className='fs-5 fw-bold text-gray-900'>User</div>
                  <div className='fs-5 fw-bold text-gray-900'>Shared With</div>
                </div>
                <DashboardSharingList
                  userShares={userShares}
                  onUserShareChange={onUserShareChange}
                />
              </div>
            )}
          </LoadingOverlayWrapper>
        </div>
        <div className='modal-footer py-3'>
          <button
            type='button'
            className='btn btn-primary'
            disabled={!userSharesChanged || submitting}
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}

type DashboardSharingListProps = {
  userShares: UserShareStatus[]
  onUserShareChange: (user: User, isShared: boolean) => void
}

const DashboardSharingList = ({userShares, onUserShareChange}: DashboardSharingListProps) => {
  return (
    <>
      {userShares.map((userShare) => (
        <div
          className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
          key={userShare.user.email}
        >
          <div className='d-flex ms-4'>
            <div className='fs-5 text-gray-900'>{userShare.user.email}</div>
            {userShare.user.role?.name && (
              <span className='badge badge-light-primary ms-1'>{userShare.user.role.name}</span>
            )}
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid me-3'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={userShare.isShared}
              onChange={(e) => onUserShareChange(userShare.user, e.target.checked)}
            />
          </label>
        </div>
      ))}
    </>
  )
}

export default ShareDashboardModal
