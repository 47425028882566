import React, {ReactNode} from 'react'
import {PulseLoader} from 'react-spinners'

interface Props {
  loading: boolean
  children?: ReactNode
  className?: string
  height?: string
  width?: string
}

const LoadingWrapper: React.FC<Props> = ({
  loading,
  children,
  className = 'align-items-center justify-content-center',
  height = '100%',
  width = '100%',
}) => {
  return (
    <>
      {loading ? (
        <div className='row' style={{width: width, height: height}}>
          <PulseLoader className={className} color='#009ef7' />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default LoadingWrapper
