import {Machine} from '../core/_models'
import {useMachineOperationalStats} from '../core/requests/oee'
import {convertSecondsToHoursMinutesSeconds} from '../core/time-util'
import LoadingWrapper from './LoadingWrapper'

interface Props {
  machine: Machine
  startTime?: string
  endTime?: string
}

const OperationalStatsBox = ({machine, startTime, endTime}: Props) => {
  const {data: stats = {}, isLoading} = useMachineOperationalStats({
    id: machine._id,
    startTime: startTime,
    endTime: endTime,
    options: {
      refetchInterval: 60 * 1000,
    },
  })

  let numberOfStops = 0
  let unscheduledDowntimeDuration = 0
  Object.keys(stats).forEach((key) => {
    const stat = stats[key]
    if (stat.unscheduled) unscheduledDowntimeDuration += stat.duration
    if (!stat.operational) numberOfStops += stat.count
  })

  return (
    <div className='d-flex flex-column'>
      <LoadingWrapper loading={isLoading}>
        <div className='d-flex align-items-center mb-1'>
          <a className='text-gray-800 fs-7 fw-bold me-1'>Unscheduled Downtime:</a>
          <span className='badge py-2 px-2 fs-7 badge-light-primary'>
            {convertSecondsToHoursMinutesSeconds(unscheduledDowntimeDuration!)}
          </span>
        </div>
        <div className='d-flex align-items-center mb-1'>
          <a className='text-gray-800 fs-7 fw-bold me-1'>Number of Stops:</a>
          <span className='badge py-2 px-2 fs-7 badge-light-primary'>{`${numberOfStops} stop${
            numberOfStops === 1 ? '' : 's'
          }`}</span>
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default OperationalStatsBox
