import {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {Part} from '../../core/_models'
import {PartDetail} from './PartDetail'

type PartDetailModalProps = {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  part: Part
}

const PartDetailModal = ({show, setShow, part}: PartDetailModalProps) => (
  <Modal
    show={show}
    tabIndex={-1}
    aria-hidden='true'
    dialogClassName='modal-dialog modal-dialog-centered'
    size='xl'
    onHide={() => setShow(false)}
    backdrop={true}
  >
    <div className='modal-header'>
      <h2>Part Details</h2>
      <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => setShow(false)}>
        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
      </div>
    </div>
    <div className='modal-body py-lg-10 px-lg-10'>
      <PartDetail part={part} onCancel={() => setShow(false)} />
    </div>
  </Modal>
)

export default PartDetailModal
