import {UseQueryOptions, useQuery} from 'react-query'
import {
  BaseModel,
  Dashboard,
  DeletionConfirmation,
  PaginatedQueryProps,
  PaginatedRequestParams,
  PaginatedResponse,
  Widget,
} from '../_models'
import axios from 'axios'
import {ICreateDashboard} from '../../modals/create-dashboard/_models'
import {IWidget} from '../../pages/dashboard/dashboard-grid/widgets/_models'
import qs from 'qs'
import {patchObjectsDates} from '../request-util'

const TEMPLATE_API_BASE_URL = process.env.REACT_APP_WA_TEMPLATES_URL

// ----- DASHBOARDS ----- //

const DASHBOARDS_URL = `${TEMPLATE_API_BASE_URL}/dashboards`

type UseDashboardsProps = PaginatedQueryProps<Dashboard & BaseModel>

type UseDashboardsRequestParams = PaginatedRequestParams

export function useDashboards(props: UseDashboardsProps = {}) {
  const {page, size, options} = props
  const params: UseDashboardsRequestParams = {page, size}
  return useQuery<PaginatedResponse<Dashboard & BaseModel>, Error>(
    ['dashboards', ...Object.values(params)],
    async () => {
      const {data} = await axios.get<PaginatedResponse<Dashboard & BaseModel>>(DASHBOARDS_URL, {
        params,
      })
      return data
    },
    options
  )
}

interface UseDashboardProps {
  dashboard?: string
  options?: UseQueryOptions<Dashboard & BaseModel, Error>
}

export function useDashboard({dashboard, options}: UseDashboardProps) {
  return useQuery<Dashboard & BaseModel, Error>(
    ['dashboard', dashboard],
    async () => {
      const {data} = await axios.get<Dashboard & BaseModel>(`${DASHBOARDS_URL}/${dashboard}`)
      return data
    },
    {enabled: !!dashboard, ...options}
  )
}

export async function createDashboard(data: ICreateDashboard) {
  const {data: dashboard} = await axios.post<Dashboard & BaseModel>(DASHBOARDS_URL, data)
  return dashboard
}

export async function deleteDashboard(dashboard: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${DASHBOARDS_URL}/${dashboard}`)
  return data
}

export async function updateDashboard(dashboard: string, data: Partial<ICreateDashboard>) {
  const {data: updatedDashboard} = await axios.put<Dashboard & BaseModel>(
    `${DASHBOARDS_URL}/${dashboard}`,
    data
  )
  return updatedDashboard
}

// ----- WIDGETS ----- //

const WIDGETS_URL = `${TEMPLATE_API_BASE_URL}/widgets`

type UseWidgetsProps = {
  dashboards?: string[]
  entities?: string[]
  options?: UseQueryOptions<(Widget & BaseModel)[], Error>
}

type UseWidgetsRequestParams = {
  dashboards?: string[]
  entities?: string[]
}

export function useWidgets(props: UseWidgetsProps) {
  const {dashboards, entities, options} = props
  const params: UseWidgetsRequestParams = {dashboards, entities}
  return useQuery<(Widget & BaseModel)[], Error>(
    ['widgets', ...Object.values(params)],
    async () => {
      const {data} = await axios.get<(Widget & BaseModel)[]>(WIDGETS_URL, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
      })
      return patchObjectsDates(data, ['start_time', 'end_time'])
    },
    {...options}
  )
}

type UseWidgetProps = {
  uuid?: string
  options?: UseQueryOptions<Widget & BaseModel, Error>
}

type UseWidgetRequestParams = {
  uuid?: string
}

export function useWidget(props: UseWidgetProps = {}) {
  const {uuid, options} = props
  const params: UseWidgetRequestParams = {uuid}
  return useQuery<Widget & BaseModel, Error>(
    ['widgets', ...Object.values(params)],
    async () => {
      const {data} = await axios.get<Widget & BaseModel>(`${WIDGETS_URL}/${uuid}`, {params})
      return data
    },
    {enabled: !!uuid, ...options}
  )
}

export async function createWidget(data: IWidget) {
  const {data: widget} = await axios.post<Widget & BaseModel>(WIDGETS_URL, data)
  return widget
}

export async function updateWidget(widget: string, data: Partial<IWidget>) {
  const {data: updatedWidget} = await axios.put<Widget & BaseModel>(
    `${WIDGETS_URL}/${widget}`,
    data
  )
  return updatedWidget
}

export async function deleteWidget(widget: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${WIDGETS_URL}/${widget}`)
  return data
}
