import {useState} from 'react'
import StepModalWrapper, {Step} from '../../../../../components/StepModalWrapper'
import {ILineWidget, defaultCreateLineWidget} from './_models'
import {useDashboardGrid} from '../../../../../providers/DashboardGridProvider'
import {createWidget, updateWidget} from '../../../../../core/requests/template'
import {BaseModel, Widget} from '../../../../../core/_models'
import SpecificTypesStep from './SpecificTypesStep'
import EntityStep from './EntityStep'
import DataTypesStep from './DataTypesStep'
import {useQueryClient} from 'react-query'
import DataAggregationStep from './DataAggregationStep'
import TimeRangeStep from './TimeRangeStep'
import NameStep from './NameStep'

interface Props {
  show: boolean
  hide: () => void
  cancel?: () => void
  widgetData?: Widget & BaseModel
}

const LineWidgetModal = ({show, hide, cancel, widgetData}: Props) => {
  const queryClient = useQueryClient()
  const {activeDashboardId} = useDashboardGrid()

  // normalize data types for editing
  const dataTypes: string[] = [...(widgetData?.data_types ?? [])]
  if (dataTypes.includes('Ampere')) {
    dataTypes.push('Kilowatt')
    dataTypes.splice(dataTypes.indexOf('Ampere'), 1)
  }
  if (dataTypes.some((e) => ['Phase 1', 'Phase 2', 'Phase 3', 'Average'].includes(e))) {
    dataTypes.push('Ampere')
  }
  if (dataTypes.some((e) => ['RMS X', 'RMS Y', 'RMS Z'].includes(e))) {
    dataTypes.push('Vibration')
  }
  const [data, setData] = useState<ILineWidget>({
    ...defaultCreateLineWidget,
    ...widgetData,
    data_types: dataTypes,
    timeFrame: !!widgetData?.dynamic_time_range
      ? 'interval'
      : widgetData?.start_time
      ? 'dateRange'
      : undefined,
  })

  const steps: Step<ILineWidget>[] = [
    {
      title: 'Entity',
      description: 'Select Entity',
      component: EntityStep,
      validation: (data) =>
        data.entity_group === undefined || data.entity === undefined || !data.entities.length,
    },
    {
      title: 'Data Types',
      description: 'Select Data Types',
      component: DataTypesStep,
      validation: (data) => data.data_types === undefined || data.data_types.length === 0,
    },
    {
      title: 'Specific Data Types',
      description: 'Select Specific Data Types',
      component: SpecificTypesStep,
      validation: (data) =>
        (!!data.data_types?.includes('Ampere') &&
          !!data.data_types?.every(
            (e) => !['Phase 1', 'Phase 2', 'Phase 3', 'Average'].includes(e)
          )) ||
        (!!data.data_types?.includes('Vibration') &&
          !!data.data_types?.every((e) => !['RMS X', 'RMS Y', 'RMS Z'].includes(e))),
      disabled: (data) => !!data.data_types?.every((e) => !['Ampere', 'Vibration'].includes(e)),
    },
    {
      title: 'Data Aggregation',
      description: 'Set data grouping interval',
      component: DataAggregationStep,
    },
    {
      title: 'Time Frame Settings',
      description: 'Configure Time Frame Settings',
      component: TimeRangeStep,
      validation: (data) => (!!data.start_time || !!data.end_time) && !!data.dynamic_time_range,
    },
    {
      title: 'Choose Name',
      description: 'Select the name of the widget',
      component: NameStep,
      validation: (data) => data.name === undefined,
    },
  ]

  const submit = async (data: ILineWidget) => {
    data.dashboard = activeDashboardId!
    // denormalize data types
    const newData = {...data}
    const newDataTypes = [...(newData.data_types ?? [])]
    if (newDataTypes.includes('Kilowatt')) {
      newDataTypes.splice(newDataTypes.indexOf('Kilowatt'), 1)
      if (!newDataTypes.includes('Ampere')) {
        newDataTypes.push('Ampere')
      }
    } else if (newDataTypes.includes('Ampere')) {
      newDataTypes.splice(newDataTypes.indexOf('Ampere'), 1)
    }
    if (newDataTypes.includes('Vibration')) {
      newDataTypes.splice(newDataTypes.indexOf('Vibration'), 1)
    }
    newData.data_types = newDataTypes
    const widget = newData._id
      ? await updateWidget(newData._id, newData)
      : await createWidget(newData)
    await queryClient.invalidateQueries({queryKey: 'widgets'})
    return widget
  }

  return (
    <StepModalWrapper<ILineWidget, Widget>
      id='line_widget_modal'
      title={`${data._id ? 'Update' : 'Create'} Line Widget`}
      show={show}
      close={hide}
      cancel={cancel}
      steps={steps}
      data={data}
      defaultData={defaultCreateLineWidget}
      setData={setData}
      submitData={submit}
    />
  )
}

export default LineWidgetModal
