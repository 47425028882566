import {Sensor} from './_models'

export const convertSensorToNameValuePairs = (sensor: Sensor): {name: string; value: string}[] => {
  const result = [
    {name: 'Placement', value: `${sensor.area.name} - ${sensor.placement.name}`},
    {name: 'Device ID', value: sensor.device_id},
    {name: 'Data Types', value: sensor.data_types.join(', ')},
    {name: 'Manufacturer', value: sensor.manufacturer},
  ]

  sensor.model && result.push({name: 'Model', value: sensor.model})
  sensor.x && result.push({name: 'X', value: sensor.x})
  sensor.y && result.push({name: 'Y', value: sensor.y})
  sensor.z && result.push({name: 'Z', value: sensor.z})
  sensor.wakeup_threshold !== undefined &&
    sensor.wakeup_threshold !== null &&
    result.push({name: 'Wakeup Threshold', value: `${sensor.wakeup_threshold}`})
  sensor.track_scrap !== undefined &&
    sensor.track_scrap !== null &&
    result.push({name: 'Track Scrap', value: `${sensor.track_scrap}`})

  return result
}
