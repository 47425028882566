import {useEffect, useState} from 'react'

const SIDEBAR_ID = 'kt_app_body'
const ATTRIBUTE_NAME = 'data-kt-app-sidebar-minimize'

const useIsSidebarMinimized = () => {
  const [isMinimized, setIsMinimized] = useState(true)

  useEffect(() => {
    const sidebarElement = document.getElementById(SIDEBAR_ID)
    if (!sidebarElement) return

    const updateIsMinimized = () => {
      const minimizedValue = sidebarElement.getAttribute(ATTRIBUTE_NAME)
      setIsMinimized(minimizedValue === 'on')
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === ATTRIBUTE_NAME) {
          updateIsMinimized()
        }
      })
    })

    observer.observe(sidebarElement, {
      attributes: true,
    })

    updateIsMinimized()

    return () => {
      observer.disconnect()
    }
  }, [])

  return isMinimized
}

export default useIsSidebarMinimized
