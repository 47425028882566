import {Reference} from '../../../../core/_models'
import {useArea} from '../../../../core/requests/factory'
import useModalState from '../../../../hooks/UseModalState'
import {AreaOverviewDetailsModal} from '../../../../modals/overview/AreaOverviewDetailsModal'

type AreaCellProps = {
  areaReference: Reference
}

const AreaCell = ({areaReference}: AreaCellProps) => {
  const detailsModal = useModalState()

  const {data: area} = useArea(areaReference._id)

  return (
    <>
      <div className='d-flex align-items-center'>
        <a
          className='text-gray-800 text-hover-primary mb-1 cursor-pointer'
          onClick={detailsModal.open}
        >
          {areaReference.name}
        </a>
      </div>

      {area && (
        <AreaOverviewDetailsModal
          entity={area}
          show={detailsModal.isOpen}
          onHide={detailsModal.close}
        />
      )}
    </>
  )
}

export default AreaCell
