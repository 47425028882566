import {useEffect, useState} from 'react'

type ContentTitleProps = {
  title: string
  onEdit?: (newTitle: string) => Promise<void>
  protectedNames?: string[]
  className?: string
}

const ContentTitle = ({title, onEdit, protectedNames = [], className = ''}: ContentTitleProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [editableTitle, setEditableTitle] = useState(title)

  const handleEdit = async () => {
    if (onEdit === undefined || isLoading) return
    if (editableTitle === title || protectedNames.includes(editableTitle)) {
      setIsEditing(false)
      setEditableTitle(title)
      return
    }
    setIsLoading(true)
    try {
      await onEdit(editableTitle)
      setIsEditing(false)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setEditableTitle(title)
  }, [title])

  return (
    <div className={`d-flex justify-content-start align-items-center gap-5 ${className}`}>
      {isEditing ? (
        <input
          type='text'
          value={editableTitle}
          onChange={(e) => setEditableTitle(e.target.value)}
          onBlur={handleEdit}
          onKeyDown={(e) => e.key === 'Enter' && handleEdit()}
          className='fs-1 fw-bold m-0 p-0 w-100'
          style={{border: 'none', background: 'transparent', outline: 'none'}}
          autoFocus
          disabled={isLoading}
        />
      ) : (
        <div className='fs-1 fw-bold'>{title}</div>
      )}
      {onEdit && !isEditing && !isLoading && (
        <i
          className='fa-solid fa-pen-to-square fs-1 cursor-pointer'
          onClick={() => setIsEditing(true)}
        />
      )}
      {isLoading && <i className='fa-solid fa-spinner fa-spin fs-1' />}
    </div>
  )
}

export default ContentTitle
