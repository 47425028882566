import {useState} from 'react'
import {AutomationEvent} from '../../../core/_models'
import {ObjectMatchCondition, variableToString} from './conditions'
import Select, {SingleValue} from 'react-select'
import OrganizationsSelectField from '../fields/OrganizationsSelectField'
import InvitationsSelectField from '../fields/InvitationsSelectField'
import RolesSelectField from '../fields/RolesSelectField'
import FactoriesSelectField from '../fields/FactoriesSelectField'
import AreasSelectField from '../fields/AreasSelectField'
import PlacementsSelectField from '../fields/PlacementsSelectField'
import ProductionLinesSelectField from '../fields/ProductionLinesSelectField'
import AssetsSelectField from '../fields/AssetsSelectField'
import SensorsSelectField from '../fields/SensorsSelectField'
import GatewaysSelectField from '../fields/GatewaysSelectField'
import {useProfile} from '../../../core/requests/auth'

type Props = {
  condition: ObjectMatchCondition
  setCondition: (condition: ObjectMatchCondition) => void
  event: AutomationEvent
  errorOccurred?: boolean
}

const ObjectMatch = ({condition, setCondition, event, errorOccurred}: Props) => {
  const [objectType, setObjectType] = useState<SingleValue<OptionType> | null>(
    condition.entities
      ? {
          label: variableToString(condition.entities[0].key),
          value: condition.entities[0].key,
        }
      : null
  )

  const {data: profile} = useProfile()

  const objectVariables = event[1].variables.filter((v) => v.endsWith('_ID'))
  const options = objectVariables
    .filter((v) => VALID_OBJECT_TYPES.includes(v))
    .filter((v) => v !== 'ORGANIZATION_ID' || profile?.is_staff)
    .map((v) => {
      return {label: variableToString(v), value: v}
    })

  const error = errorOccurred && !objectType

  return (
    <>
      <div className='fs-7 pt-3'>Object type*</div>
      <Select
        value={objectType}
        options={options}
        onChange={(selected) => {
          setObjectType(selected)
          setCondition({...condition, entities: undefined})
        }}
        isClearable
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? 'red' : baseStyles.borderColor,
          }),
        }}
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}

      {objectType?.value === 'ORGANIZATION_ID' && profile?.is_staff ? (
        <OrganizationsSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : objectType?.value === 'INVITATION_ID' ? (
        <InvitationsSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : objectType?.value === 'ROLE_ID' ? (
        <RolesSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : objectType?.value === 'FACTORY_ID' ? (
        <FactoriesSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : objectType?.value === 'AREA_ID' ? (
        <AreasSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : objectType?.value === 'PLACEMENT_ID' ? (
        <PlacementsSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : objectType?.value === 'PRODUCTION_LINE_ID' ? (
        <ProductionLinesSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : objectType?.value === 'ASSET_ID' ? (
        <AssetsSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : objectType?.value === 'SENSOR_ID' ? (
        <SensorsSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : objectType?.value === 'GATEWAY_ID' ? (
        <GatewaysSelectField
          initial={condition.entities?.map((e) => e.value) || []}
          onchange={(values) =>
            setCondition({
              ...condition,
              entities: !values.length
                ? undefined
                : values.map((v) => ({key: objectType.value, value: v})),
            })
          }
          errorOccurred={errorOccurred}
          className='pt-3'
        />
      ) : null}
    </>
  )
}

type OptionType = {
  value: string
  label: string
}

const VALID_OBJECT_TYPES = [
  'ORGANIZATION_ID',
  'INVITATION_ID',
  'ROLE_ID',
  'FACTORY_ID',
  'AREA_ID',
  'PLACEMENT_ID',
  'PRODUCTION_LINE_ID',
  'ASSET_ID',
  'SENSOR_ID',
  'GATEWAY_ID',
]

export default ObjectMatch
