import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {SuspensedView} from '../../routing/PrivateRoutes'

const DashboardDetailsPage = React.lazy(() => import('./DashboardDetailsPage'))
const DashboardListPage = React.lazy(() => import('./DashboardListPage'))

const DashboardRouter = () => (
  <Routes>
    <Route
      path={':id/details'}
      element={
        <SuspensedView>
          <DashboardDetailsPage />
        </SuspensedView>
      }
    />
    <Route
      path={':id?'}
      element={
        <SuspensedView>
          <DashboardListPage />
        </SuspensedView>
      }
    />
  </Routes>
)

export default DashboardRouter
