import {useQueryClient} from 'react-query'
import {useDashboardGrid} from '../../../../../providers/DashboardGridProvider'
import {IPieWidget, defaultCreatePieWidget} from './_models'
import {useState} from 'react'
import StepModalWrapper, {Step} from '../../../../../components/StepModalWrapper'
import {createWidget, updateWidget} from '../../../../../core/requests/template'
import {BaseModel, Widget} from '../../../../../core/_models'
import Step1 from './Step1'
import EntityGroupStep from './EntityGroupStep'
import TimeRangeStep from './TimeRangeStep'
import NameStep from './NameStep'

interface Props {
  show: boolean
  hide: () => void
  cancel?: () => void
  widgetData?: Widget & BaseModel
}

const PieWidgetModal = ({show, hide, cancel, widgetData}: Props) => {
  const queryClient = useQueryClient()
  const {activeDashboardId} = useDashboardGrid()
  const [data, setData] = useState<IPieWidget>({
    ...defaultCreatePieWidget,
    ...widgetData,
  })

  const steps: Step<IPieWidget>[] = [
    {
      title: 'Data Type',
      description: 'Select Data Type',
      component: EntityGroupStep,
      validation: (data) => data.entity_group === undefined,
    },
    {
      title: 'Area Filter',
      description: 'Select Area Filter',
      component: Step1,
    },
    {
      title: 'Time Frame Settings',
      description: 'Configure Time Frame Settings',
      component: TimeRangeStep,
      validation: (data) => (!!data.start_time || !!data.end_time) && !!data.dynamic_time_range,
    },
    {
      title: 'Choose Name',
      description: 'Select the name of the widget',
      component: NameStep,
      validation: (data) => data.name === undefined,
    },
  ]

  const submit = async (data: IPieWidget) => {
    data.dashboard = activeDashboardId!
    const widget = data._id ? await updateWidget(data._id, data) : await createWidget(data)
    await queryClient.invalidateQueries({queryKey: 'widgets'})
    return widget
  }

  return (
    <StepModalWrapper<IPieWidget, Widget>
      id='pie_widget_modal'
      title={`${data._id ? 'Update' : 'Create'} Pie Widget`}
      show={show}
      close={hide}
      cancel={cancel}
      steps={steps}
      data={data}
      defaultData={defaultCreatePieWidget}
      setData={setData}
      submitData={submit}
    />
  )
}

export default PieWidgetModal
