import React, {FC, useMemo} from 'react'
import {Column, ColumnInstance, Row, useTable} from 'react-table'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {TableLoading} from '../TableLoading'
import {CustomHeaderColumn} from './CustomHeaderColumn'
import {CustomRow} from './CustomRow'

type Props = {
  tableId: string
  columns: readonly Column<any>[]
  dataList?: any[]
  loading?: boolean
  expandable?: boolean
  expandedId?: string
  expandedIdField?: string
  RowComponent?: FC<{row: Row<any>}>
  children?: React.ReactNode
  onCellClick?: (value?: string) => void
}

type CellClickContextProps = {
  onCellClick?: (value?: string) => void
}

export const CellClickContext = React.createContext<CellClickContextProps>({
  onCellClick: (value?: string) => {},
})

const TableBody = ({
  tableId,
  columns,
  dataList,
  loading,
  expandable = false,
  expandedId,
  expandedIdField,
  RowComponent,
  onCellClick,
  children,
}: Props) => {
  const data = useMemo(() => dataList, [dataList])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data || [],
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id={tableId}
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              <CellClickContext.Provider
                value={{
                  onCellClick,
                }}
              >
                {rows.map((row: Row<any>, i) => {
                  prepareRow(row)
                  const key = `row-${i}-${row.id}`
                  const rowComponent = RowComponent ? (
                    <RowComponent row={row} />
                  ) : (
                    <CustomRow row={row} />
                  )
                  return (
                    <React.Fragment key={key}>
                      {rowComponent}
                      {expandable &&
                        expandedIdField &&
                        expandedId === row.original[expandedIdField] && (
                          <>
                            <tr>
                              <td colSpan={7} className='p-0'>
                                {children}
                              </td>
                            </tr>
                          </>
                        )}
                    </React.Fragment>
                  )
                })}
              </CellClickContext.Provider>
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {loading && <TableLoading />}
    </KTCardBody>
  )
}

export {TableBody}
