const EventCategoriesSidebarHeader = () => {
  return (
    <h1
      className='d-flex justify-content-center align-items-center text-white fs-2 pt-2'
      style={{height: '60px'}}
    >
      Event Category Groups
    </h1>
  )
}

export default EventCategoriesSidebarHeader
