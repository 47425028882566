import {useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {SearchComponent} from '../../_metronic/assets/ts/components'
import {searchFactoryEntities} from '../core/requests/factory'
import axios from 'axios'
import {FactoryEntity} from '../core/_models'
import {entityGroupToStringPlural} from '../core/name-util'
import {
  isArea,
  isAsset,
  isFactory,
  isGateway,
  isPart,
  isPlacement,
  isPlc,
  isProductionLine,
  isSection,
  isSensor,
} from '../core/entity-type-util'
import clsx from 'clsx'
import useModalState from '../hooks/UseModalState'
import {FactoryOverviewDetailsModal} from '../modals/overview/FactoryOverviewDetailsModal'
import {AreaOverviewDetailsModal} from '../modals/overview/AreaOverviewDetailsModal'
import {ProductionLineOverviewDetailsModal} from '../modals/overview/ProductionLineOverviewDetailsModal'
import {AssetOverviewDetailsModal} from '../modals/overview/AssetOverviewDetailsModal'
import PartDetailModal from '../modals/part-detail/PartDetailModal'
import {PlacementOverviewDetailsModal} from '../modals/overview/PlacementOverviewDetailsModal'
import {SensorOverviewDetailsModal} from '../modals/overview/SensorOverviewDetailsModal'
import {GatewayOverviewDetailsModal} from '../modals/overview/GatewayOverviewDetailsModal'

type Props = {}

const GlobalSearchField = ({}: Props) => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const searchElement = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)

  const [resultGroups, setResultGroups] = useState<SearchResultGroup[]>([])
  const [activeEntity, setActiveEntity] = useState<FactoryEntity | null>(null)
  const activeEntityDetailModal = useModalState()

  const handleSearchQuery = async (search: SearchComponent) => {
    try {
      const res = await searchFactoryEntities({
        name: search.getQuery(),
        signal: search.searchAbortController?.signal,
      })
      if (res.items.length === 0) {
        setResultGroups([])
        resultsElement.current?.classList.add('d-none')
        emptyElement.current?.classList.remove('d-none')
      } else {
        setResultGroups(categorizeSearchResults(res.items))
        resultsElement.current?.classList.remove('d-none')
        emptyElement.current?.classList.add('d-none')
      }
    } catch (error) {
      if (axios.isCancel(error)) return
      console.error('Search error:', error)
    } finally {
      if (!search.searchAbortController?.signal.aborted) search.complete()
    }
  }

  const clearSearchResults = (search: SearchComponent) => {
    resultsElement.current?.classList.add('d-none')
    emptyElement.current?.classList.add('d-none')
    setResultGroups([])
  }

  const handleResultClick = (entity: FactoryEntity) => {
    console.log('Clicked on entity:', entity)
    setActiveEntity(entity)
    activeEntityDetailModal.open()
  }

  useEffect(() => {
    const searchObject = SearchComponent.createInsance('#kt_header_search')
    searchObject?.on('kt.search.process', handleSearchQuery)
    searchObject?.on('kt.search.clear', clearSearchResults)
  }, [])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={searchElement}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className={clsx('w-100 position-relative', resultGroups.length > 0 && 'mb-3')}
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder='Search...'
                data-kt-search-element='input'
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>

              {/*  Search Options/Preferences - not in use right now */}
              {/* <div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
              >
                <div
                  data-kt-search-element='preferences-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary me-1'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('preferences')
                  }}
                  title='Show search preferences'
                >
                  <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
                </div>

                <div
                  data-kt-search-element='advanced-options-form-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('advanced')
                  }}
                  title='Show more search options'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2' />
                </div>
              </div> */}
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                {resultGroups.map((group) => (
                  <>
                    <h3
                      className='fs-5 text-muted m-0 pb-5'
                      data-kt-search-element='category-title'
                    >
                      {group.entity_type}
                    </h3>

                    {group.results.map((item) => (
                      <div
                        role='button'
                        className='d-flex text-dark text-hover-primary align-items-center mb-5'
                        onClick={() => handleResultClick(item.entity)}
                      >
                        <div className='symbol symbol-40px me-4'>
                          <img
                            src={
                              item.entity.entity.gallery?.profile_image.url ||
                              toAbsoluteUrl('/media/avatars/blank.png')
                            }
                            alt=''
                          />
                        </div>

                        <div className='d-flex flex-column justify-content-start fw-bold'>
                          <span className='fs-6 fw-bold'>{item.title}</span>
                          {item.description && (
                            <span className='fs-7 fw-bold text-muted'>{item.description}</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>

          {/* Search Options - not in use right now */}
          <form className={`pt-1 ${menuState === 'advanced' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>Advanced Search</h3>

            <div className='mb-5'>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid'
                placeholder='Contains the word'
                name='query'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='type'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    All
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='users' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Users
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='orders' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Orders
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='projects' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Projects
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='assignedto'
                className='form-control form-control-sm form-control-solid'
                placeholder='Assigned to'
              />
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='collaborators'
                className='form-control form-control-sm form-control-solid'
                placeholder='Collaborators'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='attachment'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    Has attachment
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='attachment' value='any' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Any
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <select
                name='timezone'
                aria-label='Select a Timezone'
                data-control='select2'
                data-placeholder='date_period'
                className='form-select form-select-sm form-select-solid'
              >
                <option value='next'>Within the next</option>
                <option value='last'>Within the last</option>
                <option value='between'>Between</option>
                <option value='on'>On</option>
              </select>
            </div>

            <div className='row mb-8'>
              <div className='col-6'>
                <input
                  type='number'
                  name='date_number'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Lenght'
                />
              </div>

              <div className='col-6'>
                <select
                  name='date_typer'
                  aria-label='Select a Timezone'
                  data-control='select2'
                  data-placeholder='Period'
                  className='form-select form-select-sm form-select-solid'
                >
                  <option value='days'>Days</option>
                  <option value='weeks'>Weeks</option>
                  <option value='months'>Months</option>
                  <option value='years'>Years</option>
                </select>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>

              <a
                href='/#'
                className='btn btn-sm fw-bolder btn-primary'
                data-kt-search-element='advanced-options-form-search'
              >
                Search
              </a>
            </div>
          </form>

          {/* Search Preferences - not in use right now */}
          <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>Search Preferences</h3>

            <div className='pb-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Projects
                </span>

                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Targets
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Affiliate Programs
                </span>
                <input className='form-check-input' type='checkbox' value='1' />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Referrals
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>Users</span>
                <input className='form-check-input' type='checkbox' />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>
              <button className='btn btn-sm fw-bolder btn-primary'>Save Changes</button>
            </div>
          </form>
        </div>
      </div>

      {activeEntityDetailModal.isOpen && activeEntity && (
        <>
          {isFactory(activeEntity) && (
            <FactoryOverviewDetailsModal
              entity={activeEntity.entity}
              onHide={activeEntityDetailModal.close}
              show={activeEntityDetailModal.isOpen}
            />
          )}
          {isArea(activeEntity) && (
            <AreaOverviewDetailsModal
              entity={activeEntity.entity}
              onHide={activeEntityDetailModal.close}
              show={activeEntityDetailModal.isOpen}
            />
          )}
          {isProductionLine(activeEntity) && (
            <ProductionLineOverviewDetailsModal
              entity={activeEntity.entity}
              onHide={activeEntityDetailModal.close}
              show={activeEntityDetailModal.isOpen}
            />
          )}
          {isSection(activeEntity) && <></>}
          {isAsset(activeEntity) && (
            <AssetOverviewDetailsModal
              entity={activeEntity.entity}
              onHide={activeEntityDetailModal.close}
              show={activeEntityDetailModal.isOpen}
            />
          )}
          {isPart(activeEntity) && (
            <PartDetailModal
              show={activeEntityDetailModal.isOpen}
              setShow={activeEntityDetailModal.open}
              part={activeEntity.entity}
            />
          )}
          {isPlacement(activeEntity) && (
            <PlacementOverviewDetailsModal
              entity={activeEntity.entity}
              onHide={activeEntityDetailModal.close}
              show={activeEntityDetailModal.isOpen}
            />
          )}
          {isSensor(activeEntity) && (
            <SensorOverviewDetailsModal
              entity={activeEntity.entity}
              onHide={activeEntityDetailModal.close}
              show={activeEntityDetailModal.isOpen}
            />
          )}
          {isGateway(activeEntity) && (
            <GatewayOverviewDetailsModal
              entity={activeEntity.entity}
              onHide={activeEntityDetailModal.close}
              show={activeEntityDetailModal.isOpen}
            />
          )}
          {isPlc(activeEntity) && <></>}
        </>
      )}
    </>
  )
}

type SearchResultItem = {
  entity: FactoryEntity
  title: string
  description: string | null
}

type SearchResultGroup = {
  entity_type: string
  results: SearchResultItem[]
}

const categorizeSearchResults = (entities: FactoryEntity[]): SearchResultGroup[] => {
  return Object.values(
    entities.reduce((acc: {[key: string]: SearchResultGroup}, entity: FactoryEntity) => {
      const result = entityToSearchResultItem(entity)
      const entity_group = entityGroupToStringPlural(entity.type)
      acc[entity_group] = acc[entity_group] || {entity_type: entity_group, results: []}
      acc[entity_group].results.push(result)
      return acc
    }, {})
  )
}

const entityToSearchResultItem = (entity: FactoryEntity): SearchResultItem => {
  const getTitle = (): string => {
    if (isPart(entity)) {
      return entity.entity.type || '-'
    } else if (isSensor(entity) || isGateway(entity)) {
      return entity.entity.device_id
    } else if (
      isFactory(entity) ||
      isArea(entity) ||
      isProductionLine(entity) ||
      isSection(entity) ||
      isAsset(entity) ||
      isPlacement(entity) ||
      isPlc(entity)
    ) {
      return entity.entity.name
    }
    throw new Error(`Unhandled entity: ${JSON.stringify(entity)}`)
  }

  const getDescription = (): string | null => {
    if (isFactory(entity)) {
      return null
    } else if (isArea(entity)) {
      return entity.entity.factory.name
    } else if (isProductionLine(entity)) {
      return entity.entity.area.name
    } else if (isSection(entity)) {
      return entity.entity.production_line.name
    } else if (isAsset(entity)) {
      return entity.entity.section?.name || entity.entity.area.name
    } else if (isPart(entity)) {
      return (
        entity.entity.production_line?.name ||
        entity.entity.section?.name ||
        entity.entity.area.name
      )
    } else if (isPlacement(entity)) {
      return (
        entity.entity.section?.name ||
        entity.entity.production_line?.name ||
        entity.entity.area.name
      )
    } else if (isSensor(entity)) {
      return entity.entity.data_types.join(', ')
    } else if (isGateway(entity)) {
      return entity.entity.area.name
    } else if (isPlc(entity)) {
      return (
        entity.entity.asset?.name ||
        entity.entity.section?.name ||
        entity.entity.production_line?.name ||
        entity.entity.area.name
      )
    } else {
      throw new Error(`Unhandled entity: ${JSON.stringify(entity)}`)
    }
  }

  return {
    entity,
    title: getTitle(),
    description: getDescription(),
  }
}

export default GlobalSearchField
