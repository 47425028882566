import {useEffect, useState} from 'react'
import {fromIso8601Duration, toIso8601Duration} from '../../core/time-util'

export type IntervalOption = 'hours' | 'days' | 'weeks' | 'months'

interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
}

const TimeIntervalField = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
}: Props<DataType>) => {
  const [number, setNumber] = useState<number>(0)
  const [interval, setInterval] = useState<IntervalOption>('days')

  useEffect(() => {
    const existingValue = data[dataField]
    if (existingValue) {
      const {number, interval} = fromIso8601Duration(existingValue)
      setNumber(number)
      setInterval(interval)
    }
  }, [])

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target.value
    const parsedInt = inputVal !== '' ? parseInt(inputVal) : 0
    if (parsedInt >= 0) setNumber(parsedInt)
  }

  const handleIntervalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInterval(e.target.value as IntervalOption)
  }

  useEffect(() => {
    const updateValue = number === 0 ? undefined : toIso8601Duration(number, interval)
    updateData({[dataField]: updateValue} as Partial<DataType>)
  }, [number, interval])

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={`${required ? 'required' : ''}`}>{name}</span>

        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>
      <div className='row'>
        <div className='fs-5 col-auto d-flex align-items-center'>Last</div>
        <input
          type='number'
          className='col form-control form-control-solid'
          name='num'
          style={{maxWidth: '60px', fontSize: '1rem'}}
          value={number}
          onChange={handleNumberChange}
        />
        <select
          className='col form-select form-select-solid ms-2'
          name='time'
          style={{maxWidth: '100px', fontSize: '1rem'}}
          value={interval}
          onChange={handleIntervalChange}
        >
          <option value='hours'>Hours</option>
          <option value='days'>Days</option>
          <option value='weeks'>Weeks</option>
          <option value='months'>Months</option>
        </select>
      </div>
      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export default TimeIntervalField
