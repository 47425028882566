import {ChangeEvent, useRef, useState} from 'react'
import {QueryKey} from 'react-query/types/core/types'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {
  deleteImageFromGallery,
  pushImageToGallery,
  setImageAsCover,
} from '../../../../core/requests/factory'
import {useQueryClient} from 'react-query'
import LoadingWrapper from '../../../../components/LoadingWrapper'
import FsLightbox from 'fslightbox-react'
import {Image} from '../../../../core/_models'
import React from 'react'

type Props = {
  key?: string
  str?: string
  images?: Image[]
  href?: string
  onClick?: () => void
  index?: number
  entityId: string
  queryToInvalidate: QueryKey
}

const NameImageCell = ({str, images, href, onClick, entityId, queryToInvalidate}: Props) => {
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)
  const [toggler, setToggler] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const hasImages = images && images[0]

  const handleSetImageAsCover = async (instance: any) => {
    setToggler(!toggler)
    setLoading(true)
    const currentIndex = instance.stageIndexes.current as number
    await setImageAsCover(entityId, images![currentIndex].id)
    await queryClient.invalidateQueries(queryToInvalidate)
    await queryClient.invalidateQueries(['areas'])
    setLoading(false)
  }

  const handleDeleteImage = async (instance: any) => {
    setToggler(!toggler)
    setLoading(true)
    const currentIndex = instance.stageIndexes.current as number
    await deleteImageFromGallery(entityId, images![currentIndex].id)
    await queryClient.invalidateQueries(queryToInvalidate)
    setLoading(false)
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    setToggler(!toggler)
    setLoading(true)

    try {
      // for (let i = 0; i < event.target.files.length; i++) {
      //   const file = event.target.files[i]
      //   console.log(file)
      //   await pushImageToGallery(entityId, file)
      // }
      await pushImageToGallery(entityId, event.target.files[0])
      // window.location.reload()
    } catch (error) {
      console.log(error)
    } finally {
      await queryClient.invalidateQueries(queryToInvalidate)
      setLoading(false)
    }
  }

  const lightBox = (
    <FsLightbox
      key={`fsLightbox-${entityId}-${images?.length}`}
      toggler={toggler}
      sources={images?.map((image) => image.url)}
      types={images?.map(() => 'image')}
      showThumbsOnMount={true}
      customToolbarButtons={[
        // Set as cover image
        {
          viewBox: '0 0 512 512',
          d: 'M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z',
          width: '20px',
          height: '20px',
          title: 'Set as cover image',
          onClick: handleSetImageAsCover,
        },
        // Delete image
        {
          viewBox: '0 0 512 512',
          d: 'M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z',
          width: '20px',
          height: '20px',
          title: 'Delete image',
          onClick: handleDeleteImage,
        },
        // Add image
        {
          viewBox: '0 0 512 512',
          d: 'M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z',
          width: '20px',
          height: '20px',
          title: 'Add image',
          onClick: () => {
            fileInputRef.current?.click()
          },
        },
      ]}
    />
  )

  return (
    <>
      <div className='d-flex align-items-center'>
        <LoadingWrapper loading={loading} height='125px' width='125px'>
          <div className='symbol symbol-75px overflow-hidden me-3 overlay'>
            <div className='symbol-label overlay-wrapper'>
              <img
                src={hasImages ? images[0].url : toAbsoluteUrl('/media/blank.jpg')}
                alt='...'
                className='w-100'
              />
            </div>

            <div
              className='overlay-layer bg-dark bg-opacity-50'
              onClick={
                hasImages
                  ? () => {
                      setToggler(!toggler)
                    }
                  : () => {
                      fileInputRef.current?.click()
                    }
              }
              style={{cursor: 'pointer'}}
            >
              {hasImages ? (
                <KTSVG
                  path='/media/icons/duotune/general/gen004.svg'
                  className='svg-icon-2 text-white'
                />
              ) : (
                <div className='svg-icon text-center text-white'>
                  <i className='fa-solid fa-plus' style={{color: '#ffffff'}} />
                </div>
              )}
            </div>
          </div>
        </LoadingWrapper>
        <a
          href={href}
          className='text-gray-800 text-hover-primary'
          style={{cursor: onClick || href ? 'pointer' : 'default'}}
          onClick={() => {
            if (onClick && !href) {
              onClick()
            }
          }}
        >
          {str}
        </a>
      </div>

      <input type='file' ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange} />

      {hasImages ? lightBox : null}
    </>
  )
}

export {NameImageCell}
