import React from 'react'
import EditBatchModal from './EditBatchModal'

const useEditBatchModal = () => {
  const [id, setId] = React.useState<string>()
  const modal = id ? (
    <EditBatchModal id={id} show={true} handleClose={() => setId(undefined)} />
  ) : null
  return {
    modal,
    setId,
  }
}

export default useEditBatchModal
