import LoadingWrapper from '../../../components/LoadingWrapper'
import {useDashboards, useWidgets} from '../../../core/requests/template'

interface DashboardsTabProps {
  objectId: string
}

const DashboardsTab = ({objectId}: DashboardsTabProps) => {
  const {data: widgets} = useWidgets({entities: [objectId]})
  const {data: dashboards} = useDashboards()

  const dashboardIds = widgets?.map((widget) => widget.dashboard)
  const dashboardsLinkedToObjectId =
    dashboardIds && dashboards?.items?.filter((dashboard) => dashboardIds.includes(dashboard._id))

  return (
    <LoadingWrapper loading={dashboardsLinkedToObjectId === undefined}>
      {dashboardsLinkedToObjectId?.map((dashboard) => (
        <div key={`dashboard-${dashboard._id}`} className={'row gap-7'}>
          <div className={'col fw-bold'}>{dashboard.name}</div>
          <div className={`col d-flex justify-content-end`}>
            <a href={`/dashboards/${dashboard._id}`} rel='noopener'>
              <strong>Open</strong>
            </a>
          </div>
          <div className='w-100 d-none d-md-block'></div>
        </div>
      ))}
    </LoadingWrapper>
  )
}

export default DashboardsTab
