import {useCallback, useState} from 'react'

interface UseModalStateReturn {
  isOpen: boolean
  open: () => void
  close: () => void
}

const useModalState = (initialState: boolean = false): UseModalStateReturn => {
  const [isOpen, setIsOpen] = useState(initialState)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  return {isOpen, open, close}
}

export default useModalState
