import {createContext, useContext, useState} from 'react'
import {Batch} from '../../../../app/core/_models'

// export const lookbackValues = ['last day', 'last week', 'last month', 'all time'] as const
export const lookbackValues = ['last day', 'last week', 'last month'] as const
export type LookbackValue = typeof lookbackValues[number]
export type LookbackType = {
  productionLine: LookbackValue
}
export const DEFAULT_LOOKBACK: LookbackType = {
  productionLine: 'last day',
}
export const LOOKBACK_KEY = 'hs_lookback_value'

type LookbackContextType = {
  values: LookbackType
  updateLookback: (_lookback: Partial<LookbackType>) => void
  activebatch: Batch | undefined
  setActiveBatch: React.Dispatch<React.SetStateAction<Batch | undefined>>
}

const getLookbackFromLocalStorage = (): LookbackType => {
  if (!localStorage) return DEFAULT_LOOKBACK

  const dataStr = localStorage.getItem(LOOKBACK_KEY)
  if (!dataStr) return DEFAULT_LOOKBACK

  return JSON.parse(dataStr) as LookbackType
}

const defaultLookback: LookbackContextType = {
  values: getLookbackFromLocalStorage(),
  updateLookback: (_lookback: Partial<LookbackType>) => {},
  activebatch: undefined,
  setActiveBatch: () => {},
}

const LookbackContext = createContext<LookbackContextType>({
  values: defaultLookback.values,
  updateLookback: (_lookback: Partial<LookbackType>) => {},
  activebatch: defaultLookback.activebatch,
  setActiveBatch: () => {},
})

const useLookback = () => useContext(LookbackContext)

const LookbackProvider = ({children}: {children: React.ReactNode}) => {
  const [lookbackValues, setLookbackValues] = useState<LookbackType>(defaultLookback.values)
  const [activebatch, setActiveBatch] = useState<Batch | undefined>(defaultLookback.activebatch)

  const updateLookback = (_lookback: Partial<LookbackType>) => {
    const newLookback = {...lookbackValues, ..._lookback}
    setLookbackValues(newLookback)
    localStorage && localStorage.setItem(LOOKBACK_KEY, JSON.stringify(newLookback))
  }

  return (
    <LookbackContext.Provider
      value={{values: lookbackValues, updateLookback, activebatch, setActiveBatch}}
    >
      {children}
    </LookbackContext.Provider>
  )
}

export {LookbackProvider, useLookback}
