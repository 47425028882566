import {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {Asset} from '../../core/_models'
import {RegisterMaintenance} from './RegisterMaintenance'

type RegisterMaintenanceModalProps = {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  asset: Asset
}

const RegisterMaintenanceModal = ({show, setShow, asset}: RegisterMaintenanceModalProps) => (
  <Modal
    show={show}
    tabIndex={-1}
    aria-hidden='true'
    dialogClassName='modal-dialog modal-dialog-centered'
    size='xl'
    onHide={() => setShow(false)}
    backdrop={true}
  >
    <div className='modal-header'>
      <h2>Register Maintenance</h2>
      <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => setShow(false)}>
        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
      </div>
    </div>
    <div className='modal-body py-lg-10 px-lg-10'>
      <RegisterMaintenance
        asset={asset}
        onCancel={() => setShow(false)}
        onComplete={() => setShow(false)}
      />
    </div>
  </Modal>
)

export default RegisterMaintenanceModal
