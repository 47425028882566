import {Column} from 'react-table'
import {SelectionCell} from '../core/columns/SelectionCell'
import {CustomHeader} from '../core/columns/CustomHeader'
import {SelectionHeader} from '../core/columns/SelectionHeader'
import {Section} from '../../../core/_models'
import {NameImageCell} from '../core/columns/ImageStringCell'
import ProductionLineCell from '../core/columns/ProductionLineCell'

const Columns: ReadonlyArray<Column<Section>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} tableId='hs_sections_table' />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index]._id} tableId='hs_sections_table' />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => (
      <NameImageCell
        str={props.data[props.row.index].name}
        images={
          props.data[props.row.index].gallery
            ? [
                props.data[props.row.index].gallery!.profile_image,
                ...props.data[props.row.index].gallery!.images,
              ]
            : undefined
        }
        index={props.row.index}
        entityId={props.data[props.row.index]._id}
        queryToInvalidate='sections'
      />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Production Line' className='min-w-125px' />
    ),
    id: 'productionLine',
    Cell: ({...props}) => (
      <ProductionLineCell productionLineReference={props.data[props.row.index].production_line} />
    ),
  },
]

export {Columns}
