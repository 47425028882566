import React from 'react'
import {Section} from '../../../core/_models'
import {useAssets, useAssetsPaginated} from '../../../core/requests/factory'
import AssetNode from './AssetNode'
import TreeNode from './TreeNode'

interface SectionNodeProps {
  section: Section
}

export const SectionNode = ({section}: SectionNodeProps) => {
  const [opened, setOpen] = React.useState(false)
  const {data: areas} = useAssets({section: section._id})
  const {items, isFetching, hasNextPage, fetchNextPage} = useAssetsPaginated(
    {section: section._id},
    {
      enabled: opened,
    }
  )

  React.useEffect(() => {
    if (!isFetching && hasNextPage) {
      ;(async () => {
        await fetchNextPage()
      })()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  const nodes = items?.map((asset) => <AssetNode asset={asset} />)

  return (
    <TreeNode
      name={section.name}
      iconPath={`/media/icons/duotune/art/art006.svg`}
      opened={opened}
      setOpen={setOpen}
      nodes={nodes}
      isExpandable={!!areas?.total}
    />
  )
}

export default SectionNode
