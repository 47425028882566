import {useState} from 'react'
import {ICreateDashboard, StepProps, defaultCreateDashboard} from './_models'
import {useCreateModals} from '../CreateModalsProvider'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {Dashboard} from '../../core/_models'
import TextField from '../../components/form-components/TextField'
import {createDashboard} from '../../core/requests/template'
import {useQueryClient} from 'react-query'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateDashboardModal = ({show, handleClose}: Props) => {
  const queryClient = useQueryClient()
  const [data, setData] = useState<ICreateDashboard>(defaultCreateDashboard)

  const steps: Step<ICreateDashboard>[] = [
    {
      title: 'Name',
      description: 'Name Your Dashboard',
      component: ModalContent,
      validation: (data) => data.name === undefined,
    },
  ]

  const submit = async (data: ICreateDashboard) => {
    const dashboard = await createDashboard(data)
    await queryClient.invalidateQueries('dashboards')
    return dashboard
  }

  return (
    <StepModalWrapper<ICreateDashboard, Dashboard>
      id='hs_create_dashboard'
      objectName='Dashboard'
      show={show}
      close={handleClose}
      steps={steps}
      data={data}
      defaultData={defaultCreateDashboard}
      setData={setData}
      submitData={submit}
    />
  )
}

const ModalContent = ({data, updateData, hasError}: StepProps) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <TextField
          name='Name'
          data={data}
          updateData={updateData}
          dataField='name'
          hasError={hasError}
          required
          lastField
        />
      </div>
    </div>
  )
}

export default CreateDashboardModal
