import TextField from '../../../../../components/form-components/TextField'
import {StepProps} from './_models'

const NameStep = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <TextField
          name='Name'
          data={data}
          updateData={updateData}
          dataField='name'
          required
          hasError={hasError}
          lastField
        />
      </div>
    </div>
  )
}

export default NameStep
