import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {useProfile} from '../../core/requests/auth'
import {deleteProduct, useProducts} from '../../core/requests/oee'
import LoadingWrapper from '../../components/LoadingWrapper'
import CreateProductsModal from './CreateProductsModal'
import {Product, ProductionLine} from '../../core/_models'
import {ConfirmDeletionModal} from '../overview/core/columns/TableSelectionToolbar'
import {useQueryClient} from 'react-query'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {useProductionLines} from '../../core/requests/factory'

type Props = {
  show: boolean
  hide: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ProductsModal = ({show, hide}: Props) => {
  const queryClient = useQueryClient()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletingProduct, setDeletingProduct] = useState<Product | null>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [editingProduct, setEditingProduct] = useState<Product | null>(null)

  const {data: productionLines} = useProductionLines()

  const {data: profile} = useProfile()
  const {data: products} = useProducts({
    organization: profile?.organization?._id,
    options: {enabled: !!profile?.organization?._id},
  })

  const handleEditClick = (product: Product) => {
    setEditingProduct(product)
    setShowEditModal(true)
  }

  const handleDeleteClick = (product: Product) => {
    setDeletingProduct(product)
    setShowDeleteModal(true)
  }

  async function handleDeleteProduct() {
    await deleteProduct(deletingProduct!._id)
    setDeletingProduct(null)
    setShowDeleteModal(false)
    queryClient.invalidateQueries('products')
  }

  useEffect(() => {
    if (!show) return
    MenuComponent.reinitialization()
  }, [show, products])

  return (
    <>
      <Modal
        id='hs_products_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={show}
        onHide={hide}
        backdrop
        container={modalsRoot}
      >
        <div className='modal-header'>
          <h2>Products</h2>
          <div>
            <a
              className='btn btn-sm fw-bold btn-primary me-5'
              onClick={() => setShowCreateModal(true)}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add Product
            </a>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hide}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
        </div>

        <div className='modal-body m-5'>
          <LoadingWrapper loading={products === undefined || productionLines === undefined}>
            <div className='mh-400px scroll-y'>
              <div className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'>
                <div className='ms-5'>
                  <a className='fs-5 fw-bold text-gray-900'>Name</a>
                </div>
                <div className='fs-5 fw-bold text-gray-900' style={{marginRight: '92px'}}>
                  SKU
                </div>
              </div>
              {products?.items?.map((product) => (
                <ProductRow
                  product={product}
                  productionLines={productionLines?.items ?? []}
                  handleEditClick={handleEditClick}
                  key={product._id}
                />
              ))}
            </div>
          </LoadingWrapper>
        </div>
      </Modal>
      <CreateProductsModal show={showCreateModal} hide={() => setShowCreateModal(false)} />
      <CreateProductsModal
        show={showEditModal}
        hide={() => setShowEditModal(false)}
        edit={editingProduct ?? undefined}
      />
      <ConfirmDeletionModal
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        deleteSelections={handleDeleteProduct}
      />
    </>
  )
}

const ProductRow = ({
  product,
  productionLines,
  handleEditClick,
}: {
  product: Product
  productionLines: ProductionLine[]
  handleEditClick: (product: Product) => void
}) => {
  return (
    <div className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'>
      <div className='ms-5'>
        <a className='fs-5 text-gray-900'>{product.name}</a>
      </div>
      <div className='fs-5 text-gray-900 d-flex align-items-center'>
        <div className='me-2'>{product.sku}</div>
        <button
          type='button'
          className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
          onClick={() => handleEditClick(product)}
        >
          <i className={`fa-solid fs-2 fa-pen`}></i>
        </button>
        <ProductionLinesMenu product={product} productionLines={productionLines} />
        {/* <button type='button' className='btn btn-icon btn-color-gray-400 btn-active-color-danger'>
          <i className={`fa-solid fs-2 fa-trash-can`}></i>
        </button> */}
      </div>
    </div>
  )
}

const ProductionLinesMenu = ({
  product,
  productionLines,
}: {
  product: Product
  productionLines: ProductionLine[]
}) => {
  return (
    <>
      <button
        type='button'
        className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='right'
      >
        <i className={`fa-solid fs-2 fa-question`} />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-300px'
        data-kt-menu='true'
        id='add_assignee_menu'
      >
        <div className='menu-item px-3 py-2 mt-2'>
          <div className='font-weight-bold d-flex justify-content-center'>
            Compatible Production Lines
          </div>
        </div>
        {product.compatible_production_lines.map((pl) => (
          <div className='menu-item px-3 mt-2'>
            {productionLines.find((productionLine) => productionLine._id === pl)?.name}
          </div>
        ))}
      </div>
    </>
  )
}

export default ProductsModal
