import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

export function showNoDataDisplay(id: string, root: am5.Root, chart?: am5.Chart): am5.Root {
  if (chart === undefined) {
    chart = root.container.children.push(am5xy.XYChart.new(root, {panX: false, panY: false}))
  }

  const htmlContent = `
    <span
      class='fs-1 fw-bold text-center text-gray-400'
      style='display: inline-block; background-color: #ffffff; padding: 50px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);'
    >
      No data to display
    </span>
  `

  chart.children.unshift(
    am5.Label.new(root, {
      id: `${id}-no-data-display`,
      html: htmlContent,
      x: am5.percent(50),
      centerX: am5.percent(50),
      y: am5.percent(50),
      centerY: am5.percent(50),
    })
  )

  return root
}
