import ProductionMetricsBox from '../../../../components/ProductionMetricsBox'
import {WidgetProps} from './Widget'

const ProductionStatsWidget = ({widget}: WidgetProps) => (
  <div className='d-flex align-items-center justify-content-center h-100 mx-5'>
    <ProductionMetricsBox productionLineId={widget.entity} />
  </div>
)

export default ProductionStatsWidget
