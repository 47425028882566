import {KTSVG} from '../../../../../_metronic/helpers'

type Props = {
  buttonText: string
  onButtonClick: () => void
  buttonIcon?: string
}

const TableToolbar = ({
  buttonText,
  onButtonClick,
  buttonIcon = '/media/icons/duotune/arrows/arr075.svg',
}: Props) => {
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={() => onButtonClick()}>
        <KTSVG path={`${buttonIcon}`} className='svg-icon-2' />
        {buttonText}
      </button>
    </div>
  )
}

export {TableToolbar}
