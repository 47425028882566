import {useQueryClient} from 'react-query'
import {Action, WorkOrder} from '../../core/_models'
import {completeWorkOrder} from '../../core/requests/maintenance'
import {ContentProps, FormModalWrapper} from '../../components/FormModalWrapper'
import TextField from '../../components/form-components/TextField'

export interface ICompleteWorkOrderData {
  completed_actions?: string[]
  note?: string
  end_time?: number

  // non-api fields
  actions: Action[]
}

const defaultCompleteWorkOrderData = (workOrder: WorkOrder): ICompleteWorkOrderData => {
  return {
    completed_actions: [],
    note: undefined,
    end_time: undefined,
    actions: workOrder.actions,
  }
}

type Props = {
  show: boolean
  handleClose: () => void
  workOrder: WorkOrder
}

const WorkOrderCompleteModal = ({show, handleClose, workOrder}: Props) => {
  const queryClient = useQueryClient()

  const submit = async (data: ICompleteWorkOrderData) => {
    await completeWorkOrder(workOrder._id, data)
    await queryClient.invalidateQueries('workOrders')
  }

  return (
    <FormModalWrapper<ICompleteWorkOrderData>
      id='hs_work_order_complete_modal'
      title='Complete Work Order'
      show={show}
      close={handleClose}
      defaultData={defaultCompleteWorkOrderData(workOrder)}
      submit={submit}
      body={{content: Content}}
    />
  )
}

const Content = ({data, updateData, hasError}: ContentProps<ICompleteWorkOrderData>) => {
  return (
    <>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Actions</label>
      {data.actions.map((action, index) => {
        return (
          <div className='form-check form-check-custom form-check-solid mb-3' key={index}>
            <input
              className='form-check-input'
              type='checkbox'
              value={action._id}
              id={`action-${action._id}`}
              checked={data.completed_actions!.includes(action._id)}
              onChange={(e) => {
                const checked = e.target.checked
                if (checked) {
                  updateData({completed_actions: [...data.completed_actions!, action._id]})
                } else {
                  updateData({
                    completed_actions: data.completed_actions!.filter((id) => id !== action._id),
                  })
                }
              }}
            />
            <label className='form-check-label' htmlFor={`action-${action._id}`}>
              {action.description}
            </label>
          </div>
        )
      })}
      <div className='mt-10'>
        <TextField name='Note' data={data} updateData={updateData} dataField='note' lastField />
      </div>
    </>
  )
}

export {WorkOrderCompleteModal}
