import React, {useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import clsx from 'clsx'
import {useEventCategories} from '../../../core/requests/oee'
import {useCategorySelection} from '../CategorySelectionContext'
import EventCategoriesSidebarSubItem from './EventCategoriesSidebarSubItem'
import {EventCategoryGroup} from '../../../core/_models'

type EventCategoriesSidebarItemProps = {
  eventCategoryGroup: EventCategoryGroup
}

const EventCategoriesSidebarItem: React.FC<EventCategoriesSidebarItemProps> = ({
  eventCategoryGroup,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const {activeCategoryGroup, setActiveCategoryGroup} = useCategorySelection()
  const {data: eventCategories} = useEventCategories()

  const eventCategoriesInGroup =
    eventCategories?.items.filter((eventCategory) =>
      eventCategory.event_category_groups.includes(eventCategoryGroup._id)
    ) || []

  const handleClick = () => {
    setIsExpanded(!isExpanded)
    setActiveCategoryGroup(eventCategoryGroup)
  }

  const isActive = activeCategoryGroup?._id === eventCategoryGroup._id

  return (
    <div className='my-2 text-white'>
      <div className='d-flex align-items-center cursor-pointer' onClick={handleClick}>
        <motion.i
          className='me-2 fa-solid fa-chevron-right'
          variants={{
            open: {rotate: 90},
            collapsed: {rotate: 0},
          }}
          animate={isExpanded ? 'open' : 'collapsed'}
          transition={{duration: 0.2}}
        />
        <span className={clsx('fs-3 ps-1 flex-fill', {'bg-primary rounded': isActive})}>
          {eventCategoryGroup.title}
        </span>
      </div>
      <motion.div
        initial='collapsed'
        animate={isExpanded ? 'open' : 'collapsed'}
        variants={{
          open: {opacity: 1, height: 'auto', display: 'block'},
          collapsed: {opacity: 0, height: 0, transitionEnd: {display: 'none'}},
        }}
        transition={{duration: 0.2}}
      >
        {isExpanded &&
          eventCategoriesInGroup.map((eventCategory) => (
            <EventCategoriesSidebarSubItem
              key={eventCategory._id}
              eventCategory={eventCategory}
              subEventCategoryPathIds={[]}
            />
          ))}
      </motion.div>
    </div>
  )
}

export default EventCategoriesSidebarItem
