import * as am5 from '@amcharts/amcharts5'
import {useEffect, useRef} from 'react'
import {usePlacementGraphs} from '../../../../core/requests/factory'
import {Sensor} from '../../../../core/_models'
import {ChartLoader} from '../ChartLoader'
import {RMSChartConfig} from '../../core/_chartConfigs/RMSChartConfig'

type RmsGraphProps = {
  sensor: Sensor
  startTime?: string
  endTime?: string
  height: string
  chartId?: string
}

const RmsGraph = ({
  sensor,
  startTime,
  endTime,
  height,
  chartId = `rms-${sensor._id}`,
}: RmsGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)

  const {data: placementGraphs} = usePlacementGraphs({
    placementId: sensor.placement._id,
    startTime,
    endTime,
  })

  const loading = placementGraphs === undefined

  useEffect(() => {
    if (loading) return

    chartRef.current = RMSChartConfig(chartId, [
      placementGraphs.rms_x,
      placementGraphs.rms_y,
      placementGraphs.rms_z,
    ])

    return () => {
      chartRef.current?.dispose()
    }
  }, [placementGraphs, loading])

  return <ChartLoader loading={loading} chartId={chartId} height={height} />
}

export {RmsGraph}
