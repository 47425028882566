import React from 'react'
import Flatpickr from 'react-flatpickr'
import Select from 'react-select'
import {
  workOrderCategories,
  WorkOrderCategory,
  WorkOrderStatus,
  workOrderStatuses,
} from '../../../core/_models'
import {useAssets, useProductionLines} from '../../../core/requests/factory'

type UseWorkOrdersFilterProps = {
  category?: WorkOrderCategory
  status?: WorkOrderStatus
  entities?: string[]
}

const useWorkOrdersFilter = (props: UseWorkOrdersFilterProps = {}) => {
  const [plannedStartTime, setPlannedStartTime] = React.useState<number>()
  const [status, setStatus] = React.useState<WorkOrderStatus | undefined>(props.status)
  const [category, setCategory] = React.useState<WorkOrderCategory | undefined>(props.category)
  const [entities, setEntities] = React.useState<string[]>(props.entities || [])

  const {data: productionLines, isLoading: isProductionLinesLoading} = useProductionLines()
  const {data: assets, isLoading: isAssetsLoading} = useAssets()

  const statusSelect = (
    <Select
      placeholder={`Status`}
      defaultValue={
        status
          ? {
              value: status,
              label: status,
            }
          : undefined
      }
      onChange={(e) => setStatus(e?.value ? e.value : undefined)}
      options={workOrderStatuses.map((wos) => ({
        value: wos,
        label: wos,
      }))}
      isClearable
      className={'me-3'}
    />
  )

  const categorySelect = (
    <Select
      placeholder={`Category`}
      defaultValue={
        category
          ? {
              value: category,
              label: category,
            }
          : undefined
      }
      onChange={(e) => setCategory(e?.value ? e.value : undefined)}
      options={workOrderCategories.map((woc) => ({
        value: woc,
        label: woc,
      }))}
      isClearable
      className={'me-5'}
    />
  )

  const entityOptions = [...(productionLines?.items || []), ...(assets?.items || [])]
  const selectedOptions = entityOptions
    .filter((option) => entities.includes(option._id))
    .map((option) => ({
      value: option._id,
      label: option.name,
    }))

  const entitiesSelect = (
    <Select
      placeholder={`Entities`}
      isMulti
      options={entityOptions.map((entity) => ({
        value: entity._id,
        label: entity.name,
      }))}
      value={selectedOptions}
      onChange={(e) => setEntities(e.map((entity) => entity.value) || [])}
      isLoading={isProductionLinesLoading || isAssetsLoading}
    />
  )

  const plannedStartTimeFilter = (
    <Flatpickr
      data-enable-time
      className='form-control form-control-solid'
      placeholder={`Planned start time`}
      options={{dateFormat: 'd, M Y, H:i', time_24hr: true, static: true}}
      value={plannedStartTime}
      onChange={(date) => setPlannedStartTime(date[0]?.getTime())}
    />
  )

  return {
    plannedStartTime,
    setPlannedStartTime,
    plannedStartTimeFilter,
    status,
    setStatus,
    statusSelect,
    category,
    setCategory,
    categorySelect,
    entities,
    setEntities,
    entitiesSelect,
  }
}

export default useWorkOrdersFilter
