import * as am5 from '@amcharts/amcharts5'
import {useEffect, useRef} from 'react'
import {usePlacementGraphs} from '../../../../core/requests/factory'
import {Sensor} from '../../../../core/_models'
import {ChartLoader} from '../ChartLoader'
import {LineData, lineConfig} from '../../core/_chartConfigs/lineConfig'

type BatteryGraphProps = {
  sensor: Sensor
  startTime?: string
  endTime?: string
  height: string
  chartId?: string
}

const BatteryGraph = ({
  sensor,
  startTime,
  endTime,
  height,
  chartId = `battery-${sensor._id}`,
}: BatteryGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)

  const {data: placementGraphs} = usePlacementGraphs({
    placementId: sensor.placement._id,
    startTime,
    endTime,
  })

  const loading = placementGraphs === undefined

  useEffect(() => {
    if (loading) return

    const data: LineData[] = [{dataType: 'battery', data: placementGraphs.battery_voltage}]
    chartRef.current = lineConfig(chartId, {[sensor._id]: data})

    return () => {
      chartRef.current?.dispose()
    }
  }, [placementGraphs, loading])

  return <ChartLoader loading={loading} chartId={chartId} height={height} />
}

export {BatteryGraph}
