import {useEffect, useRef, useState} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {useBatches, useProducts} from '../../core/requests/oee'
import useEditBatchModal from '../../modals/edit-batch/useEditBatchModal'
import {ChartLoader} from '../dashboard/components/ChartLoader'
import {useProductionLines} from '../../core/requests/factory'
import {Batch, BatchTemplate, ProductionLine, WorkOrder} from '../../core/_models'
import {useWorkOrders} from '../../core/requests/maintenance'
import {convertSecondsToHoursMinutesSecondsShort} from '../../core/time-util'
import {useProfile} from '../../core/requests/auth'
import {CreateSetupProps} from '../../modals/create-planner-setup/CreatePlannerSetupModal'
import {ProductRate} from './_models'

export interface IBatchData {
  productionLineId: string
  productionLineName: string
  start: number
  end: number
  columnSettings?: any
  name: string
}

const CHART_ID = 'planner'

type Props = {
  selectedTemplate: BatchTemplate | null
  setSelectedTemplate: React.Dispatch<React.SetStateAction<BatchTemplate | null>>
  activeSetup: CreateSetupProps | null
  setActiveSetup: React.Dispatch<React.SetStateAction<CreateSetupProps | null>>
  addedSetup: CreateSetupProps | null
  setAddedSetup: React.Dispatch<React.SetStateAction<CreateSetupProps | null>>
  rates: ProductRate[] | null
  addedBatch: IBatchData | null
  setAddedBatch: React.Dispatch<React.SetStateAction<IBatchData | null>>
}

const PlannerGraph = ({
  selectedTemplate,
  setSelectedTemplate,
  activeSetup,
  setActiveSetup,
  addedSetup,
  setAddedSetup,
  rates,
  addedBatch,
  setAddedBatch,
}: Props) => {
  const {modal: editBatchModal, setId: setEditBatchId} = useEditBatchModal()
  const {data: batches, isSuccess: batchesReady} = useBatches()
  const {data: productionLines, isSuccess: productionLinesReady} = useProductionLines({
    manufacturingType: 'discrete',
  })
  const {data: workOrders, isSuccess: workOrdersReady} = useWorkOrders({
    factoryEntities: productionLines?.items.map((pl) => pl._id),
    category: ['Setup', 'Shutdown Repair Work'],
    timeField: 'planned_start_time',
    startTime: new Date(new Date().setHours(0, 0, 0, 0) - 24 * 60 * 60 * 1000).toISOString(),
    options: {enabled: productionLines !== undefined},
  })
  const dataReady = batchesReady && productionLinesReady && workOrdersReady

  const {data: profile} = useProfile()
  const {data: products, isSuccess: productsReady} = useProducts({
    organization: profile?.organization?._id,
    options: {enabled: !!profile?.organization?._id},
  })

  const selectedProductionLines =
    productionLines?.items.filter(
      (line) =>
        products?.items
          ?.find((product) => product._id === selectedTemplate?.product)
          ?.compatible_production_lines.includes(line._id) || false
    ) || []

  const [root, setRoot] = useState<am5.Root | null>(null)
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!dataReady) return
    if (root !== null) root.dispose()
    const chartRoot = initializeChart(productionLines!.items, batches?.items!, workOrders!.items)
    setRoot(chartRoot)
    chartRoot.container.children
      .getIndex(0)
      // @ts-ignore
      ?.series.getIndex(0)
      // @ts-ignore
      .columns.template.events.on('click', (ev) =>
        setEditBatchId(ev.target.dataItem.dataContext._id)
      )
  }, [batches, productionLines, workOrders])

  useEffect(() => {
    if (!dataReady || root === null || !productsReady) return

    const chart = root.container.children.getIndex(0) as am5xy.XYChart
    const yAxis = chart.yAxes.getIndex(0) as am5xy.CategoryAxis<am5xy.AxisRenderer>
    const xAxis = chart.xAxes.getIndex(0) as am5xy.DateAxis<am5xy.AxisRenderer>
    const series = chart.series.getIndex(0) as am5xy.ColumnSeries
    const cursor = chart.get('cursor') as am5xy.XYCursor

    const scopeMin = xAxis.getPrivate('selectionMin') as number
    const scopeMax = xAxis.getPrivate('selectionMax') as number

    var newBatchAdded = false
    var nextRenderReady = true

    if (selectedTemplate === null && activeSetup === null) {
      xAxis.set('min', new Date().getTime())
      xAxis.set('max', new Date().getTime() + 1000 * 60 * 60 * 24)
      yAxis.data.setAll(productionLines!.items)
      series.data.setAll([
        ...getBatchData(chart, batches?.items!, productionLines!.items),
        ...getWorkOrderData(workOrders!.items, productionLines!.items),
      ])
      const yRenderer = yAxis.get('renderer') as am5xy.AxisRendererY
      yRenderer.labels.template.set('text', '[bold]{category}[/]')
    } else if (addedBatch !== null) {
      series.data.push(addedBatch)
    } else if (addedSetup !== null) {
      const productionLine = productionLines!.items.find(
        (line) => line._id === addedSetup.productionLineId
      )!
      series.data.push(
        getNewSetup(
          new Date(addedSetup.duration! * 60000),
          productionLine,
          addedSetup as CreateSetupProps
        )
      )
    } else {
      xAxis.set('min', scopeMin)
      xAxis.set('max', scopeMax)

      if (selectedTemplate !== null) {
        yAxis.data.setAll(
          selectedProductionLines.map(({name, _id}) => {
            const rate = rates?.find((rate) => rate.production_line === _id)
            const duration =
              selectedTemplate && rate?.product_rate
                ? `duration: ${convertSecondsToHoursMinutesSecondsShort(
                    (selectedTemplate.total_product_quantity / rate.product_rate) * 60
                  )}`
                : ''
            return {name, duration}
          })
        )
        series.data.setAll([
          ...getBatchData(chart, batches?.items!, selectedProductionLines),
          ...getWorkOrderData(workOrders!.items, selectedProductionLines),
        ])
      }

      const yRenderer = yAxis.get('renderer') as am5xy.AxisRendererY
      yRenderer.labels.template.set('text', '[bold]{category}[/]\n{duration}')

      series.events.on('datavalidated', onDataValidated)
      cursor.events.on('cursorhidden', onCursorHidden)
      cursor.events.on('cursormoved', onCursorMoved)
      document.addEventListener('keydown', onKeyDown)
      chartRef.current?.addEventListener('click', onClick)

      return () => {
        series.events.off('datavalidated')
        cursor.events.off('cursorhidden')
        cursor.events.off('cursormoved')
        document.removeEventListener('keydown', onKeyDown)
        chartRef.current?.removeEventListener('click', onClick)
      }
    }

    function onClick() {
      if (!newBatchAdded) return
      const newBatchIndex = series.data?.length! - 1
      const batchData = series.data.getIndex(newBatchIndex) as any
      if (selectedTemplate !== null) setAddedBatch({...batchData})
      else if (activeSetup !== null)
        setAddedSetup({
          ...activeSetup,
          productionLineId: batchData.productionLineId,
          startTime: batchData.start,
        })
    }

    function onCursorMoved(
      e: {
        target: am5xy.XYCursor
      } & {
        type: 'cursormoved'
        target: am5xy.XYCursor
      }
    ) {
      // get cursor position
      const x = e.target.getPrivate('positionX') as number
      const y = e.target.getPrivate('positionY') as number
      const cursorDate = xAxis.positionToDate(xAxis.toAxisPosition(x))
      const cursorIndex = yAxis.axisPositionToIndex(yAxis.toAxisPosition(y))

      // create new batch
      const productionLine =
        selectedTemplate !== null
          ? selectedProductionLines[cursorIndex]
          : productionLines!.items[cursorIndex]
      const batchRates = rates?.find((rate) => rate.production_line === productionLine._id)
      if (productionLine === undefined || (selectedTemplate !== null && batchRates === undefined))
        return

      const newBatch =
        activeSetup !== null
          ? getNewSetup(cursorDate, productionLine, activeSetup)
          : getNewBatch(
              cursorDate,
              productionLine,
              batchRates!.product_rate!,
              selectedTemplate!.total_product_quantity,
              chart.get('colors')!.getIndex(cursorIndex)
            )

      // check of batch overlaps with existing batches
      const otherBatches = newBatchAdded
        ? (series.data.values.slice(0, -1) as IBatchData[])
        : (series.data.values as IBatchData[])
      const batchOverlaps = otherBatches?.some(
        ({start, end, productionLineName}) =>
          productionLineName === newBatch.productionLineName &&
          end > newBatch.start &&
          start < newBatch.end
      )

      if (batchOverlaps) {
        if (!newBatchAdded) return
        const newBatchIndex = series.data?.length! - 1
        series.data.removeIndex(newBatchIndex)
        newBatchAdded = false
        return
      }

      // add new batch
      if (!newBatchAdded) {
        newBatchAdded = true
        series.data.push(newBatch)
      } else if (nextRenderReady) {
        nextRenderReady = false
        const newBatchIndex = series.data?.length! - 1
        series.data.removeIndex(newBatchIndex)
        series.data.push(newBatch)
      }
    }

    function onKeyDown(e: KeyboardEvent) {
      if (e.key !== 'Escape') return
      if (newBatchAdded) {
        onCursorHidden()
      }
      setSelectedTemplate(null)
      setActiveSetup(null)
    }

    function onCursorHidden() {
      if (!newBatchAdded) return
      const newBatchIndex = series.data?.length! - 1
      series.columns.getIndex(newBatchIndex)?.hideTooltip()
      series.data.removeIndex(newBatchIndex)
      newBatchAdded = false
    }

    function onDataValidated() {
      nextRenderReady = true
      if (newBatchAdded) series.columns.getIndex(series.data.length - 1)?.showTooltip()
    }
  }, [selectedTemplate, activeSetup, addedSetup, rates, addedBatch])

  const calculateChartHeight = () => {
    const lines = selectedTemplate !== null ? selectedProductionLines : productionLines?.items || []
    const height = lines.length === 0 ? 0 : lines.length * 100 + 100
    return `${height}px`
  }

  return (
    <>
      {selectedTemplate !== null && selectedProductionLines.length === 0 && (
        <h1 className='fw-semibold text-gray-800 text-center my-10'>
          {`${selectedTemplate.name}'s product is not compatible with any discrete production lines`}
        </h1>
      )}
      <div ref={chartRef}>
        <ChartLoader loading={!dataReady} chartId={CHART_ID} height={calculateChartHeight()} />
      </div>
      {editBatchModal}
    </>
  )
}

function getNewBatch(
  startDate: Date,
  productionLine: ProductionLine,
  rate: number,
  quantity: number,
  color: am5.Color
): IBatchData {
  const ratePerSecond = rate / 60
  const startDateNearestMinute = Math.round(startDate.getTime() / 60000) * 60000
  const startDateSeconds = startDateNearestMinute / 1000
  const endDate = startDateSeconds + parseFloat((quantity / ratePerSecond).toFixed(2))

  return {
    productionLineId: productionLine._id,
    productionLineName: productionLine.name,
    start: startDateNearestMinute,
    end: endDate * 1000,
    columnSettings: {
      fill: am5.Color.brighten(color, 0),
    },
    name: 'New Batch',
  }
}

function getNewSetup(
  startDate: Date,
  productionLine: ProductionLine,
  activeSetup: CreateSetupProps
) {
  const startDateNearestMinute = Math.round(startDate.getTime() / 60000) * 60000
  const durationMilliseconds = activeSetup.duration! * 60000

  return {
    productionLineId: productionLine._id,
    productionLineName: productionLine.name,
    start: startDateNearestMinute,
    end: startDateNearestMinute + durationMilliseconds,
    columnSettings: {fill: am5.color('#acb5b5')},
    name: 'Setup',
  }
}

function initializeChart(
  productionLines: ProductionLine[],
  batches: Batch[],
  workOrders: WorkOrder[]
) {
  const root = am5.Root.new(CHART_ID)
  root.setThemes([am5themes_Animated.new(root)])

  root.dateFormatter.setAll({
    dateFormat: 'yyyy-MM-dd HH:mm:ss',
    dateFields: ['valueX', 'openValueX'],
  })

  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: true,
      wheelY: 'zoomX',
      layout: root.verticalLayout,
      cursor: am5xy.XYCursor.new(root, {
        forceHidden: true,
      }),
    })
  )

  chart.get('cursor')?.lineY.setAll({visible: false})
  chart.get('cursor')?.lineX.setAll({visible: false})

  const yRenderer = am5xy.AxisRendererY.new(root, {})
  yRenderer.grid.template.set('location', 1)
  yRenderer.labels.template.set('text', '[bold]{category}[/]')

  const yAxis = chart.yAxes.push(
    am5xy.CategoryAxis.new(root, {categoryField: 'name', renderer: yRenderer})
  )

  yAxis.data.setAll(productionLines)

  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: {timeUnit: 'minute', count: 1},
      renderer: am5xy.AxisRendererX.new(root, {strokeOpacity: 0.1}),
      min: new Date().getTime(),
      max: new Date().getTime() + 1000 * 60 * 60 * 24,
      extraMax: 30,
      maxDeviation: 0,
    })
  )

  const series = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      openValueXField: 'start',
      valueXField: 'end',
      categoryYField: 'productionLineName',
      sequencedInterpolation: true,
    })
  )

  series.columns.template.setAll({
    templateField: 'columnSettings',
    strokeOpacity: 0,
    tooltipText:
      '[bold]{name}[/]\nQuantity: [bold]{quantity}[/]\nStart: [bold]{openValueX}[/]\nEnd: [bold]{valueX}[/]',
  })

  series.data.setAll([
    ...getBatchData(chart, batches, productionLines),
    ...getWorkOrderData(workOrders, productionLines),
  ])

  return root
}

function getWorkOrderData(workOrders: WorkOrder[], productionLines: ProductionLine[]) {
  return workOrders
    .filter(({factory_entity}) => productionLines.some((line) => line._id === factory_entity))
    .map(
      ({factory_entity, start_time, planned_start_time, end_time, expected_duration, title}) => ({
        productionLineId: factory_entity,
        productionLineName: productionLines.find((line) => line._id === factory_entity)!.name,
        start: new Date(start_time || planned_start_time).getTime(),
        end: new Date(
          end_time || new Date(planned_start_time).getTime() + expected_duration * 60 * 1000
        ).getTime(),
        columnSettings: {fill: am5.color('#acb5b5')},
        name: title,
        quantity: undefined,
      })
    )
}

function getBatchData(
  chart: am5xy.XYChart,
  batches: Batch[],
  productionLines: ProductionLine[]
): IBatchData[] {
  const mapProductionLineNames = (lines: ProductionLine[]): Map<string, number> => {
    const nameToIndexMap = new Map<string, number>()
    lines.forEach((line, index) => nameToIndexMap.set(line.name, index))
    return nameToIndexMap
  }

  const findLineById = (lines: ProductionLine[], id: string) =>
    lines.find((line) => line._id === id)

  const calculateEndTime = (batch: Batch) =>
    new Date(
      batch.end_time ??
        new Date(batch.planned_start_time).getTime() + batch.planned_production_time * 1000
    ).getTime()

  const productionLineNameToIndex = mapProductionLineNames(productionLines)
  const colors = chart.get('colors') as am5.ColorSet

  return batches
    .filter((batch) => findLineById(productionLines, batch.production_line))
    .map((batch) => {
      const productionLine = findLineById(productionLines, batch.production_line)!
      const startTime = new Date(batch.start_time || batch.planned_start_time).getTime()
      const endTime = calculateEndTime(batch)
      const colorIndex = productionLineNameToIndex.get(productionLine.name) ?? 0
      return {
        _id: batch._id,
        productionLineId: batch.production_line,
        productionLineName: productionLine.name,
        start: startTime,
        end: endTime,
        columnSettings: {
          fill: colors.getIndex(colorIndex),
        },
        name: batch.name,
        quantity: batch.total_product_quantity,
      }
    })
}

export default PlannerGraph
