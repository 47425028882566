import {useState} from 'react'
import {IWorkOrdersWidget, defaultCreateTextWidget} from './_models'
import {useDashboardGrid} from '../../../../../providers/DashboardGridProvider'
import {useQueryClient} from 'react-query'
import StepModalWrapper, {Step} from '../../../../../components/StepModalWrapper'
import {createWidget, updateWidget} from '../../../../../core/requests/template'
import {BaseModel, Widget} from '../../../../../core/_models'
import NameStep from './NameStep'
import FiltersStep from './FiltersStep'
import TimeRangeStep from './TimeRangeStep'

interface Props {
  show: boolean
  hide: () => void
  widgetData?: Widget & BaseModel
}

const WorkOrdersWidgetModal = ({show, hide, widgetData}: Props) => {
  const queryClient = useQueryClient()
  const {activeDashboardId} = useDashboardGrid()
  const [data, setData] = useState<IWorkOrdersWidget>({
    ...defaultCreateTextWidget,
    ...widgetData,
  })

  const steps: Step<IWorkOrdersWidget>[] = [
    {
      title: 'Filters',
      description: 'Configure Default Filters',
      component: FiltersStep,
    },
    {
      title: 'Timeframe',
      description: 'Specify the data range',
      component: TimeRangeStep,
      validation: (data) => (!!data.start_time || !!data.end_time) && !!data.dynamic_time_range,
    },
    {
      title: 'Name',
      description: 'Enter Name',
      component: NameStep,
      validation: (data) => data.name === undefined,
    },
  ]

  const submit = async (data: IWorkOrdersWidget) => {
    data.dashboard = activeDashboardId!
    const widget = data._id ? await updateWidget(data._id, data) : await createWidget(data)
    await queryClient.invalidateQueries({queryKey: 'widgets'})
    return widget
  }

  return (
    <StepModalWrapper<IWorkOrdersWidget, Widget>
      id='text_widget_modal'
      title={`${data._id ? 'Update' : 'Create'} Work Orders Widget`}
      show={show}
      close={hide}
      steps={steps}
      data={data}
      defaultData={defaultCreateTextWidget}
      setData={setData}
      submitData={submit}
    />
  )
}

export default WorkOrdersWidgetModal
