import {FC} from 'react'

type Props = {
  color: string
  str?: string
}

const ColorCell: FC<Props> = ({color, str}) => (
  <div className={`rounded-2 h-20px w-100 bg-${color}`}>{str}</div>
)

export {ColorCell}
