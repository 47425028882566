import React from 'react'
import {FileAttachment} from '../../core/_models'

interface AttachmentListProps {
  attachments: FileAttachment[]
  actions?: [
    {
      getElement?: (attachment: FileAttachment, index: number) => React.ReactNode
    }
  ]
}

const AttachmentList = ({attachments, actions}: AttachmentListProps) => {
  const getPreview = (attachment: FileAttachment) => {
    let imagePreviewUrl: string | undefined
    if (attachment.preview_url) {
      imagePreviewUrl = attachment.preview_url
    } else if (attachment.file?.type.startsWith('image/')) {
      imagePreviewUrl = URL.createObjectURL(attachment.file)
    }

    return imagePreviewUrl ? (
      <div
        className={'m-1'}
        style={{
          backgroundImage: `url("${imagePreviewUrl}")`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: 100,
        }}
      ></div>
    ) : (
      <div className={`m-1 d-flex justify-content-center align-items-center`} style={{height: 100}}>
        no preview
      </div>
    )
  }

  return (
    <div className={`row g-6 g-xl-9 mb-4 mt-5`}>
      {attachments.map((attachment, index) => (
        <div className={`col-md-6 col-xxl-4`}>
          <div className={`card border rounded`}>
            <div className={`card-body p-5`}>{getPreview(attachment)}</div>
            <div className={`card-footer text-center p-5`} style={{height: 50, overflow: 'hidden'}}>
              {actions?.map((action) =>
                action?.getElement ? action.getElement(attachment, index) : null
              )}
              <span className={`text-nowrap`}>
                {attachment?.file?.type || attachment.content_type}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default AttachmentList
