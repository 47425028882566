import Select from 'react-select'
import {Manufacturer, manufacturers} from '../../../../core/_models'

type ManufacturerSelectProps = {
  onChange: (manufacturer?: Manufacturer) => void
}

const ManufacturerSelect = ({onChange}: ManufacturerSelectProps) => (
  <Select
    placeholder={`Manufacturer`}
    onChange={(e) => onChange(e?.value)}
    options={
      manufacturers.map((m) => ({
        value: m,
        label: m,
      })) || []
    }
    isClearable
    className={'me-3'}
  />
)

export default ManufacturerSelect
