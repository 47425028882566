import {Reference} from '../../../../core/_models'
import {useFactory, usePlacement} from '../../../../core/requests/factory'
import useModalState from '../../../../hooks/UseModalState'
import {FactoryOverviewDetailsModal} from '../../../../modals/overview/FactoryOverviewDetailsModal'
import {PlacementOverviewDetailsModal} from '../../../../modals/overview/PlacementOverviewDetailsModal'

type PlacementCellProps = {
  placementReference: Reference
}

const PlacementCell = ({placementReference}: PlacementCellProps) => {
  const detailsModal = useModalState()

  const {data: placement} = usePlacement(placementReference._id)

  return (
    <>
      <div className='d-flex align-items-center'>
        <a
          className='text-gray-800 text-hover-primary mb-1 cursor-pointer'
          onClick={detailsModal.open}
        >
          {placementReference.name}
        </a>
      </div>

      {placement && (
        <PlacementOverviewDetailsModal
          entity={placement}
          show={detailsModal.isOpen}
          onHide={detailsModal.close}
        />
      )}
    </>
  )
}

export default PlacementCell
