import {IWidget, defaultCreateWidget, defaultLayout} from '../../widgets/_models'

export type IEnergyWidget = IWidget & {
  nameIsDefault?: boolean
  by_product?: boolean
  quantity?: number
}

export const defaultCreateEnergyWidget: IEnergyWidget = {
  ...defaultCreateWidget,
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
    min_w: 4,
    min_h: 7,
  },
  nameIsDefault: true,
}

export type StepProps = {
  data: IEnergyWidget
  updateData: (fieldsToUpdate: Partial<IEnergyWidget>) => void
  hasError: boolean
  current?: boolean
}
