import {Route, Routes} from 'react-router-dom'
import FactoryTree from '../../modules/factoryTree/FactoryTree'

const DevicesPage = () => {
  return (
    <Routes>
      <Route path='' element={<FactoryTree />} />
    </Routes>
  )
}

export default DevicesPage
