export type ICreateSection = {
  production_line?: string
  name?: string
}

export const defaultCreateSection: ICreateSection = {
  production_line: undefined,
  name: undefined,
}

export type StepProps = {
  data: ICreateSection
  updateData: (fieldsToUpdate: Partial<ICreateSection>) => void
  hasError: boolean
}
