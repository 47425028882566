import {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import Flatpickr from 'react-flatpickr'

interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  id: string
}

const DaysOfMonthField = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
  id,
}: Props<DataType>) => {
  const [dates, setDates] = useState<Date[]>(
    data[dataField]?.map((dateIndex: number) => new Date(2023, 0, dateIndex + 1)) || []
  )
  const handleResize = () => {
    const parentElement = document.getElementById(`${id}parentElement`)
    const parentWidth = parentElement?.offsetWidth
    const flatpickrElement = document.getElementById(`${id}flatpickrElement`)
    if (parentWidth && flatpickrElement) flatpickrElement.style.width = parentWidth + 'px'
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize)
    handleResize()
    const parentElement = document.getElementById(`${id}parentElement`)
    if (parentElement) resizeObserver.observe(parentElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const hideHeader = () => {
    const monthsHeader = document.querySelector(
      `#${id}parentElement > div.flatpickr-wrapper > div > div.flatpickr-months`
    ) as HTMLElement
    const weekDaysHeader = document.querySelector(
      `#${id}parentElement > div.flatpickr-wrapper > div > div.flatpickr-innerContainer > div > div.flatpickr-weekdays`
    ) as HTMLElement

    monthsHeader.style.display = 'none'
    weekDaysHeader.style.display = 'none'
  }

  const onChange = (dates: Date[]) => {
    setDates(dates)
    const dateIndices = dates.map((date) => date.getDate() - 1)
    updateData({[dataField]: dates.length === 0 ? undefined : dateIndices} as Partial<DataType>)
  }

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>
        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>

      <div className='position-relative' id={`${id}parentElement`}>
        <div style={{position: 'relative', zIndex: '1'}}>
          <KTSVG
            path='/media/icons/duotune/general/gen014.svg'
            className='svg-icon-2 position-absolute top-50 translate-middle-y mx-3 mt-6'
          />
        </div>
        <Flatpickr
          className='form-control form-control-solid ps-12'
          placeholder={`Select ${name.toLowerCase()}`}
          options={{
            dateFormat: 'd',
            time_24hr: true,
            static: true,
            mode: 'multiple',
            defaultDate: new Date(2023, 0, 1),
            enable: [
              {
                from: new Date(2023, 0, 1),
                to: new Date(2023, 0, 31),
              },
            ],
          }}
          value={dates}
          onChange={(dates) => onChange(dates)}
          style={{position: 'relative', zIndex: '0'}}
          id={`${id}flatpickrElement`}
          onOpen={hideHeader}
        />
      </div>
      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export {DaysOfMonthField}
