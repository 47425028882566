import SelectField from '../../../components/form-components/SelectField'
import SelectFieldBoxes from '../../../components/form-components/SelectFieldBoxes'
import TextField from '../../../components/form-components/TextField'
import {priorities, workOrderCategories} from '../../../core/_models'
import {StepProps} from './_models'

const Step3 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectField
          name='Category'
          optionNames={workOrderCategories}
          optionValues={workOrderCategories}
          data={data}
          updateData={updateData}
          dataField='category'
          updatesOnChange={() => ({sub_category: undefined})}
        />

        {data.category !== undefined && (
          <TextField
            name='Sub Category'
            data={data}
            updateData={updateData}
            dataField='sub_category'
          />
        )}

        <SelectFieldBoxes
          name='Priority'
          optionNames={priorities.map(
            (priority) => priority.charAt(0) + priority.slice(1).toLowerCase()
          )}
          optionValues={priorities}
          optionIcons={priorities.map((priority) => ({
            icon: 'fa-flag',
            color:
              priority === 'LOW'
                ? '#D8D8D8'
                : priority === 'MEDIUM'
                ? '#6FDDFF'
                : priority === 'HIGH'
                ? '#FFCC00'
                : '#F50000',
          }))}
          data={data}
          updateData={updateData}
          dataField='priority'
        />
      </div>
    </div>
  )
}

export default Step3
