import {EntityGroup} from '../../../../../core/_models'
import {IWidget, defaultCreateWidget, defaultLayout} from '../../widgets/_models'

export type IConditionWidget = IWidget & {
  entity_group_filter?: EntityGroup
  entity_filter?: string
  health_type?: 'Simple' | 'Advanced'
  timeFrame?: 'dateRange' | 'interval'
}

export const defaultCreateConditionWidget: IConditionWidget = {
  ...defaultCreateWidget,
  entity_group: 'asset',
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
  },
}

export type StepProps = {
  data: IConditionWidget
  updateData: (fieldsToUpdate: Partial<IConditionWidget>) => void
  hasError: boolean
}
