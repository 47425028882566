import {useEffect, useRef, useState} from 'react'
import Select, {MultiValue} from 'react-select'
import {HealthState, healthStates} from '../conditions/conditions'

type OptionType = {
  value: HealthState
  label: string
}

const options: OptionType[] = healthStates.map((hs) => ({
  value: hs,
  label: hs,
}))

type Props = {
  initial?: HealthState[]
  name?: string
  onChange: (value: HealthState[]) => void
  errorOccurred?: boolean
  className?: string
}

const ZonesSelectField = ({
  initial = [],
  name = 'States',
  onChange,
  errorOccurred,
  className,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([])
  const initialOptionsSet = useRef(false)

  const handleChange = (selected: MultiValue<OptionType>) => {
    onChange(selected.map((option) => option.value))
    setSelectedOptions(selected)
  }

  useEffect(() => {
    if (!initial.length || initialOptionsSet.current) return
    setSelectedOptions(options.filter((option) => initial.includes(option.value)))
    initialOptionsSet.current = true
  }, [initial])

  const error = errorOccurred && !selectedOptions.length

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={selectedOptions}
        options={options}
        onChange={handleChange}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? 'red' : baseStyles.borderColor,
          }),
        }}
        isMulti
        isClearable
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}
    </>
  )
}

export default ZonesSelectField
