import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'

const modalsRoot = document.getElementById('root-modals') || document.body

type Props = {
  show: boolean
  handleClose: () => void
  handleConfirm: () => void
}

const AssetChecklistConfirmationModal = ({show, handleClose, handleConfirm}: Props) => {
  return createPortal(
    <Modal
      id='hs_asset_checklist_confirmation_modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      show={show}
      onHide={handleClose}
      backdrop='static'
    >
      <div className='modal-header'>
        <h2>Fault Trend Detection Confirmation</h2>
      </div>
      <div className='modal-body'>
        <span className='fs-4 text-gray-800'>
          Are you sure you want to proceed to the detailed view and checklist?
        </span>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-light-primary' onClick={handleClose}>
          Cancel
        </button>
        <button id='submit' type='button' className='btn btn-primary' onClick={handleConfirm}>
          Confirm
        </button>
      </div>
    </Modal>,
    modalsRoot
  )
}

export default AssetChecklistConfirmationModal
