import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {PushNotificationsProvider} from '../../app/providers/PushNotificationsProvider'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {Sidebar} from './components/sidebar'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {reInitMenu} from '../helpers'
import {DisplayModeProvider} from '../partials/layout/display-mode/DisplayModeProvider'
import {CreateModalsProvider} from '../../app/modals/CreateModalsProvider'
import CreateFactoryModal from '../../app/modals/create-factory/CreateFactoryModal'
import CreateAreaModal from '../../app/modals/create-area/CreateAreaModal'
import CreateProductionLineModal from '../../app/modals/create-production-line/CreateProductionLineModal'
import CreateSectionModal from '../../app/modals/create-section/CreateSectionModal'
import CreateAssetModal from '../../app/modals/create-asset/CreateAssetModal'
import CreatePartModal from '../../app/modals/create-part/CreatePartModal'
import CreatePlacementModal from '../../app/modals/create-placement/CreatePlacementModal'
import CreateSensorModal from '../../app/modals/create-sensor/CreateSensorModal'
import CreateGatewayModal from '../../app/modals/create-gateway/CreateGatewayModal'
import {LookbackProvider} from '../partials/layout/lookback/LookbackProvider'
import {DashboardGridProvider} from '../../app/providers/DashboardGridProvider'
import EntityDetailModal from '../../app/modals/entity-detail/EntityDetailModal'
import CreatePlcModal from '../../app/modals/create-plc/CreatePlcModal'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <ThemeModeProvider>
      <DisplayModeProvider>
        <LookbackProvider>
          <CreateModalsProvider>
            <DashboardGridProvider>
              <PushNotificationsProvider>
                <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
                  <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
                    <HeaderWrapper />
                    <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                      <Sidebar />
                      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                        <div className='d-flex flex-column flex-column-fluid'>
                          <Content>
                            <Outlet />
                          </Content>
                        </div>
                        {/* <FooterWrapper /> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* begin:: Drawers */}
                <ActivityDrawer />
                {/* <RightToolbar /> */}
                <DrawerMessenger />
                {/* end:: Drawers */}

                {/* begin:: Modals */}
                <InviteUsers />
                <UpgradePlan />

                <CreateFactoryModal />
                <CreateAreaModal />
                <CreateProductionLineModal />
                <CreateSectionModal />
                <CreateAssetModal />
                <CreatePartModal />
                <CreatePlacementModal />
                <CreateSensorModal />
                <CreateGatewayModal />
                <CreatePlcModal />
                <EntityDetailModal />
                {/* end:: Modals */}
                <ScrollTop />
              </PushNotificationsProvider>
            </DashboardGridProvider>
          </CreateModalsProvider>
        </LookbackProvider>
      </DisplayModeProvider>
    </ThemeModeProvider>
  )
}

export {MasterLayout}
