type Props = {
  text: string
}

const VerticalSeparator = ({text}: Props) => {
  return (
    <div className='col-1 d-flex flex-column justify-content-center align-items-center'>
      <div className='h-50 bg-secondary' style={{width: '2px'}} />
      <span className='text-muted fw-semibold'>{text}</span>
      <div className='h-50 bg-secondary' style={{width: '2px'}} />
    </div>
  )
}

export default VerticalSeparator
