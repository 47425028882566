import React from 'react'
import LoadingWrapper from '../../../components/LoadingWrapper'
import {useAsset} from '../../../core/requests/factory'
import AssetNode from '../../../modules/factoryTree/nodes/AssetNode'
import TreeNode from '../../../modules/factoryTree/nodes/TreeNode'

interface AssetTreeTabProps {
  assetId: string
}

const AssetTreeTab = ({assetId}: AssetTreeTabProps) => {
  const {data: asset, isLoading} = useAsset({
    assetId,
  })
  const section = asset?.section || {_id: null, name: '---'}
  const sectionNode =
    asset && section ? (
      <TreeNode
        name={section.name}
        iconPath={`/media/icons/duotune/art/art006.svg`}
        opened={true}
        setOpen={() => {}}
        nodes={[<AssetNode asset={asset} />]}
        isExpandable={false}
      />
    ) : undefined
  const productionLine = asset?.production_line || {_id: null, name: '---'}
  const productionLineNode = productionLine ? (
    <TreeNode
      name={productionLine.name}
      iconPath={`/media/icons/duotune/technology/teh005.svg`}
      opened={true}
      setOpen={() => {}}
      nodes={[sectionNode]}
      isExpandable={false}
    />
  ) : undefined
  const area = asset?.area
  const areaNode = area ? (
    <TreeNode
      name={area.name}
      iconPath={`/media/icons/duotune/maps/map001.svg`}
      opened={true}
      setOpen={() => {}}
      nodes={[productionLineNode]}
      isExpandable={false}
    />
  ) : undefined
  const factory = asset?.factory
  const factoryNode = factory ? (
    <TreeNode
      name={factory.name}
      icon={`fa-industry`}
      opened={true}
      setOpen={() => {}}
      nodes={[areaNode]}
      isExpandable={false}
    />
  ) : undefined
  return (
    <LoadingWrapper loading={isLoading || !asset}>
      <div className={`factory-tree`}>{factoryNode}</div>
    </LoadingWrapper>
  )
}

export default AssetTreeTab
