import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {PowerConsumptionGraph} from '../../components/charts/PowerConsumptionGraph'
import {WidgetProps} from './Widget'

const PowerConsumptionWidget = ({widget, height}: WidgetProps) => {
  const type =
    widget.data_types?.at(0) === 'Power Consumption Column'
      ? 'Power Consumption Column'
      : 'Power Consumption'
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  return (
    <PowerConsumptionGraph
      entityId={widget.entity}
      entityGroup={widget.entity_group}
      type={type}
      timespan={widget.timespan}
      multiplier={widget.multiplier}
      height={height}
      startTime={startTime}
      endTime={endTime}
      dynamicTimeRange={widget.dynamic_time_range}
      chartId={widget._id}
    />
  )
}

export default PowerConsumptionWidget
