export type ICreatePlc = {
  area?: string
  production_line?: string
  section?: string
  asset?: string
  name?: string
  // Not model fields
  locationChoice?: 'area' | 'production_line' | 'section' | 'asset'
}

export const defaultCreatePlc: ICreatePlc = {
  area: undefined,
  production_line: undefined,
  section: undefined,
  asset: undefined,
  name: undefined,
  locationChoice: undefined,
}

export type StepProps = {
  data: ICreatePlc
  updateData: (fieldsToUpdate: Partial<ICreatePlc>) => void
  hasError: boolean
}
