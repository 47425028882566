import {BatchTemplateRates, ProductionLine} from '../../core/_models'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import LoadingWrapper from '../../components/LoadingWrapper'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {ProductRate} from './_models'

type Props = {
  show: boolean
  hide: () => void
  cancel: () => void
  rates?: BatchTemplateRates[]
  productionLines?: ProductionLine[]
  addnewRates(rates: ProductRate[]): void
  loading?: boolean
}

interface TempRateMap {
  [key: string]: {
    onlyCustom: boolean
    choice: 'average' | 'optimal' | 'custom'
    custom?: number
  }
}

const modalsRoot = document.getElementById('root-modals') || document.body

const RatesModal = ({
  show,
  hide,
  cancel,
  rates,
  productionLines,
  addnewRates,
  loading = false,
}: Props) => {
  const intl = useIntl()

  const [tempRates, setTempRates] = useState<TempRateMap>({})

  const updateRates = (fieldsToUpdate: Partial<TempRateMap>) => {
    const fields = fieldsToUpdate as TempRateMap
    const updatedRates = {...tempRates, ...fields}
    setTempRates(updatedRates)
  }

  function onConfirm() {
    const newRates: ProductRate[] = []
    for (const [productionLine, rate] of Object.entries(tempRates)) {
      const productRate =
        (rate.choice === 'custom'
          ? rate.custom
          : rate.choice === 'average'
          ? rates?.find((rate) => rate.production_line === productionLine)
              ?.average_theoretical_product_rate
          : rates?.find((rate) => rate.production_line === productionLine)
              ?.optimal_theoretical_product_rate) ?? 0

      newRates.push({
        production_line: productionLine,
        product_rate: productRate,
      })
    }
    addnewRates(newRates)
    setTempRates({})
    hide()
  }

  useEffect(() => {
    if (rates === undefined || productionLines === undefined) return

    if (productionLines.length === 0) {
      hide()
      return
    }

    let newRates: TempRateMap = {}
    for (const productionLine of productionLines) {
      const rate = rates?.find((rate) => rate.production_line === productionLine._id)
      const ratesExist = rate?.average_theoretical_product_rate !== undefined
      newRates[`${productionLine._id}`] = {
        onlyCustom: !ratesExist,
        choice: ratesExist ? 'average' : 'custom',
      }
    }

    setTempRates(newRates)
  }, [rates, productionLines, show])

  return (
    <>
      <Modal
        id='hs_rates_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={show}
        onHide={cancel}
        backdrop='static'
      >
        <div className='modal-header'>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between align-items-center'>
              <h2>Add Unknown Rates</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={cancel}>
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>

            <div className='text-muted fw-semibold fs-5 mt-5'>
              {intl.formatMessage({id: 'TOOLTIP.PRODUCT_RATES'})}
            </div>
          </div>
        </div>

        <LoadingWrapper loading={loading || rates === undefined || productionLines === undefined}>
          <div className='modal-body m-5'>
            {/* loop tempRates */}
            {tempRates &&
              Object.entries(tempRates).map(([productionLine, rateInfo], index) => (
                <>
                  <label
                    className={`d-flex align-items-center fs-5 fw-semibold mb-2 ${
                      index !== 0 ? 'mt-10' : ''
                    }`}
                  >
                    {productionLines?.find((line) => line._id === productionLine)?.name!}
                  </label>
                  <div className='d-flex'>
                    <label className='form-check form-switch form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={rateInfo.choice === 'average'}
                        disabled={rateInfo.onlyCustom}
                        onChange={() => {
                          updateRates({
                            [productionLine]: {...tempRates[productionLine], choice: 'average'},
                          })
                        }}
                      />
                      <span className='form-check-label fw-semibold text-muted'>Average</span>
                    </label>
                    <label className='form-check form-switch form-check-custom form-check-solid me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={rateInfo.choice === 'optimal'}
                        disabled={rateInfo.onlyCustom}
                        onChange={() => {
                          updateRates({
                            [productionLine]: {...tempRates[productionLine], choice: 'optimal'},
                          })
                        }}
                      />
                      <span className='form-check-label fw-semibold text-muted'>Optimal</span>
                    </label>
                    <label className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={rateInfo.choice === 'custom'}
                        onChange={() => {
                          updateRates({
                            [productionLine]: {...tempRates[productionLine], choice: 'custom'},
                          })
                        }}
                      />
                      <span className='form-check-label fw-semibold text-muted'>Custom</span>
                    </label>
                  </div>

                  {rateInfo.choice === 'custom' && (
                    <input
                      placeholder='Custom Rate'
                      type='number'
                      className='form-control form-control-lg form-control-solid mt-3'
                      value={rateInfo.custom}
                      onChange={(e) => {
                        const inputValue = e.target.value
                        if (inputValue === '') {
                          updateRates({
                            [productionLine]: {...tempRates[productionLine], custom: undefined},
                          })
                          return
                        }

                        const parsedValue = parseFloat(inputValue)
                        if (isNaN(parsedValue)) return
                        updateRates({
                          [productionLine]: {...tempRates[productionLine], custom: parsedValue},
                        })
                      }}
                    />
                  )}
                </>
              ))}

            <div className='d-flex flex-stack pt-10'>
              <div />
              <div>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  disabled={
                    tempRates === undefined ||
                    Object.values(tempRates).some(
                      (rate) => rate.choice === 'custom' && rate.custom === undefined
                    )
                  }
                  onClick={onConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </LoadingWrapper>
      </Modal>
    </>
  )
}

export default RatesModal
