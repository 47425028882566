import React from 'react'
import {Widget as WidgetModel} from '../../../../core/_models'
import LineWidget from './LineWidget'
import OperationalStateWidget from './OperationalStateWidget'
import OperationalStatsWidget from './OperationalStatsWidget'
import ParetoWidget from './ParetoWidget'
import ProductionStatsWidget from './ProductionStatsWidget'
import TimelineWidget from './TimelineWidget'
import OeeWidget from './OeeWidget'
import OeePerformanceWidget from './OeePerformanceWidget'
import OeeAvailabilityWidget from './OeeAvailabilityWidget'
import OeeQualityWidget from './OeeQualityWidget'
import PowerConsumptionWidget from './PowerConsumptionWidget'
import EnergyConsumptionWidget from './EnergyConsumptionWidget'
import StandbyConsumptionWidget from './StandbyConsumptionWidget'
import PotentialSavingsWidget from './PotentialSavingsWidget'
import EmissionsWidget from './EmissionsWidget'
import PowerFactorWidget from './PowerFactorWidget'
import {CardWrapper} from '../../components/CardWrapper'
import TextWidget from './TextWidget'
import HealthWidget from './HealthWidget'
import AnomalyWidget from './AnomalyWidget'
import PieWidget from './PieWidget'
import StopRegistrationWidget from './StopRegistrationWidget'
import {WidgetTitle} from './WidgetTitle'
import WorkOrdersWidget from './WorkOrdersWidget'
import EnergyPiechartWidget from './EnergyPiechartWidget'
import EventLogsWidget from './EventLogsWidget'
import TagWidget from './TagWidget'

export type WidgetProps = {
  widget: WidgetModel
  height: string
  enableNameEdit?: boolean
}

const Widget = ({widget, height, enableNameEdit}: WidgetProps) => {
  const WidgetComponent = widgetComponentsMap[widget.type]

  const TitleComponent = <WidgetTitle widget={widget} enableNameEdit={enableNameEdit} />

  return (
    <CardWrapper titleComponent={TitleComponent}>
      {WidgetComponent ? (
        <WidgetComponent widget={widget} height={height} />
      ) : (
        <div className={`d-flex justify-content-center align-items-center h-100 text-danger`}>
          Unknown widget type {widget.type}
        </div>
      )}
    </CardWrapper>
  )
}

const areEqual = (prevProps: WidgetProps, nextProps: WidgetProps) =>
  JSON.stringify(prevProps.widget) === JSON.stringify(nextProps.widget) &&
  prevProps.height === nextProps.height &&
  prevProps.enableNameEdit === nextProps.enableNameEdit

const widgetComponentsMap: {[key: string]: React.FC<WidgetProps>} = {
  Pareto: ParetoWidget,
  Timeline: TimelineWidget,
  Line: LineWidget,
  OEE: OeeWidget,
  'OEE Performance': OeePerformanceWidget,
  'OEE Availability': OeeAvailabilityWidget,
  'OEE Quality': OeeQualityWidget,
  'Power Factor': PowerFactorWidget,
  'Power Consumption': PowerConsumptionWidget,
  'Energy Consumption': EnergyConsumptionWidget,
  'Standby Consumption': StandbyConsumptionWidget,
  'Potential Savings': PotentialSavingsWidget,
  'CO2 Emissions': EmissionsWidget,
  Text: TextWidget,
  Health: HealthWidget,
  Anomaly: AnomalyWidget,
  Pie: PieWidget,
  'Stop Registration': StopRegistrationWidget,
  'Operational State': OperationalStateWidget,
  'Operational Stats': OperationalStatsWidget,
  'Production Stats': ProductionStatsWidget,
  'Work Orders': WorkOrdersWidget,
  'Energy Piechart': EnergyPiechartWidget,
  'Event Logs': EventLogsWidget,
  Tag: TagWidget,
}

export default React.memo(Widget, areEqual)
