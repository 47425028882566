import React from 'react'
import {createContext, useContext, useMemo} from 'react'
import {ReferenceUUID} from '../core/_models'

type SelectedRowsContextProps = {
  selected: Array<string>
  onSelect: (selectedId: string) => void
  onSelectAll: () => void
  clearSelected: () => void
  allSelected: boolean

  expanded?: string
  onExpand: (id?: string) => void
  setDataItems: (items: any[]) => void
}

export const initialSelectedRows: SelectedRowsContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  allSelected: false,
  expanded: undefined,
  onExpand: () => {},
  setDataItems: (items: ReferenceUUID[]) => {},
}

const SelectedRowsContext = createContext<SelectedRowsContextProps>(initialSelectedRows)

type SelectedRowsProviderProps = {
  data?: ReferenceUUID[]
  idField?: string
  children: React.ReactNode
}

const calculateIsAllDataSelected = (data: ReferenceUUID[], selected: string[]): boolean => {
  if (!data) return false
  return data.length > 0 && data.length === selected.length
}

const SelectedRowsProvider = ({
  children,
  data = [],
  idField = '_id',
}: SelectedRowsProviderProps) => {
  const [items, setItems] = React.useState<ReferenceUUID[]>(data)
  const [selected, setSelected] = React.useState<Array<string>>(initialSelectedRows.selected)
  const allSelected = useMemo(() => calculateIsAllDataSelected(items, selected), [items, selected])

  const [expanded, setExpanded] = React.useState<string | undefined>(initialSelectedRows.expanded)

  const onSelect = (selectedId: string) => {
    if (selected.includes(selectedId)) {
      setSelected(selected.filter((id) => id !== selectedId))
    } else {
      setSelected([...selected, selectedId])
    }
  }

  const onSelectAll = () => {
    if (allSelected) {
      setSelected([])
    } else {
      setSelected(items.map((item) => item[idField]))
    }
  }

  const clearSelected = () => {
    setSelected([])
  }

  const onExpand = (id?: string) => {
    setExpanded(id)
  }

  return (
    <SelectedRowsContext.Provider
      value={{
        selected,
        allSelected,
        onSelect,
        onSelectAll,
        clearSelected,
        expanded,
        onExpand,
        setDataItems: setItems,
      }}
    >
      {children}
    </SelectedRowsContext.Provider>
  )
}

const useSelectedRows = () => useContext(SelectedRowsContext)

export {SelectedRowsProvider, useSelectedRows}
