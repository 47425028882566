import React from 'react'
import {Link, useParams} from 'react-router-dom'

interface OverviewModeSwitchProps {
  switchUrl: string
}

const OverviewModeSwitch = ({switchUrl}: OverviewModeSwitchProps) => {
  const {'*': splat} = useParams()
  const isGridView = splat?.split('/')[1] === 'grid'

  return (
    <div className='card-toolbar'>
      <ul className='nav' id='kt_chart_widget_8_tabs' role='tablist'>
        <li className='nav-item'>
          <Link
            to={switchUrl}
            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 ${
              isGridView ? '' : 'bg-white active'
            }`}
          >
            <i className='fa-solid fa-table' />
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            to={`${switchUrl}/grid`}
            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 ${
              isGridView ? 'bg-white active' : ''
            }`}
          >
            <i className='fa-solid fa-grip' />
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default OverviewModeSwitch
