import {useState} from 'react'
import {SendNotificationAction} from './actions'
import {useUsers} from '../../../core/requests/auth'
import Select from 'react-select'
import TextInputField from '../fields/TextInputField'
import TextAreaField from '../fields/TextAreaField'
import {useNotificationGroupsPaginated} from '../../../core/requests/notification'

type Props = {
  action: SendNotificationAction
  setAction: (action: SendNotificationAction) => void
  eventVariables?: string[]
  errorOccurred?: boolean
}

const SendNotification = ({action, setAction, eventVariables, errorOccurred}: Props) => {
  const [selectedTarget, setSelectedTarget] = useState<'user' | 'group'>('user')

  const {data: users} = useUsers()
  const {
    items: notificationGroups,
    isFetching: notificationGroupsFetching,
    fetchNextPage: notificationGroupsNextPage,
  } = useNotificationGroupsPaginated()

  return (
    <>
      <div className='fs-7 pt-3'>Target</div>
      <div className='btn-group w-100' style={{zIndex: 0}}>
        <button
          className={`btn btn-primary p-2 ${selectedTarget === 'user' ? 'active' : ''}`}
          onClick={() => {
            setSelectedTarget('user')
            setAction({
              ...action,
              form: {...action.form, user: undefined, notification_group: undefined},
            })
          }}
        >
          User
        </button>
        <button
          className={`btn btn-primary p-2 ${selectedTarget === 'group' ? 'active' : ''}`}
          onClick={() => {
            setSelectedTarget('group')
            setAction({
              ...action,
              form: {...action.form, user: undefined, notification_group: undefined},
            })
          }}
        >
          Group
        </button>
      </div>

      {selectedTarget === 'user' && (
        <>
          <div className='fs-7 pt-3'>User</div>
          <Select
            placeholder='Select user...'
            options={users?.items.map((user) => ({label: user.email, value: user._id}))}
            onChange={(selected) =>
              setAction({...action, form: {...action.form, user: selected?.value}})
            }
            isClearable
          />
        </>
      )}
      {selectedTarget === 'group' && (
        <>
          <div className='fs-7 pt-3'>Group</div>
          <Select
            placeholder='Select group...'
            options={notificationGroups?.map((group) => ({
              label: group.name,
              value: group._id,
            }))}
            onChange={(selected) =>
              setAction({...action, form: {...action.form, notification_group: selected?.value}})
            }
            isLoading={notificationGroupsFetching}
            isDisabled={notificationGroupsFetching}
            isClearable
            onMenuScrollToBottom={() => notificationGroupsNextPage && notificationGroupsNextPage()}
          />
        </>
      )}

      <div className='fs-7 pt-3'>Level</div>
      <Select
        value={{label: action.form.level?.toString(), value: action.form.level}}
        options={[
          {label: '0', value: 0},
          {label: '1', value: 1},
          {label: '2', value: 2},
          {label: '3', value: 3},
          {label: '4', value: 4},
        ]}
        onChange={(selected) =>
          setAction({...action, form: {...action.form, level: selected?.value}})
        }
      />

      <div className='fs-7 pt-3'>Channels*</div>
      <Select
        value={action.form.channels?.map((channel) => ({label: channel, value: channel}))}
        options={[
          {label: 'Email', value: 'Email'},
          {label: 'SMS', value: 'SMS'},
          {label: 'Push', value: 'Push'},
        ]}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor:
              errorOccurred && action.form.channels === undefined ? 'red' : baseStyles.borderColor,
          }),
        }}
        onChange={(selectedOptions) =>
          setAction({
            ...action,
            form: {
              ...action.form,
              require_click: selectedOptions.some((option) => option.value === 'Push')
                ? action.form.require_click
                : false,
              channels: selectedOptions.length
                ? selectedOptions.map((option) => option.value as 'Email' | 'SMS' | 'Push')
                : undefined,
            },
          })
        }
        isMulti
      />
      {errorOccurred && action.form.channels === undefined && (
        <div className='text-danger fs-8'>This field is required</div>
      )}

      {action.form.channels?.includes('Push') && (
        <>
          <div className='fs-7 pt-3'>Timeout (s)</div>
          <input
            type='number'
            className='form-control fw-normal text-dark p-3'
            style={{height: '38px'}}
            value={action.form.timeout}
            onChange={(e) => {
              const parsed = parseInt(e.target.value)
              setAction({
                ...action,
                form: {...action.form, timeout: isNaN(parsed) ? undefined : parsed},
              })
            }}
          />
          <div className='form-check pt-3'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={action.form.require_click}
              onChange={(e) =>
                setAction({...action, form: {...action.form, require_click: e.target.checked}})
              }
            />
            <label className='form-check-label'>Require Click</label>
          </div>
        </>
      )}

      <TextInputField
        name='Title'
        initial={action.form.title}
        placeholder='Default title will be used if left empty'
        onchange={(value) =>
          setAction({...action, form: {...action.form, title: value || undefined}})
        }
        variables={eventVariables}
        className='pt-3'
      />

      <TextAreaField
        name='Message'
        initial={action.form.message}
        placeholder='Default message will be used if left empty'
        onchange={(value) =>
          setAction({...action, form: {...action.form, message: value || undefined}})
        }
        variables={eventVariables}
        className='pt-3'
      />

      <TextAreaField
        name='Additional Message'
        initial={action.form.additional_message}
        placeholder='Optional: additional information'
        onchange={(value) =>
          setAction({...action, form: {...action.form, additional_message: value || undefined}})
        }
        variables={eventVariables}
        className='pt-3'
      />
    </>
  )
}

export default SendNotification
