import {UseQueryOptions, useQuery} from 'react-query'
import {
  usePaginatedInfiniteQuery,
  UsePaginatedInfiniteQueryOptions,
} from '../../components/pagination/usePaginatedInfiniteQuery'
import usePaginatedQuery from '../../components/pagination/usePaginatedQuery'
import {
  BaseModel,
  DeletionConfirmation,
  Invitation,
  Organization,
  OrganizationQueryProps,
  OrganizationRequestParams,
  PaginatedQueryProps,
  PaginatedRequestParams,
  PaginatedResponse,
  Profile,
  Role,
  User,
} from '../_models'
import axios from 'axios'
import {AuthTokenModel, UserCreateModel} from '../../modules/auth'

const AUTH_API_BASE_URL = process.env.REACT_APP_WA_AUTH_URL

// ----- USERS ----- //

const USERS_URL = `${AUTH_API_BASE_URL}/users`

export function createUser(userData: UserCreateModel) {
  return axios.post<User & BaseModel>(USERS_URL, userData)
}

interface UseUsersProps extends PaginatedQueryProps<User & BaseModel>, OrganizationQueryProps {
  organization?: string
}

export const useUsers = ({organization, page, size, options}: UseUsersProps = {}) =>
  useQuery<PaginatedResponse<User & BaseModel>, Error>(
    ['users', organization, page, size],
    async () =>
      (
        await axios.get<PaginatedResponse<User & BaseModel>>(USERS_URL, {
          params: {
            organization,
            page,
            size,
          },
        })
      ).data,
    options
  )

export type UpdateUserProps = {
  phoneNumber?: string
}

export async function updateUser(props: UpdateUserProps) {
  const {phoneNumber} = props
  const data = {phone_number: phoneNumber || null}
  const {data: user} = await axios.put<User & BaseModel>(USERS_URL, data)
  return user
}

export type changePasswordProps = {
  currentPassword: string
  newPassword: string
}

export async function changePassword(props: changePasswordProps) {
  const {currentPassword, newPassword} = props
  const data = {old_password: currentPassword, new_password: newPassword}
  const {data: user} = await axios.post<User>(`${USERS_URL}/change-password`, data)
  return user
}

type ResetPasswordProps = {
  email: string
}

export async function resetPassword(props: ResetPasswordProps) {
  const {email} = props
  const data = {email: email}
  const {data: status} = await axios.post<{status: string}>(`${USERS_URL}/reset-password`, data)
  return status
}

type ConfirmNewPasswordProps = {
  token: string
  newPassword: string
}

export async function confirmNewPassword(props: ConfirmNewPasswordProps) {
  const {token, newPassword} = props
  const data = {token: token, new_password: newPassword}
  const {data: status} = await axios.post<{status: string}>(
    `${USERS_URL}/reset-password/confirm`,
    data
  )
  return status
}

// ----- PROFILE ----- //

const PROFILE_URL = `${USERS_URL}/profile`

export async function getProfile() {
  const {data} = await axios.get<Profile>(PROFILE_URL)
  return data
}

export function useProfile({options}: {options?: UseQueryOptions<Profile, Error>} = {}) {
  return useQuery<Profile, Error>(
    'profile',
    async () => {
      const {data} = await axios.get<Profile>(PROFILE_URL)
      return data
    },
    options
  )
}

// ----- TOKEN ----- //

const TOKEN_URL = `${AUTH_API_BASE_URL}/token`

type CreateTokenProps = {
  email: string
  password: string
}

export async function createToken(props: CreateTokenProps) {
  const {email, password} = props
  const {data} = await axios.post<AuthTokenModel>(TOKEN_URL, {
    email,
    password,
  })
  return data
}

// ----- ORGANIZATIONS ----- //

const ORGANIZATIONS_URL = `${AUTH_API_BASE_URL}/organizations`

type UseOrganizationProps = PaginatedQueryProps<Organization>

type UseOrganizationRequestParams = PaginatedRequestParams

export const fetchOrganizations = async <T = Organization>(params?: UseOrganizationRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(ORGANIZATIONS_URL, {params})).data

export function useOrganizations(props: UseOrganizationProps = {}) {
  const {page, size, options} = props
  const params: UseOrganizationRequestParams = {
    page,
    size,
  }
  return useQuery<PaginatedResponse<Organization>, Error>(
    ['organizations', ...Object.values(params)],
    async () => await fetchOrganizations(params),
    options
  )
}

export const useOrganizationsPaginated = (
  params: UseOrganizationRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Organization, typeof fetchOrganizations>({
    queryKey: ['organizations', ...Object.values(params)],
    fetchFn: fetchOrganizations,
    params,
    enabled: options?.enabled,
  })

// ----- INVITATIONS ----- //

const INVITATIONS_URL = `${AUTH_API_BASE_URL}/invitations`

type UseInvitationsProps = PaginatedQueryProps<Invitation & BaseModel> & {
  organization?: string
  factory?: string
}

type UseInvitationsRequestParams = PaginatedRequestParams & {
  organization?: string
}

export function useInvitations(props: UseInvitationsProps = {}) {
  const {organization, page, size, options} = props
  const params: UseInvitationsRequestParams = {
    organization,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Invitation & BaseModel>, Error>(
    ['invitations', ...Object.values(params)],
    async () =>
      (await axios.get<PaginatedResponse<Invitation & BaseModel>>(INVITATIONS_URL, {params})).data,
    options
  )
}

export const useInvitationsPaginated = (props: UseInvitationsProps = {}) =>
  usePaginatedQuery<Invitation & BaseModel, typeof useInvitations>({
    useQuery: useInvitations,
    props,
  })

type CreateInvitationProps = {
  role: string
  email: string
}

export async function createInvitation(props: CreateInvitationProps) {
  const {role, email} = props
  const data = {role: role, user: email}
  const {data: invitation} = await axios.post<Invitation & BaseModel>(INVITATIONS_URL, data)
  return invitation
}

export async function deleteInvitation(_id: string) {
  const deleteURL = `${INVITATIONS_URL}/${_id}`
  const {data: invitation} = await axios.delete<DeletionConfirmation>(deleteURL)
  return invitation
}

// ----- ROLES ----- //

const ROLES_URL = `${AUTH_API_BASE_URL}/roles`

type UseRolesProps = PaginatedQueryProps<Role & BaseModel> & OrganizationQueryProps

type UseRolesRequestParams = PaginatedRequestParams & OrganizationRequestParams

export const fetchRoles = async <T = Role & BaseModel>(params?: UseRolesRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(ROLES_URL, {params})).data

export function useRoles(props: UseRolesProps = {}) {
  const {organization, page, size, options} = props
  const params: UseRolesRequestParams = {
    organization,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Role & BaseModel>, Error>(
    ['roles', ...Object.values(params)],
    async () => await fetchRoles(params),
    options
  )
}

export const useRolesPaginated = (
  params: UseRolesRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Role & BaseModel, typeof fetchRoles>({
    queryKey: ['roles', ...Object.values(params)],
    fetchFn: fetchRoles,
    params,
    enabled: options?.enabled,
  })
