import TextAreaField from '../fields/TextAreaField'
import TextInputField from '../fields/TextInputField'
import {AppendToLogAction} from './actions'

type Props = {
  action: AppendToLogAction
  setAction: (action: AppendToLogAction) => void
  eventVariables?: string[]
  errorOccurred?: boolean
}

const AppendToLog = ({action, setAction, eventVariables, errorOccurred}: Props) => {
  return (
    <>
      <TextInputField
        name='Title'
        initial={action.form.title}
        placeholder='Default title will be used if left empty'
        onchange={(value) =>
          setAction({...action, form: {...action.form, title: value || undefined}})
        }
        variables={eventVariables}
        className='pt-3'
      />

      <TextAreaField
        name='Message'
        initial={action.form.message}
        placeholder='Default message will be used if left empty'
        onchange={(value) =>
          setAction({...action, form: {...action.form, message: value || undefined}})
        }
        variables={eventVariables}
        className='pt-3'
      />

      <TextAreaField
        name='Additional Message'
        initial={action.form.additional_message}
        placeholder='Optional: additional information'
        onchange={(value) =>
          setAction({...action, form: {...action.form, additional_message: value || undefined}})
        }
        variables={eventVariables}
        className='pt-3'
      />
    </>
  )
}

export default AppendToLog
