import webpack from 'webpack'
import usePagination, {UsePaginationProps} from '../../../../components/pagination/usePagination'
import {Area, Asset, Organization, Part, ProductionLine, Section} from '../../../../core/_models'
import {UsePlacementsProps} from '../../../../core/requests/factory'
import {useFilterState} from './useFilterState'

const usePlacementsFilter = (props: UsePaginationProps = {}) => {
  const {activePage, setActivePage, pageSize} = usePagination({
    initialPage: props.initialPage,
    size: props.size,
  })

  const filterState = {
    organization: useFilterState<Organization>(),
    asset: useFilterState<Asset>(),
    area: useFilterState<Area>(),
    productionLine: useFilterState<ProductionLine>(),
    section: useFilterState<Section>(),
    part: useFilterState<Part>(),
  }

  const placementFilterProps: UsePlacementsProps = {
    organization: filterState.organization.value?._id,
    asset: filterState.asset.value?._id,
    area: filterState.area.value?._id,
    productionLine: filterState.productionLine.value?._id,
    section: filterState.section.value?._id,
    part: filterState.part.value?._id,
    page: activePage,
    size: pageSize,
    options: {
      keepPreviousData: true,
    },
  }

  return {
    filterState,
    filterCount: Object.values(filterState).filter((v) => v.value !== undefined).length,
    placementFilterProps,
    activePage,
    setActivePage,
    pageSize,
  }
}

export default usePlacementsFilter
