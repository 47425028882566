export const brandingProfiles = ['Hexastate', 'MaintMaster', 'AI_America'] as const
export type BrandingProfile = typeof brandingProfiles[number]

export const activeBrandingProfile = process.env.REACT_APP_CUSTOM_BRANDING as BrandingProfile
const resourcePath = `/media/branding-profiles/${activeBrandingProfile}`

const imagePaths: Record<
  BrandingProfile,
  {
    logo: string
    logoHorizontal: string
    icon: string
  }
> = {
  Hexastate: {
    logo: `${resourcePath}/logo.png`,
    logoHorizontal: `${resourcePath}/logo.png`,
    icon: `${resourcePath}/icon.png`,
  },
  MaintMaster: {
    logo: `${resourcePath}/logo.svg`,
    logoHorizontal: `${resourcePath}/logo.svg`,
    icon: `${resourcePath}/icon.png`,
  },
  AI_America: {
    logo: `${resourcePath}/logo.png`,
    logoHorizontal: `${resourcePath}/logo-horizontal.png`,
    icon: `${resourcePath}/icon.png`,
  },
}

export const logoPath = imagePaths[activeBrandingProfile].logo
export const logoHorizontalPath = imagePaths[activeBrandingProfile].logoHorizontal
export const iconPath = imagePaths[activeBrandingProfile].icon
