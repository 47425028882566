import React from 'react'
import {CopyClipboardText} from '../../components/content/CopyClipboardText'
import EntityInlineTextFieldForm from '../../components/form-components/EntityInlineTextFieldForm'
import {Area} from '../../core/_models'
import {deleteArea, useUpdateAreaMutation} from '../../core/requests/factory'
import {NameImageCell} from '../../pages/overview/core/columns/ImageStringCell'
import useEntityDeleteModal from '../entity-delete/useEntityDeleteModal'
import OverviewDetailsModal, {BaseOverviewDetailsModalProps} from './OverviewDetailsModal'

const AreaOverviewDetailsModal = ({
  entity,
  onHide,
  show = true,
}: BaseOverviewDetailsModalProps<Area>) => {
  const deleteModal = useEntityDeleteModal({
    entity,
    deleteHandler: deleteArea,
    queryToInvalidate: 'areas',
  })
  return (
    <OverviewDetailsModal
      show={show}
      onHide={onHide}
      title={
        <>
          <span className={`text-nowrap`}>Area Details</span> ・
          <EntityInlineTextFieldForm
            data={entity}
            dataField={'name'}
            useMutation={useUpdateAreaMutation}
            queryToInvalidate={'areas'}
          />
          {deleteModal.handler}
        </>
      }
      objectId={entity._id}
      attributes={[
        ['ID', <CopyClipboardText text={entity._id} />],
        [
          'Gallery',
          <NameImageCell
            index={1}
            str={''}
            images={
              entity.gallery
                ? [entity.gallery.profile_image, ...(entity.gallery.images ?? [])]
                : undefined
            }
            entityId={entity._id}
            queryToInvalidate='areas'
          />,
        ],
        ['Organization Name', entity.organization.name],
        ['Factory Name', entity.factory.name],
        ['Name', entity.name],
      ]}
    />
  )
}

export {AreaOverviewDetailsModal}
