/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>}
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never}
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string}
  String: {input: string; output: string}
  Boolean: {input: boolean; output: boolean}
  Int: {input: number; output: number}
  Float: {input: number; output: number}
  /** Date with time (isoformat) */
  DateTime: {input: any; output: any}
  /** Represents NULL values */
  Void: {input: any; output: any}
  _Any: {input: any; output: any}
}

export type CategoryConsumption = {
  __typename?: 'CategoryConsumption'
  /** The total energy consumption for the category measured in kWh */
  consumption: Scalars['Float']['output']
  /** The operational state of the production line */
  state: OperationalStates
  /** The total time the production line spent in this operational state measured in seconds */
  time: Scalars['Int']['output']
}

export type Checklist = {
  __typename?: 'Checklist'
  actions: Array<Scalars['String']['output']>
  partType: PartTypes
  placement: Scalars['String']['output']
  potentialFaults: Array<Scalars['String']['output']>
}

export type ConsumptionByCategory = {
  __typename?: 'ConsumptionByCategory'
  /** A mapping of operational states to their corresponding energy consumption and time spent */
  consumptions: Array<CategoryConsumption>
}

export type ConsumptionType = {
  __typename?: 'ConsumptionType'
  costs: Scalars['Float']['output']
  currency: Currencies
  emissions: Scalars['Float']['output']
  energy: Scalars['Float']['output']
  power: Array<TimeSeriesPoint>
}

export type CreateDashboardInput = {
  description?: InputMaybe<Scalars['String']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type CreateFolderInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  parentFolder?: InputMaybe<Scalars['String']['input']>
}

export enum Currencies {
  Aud = 'AUD',
  Cad = 'CAD',
  Chf = 'CHF',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Isk = 'ISK',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pln = 'PLN',
  Sek = 'SEK',
  Usd = 'USD',
}

/** A dashboard that contains widgets. */
export type Dashboard = {
  __typename?: 'Dashboard'
  /** Description of the dashboard. */
  description?: Maybe<Scalars['String']['output']>
  /** ID of the folder the dashboard belongs to. */
  folder?: Maybe<Scalars['String']['output']>
  /** Unique identifier for the dashboard. */
  id: Scalars['ID']['output']
  /** High-level property defining whether the dashboard is shared with anyone else. */
  isShared: Scalars['Boolean']['output']
  /** Name of the dashboard. */
  name: Scalars['String']['output']
  /** ID of the user that owns the dashboard. */
  owner: Scalars['String']['output']
  /** List of user IDs the dashboard is shared with. */
  sharedWith: Array<Scalars['String']['output']>
  /** List of widgets the dashboard contains. */
  widgets?: Maybe<Array<Widget>>
}

export type DashboardMutations = {
  __typename?: 'DashboardMutations'
  createDashboard: Dashboard
  deleteDashboard?: Maybe<Scalars['Void']['output']>
  shareDashboard: Dashboard
  unshareDashboard: Dashboard
  updateDashboard: Dashboard
}

export type DashboardMutationsCreateDashboardArgs = {
  input: CreateDashboardInput
}

export type DashboardMutationsDeleteDashboardArgs = {
  input: DeleteDashboardInput
}

export type DashboardMutationsShareDashboardArgs = {
  input: ShareDashboardInput
}

export type DashboardMutationsUnshareDashboardArgs = {
  input: UnshareDashboardInput
}

export type DashboardMutationsUpdateDashboardArgs = {
  input: UpdateDashboardInput
}

export type DeleteDashboardInput = {
  id: Scalars['ID']['input']
}

export type DeleteFolderInput = {
  deleteDashboards?: Scalars['Boolean']['input']
  id: Scalars['ID']['input']
}

export type Diagnosis = {
  __typename?: 'Diagnosis'
  faults: Array<Fault>
  id: Scalars['ID']['output']
  time: Scalars['Int']['output']
}

export type Fault = {
  __typename?: 'Fault'
  increase?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  possibility?: Maybe<Scalars['Float']['output']>
  probability?: Maybe<Scalars['Float']['output']>
}

/** A folder that contains dashboards. */
export type Folder = {
  __typename?: 'Folder'
  /** List of dashboards the folder contains. */
  dashboards: Array<Dashboard>
  /** Description of the folder. */
  description?: Maybe<Scalars['String']['output']>
  /** The hierarchy this folder is in sorted with the root folder last. */
  hierarchy: Array<FolderHierarchy>
  /** Unique identifier for the folder. */
  id: Scalars['ID']['output']
  /** High-level property defining whether the folder is shared with anyone else. */
  isShared: Scalars['Boolean']['output']
  /** Name of the folder. */
  name: Scalars['String']['output']
  /** ID of the user that owns the folder. */
  owner: Scalars['String']['output']
  /** ID of the parent folder if any. */
  parentFolder?: Maybe<Scalars['String']['output']>
  /** List of user IDs the folder is shared with. */
  sharedWith: Array<Scalars['String']['output']>
}

/** A folder hierarchy. */
export type FolderHierarchy = {
  __typename?: 'FolderHierarchy'
  /** Unique identifier for the folder. */
  id: Scalars['ID']['output']
  /** Name of the folder. */
  name: Scalars['String']['output']
  /** Order of the folder in the hierarchy. */
  order: Scalars['Int']['output']
}

export type FolderMutations = {
  __typename?: 'FolderMutations'
  createFolder: Folder
  deleteFolder?: Maybe<Scalars['Void']['output']>
  shareFolder: Folder
  unshareFolder: Folder
  updateFolder: Folder
}

export type FolderMutationsCreateFolderArgs = {
  input: CreateFolderInput
}

export type FolderMutationsDeleteFolderArgs = {
  input: DeleteFolderInput
}

export type FolderMutationsShareFolderArgs = {
  input: ShareFolderInput
}

export type FolderMutationsUnshareFolderArgs = {
  input: UnshareFolderInput
}

export type FolderMutationsUpdateFolderArgs = {
  input: UpdateFolderInput
}

export type Health = {
  __typename?: 'Health'
  firstStateAt?: Maybe<Scalars['Int']['output']>
  id: Scalars['String']['output']
  lastStateAt?: Maybe<Scalars['Int']['output']>
  lastStateChangeAt?: Maybe<Scalars['Int']['output']>
  title: MachineStatuses
}

export type HealthCount = {
  __typename?: 'HealthCount'
  count: Scalars['Int']['output']
  title: Scalars['String']['output']
}

export type HealthPoint = {
  __typename?: 'HealthPoint'
  time: Scalars['Int']['output']
  value: Scalars['Int']['output']
}

export enum MachineStatuses {
  Calibrating = 'CALIBRATING',
  Good = 'GOOD',
  Offline = 'OFFLINE',
  Satisfactory = 'SATISFACTORY',
  Unacceptable = 'UNACCEPTABLE',
  Unsatisfactory = 'UNSATISFACTORY',
}

export type Mutation = {
  __typename?: 'Mutation'
  configureMachineProfile: Scalars['String']['output']
  createCustomPowerPrice: Scalars['String']['output']
  dashboards: DashboardMutations
  folders: FolderMutations
}

export type MutationConfigureMachineProfileArgs = {
  id: Scalars['ID']['input']
}

export enum OperationalStates {
  Running = 'RUNNING',
  Shutdown = 'SHUTDOWN',
  Standby = 'STANDBY',
}

export enum PartTypes {
  AcMotor = 'AC_MOTOR',
  BeltDrive = 'BELT_DRIVE',
  CentrifugalPump = 'CENTRIFUGAL_PUMP',
  Compressor = 'COMPRESSOR',
  Coupling = 'COUPLING',
  DcMotor = 'DC_MOTOR',
  EcMotor = 'EC_MOTOR',
  Gear = 'GEAR',
  PositiveDisplacementPump = 'POSITIVE_DISPLACEMENT_PUMP',
  RollingElementBearing = 'ROLLING_ELEMENT_BEARING',
  Shaft = 'SHAFT',
}

export type Position = {
  __typename?: 'Position'
  h: Scalars['Float']['output']
  i: Scalars['String']['output']
  isBounded?: Maybe<Scalars['Boolean']['output']>
  isDraggable?: Maybe<Scalars['Boolean']['output']>
  isResizable?: Maybe<Scalars['Boolean']['output']>
  maxH?: Maybe<Scalars['Float']['output']>
  maxW?: Maybe<Scalars['Float']['output']>
  minH?: Maybe<Scalars['Float']['output']>
  minW?: Maybe<Scalars['Float']['output']>
  moved?: Maybe<Scalars['Boolean']['output']>
  resizeHandles?: Maybe<Array<ResizeHandles>>
  static?: Maybe<Scalars['Boolean']['output']>
  w: Scalars['Float']['output']
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
}

export type Query = {
  __typename?: 'Query'
  _service: _Service
  advancedHealthHistory: Array<HealthPoint>
  anomalyDetectionHistory: Array<TimeSeriesPoint>
  areaHealthDistribution: Array<HealthCount>
  checklist: Array<Checklist>
  diagnosis?: Maybe<Array<Diagnosis>>
  getAssetStates: Array<Health>
  getDashboard: Dashboard
  /** Get a folder by ID. */
  getFolder: Folder
  /** Get all dashboards that belong to the user's organization, and that the user can choose to access. */
  getOrganizationDashboards: Array<Dashboard>
  /** Get all folders that belong to the user's organization, and that the user can choose to access. */
  getOrganizationFolders: Array<Folder>
  getPlacementPowerConsumptionHistory: Array<TimeSeriesPoint>
  getPlacementPowerFactor: Array<TimeSeriesPoint>
  getPlacementStandbyConsumption: ConsumptionType
  getPlacementTotalConsumption: ConsumptionType
  getProductionLineConsumptionByCategory: ConsumptionByCategory
  getProductionLinePowerConsumptionHistory: Array<TimeSeriesPoint>
  getProductionLinePowerFactor: Array<TimeSeriesPoint>
  getProductionLineStandbyConsumption: ConsumptionType
  getProductionLineTotalConsumption: ConsumptionType
  getSectionPowerConsumptionHistory: Array<TimeSeriesPoint>
  getSectionPowerFactor: Array<TimeSeriesPoint>
  getSectionStandbyConsumption: ConsumptionType
  getSectionTotalConsumption: ConsumptionType
  /** Get all dashboards where the user is the owner or is being directly shared with. */
  getUserDashboards: Array<Dashboard>
  /** Get all folders where the user is the owner or is being directly shared with. */
  getUserFolders: Array<Folder>
  health: Health
  simpleHealthHistory: Array<HealthPoint>
}

export type QueryAdvancedHealthHistoryArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['String']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryAnomalyDetectionHistoryArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['String']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryAreaHealthDistributionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  statuses?: InputMaybe<Array<MachineStatuses>>
}

export type QueryChecklistArgs = {
  id: Scalars['ID']['input']
}

export type QueryDiagnosisArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetAssetStatesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryGetDashboardArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetFolderArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetPlacementPowerConsumptionHistoryArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetPlacementPowerFactorArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetPlacementStandbyConsumptionArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetPlacementTotalConsumptionArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetProductionLineConsumptionByCategoryArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  masterPlacement: Scalars['String']['input']
  operationalIntervalMaxs: Array<Scalars['Float']['input']>
  operationalIntervalMins: Array<Scalars['Float']['input']>
  operationalStates: Array<OperationalStates>
  placements: Array<Scalars['ID']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryGetProductionLinePowerConsumptionHistoryArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetProductionLinePowerFactorArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetProductionLineStandbyConsumptionArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetProductionLineTotalConsumptionArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetSectionPowerConsumptionHistoryArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetSectionPowerFactorArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetSectionStandbyConsumptionArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetSectionTotalConsumptionArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['ID']['input']
  multiplier?: Scalars['Int']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
}

export type QueryGetUserDashboardsArgs = {
  folder?: InputMaybe<Scalars['String']['input']>
  standalone?: Scalars['Boolean']['input']
}

export type QueryGetUserFoldersArgs = {
  parentFolder?: InputMaybe<Scalars['String']['input']>
}

export type QueryHealthArgs = {
  id: Scalars['ID']['input']
}

export type QuerySimpleHealthHistoryArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['String']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
}

export enum ResizeHandles {
  E = 'E',
  N = 'N',
  Ne = 'NE',
  Nw = 'NW',
  S = 'S',
  Se = 'SE',
  Sw = 'SW',
  W = 'W',
}

export type ShareDashboardInput = {
  id: Scalars['ID']['input']
  permission?: SharingPermissions
  shareWithUsers: Array<Scalars['String']['input']>
}

export type ShareFolderInput = {
  id: Scalars['ID']['input']
  permission?: SharingPermissions
  shareWithUsers: Array<Scalars['String']['input']>
}

export enum SharingPermissions {
  Edit = 'EDIT',
  Manage = 'MANAGE',
  ReadOnly = 'READ_ONLY',
}

export type Subscription = {
  __typename?: 'Subscription'
  dashboardUpdates: Scalars['Int']['output']
  healthUpdates: Scalars['Int']['output']
  powerConsumptionUpdates: Scalars['Int']['output']
}

export type SubscriptionDashboardUpdatesArgs = {
  id: Scalars['String']['input']
}

export type SubscriptionHealthUpdatesArgs = {
  id: Scalars['String']['input']
}

export type SubscriptionPowerConsumptionUpdatesArgs = {
  id: Scalars['String']['input']
}

export type TimeSeriesPoint = {
  __typename?: 'TimeSeriesPoint'
  time: Scalars['Int']['output']
  value: Scalars['Float']['output']
}

export enum Timespan {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type UnshareDashboardInput = {
  id: Scalars['ID']['input']
  users?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UnshareFolderInput = {
  id: Scalars['ID']['input']
  users?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UpdateDashboardInput = {
  description?: InputMaybe<Scalars['String']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateFolderInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  parentFolder?: InputMaybe<Scalars['String']['input']>
}

export type Widget = {
  __typename?: 'Widget'
  description?: Maybe<Scalars['String']['output']>
  flow: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  position: Position
}

export type _Service = {
  __typename?: '_Service'
  sdl: Scalars['String']['output']
}

export type AdvancedHealthHistoryQueryVariables = Exact<{
  id: Scalars['String']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
}>

export type AdvancedHealthHistoryQuery = {
  __typename?: 'Query'
  advancedHealthHistory: Array<{__typename?: 'HealthPoint'; time: number; value: number}>
}

export type AnomalyDetectionHistoryQueryVariables = Exact<{
  id: Scalars['String']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
}>

export type AnomalyDetectionHistoryQuery = {
  __typename?: 'Query'
  anomalyDetectionHistory: Array<{__typename?: 'TimeSeriesPoint'; time: number; value: number}>
}

export type AreaHealthDistributionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>
  statuses?: InputMaybe<Array<MachineStatuses> | MachineStatuses>
}>

export type AreaHealthDistributionQuery = {
  __typename?: 'Query'
  areaHealthDistribution: Array<{__typename?: 'HealthCount'; count: number; title: string}>
}

export type ChecklistQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ChecklistQuery = {
  __typename?: 'Query'
  checklist: Array<{
    __typename?: 'Checklist'
    partType: PartTypes
    placement: string
    potentialFaults: Array<string>
    actions: Array<string>
  }>
}

export type GetAssetStatesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type GetAssetStatesQuery = {
  __typename?: 'Query'
  getAssetStates: Array<{
    __typename?: 'Health'
    id: string
    firstStateAt?: number | null
    lastStateAt?: number | null
    lastStateChangeAt?: number | null
    title: MachineStatuses
  }>
}

export type SimpleHealthHistoryQueryVariables = Exact<{
  id: Scalars['String']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
}>

export type SimpleHealthHistoryQuery = {
  __typename?: 'Query'
  simpleHealthHistory: Array<{__typename?: 'HealthPoint'; time: number; value: number}>
}

export type FolderItemFragment = {
  __typename?: 'Folder'
  id: string
  name: string
  description?: string | null
  parentFolder?: string | null
  isShared: boolean
} & {' $fragmentName'?: 'FolderItemFragment'}

export type CreateDashboardMutationVariables = Exact<{
  input: CreateDashboardInput
}>

export type CreateDashboardMutation = {
  __typename?: 'Mutation'
  dashboards: {
    __typename?: 'DashboardMutations'
    createDashboard: {
      __typename?: 'Dashboard'
      id: string
      folder?: string | null
      name: string
      description?: string | null
    }
  }
}

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInput
}>

export type CreateFolderMutation = {
  __typename?: 'Mutation'
  folders: {
    __typename?: 'FolderMutations'
    createFolder: {__typename?: 'Folder'; id: string; name: string; parentFolder?: string | null}
  }
}

export type DeleteDashboardMutationVariables = Exact<{
  input: DeleteDashboardInput
}>

export type DeleteDashboardMutation = {
  __typename?: 'Mutation'
  dashboards: {__typename?: 'DashboardMutations'; deleteDashboard?: any | null}
}

export type DeleteFolderMutationVariables = Exact<{
  input: DeleteFolderInput
}>

export type DeleteFolderMutation = {
  __typename?: 'Mutation'
  folders: {__typename?: 'FolderMutations'; deleteFolder?: any | null}
}

export type UpdateDashboardMutationVariables = Exact<{
  input: UpdateDashboardInput
}>

export type UpdateDashboardMutation = {
  __typename?: 'Mutation'
  dashboards: {
    __typename?: 'DashboardMutations'
    updateDashboard: {
      __typename?: 'Dashboard'
      description?: string | null
      folder?: string | null
      id: string
      name: string
    }
  }
}

export type UpdateFolderMutationVariables = Exact<{
  input: UpdateFolderInput
}>

export type UpdateFolderMutation = {
  __typename?: 'Mutation'
  folders: {
    __typename?: 'FolderMutations'
    updateFolder: {
      __typename?: 'Folder'
      id: string
      name: string
      description?: string | null
      parentFolder?: string | null
    }
  }
}

export type GetFolderQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetFolderQuery = {
  __typename?: 'Query'
  getFolder: {
    __typename?: 'Folder'
    id: string
    parentFolder?: string | null
    name: string
    description?: string | null
    isShared: boolean
    hierarchy: Array<{__typename?: 'FolderHierarchy'; id: string; name: string; order: number}>
  }
}

export type GetUserDashboardsQueryVariables = Exact<{
  folder?: InputMaybe<Scalars['String']['input']>
}>

export type GetUserDashboardsQuery = {
  __typename?: 'Query'
  getUserDashboards: Array<{
    __typename?: 'Dashboard'
    id: string
    folder?: string | null
    name: string
    description?: string | null
    isShared: boolean
  }>
}

export type GetUserFoldersQueryVariables = Exact<{
  parentFolder?: InputMaybe<Scalars['String']['input']>
}>

export type GetUserFoldersQuery = {
  __typename?: 'Query'
  getUserFolders: Array<{
    __typename?: 'Folder'
    id: string
    parentFolder?: string | null
    name: string
    description?: string | null
    isShared: boolean
  }>
}

export type GetPlacementPowerConsumptionHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
  multiplier: Scalars['Int']['input']
}>

export type GetPlacementPowerConsumptionHistoryQuery = {
  __typename?: 'Query'
  getPlacementPowerConsumptionHistory: Array<{
    __typename?: 'TimeSeriesPoint'
    time: number
    value: number
  }>
}

export type GetProductionLinePowerConsumptionHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
  multiplier: Scalars['Int']['input']
}>

export type GetProductionLinePowerConsumptionHistoryQuery = {
  __typename?: 'Query'
  getProductionLinePowerConsumptionHistory: Array<{
    __typename?: 'TimeSeriesPoint'
    time: number
    value: number
  }>
}

export type GetSectionPowerConsumptionHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
  multiplier: Scalars['Int']['input']
}>

export type GetSectionPowerConsumptionHistoryQuery = {
  __typename?: 'Query'
  getSectionPowerConsumptionHistory: Array<{
    __typename?: 'TimeSeriesPoint'
    time: number
    value: number
  }>
}

export type GetProductionLineConsumptionByCategoryQueryVariables = Exact<{
  id: Scalars['ID']['input']
  masterPlacement: Scalars['String']['input']
  placements: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  operationalStates: Array<OperationalStates> | OperationalStates
  operationalIntervalMins: Array<Scalars['Float']['input']> | Scalars['Float']['input']
  operationalIntervalMaxs: Array<Scalars['Float']['input']> | Scalars['Float']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
}>

export type GetProductionLineConsumptionByCategoryQuery = {
  __typename?: 'Query'
  getProductionLineConsumptionByCategory: {
    __typename?: 'ConsumptionByCategory'
    consumptions: Array<{
      __typename?: 'CategoryConsumption'
      consumption: number
      state: OperationalStates
      time: number
    }>
  }
}

export type GetPlacementStandbyConsumptionQueryVariables = Exact<{
  id: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
  multiplier: Scalars['Int']['input']
}>

export type GetPlacementStandbyConsumptionQuery = {
  __typename?: 'Query'
  getPlacementStandbyConsumption: {
    __typename?: 'ConsumptionType'
    costs: number
    currency: Currencies
    emissions: number
    energy: number
    power: Array<{__typename?: 'TimeSeriesPoint'; time: number; value: number}>
  }
}

export type GetProductionLineStandbyConsumptionQueryVariables = Exact<{
  id: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
  multiplier: Scalars['Int']['input']
}>

export type GetProductionLineStandbyConsumptionQuery = {
  __typename?: 'Query'
  getProductionLineStandbyConsumption: {
    __typename?: 'ConsumptionType'
    costs: number
    currency: Currencies
    emissions: number
    energy: number
    power: Array<{__typename?: 'TimeSeriesPoint'; time: number; value: number}>
  }
}

export type GetSectionStandbyConsumptionQueryVariables = Exact<{
  id: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
  multiplier: Scalars['Int']['input']
}>

export type GetSectionStandbyConsumptionQuery = {
  __typename?: 'Query'
  getSectionStandbyConsumption: {
    __typename?: 'ConsumptionType'
    costs: number
    currency: Currencies
    emissions: number
    energy: number
    power: Array<{__typename?: 'TimeSeriesPoint'; time: number; value: number}>
  }
}

export type GetPlacementTotalConsumptionQueryVariables = Exact<{
  id: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
  multiplier: Scalars['Int']['input']
}>

export type GetPlacementTotalConsumptionQuery = {
  __typename?: 'Query'
  getPlacementTotalConsumption: {
    __typename?: 'ConsumptionType'
    costs: number
    currency: Currencies
    emissions: number
    energy: number
    power: Array<{__typename?: 'TimeSeriesPoint'; time: number; value: number}>
  }
}

export type GetProductionLineTotalConsumptionQueryVariables = Exact<{
  id: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
  multiplier: Scalars['Int']['input']
}>

export type GetProductionLineTotalConsumptionQuery = {
  __typename?: 'Query'
  getProductionLineTotalConsumption: {
    __typename?: 'ConsumptionType'
    costs: number
    currency: Currencies
    emissions: number
    energy: number
    power: Array<{__typename?: 'TimeSeriesPoint'; time: number; value: number}>
  }
}

export type GetSectionTotalConsumptionQueryVariables = Exact<{
  id: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  timespan?: InputMaybe<Timespan>
  multiplier: Scalars['Int']['input']
}>

export type GetSectionTotalConsumptionQuery = {
  __typename?: 'Query'
  getSectionTotalConsumption: {
    __typename?: 'ConsumptionType'
    costs: number
    currency: Currencies
    emissions: number
    energy: number
    power: Array<{__typename?: 'TimeSeriesPoint'; time: number; value: number}>
  }
}

export const FolderItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'FolderItem'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Folder'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'parentFolder'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isShared'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<FolderItemFragment, unknown>
export const AdvancedHealthHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AdvancedHealthHistory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'advancedHealthHistory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdvancedHealthHistoryQuery, AdvancedHealthHistoryQueryVariables>
export const AnomalyDetectionHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AnomalyDetectionHistory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'anomalyDetectionHistory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AnomalyDetectionHistoryQuery, AnomalyDetectionHistoryQueryVariables>
export const AreaHealthDistributionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'AreaHealthDistribution'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'statuses'}},
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'MachineStatuses'}},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'areaHealthDistribution'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'statuses'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'statuses'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                {kind: 'Field', name: {kind: 'Name', value: 'title'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AreaHealthDistributionQuery, AreaHealthDistributionQueryVariables>
export const ChecklistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'Checklist'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'checklist'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'partType'}},
                {kind: 'Field', name: {kind: 'Name', value: 'placement'}},
                {kind: 'Field', name: {kind: 'Name', value: 'potentialFaults'}},
                {kind: 'Field', name: {kind: 'Name', value: 'actions'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChecklistQuery, ChecklistQueryVariables>
export const GetAssetStatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetAssetStates'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getAssetStates'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'ids'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'ids'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'firstStateAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'lastStateAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'lastStateChangeAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'title'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssetStatesQuery, GetAssetStatesQueryVariables>
export const SimpleHealthHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'SimpleHealthHistory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'simpleHealthHistory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SimpleHealthHistoryQuery, SimpleHealthHistoryQueryVariables>
export const CreateDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CreateDashboard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'CreateDashboardInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboards'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'createDashboard'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'input'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'folder'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDashboardMutation, CreateDashboardMutationVariables>
export const CreateFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CreateFolder'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'CreateFolderInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'folders'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'createFolder'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'input'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'parentFolder'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFolderMutation, CreateFolderMutationVariables>
export const DeleteDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'DeleteDashboard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DeleteDashboardInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboards'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'deleteDashboard'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'input'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDashboardMutation, DeleteDashboardMutationVariables>
export const DeleteFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'DeleteFolder'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DeleteFolderInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'folders'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'deleteFolder'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'input'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFolderMutation, DeleteFolderMutationVariables>
export const UpdateDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UpdateDashboard'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UpdateDashboardInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboards'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'updateDashboard'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'input'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'folder'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDashboardMutation, UpdateDashboardMutationVariables>
export const UpdateFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UpdateFolder'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UpdateFolderInput'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'folders'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'updateFolder'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'input'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'parentFolder'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFolderMutation, UpdateFolderMutationVariables>
export const GetFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetFolder'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getFolder'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'parentFolder'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'isShared'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'hierarchy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'order'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFolderQuery, GetFolderQueryVariables>
export const GetUserDashboardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetUserDashboards'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'folder'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getUserDashboards'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'folder'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'folder'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'folder'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'isShared'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserDashboardsQuery, GetUserDashboardsQueryVariables>
export const GetUserFoldersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetUserFolders'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'parentFolder'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getUserFolders'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'parentFolder'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'parentFolder'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'parentFolder'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'isShared'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserFoldersQuery, GetUserFoldersQueryVariables>
export const GetPlacementPowerConsumptionHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetPlacementPowerConsumptionHistory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timespan'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getPlacementPowerConsumptionHistory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timespan'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'multiplier'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlacementPowerConsumptionHistoryQuery,
  GetPlacementPowerConsumptionHistoryQueryVariables
>
export const GetProductionLinePowerConsumptionHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetProductionLinePowerConsumptionHistory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timespan'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getProductionLinePowerConsumptionHistory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timespan'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'multiplier'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductionLinePowerConsumptionHistoryQuery,
  GetProductionLinePowerConsumptionHistoryQueryVariables
>
export const GetSectionPowerConsumptionHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetSectionPowerConsumptionHistory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timespan'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getSectionPowerConsumptionHistory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timespan'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'multiplier'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSectionPowerConsumptionHistoryQuery,
  GetSectionPowerConsumptionHistoryQueryVariables
>
export const GetProductionLineConsumptionByCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetProductionLineConsumptionByCategory'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'masterPlacement'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'placements'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'operationalStates'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'OperationalStates'}},
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'operationalIntervalMins'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'operationalIntervalMaxs'}},
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getProductionLineConsumptionByCategory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'masterPlacement'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'masterPlacement'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'placements'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'placements'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'operationalStates'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'operationalStates'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'operationalIntervalMins'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'operationalIntervalMins'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'operationalIntervalMaxs'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'operationalIntervalMaxs'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'consumptions'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'consumption'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductionLineConsumptionByCategoryQuery,
  GetProductionLineConsumptionByCategoryQueryVariables
>
export const GetPlacementStandbyConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetPlacementStandbyConsumption'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timespan'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getPlacementStandbyConsumption'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timespan'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'multiplier'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'costs'}},
                {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'emissions'}},
                {kind: 'Field', name: {kind: 'Name', value: 'energy'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'power'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'value'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlacementStandbyConsumptionQuery,
  GetPlacementStandbyConsumptionQueryVariables
>
export const GetProductionLineStandbyConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetProductionLineStandbyConsumption'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timespan'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getProductionLineStandbyConsumption'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timespan'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'multiplier'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'costs'}},
                {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'emissions'}},
                {kind: 'Field', name: {kind: 'Name', value: 'energy'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'power'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'value'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductionLineStandbyConsumptionQuery,
  GetProductionLineStandbyConsumptionQueryVariables
>
export const GetSectionStandbyConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetSectionStandbyConsumption'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timespan'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getSectionStandbyConsumption'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timespan'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'multiplier'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'costs'}},
                {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'emissions'}},
                {kind: 'Field', name: {kind: 'Name', value: 'energy'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'power'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'value'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSectionStandbyConsumptionQuery,
  GetSectionStandbyConsumptionQueryVariables
>
export const GetPlacementTotalConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetPlacementTotalConsumption'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timespan'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getPlacementTotalConsumption'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timespan'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'multiplier'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'costs'}},
                {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'emissions'}},
                {kind: 'Field', name: {kind: 'Name', value: 'energy'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'power'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'value'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlacementTotalConsumptionQuery,
  GetPlacementTotalConsumptionQueryVariables
>
export const GetProductionLineTotalConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetProductionLineTotalConsumption'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timespan'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getProductionLineTotalConsumption'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timespan'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'multiplier'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'costs'}},
                {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'emissions'}},
                {kind: 'Field', name: {kind: 'Name', value: 'energy'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'power'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'value'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductionLineTotalConsumptionQuery,
  GetProductionLineTotalConsumptionQueryVariables
>
export const GetSectionTotalConsumptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GetSectionTotalConsumption'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Timespan'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getSectionTotalConsumption'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'startTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'startTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'endTime'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'endTime'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timespan'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timespan'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'multiplier'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'multiplier'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'costs'}},
                {kind: 'Field', name: {kind: 'Name', value: 'currency'}},
                {kind: 'Field', name: {kind: 'Name', value: 'emissions'}},
                {kind: 'Field', name: {kind: 'Name', value: 'energy'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'power'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'time'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'value'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSectionTotalConsumptionQuery,
  GetSectionTotalConsumptionQueryVariables
>
