import {StepProps} from './_models'
import {useFactoryEntities, useTagsPaginated} from '../../../../../core/requests/factory'
import LoadingWrapper from '../../../../../components/LoadingWrapper'
import SelectFieldTable from '../../../../../components/form-components/SelectFieldTable'
import {entityToString} from '../../../../../core/name-util'

const TagSelectStep = ({data, updateData, hasError}: StepProps) => {
  const {items: tags, isLoading: tagsLoading, fetchNextPage: tagsFetchNextPage} = useTagsPaginated()

  const entityIds = tags?.filter((tag) => !!tag.entity).map((tag) => tag.entity as string)
  const {data: entities, isLoading: entitiesLoading} = useFactoryEntities({
    entityIds,
    options: {enabled: entityIds !== undefined && entityIds.length > 0},
  })

  const entityNameMap: Record<string, string> = {}
  entities?.items.forEach((entity) => {
    entityNameMap[entity.entity._id] = entityToString(entity)
  })

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <LoadingWrapper loading={tagsLoading || entitiesLoading}>
          {tags?.length ? (
            <SelectFieldTable
              name='Tag'
              columnNames={['Key', 'Value', 'Entity']}
              optionRows={tags.map((tag) => [
                tag.key,
                tag.value,
                tag.entity ? entityNameMap[tag.entity] || '?' : '-',
              ])}
              optionValues={tags.map((tag) => tag._id)}
              data={data}
              updateData={updateData}
              dataField='entity'
              required
              hasError={hasError}
              lastField
              gotoNextPage={tagsFetchNextPage}
            />
          ) : (
            'No tags'
          )}
        </LoadingWrapper>
      </div>
    </div>
  )
}

export default TagSelectStep
