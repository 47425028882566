import {useEffect, useState} from 'react'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {Part} from '../../core/_models'
import {useCreateModals} from '../CreateModalsProvider'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import {defaultCreatePart, ICreatePart} from './_models'
import {createPart} from '../../core/requests/factory'

const CreatePartModal = () => {
  const [data, setData] = useState<ICreatePart>(defaultCreatePart)
  const {showCreatePartModal, closeCreatePartModal, openCreatePlacementModal, initialAsset} =
    useCreateModals()

  useEffect(() => {
    if (!data.locationChoice && initialAsset?._id !== data.asset) {
      setData({
        asset: initialAsset?._id,
        locationChoice: initialAsset ? 'asset' : undefined,
      })
    }
  }, [initialAsset, setData])

  const steps: Step<ICreatePart>[] = [
    {
      title: 'Location',
      description: 'Choose Part Location',
      component: Step1,
      validation: (data) => !data.locationChoice,
      skipTo: [
        {
          step: 2,
          condition: () => initialAsset !== undefined,
          action: (data) => setData({...data, locationChoice: 'asset', asset: initialAsset?._id}),
        },
      ],
    },
    {
      title: 'Location',
      description: 'Choose Part Location',
      component: Step2,
      validation: (data) =>
        (data.locationChoice === 'production_line' && !data.production_line) ||
        (data.locationChoice === 'section' && !data.section) ||
        (data.locationChoice === 'asset' && !data.asset),
    },
    {
      title: 'Specifics',
      description: 'Choose Part Specifics',
      component: Step3,
      validation: (data) => !data.type,
    },
  ]

  return (
    <StepModalWrapper<ICreatePart, Part>
      id='hs_create_part'
      objectName='Part'
      show={showCreatePartModal}
      close={closeCreatePartModal}
      steps={steps}
      data={data}
      defaultData={defaultCreatePart}
      setData={setData}
      submitData={createPart}
      openNextModals={[openCreatePlacementModal]}
      nextObjectNames={['Placement']}
    />
  )
}

export default CreatePartModal
