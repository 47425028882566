import React from 'react'

type Props = {
  category: string
  className?: string
}

const WorkOrderCategoryBadge = ({category, className}: Props) => (
  <span className={`badge badge-light-${'primary'} ${className}`}>
    {category
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')}
  </span>
)

export default WorkOrderCategoryBadge
