import {useEffect, useState} from 'react'

const ICONS = [
  'fa-solid fa-atom',
  'fa-solid fa-industry',
  'fa-solid fa-shapes',
  'fa-solid fa-microchip',
  'fa-solid fa-envelope-open-text',
  'fa-solid fa-envelope',
  'fa-solid fa-clipboard',
  'fa-solid fa-gear',
  'fa-solid fa-calendar',
  'fa-solid fa-list',
  'fa-solid fa-users',
  'fa-solid fa-desktop',
  'fa-solid fa-image',
  'fa-solid fa-list-check',
  'fa-solid fa-lock',
]

type IconPickerProps = {
  icon: string
  setIcon: (icon: string) => void
  className?: string
}

export const IconPicker = ({icon, setIcon, className = ''}: IconPickerProps) => {
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null)
  const [show, setShow] = useState(false)

  // const isControlled = setIcon !== undefined
  // const currentIcon = isControlled ? icon : internalIcon

  const handleIconClick = (icon: string) => {
    setIcon(icon)
    setShow(false)
  }

  return (
    <div className={`position-relative ${className}`}>
      <i
        className={`${icon} cursor-pointer me-1`}
        onClick={(e) => {
          e.stopPropagation()
          setShow(true)
        }}
      />
      {show && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={(e) => {
              e.stopPropagation()
              setShow(false)
            }}
          />
          <div
            className=''
            style={{
              position: 'absolute',
              top: '0',
              left: '100%',
              transform: 'translateY( 0%)',
            }}
          >
            <div className='card' style={{width: '12rem'}}>
              <div className='card-body p-3'>
                {chunkIcons(ICONS, 5).map((chunk, i) => (
                  <div
                    className={`d-flex flex-row justify-content-between ${i !== 0 ? 'mt-3' : ''}`}
                    key={i}
                  >
                    {chunk.map((icon) => (
                      <i
                        key={icon}
                        className={`${icon} cursor-pointer ${
                          hoveredIcon === icon ? 'text-primary' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleIconClick(icon)
                        }}
                        onMouseEnter={() => setHoveredIcon(icon)}
                        onMouseLeave={() => setHoveredIcon(null)}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const chunkIcons = (icons: string[], chunkSize: number) => {
  const chunks = []
  for (let i = 0; i < icons.length; i += chunkSize) {
    chunks.push(icons.slice(i, i + chunkSize))
  }
  return chunks
}

export default IconPicker
