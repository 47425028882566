import {useQueryClient} from 'react-query'
import {ICreateMaintenancePlan, defaultCreateMaintenancePlan} from './_models'
import {useState} from 'react'
import StepModalWrapper, {Step} from '../../../components/StepModalWrapper'
import {MaintenancePlan} from '../../../core/_models'
import {createMaintenancePlan, updateMaintenancePlan} from '../../../core/requests/maintenance'
import Step1 from './Step1'
import Step2 from './Step2'

type Props = {
  show: boolean
  hide: () => void
  plan?: MaintenancePlan
}

const PlanModal = ({show, hide, plan}: Props) => {
  const queryClient = useQueryClient()
  const [data, setData] = useState<ICreateMaintenancePlan>(plan ?? defaultCreateMaintenancePlan)

  const steps: Step<ICreateMaintenancePlan>[] = [
    {
      title: 'Specifics',
      description: 'Define Maintenance Plan Specifics',
      component: Step1,
      validation: (data) => data.name === undefined,
    },
    {
      title: 'Entities',
      description: 'Define Maintenance Plan Entities',
      component: Step2,
      validation: (data) =>
        data.factory_entities === undefined || data.factory_entities.length === 0,
    },
  ]

  const submit = async (data: ICreateMaintenancePlan) => {
    const maintenancePlan = plan?._id
      ? await updateMaintenancePlan({...plan, ...data})
      : await createMaintenancePlan(data)
    await queryClient.invalidateQueries('maintenancePlans')
    return maintenancePlan
  }

  return (
    <StepModalWrapper<ICreateMaintenancePlan, MaintenancePlan>
      id='hs_create_maintenance_plan_modal'
      objectName='Maintenance Plan'
      show={show}
      close={hide}
      steps={steps}
      data={data}
      defaultData={plan ?? defaultCreateMaintenancePlan}
      setData={setData}
      submitData={submit}
      title={`${plan?._id ? 'Update' : 'Create new'} Maintenance Plan`}
    />
  )
}

export default PlanModal
