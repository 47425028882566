import TextField from '../../../../../components/form-components/TextField'
import {StepProps} from './_models'

const NameStep = ({data, updateData, hasError}: StepProps) => (
  <div data-kt-stepper-element='content'>
    <div className='w-100'>
      <div className='mb-10'>
        <h2 className='fw-bold d-flex align-items-center text-dark'>Set Name</h2>
        <div className='text-muted fw-semibold fs-6'>Enter a name for your widget.</div>
      </div>

      <TextField
        name='Name'
        data={data}
        updateData={updateData}
        dataField='name'
        required
        hasError={hasError}
        lastField
      />
    </div>
  </div>
)

export default NameStep
