import {useCallback, useEffect, useRef} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
}

const RichTextField = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
}: Props<DataType>) => {
  const quillRef = useRef<ReactQuill>(null)
  useEffect(() => {
    quillRef.current?.editor?.root.setAttribute('spellcheck', 'false')
  }, [])

  const modules = {
    toolbar: [
      [{size: ['small', false, 'large', 'huge']}],
      ['bold', 'italic', 'underline', 'strike'],
      [{list: 'ordered'}, {list: 'bullet'}, {align: []}],
      [{color: []}, {background: []}],
      ['clean'],
    ],
  }

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>

        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>
      <ReactQuill
        ref={quillRef}
        theme='snow'
        value={data[dataField]}
        modules={modules}
        onChange={(content) => {
          updateData({[dataField]: content} as Partial<DataType>)
        }}
      />
      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export default RichTextField
