import React from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {ReferenceUUID} from '../../core/_models'
import AssetTreeTab from './tabs/AssetTreeTab'
import OverviewTab from './tabs/OverviewTab'
import DashboardsTab from './tabs/DashboardsTab'
import ProductionLineTreeTab from './tabs/ProductionLineTreeTab'
import TimelineTab from './tabs/TimelineTab'

enum Tab {
  Overview = 'Overview',
  Dashboards = 'Dashboards',
  Timeline = 'Timeline',
  Tree = 'Tree',
}

export interface BaseOverviewDetailsModalProps<T extends ReferenceUUID = ReferenceUUID> {
  entity: T
  show?: boolean
  onHide: () => void
}

export interface OverviewDetailsModalProps extends Omit<BaseOverviewDetailsModalProps, 'entity'> {
  title: React.ReactNode
  objectId: string
  timelineId?: string
  displayProductionLineTree?: boolean
  displayAssetTree?: boolean
  attributes: Array<[string | undefined, string | React.ReactNode | React.ReactNode[] | undefined]>
}

const OverviewDetailsModal = ({
  title,
  objectId,
  timelineId,
  attributes,
  show = false,
  displayProductionLineTree = false,
  displayAssetTree = false,
  onHide,
}: OverviewDetailsModalProps) => {
  const [activeTab, setActiveTab] = React.useState<Tab>(Tab.Overview)

  const tabs = Object.values(Tab)
  if (!timelineId) {
    delete tabs[tabs.indexOf(Tab.Timeline)]
  }

  let treeTab
  if (displayProductionLineTree || displayAssetTree) {
    treeTab = displayProductionLineTree ? (
      <ProductionLineTreeTab productionLineId={objectId} />
    ) : (
      <AssetTreeTab assetId={objectId} />
    )
  } else {
    delete tabs[tabs.indexOf(Tab.Tree)]
  }

  const tabContents = {
    [Tab.Overview]: <OverviewTab attributes={attributes} />,
    [Tab.Dashboards]: <DashboardsTab objectId={objectId} />,
    [Tab.Timeline]: timelineId ? <TimelineTab timelineId={timelineId} /> : null,
    [Tab.Tree]: treeTab ? treeTab : null,
  }[activeTab]

  return (
    <Modal
      dialogClassName='modal-dialog modal-dialog-centered'
      size='lg'
      show={show}
      onHide={onHide}
    >
      <div className='modal-header'>
        <h2>{title}</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={onHide}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body container'>
        <>
          <ul className='nav nav-stretch nav-pills nav-pills-custom d-flex mb-5 mt-n5'>
            {tabs.map((tab) => (
              <li className='nav-item p-0 ms-0 me-8' key={`tab_${tab}`}>
                <button
                  className={`nav-link btn btn-color-muted px-0${
                    tab === activeTab ? ' active' : ''
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  <span className='nav-text fw-semibold fs-6 mb-1'>{tab}</span>
                  <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded' />
                </button>
              </li>
            ))}
          </ul>
          <div className='separator mt-n5 mb-5'></div>
          {tabContents}
        </>
      </div>
    </Modal>
  )
}

export default OverviewDetailsModal
