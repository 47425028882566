import React from 'react'
import {Part} from '../../../core/_models'
import {usePlacements, usePlacementsPaginated} from '../../../core/requests/factory'
import PlacementNode from './PlacementNode'
import TreeNode from './TreeNode'

interface PartNodeProps {
  part: Part
}

export const PartNode = ({part}: PartNodeProps) => {
  const [opened, setOpen] = React.useState(false)
  const {data: placements} = usePlacements({part: part._id})
  const {items, isFetching, hasNextPage, fetchNextPage} = usePlacementsPaginated(
    {part: part._id},
    {
      enabled: opened,
    }
  )

  React.useEffect(() => {
    if (!isFetching && hasNextPage) {
      ;(async () => {
        await fetchNextPage()
      })()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  const nodes = items?.map((placement) => <PlacementNode placement={placement} />)

  return (
    <TreeNode
      name={part.type || 'part'}
      icon={`fa-shapes`}
      opened={opened}
      setOpen={setOpen}
      nodes={nodes}
      isExpandable={!!placements?.total}
    />
  )
}

export default PartNode
