import {useQuery} from 'react-query'
import {graphql} from '../../../../../gql'
import {OperationalStates} from '../../../../../gql/graphql'
import useEnergyClient from '../UseEnergyClient'

const getProductionLineConsumptionByCategoryWithVariablesQueryDocument = graphql(`
  query GetProductionLineConsumptionByCategory(
    $id: ID!
    $masterPlacement: String!
    $placements: [ID!]!
    $operationalStates: [OperationalStates!]!
    $operationalIntervalMins: [Float!]!
    $operationalIntervalMaxs: [Float!]!
    $startTime: DateTime
    $endTime: DateTime
  ) {
    getProductionLineConsumptionByCategory(
      id: $id
      masterPlacement: $masterPlacement
      placements: $placements
      operationalStates: $operationalStates
      operationalIntervalMins: $operationalIntervalMins
      operationalIntervalMaxs: $operationalIntervalMaxs
      startTime: $startTime
      endTime: $endTime
    ) {
      consumptions {
        consumption
        state
        time
      }
    }
  }
`)

interface Props {
  id?: string
  masterPlacement: string
  placements: string[]
  operationalStates: OperationalStates[]
  operationalIntervalMins: number[]
  operationalIntervalMaxs: number[]
  startTime: string
  endTime?: string
}

const useGetProductionLineConsumptionByCategory = (props: Props) => {
  const client = useEnergyClient()
  let query = useQuery({
    queryKey: [`energy.GetProductionLineConsumptionByCategory`, ...Object.values(props)],
    queryFn: async () =>
      await client.request(getProductionLineConsumptionByCategoryWithVariablesQueryDocument, {
        id: props.id as string,
        ...props,
      }),
    enabled: !!props.id,
  })
  return {
    ...query,
    consumptions: query.data?.getProductionLineConsumptionByCategory.consumptions,
  }
}

export default useGetProductionLineConsumptionByCategory
