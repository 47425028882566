import Select from 'react-select'

type SensorTrackScrapSelectProps = {
  onChange: (trackScrap?: boolean) => void
}

const SensorTrackScrapSelect = ({onChange}: SensorTrackScrapSelectProps) => (
  <Select
    placeholder={`Track Scrap`}
    onChange={(e) => onChange(e?.value)}
    options={
      [true, false].map((t) => ({
        value: t,
        label: t ? 'TRUE' : 'FALSE',
      })) || []
    }
    isClearable
    className={'me-3'}
  />
)

export default SensorTrackScrapSelect
