import {useState} from 'react'
import {MaintenancePlan} from '../../core/_models'
import {TableToolbar} from '../overview/core/columns/TableToolbar'
import {AddWorkOrderTemplateModal} from './templateModal/AddWorkOrderTemplateModal'

type Props = {
  maintenancePlan: MaintenancePlan
}

const AddWorkOrderTemplateCell = ({maintenancePlan}: Props) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div className='d-flex align-items-end'>
        <TableToolbar
          buttonText='Recurring Work Order'
          onButtonClick={() => setShowModal(true)}
          // buttonIcon='/media/icons/duotune/communication/com002.svg'
        />
        {/* <button type='button' className='btn btn-lg btn-primary' onClick={() => setShowModal(true)}>
          Add Recurring Work Order
        </button> */}
      </div>

      <AddWorkOrderTemplateModal
        show={showModal}
        hide={() => setShowModal(false)}
        maintenancePlanId={maintenancePlan._id}
      />
    </>
  )
}

export {AddWorkOrderTemplateCell}
