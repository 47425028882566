import {WorkOrderCategory, workOrderCategories} from '../../../core/_models'
import Select from 'react-select'

type Props = {
  initial?: WorkOrderCategory
  name?: string
  onchange: (value?: WorkOrderCategory) => void
  className?: string
}

const WorkOrderCategorySelectField = ({initial, name = 'Category', onchange, className}: Props) => {
  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={initial ? {value: initial, label: initial} : undefined}
        options={workOrderCategories.map((category) => ({value: category, label: category}))}
        onChange={(selected) => onchange(selected?.value)}
        isClearable
      />
    </>
  )
}

export default WorkOrderCategorySelectField
