import {useIntl} from 'react-intl'
import TextField from '../../components/form-components/TextField'
import {StepProps} from './_models'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* Factory Name */}
        <TextField
          name='Factory Name'
          data={data}
          updateData={updateData}
          dataField='name'
          hasError={hasError}
          tooltip={intl.formatMessage({id: 'TOOLTIP.NAMED_ENTITY.FACTORY'})}
          required
          lastField
        />
      </div>
    </div>
  )
}

export default Step1
