import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import Pagination from '../../../components/pagination/Pagination'
import {deleteSensor, useSensors} from '../../../core/requests/factory'
import useGridSwitch from '../../../hooks/UseGridSwitch'
import {useCreateModals} from '../../../modals/CreateModalsProvider'
import {SensorOverviewDetailsModal} from '../../../modals/overview/SensorOverviewDetailsModal'
import {useSelectedRows} from '../../../providers/SelectedRowsProvider'
import {BadgeCell} from '../core/columns/BadgeCell'
import {TableBody} from '../core/columns/TableBody'
import {TableSelectionToolbar} from '../core/columns/TableSelectionToolbar'
import {TableToolbar} from '../core/columns/TableToolbar'
import AreaSelect from '../core/components/AreaSelect'
import DeviceStateSelect from '../core/components/DeviceStateSelect'
import ListFilter from '../core/components/ListFilter'
import ManufacturerSelect from '../core/components/ManufacturerSelect'
import OrganizationSelect from '../core/components/OrganizationSelect'
import {OverviewGridItem} from '../core/components/overview/OverviewGridItem'
import PartModelSelect from '../core/components/PartModelSelect'
import SensorDataTypeSelect from '../core/components/SensorDataTypeSelect'
import SensorTrackScrapSelect from '../core/components/SensorTrackScrapSelect'
import useSensorsFilter from '../core/hooks/useSensorsFilter'
import {ListSearchComponent} from '../core/TableSearchComponent'
import {Columns} from './Columns'

export const SensorsWrapper = () => {
  const {isGridView} = useGridSwitch()
  const {selected, setDataItems} = useSelectedRows()
  const {openCreateSensorModal} = useCreateModals()
  const {filterCount, filterState, activePage, setActivePage, sensorFilterProps} =
    useSensorsFilter()

  const {data: sensors, isLoading: sensorsLoading} = useSensors(sensorFilterProps)

  React.useEffect(() => {
    if (sensors?.items !== undefined) {
      setDataItems(sensors?.items)
    }
  }, [sensors?.items])

  const columns = React.useMemo(() => Columns, [])

  const [uuid, setUUID] = React.useState<string>()
  const sensor = sensors?.items.find((s) => s._id === uuid)

  return (
    <KTCard className={`${isGridView ? 'bg-transparent' : undefined}`}>
      <div
        className={`card-header border-0 justify-content-end pt-6 bg-white ${
          isGridView ? 'mb-5 pb-5 rounded' : ''
        }`}
      >
        {/* <ListSearchComponent /> */}
        <div className='card-toolbar'>
          <ListFilter
            count={filterCount}
            filters={[
              <DeviceStateSelect onChange={filterState.state.setValue} />,
              <AreaSelect onChange={filterState.area.setValue} />,
              <SensorDataTypeSelect onChange={filterState.dataType.setValue} />,
              <ManufacturerSelect onChange={filterState.manufacturer.setValue} />,
              <PartModelSelect onChange={filterState.model.setValue} />,
              <SensorTrackScrapSelect onChange={filterState.trackScrap.setValue} />,
              <OrganizationSelect onChange={filterState.organization.setValue} />,
            ]}
          />

          {/* Standard toolbar */}
          {selected.length === 0 && (
            <TableToolbar buttonText='Add' onButtonClick={openCreateSensorModal} />
          )}

          {/* Selection toolbar */}
          {selected.length > 0 && (
            <TableSelectionToolbar onDelete={deleteSensor} queryKey='sensors' />
          )}
        </div>
      </div>

      {isGridView ? (
        <div className={`row g-6 g-xl-9 mb-4`} id='hs_sensors_table'>
          {sensors?.items.map((item) => (
            <OverviewGridItem
              item={item}
              title={item.device_id}
              description={`${item.asset?.name || item.production_line?.name} ${
                item.placement.name
              }`}
              actions={
                <div className={`d-flex justify-content-center align-items-center`}>
                  <BadgeCell
                    color={item.state === 'Online' ? 'success' : 'danger'}
                    str={item.state}
                  />
                  <div
                    className={`fa-duotone fa-solid ${
                      !item.battery_low
                        ? 'fa-battery-full text-success'
                        : 'fa-battery-empty text-danger'
                    }`}
                  ></div>
                </div>
              }
              imageUrl={item.gallery?.profile_image.thumbnail_url}
              onClick={setUUID}
            />
          ))}
        </div>
      ) : (
        <TableBody
          tableId='hs_sensors_table'
          columns={columns}
          dataList={sensors?.items}
          loading={sensorsLoading}
          onCellClick={setUUID}
        />
      )}
      {sensors?.total ? (
        <Pagination
          page={activePage}
          total={sensors.total}
          size={sensors.size}
          onClick={(p) => setActivePage(p)}
        />
      ) : null}

      {sensor && <SensorOverviewDetailsModal entity={sensor} onHide={() => setUUID(undefined)} />}
    </KTCard>
  )
}
