import {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {axes, Axis} from '../../core/_models'
import {StepProps} from './_models'
import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxes
          name='Where is the part located?'
          optionNames={['Production Line', 'Section', 'Asset']}
          optionValues={['production_line', 'section', 'asset']}
          data={data}
          updateData={updateData}
          dataField='locationChoice'
          updatesOnChange={{production_line: undefined, section: undefined, asset: undefined}}
          hasError={hasError}
          required
          lastField
        />
      </div>
    </div>
  )
}

export default Step1
