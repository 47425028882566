import LoadingWrapper from '../../../components/LoadingWrapper'
import TextAreaField from '../../../components/form-components/TextAreaField'
import TextField from '../../../components/form-components/TextField'
import {useProfile} from '../../../core/requests/auth'
import {StepProps} from './_models'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const {data: profile} = useProfile()

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={profile === undefined}>
        <div className='w-100'>
          <TextField
            name='Name'
            data={data}
            updateData={updateData}
            dataField='name'
            hasError={hasError}
            required
          />

          <TextAreaField
            name='Description'
            data={data}
            updateData={updateData}
            dataField='description'
            placeholder='Add a description'
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step1
