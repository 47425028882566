import {useState} from 'react'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {Gateway} from '../../core/_models'
import {useCreateModals} from '../CreateModalsProvider'
import Step1 from './Step1'
import {defaultCreateGateway, ICreateGateway} from './_models'
import {createGateway} from '../../core/requests/factory'

const CreateGatewayModal = () => {
  const [data, setData] = useState<ICreateGateway>(defaultCreateGateway)
  const {showCreateGatewayModal, closeCreateGatewayModal} = useCreateModals()

  const steps: Step<ICreateGateway>[] = [
    {
      title: 'Name',
      description: 'Name Your Gateway',
      component: Step1,
      validation: (data) => !data.area || !data.device_id,
    },
  ]

  return (
    <StepModalWrapper<ICreateGateway, Gateway>
      id='hs_create_gateway'
      objectName='Gateway'
      show={showCreateGatewayModal}
      close={closeCreateGatewayModal}
      steps={steps}
      data={data}
      defaultData={defaultCreateGateway}
      setData={setData}
      submitData={createGateway}
    />
  )
}

export default CreateGatewayModal
