import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import Pagination from '../../../components/pagination/Pagination'
import {deletePlacement, usePlacements} from '../../../core/requests/factory'
import useGridSwitch from '../../../hooks/UseGridSwitch'
import {useCreateModals} from '../../../modals/CreateModalsProvider'
import {PlacementOverviewDetailsModal} from '../../../modals/overview/PlacementOverviewDetailsModal'
import {useSelectedRows} from '../../../providers/SelectedRowsProvider'
import {TableBody} from '../core/columns/TableBody'
import {TableSelectionToolbar} from '../core/columns/TableSelectionToolbar'
import {TableToolbar} from '../core/columns/TableToolbar'
import AreaSelect from '../core/components/AreaSelect'
import ListFilter from '../core/components/ListFilter'
import OrganizationSelect from '../core/components/OrganizationSelect'
import {OverviewGridItem} from '../core/components/overview/OverviewGridItem'
import PartSelect from '../core/components/PartSelect'
import ProductionLineSelect from '../core/components/ProductionLineSelect'
import SectionSelect from '../core/components/SectionSelect'
import usePlacementsFilter from '../core/hooks/usePlacementsFilter'
import {ListSearchComponent} from '../core/TableSearchComponent'
import AlertsCell from './AlertsCell'
import {Columns} from './Columns'

export const PlacementsWrapper = () => {
  const {isGridView} = useGridSwitch()

  const {selected, setDataItems} = useSelectedRows()
  const {openCreatePlacementModal} = useCreateModals()
  const {filterState, filterCount, activePage, setActivePage, placementFilterProps} =
    usePlacementsFilter()
  const {data: placements, isLoading: placementsLoading} = usePlacements(placementFilterProps)

  React.useEffect(() => {
    if (placements?.items !== undefined) {
      setDataItems(placements?.items)
    }
  }, [placements?.items])

  const columns = React.useMemo(() => Columns, [])

  const [uuid, setUUID] = React.useState<string>()
  const placement = placements?.items.find((p) => p._id === uuid)

  return (
    <KTCard className={`${isGridView ? 'bg-transparent' : undefined}`}>
      {/* Header */}
      <div
        className={`card-header border-0 justify-content-end pt-6 bg-white ${
          isGridView ? 'mb-5 pb-5 rounded' : ''
        }`}
      >
        {/* <ListSearchComponent /> */}
        <div className='card-toolbar'>
          <ListFilter
            count={filterCount}
            filters={[
              <OrganizationSelect onChange={filterState.organization.setValue} />,
              <AreaSelect onChange={filterState.area.setValue} />,
              <ProductionLineSelect onChange={filterState.productionLine.setValue} />,
              <SectionSelect onChange={filterState.section.setValue} />,
              <PartSelect onChange={filterState.part.setValue} />,
            ]}
          />

          {/* Standard toolbar */}
          {selected.length === 0 && (
            <TableToolbar buttonText='Add' onButtonClick={openCreatePlacementModal} />
          )}

          {/* Selection toolbar */}
          {selected.length > 0 && (
            <TableSelectionToolbar onDelete={deletePlacement} queryKey='placements' />
          )}
        </div>
      </div>

      {isGridView ? (
        <div className={`row g-6 g-xl-9 mb-4`} id='hs_placements_table'>
          {placements?.items.map((item) => (
            <OverviewGridItem
              item={item}
              title={item.name}
              description={`${item.factory?.name || ''} - ${item.area?.name || ''} - ${
                item.asset?.name || item.production_line?.name || ''
              }`}
              actions={<AlertsCell placement={item} />}
              imageUrl={item.gallery?.profile_image.thumbnail_url}
              onClick={setUUID}
            />
          ))}
        </div>
      ) : (
        <TableBody
          tableId='hs_placements_table'
          columns={columns}
          dataList={placements?.items}
          loading={placementsLoading}
          onCellClick={(value) => setUUID(value)}
        />
      )}
      {placements?.total ? (
        <Pagination
          page={activePage}
          total={placements.total}
          size={placements.size}
          onClick={(p) => setActivePage(p)}
        />
      ) : null}
      {placement && (
        <PlacementOverviewDetailsModal entity={placement} onHide={() => setUUID(undefined)} />
      )}
    </KTCard>
  )
}
