import LoadingWrapper from '../../components/LoadingWrapper'
import {useParts, usePlacementsPaginated} from '../../core/requests/factory'
import QRReader from '../../components/qr/QRReader'
import {useCreateModals} from '../CreateModalsProvider'
import {StepProps} from './_models'
import TextField from '../../components/form-components/TextField'
import SelectField from '../../components/form-components/SelectField'
import {useIntl} from 'react-intl'
import React from 'react'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  const {initialPlacement, initialAsset} = useCreateModals()
  const {data: parts, isLoading: partsLoading} = useParts({asset: initialAsset?._id})

  const {
    items: placements,
    isLoading: placementsLoading,
    isFetching: placementsFetching,
    fetchNextPage: placementsNextPage,
  } = usePlacementsPaginated()

  const placementList = initialAsset?._id
    ? placements?.filter(
        (placement) =>
          placement.part?._id ===
          parts?.items?.find((part) => part._id === placement.part?._id)?._id
      )
    : placements

  React.useEffect(() => {
    if (!data.placement && initialPlacement) updateData({placement: initialPlacement._id})
  }, [initialPlacement])

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={partsLoading || placementsLoading}>
        <div className='w-100'>
          <QRReader
            onSuccess={(decodedText) => {
              updateData({
                device_id: decodedText,
              })
            }}
          />
          <TextField
            name='Device ID'
            data={data}
            updateData={updateData}
            dataField='device_id'
            hasError={hasError}
            required
          />
          {/* Placement */}
          <SelectField
            name='Placement'
            optionNames={
              placementList?.map(
                (placement) =>
                  `${placement.name} | ${placement.production_line?.name || placement.asset?.name}`
              ) || []
            }
            optionValues={placementList?.map((placement) => placement._id) || []}
            data={data}
            updateData={updateData}
            dataField='placement'
            lastField
            hasError={hasError}
            required
            tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.PLACEMENT'})}
            isLoading={placementsFetching}
            gotoNextPage={placementsNextPage}
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step1
