import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import Pagination from '../../../components/pagination/Pagination'
import {deletePart, useParts} from '../../../core/requests/factory'
import useGridSwitch from '../../../hooks/UseGridSwitch'
import {useCreateModals} from '../../../modals/CreateModalsProvider'
import {useSelectedRows} from '../../../providers/SelectedRowsProvider'
import {BadgeCell} from '../core/columns/BadgeCell'
import {TableBody} from '../core/columns/TableBody'
import {TableSelectionToolbar} from '../core/columns/TableSelectionToolbar'
import {TableToolbar} from '../core/columns/TableToolbar'
import AssetSelect from '../core/components/AssetSelect'
import ListFilter from '../core/components/ListFilter'
import OrganizationSelect from '../core/components/OrganizationSelect'
import {OverviewGridItem} from '../core/components/overview/OverviewGridItem'
import PartTypeSelect from '../core/components/PartTypeSelect'
import ProductionLineSelect from '../core/components/ProductionLineSelect'
import SectionSelect from '../core/components/SectionSelect'
import usePartsFilter from '../core/hooks/usePartsFilter'
import {ListSearchComponent} from '../core/TableSearchComponent'
import {Columns} from './Columns'

export const PartsWrapper = () => {
  const {isGridView} = useGridSwitch()

  const {selected, setDataItems} = useSelectedRows()
  const {openCreatePartModal} = useCreateModals()
  const {filterState, filterCount, activePage, setActivePage, partFilterProps} = usePartsFilter()
  const {data: parts, isLoading: partsLoading} = useParts(partFilterProps)
  const columns = React.useMemo(() => Columns, [])

  React.useEffect(() => {
    if (parts?.items !== undefined) {
      setDataItems(parts?.items)
    }
  }, [parts?.items])

  return (
    <KTCard className={`${isGridView ? 'bg-transparent' : undefined}`}>
      {/* Header */}
      <div
        className={`card-header border-0 justify-content-end pt-6 bg-white ${
          isGridView ? 'mb-5 pb-5 rounded' : ''
        }`}
      >
        {/* <ListSearchComponent /> */}
        <div className='card-toolbar'>
          <ListFilter
            count={filterCount}
            filters={[
              <OrganizationSelect onChange={filterState.organization.setValue} />,
              <ProductionLineSelect onChange={filterState.productionLine.setValue} />,
              <SectionSelect onChange={filterState.section.setValue} />,
              <AssetSelect onChange={filterState.asset.setValue} />,
              <PartTypeSelect onChange={filterState.partType.setValue} />,
            ]}
          />

          {/* Standard toolbar */}
          {selected.length === 0 && (
            <TableToolbar buttonText='Add' onButtonClick={openCreatePartModal} />
          )}

          {/* Selection toolbar */}
          {selected.length > 0 && <TableSelectionToolbar onDelete={deletePart} queryKey='parts' />}
        </div>
      </div>

      {isGridView ? (
        <div className={`row g-6 g-xl-9 mb-4`} id='hs_parts_table'>
          {parts?.items.map((item) => (
            <OverviewGridItem
              item={item}
              title={`${item.manufacturer || ''} ${item.model_number || ''}`}
              description={`${item.production_line?.name || ''} - ${item.section?.name || ''} - ${
                item.asset?.name || ''
              }`}
              actions={
                <div className={`badge py-3 px-4 fs-7 badge-light-primary`}>{item.type || ''}</div>
              }
              imageUrl={item.gallery?.profile_image.thumbnail_url}
            />
          ))}
        </div>
      ) : (
        <TableBody
          tableId='hs_parts_table'
          columns={columns}
          dataList={parts?.items}
          loading={partsLoading}
        />
      )}
      {parts?.total ? (
        <Pagination
          page={activePage}
          total={parts.total}
          size={parts.size}
          onClick={(p) => setActivePage(p)}
        />
      ) : null}
    </KTCard>
  )
}
