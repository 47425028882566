import {useEffect} from 'react'
import LoadingWrapper from '../../components/LoadingWrapper'
import {useCreateModals} from '../CreateModalsProvider'
import {StepProps} from './_models'
import TextField from '../../components/form-components/TextField'
import {useIntl} from 'react-intl'
import SelectField from '../../components/form-components/SelectField'
import {useAreasPaginated} from '../../core/requests/factory'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  const {initialArea} = useCreateModals()
  const {items, isLoading, isFetching, fetchNextPage} = useAreasPaginated()

  useEffect(() => {
    if (!data.area && initialArea) updateData({area: initialArea._id})
  }, [initialArea])

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={isLoading}>
        <div className='w-100'>
          {/* Production Line Name */}
          <TextField
            name='Production Line Name'
            data={data}
            updateData={updateData}
            dataField='name'
            hasError={hasError}
            tooltip={intl.formatMessage({id: 'TOOLTIP.NAMED_ENTITY.PRODUCTION_LINE'})}
            required
          />

          {/* Area */}
          <SelectField
            name='Area'
            optionNames={items?.map((area) => area.name) || []}
            optionValues={items?.map((area) => area._id) || []}
            data={data}
            updateData={updateData}
            dataField='area'
            hasError={hasError}
            tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.AREA'})}
            required
            lastField
            isLoading={isFetching}
            gotoNextPage={fetchNextPage}
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step1
