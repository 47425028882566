import LoadingWrapper from '../../../../../components/LoadingWrapper'
import SelectFieldBoxesMultiple from '../../../../../components/form-components/SelectFieldBoxesMultiple'
import {useSensorQueries} from '../../../../../core/requests/factory'
import {WidgetDataType} from '../../widgets/_models'
import {StepProps} from './_models'

const DataTypesStep = ({data, updateData, hasError}: StepProps) => {
  const {isLoading, items: sensors} = useSensorQueries(data.entities)

  let sensorDataOptions: WidgetDataType[] = []
  sensors.forEach((sensor) => {
    sensor?.data_types.forEach((dataType) => {
      if (dataType === 'vibration') sensorDataOptions.push('Vibration')
      else if (dataType === 'ampere') sensorDataOptions.push('Ampere', 'Kilowatt')
      else if (dataType === 'h2s') sensorDataOptions.push('H2S')
      else if (dataType === 'output') sensorDataOptions.push('Output')
      else if (dataType === 'temperature') sensorDataOptions.push('Temperature')
    })
  })
  sensorDataOptions = sensorDataOptions
    .filter((value, index, array) => array.indexOf(value) === index)
    .filter((v) => v !== null)

  return (
    <div data-kt-stepper-element='content'>
      <LoadingWrapper loading={isLoading}>
        <div className='w-100'>
          <SelectFieldBoxesMultiple
            name='Sensor Data Types'
            optionNames={sensorDataOptions || []}
            optionValues={sensorDataOptions || []}
            data={data}
            updateData={(newData) => {
              const newDataTypes = [...(newData.data_types ?? [])]
              if (!newDataTypes.includes('Ampere')) {
                ;['Phase 1', 'Phase 2', 'Phase 3', 'Average'].forEach((t) => {
                  if (newDataTypes.indexOf(t) > -1) {
                    newDataTypes.splice(newDataTypes.indexOf(t), 1)
                  }
                })
              }
              if (!newDataTypes.includes('Vibration')) {
                ;['RMS X', 'RMS Y', 'RMS Z'].forEach((t) => {
                  if (newDataTypes.indexOf(t) > -1) {
                    newDataTypes.splice(newDataTypes.indexOf(t), 1)
                  }
                })
              }
              updateData({
                data_types: newDataTypes,
              })
            }}
            dataField='data_types'
            required
            hasError={hasError}
            colSpace={12}
            lastField
          />
          {hasError && (
            <div className='fv-plugins-message-container mb-10'>
              <div className='fv-help-block'>You must specify sensor data types</div>
            </div>
          )}
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default DataTypesStep
