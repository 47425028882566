import {uniq} from 'lodash'
import SelectFieldBoxesMultiple from '../../../../../../components/form-components/SelectFieldBoxesMultiple'
import {operationalStates} from '../../../../../../core/_models'
import {StepProps} from '../_models'

const CategoriesStep = ({data, updateData, hasError}: StepProps) => (
  <div className='current' data-kt-stepper-element='content'>
    <div className='w-100'>
      <SelectFieldBoxesMultiple
        name='Categories'
        optionNames={operationalStates.map((p) => `${p[0].toUpperCase()}${p.slice(1)}`)}
        optionValues={operationalStates}
        data={data}
        updateData={(fieldsToUpdate) =>
          updateData({
            operational_states: uniq(
              [...(fieldsToUpdate.operational_states || []), 'running', 'standby'].filter(
                (v) => !!v
              )
            ),
          })
        }
        dataField='operational_states'
        required
        hasError={hasError}
      />
    </div>
  </div>
)

export default CategoriesStep
