import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import {TimedValue} from '../../../../core/_models'
import {showNoDataDisplay} from '../../../../core/chart-util'

export function healthChartConfig(chartId: string, data: TimedValue[]) {
  const root = am5.Root.new(chartId)
  root.setThemes([am5themes_Animated.new(root)])

  let newData = []
  for (const d of data) {
    if (d.value === 100) {
      newData.push({date: new Date(d.time).getTime(), value: 3.5, label: 'Good'})
    } else if (d.value === 200) {
      newData.push({date: new Date(d.time).getTime(), value: 2.5, label: 'Satisfactory'})
    } else if (d.value === 300) {
      newData.push({date: new Date(d.time).getTime(), value: 1.5, label: 'Unsatisfactory'})
    } else if (d.value === 400) {
      newData.push({date: new Date(d.time).getTime(), value: 0.5, label: 'Unacceptable'})
    }
  }

  root.dateFormatter.setAll({
    dateFormat: "yyyy.MM.dd 'at' HH:mm:ss",
    dateFields: ['valueX'],
  })

  // Create chart
  // https://www.amcharts.com/docs/v5/charts/xy-chart/
  let chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: 'panX',
      wheelY: 'zoomX',
      pinchZoomX: true,
    })
  )

  // Add cursor
  // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
  let cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'none',
    })
  )
  cursor.lineY.set('visible', false)

  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  let xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      maxDeviation: 0.5,
      baseInterval: {timeUnit: 'hour', count: 1},
      renderer: am5xy.AxisRendererX.new(root, {pan: 'zoom'}),
      tooltip: am5.Tooltip.new(root, {}),
    })
  )

  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      min: 0,
      max: 4,
      strictMinMax: true,

      maxDeviation: 1,
      renderer: am5xy.AxisRendererY.new(root, {pan: 'zoom'}),
    })
  )

  var rendererY = yAxis.get('renderer')
  rendererY.grid.template.set('forceHidden', true)
  rendererY.labels.template.set('forceHidden', true)

  function createRange(value: number, axis: am5xy.ValueAxis<am5xy.AxisRenderer>, label: string) {
    let rangeDataItem = axis.makeDataItem({
      value: value,
    })

    let range = axis.createAxisRange(rangeDataItem)

    range.get('label')?.setAll({
      forceHidden: false,
      text: label,
    })

    range.get('grid')?.setAll({
      forceHidden: false,
      strokeOpacity: 0.2,
      location: 1,
    })
  }

  createRange(0.5, yAxis, 'Unacceptable')
  createRange(1.5, yAxis, 'Unsatisfactory')
  createRange(2.5, yAxis, 'Satisfactory')
  createRange(3.5, yAxis, 'Good')

  // Add series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  let series = chart.series.push(
    am5xy.StepLineSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      valueXField: 'date',
      stroke: am5.color(0xffffff),
      fill: am5.color(0xffffff),
      tooltip: am5.Tooltip.new(root, {
        labelText: '{valueX}: [bold]{label}',
      }),
    })
  )

  series.strokes.template.setAll({
    strokeWidth: 3,
  })

  series.data.setAll(newData)

  // Add scrollbar
  // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
  chart.set(
    'scrollbarX',
    am5.Scrollbar.new(root, {
      orientation: 'horizontal',
    })
  )

  let range0DataItem = yAxis.makeDataItem({
    value: 0,
    endValue: 1,
  })
  yAxis.createAxisRange(range0DataItem)
  range0DataItem.get('axisFill')?.setAll({
    visible: true,
    fillOpacity: 1,
    fill: am5.Color.fromString('#ff0202'),
  })

  let range1DataItem = yAxis.makeDataItem({
    value: 1,
    endValue: 2,
  })
  yAxis.createAxisRange(range1DataItem)
  range1DataItem.get('axisFill')?.setAll({
    visible: true,
    fillOpacity: 1,
    fill: am5.Color.fromString('#e58c0f'),
  })

  let range2DataItem = yAxis.makeDataItem({
    value: 2,
    endValue: 3,
  })
  yAxis.createAxisRange(range2DataItem)
  range2DataItem.get('axisFill')?.setAll({
    visible: true,
    fillOpacity: 1,
    fill: am5.Color.fromString('#9cd98d'),
  })

  let range3DataItem = yAxis.makeDataItem({
    value: 3,
    endValue: 4,
  })
  yAxis.createAxisRange(range3DataItem)
  range3DataItem.get('axisFill')?.setAll({
    visible: true,
    fillOpacity: 1,
    fill: am5.Color.fromString('#228002'),
  })

  if (!newData.length) return showNoDataDisplay(chartId, root)

  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  series.appear(1000)
  chart.appear(1000, 100)

  return root
}
