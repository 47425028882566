import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import {showNoDataDisplay} from '../../../../core/chart-util'
import {EventCategory, OeeEventLog, SubEventCategory} from '../../../../core/_models'

export type TransformedEventData = {
  category: ''
  from: number
  to: number
  columnSettings: {
    fill: am5.Color
  }
  eventLogId: string
  eventCategoryTitle: string
  eventCategory?: EventCategory | SubEventCategory
  note?: string
  eventLog: OeeEventLog
}

export function productionStatusTimelineConfig(
  chartId: string,
  eventLogs: OeeEventLog[],
  categoryMap: Map<string, EventCategory | SubEventCategory>,
  productRates: [number, number][],
  endTimeLimit?: string,
  openEditEventModal?: (eventData: TransformedEventData) => void
) {
  const eventData: TransformedEventData[] = eventLogs.map((log, i) => {
    let toDate: number
    if (i < eventLogs.length - 1) {
      toDate = eventLogs[i + 1].unix * 1000
    } else {
      toDate = !!endTimeLimit ? new Date(endTimeLimit).getTime() : new Date().getTime()
    }

    const category = categoryMap.get(log.categories.at(-1) || '')

    return {
      category: '',
      from: log.unix * 1000,
      to: toDate,
      columnSettings: {
        fill: am5.color(category?.color || 'rgb(255, 255, 255)'),
      },
      eventLogId: log._id,
      eventCategoryTitle: category?.title || log.categories[0],
      eventCategory: category,
      note: log.note,
      eventLog: log,
    }
  })

  const earliestEventDate = eventData.at(0)?.from
  const productRateData = productRates
    .map((rate) => ({
      date: rate[0] * 1000,
      rate: rate[1],
    }))
    .filter((rate) => (earliestEventDate ? rate.date > earliestEventDate : true))

  const root = am5.Root.new(chartId)
  root.setThemes([am5themes_Animated.new(root)])

  // Create chart instance
  // - vertical layout will stack elements one under another (legend under chart)
  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
      maxTooltipDistance: -1,
      wheelY: 'zoomX',
    })
  )

  // Set up Category Y axis (for status)
  // - category axis will use category field from data
  // - only data items with matching category will be shown on the axis (only those with category = '' in this case)
  const statusYAxis = chart.yAxes.push(
    am5xy.CategoryAxis.new(root, {
      categoryField: 'category',
      renderer: am5xy.AxisRendererY.new(root, {}),
    })
  )
  statusYAxis.data.setAll([{category: ''}])

  // Set up Value Y axis (for product rate)
  const productionRateYAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
    })
  )

  // Set up X axis
  // - date axis; data items must be sorted in ascending order by date
  // - granularity is specified by baseInterval (1 data item per second)
  let xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: {timeUnit: 'second', count: 1},
      renderer: am5xy.AxisRendererX.new(root, {}),
    })
  )

  // Set up status series
  // - column series displays columns or bars
  // - x axis is date axis so we use valueXField, y axis is category axis so we use categoryYField
  // - x axiis also has an openValueXField. Series will display columns from openValueXField to valueXField (and not from 0 to valueXField)
  let statusSeries = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: statusYAxis,
      valueXField: 'to',
      openValueXField: 'from',
      categoryYField: 'category',
      // tooltip: am5.Tooltip.new(root, {
      //   labelText: '[bold]Status:[/] {category}',
      // }),
    })
  )

  // Configure status series appearance
  // - stroke width and opacity is 0, so columns will be solid and not have outlines
  // - height is 100% so columns will stretch from top to bottom of the chart
  // - templateField allows each data item to change a field on the template (used for setting fill color)
  statusSeries.columns.template.setAll({
    strokeWidth: 0,
    strokeOpacity: 0,
    height: am5.percent(100),
    templateField: 'columnSettings',
    cursorOverStyle: 'pointer',
  })

  // Bind data to status series
  statusSeries.data.setAll(eventData)

  // Set up production rate series
  const productionRateSeries = chart.series.push(
    am5xy.SmoothedXLineSeries.new(root, {
      xAxis: xAxis,
      yAxis: productionRateYAxis,
      valueXField: 'date',
      valueYField: 'rate',
      stroke: am5.color('rgb(255, 255, 255)'),
      tooltip: am5.Tooltip.new(root, {
        labelText: '[bold]Rate:[/] {rate} per minute',
      }),
    })
  )

  // Configure production rate series appearance
  productionRateSeries.strokes.template.setAll({
    strokeWidth: 2,
    strokeOpacity: 1,
  })

  // Bind data to production rate series
  productionRateSeries.data.setAll(productRateData)

  // Add cursor
  let cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      snapToSeries: [productionRateSeries],
      snapToSeriesBy: 'x',
      behavior: 'zoomX',
    })
  )
  cursor.lineY.set('visible', false)

  chart.set(
    'scrollbarX',
    am5.Scrollbar.new(root, {
      orientation: 'horizontal',
    })
  )

  if (openEditEventModal) {
    statusSeries.columns.template.events.on('click', function (ev) {
      const dataItem = ev.target.dataItem?.dataContext as TransformedEventData
      openEditEventModal(dataItem)
    })

    statusSeries.columns.template.events.on('pointerover', (ev) => {
      ev.target.setAll({fillOpacity: 0.8})
    })

    statusSeries.columns.template.events.on('pointerout', (ev) => {
      ev.target.setAll({fillOpacity: 1})
    })
  }

  // Add legend to chart
  let legend = chart.children.push(
    am5.Legend.new(root, {
      nameField: 'name',
      fillField: 'color',
      strokeField: 'color',
      centerX: am5.percent(50),
      x: am5.percent(50),
    })
  )

  // Set up legend data
  const uniqueCategoriesAndColors = eventData.reduce(
    (acc, {eventCategoryTitle, columnSettings}) => {
      if (!acc[eventCategoryTitle]) acc[eventCategoryTitle] = columnSettings.fill
      return acc
    },
    {} as Record<string, am5.Color>
  )

  legend.data.setAll(
    Object.entries(uniqueCategoriesAndColors).map(([name, color]) => ({name, color}))
  )

  if (!productRateData.length && !eventData.length) return showNoDataDisplay(chartId, root)

  // Make graph animate on load
  statusSeries.appear()
  chart.appear(1000, 100)

  return root
}
