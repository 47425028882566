import clsx from 'clsx'
import {useState} from 'react'

type Props = {
  initial?: number
  name?: string
  placeholder?: string
  onchange: (value?: number) => void
  errorOccurred?: boolean
  className?: string
}

const NumberInputField = ({
  initial,
  name,
  placeholder,
  onchange,
  errorOccurred,
  className,
}: Props) => {
  const [value, setValue] = useState(initial || '')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const parsed = parseInt(value)
    const isValid = value !== '' && !isNaN(parsed)
    setValue(isValid ? parsed : '')
    onchange(isValid ? parsed : undefined)
  }

  const error = errorOccurred && value === ''

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <input
        type='number'
        placeholder={placeholder}
        className={clsx('form-control fw-normal text-dark p-3', error && 'is-invalid')}
        style={{height: '38px'}}
        value={value}
        onChange={handleChange}
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}
    </>
  )
}

export default NumberInputField
