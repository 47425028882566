import {Column} from 'react-table'
import {MaintenancePlan} from '../../core/_models'
import {SelectionHeader} from '../overview/core/columns/SelectionHeader'
import {SelectionCell} from '../overview/core/columns/SelectionCell'
import {CustomHeader} from '../overview/core/columns/CustomHeader'
import {StringCell} from '../overview/core/columns/StringCell'
import {ExpandCell} from '../overview/core/columns/ExpandCell'
import {CellClickContext} from '../overview/core/columns/TableBody'
import {AddWorkOrderTemplateCell} from './AddWorkOrderTemplateCell'

const Columns: ReadonlyArray<Column<MaintenancePlan>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} tableId='hs_maintenance_plans_table' />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index]._id} tableId='hs_maintenance_plans_table' />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='' className='w-10px' />,
    id: 'expand',
    Cell: ({...props}) => <ExpandCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => {
      return (
        <CellClickContext.Consumer>
          {({onCellClick}) => (
            <StringCell
              str={props.data[props.row.index].name}
              onClick={() =>
                onCellClick ? onCellClick(props.data[props.row.index]._id) : undefined
              }
            />
          )}
        </CellClickContext.Consumer>
      )
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='' className='min-w-125px' />,
    id: 'add_work_order_template',
    Cell: ({...props}) => (
      <AddWorkOrderTemplateCell maintenancePlan={props.data[props.row.index]} />
    ),
  },
]

// name, #work orders #recurring WO, add WO
// title, next occurrence, add WO

export {Columns}
