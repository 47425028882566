import {useEffect, useRef, useState} from 'react'
import {updateDashboard, useDashboard} from '../../../../../app/core/requests/template'
import {useDashboardGrid} from '../../../../../app/providers/DashboardGridProvider'
import {useQueryClient} from 'react-query'
import LoadingOverlayWrapper from '../../../../../app/components/LoadingOverlayWrapper'

const DashboardPageTitle = () => {
  const queryClient = useQueryClient()
  const {activeDashboardId, widgetsLocked} = useDashboardGrid()
  const {data: dashboard} = useDashboard({dashboard: activeDashboardId})

  const [addingTag, setAddingTag] = useState(false)
  const [newTag, setNewTag] = useState('')
  const [editingTitle, setEditingTitle] = useState(false)
  const [newTitle, setNewTitle] = useState('')
  const [loading, setLoading] = useState(false)

  const handleAddNewTag = async () => {
    if (!dashboard || newTag === '' || dashboard?.tags.includes(newTag)) {
      setAddingTag(false)
      setNewTag('')
      return
    }

    setLoading(true)
    const newTags = [...dashboard.tags, newTag]
    try {
      await updateDashboard(dashboard._id, {tags: newTags})
      await queryClient.invalidateQueries(['dashboard', dashboard._id])
      await queryClient.invalidateQueries('dashboards')
    } catch (error) {
      console.error(error)
    } finally {
      setAddingTag(false)
      setNewTag('')
      setLoading(false)
    }
  }

  const handleDeleteTag = async (tag: string) => {
    if (dashboard === undefined) return

    setLoading(true)
    const newTags = dashboard.tags.filter((t) => t !== tag)
    try {
      await updateDashboard(dashboard._id, {tags: newTags})
      await queryClient.invalidateQueries(['dashboard', dashboard._id])
      await queryClient.invalidateQueries('dashboards')
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeTitle = async () => {
    if (!dashboard || newTitle === '' || newTitle === dashboard.name) {
      setEditingTitle(false)
      setNewTitle('')
      return
    }

    setLoading(true)
    try {
      await updateDashboard(dashboard._id, {name: newTitle})
      await queryClient.invalidateQueries(['dashboard', dashboard._id])
      await queryClient.invalidateQueries('dashboards')
    } catch (error) {
      console.error(error)
    } finally {
      setEditingTitle(false)
      setNewTitle('')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (widgetsLocked) setEditingTitle(false)
  }, [widgetsLocked])

  return (
    <LoadingOverlayWrapper loading={loading}>
      <div className='d-flex'>
        {!widgetsLocked && editingTitle ? (
          <TitleInput
            newTitle={newTitle}
            setNewTitle={setNewTitle}
            handleChangeTitle={handleChangeTitle}
          />
        ) : null}
        {dashboard && (
          <div className='d-flex align-items-center'>
            {!widgetsLocked && !editingTitle && (
              <i
                className='fa-solid fa-pen fs-5 cursor-pointer me-10'
                onClick={() => {
                  setNewTitle(dashboard.name)
                  setEditingTitle(true)
                }}
              />
            )}
            <TagDisplay
              tags={dashboard.tags}
              addingTag={addingTag}
              onDeleteTag={handleDeleteTag}
              onAddTagClick={() => setAddingTag(true)}
            />
            {addingTag && (
              <TagInput newTag={newTag} setNewTag={setNewTag} onAddNewTag={handleAddNewTag} />
            )}
          </div>
        )}
      </div>
    </LoadingOverlayWrapper>
  )
}

interface TagDisplayProps {
  tags: string[]
  addingTag: boolean
  onDeleteTag: (tag: string) => void
  onAddTagClick: () => void
}

const TagDisplay = ({tags, addingTag, onDeleteTag, onAddTagClick}: TagDisplayProps) => {
  return (
    <>
      {tags.map((tag) => (
        <span key={tag} className='badge py-2 px-3 fs-7 badge-secondary me-1'>
          {tag}
          <i
            className='fa-solid fa-trash fs-7 ms-2 cursor-pointer'
            onClick={() => onDeleteTag(tag)}
          />
        </span>
      ))}
      {!addingTag && (
        <span
          className='badge py-2 px-3 fs-7 badge-secondary cursor-pointer'
          onClick={onAddTagClick}
        >
          Tag
          <i className='fa-solid fa-plus fs-7 ms-2' />
        </span>
      )}
    </>
  )
}

interface TagInputProps {
  newTag: string
  setNewTag: React.Dispatch<React.SetStateAction<string>>
  onAddNewTag: () => void
}

const TagInput = ({newTag, setNewTag, onAddNewTag}: TagInputProps) => {
  const newTagInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    newTagInputRef.current?.focus()
  }, [])

  return (
    <div className='d-flex align-items-center gap-1'>
      <input
        ref={newTagInputRef}
        type='text'
        className='form-control form-control-sm form-control-solid-flush border'
        name='name'
        placeholder='New Tag'
        value={newTag}
        onChange={(e) => {
          setNewTag(e.target.value)
        }}
      />
      <i className='fa-solid fa-check fs-3 ms-2 cursor-pointer' onClick={onAddNewTag} />
    </div>
  )
}

interface TitleInputProps {
  newTitle: string
  setNewTitle: React.Dispatch<React.SetStateAction<string>>
  handleChangeTitle: () => void
}

const TitleInput = ({newTitle, setNewTitle, handleChangeTitle}: TitleInputProps) => {
  const newTitleInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    newTitleInputRef.current?.focus()
  }, [])

  return (
    <div className='d-flex align-items-center'>
      <input
        ref={newTitleInputRef}
        type='text'
        className='form-control form-control-sm fs-5 text-dark fw-bold'
        name='name'
        placeholder='New Title'
        value={newTitle}
        onChange={(e) => {
          setNewTitle(e.target.value)
        }}
      />
      <i className='fa-solid fa-check fs-3 ms-4 me-10 cursor-pointer' onClick={handleChangeTitle} />
    </div>
  )
}

export {DashboardPageTitle}
