import SelectFieldTable from '../../../../../components/form-components/SelectFieldTable'
import LoadingWrapper from '../../../../../components/LoadingWrapper'
import SelectField from '../../../../../components/form-components/SelectField'
import SelectFieldBoxes from '../../../../../components/form-components/SelectFieldBoxes'
import {
  useAreasPaginated,
  useAssetsPaginated,
  useProductionLinesPaginated,
  useSensors,
} from '../../../../../core/requests/factory'
import {StepProps} from './_models'

const EntityStep = ({data, updateData, hasError}: StepProps) => {
  const {
    items: areas,
    isFetching: areasFetching,
    fetchNextPage: areasNextPage,
  } = useAreasPaginated({}, {enabled: data.entity_group === 'area'})

  const {
    items: assets,
    isFetching: assetsFetching,
    fetchNextPage: assetsNextPage,
  } = useAssetsPaginated({}, {enabled: data.entity_group === 'asset'})

  const {
    items: productionLines,
    isFetching: productionLinesFetching,
    fetchNextPage: productionLinesNextPage,
  } = useProductionLinesPaginated({}, {enabled: data.entity_group === 'production_line'})

  const {data: sensors} = useSensors({
    area: data.entity_group === 'area' ? data.entity : undefined,
    asset: data.entity_group === 'asset' ? data.entity : undefined,
    productionLine: data.entity_group === 'production_line' ? data.entity : undefined,
    options: {enabled: !!data.entity},
  })

  let selectedCategoryName
  let selectedEntities
  let isFetching = false
  let gotoNextPage
  switch (data.entity_group) {
    case 'area':
      selectedEntities = areas
      isFetching = areasFetching
      gotoNextPage = areasNextPage
      selectedCategoryName = 'Area'
      break
    case 'asset':
      selectedEntities = assets
      isFetching = assetsFetching
      gotoNextPage = assetsNextPage
      selectedCategoryName = 'Asset'
      break
    case 'production_line':
      selectedEntities = productionLines
      isFetching = productionLinesFetching
      gotoNextPage = productionLinesNextPage
      selectedCategoryName = 'Production Line'
      break
    default:
      break
  }

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* Entity Group filter */}
        <SelectFieldBoxes
          name='Entity Group'
          optionNames={['Area', 'Production Line', 'Asset']}
          optionValues={['area', 'production_line', 'asset']}
          data={data}
          updateData={updateData}
          dataField='entity_group'
          required
          hasError={hasError}
          colSpace={4}
          updatesOnChange={{entity: undefined, entities: [], data_types: []}}
        />

        {/* Specific filter entity */}
        {data.entity_group && (
          <LoadingWrapper loading={selectedEntities === undefined}>
            {selectedEntities && selectedEntities.length <= 3 && (
              <SelectFieldBoxes
                name={selectedCategoryName || ''}
                optionNames={selectedEntities?.map((entity) => entity.name) || []}
                optionValues={selectedEntities?.map((entity) => entity._id) || []}
                data={data}
                updateData={updateData}
                dataField='entity'
                required
                hasError={hasError}
                colSpace={4}
                updatesOnChange={{entities: []}}
              />
            )}
            {selectedEntities && selectedEntities.length > 3 && (
              <SelectField
                name={selectedCategoryName || ''}
                optionNames={selectedEntities?.map((entity) => entity.name) || []}
                optionValues={selectedEntities?.map((entity) => entity._id) || []}
                data={data}
                updateData={updateData}
                dataField='entity'
                required
                hasError={hasError}
                updatesOnChange={() => ({entities: [], data_types: []})}
                isLoading={isFetching}
                gotoNextPage={gotoNextPage}
              />
            )}
          </LoadingWrapper>
        )}

        {/* Specific entity */}
        {data.entity && (
          <LoadingWrapper loading={sensors?.items === undefined}>
            <SelectFieldTable
              name='Sensors'
              columnNames={['Placement', 'ID', 'Types']}
              optionRows={
                sensors?.items.map((sensor) => [
                  sensor.placement.name,
                  sensor.device_id,
                  sensor.data_types.join(', '),
                ]) || []
              }
              optionValues={sensors?.items.map((sensor) => sensor._id) || []}
              data={data}
              updateData={updateData}
              updatesOnChange={() => ({data_types: []})}
              dataField='entities'
              required
              hasError={hasError}
              height='400px'
              lastField
            />
          </LoadingWrapper>
        )}
      </div>
    </div>
  )
}

export default EntityStep
