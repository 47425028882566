import {Reference} from '../../../../core/_models'
import {useFactory} from '../../../../core/requests/factory'
import useModalState from '../../../../hooks/UseModalState'
import {FactoryOverviewDetailsModal} from '../../../../modals/overview/FactoryOverviewDetailsModal'

type FactoryCellProps = {
  factoryReference: Reference
}

const FactoryCell = ({factoryReference}: FactoryCellProps) => {
  const detailsModal = useModalState()

  const {data: factory} = useFactory({id: factoryReference._id})

  return (
    <>
      <div className='d-flex align-items-center'>
        <a
          className='text-gray-800 text-hover-primary mb-1 cursor-pointer'
          onClick={detailsModal.open}
        >
          {factoryReference.name}
        </a>
      </div>

      {factory && (
        <FactoryOverviewDetailsModal
          entity={factory}
          show={detailsModal.isOpen}
          onHide={detailsModal.close}
        />
      )}
    </>
  )
}

export default FactoryCell
