import {formatDuration, intervalToDuration} from 'date-fns'
import {OperationalStates} from '../../../../../gql/graphql'
import LoadingWrapper from '../../../../components/LoadingWrapper'
import useGetProductionLineConsumptionByCategory from '../../../../core/graphql/energy/queries/GetProductionLineConsumptionByCategory'
import {usePlacement} from '../../../../core/requests/factory'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {PieGraph} from '../../components/charts/PieGraph'
import {PieData} from '../../core/_chartConfigs/pieConfig'
import {WidgetProps} from './Widget'
import * as am5 from '@amcharts/amcharts5'

interface EnergyLegendProps {
  name: string
  consumption: number
  time: number
}

const EnergyLegend = ({name, consumption, time}: EnergyLegendProps) => (
  <>
    <strong>{name}</strong>
    <ul>
      <li className={`text-nowrap`}>
        {formatDuration(
          intervalToDuration({
            start: 0,
            end: time * 1000,
          })
        )}
      </li>
      <li className={`text-nowrap`}>{consumption} kWh</li>
    </ul>
  </>
)

const EnergyPiechartWidget = ({widget, height}: WidgetProps) => {
  const {data: placement} = usePlacement(widget.entity)

  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const {consumptions, isLoading} = useGetProductionLineConsumptionByCategory({
    id: placement?.production_line?._id,
    placements: widget.entities,
    masterPlacement: widget.entity,
    operationalIntervalMins: widget.operational_state_intervals?.map((v) => v[0]),
    operationalIntervalMaxs: widget.operational_state_intervals?.map((v) => v[1]),
    operationalStates:
      widget.operational_states && widget.operational_states.length
        ? (widget.operational_states.map((os) => os.toUpperCase()) as OperationalStates[])
        : [OperationalStates.Running, OperationalStates.Standby],
    startTime,
    endTime,
  })

  const pieData: PieData[] =
    consumptions?.map((category) => ({
      label: category.state,
      value: category.consumption / 1000,
      color: StateToColor(category.state),
    })) ?? []

  return (
    <div className={`d-flex`}>
      <LoadingWrapper loading={isLoading}>
        <PieGraph id={widget._id} height={height} data={pieData} />
        <div className={`p-5`}>
          {consumptions?.map((category) => (
            <EnergyLegend
              name={category.state}
              consumption={Math.round(category.consumption) / 1000}
              time={category.time}
            />
          ))}
        </div>
      </LoadingWrapper>
    </div>
  )
}

const StateToColor = (state: OperationalStates) => {
  switch (state) {
    case OperationalStates.Running:
      return am5.color('#228002')
    case OperationalStates.Standby:
      return am5.color('#7d85b9')
    case OperationalStates.Shutdown:
      return am5.color('#ff0202')
  }
}

export default EnergyPiechartWidget
