import Select from 'react-select'
import {Factory} from '../../../../core/_models'
import {useFactoriesPaginated} from '../../../../core/requests/factory'

type FactorySelectProps = {
  onChange: (factory?: Factory) => void
}

const FactorySelect = ({onChange}: FactorySelectProps) => {
  const {
    items: factories,
    isFetching: factoriesFetching,
    fetchNextPage: factoriesNextPage,
  } = useFactoriesPaginated()

  return (
    <Select
      placeholder={`Factory`}
      onChange={(e) => onChange(factories?.find((f) => f._id === e?.value))}
      options={
        factories?.map((f) => ({
          value: f._id,
          label: f.name,
        })) || []
      }
      isClearable
      className={'me-3'}
      isLoading={factoriesFetching}
      onMenuScrollToBottom={() => factoriesNextPage()}
    />
  )
}

export default FactorySelect
