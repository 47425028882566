import Select from 'react-select'
import {Asset} from '../../../../core/_models'
import {useAssetsPaginated} from '../../../../core/requests/factory'

type AssetSelectProps = {
  onChange: (asset?: Asset) => void
  isDisabled?: boolean
  filterFunction?: (asset: Asset) => boolean
}

const AssetSelect = ({
  onChange,
  isDisabled = false,
  filterFunction = () => true,
}: AssetSelectProps) => {
  const {
    items: assets,
    isFetching: assetsFetching,
    fetchNextPage: assetsNextPage,
  } = useAssetsPaginated()

  const filteredAssets = assets?.filter(filterFunction) || []

  return (
    <Select
      placeholder={`Asset`}
      onChange={(e) => onChange(filteredAssets.find((a) => a._id === e?.value))}
      options={
        filteredAssets.map((a) => ({
          value: a._id,
          label: a.name,
        })) || []
      }
      isClearable
      className={'me-3'}
      isLoading={assetsFetching}
      onMenuScrollToBottom={() => assetsNextPage()}
      isDisabled={isDisabled}
    />
  )
}

export default AssetSelect
