import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import Pagination from '../../../components/pagination/Pagination'
import {useProfile} from '../../../core/requests/auth'
import {deleteFactory, useFactories} from '../../../core/requests/factory'
import useGridSwitch from '../../../hooks/UseGridSwitch'
import {useCreateModals} from '../../../modals/CreateModalsProvider'
import {FactoryOverviewDetailsModal} from '../../../modals/overview/FactoryOverviewDetailsModal'
import {useSelectedRows} from '../../../providers/SelectedRowsProvider'
import {TableBody} from '../core/columns/TableBody'
import {TableSelectionToolbar} from '../core/columns/TableSelectionToolbar'
import {TableToolbar} from '../core/columns/TableToolbar'
import OrganizationSelect from '../core/components/OrganizationSelect'
import ListFilter from '../core/components/ListFilter'
import {OverviewGridItem} from '../core/components/overview/OverviewGridItem'
import useFactoriesFilter from '../core/hooks/useFactoriesFilter'
import {Columns} from './Columns'

export const FactoriesWrapper = () => {
  const {isGridView} = useGridSwitch()

  const {data: profile} = useProfile()
  const {selected, setDataItems} = useSelectedRows()
  const {openCreateFactoryModal} = useCreateModals()

  const {filterState, filterCount, activePage, setActivePage, factoriesFilterProps} =
    useFactoriesFilter()

  const {data: factories, isLoading: factoriesLoading} = useFactories(factoriesFilterProps)
  const columns = React.useMemo(() => Columns, [])

  const [uuid, setUUID] = React.useState<string>()
  const factory = factories?.items.find((f) => f._id === uuid)

  const filters: React.ReactNode[] = []
  if (profile?.is_staff) {
    filters.push(<OrganizationSelect onChange={filterState.organization.setValue} />)
  }

  React.useEffect(() => {
    if (factories?.items !== undefined) {
      setDataItems(factories?.items)
    }
  }, [factories?.items])

  return (
    <KTCard className={`${isGridView ? 'bg-transparent' : undefined}`}>
      {/* Header */}
      <div
        className={`card-header border-0 justify-content-end pt-6 bg-white ${
          isGridView ? 'mb-5 pb-5 rounded' : ''
        }`}
      >
        {/* <ListSearchComponent /> */}
        <div className='card-toolbar'>
          {!!filters.length && <ListFilter count={filterCount} filters={filters} />}
          {/* Standard toolbar */}
          {selected.length === 0 && (
            <TableToolbar buttonText='Add' onButtonClick={openCreateFactoryModal} />
          )}

          {/* Selection toolbar */}
          {selected.length > 0 && (
            <TableSelectionToolbar onDelete={deleteFactory} queryKey='factories' />
          )}
        </div>
      </div>

      {isGridView ? (
        <div className={`row g-6 g-xl-9 mb-4`} id='hs_factories_table'>
          {factories?.items.map((item) => (
            <OverviewGridItem
              item={item}
              title={item.name}
              description={item.organization.name}
              imageUrl={item.gallery?.profile_image.thumbnail_url}
              onClick={setUUID}
            />
          ))}
        </div>
      ) : (
        <TableBody
          tableId='hs_factories_table'
          columns={columns}
          dataList={factories?.items}
          loading={factoriesLoading}
          onCellClick={setUUID}
        />
      )}

      {factories?.total ? (
        <Pagination
          page={activePage}
          total={factories.total}
          size={factories.size}
          onClick={(p) => setActivePage(p)}
        />
      ) : null}

      {factory && (
        <FactoryOverviewDetailsModal entity={factory} onHide={() => setUUID(undefined)} />
      )}
    </KTCard>
  )
}
