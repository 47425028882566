import React from 'react'
import {Placement} from '../../../core/_models'
import {useSensors, useSensorsPaginated} from '../../../core/requests/factory'
import SensorNode from './SensorNode'
import TreeNode from './TreeNode'

interface PlacementNodeProps {
  placement: Placement
}

export const PlacementNode = ({placement}: PlacementNodeProps) => {
  const [opened, setOpen] = React.useState(false)
  const {data: sensors} = useSensors({placement: placement._id})
  const {items, isFetching, hasNextPage, fetchNextPage} = useSensorsPaginated(
    {placement: placement._id},
    {
      enabled: opened,
    }
  )

  React.useEffect(() => {
    if (!isFetching && hasNextPage) {
      ;(async () => {
        await fetchNextPage()
      })()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  const nodes = items?.map((sensor) => <SensorNode sensor={sensor} />)

  return (
    <TreeNode
      name={placement.name}
      iconPath={`/media/icons/duotune/general/gen018.svg`}
      opened={opened}
      setOpen={setOpen}
      nodes={nodes}
      isExpandable={!!sensors?.total}
    />
  )
}

export default PlacementNode
