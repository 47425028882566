import React from 'react'
import {ProductionLine} from '../../../core/_models'
import {useSections, useSectionsPaginated} from '../../../core/requests/factory'
import SectionNode from './SectionNode'
import TreeNode from './TreeNode'

interface ProductionLineNodeProps {
  productionLine: ProductionLine
}

export const ProductionLineNode = ({productionLine}: ProductionLineNodeProps) => {
  const [opened, setOpen] = React.useState(false)
  const {data: sections} = useSections({productionLine: productionLine._id})
  const {items, isFetching, hasNextPage, fetchNextPage} = useSectionsPaginated(
    {production_line: productionLine._id},
    {
      enabled: opened,
    }
  )

  React.useEffect(() => {
    if (!isFetching && hasNextPage) {
      ;(async () => {
        await fetchNextPage()
      })()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  const nodes = items?.map((section) => <SectionNode section={section} />)

  return (
    <TreeNode
      name={productionLine.name}
      iconPath={`/media/icons/duotune/technology/teh005.svg`}
      opened={opened}
      setOpen={setOpen}
      nodes={nodes}
      isExpandable={!!sections?.total}
    />
  )
}

export default ProductionLineNode
