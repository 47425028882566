import {ReactNode, createContext, useContext, useState} from 'react'
import {EventCategory, EventCategoryGroup, SubEventCategory} from '../../core/_models'
import {UpdateEventCategoryProps, updateEventCategory} from '../../core/requests/oee'
import {useQueryClient} from 'react-query'

interface CategorySelectionContextType {
  activeCategoryGroup?: EventCategoryGroup
  setActiveCategoryGroup: (value?: EventCategoryGroup) => void
  activeCategory?: EventCategory
  activeSubEventCategoryPathIds: string[]
  setActiveCategory: (
    value: EventCategory | undefined,
    activeSubEventCategoryPathIds: string[]
  ) => void
  findSubEventCategoryByPath: (
    eventCategory: EventCategory,
    path: string[]
  ) => SubEventCategory | undefined
  collectCategoryIds: (category: EventCategory | SubEventCategory) => string[]
  collectAllCategoryObjects: (
    category: EventCategory | SubEventCategory,
    includeRoot?: boolean
  ) => (EventCategory | SubEventCategory)[]
  updateCategory: (
    eventCategory: EventCategory,
    subEventCategoryPathIds: string[],
    updates: Partial<UpdateEventCategoryProps>,
    navigateToCategory?: boolean
  ) => Promise<EventCategory>
  showDeleteModal: boolean
  openDeleteModal: (
    categorytoDelete: EventCategoryGroup | EventCategory,
    subEventCategoryPathIds?: string[]
  ) => void
  closeDeleteModal: () => void
  categoryToDelete?: EventCategory | EventCategoryGroup
  subEventCategoryPathIdsToDelete: string[]
}

interface CategorySelectionProviderProps {
  children: ReactNode
}

const CategorySelectionContext = createContext<CategorySelectionContextType | undefined>(undefined)

export const CategorySelectionProvider = ({children}: CategorySelectionProviderProps) => {
  const [activeCategoryGroup, setActiveCategoryGroupState] = useState<EventCategoryGroup>()
  const [activeCategory, setActiveCategoryState] = useState<EventCategory>()
  const [activeSubEventCategoryPathIds, setActiveSubEventCategoryPathIdsState] = useState<string[]>(
    []
  )
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [categoryToDelete, setCategoryToDelete] = useState<
    EventCategory | EventCategoryGroup | undefined
  >(undefined)
  const [subEventCategoryPathIdsToDelete, setSubEventCategoryPathIdsToDelete] = useState<string[]>(
    []
  )

  const queryClient = useQueryClient()

  const setActiveCategoryGroup = (categoryGroup?: EventCategoryGroup) => {
    setActiveCategoryGroupState(categoryGroup)
    setActiveCategoryState(undefined)
    setActiveSubEventCategoryPathIdsState([])
  }

  const setActiveCategory = (
    category: EventCategory | undefined,
    activeSubEventCategoryPathIds: string[]
  ) => {
    setActiveCategoryState(category)
    setActiveSubEventCategoryPathIdsState(activeSubEventCategoryPathIds)
    setActiveCategoryGroupState(undefined)
  }

  const findSubEventCategoryByPath = (
    eventCategory: EventCategory,
    subEventCategoryPathIds: string[]
  ): SubEventCategory | undefined => {
    const findSubEventCategory = (
      subCategories: SubEventCategory[],
      pathIds: string[]
    ): SubEventCategory | undefined => {
      if (pathIds.length === 0) return undefined

      const currentId = pathIds[0]
      const currentSubCategory = subCategories.find((subCat) => subCat._id === currentId)

      if (pathIds.length === 1 || !currentSubCategory) return currentSubCategory
      return findSubEventCategory(currentSubCategory.sub_event_categories, pathIds.slice(1))
    }

    return findSubEventCategory(eventCategory.sub_event_categories, subEventCategoryPathIds)
  }

  const updateCategory = async (
    eventCategory: EventCategory,
    subEventCategoryPathIds: string[],
    updates: Partial<UpdateEventCategoryProps>,
    navigateToCategory: boolean = true
  ) => {
    const isRootCategory = subEventCategoryPathIds.length === 0
    const updatePayload: UpdateEventCategoryProps = {
      id: eventCategory._id,
    }

    if (!isRootCategory) {
      const eventCategoryCopy = {...eventCategory}
      const subCategory = findSubEventCategoryByPath(eventCategoryCopy, subEventCategoryPathIds)
      Object.assign(subCategory!, updates)
      updatePayload.subEventCategories = eventCategoryCopy.sub_event_categories
    } else {
      Object.assign(updatePayload, updates)
    }

    const updatedEventCategory = await updateEventCategory(updatePayload)
    await queryClient.invalidateQueries('eventCategories')
    if (navigateToCategory)
      setActiveCategory(updatedEventCategory, isRootCategory ? [] : subEventCategoryPathIds)
    return updatedEventCategory
  }

  const collectCategoryIds = (category: EventCategory | SubEventCategory): string[] => {
    const ids: string[] = [category._id!]
    for (const subCategory of category.sub_event_categories)
      ids.push(...collectCategoryIds(subCategory))
    return ids
  }

  const collectAllCategoryObjects = (
    category: EventCategory | SubEventCategory,
    includeRoot: boolean = true
  ): (EventCategory | SubEventCategory)[] => {
    const results: (EventCategory | SubEventCategory)[] = includeRoot ? [category] : []
    for (const subCategory of category.sub_event_categories) {
      const subResults = collectAllCategoryObjects(subCategory)
      results.push(...subResults)
    }
    return results
  }

  const openDeleteModal = (
    categorytoDelete: EventCategoryGroup | EventCategory,
    subEventCategoryPathIds: string[] = []
  ) => {
    setShowDeleteModal(true)
    setCategoryToDelete(categorytoDelete)
    setSubEventCategoryPathIdsToDelete(subEventCategoryPathIds)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
    setCategoryToDelete(undefined)
    setSubEventCategoryPathIdsToDelete([])
  }

  return (
    <CategorySelectionContext.Provider
      value={{
        activeCategoryGroup,
        setActiveCategoryGroup,
        activeCategory,
        setActiveCategory,
        activeSubEventCategoryPathIds,
        findSubEventCategoryByPath,
        collectCategoryIds,
        collectAllCategoryObjects,
        updateCategory,
        showDeleteModal,
        openDeleteModal,
        closeDeleteModal,
        categoryToDelete,
        subEventCategoryPathIdsToDelete,
      }}
    >
      {children}
    </CategorySelectionContext.Provider>
  )
}

export const useCategorySelection = () => {
  const context = useContext(CategorySelectionContext)
  if (context === undefined)
    throw new Error('useEventCategories must be used within a EventCategoriesProvider')
  return context
}
