/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {createToken, createUser, getProfile} from '../../../core/requests/auth'
import axios from 'axios'

const initialValues = {
  email: '',
  password: '',
  phoneNumber: '',
  repeatPassword: '',
  invitationCode: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  repeatPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Passwords do not match. Please re-enter your passwords'
      ),
    }),
  invitationCode: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const {saveAuthToken, setCurrentProfile} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await createUser({
          email: values.email,
          password: values.password,
          accepts_gdpr: values.acceptTerms,
          phone_number: values.phoneNumber != '' ? values.phoneNumber : undefined,
          invitation: values.invitationCode != '' ? values.invitationCode : undefined,
        })
        const authToken = await createToken({email: values.email, password: values.password})
        saveAuthToken(authToken)
        const profile = await getProfile()
        setCurrentProfile(profile)
      } catch (error) {
        console.log('error', error)
        saveAuthToken(undefined)
        setSubmitting(false)
        setLoading(false)

        if (axios.isAxiosError(error)) {
          if (error.response?.data.detail) {
            setStatus(error.response.data.detail)
          } else {
            setStatus('Account creation failed due to a server error. Please try again later')
          }
        } else {
          setStatus(
            'Account creation failed due to a network error. Please check your connection and try again'
          )
        }
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
      </div>

      {/* Error Msg */}
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* Email Input */}
      <div className='fv-row mb-8'>
        <label className='form-label required'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      {/* Password Input */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <div className='position-relative mb-3'>
            <label className='form-label required'>Password</label>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </div>

        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>

      {/* Repeat Password Input */}
      <div className='fv-row mb-8'>
        <label className='form-label required'>Repeat Password</label>
        <input
          type='password'
          placeholder='Repeat Password'
          autoComplete='off'
          {...formik.getFieldProps('repeatPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.repeatPassword && formik.errors.repeatPassword,
            },
            {
              'is-valid': formik.touched.repeatPassword && !formik.errors.repeatPassword,
            }
          )}
        />
        {formik.touched.repeatPassword && formik.errors.repeatPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.repeatPassword}</span>
            </div>
          </div>
        )}
      </div>

      {/* Invitation Code Input */}
      <div className='fv-row mb-8'>
        <label className='form-label'>
          <span>Invitation Code</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Enter your invitation code here if you received one via email'
          />
        </label>
        <input
          placeholder='Invitation Code'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('invitationCode')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.invitationCode && formik.errors.invitationCode},
            {
              'is-valid': formik.touched.invitationCode && !formik.errors.invitationCode,
            }
          )}
        />
        {formik.touched.invitationCode && formik.errors.invitationCode && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.invitationCode}</span>
            </div>
          </div>
        )}
      </div>

      {/* Phone Input */}
      <div className='fv-row mb-8'>
        <label className='form-label'>Phone Number</label>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='Phone Number including country code e.g, +45 for Denmark'
        />
        <input
          placeholder='Phone Number (e.g., +45 12345678)'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('phoneNumber')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber},
            {
              'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
            }
          )}
        />
        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phoneNumber}</span>
            </div>
          </div>
        )}
      </div>

      {/* Accept Terms Input */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the
            <a
              href='https://hexastate.com/cookie-and-privacy-policy/'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>

      {/* Submit */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Already have an Account?{' '}
        <Link to='/auth' className='link-primary fw-semibold'>
          Sign in
        </Link>
      </div>
    </form>
  )
}
