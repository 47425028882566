import {useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import Flatpickr from 'react-flatpickr'

interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  id: string
}

const TimeField = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
  id,
}: Props<DataType>) => {
  const handleResize = () => {
    const parentElement = document.getElementById(`${id}parentElement`)
    const parentWidth = parentElement?.offsetWidth
    const flatpickrElement = document.getElementById(`${id}flatpickrElement`)
    if (parentWidth && flatpickrElement) flatpickrElement.style.width = parentWidth + 'px'
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize)
    handleResize()
    const parentElement = document.getElementById(`${id}parentElement`)
    if (parentElement) resizeObserver.observe(parentElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>
        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>

      <div className='position-relative' id={`${id}parentElement`}>
        <div style={{position: 'relative', zIndex: '1'}}>
          <KTSVG
            path='/media/icons/duotune/general/gen013.svg'
            className='svg-icon-2 position-absolute top-50 translate-middle-y mx-3 mt-6'
          />
        </div>
        <Flatpickr
          data-enable-time
          className='form-control form-control-solid ps-12'
          placeholder={`Select ${name.toLowerCase()}`}
          options={{dateFormat: 'H:i:S', time_24hr: true, static: true, noCalendar: true}}
          value={data[dataField]}
          onChange={(dates) => {
            updateData({
              [dataField]:
                dates.length === 0
                  ? undefined
                  : `${dates[0].getHours().toString().padStart(2, '0')}:${dates[0]
                      .getMinutes()
                      .toString()
                      .padStart(2, '0')}:${dates[0].getSeconds().toString().padStart(2, '0')}`,
            } as Partial<DataType>)
          }}
          style={{position: 'relative', zIndex: '0'}}
          id={`${id}flatpickrElement`}
        />
      </div>
      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export default TimeField
