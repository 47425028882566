import {useQueryClient} from 'react-query'
import {Placement, Subscription} from '../../core/_models'
import {useEffect, useState} from 'react'
import {ICreatePlacementAlert, defaultCreatePlacementAlert} from './_models'
import {createSubscription, updateSubscription} from '../../core/requests/notification'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

type Props = {
  show: boolean
  hide: () => void
  placement: Placement
  edit?: Subscription
}

const CreatePlacementAlertModal = ({show, hide, placement, edit}: Props) => {
  const queryClient = useQueryClient()
  const [data, setData] = useState<ICreatePlacementAlert>({
    ...defaultCreatePlacementAlert,
    placement: placement,
  })

  useEffect(() => {
    if (edit) setData(subscriptionToICreatePlacementAlert(edit, placement))
    else setData({...defaultCreatePlacementAlert, placement: placement})
  }, [edit, placement])

  const steps: Step<ICreatePlacementAlert>[] = [
    {
      title: 'Alert Type',
      description: 'Select Alert Type',
      component: Step1,
      validation: (data) => data.topic === undefined,
    },
    {
      title: 'Type Specific Settings',
      description: 'Configure Type Specific Settings',
      component: Step2,
      validation: (data) =>
        data.data_types === undefined ||
        (data.data_types.includes('vibration') && data.axis === undefined) ||
        (data.topic === 'threshold-crossed' &&
          data.minimum === undefined &&
          data.maximum === undefined),
    },
    {
      title: 'Alert Settings',
      description: 'Configure Alert Settings',
      component: Step3,
      validation: (data) => data.channels === undefined,
    },
  ]

  const createPlacementAlert = async (data: ICreatePlacementAlert) => {
    data.entities = [placement._id]
    const sucbscription = await createSubscription(data)
    queryClient.invalidateQueries('subscriptions')
    return sucbscription
  }

  const editPlacementAlert = async (data: ICreatePlacementAlert) => {
    const sucbscription = await updateSubscription(edit!._id, data)
    queryClient.invalidateQueries('subscriptions')
    return sucbscription
  }

  return (
    <StepModalWrapper<ICreatePlacementAlert, Subscription>
      id='hs_create_planner_setup_modal'
      title={edit ? 'Edit Placement Alert' : 'Create Placement Alert'}
      show={show}
      close={hide}
      steps={steps}
      data={data}
      defaultData={defaultCreatePlacementAlert}
      setData={setData}
      submitData={edit !== undefined ? editPlacementAlert : createPlacementAlert}
    />
  )
}

const subscriptionToICreatePlacementAlert = (
  subscription: Subscription,
  placement: Placement
): ICreatePlacementAlert => {
  return {
    topic: subscription.topic as 'point-anomaly-detected' | 'threshold-crossed',
    channels: subscription.channels,
    is_enabled: subscription.is_enabled,
    additional_message: subscription.additional_message,
    cooldown_hours: subscription.cooldown_hours,
    entities: subscription.entities,
    zones: subscription.zones,
    data_types: subscription.data_types,
    axis: subscription.axis,
    name: subscription.name,
    minimum: subscription.minimum,
    maximum: subscription.maximum,
    n_neighbours: subscription.n_neighbours,
    placement: placement,
  }
}

export default CreatePlacementAlertModal
