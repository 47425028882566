import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../../../core/requests/auth'

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const formik = useFormik({
    initialValues: {email: ''},
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await resetPassword({email: values.email})
        setSuccess(true)
      } catch (error) {
        console.error(error)
        setStatus('Request failed. Please check the email and try again')
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  if (success) {
    return (
      <>
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Success</h1>
          <div className='text-gray-500 fw-semibold fs-6'>
            Check your email! We've sent you a link to reset your password.
          </div>
        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <Link to='/auth/new-password'>
            <button type='submit' className='btn btn-primary'>
              <span className='indicator-label'>Reset Password</span>
            </button>
          </Link>
        </div>
      </>
    )
  }

  return (
    <form className='form w-100' noValidate onSubmit={formik.handleSubmit}>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password?</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password
        </div>
      </div>

      {formik.status && (
        <div className='mb-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* Email Input */}
      <div className='fv-row mb-11'>
        <label className='form-label required'>Email</label>
        <input
          type='email'
          placeholder='Email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      {/* Submit */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Already reset your password?{' '}
        <Link to='/auth' className='link-primary fw-semibold'>
          Sign in
        </Link>
      </div>
    </form>
  )
}
