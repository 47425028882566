import {useState} from 'react'
import StepModalWrapper, {Step} from '../../../../../components/StepModalWrapper'
import {useDashboardGrid} from '../../../../../providers/DashboardGridProvider'
import {createWidget, updateWidget} from '../../../../../core/requests/template'
import {BaseModel, Widget} from '../../../../../core/_models'
import {useQueryClient} from 'react-query'
import {WidgetType, getDisplayName} from '../../widgets/_models'
import {IEnergyWidget, defaultCreateEnergyWidget} from './_models'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

interface Props {
  show: boolean
  hide: () => void
  cancel?: () => void
  type: WidgetType
  widgetData?: Widget & BaseModel
}

const EnergyWidgetModal = ({show, hide, cancel, type, widgetData}: Props) => {
  const queryClient = useQueryClient()
  const {activeDashboardId} = useDashboardGrid()
  const [data, setData] = useState<IEnergyWidget>({
    ...defaultCreateEnergyWidget,
    type: type,
    ...widgetData,
  })

  const steps: Step<IEnergyWidget>[] = [
    {
      title: 'Production Scope',
      description: 'Choose a production line or section to monitor',
      component: Step1,
      validation: (data) => data.entity_group === undefined || data.entity === undefined,
    },
    {
      title: 'Timeframe',
      description: 'Specify the data range',
      component: Step2,
      validation: (data) =>
        (data.start_time === undefined && data.dynamic_time_range === undefined) ||
        ((!!data.start_time || !!data.end_time) && !!data.dynamic_time_range),
    },
    {
      title: 'Name',
      description: 'Finalize the widget',
      component: Step3,
      validation: (data) => data.name === undefined,
    },
  ]

  const submit = async (data: IEnergyWidget) => {
    data.dashboard = activeDashboardId!
    data.data_types = [type]
    const widget = data._id ? await updateWidget(data._id, data) : await createWidget(data)
    await queryClient.invalidateQueries({queryKey: 'widgets'})
    return widget
  }

  return (
    <StepModalWrapper<IEnergyWidget, Widget>
      id='energy_widget_modal'
      title={`${data._id ? 'Update' : 'Create'} ${getDisplayName(type)} Widget`}
      show={show}
      close={hide}
      cancel={cancel}
      steps={steps}
      data={data}
      defaultData={defaultCreateEnergyWidget}
      setData={setData}
      submitData={submit}
    />
  )
}

export default EnergyWidgetModal
