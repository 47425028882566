import {Part} from '../../core/_models'

type PartDetailProps = {
  part: Part
  onCancel: () => void
}

const fields = {
  _id: 'ID',
  type: 'Type',
  manufacturer: 'Manufacturer',
  model_number: 'Model Number',
  kilowatt: 'Kilowatt',
  rotor_bars: 'Rotor Bars',
  stator_coils: 'Stator Coils',
  vanes: 'Vanes',
  line_frequency: 'Line Frequency',
  rolling_elements: 'Rolling Elements',
  inner_diameter: 'Inner Diameter',
  outer_diameter: 'Outer Diameter',
  damin_d1: 'damin_d1',
  damax_d2: 'damax_d2',
  contact_angle: 'Contact Angle',
}

export const PartDetail = ({onCancel, part}: PartDetailProps) => {
  const options = Object.keys(fields).map((k) => {
    const value = part[k as keyof Part]
    return value ? (
      <div className='row mb-7' key={k}>
        <label className='col fw-semibold text-muted'>{fields[k as keyof typeof fields]}</label>
        <div className='col-md-auto'>
          <span className='fw-bold fs-6 text-gray-800'>{value as string}</span>
        </div>
      </div>
    ) : null
  })

  return (
    <>
      {options}
      <div className={`d-flex`}>
        <button className={`btn btn-primary mx-auto`} onClick={onCancel}>
          Close
        </button>
      </div>
    </>
  )
}
