import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'
import {
  AMIModels,
  Manufacturer,
  manufacturers,
  manufacturersMap,
  PartModel,
  PRESSACModels,
  SensorType,
  sensorTypes,
  sensorTypesMap,
  TREONModels,
  voltages,
} from '../../core/_models'
import {StepProps} from './_models'
import SelectField from '../../components/form-components/SelectField'
import SelectFieldBoxesMultiple from '../../components/form-components/SelectFieldBoxesMultiple'
import React from 'react'

const Step2 = ({data, updateData, hasError}: StepProps) => {
  const [trackScrap, setTrackScrap] = React.useState(false)

  let models: readonly string[] = []
  switch (data.manufacturer) {
    case manufacturersMap.AMI:
      models = AMIModels
      break
    case manufacturersMap.TREON:
      models = TREONModels
      break
    case manufacturersMap.PRESSAC:
      models = PRESSACModels
      break
    default:
      break
  }

  const getSensorTypes = (manufacturer: Manufacturer, model?: PartModel) => {
    let sensorTypes: SensorType[] = []
    switch (manufacturer) {
      case manufacturersMap.AMI:
      case manufacturersMap.TREON:
        sensorTypes = [sensorTypesMap.vibration, sensorTypesMap.temperature]
        break
      case manufacturersMap.PRESSAC:
        if (model === 'CT Clamp') {
          sensorTypes = [sensorTypesMap.ampere]
        }
        break
      default:
        break
    }
    return sensorTypes
  }

  const displayVoltages =
    data.manufacturer === manufacturersMap.PRESSAC &&
    data.model === 'CT Clamp' &&
    data.data_types?.includes(sensorTypesMap.ampere)

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* Manufacturer */}
        <SelectField
          name='Manufacturer'
          optionNames={manufacturers}
          optionValues={manufacturers}
          data={data}
          updateData={updateData}
          dataField='manufacturer'
          updatesOnChange={(newValue) => ({
            model: undefined,
            data_types: getSensorTypes(newValue as Manufacturer),
            voltage: undefined,
          })}
          hasError={hasError}
          required
        />

        {/* Model */}
        {models?.length ? (
          <SelectField
            name='Model'
            optionNames={models}
            optionValues={models}
            data={data}
            updateData={updateData}
            updatesOnChange={(newValue) => ({
              data_types: getSensorTypes(data.manufacturer as Manufacturer, newValue as PartModel),
              voltage: undefined,
            })}
            dataField='model'
            hasError={hasError}
            required
          />
        ) : null}

        {/* Types */}
        <SelectFieldBoxesMultiple
          name='Types'
          optionNames={sensorTypes}
          optionValues={sensorTypes}
          data={data}
          updateData={updateData}
          updatesOnChange={(newValue) => ({
            voltage: newValue?.includes(sensorTypesMap.ampere) ? data.voltage : undefined,
          })}
          dataField='data_types'
        />

        {displayVoltages && (
          <SelectFieldBoxes
            name='Voltage'
            optionNames={voltages.map((v) => `${v}v`)}
            optionValues={voltages}
            data={data}
            updateData={updateData}
            dataField='voltage'
          />
        )}

        {/* Checkbox for 'Track Scrap?' */}
        {data.data_types && data.data_types.includes('output') && (
          <div className='fv-row'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Track Scrap?</span>
            </label>
            <div className='form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='scrap'
                checked={trackScrap}
                onChange={(e) => {
                  setTrackScrap(e.target.checked)
                  updateData({track_scrap: e.target.checked})
                }}
              />
              <label className='form-check-label' htmlFor='scrap'>
                Yes
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Step2
