import {Link} from 'react-router-dom'

interface Breadcrumb {
  title: React.ReactNode
  path: string
  isSeparator: boolean
  isActive: boolean
}

interface Props {
  title: React.ReactNode
  breadcrumbs?: Breadcrumb[]
  children?: React.ReactNode
}

const PageNavigationBar = ({title, breadcrumbs = [], children}: Props) => (
  <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
    <div id='kt_app_toolbar_container' className='p-0 d-flex flex-stack container-fluid'>
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'
      >
        <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
          {title}
        </h1>
        {breadcrumbs.length > 0 ? (
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0'>
            {breadcrumbs.map((item, index) => (
              <li className='breadcrumb-item' key={`${item.path}${index}`}>
                {!item.isSeparator ? (
                  <Link className='text-muted text-hover-primary' to={item.path}>
                    {item.title}
                  </Link>
                ) : (
                  <span className='bullet bg-gray-200 w-5px h-2px'></span>
                )}
              </li>
            ))}
            <li className='breadcrumb-item text-dark'>{title}</li>
          </ul>
        ) : null}
      </div>
      <div className='card-toolbar'>{children}</div>
    </div>
  </div>
)

export default PageNavigationBar
export type {Breadcrumb}
