import {
  EntityGroup,
  EntityGroupPretty,
  FactoryEntity,
  Gateway,
  Part,
  Placement,
  Sensor,
} from './_models'
import {
  isArea,
  isAsset,
  isFactory,
  isGateway,
  isPart,
  isPlacement,
  isPlc,
  isProductionLine,
  isSection,
  isSensor,
} from './entity-type-util'

export function gatewayToString(gateway: Gateway): string {
  return `${gateway.area.name} | ${gateway.device_id}`
}

export function sensorToString(sensor: Sensor): string {
  return `${sensor.area.name} | ${sensor.placement.name} | ${sensor.data_types.join(', ')}`
}

export function placementToString(placement: Placement): string {
  return `${placement.area.name} | ${placement.name}`
}

export function partToString(part: Part): string {
  return part.production_line
    ? `${part.production_line.name} | ${part.type}`
    : part.section
    ? `${part.section.name} | ${part.type}`
    : part.asset
    ? `${part.asset.name} | ${part.type}`
    : ''
}

export function entityToString(entity: FactoryEntity): string {
  if (isFactory(entity)) return entity.entity.name
  if (isArea(entity)) return entity.entity.name
  if (isProductionLine(entity)) return entity.entity.name
  if (isSection(entity)) return entity.entity.name
  if (isAsset(entity)) return entity.entity.name
  if (isPart(entity)) return partToString(entity.entity)
  if (isPlacement(entity)) return placementToString(entity.entity)
  if (isSensor(entity)) return sensorToString(entity.entity)
  if (isGateway(entity)) return gatewayToString(entity.entity)
  if (isPlc(entity)) return entity.entity.name
  throw new Error(`Unhandled entity type: ${entity.type}`)
}

export function entityGroupToString(entityGroup: EntityGroup): EntityGroupPretty {
  if (entityGroup === 'factory') return 'Factory'
  if (entityGroup === 'area') return 'Area'
  if (entityGroup === 'production_line') return 'Production Line'
  if (entityGroup === 'section') return 'Section'
  if (entityGroup === 'asset') return 'Asset'
  if (entityGroup === 'part') return 'Part'
  if (entityGroup === 'placement') return 'Placement'
  if (entityGroup === 'sensor') return 'Sensor'
  if (entityGroup === 'gateway') return 'Gateway'
  if (entityGroup === 'plc') return 'PLC'
  throw new Error(`Unhandled entity group: ${entityGroup}`)
}

export function entityGroupToStringPlural(entityGroup: EntityGroup): string {
  if (entityGroup === 'factory') return 'Factories'
  if (entityGroup === 'area') return 'Areas'
  if (entityGroup === 'production_line') return 'Production Lines'
  if (entityGroup === 'section') return 'Sections'
  if (entityGroup === 'asset') return 'Assets'
  if (entityGroup === 'part') return 'Parts'
  if (entityGroup === 'placement') return 'Placements'
  if (entityGroup === 'sensor') return 'Sensors'
  if (entityGroup === 'gateway') return 'Gateways'
  if (entityGroup === 'plc') return 'PLCs'
  throw new Error(`Unhandled entity group: ${entityGroup}`)
}

export const formatSubscriptionTopic = (input: string): string =>
  input
    .split('-')
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ')
