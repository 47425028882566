import axios, {AxiosError} from 'axios'
import qs from 'qs'
import {useMemo} from 'react'
import {useMutation, useQueries, useQuery, UseQueryOptions} from 'react-query'
import {
  usePaginatedInfiniteQuery,
  UsePaginatedInfiniteQueryOptions,
} from '../../components/pagination/usePaginatedInfiniteQuery'
import {IWorkOrder} from '../../pages/work-orders/modal/_models'
import {ICreateMaintenancePlan} from '../../pages/plans/modal/_models'
import {ICreateWorkOrderTemplate} from '../../pages/plans/templateModal/_models'
import {ICompleteWorkOrderData} from '../../pages/schedule/WorkOrderCompleteModal'
import {IInitiateWorkOrderData} from '../../pages/schedule/WorkOrderStartModal'
import {
  AxiosServerError,
  BaseModel,
  DeletionConfirmation,
  MaintenanceEvent,
  MaintenancePlan,
  OrganizationQueryProps,
  OrganizationRequestParams,
  PaginatedQueryProps,
  PaginatedRequestParams,
  PaginatedResponse,
  Priority,
  TimeField,
  WorkOrder,
  WorkOrderCategory,
  WorkOrderStatus,
  WorkOrderTemplate,
} from '../_models'

const MAINTENANCE_API_BASE_URL = process.env.REACT_APP_WA_MAINTENANCE_URL

// ----- MAINTENANCE PLANS ----- //

const MAINTENANCE_PLANS_URL = `${MAINTENANCE_API_BASE_URL}/maintenance-plans`

type UseMaintenancePlansProps = PaginatedQueryProps<MaintenancePlan & BaseModel> & {
  factoryEntity?: string
} & OrganizationQueryProps

type UseMaintenancePlansRequestParams = PaginatedRequestParams & {
  factory_entity?: string
} & OrganizationRequestParams

export function useMaintenancePlans(props: UseMaintenancePlansProps = {}) {
  const {organization, factoryEntity, page, size, options} = props
  const params: UseMaintenancePlansRequestParams = {
    organization: organization,
    factory_entity: factoryEntity,
    page: page,
    size: size,
  }

  return useQuery<PaginatedResponse<MaintenancePlan & BaseModel>, Error>(
    ['maintenancePlans', ...Object.values(params)],
    async () =>
      (
        await axios.get<PaginatedResponse<MaintenancePlan & BaseModel>>(MAINTENANCE_PLANS_URL, {
          params,
        })
      ).data,
    options
  )
}

export const createMaintenancePlan = async (props: ICreateMaintenancePlan) => {
  const {data: maintenancePlan} = await axios.post<MaintenancePlan & BaseModel>(
    MAINTENANCE_PLANS_URL,
    props
  )
  return maintenancePlan
}

export const updateMaintenancePlan = async (props: MaintenancePlan) => {
  const {data: maintenancePlan} = await axios.put<MaintenancePlan & BaseModel>(
    `${MAINTENANCE_PLANS_URL}/${props._id}`,
    props
  )
  return maintenancePlan
}

export async function deleteMaintenancePlan(id: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${MAINTENANCE_PLANS_URL}/${id}`)
  return data
}

// ----- MAINTENANCE LOGS ----- //

const MAINTENANCE_LOGS_URL = `${MAINTENANCE_API_BASE_URL}/maintenance-logs`

export type RegisterMaintenanceData = {
  uuid: string
  startTime: string
  endTime: string
  note?: string
}

export const registerMaintenance = async (data: RegisterMaintenanceData) => {
  const {uuid, startTime, endTime, note} = data
  const {data: response} = await axios.post<MaintenanceEvent>(MAINTENANCE_LOGS_URL, {
    uuid,
    start_time: startTime,
    end_time: endTime,
    note,
  })
  return response
}

export const useRegisterMaintenanceMutation = () => {
  return useMutation<MaintenanceEvent, AxiosError<AxiosServerError>, RegisterMaintenanceData>({
    mutationFn: registerMaintenance,
  })
}

// ----- WORK ORDER TEMPLATES ----- //

const WORK_ORDER_TEMPLATES_URL = `${MAINTENANCE_API_BASE_URL}/work-order-templates`

type UseWorkOrderTemplateProps = PaginatedQueryProps<WorkOrderTemplate & BaseModel> & {
  maintenancePlans?: string[]
  factoryEntity?: string
  assignees?: string[]
  priority?: Priority
  category?: WorkOrderCategory
  subCategory?: string
  isRecurring?: boolean
} & OrganizationQueryProps

type UseWorkOrderTemplateRequestParams = PaginatedRequestParams & {
  maintenance_plans?: string[]
  factory_entity?: string
  assignees?: string[]
  priority?: Priority
  category?: WorkOrderCategory
  sub_category?: string
  is_recurring?: boolean
}
// } & OrganizationRequestParams

export function useWorkOrderTemplates(props: UseWorkOrderTemplateProps = {}) {
  const {
    organization,
    maintenancePlans,
    factoryEntity,
    assignees,
    priority,
    category,
    subCategory,
    isRecurring,
    page,
    size,
    options,
  } = props
  const params: UseWorkOrderTemplateRequestParams = {
    // organization,
    maintenance_plans: maintenancePlans,
    factory_entity: factoryEntity,
    assignees,
    priority,
    category,
    sub_category: subCategory,
    is_recurring: isRecurring,
    page: page,
    size: size,
  }
  return useQuery<PaginatedResponse<WorkOrderTemplate & BaseModel>, Error>(
    ['workOrderTemplates', ...Object.values(params)],
    async () =>
      (
        await axios.get<PaginatedResponse<WorkOrderTemplate & BaseModel>>(
          WORK_ORDER_TEMPLATES_URL,
          {
            params,
            paramsSerializer: (params) =>
              qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
          }
        )
      ).data,
    options
  )
}

export async function createWorkOrderTemplate(props: ICreateWorkOrderTemplate) {
  const {data: workOrderTemplate} = await axios.post<WorkOrderTemplate & BaseModel>(
    WORK_ORDER_TEMPLATES_URL,
    props
  )
  return workOrderTemplate
}

export async function updateWorkOrderTemplate(id: string, data: ICreateWorkOrderTemplate) {
  const {data: workOrderTemplate} = await axios.put<WorkOrderTemplate & BaseModel>(
    `${WORK_ORDER_TEMPLATES_URL}/${id}`,
    data
  )
  return workOrderTemplate
}

export async function deleteWorkOrderTemplate(id: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${WORK_ORDER_TEMPLATES_URL}/${id}`)
  return data
}

export function useWorkOrderTemplatesNextOccurrences(
  workOrderTemplates: string[],
  {options}: {options?: UseQueryOptions<{time: string}, Error>} = {}
) {
  return useQueries(
    useMemo(
      () =>
        workOrderTemplates?.map((workOrderTemplate) => ({
          queryKey: ['workOrderTemplateNextOccurrence', workOrderTemplate],
          queryFn: async () => {
            const {data} = await axios.get<{time: string}>(
              `${WORK_ORDER_TEMPLATES_URL}/${workOrderTemplate}/next-occurrence`
            )
            return data
          },
          ...options,
        })) ?? [],
      [workOrderTemplates]
    )
  )
}

// ----- WORK ORDERS ----- //

const WORK_ORDERS_URL = `${MAINTENANCE_API_BASE_URL}/work-orders`

type UseWorkOrdersProps = PaginatedQueryProps<WorkOrder & BaseModel> & {
  maintenancePlans?: string[]
  workOrderTemplates?: string[]
  factoryEntities?: string[]
  category?: WorkOrderCategory[]
  statuses?: WorkOrderStatus[]
  plannedStartTime?: string
  startTime?: string
  timeField?: TimeField
  endTime?: string
}

type UseWorkOrdersParams = PaginatedRequestParams & {
  maintenance_plans?: string[]
  work_order_templates?: string[]
  factory_entities?: string[]
  category?: WorkOrderCategory[]
  statuses?: WorkOrderStatus[]
  planned_start_time?: string
  start_time?: string
  time_field?: TimeField
  end_time?: string
  order?:
    | '+planned_start_time'
    | '-planned_start_time'
    | '+end_time'
    | '-end_time'
    | '+start_time'
    | '-start_time'
}

export const fetchWorkOrders = async <T = WorkOrder>(params?: UseWorkOrdersParams) =>
  (
    await axios.get<PaginatedResponse<T>>(WORK_ORDERS_URL, {
      params,
      paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
    })
  ).data

export function useWorkOrders(props: UseWorkOrdersProps = {}) {
  const {
    maintenancePlans,
    workOrderTemplates,
    factoryEntities,
    category,
    statuses,
    startTime,
    plannedStartTime,
    timeField,
    endTime,
    page,
    size,
    options,
  } = props
  const params: UseWorkOrdersParams = {
    maintenance_plans: maintenancePlans,
    work_order_templates: workOrderTemplates,
    factory_entities: factoryEntities,
    category,
    statuses,
    start_time: startTime,
    planned_start_time: plannedStartTime,
    time_field: timeField,
    end_time: endTime,
    page,
    size,
  }
  return useQuery<PaginatedResponse<WorkOrder & BaseModel>, Error>(
    ['workOrders', ...Object.values(params)],
    async () => await fetchWorkOrders(params),
    options
  )
}

export const useWorkOrdersPaginated = (
  params: UseWorkOrdersParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<WorkOrder, typeof fetchWorkOrders>({
    queryKey: ['workOrders', ...Object.values(params)],
    fetchFn: fetchWorkOrders,
    params,
    enabled: options?.enabled,
  })

type UseWorkOrderProps = {
  workOrderId?: string
  options?: UseQueryOptions<WorkOrder, Error>
}

export function useWorkOrder(props: UseWorkOrderProps) {
  const {workOrderId, options} = props
  return useQuery<WorkOrder, Error>(
    ['workOrder', workOrderId],
    async () => {
      const {data} = await axios.get<WorkOrder>(`${WORK_ORDERS_URL}/${workOrderId}`)
      return data
    },
    {enabled: !!workOrderId, refetchOnWindowFocus: false, ...options}
  )
}

export async function createWorkOrder(data: IWorkOrder) {
  const {data: workOrder} = await axios.post<WorkOrder & BaseModel>(WORK_ORDERS_URL, data)
  return workOrder
}

type UpdateWorkOrderProps = {
  workOrderId: string
  data: Partial<IWorkOrder>
}

export async function updateWorkOrder({workOrderId, data}: UpdateWorkOrderProps) {
  const {data: workOrder} = await axios.put<WorkOrder & BaseModel>(
    `${WORK_ORDERS_URL}/${workOrderId}`,
    data
  )
  return workOrder
}

type UpdateWorkOrderResponse = {}

export const useUpdateWorkOrderMutation = () => {
  return useMutation<UpdateWorkOrderResponse, AxiosError<AxiosServerError>, UpdateWorkOrderProps>({
    mutationFn: updateWorkOrder,
  })
}

export async function deleteWorkOrder(workOrderId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${WORK_ORDERS_URL}/${workOrderId}`)
  return data
}

interface CreateWorkOrderAttachmentProps {
  workOrderId: string
  file: File
}

export const createWorkOrderAttachment = async ({
  workOrderId,
  file,
}: CreateWorkOrderAttachmentProps) => {
  const formData = new FormData()
  formData.append('attachment', file)
  const {data: workOrder} = await axios.post<WorkOrder & BaseModel>(
    `${WORK_ORDERS_URL}/${workOrderId}/attachments`,
    formData
  )
  return workOrder
}

interface RemoveWorkOrderAttachmentProps {
  workOrderId: string
  name: string
}

export const removeWorkOrderAttachment = async ({
  workOrderId,
  name,
}: RemoveWorkOrderAttachmentProps) => {
  const {data: workOrder} = await axios.delete<WorkOrder & BaseModel>(
    `${WORK_ORDERS_URL}/${workOrderId}/attachments/${name}`
  )
  return workOrder
}

export async function initiateWorkOrder(workOrderId: string, data?: IInitiateWorkOrderData) {
  const {data: workOrder} = await axios.put<WorkOrder & BaseModel>(
    `${WORK_ORDERS_URL}/${workOrderId}/initiate`,
    data
  )
  return workOrder
}

export async function completeWorkOrder(workOrderId: string, data?: ICompleteWorkOrderData) {
  const {data: workOrder} = await axios.put<WorkOrder & BaseModel>(
    `${WORK_ORDERS_URL}/${workOrderId}/complete`,
    data
  )
  return workOrder
}
