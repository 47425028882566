import useGetTotalConsumption from '../../../../core/graphql/energy/queries/GetTotalConsumption'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import useProductProcessedDivider from './useProductProcessedDivider'
import QuantityWidget from './utility-widgets/QuantityWidget'
import {WidgetProps} from './Widget'

const EmissionsWidget = ({widget}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const divider = useProductProcessedDivider({widget})

  const {consumption} = useGetTotalConsumption({
    entityGroup: widget.entity_group,
    id: widget.entity,
    startTime,
    endTime,
  })

  const emissions = consumption?.emissions

  return (
    <QuantityWidget
      value={
        emissions !== undefined && divider ? `${(emissions / divider).toFixed(2)} kg` : undefined
      }
      unit='CO2e'
    />
  )
}

export default EmissionsWidget
