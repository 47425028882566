import {useState} from 'react'
import {Asset} from '../../core/_models'
import RegisterMaintenanceModal from './RegisterMaintenanceModal'

type useRegisterMaintenanceModalProps = {
  asset?: Asset
}

const useRegisterMaintenanceModal = ({asset}: useRegisterMaintenanceModalProps) => {
  const [show, setShow] = useState(false)
  const modal = asset
    ? RegisterMaintenanceModal({
        show,
        setShow,
        asset,
      })
    : undefined
  return {
    modal,
    setShow,
  }
}

export default useRegisterMaintenanceModal
