import {useQuery} from 'react-query'
import {graphql} from '../../../../../gql'
import {
  GetPlacementPowerConsumptionHistoryQuery,
  GetProductionLinePowerConsumptionHistoryQuery,
  GetSectionPowerConsumptionHistoryQuery,
  TimeSeriesPoint,
  Timespan,
} from '../../../../../gql/graphql'
import {EntityGroup} from '../../../_models'
import useEnergyClient from '../UseEnergyClient'

const getPlacementPowerConsumptionHistoryWithVariablesQueryDocument = graphql(`
  query GetPlacementPowerConsumptionHistory(
    $id: ID!
    $startTime: DateTime
    $endTime: DateTime
    $timespan: Timespan
    $multiplier: Int!
  ) {
    getPlacementPowerConsumptionHistory(
      id: $id
      startTime: $startTime
      endTime: $endTime
      timespan: $timespan
      multiplier: $multiplier
    ) {
      time
      value
    }
  }
`)

const getProductionLinePowerConsumptionHistoryWithVariablesQueryDocument = graphql(`
  query GetProductionLinePowerConsumptionHistory(
    $id: ID!
    $startTime: DateTime
    $endTime: DateTime
    $timespan: Timespan
    $multiplier: Int!
  ) {
    getProductionLinePowerConsumptionHistory(
      id: $id
      startTime: $startTime
      endTime: $endTime
      timespan: $timespan
      multiplier: $multiplier
    ) {
      time
      value
    }
  }
`)

const getSectionPowerConsumptionHistoryWithVariablesQueryDocument = graphql(`
  query GetSectionPowerConsumptionHistory(
    $id: ID!
    $startTime: DateTime
    $endTime: DateTime
    $timespan: Timespan
    $multiplier: Int!
  ) {
    getSectionPowerConsumptionHistory(
      id: $id
      startTime: $startTime
      endTime: $endTime
      timespan: $timespan
      multiplier: $multiplier
    ) {
      time
      value
    }
  }
`)

type PowerConsumptionHistory =
  | typeof getPlacementPowerConsumptionHistoryWithVariablesQueryDocument
  | typeof getProductionLinePowerConsumptionHistoryWithVariablesQueryDocument
  | typeof getSectionPowerConsumptionHistoryWithVariablesQueryDocument

type PowerConsumptionHistoryQuery =
  | GetPlacementPowerConsumptionHistoryQuery
  | GetProductionLinePowerConsumptionHistoryQuery
  | GetSectionPowerConsumptionHistoryQuery

interface Props {
  entityGroup: EntityGroup
  id?: string
  startTime: string
  endTime?: string
  multiplier?: number
  timespan?: Timespan
}

const useGetPowerConsumptionHistory = (props: Props) => {
  const {entityGroup, id, startTime, endTime, multiplier = 1, timespan} = props
  const client = useEnergyClient()
  let queryKey: string
  let getPowerConsumptionHistoryWithVariablesQueryDocument: PowerConsumptionHistory
  switch (entityGroup) {
    case 'placement':
      queryKey = 'GetPlacementPowerConsumptionHistory'
      getPowerConsumptionHistoryWithVariablesQueryDocument =
        getPlacementPowerConsumptionHistoryWithVariablesQueryDocument
      break
    case 'production_line':
      queryKey = 'GetProductionLinePowerConsumptionHistory'
      getPowerConsumptionHistoryWithVariablesQueryDocument =
        getProductionLinePowerConsumptionHistoryWithVariablesQueryDocument
      break
    case 'section':
      queryKey = 'GetSectionPowerConsumptionHistory'
      getPowerConsumptionHistoryWithVariablesQueryDocument =
        getSectionPowerConsumptionHistoryWithVariablesQueryDocument
      break
    default:
      throw new Error(`Wrong entityGroup: ${entityGroup}`)
  }
  let query = useQuery({
    queryKey: [`energy.${queryKey}`, ...Object.values(props)],
    queryFn: async () =>
      await client.request<PowerConsumptionHistoryQuery>(
        getPowerConsumptionHistoryWithVariablesQueryDocument,
        {
          id,
          startTime,
          endTime,
          multiplier,
          timespan,
        }
      ),
    enabled: !!id,
  })

  let consumption: TimeSeriesPoint[] = []
  if (query.data) {
    if ('getPlacementPowerConsumptionHistory' in query.data) {
      consumption = query.data.getPlacementPowerConsumptionHistory
    }
    if ('getProductionLinePowerConsumptionHistory' in query.data) {
      consumption = query.data.getProductionLinePowerConsumptionHistory
    }
    if ('getSectionPowerConsumptionHistory' in query.data) {
      consumption = query.data.getSectionPowerConsumptionHistory
    }
  }
  consumption = consumption.map((c) => ({
    ...c,
    time: c.time * 1000,
  }))

  return {
    consumption,
    ...query,
  }
}

export default useGetPowerConsumptionHistory
