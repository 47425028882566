export type ICreateArea = {
  factory?: string
  name?: string
}

export const defaultCreateArea: ICreateArea = {
  factory: undefined,
  name: undefined,
}

export type StepProps = {
  data: ICreateArea
  updateData: (fieldsToUpdate: Partial<ICreateArea>) => void
  hasError: boolean
}
