import Select from 'react-select'
import {Organization} from '../../../../core/_models'
import {useOrganizationsPaginated, useProfile} from '../../../../core/requests/auth'

type OrganizationSelectProps = {
  onChange: (organization?: Organization) => void
}

const OrganizationSelect = ({onChange}: OrganizationSelectProps) => {
  const {data: profile} = useProfile()
  const {
    items: organizations,
    isFetching: organizationsFetching,
    fetchNextPage: organizationsNextPage,
  } = useOrganizationsPaginated(
    {},
    {
      enabled: !!profile?.is_staff,
    }
  )

  return organizations ? (
    <Select
      placeholder={`Organization`}
      onChange={(e) => onChange(organizations?.find((o) => o._id === e?.value))}
      options={
        organizations?.map((o) => ({
          value: o._id,
          label: o.name,
        })) || []
      }
      isClearable
      className={'me-3'}
      isLoading={organizationsFetching}
      onMenuScrollToBottom={() => organizationsNextPage()}
    />
  ) : (
    <></>
  )
}

export default OrganizationSelect
