import React from 'react'
import Select from 'react-select'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {WidgetProps} from './Widget'
import {LineData} from '../../core/_chartConfigs/lineConfig'
import {LineGraph} from '../../components/charts/LineGraph'
import {Reference, Sensor, Widget} from '../../../../core/_models'
import {dataTypeUnitMapping} from './_models'
import {useSensorQueries} from '../../../../core/requests/factory'
import {getSensorRecords} from '../../../../core/requests/condition'

const lineWidgetDataTypes = [
  'RMS X',
  'RMS Y',
  'RMS Z',
  'Temperature',
  'Output',
  'Ampere',
  'Phase 1',
  'Phase 2',
  'Phase 3',
  'Average',
  'H2S',
]
export type LineWidgetDataType = typeof lineWidgetDataTypes[number]

const LineWidget = ({widget, height}: WidgetProps) => {
  const [linesLoading, setLinesLoading] = React.useState(true)
  const [selectedSensors, setSelectedSensors] = React.useState<Reference[]>()
  const [data, setData] = React.useState<Record<string, LineData[]>>({})

  const {items: sensorItems, isLoading} = useSensorQueries(widget.entities)
  const items = sensorItems.filter((sensor) => Object.keys(data).includes(sensor?._id as string))

  React.useEffect(() => {
    if (!isLoading) {
      ;(async () => {
        const lineData: Record<string, Promise<LineData[]>> = {}

        sensorItems.forEach((entity, index) => {
          if (entity?._id) {
            lineData[entity._id] = getLineData(widget, entity, entity.placement.name)
          }
        })

        const _data: Record<string, LineData[]> = {}
        const response = await Promise.all(Object.values(lineData))
        response.forEach((responseLineData, index) => {
          _data[Object.keys(lineData)[index]] = responseLineData
        })

        setData(_data)
        setLinesLoading(false)
      })()
    } else {
      setLinesLoading(true)
    }
  }, [widget, isLoading])

  const filter =
    !linesLoading && Object.keys(data).length > 1 ? (
      <div className={`d-flex mx-5 mt-5`}>
        <Select
          options={items.map((entity) => ({
            label: `${entity?.placement.name}: ${entity?.data_types.join(', ')} ${
              entity?.device_id
            }`,
            value: entity?._id as string,
          }))}
          onChange={(values) =>
            setSelectedSensors(
              values.map((value) => ({
                _id: value.value,
                name: value.label,
              }))
            )
          }
          isClearable={true}
          className={`w-100`}
          placeholder={`Select sensor...`}
          isMulti={true}
        />
      </div>
    ) : null

  const entitiesData: Record<string, LineData[]> = {}
  selectedSensors?.forEach((entity) => {
    entitiesData[entity._id] = data[entity._id]
  })
  const lineData = selectedSensors?.length ? entitiesData : data
  return (
    <>
      {filter}
      <LineGraph
        id={widget._id}
        height={filter ? `calc(${height} - 55px)` : height}
        data={lineData}
        loading={linesLoading}
      />
    </>
  )
}

const getLineData = async (widget: Widget, entity: Sensor, namePrefix?: string) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const dataTypes: string[] = []
  const data = (
    await Promise.all(
      (widget?.data_types || []).map(async (type, i) => {
        const props = {
          sensorId: entity._id,
          startTime: startTime,
          endTime: endTime,
          timespan: widget.timespan,
          multiplier: widget.multiplier,
        }
        try {
          switch (type) {
            case 'Ampere':
              if (entity.data_types.includes('ampere')) {
                dataTypes.push('Kilowatt')
                return await getSensorRecords({
                  ...props,
                  dataType: 'ampere',
                })
              }
              break
            case 'Phase 1':
            case 'Phase 2':
            case 'Phase 3':
            case 'Average':
              if (entity.data_types.includes('ampere')) {
                dataTypes.push(type)
                return await getSensorRecords({
                  ...props,
                  dataType: 'ampere',
                  amperageType: type,
                })
              }
              break
            case 'RMS X':
              if (entity.data_types.includes('vibration')) {
                dataTypes.push('RMS X')
                return await getSensorRecords({
                  ...props,
                  dataType: 'vibration',
                  axis: 'X',
                })
              }
              break
            case 'RMS Y':
              if (entity.data_types.includes('vibration')) {
                dataTypes.push('RMS Y')
                return await getSensorRecords({
                  ...props,
                  dataType: 'vibration',
                  axis: 'Y',
                })
              }
              break
            case 'RMS Z':
              if (entity.data_types.includes('vibration')) {
                dataTypes.push('RMS Z')
                return await getSensorRecords({
                  ...props,
                  dataType: 'vibration',
                  axis: 'Z',
                })
              }
              break
            default:
              if (entity.data_types.includes(type.toLowerCase())) {
                dataTypes.push(type)
                return await getSensorRecords({
                  ...props,
                  dataType: type.toLowerCase(),
                })
              }
              break
          }
        } catch (e) {
          console.warn('getSensorRecords error res1', e)
        }
      })
    )
  ).filter((v) => v !== undefined)

  const lineData: LineData[] = data
    .filter((records) => !!records?.length)
    .map((records, i) => {
      const dataType = dataTypes[i]
      let name, legend
      let unit = dataTypeUnitMapping[dataType.toLowerCase()]
      if (dataType === 'Kilowatt') {
        name = `${namePrefix}`
        unit = 'kW'
        legend = `${namePrefix}: kW`
      } else if (['Phase 1', 'Phase 2', 'Phase 3', 'Average'].includes(dataType)) {
        name = `${namePrefix}`
        unit = `${dataType} - ${dataTypeUnitMapping['ampere']}`
        legend = `${namePrefix}: ${dataType} - Ampere`
      } else {
        name = namePrefix ? `${namePrefix}` : `${dataType}`
      }
      const data =
        records?.map((record) => ({
          time: record.time,
          value: record.value,
        })) || []
      return {dataType, unit, data, name, legend}
    })

  return lineData
}

export default LineWidget
