import Select from 'react-select'
import {Section} from '../../../../core/_models'
import {useSectionsPaginated} from '../../../../core/requests/factory'

type SectionSelectProps = {
  onChange: (section?: Section) => void
}

const SectionSelect = ({onChange}: SectionSelectProps) => {
  const {
    items: sections,
    isFetching: sectionsFetching,
    fetchNextPage: sectionsNextPage,
  } = useSectionsPaginated()
  return (
    <Select
      placeholder={`Section`}
      onChange={(e) => onChange(sections?.find((s) => s._id === e?.value))}
      options={
        sections?.map((s) => ({
          value: s._id,
          label: s.name,
        })) || []
      }
      isClearable
      className={'me-3'}
      isLoading={sectionsFetching}
      onMenuScrollToBottom={() => sectionsNextPage()}
    />
  )
}

export default SectionSelect
