import React, {ReactNode} from 'react'
import {PulseLoader} from 'react-spinners'
import {KTSVG} from '../../_metronic/helpers'
import {ServerError} from '../core/_models'

interface Props {
  hasError: boolean
  errorData?: ServerError
  hide: () => void
  children: ReactNode
}

const ModalErrorWrapper: React.FC<Props> = ({hasError, errorData, hide, children}) => {
  return (
    <>
      {hasError ? (
        <>
          <div className='modal-header'>
            <h2>{errorData ? 'Error' : 'Unexpected Error'}</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hide}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            {errorData && JSON.stringify(errorData)}
          </div>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default ModalErrorWrapper
