import {Modal} from 'react-bootstrap'
import EventCategoriesSidebar from './sidebar/EventCategoriesSidebar'
import EventCategoriesContent from './content/EventCategoriesContent'
import {CategorySelectionProvider, useCategorySelection} from './CategorySelectionContext'
import EventRegistrationDeleteModal from './delete-modal/EventRegistrationDeleteModal'
import {useEffect, useRef} from 'react'

const MODAL_HEIGHT = '800px'

export const MAX_DEPTH = 3
export const PROTECTED_CATEGORIES = ['Running', 'Auto-detected']

type EventCategoriesModalProps = {
  show: boolean
  open: () => void
  hide: () => void
}

const EventCategoriesModal = ({show, open, hide}: EventCategoriesModalProps) => {
  const {showDeleteModal} = useCategorySelection()
  const isInitialRender = useRef(true)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    if (showDeleteModal && show) hide()
    else if (!showDeleteModal && !show) open()
  }, [showDeleteModal])

  return (
    <>
      <Modal
        show={show}
        tabIndex={-1}
        aria-hidden='true'
        size='xl'
        dialogClassName='modal-dialog modal-dialog-centered'
        onHide={hide}
      >
        <div className='modal-body p-0'>
          <div className='container d-flex flex-column' style={{height: MODAL_HEIGHT}}>
            <div className='row flex-grow-1'>
              <EventCategoriesSidebar className='col-3 rounded' />
              <EventCategoriesContent className='col-9 rounded' />
            </div>
          </div>
        </div>
      </Modal>
      <EventRegistrationDeleteModal />
    </>
  )
}

const EventCategoriesModalWrapper = ({show, open, hide}: EventCategoriesModalProps) => {
  return (
    <CategorySelectionProvider>
      <EventCategoriesModal show={show} open={open} hide={hide} />
    </CategorySelectionProvider>
  )
}

export {EventCategoriesModalWrapper as EventCategoriesModal}
