import {useEffect, useState} from 'react'
import {ICreateBatchTemplate, defaultCreateBatchTemplate} from './_models'
import {useQueryClient} from 'react-query'
import {
  createBatchTemplate,
  updateBatchTemplate,
  useProductsPaginated,
} from '../../core/requests/oee'
import {ContentProps, FormModalWrapper} from '../../components/FormModalWrapper'
import {useProfile} from '../../core/requests/auth'
import TextField from '../../components/form-components/TextField'
import SelectField from '../../components/form-components/SelectField'
import NumberField from '../../components/form-components/NumberField'
import LoadingWrapper from '../../components/LoadingWrapper'
import {BatchTemplate} from '../../core/_models'

type Props = {
  show: boolean
  hide: () => void
  edit?: BatchTemplate
}

const CreateBatchTemplateModal = ({show, hide, edit}: Props) => {
  const queryClient = useQueryClient()
  const [data, setData] = useState<ICreateBatchTemplate>(defaultCreateBatchTemplate)

  useEffect(() => {
    if (edit === undefined) return
    setData({
      name: edit.name,
      product: edit.product,
      total_product_quantity: edit.total_product_quantity,
    })
  }, [edit])

  const submitCreate = async (batchTemplateData: ICreateBatchTemplate) => {
    await createBatchTemplate(batchTemplateData)
    await queryClient.invalidateQueries('batchTemplates')
  }

  const submitEdit = async (batchTemplateData: ICreateBatchTemplate) => {
    await updateBatchTemplate(edit!._id, batchTemplateData)
    await queryClient.invalidateQueries('batchTemplates')
  }

  return (
    <FormModalWrapper<ICreateBatchTemplate>
      id='hs_create_batch_template'
      title={`${edit === undefined ? 'Create' : 'Edit'} Production Batch Template`}
      show={show}
      close={hide}
      defaultData={data}
      submit={edit === undefined ? submitCreate : submitEdit}
      validation={(data) =>
        data.name !== undefined &&
        data.product !== undefined &&
        data.total_product_quantity !== undefined
      }
      body={{content: Content}}
    />
  )
}

const Content = ({data, updateData, hasError}: ContentProps<ICreateBatchTemplate>) => {
  const {data: profile} = useProfile()
  const {
    items: products,
    isFetching: productsFetching,
    gotoNextPage: productsNextPage,
  } = useProductsPaginated({
    organization: profile?.organization?._id,
    options: {enabled: !!profile?.organization?._id},
  })

  useEffect(() => {
    if (profile && profile.organization) {
      updateData({organization: profile.organization._id})
    }
  }, [profile])

  return (
    <LoadingWrapper loading={products === undefined || profile === undefined}>
      <div className='w-100'>
        <TextField
          name='Template Name'
          data={data}
          updateData={updateData}
          dataField='name'
          hasError={hasError}
          required
        />
        <SelectField
          name='Product'
          optionNames={products?.map((product) => product.name) || []}
          optionValues={products?.map((product) => product._id) || []}
          data={data}
          updateData={updateData}
          dataField='product'
          hasError={hasError}
          required
          isLoading={productsFetching}
          gotoNextPage={productsNextPage}
        />
        <NumberField
          name='Total Product Quantity'
          data={data}
          updateData={updateData}
          dataField='total_product_quantity'
          hasError={hasError}
          required
        />
      </div>
    </LoadingWrapper>
  )
}

export default CreateBatchTemplateModal
