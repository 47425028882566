import React from 'react'
import {Area} from '../../../core/_models'
import {useProductionLines, useProductionLinesPaginated} from '../../../core/requests/factory'
import ProductionLineNode from './ProductionLineNode'
import TreeNode from './TreeNode'

interface AreaNodeProps {
  area: Area
}

export const AreaNode = ({area}: AreaNodeProps) => {
  const [opened, setOpen] = React.useState(false)
  const {data: areas} = useProductionLines({area: area._id})
  const {items, isFetching, hasNextPage, fetchNextPage} = useProductionLinesPaginated(
    {area: area._id},
    {
      enabled: opened,
    }
  )

  React.useEffect(() => {
    if (!isFetching && hasNextPage) {
      ;(async () => {
        await fetchNextPage()
      })()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  const nodes = items?.map((productionLine) => (
    <ProductionLineNode productionLine={productionLine} />
  ))

  return (
    <TreeNode
      name={area.name}
      iconPath={`/media/icons/duotune/maps/map001.svg`}
      opened={opened}
      setOpen={setOpen}
      nodes={nodes}
      isExpandable={!!areas?.total}
    />
  )
}

export default AreaNode
