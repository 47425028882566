import {useEffect, useRef, useState} from 'react'
import {EntityGroupPretty, entityGroupsPretty} from '../../../core/_models'
import Select from 'react-select'
import {
  useAreasPaginated,
  useAssetsPaginated,
  useFactoriesPaginated,
  useFactoryEntities,
  useGatewaysPaginated,
  usePartsPaginated,
  usePlacementsPaginated,
  usePlcsPaginated,
  useProductionLinesPaginated,
  useSectionsPaginated,
  useSensorsPaginated,
} from '../../../core/requests/factory'
import {
  entityGroupToString,
  gatewayToString,
  partToString,
  placementToString,
  sensorToString,
} from '../../../core/name-util'

type Props = {
  initial?: string
  name?: string
  onchange: (value?: string) => void
  className?: string
}

const FactoryEntitySelectField = ({initial, name = 'Entity', onchange, className}: Props) => {
  const [entityType, setEntityType] = useState<EntityGroupPretty>()
  const [entity, setEntity] = useState<string | undefined>(initial)
  const [loadingInitialEntity, setLoadingInitialEntity] = useState(initial !== undefined)

  const {data: initialEntity} = useFactoryEntities({
    entityIds: initial ? [initial] : undefined,
    options: {enabled: !!initial},
  })

  useEffect(() => {
    if (!loadingInitialEntity || initialEntity === undefined) return
    if (!initialEntity.items.length) setLoadingInitialEntity(false)
    else {
      setEntityType(entityGroupToString(initialEntity.items[0].type))
      setEntity(initialEntity.items[0].entity._id)
      setLoadingInitialEntity(false)
    }
  }, [initialEntity])

  const {
    items: factories,
    isFetching: factoriesFetching,
    fetchNextPage: factoriesNextPage,
  } = useFactoriesPaginated({}, {enabled: entityType === 'Factory'})
  const {
    items: areas,
    isFetching: areasFetching,
    fetchNextPage: areasNextPage,
  } = useAreasPaginated({}, {enabled: entityType === 'Area'})
  const {
    items: productionLines,
    isFetching: productionLinesFetching,
    fetchNextPage: productionLinesNextPage,
  } = useProductionLinesPaginated({}, {enabled: entityType === 'Production Line'})
  const {
    items: sections,
    isFetching: sectionsFetching,
    fetchNextPage: sectionsNextPage,
  } = useSectionsPaginated({}, {enabled: entityType === 'Section'})
  const {
    items: assets,
    isFetching: assetsFetching,
    fetchNextPage: assetsNextPage,
  } = useAssetsPaginated({}, {enabled: entityType === 'Asset'})
  const {
    items: parts,
    isFetching: partsFetching,
    fetchNextPage: partsNextPage,
  } = usePartsPaginated({}, {enabled: entityType === 'Part'})
  const {
    items: placements,
    isFetching: placementsFetching,
    fetchNextPage: placementsNextPage,
  } = usePlacementsPaginated({}, {enabled: entityType === 'Placement'})
  const {
    items: sensors,
    isFetching: sensorsFetching,
    fetchNextPage: sensorsNextPage,
  } = useSensorsPaginated({}, {enabled: entityType === 'Sensor'})
  const {
    items: gateways,
    isFetching: gatewaysFetching,
    fetchNextPage: gatewaysNextPage,
  } = useGatewaysPaginated({}, {enabled: entityType === 'Gateway'})
  const {
    items: plcs,
    isFetching: plcsFetching,
    fetchNextPage: plcsNextPage,
  } = usePlcsPaginated({}, {enabled: entityType === 'PLC'})

  const [entities, entityNames, entitiesFetching, entitiesNextPage] =
    entityType === 'Factory'
      ? [factories, factories?.map((x) => x.name), factoriesFetching, factoriesNextPage]
      : entityType === 'Area'
      ? [areas, areas?.map((x) => x.name), areasFetching, areasNextPage]
      : entityType === 'Production Line'
      ? [
          productionLines,
          productionLines?.map((x) => x.name),
          productionLinesFetching,
          productionLinesNextPage,
        ]
      : entityType === 'Section'
      ? [sections, sections?.map((x) => x.name), sectionsFetching, sectionsNextPage]
      : entityType === 'Asset'
      ? [assets, assets?.map((x) => x.name), assetsFetching, assetsNextPage]
      : entityType === 'Part'
      ? [parts, parts?.map((x) => partToString(x)), partsFetching, partsNextPage]
      : entityType === 'Placement'
      ? [
          placements,
          placements?.map((x) => placementToString(x)),
          placementsFetching,
          placementsNextPage,
        ]
      : entityType === 'Sensor'
      ? [sensors, sensors?.map((x) => sensorToString(x)), sensorsFetching, sensorsNextPage]
      : entityType === 'Gateway'
      ? [gateways, gateways?.map((x) => gatewayToString(x)), gatewaysFetching, gatewaysNextPage]
      : entityType === 'PLC'
      ? [plcs, plcs?.map((x) => x.name), plcsFetching, plcsNextPage]
      : [undefined, undefined, false, undefined]

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        placeholder='Select entity type...'
        options={entityGroupsPretty.map((entityGroup) => ({
          value: entityGroup,
          label: entityGroup,
        }))}
        value={entityType ? {value: entityType, label: entityType} : null}
        onChange={(e) => {
          setEntityType(e?.value || undefined)
          setEntity(undefined)
          onchange(undefined)
        }}
        isClearable
        isLoading={loadingInitialEntity}
        isDisabled={loadingInitialEntity}
      />
      <Select
        className='pt-1'
        placeholder={entityType ? `Select ${entityType.toLowerCase()}...` : ''}
        options={entities?.map((entity, i) => ({
          value: entity._id,
          label: entityNames?.[i] || entity._id,
        }))}
        value={
          entity && entities
            ? {value: entity, label: entityNames?.[entities.findIndex((x) => x._id === entity)]}
            : null
        }
        onChange={(e) => {
          setEntity(e?.value || undefined)
          onchange(e?.value)
        }}
        isLoading={loadingInitialEntity || entitiesFetching}
        isClearable
        onMenuScrollToBottom={() => entitiesNextPage && entitiesNextPage()}
        isDisabled={!entityType || entitiesFetching || loadingInitialEntity}
      />
    </>
  )
}

export default FactoryEntitySelectField
