import {Column} from 'react-table'
import {StringCell} from '../core/columns/StringCell'
import {SelectionCell} from '../core/columns/SelectionCell'
import {CustomHeader} from '../core/columns/CustomHeader'
import {SelectionHeader} from '../core/columns/SelectionHeader'
import {Area} from '../../../core/_models'
import {NameImageCell} from '../core/columns/ImageStringCell'
import {CellClickContext} from '../core/columns/TableBody'
import FactoryCell from '../core/columns/FactoryCell'

const Columns: ReadonlyArray<Column<Area>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} tableId='hs_areas_table' />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index]._id} tableId='hs_areas_table' />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => (
      <CellClickContext.Consumer>
        {({onCellClick}) => (
          <NameImageCell
            str={props.data[props.row.index].name}
            images={
              props.data[props.row.index].gallery
                ? [
                    props.data[props.row.index].gallery!.profile_image,
                    ...props.data[props.row.index].gallery!.images,
                  ]
                : undefined
            }
            index={props.row.index}
            entityId={props.data[props.row.index]._id}
            queryToInvalidate='areas'
            onClick={() => (onCellClick ? onCellClick(props.data[props.row.index]._id) : undefined)}
          />
        )}
      </CellClickContext.Consumer>
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Factory' className='min-w-125px' />,
    id: 'factory',
    Cell: ({...props}) => <FactoryCell factoryReference={props.data[props.row.index].factory} />,
  },
]

export {Columns}
