import React from 'react'
import {useAuth} from '../auth'

export const PendoScript = () => {
  const {currentProfile} = useAuth()
  const [initialized, setInitialized] = React.useState(false)
  const pendo = window.pendo

  const anonymousData = {
    visitor: {
      id: 'VISITOR-UNIQUE-ID',
    },
    account: {
      id: null,
    },
  }

  React.useEffect(() => {
    pendo.initialize(anonymousData)
    setInitialized(true)
    return () => {
      pendo.identify(anonymousData)
    }
  }, [])

  React.useEffect(() => {
    if (initialized) {
      if (currentProfile?._id) {
        pendo.identify({
          visitor: {
            id: currentProfile._id,
            email: currentProfile.email,
            role: currentProfile.current_role?.name,
          },
          account: {
            id: currentProfile._id,
          },
        })
      } else {
        pendo.identify(anonymousData)
      }
    }
  }, [currentProfile, initialized])

  return null
}
