import {useEvents} from '../../core/requests/automations'
import HealthMatch from './conditions/HealthMatch'
import ObjectMatch from './conditions/ObjectMatch'
import ThresholdCrossed from './conditions/ThresholdCrossed'
import {Condition, conditionTypesMap} from './conditions/conditions'
import Select from 'react-select'

type Props = {
  condition: Condition | null
  setCondition: (condition: Condition | null) => void
  removeCondition?: () => void
  selectedEvent: string
  errorOccurred?: boolean
  className?: string
}

const ConditionCard = ({
  condition,
  setCondition,
  removeCondition,
  selectedEvent,
  errorOccurred,
  className,
}: Props) => {
  const {data: events, isLoading: eventsLoading} = useEvents()
  const event = events?.find((e) => e[0] === selectedEvent)

  return (
    <div className={`card border border-secondary shadow-sm w-75 ${className}`}>
      <div className='card-body p-5'>
        <div className='d-flex align-items-center'>
          <Select
            placeholder='Select condition...'
            className='w-100'
            value={
              condition ? {label: conditionTypesMap[condition.type], value: condition.type} : null
            }
            options={event?.[1].conditions.map((c) => ({label: conditionTypesMap[c], value: c}))}
            onChange={(e) => {
              if (e === null) setCondition(null)
              else setCondition({type: e.value, enabled: true})
            }}
            isLoading={eventsLoading}
          />

          {removeCondition && (
            <i
              className='fa-solid fa-trash fs-5 cursor-pointer ps-5 pe-2'
              onClick={removeCondition}
              style={{color: '#EE2D41'}}
            />
          )}
        </div>

        {condition?.type === 'health_match' ? (
          <HealthMatch
            condition={condition}
            setCondition={setCondition}
            errorOccurred={errorOccurred}
          />
        ) : condition?.type === 'threshold_crossed' ? (
          <ThresholdCrossed
            condition={condition}
            setCondition={setCondition}
            errorOccurred={errorOccurred}
          />
        ) : condition?.type === 'object_match' && event ? (
          <ObjectMatch
            condition={condition}
            setCondition={setCondition}
            event={event}
            errorOccurred={errorOccurred}
          />
        ) : null}
      </div>
    </div>
  )
}

export default ConditionCard
