import SelectBoxes from '../../../../../../components/form-components/SelectBoxes'
import {StepProps} from './_models'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='mb-10'>
          <h2 className='fw-bold d-flex align-items-center text-dark'>Chart Options</h2>
          <div className='text-muted fw-semibold fs-6'>
            Select a chart type based on your data representation needs. Line charts are suitable
            for visualizing data over time, while column charts are useful for comparing data at
            specific intervals.
          </div>
        </div>

        <SelectBoxes
          required
          optionNames={['Line Chart', 'Column Chart']}
          optionDescriptions={[
            'Displays data as a series of points connected by straight lines. Useful for observing trends and changes in power consumption over a continuous period',
            'Represents data in vertical bars. Effective for comparing power usage across different production lines or timeframes in distinct intervals',
          ]}
          optionIcons={['fa-chart-line', 'fa-chart-column']}
          optionValues={['line', 'column']}
          data={data}
          dataField='visualization'
          updateData={updateData}
          hasError={hasError}
          lastField
        />
      </div>
    </div>
  )
}

export default Step1
