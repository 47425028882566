import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'
import {formatSubscriptionTopic} from '../../core/name-util'
import {StepProps} from './_models'

const topics = ['point-anomaly-detected', 'threshold-crossed']

const Step1 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxes
          name='Topic'
          optionNames={topics.map((topic) => formatSubscriptionTopic(topic))}
          optionValues={topics}
          data={data}
          updateData={updateData}
          dataField='topic'
          required
          hasError={hasError}
        />
      </div>
    </div>
  )
}

export default Step1
