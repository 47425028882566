/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  str: string
  href?: string
  icon?: string
  iconSize?: number
  onClick?: () => void
  onDoubleClick?: () => void
}

const StringCell: FC<Props> = ({str, href, icon, iconSize = 1, onClick, onDoubleClick}) => (
  <a
    href={href}
    className='d-flex align-items-center text-gray-800 text-hover-primary cursor-pointer'
    onClick={(event) => {
      event.stopPropagation()
      if (onClick && !href) {
        onClick()
      }
    }}
    onDoubleClick={(event) => {
      event.stopPropagation()
      if (onDoubleClick) {
        onDoubleClick()
      }
    }}
  >
    {icon && <i className={`fa ${icon} fs-${iconSize} text-center`} style={{width: 30}} />}
    {str}
  </a>
)

export {StringCell}
