import React from 'react'
import {Route, Routes} from 'react-router-dom'
import PageNavigationBar from '../../components/PageNavigationBar'

const TasksPage = () => {
  return (
    <Routes>
      <Route path='' element={<PageNavigationBar title={`Tasks`} />} />
    </Routes>
  )
}

export default TasksPage
