import {AxiosError} from 'axios'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../_metronic/helpers'
import {ServerError} from '../core/_models'
import LoadingWrapper from './LoadingWrapper'
import ModalErrorWrapper from './ModalErrorWrapper'

export type ContentProps<IData> = {
  data: IData
  updateData: (fieldsToUpdate: Partial<IData>) => void
  hasError: boolean
}

type Props<IData> = {
  id: string
  title: string
  show: boolean
  close: () => void
  defaultData: IData
  validation?: (data: IData) => boolean
  submit: (data: IData) => Promise<void>
  body: {
    content: ({data, updateData, hasError}: ContentProps<IData>) => JSX.Element
  }
  loading?: boolean
  size?: 'sm' | 'lg' | 'xl'
}

const FormModalWrapper = <IData,>({
  id,
  title,
  show,
  close,
  defaultData,
  validation,
  submit,
  body,
  loading = false,
  size = 'lg',
}: Props<IData>) => {
  const [data, setData] = useState<IData>(defaultData)
  const [hasValidationError, setHasValidationError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasServerError, setHasServerError] = useState(false)
  const [serverError, setServerError] = useState<ServerError | null>(null)

  const updateData = (fieldsToUpdate: Partial<IData>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const submitForm = async () => {
    if (validation && !validation(data)) {
      setHasValidationError(true)
      return
    }

    setIsSubmitting(true)
    try {
      await submit(data)
      setIsSubmitting(false)
      hide()
    } catch (error) {
      setHasServerError(true)
      const errorData: ServerError = (error as AxiosError).response?.data as ServerError
      setServerError(errorData)
      setIsSubmitting(false)
    }
    setIsSubmitting(false)
  }

  const hide = () => {
    close()
    setHasValidationError(false)
    setHasServerError(false)
    setServerError(null)
    setData(defaultData)
  }

  useEffect(() => {
    setData(defaultData)
  }, [defaultData])

  return (
    <Modal
      id={id}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered'
      size={size}
      show={show}
      onHide={hide}
      backdrop={isSubmitting ? 'static' : true}
    >
      <ModalErrorWrapper hasError={hasServerError} errorData={serverError || undefined} hide={hide}>
        <div className='modal-header'>
          <h2>{title}</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hide}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='modal-body m-5'>
          <LoadingWrapper loading={loading || isSubmitting}>
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <form noValidate id='hs_production_stop_form'>
                <body.content data={data} updateData={updateData} hasError={hasValidationError} />

                <div className='d-flex flex-stack pt-10'>
                  <div />
                  <div>
                    <button type='button' className='btn btn-lg btn-primary' onClick={submitForm}>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </LoadingWrapper>
        </div>
      </ModalErrorWrapper>
    </Modal>
  )
}

export {FormModalWrapper}
