import {EntityGroup, EventCategory, SubEventCategory} from '../../../../../core/_models'
import {IWidget, WidgetType, defaultCreateWidget, defaultLayout} from '../../widgets/_models'

export type IOeeWidget = IWidget & {
  entity_group_filter?: EntityGroup
  entity_filter?: string
  type?: WidgetType
  timeFrame?: 'dateRange' | 'interval' | 'batch'
  productionBatch?: string
  eventRegistrationType?: 'General Event Registration' | 'Pre-configured Event Registration'
  selectedCategory?: EventCategory | SubEventCategory
  expandedCategoryTrace: (EventCategory | SubEventCategory)[]
}

export const defaultCreateOeeWidget: IOeeWidget = {
  ...defaultCreateWidget,
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
  },
  entity_group: 'production_line',
  expandedCategoryTrace: [],

  start_time: '2024-04-01T10:00:00+00:00',
  dynamic_time_range: 'P2W',
}

export type StepProps = {
  data: IOeeWidget
  updateData: (fieldsToUpdate: Partial<IOeeWidget>) => void
  hasError: boolean
}
