import {useState} from 'react'
import {deleteDashboard, useDashboards} from '../../../../../app/core/requests/template'
import useModalState from '../../../../../app/hooks/UseModalState'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {useQueryClient} from 'react-query'
import {SidebarMenuItem} from './SidebarMenuItem'
import {ConfirmDeletionModal} from '../../../../../app/pages/overview/core/columns/TableSelectionToolbar'
import CreateDashboardModal from '../../../../../app/modals/create-dashboard/CreateDashboardModal'
import {Dashboard} from '../../../../../app/core/_models'

const DashboardSidebarMenu = () => {
  const queryClient = useQueryClient()
  const createModal = useModalState()
  const deletionModal = useModalState()

  const [deletingDashboardId, setDeletingDashboardId] = useState<string>()

  const {data: dashboards} = useDashboards()

  const handleDelete = async () => {
    if (!deletingDashboardId) return
    try {
      await deleteDashboard(deletingDashboardId)
      await queryClient.invalidateQueries('dashboards')
    } catch (error) {
      console.error(error)
    } finally {
      setDeletingDashboardId(undefined)
      deletionModal.close()
    }
  }

  const DashboardItem = ({dashboard}: {dashboard: Dashboard}) => (
    <SidebarMenuItem
      to={`/dashboards/${dashboard._id}`}
      title={dashboard.name}
      buttonIcon='fa-trash-can'
      buttonTheme='danger'
      handleButtonClick={() => {
        setDeletingDashboardId(dashboard._id)
        deletionModal.open()
      }}
      key={dashboard._id}
    />
  )

  const dashboardTags = Array.from(
    new Set(dashboards?.items?.flatMap((dashboard) => dashboard.tags))
  )

  return (
    <>
      <SidebarMenuItemWithSub
        to=''
        fontAwesomeIcon='fa-chart-line'
        title='Dashboards'
        buttonIcon='fa-plus'
        handleButtonClick={createModal.open}
      >
        {dashboardTags.map((tag) => (
          <>
            <SidebarMenuItemWithSub to='' fontAwesomeIcon='fa-folder' title={tag}>
              {dashboards?.items
                .filter((dashboard) => dashboard.tags.includes(tag))
                .map((dashboard) => (
                  <DashboardItem dashboard={dashboard} key={dashboard._id} />
                ))}
            </SidebarMenuItemWithSub>
          </>
        ))}

        {dashboards?.items
          .filter((dashboard) => !dashboard.tags.length)
          .map((dashboard) => (
            <DashboardItem dashboard={dashboard} key={dashboard._id} />
          ))}
      </SidebarMenuItemWithSub>

      <CreateDashboardModal show={createModal.isOpen} handleClose={createModal.close} />

      <ConfirmDeletionModal
        show={deletionModal.isOpen}
        hide={deletionModal.close}
        deleteSelections={handleDelete}
      />
    </>
  )
}

export {DashboardSidebarMenu}
