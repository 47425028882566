import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title='Factories' to='/factories/overview' fontIcon='fa-solid fa-industry' />
      <MenuItem title='Areas' to='/areas/overview' icon='/media/icons/duotune/maps/map001.svg' />
      <MenuItem
        title='Production Lines'
        to='/production-lines'
        icon='/media/icons/duotune/technology/teh005.svg'
      />
      <MenuItem title='Sections' to='/sections' icon='/media/icons/duotune/art/art006.svg' />
      <MenuItem title='Assets' to='/assets' icon='/media/icons/duotune/general/gen017.svg' />
      <MenuItem title='Parts' to='/parts' fontIcon='fa-solid fa-shapes' />
      <MenuItem
        title='Placements'
        to='/placements'
        icon='/media/icons/duotune/general/gen018.svg'
      />
      <MenuItem title='Sensors' to='/sensors' icon='/media/icons/duotune/electronics/elc007.svg' />
      <MenuItem
        title='Gateways'
        to='/gateways'
        icon='/media/icons/duotune/electronics/elc008.svg'
      />
      {/*<MenuItem title='PLCs' to='/plcs' fontIcon='fa-solid fa-microchip' />*/}
    </>
  )
}
