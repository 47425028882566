import React from 'react'
import {ProductionStatusTimelineGraph} from '../../../pages/dashboard/components/charts/ProductionStatusTimelineGraph'

interface TimelineTabProps {
  timelineId: string
}

const TimelineTab = ({timelineId}: TimelineTabProps) => {
  const [editMode, setEditMode] = React.useState(false)
  return (
    <>
      <div className='d-flex justify-content-end'>
        <button
          className='btn btn-sm btn-icon btn-color-muted me-n4'
          onClick={() => setEditMode(!editMode)}
          title={`Edit ${editMode ? 'unlocked' : 'locked'}`}
        >
          <i className={`fa-solid ${editMode ? 'fa-unlock' : 'fa-lock'}`}></i>
        </button>
      </div>
      <ProductionStatusTimelineGraph
        productionLineId={timelineId}
        height='375px'
        editMode={editMode}
      />
    </>
  )
}

export default TimelineTab
