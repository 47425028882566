import usePagination, {UsePaginationProps} from '../../../../components/pagination/usePagination'
import {
  Area,
  Asset,
  DeviceState,
  Manufacturer,
  Organization,
  PartModel,
  ProductionLine,
  Section,
  SensorType,
} from '../../../../core/_models'
import {UseSensorsProps} from '../../../../core/requests/factory'
import {useFilterState} from './useFilterState'

const useSensorsFilter = (props: UsePaginationProps = {}) => {
  const {activePage, setActivePage, pageSize} = usePagination({
    initialPage: props.initialPage,
    size: props.size,
  })

  const filterState = {
    organization: useFilterState<Organization>(),
    area: useFilterState<Area>(),
    state: useFilterState<DeviceState>(),
    productionLine: useFilterState<ProductionLine>(),
    section: useFilterState<Section>(),
    asset: useFilterState<Asset>(),
    dataType: useFilterState<SensorType>(),
    manufacturer: useFilterState<Manufacturer>(),
    model: useFilterState<PartModel>(),
    trackScrap: useFilterState<boolean>(),
  }

  const sensorFilterProps: UseSensorsProps = {
    area: filterState.area.value?._id,
    state: filterState.state.value,
    organization: filterState.organization.value?._id,
    productionLine: filterState.productionLine.value?._id,
    section: filterState.section.value?._id,
    asset: filterState.asset.value?._id,
    dataType: filterState.dataType.value,
    manufacturer: filterState.manufacturer.value,
    model: filterState.model.value,
    trackScrap: filterState.trackScrap.value,
    page: activePage,
    size: pageSize,
    options: {
      keepPreviousData: true,
    },
  }

  return {
    sensorFilterProps,
    activePage,
    setActivePage,
    pageSize,
    filterState,
    filterCount: Object.values(filterState).filter((v) => v.value !== undefined).length,
  }
}

export default useSensorsFilter
