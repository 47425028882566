import {Priority, WorkOrderCategory} from '../../../core/_models'

export type ActionType = 'send_notification' | 'create_work_order' | 'append_to_log'
export const actionTypes: ActionType[] = ['send_notification', 'create_work_order', 'append_to_log']
export const actionTypesMap: Record<ActionType, string> = {
  send_notification: 'Send notification',
  create_work_order: 'Create work order',
  append_to_log: 'Append to log',
}

// Send Notification

export type SendNotificationForm = {
  notification_group?: string
  user?: string
  level?: 0 | 1 | 2 | 3 | 4
  timeout?: number
  require_click?: boolean
  channels?: ('Email' | 'SMS' | 'Push')[]
  title?: string
  message?: string
  additional_message?: string
}

export const defaultSendNotificationForm: SendNotificationForm = {
  level: 0,
  timeout: 10,
  require_click: false,
}

export type SendNotificationAction = {
  type: 'send_notification'
  enabled: boolean
  form: SendNotificationForm
}

// Append to Log

export type AppendToLogForm = {
  title?: string
  message?: string
  additional_message?: string
}

export const defaultAppendToLogForm: AppendToLogForm = {}

export type AppendToLogAction = {
  type: 'append_to_log'
  enabled: boolean
  form: AppendToLogForm
}

// Create Work Order

export type CreateWorkOrderForm = {
  title?: string
  expected_duration?: number
  actions?: string[]
  factory_entity?: string
  assignees?: string[]
  priority?: Priority
  category?: WorkOrderCategory
}

export type CreateWorkOrderAction = {
  type: 'create_work_order'
  enabled: boolean
  form: CreateWorkOrderForm
}

export const defaultCreateWorkOrderForm: CreateWorkOrderForm = {}

export type Action = SendNotificationAction | AppendToLogAction | CreateWorkOrderAction

export const validateAction = (action: Action): boolean => {
  switch (action.type) {
    case 'send_notification':
      return action.form.channels !== undefined
    case 'create_work_order':
      return (
        action.form.title !== undefined &&
        !!action.form.title &&
        action.form.expected_duration !== undefined
      )
    case 'append_to_log':
      return true
  }
}

export const validateActions = (actions: Action[]): boolean => actions.every(validateAction)
