const dashboardV2Enabled = process.env.REACT_APP_DASHBOARDS_V2_ENABLED === '1'

const WA_CONDITION_GRAPHQL_URL: string = process.env.REACT_APP_WA_CONDITION_GRAPHQL_URL
const WA_DASHBOARDS_GRAPHQL_URL: string = process.env.REACT_APP_WA_DASHBOARDS_GRAPHQL_URL
const WA_ENERGY_GRAPHQL_URL: string = process.env.REACT_APP_WA_ENERGY_GRAPHQL_URL

export {
  dashboardV2Enabled,
  WA_CONDITION_GRAPHQL_URL,
  WA_DASHBOARDS_GRAPHQL_URL,
  WA_ENERGY_GRAPHQL_URL,
}
