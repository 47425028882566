import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {PowerFactorGraph} from '../../components/charts/PowerFactorGraph'
import {WidgetProps} from './Widget'

const PowerFactorWidget = ({widget, height}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time
  return (
    <PowerFactorGraph
      productionLineId={widget.entity}
      height={height}
      startTime={startTime}
      endTime={endTime}
      chartId={widget._id}
    />
  )
}

export default PowerFactorWidget
