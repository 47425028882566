export type ICreateBatchTemplate = {
  name?: string
  organization?: string
  product?: string
  total_product_quantity?: number
  auto_stop_detection_tolerance?: number

  // non API fields
  // planned_production_time_seconds?: number
  // planned_production_time_minutes?: number
  // planned_production_time_hours?: number
}

export type IUpdateBatchTemplate = {
  name?: string
  product?: string
  total_product_quantity?: number
  auto_stop_detection_tolerance?: number
}

export const defaultCreateBatchTemplate: ICreateBatchTemplate = {
  name: undefined,
  organization: undefined,
  product: undefined,
  total_product_quantity: undefined,
  auto_stop_detection_tolerance: 5,
}

export type StepProps = {
  data: ICreateBatchTemplate
  updateData: (fieldsToUpdate: Partial<ICreateBatchTemplate>) => void
  hasError: boolean
}
