import SelectFieldBoxes from '../../../../../components/form-components/SelectFieldBoxes'
import {StepProps} from './_models'

const HealthTypeStep = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxes
          name='Health Type'
          optionNames={['Simple', 'Advanced']}
          optionValues={['Simple', 'Advanced']}
          data={data}
          updateData={updateData}
          dataField='health_type'
          required
          hasError={hasError}
          colSpace={12}
          lastField
        />
      </div>
    </div>
  )
}

export default HealthTypeStep
