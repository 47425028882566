import {Column} from 'react-table'
import {StringCell} from '../core/columns/StringCell'
import {SelectionCell} from '../core/columns/SelectionCell'
import {CustomHeader} from '../core/columns/CustomHeader'
import {SelectionHeader} from '../core/columns/SelectionHeader'
import {Sensor} from '../../../core/_models'
import {BadgeCell} from '../core/columns/BadgeCell'
import {NameImageCell} from '../core/columns/ImageStringCell'
import {CellClickContext} from '../core/columns/TableBody'
import FactoryCell from '../core/columns/FactoryCell'
import AreaCell from '../core/columns/AreaCell'
import AssetCell from '../core/columns/AssetCell'
import ProductionLineCell from '../core/columns/ProductionLineCell'
import PlacementCell from '../core/columns/PlacementCell'

const Columns: ReadonlyArray<Column<Sensor>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} tableId='hs_sensors_table' />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index]._id} tableId='hs_sensors_table' />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Device Id' className='min-w-125px' />
    ),
    id: 'deviceId',
    Cell: ({...props}) => (
      <CellClickContext.Consumer>
        {({onCellClick}) => (
          <NameImageCell
            str={props.data[props.row.index].device_id}
            images={
              props.data[props.row.index].gallery
                ? [
                    props.data[props.row.index].gallery!.profile_image,
                    ...props.data[props.row.index].gallery!.images,
                  ]
                : undefined
            }
            index={props.row.index}
            entityId={props.data[props.row.index]._id}
            queryToInvalidate='sensors'
            onClick={() => (onCellClick ? onCellClick(props.data[props.row.index]._id) : undefined)}
          />
        )}
      </CellClickContext.Consumer>
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Factory' className='min-w-125px' />,
    id: 'factory',
    Cell: ({...props}) => <FactoryCell factoryReference={props.data[props.row.index].factory} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Area' className='min-w-125px' />,
    id: 'area',
    Cell: ({...props}) => <AreaCell areaReference={props.data[props.row.index].area} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Asset/Production Line' className='min-w-125px' />
    ),
    id: 'asset',
    Cell: ({...props}) =>
      props.data[props.row.index].asset ? (
        <AssetCell assetReference={props.data[props.row.index].asset!} />
      ) : props.data[props.row.index].production_line ? (
        <ProductionLineCell
          productionLineReference={props.data[props.row.index].production_line!}
        />
      ) : (
        <StringCell str='' />
      ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Placement' className='min-w-125px' />
    ),
    id: 'placement',
    Cell: ({...props}) => (
      <PlacementCell placementReference={props.data[props.row.index].placement} />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='State' className='min-w-125px' />,
    id: 'state',
    Cell: ({...props}) => {
      const state = props.data[props.row.index].state
      const color = state === 'Online' ? 'success' : 'danger'
      return <BadgeCell color={color} str={state} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Battery' className='min-w-125px' />,
    id: 'battery_low',
    Cell: ({...props}) => {
      const batteryIsLow = props.data[props.row.index].battery_low
      const color = !batteryIsLow ? 'fa-battery-full text-success' : 'fa-battery-empty text-danger'
      return <div className={`fa-duotone fa-solid ${color}`}></div>
    },
  },
]

export {Columns}
