import {useCategorySelection} from '../CategorySelectionContext'
import DisplayCategoryGroup from './DisplayCategoryGroup'
import DisplayCategory from './DisplayCategory'

type EventCategoriesContentProps = {
  className?: string
}

const EventCategoriesContent = ({className = ''}: EventCategoriesContentProps) => {
  const {activeCategory, activeCategoryGroup, activeSubEventCategoryPathIds} =
    useCategorySelection()

  return (
    <div
      className={`d-flex flex-column p-10 mh-800px scroll-y ${className}`}
      style={{backgroundColor: 'var(--kt-app-bg-color)'}}
    >
      {activeCategoryGroup && <DisplayCategoryGroup activeCategoryGroup={activeCategoryGroup} />}
      {activeCategory && (
        <DisplayCategory
          eventCategory={activeCategory}
          subEventCategoryPathIds={activeSubEventCategoryPathIds}
        />
      )}
      {!activeCategoryGroup && !activeCategory && (
        <div className='text-center text-muted fs-4'>Select a category or category group</div>
      )}
    </div>
  )
}

export default EventCategoriesContent
