import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {createDashboard, createWidget, useDashboard, useWidgets} from '../../core/requests/template'
import {KTSVG} from '../../../_metronic/helpers'
import clsx from 'clsx'
import {FadeLoader, HashLoader, MoonLoader} from 'react-spinners'
import {useQueryClient} from 'react-query'
import {BaseModel, Dashboard} from '../../core/_models'
import {Link} from 'react-router-dom'

type CopyDashboardModalProps = {
  show: boolean
  hide: () => void
  dashboardId: string
}

const CopyDashboardModal = ({show, hide, dashboardId}: CopyDashboardModalProps) => {
  const [stepText, setStepText] = useState('Creating dashboard...')
  const [newDashboardName, setNewDashboardName] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [newDashboard, setNewDashboard] = useState<Dashboard & BaseModel>()
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [numSteps, setNumSteps] = useState(1)

  const {data: dashboard} = useDashboard({dashboard: dashboardId})
  const {data: widgets} = useWidgets({dashboards: [dashboardId]})
  const queryClient = useQueryClient()

  const copyDashboard = async () => {
    if (dashboard === undefined || widgets === undefined || !newDashboardName) return

    setSubmitting(true)
    setCurrentStep(0)
    setNumSteps(1 + widgets.length)

    try {
      const newDashboard = await createDashboard({name: newDashboardName, tags: dashboard.tags})
      setCurrentStep(1)
      setStepText('Copying widgets...')

      for (const widget of widgets) {
        await createWidget({...widget, _id: undefined, dashboard: newDashboard._id})
        setCurrentStep((step) => step + 1)
      }

      await queryClient.invalidateQueries('dashboards')
      await queryClient.invalidateQueries('widgets')
      setNewDashboard(newDashboard)
    } catch (error) {
      setErrorOccurred(true)
    } finally {
      setSubmitting(false)
    }
  }

  const close = () => {
    hide()
    setNewDashboardName('')
    setNewDashboard(undefined)
    setStepText('Creating dashboard...')
    setSubmitting(false)
    setErrorOccurred(false)
    setCurrentStep(0)
    setNumSteps(1)
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='lg'
      show={show}
      onHide={close}
      backdrop={submitting ? 'static' : true}
      container={document.getElementById('root-modals')}
    >
      <div className='modal-header'>
        <h2>{`Copy ${dashboard?.name || 'Dashboard'}`}</h2>
        <div>
          <div
            className={clsx(
              'btn btn-sm btn-icon btn-active-color-primary',
              submitting && 'disabled'
            )}
            onClick={close}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
      </div>
      <div className='modal-body mx-5'>
        {errorOccurred ? (
          <>
            <div className='text-muted fw-semibold fs-4 mb-5'>
              An error occurred while copying the dashboard. Please try again later.
            </div>

            <div className='d-flex justify-content-center'>
              <button type='button' className='btn btn-primary' onClick={close}>
                Close
              </button>
            </div>
          </>
        ) : submitting ? (
          <>
            <div className='d-flex justify-content-center mb-5'>
              <HashLoader color='#009ef7' />
            </div>
            <div className='d-flex justify-content-center mb-1'>
              <div className='progress h-6px w-50'>
                <div
                  className='progress-bar bg-primary'
                  role='progressbar'
                  style={{
                    width: `${(currentStep / numSteps) * 100}%`,
                  }}
                />
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <div className='text-muted fw-semibold fs-4 mb-5'>{stepText}</div>
            </div>
          </>
        ) : newDashboard ? (
          <>
            <div className='text-muted fw-semibold fs-4 mb-5'>
              Your new dashboard has been successfully created! You can now view it by clicking the
              button below.
            </div>

            <div className='d-flex justify-content-center'>
              <Link
                className='btn btn-primary'
                to={`/dashboards/${newDashboard._id}`}
                onClick={close}
              >
                View New Dashboard
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className='text-muted fw-semibold fs-4 mb-5'>
              Please enter a name for the new dashboard:
            </div>

            <input
              type='text'
              placeholder='New Dashboard Name'
              className='form-control form-control-lg form-control-solid mb-10'
              name='name'
              value={newDashboardName}
              onChange={(e) => setNewDashboardName(e.target.value)}
            />

            <div className='text-muted fw-semibold fs-4 mb-5'>
              By clicking "Copy," you will create a new dashboard with all widgets and their
              configurations duplicated from the current dashboard. You can edit or customize the
              new dashboard at any time after creation.
            </div>

            <div className='d-flex justify-content-center'>
              <button type='button' className='btn btn-light me-2' onClick={close}>
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={newDashboardName === '' || widgets === undefined}
                onClick={copyDashboard}
              >
                Copy
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default CopyDashboardModal
