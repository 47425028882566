import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: React.ReactNode
  fontAwesomeIcon?: string
  buttonIcon?: string
  buttonTheme?: string
  handleButtonClick?: () => void
  forceRefresh?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  fontAwesomeIcon,
  buttonIcon,
  buttonTheme = 'success',
  handleButtonClick = () => {},
  forceRefresh = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  const handleButtonClickWrapper = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation() // Stop the click event from propagating
    event.preventDefault()
    handleButtonClick()
  }

  return (
    <div className='menu-item'>
      <Link
        className={clsx('menu-link without-sub', {active: isActive})}
        to={to}
        reloadDocument={forceRefresh}
      >
        {fontAwesomeIcon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <>
            <span className='menu-icon'>
              {' '}
              <i className={`fa-solid fs-2 ${fontAwesomeIcon}`}></i>
            </span>
          </>
        )}
        <span className='menu-title'>{title}</span>
        {buttonIcon && (
          <span className='menu-badge' onClick={handleButtonClickWrapper}>
            <span className={`badge badge-${buttonTheme}`}>
              <i className={`fa-solid fs-8 ${buttonIcon}`} style={{color: '#ffffff'}} />
            </span>
          </span>
        )}
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
