import clsx from 'clsx'
import {useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {
  createTag,
  deleteTag,
  updateTag,
  useFactoryEntities,
  useTags,
} from '../../core/requests/factory'
import {FactoryEntity, Tag} from '../../core/_models'
import useEntityOptions from './useEntityOptions'
import {useQueryClient} from 'react-query'
import {entityGroupToString, entityToString} from '../../core/name-util'

const MODAL_HEIGHT = '800px'

type TagsModalProps = {
  show: boolean
  open: () => void
  hide: () => void
}

const TagsModal = ({show, open, hide}: TagsModalProps) => {
  const {data: tags} = useTags()

  return (
    <>
      <Modal
        show={show}
        tabIndex={-1}
        aria-hidden='true'
        size='xl'
        dialogClassName='modal-dialog modal-dialog-centered'
        onHide={hide}
      >
        <div className='modal-body p-10' style={{height: MODAL_HEIGHT}}>
          <div className='fs-1 fw-bold mb-5'>Tags</div>

          <div className='container border border-secondary rounded py-1'>
            <div className='row p-1'>
              <div className='col-3 text-muted'>Key</div>
              <div className='col-3 text-muted'>Value</div>
              <div className='col-6 text-muted'>Entity</div>
            </div>
            {tags?.items.map((tag) => (
              <TagRow key={tag._id} tag={tag} />
            ))}
            <NewTagRow />
          </div>
        </div>
      </Modal>
    </>
  )
}

const TagRow = ({tag}: {tag: Tag}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const {data: entity} = useFactoryEntities({
    entityIds: [tag.entity || ''],
    options: {enabled: !!tag.entity},
  })
  const entityName = entity?.items[0] ? entityToString(entity.items[0]) : undefined

  const onDelete = async () => {
    if (isLoading) return
    setIsLoading(true)
    try {
      await deleteTag(tag._id)
      await queryClient.invalidateQueries('tags')
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  if (isEditing)
    return <EditTagRow tag={tag} tagEntity={entity?.items.at(0)} setIsEditing={setIsEditing} />

  return (
    <div className={clsx('row py-2 border-top border-secondary', isLoading && 'text-muted')}>
      <div className='col-3 fw-bold'>{tag.key}</div>
      <div className='col-3 fw-bold'>{tag.value}</div>
      <div className='col-4 fw-bold'>{entityName || '-'}</div>
      <div className='col-2 d-flex align-items-center justify-content-end'>
        {!isLoading && (
          <>
            <i
              className='fa-solid fa-pen cursor-pointer me-5'
              style={{color: '#6C757D', fontSize: '1.2rem'}}
              onClick={() => setIsEditing(true)}
            />
            <i
              className='fa-solid fa-trash cursor-pointer me-2'
              style={{color: '#DC3545', fontSize: '1.2rem'}}
              onClick={onDelete}
            />
          </>
        )}
        {isLoading && <i className='fa-solid fa-spinner fa-spin me-2 float-end' />}
      </div>
    </div>
  )
}

const EditTagRow = ({
  tag,
  tagEntity,
  setIsEditing,
}: {
  tag: Tag
  tagEntity?: FactoryEntity
  setIsEditing: (value: boolean) => void
}) => {
  const [newKey, setNewKey] = useState(tag.key)
  const [newValue, setNewValue] = useState(tag.value)
  const [isLoading, setIsLoading] = useState(false)
  const rowRef = useRef<HTMLDivElement | null>(null)
  const queryClient = useQueryClient()

  const {entityTypeSelect, entitySelect, entity} = useEntityOptions({
    // @ts-ignore
    entityType: tagEntity && entityGroupToString(tagEntity.type),
    entity: tag.entity,
  })

  const onSubmit = async () => {
    if (isLoading || !newKey || !newValue) setIsEditing(false)
    if (newKey === tag.key && newValue === tag.value && entity === tag.entity) setIsEditing(false)
    setIsLoading(true)
    try {
      await updateTag({id: tag._id, key: newKey, value: newValue, entity})
      await queryClient.invalidateQueries('tags')
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
      setIsEditing(false)
    }
  }

  useEffect(() => {
    const handleClickOutsideRow = (event: MouseEvent) => {
      if (!isLoading && rowRef.current && !rowRef.current.contains(event.target as Node))
        setIsEditing(false)
    }
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (!isLoading && event.key === 'Escape') setIsEditing(false)
    }

    if (!isLoading) {
      document.addEventListener('mousedown', handleClickOutsideRow)
      document.addEventListener('keydown', handleEscapeKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideRow)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [isLoading])

  return (
    <div ref={rowRef} className='row py-2 border-top border-secondary align-items-center'>
      <div className='col-3'>
        <input
          type='text'
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
          className='col-10 m-0 p-0 ps-1 fw-bold'
          style={{border: 'none', background: 'transparent', outline: 'none'}}
          placeholder='Enter key...'
          autoFocus
          disabled={isLoading}
        />
      </div>
      <div className='col-3'>
        <input
          type='text'
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          className='col-10 m-0 p-0 ps-1 fw-bold'
          style={{border: 'none', background: 'transparent', outline: 'none'}}
          placeholder='Enter value...'
          disabled={isLoading}
        />
      </div>
      <div className='col-4 d-flex flex-column'>
        <div className='mb-2'>{entityTypeSelect}</div>
        <div>{entitySelect}</div>
      </div>
      <div className='col-2'>
        {!isLoading && (
          <button
            type='button'
            className={clsx('btn btn-primary btn-sm d-flex align-items-center px-2 py-3 float-end')}
            disabled={isLoading || !newKey || !newValue}
            onClick={onSubmit}
          >
            Save
            <i className='fa-solid fa-floppy-disk ms-1' />
          </button>
        )}
        {isLoading && <i className='fa-solid fa-spinner fa-spin me-7 float-end' />}
      </div>
    </div>
  )
}

const NewTagRow = () => {
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [newKey, setNewKey] = useState('')
  const [newValue, setNewValue] = useState('')
  const rowRef = useRef<HTMLDivElement | null>(null)
  const queryClient = useQueryClient()

  const {entityTypeSelect, entitySelect, entity, setEntity, setEntityType} = useEntityOptions()

  const reset = () => {
    setNewKey('')
    setNewValue('')
    setEntity(undefined)
    setEntityType(undefined)
    setIsActive(false)
  }

  const onSubmit = async () => {
    if (isLoading || !newKey || !newValue) {
      reset()
      return
    }
    setIsLoading(true)
    try {
      await createTag({key: newKey, value: newValue, entity})
      await queryClient.invalidateQueries('tags')
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
      reset()
    }
  }

  useEffect(() => {
    const handleClickOutsideRow = (event: MouseEvent) => {
      if (!isLoading && rowRef.current && !rowRef.current.contains(event.target as Node)) reset()
    }
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (!isLoading && event.key === 'Escape') reset()
    }

    if (isActive && !isLoading) {
      document.addEventListener('mousedown', handleClickOutsideRow)
      document.addEventListener('keydown', handleEscapeKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideRow)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [isActive, isLoading])

  if (isActive) {
    return (
      <div ref={rowRef} className='row py-2 border-top border-secondary align-items-center'>
        <div className='col-3'>
          <input
            type='text'
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
            className='col-10 m-0 p-0 ps-1 fw-bold'
            style={{border: 'none', background: 'transparent', outline: 'none'}}
            placeholder='Enter key...'
            autoFocus
            disabled={isLoading}
          />
        </div>
        <div className='col-3'>
          <input
            type='text'
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            className='col-10 m-0 p-0 ps-1 fw-bold'
            style={{border: 'none', background: 'transparent', outline: 'none'}}
            placeholder='Enter value...'
            disabled={isLoading}
          />
        </div>
        <div className='col-4 d-flex flex-column'>
          <div className='mb-2'>{entityTypeSelect}</div>
          <div>{entitySelect}</div>
        </div>
        <div className='col-2'>
          {!isLoading && (
            <button
              type='button'
              className={clsx(
                'btn btn-primary btn-sm d-flex align-items-center px-2 py-3 float-end'
              )}
              disabled={isLoading || !newKey || !newValue}
              onClick={onSubmit}
            >
              Save
              <i className='fa-solid fa-floppy-disk ms-1' />
            </button>
          )}
          {isLoading && <i className='fa-solid fa-spinner fa-spin me-7 float-end' />}
        </div>
      </div>
    )
  }

  return (
    <div
      className='row py-2 border-top border-secondary cursor-pointer bg-hover-secondary'
      onClick={() => setIsActive(true)}
    >
      <div className='d-flex align-items-center'>
        <i className='fa-solid fa-plus me-3' />
        <div className='text-muted'>Tag</div>
      </div>
    </div>
  )
}

export default TagsModal
