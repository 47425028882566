import {AxiosError} from 'axios'
import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useQueryClient} from 'react-query'
import {KTSVG} from '../../../../../_metronic/helpers'
import LoadingWrapper from '../../../../components/LoadingWrapper'
import ModalErrorWrapper from '../../../../components/ModalErrorWrapper'
import {DeletionConfirmation, ServerError} from '../../../../core/_models'
import {useSelectedRows} from '../../../../providers/SelectedRowsProvider'

type Props = {
  onDelete: (id: string) => Promise<DeletionConfirmation>
  queryKey: any
}

const TableSelectionToolbar = ({onDelete, queryKey}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const {selected, clearSelected} = useSelectedRows()
  const queryClient = useQueryClient()

  const deleteSelections = async () => {
    await Promise.all(selected.map((id) => onDelete(id)))
    queryClient.invalidateQueries(queryKey)
    clearSelected()
    setShowModal(false)
  }

  return (
    <>
      <div className='d-flex justify-content-end align-items-center'>
        <div className='fw-bolder me-5'>
          <span className='me-2'>{selected.length}</span> Selected
        </div>

        <button
          type='button'
          className='btn btn-danger'
          // onClick={async () => await deleteSelectedItems.mutateAsync()}
          onClick={() => setShowModal(true)}
        >
          Delete Selected
        </button>
      </div>
      <ConfirmDeletionModal
        show={showModal}
        hide={() => setShowModal(false)}
        deleteSelections={deleteSelections}
      />
    </>
  )
}

type ConfirmDeletionModalProps = {
  show: boolean
  hide: () => void
  deleteSelections: () => Promise<void>
}

const ConfirmDeletionModal = ({show, hide, deleteSelections}: ConfirmDeletionModalProps) => {
  const [deleting, setDeleting] = useState(false)
  const [hasServerError, setHasServerError] = useState(false)
  const [serverError, setServerError] = useState<ServerError | null>(null)
  const {selected} = useSelectedRows()

  const close = () => {
    setDeleting(false)
    setHasServerError(false)
    setServerError(null)
    hide()
  }

  const handleDelete = async () => {
    setDeleting(true)
    try {
      await deleteSelections()
      close()
    } catch (error) {
      setHasServerError(true)
      const errorData: ServerError = (error as AxiosError).response?.data as ServerError
      setServerError(errorData)
    }
  }

  return (
    <Modal
      id='hs_confirm_deletion_modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='lg'
      show={show}
      onHide={close}
      backdrop={deleting ? 'static' : true}
      container={document.getElementById('root-modals')}
    >
      <ModalErrorWrapper
        hasError={hasServerError}
        errorData={serverError || undefined}
        hide={close}
      >
        <LoadingWrapper loading={deleting}>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={close}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>

          <div className='modal-body mx-5 mx-xl-18 pt-0'>
            <h1 className='text-center mb-13'>Confirm Deletion</h1>

            <div className='notice bg-light-danger rounded border-danger border border-dashed p-6 mb-3'>
              <div className='d-flex'>
                <KTSVG
                  className='svg-icon-2tx svg-icon-danger me-4'
                  path='/media/icons/duotune/general/gen044.svg'
                />
                <div className='d-flex flex-row flex-stack flex-grow-1'>
                  <div className='fw-semibold'>
                    <h4 className='text-gray-900 fw-bold'>{`You are about to perform ${
                      selected.length > 1 ? 'multiple' : 'a'
                    } deletion${selected.length > 1 ? 's' : ''}`}</h4>
                    <div className='fw-bold text-danger'>This action cannot be undone!</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-center pt-5'>
              <button className='btn btn-light me-3' onClick={hide}>
                Cancel
              </button>
              <button className='btn btn-danger' onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        </LoadingWrapper>
      </ModalErrorWrapper>
    </Modal>
  )
}

export {TableSelectionToolbar, ConfirmDeletionModal}
