import {useEventCategoryGroups} from '../core/requests/oee'
import useModalState from '../hooks/UseModalState'
import LogEventModal from '../modals/log-event/LogEventModal'
import LoadingWrapper from './LoadingWrapper'

type Props =
  | {productionLineId: string; assetId?: never}
  | {assetId: string; productionLineId?: never}

const LogEventButton = ({...ids}: Props) => {
  const modal = useModalState()

  return (
    <>
      <span className='d-inline-block' tabIndex={0}>
        <button type='button' className='btn btn-lg btn-secondary' onClick={modal.open}>
          + Event
        </button>
      </span>

      {ids.productionLineId && (
        <LogEventModal
          show={modal.isOpen}
          handleClose={modal.close}
          productionLineId={ids.productionLineId}
        />
      )}
      {ids.assetId && (
        <LogEventModal show={modal.isOpen} handleClose={modal.close} assetId={ids.assetId} />
      )}
    </>
  )
}

export default LogEventButton
