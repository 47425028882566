import {User} from '@sentry/react'
import React from 'react'
import {OrganizationToolbar} from '../../../_metronic/layout/components/toolbar/OrganizationToolbar'
import PageNavigationBar, {Breadcrumb} from '../../components/PageNavigationBar'
import {Invitation, Reference} from '../../core/_models'
import {useProfile} from '../../core/requests/auth'
import {SelectedRowsProvider} from '../../providers/SelectedRowsProvider'
import {OrganizationWrapper} from './OrganizationWrapper'

export interface InvitationProfile extends User, Reference {
  invitation?: Invitation
}

const OrganizationPage = () => {
  const {data: profile} = useProfile()

  const breadcrumbs: Breadcrumb[] = [
    {
      title: 'Manage Your Organization',
      path: '/organization',
      isActive: true,
      isSeparator: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <SelectedRowsProvider idField='email'>
      <PageNavigationBar title={profile?.organization?.name || ''} breadcrumbs={breadcrumbs}>
        <OrganizationToolbar />
      </PageNavigationBar>
      <OrganizationWrapper />
    </SelectedRowsProvider>
  )
}

export {OrganizationPage}
