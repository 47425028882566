import {TimeSeriesPoint, Timespan as GraphQLTimespan} from '../../../../../gql/graphql'
import useGetPowerConsumptionHistory from '../../../../core/graphql/energy/queries/GetPowerConsumptionHistory'
import {LineGraph} from './LineGraph'
import {LineData} from '../../core/_chartConfigs/lineConfig'
import {EntityGroup, Timespan} from '../../../../core/_models'
import {ColumnData} from '../../core/_chartConfigs/columnConfig'
import ColumnGraph from './ColumnGraph'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'

type PowerConsumptionGraphProps = {
  entityId: string
  entityGroup: EntityGroup
  type?: 'Power Consumption' | 'Power Consumption Column'
  timespan?: Timespan
  multiplier?: number
  startTime?: string
  endTime?: string
  dynamicTimeRange?: string
  height: string
  chartId?: string
}

const PowerConsumptionGraph = ({
  entityId,
  entityGroup,
  type = 'Power Consumption',
  timespan,
  multiplier,
  startTime,
  endTime,
  dynamicTimeRange,
  height,
  chartId = `power-consumption-${entityId}`,
}: PowerConsumptionGraphProps) => {
  const start = dynamicTimeRange ? startTimeFromIso8601Duration(dynamicTimeRange) : startTime
  const end = dynamicTimeRange ? undefined : endTime

  const {consumption, isLoading} = useGetPowerConsumptionHistory({
    entityGroup: entityGroup,
    id: entityId,
    startTime: start,
    endTime: end,
    timespan: timespan ? (timespan.toUpperCase() as GraphQLTimespan) : undefined,
    multiplier,
  })

  if (type === 'Power Consumption') {
    const lineData: LineData[] | undefined =
      consumption !== undefined ? [{dataType: 'Power', unit: 'kW', data: consumption}] : []
    return (
      <LineGraph loading={isLoading} id={chartId} height={height} data={{[entityId]: lineData}} />
    )
  } else {
    const columnData: ColumnData | undefined =
      consumption !== undefined ? {unit: 'kW', data: consumption, timespan} : undefined
    return <ColumnGraph id={chartId} height={height} data={columnData} />
  }
}

export {PowerConsumptionGraph}
