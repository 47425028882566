import React from 'react'
import {useIntl} from 'react-intl'
import LoadingWrapper from '../../components/LoadingWrapper'
import SelectField from '../../components/form-components/SelectField'
import TextField from '../../components/form-components/TextField'
import QRReader from '../../components/qr/QRReader'
import {StepProps} from './_models'
import {useAreasPaginated} from '../../core/requests/factory'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  const {
    items: areas,
    isLoading: areasLoading,
    isFetching: areasFetching,
    fetchNextPage: areasNextPage,
  } = useAreasPaginated()

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={areasLoading}>
        <div className='w-100'>
          <QRReader
            onSuccess={(decodedText) => {
              updateData({
                device_id: decodedText,
              })
            }}
          />
          <TextField
            name='Gateway Device ID'
            data={data}
            updateData={updateData}
            dataField='device_id'
            hasError={hasError}
            required
          />

          {/* Area */}
          <SelectField
            name='Area'
            optionNames={areas?.map((area) => area.name) || []}
            optionValues={areas?.map((area) => area._id) || []}
            data={data}
            updateData={updateData}
            dataField='area'
            lastField
            hasError={hasError}
            required
            tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.AREA'})}
            isLoading={areasFetching}
            gotoNextPage={areasNextPage}
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step1
