import {SetStateAction, useState} from 'react'
import {EventCategory, SubEventCategory} from '../../../core/_models'
import {
  DeleteEventCategoryProps,
  deleteEventCategory,
  useEventCategories,
  useEventCategoryGroups,
  useEventLogs,
} from '../../../core/requests/oee'
import {Modal} from 'react-bootstrap'
import LoadingOverlayWrapper from '../../../components/LoadingOverlayWrapper'
import {KTSVG} from '../../../../_metronic/helpers'
import {useCategorySelection} from '../CategorySelectionContext'
import Select from 'react-select'
import {useQueryClient} from 'react-query'
import clsx from 'clsx'

type EventCategoryDeleteModalProps = {
  show: boolean
  close: () => void
  eventCategory: EventCategory
  subEventCategoryPathIds: string[]
}

const EventCategoryDeleteModal = ({
  show,
  close,
  eventCategory,
  subEventCategoryPathIds,
}: EventCategoryDeleteModalProps) => {
  const [deleting, setDeleting] = useState(false)
  const [chosenOption, setChosenOption] = useState<'migrate' | 'delete' | null>(null)
  // const [targetCategoryId, setTargetCategoryId] = useState<string | undefined>(undefined)

  const queryClient = useQueryClient()
  const {setActiveCategory, findSubEventCategoryByPath, collectCategoryIds} = useCategorySelection()

  const category =
    subEventCategoryPathIds.length === 0
      ? eventCategory
      : findSubEventCategoryByPath(eventCategory, subEventCategoryPathIds)
  if (!category) throw new Error('Category not found')
  const allCategoryIds = collectCategoryIds(category)

  const {data: eventLogs, isLoading: eventLogsAreLoading} = useEventLogs({
    categories: allCategoryIds,
  })

  const loading = eventLogsAreLoading || deleting
  const hasNoLogs = !eventLogsAreLoading && eventLogs !== undefined && eventLogs.items.length === 0
  const categoryIdsWithLogs = allCategoryIds.filter((id) =>
    eventLogs?.items.some((log) => log.categories.includes(id))
  )

  const handleDelete = async (props: DeleteEventCategoryProps) => {
    setDeleting(true)
    try {
      const status = await deleteEventCategory(props)
      await queryClient.invalidateQueries('eventCategories')
      if (status.was_deleted) {
        if (subEventCategoryPathIds.length === 0)
          setActiveCategory(undefined, []) // TODO: navigate to parent group
        else setActiveCategory(eventCategory, subEventCategoryPathIds.slice(0, -1))
      } else console.error('Failed to delete event category group')
    } catch (error) {
      console.error(error)
    } finally {
      setDeleting(false)
      close()
    }
  }

  return (
    <Modal
      show={show}
      tabIndex={-1}
      aria-hidden='true'
      size='lg'
      dialogClassName='modal-dialog modal-dialog-centered'
      onHide={close}
      backdrop={loading ? 'static' : true}
    >
      <LoadingOverlayWrapper loading={loading}>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={close}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        {hasNoLogs && (
          <ConfirmDeletionNoLogs category={category} close={close} handleDelete={handleDelete} />
        )}
        {!hasNoLogs && (
          <>
            {chosenOption === null && (
              <ChooseToMigrate category={category} setChosenOption={setChosenOption} />
            )}
            {chosenOption === 'migrate' && (
              <MigrateAndDelete
                category={category}
                close={close}
                handleDelete={handleDelete}
                categoryIdsWithLogs={categoryIdsWithLogs}
              />
            )}
            {chosenOption === 'delete' && (
              <ConfirmDeletionWithLogs
                category={category}
                close={close}
                handleDelete={handleDelete}
              />
            )}
          </>
        )}
      </LoadingOverlayWrapper>
    </Modal>
  )
}

const MigrateAndDelete = ({
  category,
  handleDelete,
  close,
  categoryIdsWithLogs,
}: {
  category: EventCategory | SubEventCategory
  handleDelete: (props: DeleteEventCategoryProps) => Promise<void>
  close: () => void
  categoryIdsWithLogs: string[]
}) => {
  const [targetMap, setTargetMap] = useState<{
    [key: string]: {category: EventCategory; subCategory?: SubEventCategory}
  }>({})

  const {data: eventCategories, isLoading: eventCategoriesAreLoading} = useEventCategories()

  const allTargetsChosen = Object.keys(targetMap).length === categoryIdsWithLogs.length
  const potentialTargetCategories = eventCategories?.items.filter(
    (eventCategory) => eventCategory._id !== category._id
  )

  const onDelete = () => {
    const migrations = Object.entries(targetMap).map(([sourceId, target]) => [
      sourceId,
      target.subCategory?._id! || target.category._id,
    ]) as [string, string][]
    handleDelete({id: category._id!, migrations})
  }

  return (
    <div className='modal-body mx-5 mx-xl-18 pt-0'>
      <h1 className='text-center mb-8'>Migrate Event Logs Before Deletion</h1>

      <div className='fw-semibold fs-6 mb-5'>
        For each category or sub-category listed below that contains event logs, first select a
        target category where the logs will be migrated. After selecting a target category, you can
        further specify a sub-category within it to ensure logs are migrated to the most appropriate
        location. All categories must have a migration target selected before proceeding
      </div>

      <LoadingOverlayWrapper loading={eventCategoriesAreLoading}>
        <div className='container border border-secondary rounded py-1 mt-5'>
          <div className='row p-1'>
            <div className='col-4 text-muted'>Source</div>
            <div className='col-1 text-muted'>{`->`}</div>
            <div className='col-7 text-muted'>Target</div>
          </div>

          <Row
            category={category}
            targetMap={targetMap}
            setTargetMap={setTargetMap}
            potentialTargetCategories={potentialTargetCategories || []}
            categoryIdsWithLogs={categoryIdsWithLogs}
          />
        </div>
      </LoadingOverlayWrapper>

      <div className='d-flex flex-center pt-5'>
        <button className='btn btn-light me-3' onClick={close}>
          Cancel
        </button>
        <button className='btn btn-danger' disabled={!allTargetsChosen} onClick={onDelete}>
          Migrate & Delete
        </button>
      </div>
    </div>
  )
}

const Row = ({
  category,
  targetMap,
  setTargetMap,
  potentialTargetCategories,
  categoryIdsWithLogs,
  padding = 3,
}: {
  category: EventCategory | SubEventCategory
  targetMap: {[key: string]: {category: EventCategory; subCategory?: SubEventCategory}}
  setTargetMap: React.Dispatch<
    SetStateAction<{[key: string]: {category: EventCategory; subCategory?: SubEventCategory}}>
  >
  potentialTargetCategories: EventCategory[]
  categoryIdsWithLogs: string[]
  padding?: number
}) => {
  const {collectAllCategoryObjects} = useCategorySelection()
  const {data: eventCategoryGroups, isLoading} = useEventCategoryGroups()

  const cateGoryHasLogs = categoryIdsWithLogs.includes(category._id!)
  const target = targetMap.hasOwnProperty(category._id!) ? targetMap[category._id!] : undefined
  const targetCategory = target?.category
  const targetSubCategory = target?.subCategory
  const potentialSubCategories =
    targetCategory !== undefined
      ? (collectAllCategoryObjects(targetCategory, false) as SubEventCategory[])
      : []

  return (
    <>
      {cateGoryHasLogs && (
        <div className='d-flex align-items-center row py-2 border-top border-secondary'>
          <div className={`col-4 ps-${padding}`}>{category.title}</div>
          <div className='col-1'>{`->`}</div>
          <div className='col-7'>
            <Select
              className='form-select-solid'
              placeholder='Select a category...'
              data-hide-search='true'
              name={`target-category-${category._id}`}
              value={
                targetCategory
                  ? {
                      value: targetCategory._id,
                      label: `${
                        eventCategoryGroups?.items.find((g) =>
                          targetCategory.event_category_groups.includes(g._id)
                        )?.title || '?'
                      } | ${targetCategory.title}`,
                    }
                  : undefined
              }
              options={potentialTargetCategories
                .filter((eventCategory) => eventCategory._id !== category._id)
                .map((eventCategory) => ({
                  value: eventCategory._id,
                  label: `${
                    eventCategoryGroups?.items.find((g) =>
                      eventCategory.event_category_groups.includes(g._id)
                    )?.title || '?'
                  } | ${eventCategory.title}`,
                }))}
              onChange={(newValue) => {
                setTargetMap((prev) => {
                  const newMap = {...prev}
                  if (newValue === null) {
                    delete newMap[category._id!]
                  } else {
                    newMap[category._id!] = {
                      category: potentialTargetCategories.find((c) => c._id === newValue?.value)!,
                    }
                  }
                  return newMap
                })
              }}
              isClearable
              isLoading={isLoading}
            />
            <Select
              className='form-select-solid mt-2'
              placeholder='Select a sub-category...'
              data-hide-search='true'
              name={`target-sub-category-${category._id}`}
              value={
                targetSubCategory
                  ? {value: targetSubCategory._id!, label: targetSubCategory?.title}
                  : undefined
              }
              options={potentialSubCategories.map((subCategory) => ({
                value: subCategory._id!,
                label: subCategory.title,
              }))}
              onChange={(newValue) => {
                setTargetMap((prev) => {
                  const newMap = {...prev}
                  if (newValue === null) {
                    delete newMap[category._id!].subCategory
                  } else {
                    newMap[category._id!] = {
                      category: targetCategory!,
                      subCategory: potentialSubCategories.find((c) => c._id === newValue.value)!,
                    }
                  }
                  return newMap
                })
              }}
              isClearable
              isDisabled={!targetCategory}
              isLoading={isLoading}
            />
          </div>
        </div>
      )}
      {category.sub_event_categories.map((subCategory) => (
        <Row
          key={subCategory._id!}
          category={subCategory}
          targetMap={targetMap}
          setTargetMap={setTargetMap}
          potentialTargetCategories={potentialTargetCategories}
          categoryIdsWithLogs={categoryIdsWithLogs}
          padding={padding + 2}
        />
      ))}
    </>
  )
}

const ChooseToMigrate = ({
  category,
  setChosenOption,
}: {
  category: EventCategory | SubEventCategory
  setChosenOption: (value: React.SetStateAction<'migrate' | 'delete' | null>) => void
}) => (
  <div className='modal-body mx-5 mx-xl-18 pt-0'>
    <h1 className='text-center mb-8'>{`Action Required: Migrating Event Logs from ${category.title}`}</h1>

    <div className='fw-semibold fs-6 mb-5'>
      {`The category "${category.title}" or some of its sub-categories have associated event logs. Choose how you would like to handle these logs before deleting the category`}
    </div>

    <div className='row'>
      <div className='col-6'>
        <label
          className={clsx(
            'btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center h-100'
          )}
          onClick={() => setChosenOption('migrate')}
        >
          <span className='me-5'>
            <i className='fa-solid fa-share' style={{fontSize: '2.5rem'}} />
          </span>

          <span className='d-block fw-semibold text-start'>
            <span className='text-dark fw-bold d-block fs-4 mb-2'>Migrate Event Logs</span>
            <span className='text-muted fw-semibold fs-6'>
              Choose to migrate existing logs to other categoris. By migrating, you ensure the
              continuity of historical data, which is crucial for trend analysis and operational
              insights
            </span>
          </span>
        </label>
      </div>
      <div className='col-6'>
        <label
          className={clsx(
            'btn btn-outline btn-active-light-danger p-7 d-flex align-items-center h-100'
          )}
          onClick={() => setChosenOption('delete')}
        >
          <span className='me-5'>
            <i className='fa-solid fa-trash' style={{fontSize: '2.5rem'}} />
          </span>

          <span className='d-block fw-semibold text-start'>
            <span className='text-dark fw-bold d-block fs-4 mb-2'>Permanently Delete Logs</span>
            <span className='text-muted fw-semibold fs-6'>
              {`Opting to delete will permanently remove all event logs associated with "${category.title}" and any of its sub-categories. This action is final and irreversible, leading to a complete and permanent loss of historical data`}
            </span>
          </span>
        </label>
      </div>
    </div>
  </div>
)

const ConfirmDeletionWithLogs = ({
  category,
  handleDelete,
  close,
}: {
  category: EventCategory | SubEventCategory
  handleDelete: (props: DeleteEventCategoryProps) => Promise<void>
  close: () => void
}) => (
  <div className='modal-body mx-5 mx-xl-18 pt-0'>
    <h1 className='text-center mb-8'>Confirm Permanent Deletion</h1>

    <div className='notice bg-light-danger rounded border-danger border border-dashed p-6 mb-3'>
      <div className='d-flex'>
        <KTSVG
          className='svg-icon-2tx svg-icon-danger me-4'
          path='/media/icons/duotune/general/gen044.svg'
        />
        <div className='d-flex flex-row flex-stack flex-grow-1'>
          <div className='fw-semibold'>
            <h4 className='text-gray-900 fw-bold'>
              {`You are about to permanently delete all event logs associated with "${category.title}" and its sub-categories. This action is irreversible and will result in the loss of historical data`}
            </h4>

            <div className='mt-5 fw-bold'>Please confirm your understanding that:</div>
            <ul className='list list-check fw-bold'>
              <li>
                <span className='text-danger'>{`All event logs within "${category.title}" and its sub-categories will be permanently removed.`}</span>
              </li>
              <li>
                <span className='text-danger'>
                  This action cannot be undone, and the lost data cannot be recovered.
                </span>
              </li>
            </ul>

            <div className='fw-bold text-gray-800 mt-5'>
              If you have not already, consider migrating event logs to other categories to preserve
              historical data.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='d-flex flex-center pt-5'>
      <button className='btn btn-light me-3' onClick={close}>
        Cancel
      </button>
      <button className='btn btn-danger' onClick={() => handleDelete({id: category._id!})}>
        Confirm Deletion
      </button>
    </div>
  </div>
)

const ConfirmDeletionNoLogs = ({
  category,
  handleDelete,
  close,
}: {
  category: EventCategory | SubEventCategory
  handleDelete: (props: DeleteEventCategoryProps) => Promise<void>
  close: () => void
}) => (
  <div className='modal-body mx-5 mx-xl-18 pt-0'>
    <h1 className='text-center mb-8'>Delete Category?</h1>

    <div className='notice bg-light-danger rounded border-danger border border-dashed p-6 mb-3'>
      <div className='d-flex'>
        <KTSVG
          className='svg-icon-2tx svg-icon-danger me-4'
          path='/media/icons/duotune/general/gen044.svg'
        />
        <div className='d-flex flex-row flex-stack flex-grow-1'>
          <div className='fw-semibold'>
            <h4 className='text-gray-900 fw-bold'>
              {`Are you sure you want to delete the category "${category.title}"? There are no event logs associated with this category, so no historical data will be lost`}
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div className='d-flex flex-center pt-5'>
      <button className='btn btn-light me-3' onClick={close}>
        Cancel
      </button>
      <button className='btn btn-danger' onClick={() => handleDelete({id: category._id!})}>
        Confirm Deletion
      </button>
    </div>
  </div>
)

export default EventCategoryDeleteModal
