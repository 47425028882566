import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {AnomalyGraph} from '../../components/charts/AnomalyGraph'
import {WidgetProps} from './Widget'

const AnomalyWidget = ({widget, height}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time
  return (
    <AnomalyGraph
      assetId={widget.entity}
      height={height}
      startTime={startTime}
      endTime={endTime}
      chartId={widget._id}
    />
  )
}

export default AnomalyWidget
