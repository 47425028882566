import {useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {MenuComponent, ToggleComponent} from '../../../_metronic/assets/ts/components'
import NumberField from '../../components/form-components/NumberField'
import LoadingWrapper from '../../components/LoadingWrapper'
import {BatchTemplate, ICreateBatch} from '../../core/_models'
import {useProfile} from '../../core/requests/auth'
import {useProductionLines} from '../../core/requests/factory'
import {createWorkOrder} from '../../core/requests/maintenance'
import {
  createBatch,
  deleteBatchTemplate,
  useBatchTemplateRates,
  useBatchTemplates,
  useProducts,
} from '../../core/requests/oee'
import CreatePlannerSetupModal, {
  CreateSetupProps,
} from '../../modals/create-planner-setup/CreatePlannerSetupModal'
import {useCreateModals} from '../../modals/CreateModalsProvider'
import CreateBatchTemplateModal from '../batch-templates/CreateTemplateModal'
import {IWorkOrder} from '../work-orders/modal/_models'
import {ConfirmDeletionModal} from '../overview/core/columns/TableSelectionToolbar'
import {ProductRate, RatesMap} from './_models'
import PlannerGraph, {IBatchData} from './PlannerGraph'
import RatesModal from './RatesModal'

export const PlannerWrapper = () => {
  const queryClient = useQueryClient()
  const {data: profile} = useProfile()
  const {data: templates} = useBatchTemplates()
  const {data: productionLines} = useProductionLines({manufacturingType: 'discrete'})

  const [selectedTemplate, setSelectedTemplate] = useState<BatchTemplate | null>(null)
  const [addedBatch, setAddedBatch] = useState<IBatchData | null>(null)

  const {showPlannerSetupModal, closePlannerSetupModal} = useCreateModals()
  const [activeSetup, setActiveSetup] = useState<CreateSetupProps | null>(null)
  const [addedSetup, setAddedSetup] = useState<CreateSetupProps | null>(null)

  const {data: rates, isLoading: ratesLoading} = useBatchTemplateRates({
    batchTemplate: selectedTemplate?._id,
  })

  const {data: products} = useProducts({
    organization: profile?.organization?._id,
    options: {enabled: !!profile?.organization?._id},
  })

  const discreteProductIds: string[] = []
  if (products !== undefined && productionLines !== undefined) {
    const productionLineIds = new Set(productionLines.items.map((line) => line._id))
    discreteProductIds.push(
      ...products.items
        .filter((product) =>
          product.compatible_production_lines.some((line) => productionLineIds.has(line))
        )
        .map((product) => product._id)
    )
  }

  const productionLinesOfSelectedTemplate =
    productionLines?.items.filter(
      (line) =>
        products?.items
          ?.find((product) => product._id === selectedTemplate?.product)
          ?.compatible_production_lines.includes(line._id) || false
    ) || []

  const [newRates, setNewRates] = useState<RatesMap>({})
  const [showRatesModal, setShowRatesModal] = useState<boolean>(false)

  const [submitting, setSubmitting] = useState<boolean>(false)

  const [showEditTemplateModal, setShowEditTemplateModal] = useState<boolean>(false)
  const [editingTemplate, setEditingTemplate] = useState<BatchTemplate | null>(null)

  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState<boolean>(false)
  const [deletingTemplate, setDeletingTemplate] = useState<BatchTemplate | null>(null)

  function addnewRates(rates: ProductRate[]) {
    const newRatesMap = {...newRates}
    newRatesMap[selectedTemplate?._id as string] = rates
    setNewRates(newRatesMap)
  }

  const updateSelectedTemplate = (fieldsToUpdate: Partial<BatchTemplate>) => {
    const fields = fieldsToUpdate as BatchTemplate
    const updatedTemplate = {...selectedTemplate, ...fields}
    setSelectedTemplate(updatedTemplate)
  }

  const cancelAddNewRates = () => {
    setSelectedTemplate(null)
    setShowRatesModal(false)
  }

  const cancelAddNewBatch = () => {
    setAddedBatch(null)
    setSelectedTemplate(null)
  }

  const cancelAddSetup = () => {
    setAddedSetup(null)
    setActiveSetup(null)
  }

  const handleTemplateClick = (template: BatchTemplate) => () => {
    if (selectedTemplate?._id === template._id) {
      setSelectedTemplate(null)
    } else {
      setSelectedTemplate(template)
      setShowRatesModal(true)
    }
  }

  const handleEditTemplateClick = (template: BatchTemplate) => () => {
    setEditingTemplate(template)
    setShowEditTemplateModal(true)
  }

  const handleDeleteTemplateClick = (template: BatchTemplate) => () => {
    setDeletingTemplate(template)
    setShowDeleteTemplateModal(true)
  }

  async function saveNewBatch() {
    setSubmitting(true)
    try {
      const batchData: ICreateBatch = {
        create_from_template: selectedTemplate?._id!,
        production_line: addedBatch?.productionLineId!,
        planned_start_time: new Date(addedBatch?.start!).toISOString(),
        theoretical_product_rate: newRates[selectedTemplate?._id!][0].product_rate,
        total_product_quantity: selectedTemplate?.total_product_quantity,
      }
      await createBatch(batchData)
      setAddedBatch(null)
      setSelectedTemplate(null)
    } catch (e) {
      console.log(e)
    }

    setSubmitting(false)
    queryClient.invalidateQueries('batches')
  }

  async function saveNewSetup() {
    setSubmitting(true)
    try {
      const setupData: IWorkOrder = {
        planned_start_time: addedSetup!.startTime! / 1000,
        // organization: profile!.organization!._id,
        expected_duration: addedSetup?.duration,
        title: 'Setup',
        factory_entity: addedSetup?.productionLineId,
        actions: addedSetup?.actions,
        assignees: addedSetup?.assignees,
        category: 'Setup',
        is_editing: false,
      }
      await createWorkOrder(setupData)
      setAddedSetup(null)
      setActiveSetup(null)
    } catch (e) {
      console.log(e)
    }

    setSubmitting(false)
    await queryClient.invalidateQueries('workOrders')
  }

  async function deleteTemplate() {
    await deleteBatchTemplate(deletingTemplate?._id!)
    setShowDeleteTemplateModal(false)
    await queryClient.invalidateQueries('batchTemplates')
  }

  useEffect(() => {
    // Make sure the menu is reinitialized when the templates change
    MenuComponent.reinitialization()
  }, [templates])

  // @todo find a good solution, the problem: after we reload page,
  // planners submenu doesn't work, setTimeout fixes this issue
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      ToggleComponent.reinitialization()
    }, 1000)
  }, [])

  return (
    <>
      <LoadingWrapper
        loading={templates === undefined || productionLines === undefined || products === undefined}
      >
        {productionLines && productionLines.total === 0 && (
          <h2>No discrete production lines found...</h2>
        )}
        {productionLines && productionLines.total > 0 && (
          <div className='card card-flush overflow-hidden h-lg-100'>
            <div className='card-header border-0 pt-6'>
              <div className='flex-row'>
                <div className='d-flex'>
                  {templates?.items?.map((template, index) => (
                    <button
                      type='button'
                      className={`btn btn-primary p-0 ${
                        index !== templates.items.length - 1 && 'me-3'
                      } ${template._id === selectedTemplate?._id && 'active'}`}
                      disabled={addedBatch !== null || activeSetup !== null}
                      key={template._id}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='me-2 p-4' onClick={handleTemplateClick(template)}>
                          {template.name}
                        </div>
                        <div
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom'
                          className='btn btn-icon btn-color-gray-400 btn-active-color-primary me-1'
                        >
                          <i className='fa-solid fs-1 fa-ellipsis' style={{color: '#ffffff'}} />
                        </div>
                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-100px'
                          data-kt-menu='true'
                        >
                          <div
                            className='menu-item px-3 mt-2'
                            onClick={handleEditTemplateClick(template)}
                          >
                            <a href='#' className='menu-link px-3'>
                              <span className='menu-title'>Edit</span>
                            </a>
                          </div>
                          <div
                            className='menu-item px-3 mb-2'
                            onClick={handleDeleteTemplateClick(template)}
                          >
                            <a href='#' className='menu-link px-3'>
                              <span className='menu-title'>Delete</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>

                {/* Quantity input */}
                {selectedTemplate && discreteProductIds.includes(selectedTemplate.product) && (
                  <div className='mt-5'>
                    <NumberField
                      name='Product Quantity'
                      data={selectedTemplate}
                      updateData={updateSelectedTemplate}
                      dataField='total_product_quantity'
                      isInteger
                    />
                  </div>
                )}
              </div>
            </div>

            <div className='card-body p-0'>
              <PlannerGraph
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                rates={selectedTemplate ? newRates[selectedTemplate._id] : null}
                addedBatch={addedBatch}
                setAddedBatch={setAddedBatch}
                activeSetup={activeSetup}
                setActiveSetup={setActiveSetup}
                addedSetup={addedSetup}
                setAddedSetup={setAddedSetup}
              />
            </div>

            {addedBatch && (
              <div className='card-footer d-flex justify-content-end'>
                <LoadingWrapper loading={submitting}>
                  <button
                    type='button'
                    className='btn btn-secondary me-3'
                    onClick={cancelAddNewBatch}
                  >
                    <i className='fa-solid fa-xmark me-1'></i>
                    Cancel
                  </button>
                  <button type='button' className='btn btn-primary' onClick={saveNewBatch}>
                    <i className='fa-solid fa-floppy-disk me-1'></i>
                    Save Batch
                  </button>
                </LoadingWrapper>
              </div>
            )}

            {addedSetup && (
              <div className='card-footer d-flex justify-content-end'>
                <LoadingWrapper loading={submitting}>
                  <button type='button' className='btn btn-secondary me-3' onClick={cancelAddSetup}>
                    <i className='fa-solid fa-xmark me-1'></i>
                    Cancel
                  </button>
                  <button type='button' className='btn btn-primary' onClick={saveNewSetup}>
                    <i className='fa-solid fa-floppy-disk me-1'></i>
                    Save Setup
                  </button>
                </LoadingWrapper>
              </div>
            )}
          </div>
        )}
      </LoadingWrapper>

      <RatesModal
        show={showRatesModal}
        hide={() => setShowRatesModal(false)}
        rates={rates}
        productionLines={productionLinesOfSelectedTemplate}
        addnewRates={addnewRates}
        loading={ratesLoading}
        cancel={cancelAddNewRates}
      />

      <CreateBatchTemplateModal
        show={showEditTemplateModal}
        hide={() => setShowEditTemplateModal(false)}
        edit={editingTemplate ?? undefined}
      />

      <ConfirmDeletionModal
        show={showDeleteTemplateModal}
        hide={() => setShowDeleteTemplateModal(false)}
        deleteSelections={deleteTemplate}
      />

      <CreatePlannerSetupModal
        show={showPlannerSetupModal}
        handleClose={closePlannerSetupModal}
        onSubmit={(setup) => {
          if (addedBatch === null && selectedTemplate === null) {
            setActiveSetup(setup)
          }
        }}
      />
    </>
  )
}
