import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import PageNavigationBar, {Breadcrumb} from '../../../components/PageNavigationBar'
import {SelectedRowsProvider} from '../../../providers/SelectedRowsProvider'
import OverviewModeSwitch from '../core/components/overview/OverviewModeSwitch'
import {PlacementsWrapper} from './PlacementsWrapper'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Placements',
    path: '/placements/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PlacementsPage = () => (
  <Routes>
    <Route
      path='overview/grid?'
      element={
        <SelectedRowsProvider>
          <PageNavigationBar title={`Overview`} breadcrumbs={breadcrumbs}>
            <OverviewModeSwitch switchUrl={`/placements/overview`} />
          </PageNavigationBar>
          <PlacementsWrapper />
        </SelectedRowsProvider>
      }
    />
    <Route index element={<Navigate to='/placements/overview' />} />
  </Routes>
)

export default PlacementsPage
