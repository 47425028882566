import useGetStandbyConsumption from '../../../../core/graphql/energy/queries/GetStandbyConsumption'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import useProductProcessedDivider from './useProductProcessedDivider'
import QuantityWidget from './utility-widgets/QuantityWidget'
import {WidgetProps} from './Widget'

const StandbyConsumptionWidget = ({widget}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const divider = useProductProcessedDivider({widget})

  const {consumption} = useGetStandbyConsumption({
    entityGroup: widget.entity_group,
    id: widget.entity,
    startTime,
    endTime,
  })

  const energy = !!consumption?.energy && divider ? (consumption?.energy / divider).toFixed(2) : 0

  return <QuantityWidget value={energy} unit='kWh' />
}

export default StandbyConsumptionWidget
