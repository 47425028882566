import {useState} from 'react'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {Area} from '../../core/_models'
import {useCreateModals} from '../CreateModalsProvider'
import Step1 from './Step1'
import {defaultCreateArea, ICreateArea} from './_models'
import {createArea} from '../../core/requests/factory'

const CreateAreaModal = () => {
  const [data, setData] = useState<ICreateArea>(defaultCreateArea)
  const {
    showCreateAreaModal,
    closeCreateAreaModal,
    openCreateProductionLineModal,
    openCreatePlacementModalFromArea,
    openCreateAssetModalFromArea,
  } = useCreateModals()

  const steps: Step<ICreateArea>[] = [
    {
      title: 'Name',
      description: 'Name Your Area',
      component: Step1,
      validation: (data) => data.name === undefined || data.factory === undefined,
    },
  ]

  return (
    <StepModalWrapper<ICreateArea, Area>
      id='hs_create_area'
      objectName='Area'
      show={showCreateAreaModal}
      close={closeCreateAreaModal}
      steps={steps}
      data={data}
      defaultData={defaultCreateArea}
      setData={setData}
      submitData={createArea}
      openNextModals={[
        openCreateProductionLineModal,
        openCreatePlacementModalFromArea,
        openCreateAssetModalFromArea,
      ]}
      nextObjectNames={['Production Line', 'Placement', 'Asset']}
    />
  )
}

export default CreateAreaModal
