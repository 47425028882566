import SelectFieldBoxesMultiple from '../../../../../../components/form-components/SelectFieldBoxesMultiple'
import {WidgetDataType} from '../../../widgets/_models'
import {StepProps} from '../_models'

export const oeeWidgetDataTypes: {[key: string]: WidgetDataType[]} = {
  OEE: ['OEE'],
  'OEE Availability': ['OEE Availability'],
  'OEE Performance': ['OEE Performance'],
  'OEE Quality': ['OEE Quality'],
  Pareto: ['Stop Causes'],
  Timeline: ['Operational Events', 'Product Rate'],
  '': [],
}

const DataTypesSelectionStep = ({data, updateData, hasError}: StepProps) => {
  const dataTypeOptions = oeeWidgetDataTypes[data.type || '']

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxesMultiple
          name='Data Types'
          optionNames={dataTypeOptions || []}
          optionValues={dataTypeOptions || []}
          data={data}
          updateData={updateData}
          dataField='data_types'
          required
          hasError={hasError}
          colSpace={12}
          lastField
        />
      </div>
    </div>
  )
}

export default DataTypesSelectionStep
