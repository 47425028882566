import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import PageNavigationBar, {Breadcrumb} from '../../../components/PageNavigationBar'
import {SelectedRowsProvider} from '../../../providers/SelectedRowsProvider'
import OverviewModeSwitch from '../core/components/overview/OverviewModeSwitch'
import {SensorsWrapper} from './SensorsWrapper'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Sensors',
    path: '/sensors/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SensorsPage = () => (
  <Routes>
    <Route
      path='overview/grid?'
      element={
        <SelectedRowsProvider>
          <PageNavigationBar title={`Overview`} breadcrumbs={breadcrumbs}>
            <OverviewModeSwitch switchUrl={`/sensors/overview`} />
          </PageNavigationBar>
          <SensorsWrapper />
        </SelectedRowsProvider>
      }
    />
    <Route index element={<Navigate to='/sensors/overview' />} />
  </Routes>
)

export default SensorsPage
