import LocationField from '../../components/form-components/LocationField'
import SelectField from '../../components/form-components/SelectField'
import {COUNTRIES, Region, REGIONS, StepProps} from './_models'

const Step2 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <LocationField
          name='Location'
          data={data}
          updateData={updateData}
          dataField='location'
          lastField
        />
      </div>
    </div>
  )
}

export default Step2
