import React from 'react'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {manufacturersMap, Sensor} from '../../core/_models'
import {useCreateModals} from '../CreateModalsProvider'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import {defaultCreateSensor, ICreateSensor} from './_models'
import {createSensor} from '../../core/requests/factory'

const CreateSensorModal = () => {
  const [data, setData] = React.useState<ICreateSensor>(defaultCreateSensor)
  const {showCreateSensorModal, closeCreateSensorModal} = useCreateModals()

  const steps: Step<ICreateSensor>[] = [
    {
      title: 'Name',
      description: 'Name Your Sensor',
      component: Step1,
      validation: (data) => !data.device_id || !data.placement,
    },
    {
      title: 'Type',
      description: 'Specify Sensor Type',
      component: Step2,
      validation: (data) =>
        !data.manufacturer ||
        !data.data_types ||
        (data.manufacturer !== manufacturersMap.OTHER && !data.model),
      skipTo: [
        {
          step: 3,
          condition: (data) => data.manufacturer !== manufacturersMap.TREON,
          action: (data) =>
            setData({
              ...data,
              mountingChoice: 'manual',
              is_vertical: undefined,
              x: undefined,
              y: undefined,
              z: undefined,
            }),
        },
      ],
      done: (data) => {
        return (
          data.data_types !== undefined &&
          data.data_types.length > 0 &&
          !data.data_types.includes('vibration')
        )
      },
    },
    {
      title: 'Mounting',
      description: 'Specify Sensor Mounting',
      component: Step3,
      validation: (data) => !data.mountingChoice,
    },
    {
      title: 'Axes',
      description: 'Specify Sensor Axes',
      component: Step4,
      validation: (data) =>
        (data.mountingChoice === 'dot-based' && data.is_vertical === undefined) ||
        (data.mountingChoice === 'manual' && (!data.x || !data.y || !data.z)),
    },
    // {
    //   title: 'Specifics',
    //   description: 'Specify Sensor Specifics',
    //   component: Step5,
    // },
  ]

  return (
    <StepModalWrapper<ICreateSensor, Sensor>
      id='hs_create_section'
      objectName='Sensor'
      show={showCreateSensorModal}
      close={closeCreateSensorModal}
      steps={steps}
      data={data}
      defaultData={defaultCreateSensor}
      setData={setData}
      submitData={createSensor}
    />
  )
}

export default CreateSensorModal
