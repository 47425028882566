import React from 'react'
import PageNavigationBar, {Breadcrumb} from '../../components/PageNavigationBar'
import StatesPageContent from './states/StatesPageContent'

const StatesPage = () => {
  const breadcrumbs: Breadcrumb[] = [
    {
      title: 'Staff',
      path: '/staff/states',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageNavigationBar title={`States`} breadcrumbs={breadcrumbs} />
      <StatesPageContent />
    </>
  )
}

export default StatesPage
