import {createContext, useContext, useState} from 'react'
import {useQueryClient} from 'react-query'
import {Area, Asset, Factory, Part, Placement, ProductionLine, Section} from '../core/_models'

export type OpenCreateSensorModalProps = {placement?: Placement; asset?: Asset}

type CreateModalsContextType = {
  showCreateFactoryModal: boolean
  showCreateAreaModal: boolean
  showCreateProductionLineModal: boolean
  showCreateSectionModal: boolean
  showCreateAssetModal: boolean
  showCreatePartModal: boolean
  showCreatePlacementModal: boolean
  showCreateSensorModal: boolean
  showCreateGatewayModal: boolean
  showCreatePlcModal: boolean
  showCreateDashboardModal: boolean
  showEntityDetailModal: boolean
  showPlannerSetupModal: boolean

  openCreateFactoryModal: () => void
  closeCreateFactoryModal: () => void

  openCreateAreaModal: (factory?: Factory) => void
  initialFactory?: Factory
  closeCreateAreaModal: () => void

  openCreateProductionLineModal: (area?: Area) => void
  initialArea?: Area
  closeCreateProductionLineModal: () => void

  openCreateSectionModal: (productionLine?: ProductionLine) => void
  initialProductionLine?: ProductionLine
  closeCreateSectionModal: () => void

  openCreateAssetModal: (section?: Section) => void
  openCreateAssetModalFromArea: (area?: Area) => void
  initialSection?: Section
  closeCreateAssetModal: () => void

  openCreatePartModal: (asset?: Asset) => void
  initialAsset?: Asset
  closeCreatePartModal: () => void

  openCreatePlacementModal: (part?: Part) => void
  openCreatePlacementModalFromProductionLine: (productionLine?: ProductionLine) => void
  openCreatePlacementModalFromArea: (area?: Area) => void
  initialPart?: Part
  closeCreatePlacementModal: () => void

  openCreateSensorModal: (props?: OpenCreateSensorModalProps) => void
  initialPlacement?: Placement
  closeCreateSensorModal: () => void

  openCreateGatewayModal: () => void
  closeCreateGatewayModal: () => void

  openCreatePlcModal: () => void
  closeCreatePlcModal: () => void

  openEntityDetailModal: (fields: {name: string; value: string}[]) => void
  detailEntity: {name: string; value: string}[]
  closeEntityDetailModal: () => void

  openPlannerSetupModal: () => void
  closePlannerSetupModal: () => void
}

const CreateModalsContext = createContext<CreateModalsContextType>({
  showCreateFactoryModal: false,
  showCreateAreaModal: false,
  showCreateProductionLineModal: false,
  showCreateSectionModal: false,
  showCreateAssetModal: false,
  showCreatePartModal: false,
  showCreatePlacementModal: false,
  showCreateSensorModal: false,
  showCreateGatewayModal: false,
  showCreatePlcModal: false,
  showCreateDashboardModal: false,
  showEntityDetailModal: false,
  showPlannerSetupModal: false,

  openCreateFactoryModal: () => {},
  closeCreateFactoryModal: () => {},

  openCreateAreaModal: (factory?: Factory) => {},
  initialFactory: undefined,
  closeCreateAreaModal: () => {},

  openCreateProductionLineModal: (area?: Area) => {},
  initialArea: undefined,
  closeCreateProductionLineModal: () => {},

  openCreateSectionModal: (productionLine?: ProductionLine) => {},
  initialProductionLine: undefined,
  closeCreateSectionModal: () => {},

  openCreateAssetModal: (section?: Section) => {},
  openCreateAssetModalFromArea: (area?: Area) => {},
  initialSection: undefined,
  closeCreateAssetModal: () => {},

  openCreatePartModal: (asset?: Asset) => {},
  initialAsset: undefined,
  closeCreatePartModal: () => {},

  openCreatePlacementModal: (part?: Part) => {},
  openCreatePlacementModalFromProductionLine: (productionLine?: ProductionLine) => {},
  openCreatePlacementModalFromArea: (area?: Area) => {},
  initialPart: undefined,
  closeCreatePlacementModal: () => {},

  openCreateSensorModal: (props?: OpenCreateSensorModalProps) => {},
  initialPlacement: undefined,
  closeCreateSensorModal: () => {},

  openCreateGatewayModal: () => {},
  closeCreateGatewayModal: () => {},

  openCreatePlcModal: () => {},
  closeCreatePlcModal: () => {},

  openEntityDetailModal: (fields: {name: string; value: string}[]) => {},
  detailEntity: [],
  closeEntityDetailModal: () => {},

  openPlannerSetupModal: () => {},
  closePlannerSetupModal: () => {},
})

const useCreateModals = () => useContext(CreateModalsContext)

const CreateModalsProvider = ({children}: {children: React.ReactNode}) => {
  const queryClient = useQueryClient()

  const [showCreateFactoryModal, setShowCreateFactoryModal] = useState(false)
  const [showCreateAreaModal, setShowCreateAreaModal] = useState(false)
  const [showCreateProductionLineModal, setShowCreateProductionLineModal] = useState(false)
  const [showCreateSectionModal, setShowCreateSectionModal] = useState(false)
  const [showCreateAssetModal, setShowCreateAssetModal] = useState(false)
  const [showCreatePartModal, setShowCreatePartModal] = useState(false)
  const [showCreatePlacementModal, setShowCreatePlacementModal] = useState(false)
  const [showCreateSensorModal, setShowCreateSensorModal] = useState(false)
  const [showCreateGatewayModal, setShowCreateGatewayModal] = useState(false)
  const [showCreatePlcModal, setShowCreatePlcModal] = useState(false)
  const [showCreateDashboardModal, setShowCreateDashboardModal] = useState(false)
  const [showEntityDetailModal, setShowEntityDetailModal] = useState(false)
  const [showPlannerSetupModal, setShowPlannerSetupModal] = useState(false)

  const [initialFactory, setInitialFactory] = useState<Factory>()
  const [initialArea, setInitialArea] = useState<Area>()
  const [initialProductionLine, setInitialProductionLine] = useState<ProductionLine>()
  const [initialSection, setInitialSection] = useState<Section>()
  const [initialAsset, setInitialAsset] = useState<Asset>()
  const [initialPart, setInitialPart] = useState<Part>()
  const [initialPlacement, setInitialPlacement] = useState<Placement>()

  const [detailEntity, setDetailEntity] = useState<{name: string; value: string}[]>([])

  const resetInitials = () => {
    setInitialFactory(undefined)
    setInitialArea(undefined)
    setInitialProductionLine(undefined)
    setInitialSection(undefined)
    setInitialAsset(undefined)
    setInitialPart(undefined)
    setInitialPlacement(undefined)
  }

  const openCreateFactoryModal = () => setShowCreateFactoryModal(true)
  const closeCreateFactoryModal = () => setShowCreateFactoryModal(false)

  const openCreateAreaModal = (factory?: Factory) => {
    setShowCreateAreaModal(true)
    resetInitials()
    setInitialFactory(factory)
  }
  const openCreateAssetModalFromArea = (area?: Area) => {
    setShowCreateAssetModal(true)
    resetInitials()
    setInitialArea(area)
  }
  const closeCreateAreaModal = () => {
    setShowCreateAreaModal(false)
    resetInitials()
    queryClient.invalidateQueries('areas')
  }

  const openCreateProductionLineModal = (area?: Area) => {
    setShowCreateProductionLineModal(true)
    resetInitials()
    setInitialArea(area)
  }
  const closeCreateProductionLineModal = () => {
    setShowCreateProductionLineModal(false)
    resetInitials()
    queryClient.invalidateQueries('productionLines')
  }

  const openCreateSectionModal = (productionLine?: ProductionLine) => {
    setShowCreateSectionModal(true)
    resetInitials()
    setInitialProductionLine(productionLine)
  }
  const closeCreateSectionModal = () => {
    setShowCreateSectionModal(false)
    resetInitials()
    queryClient.invalidateQueries('sections')
  }

  const openCreateAssetModal = (section?: Section) => {
    setShowCreateAssetModal(true)
    resetInitials()
    setInitialSection(section)
  }
  const closeCreateAssetModal = () => {
    setShowCreateAssetModal(false)
    resetInitials()
    queryClient.invalidateQueries('assets')
  }

  const openCreatePartModal = (asset?: Asset) => {
    resetInitials()
    setInitialAsset(asset)
    setShowCreatePartModal(true)
  }
  const closeCreatePartModal = () => {
    setShowCreatePartModal(false)
    resetInitials()
    queryClient.invalidateQueries('parts')
  }

  const openCreatePlacementModal = (part?: Part) => {
    setShowCreatePlacementModal(true)
    resetInitials()
    setInitialPart(part)
  }
  const openCreatePlacementModalFromProductionLine = (productionLine?: ProductionLine) => {
    setShowCreatePlacementModal(true)
    resetInitials()
    setInitialProductionLine(productionLine)
  }
  const openCreatePlacementModalFromArea = (area?: Area) => {
    setShowCreatePlacementModal(true)
    resetInitials()
    setInitialArea(area)
  }
  const closeCreatePlacementModal = () => {
    setShowCreatePlacementModal(false)
    resetInitials()
    queryClient.invalidateQueries('placements')
  }

  const openCreateSensorModal = (props?: OpenCreateSensorModalProps) => {
    setShowCreateSensorModal(true)
    resetInitials()
    setInitialPlacement(props?.placement)
    setInitialAsset(props?.asset)
  }
  const closeCreateSensorModal = () => {
    setShowCreateSensorModal(false)
    resetInitials()
    queryClient.invalidateQueries('sensors')
  }

  const openCreateGatewayModal = () => setShowCreateGatewayModal(true)
  const closeCreateGatewayModal = () => {
    setShowCreateGatewayModal(false)
    resetInitials()
    queryClient.invalidateQueries('gateways')
  }

  const openCreatePlcModal = () => setShowCreatePlcModal(true)
  const closeCreatePlcModal = () => {
    setShowCreatePlcModal(false)
    resetInitials()
    queryClient.invalidateQueries('plcs')
  }

  const openEntityDetailModal = (fields: {name: string; value: string}[]) => {
    setShowEntityDetailModal(true)
    setDetailEntity(fields)
  }
  const closeEntityDetailModal = () => {
    setShowEntityDetailModal(false)
    setDetailEntity([])
  }

  const openPlannerSetupModal = () => setShowPlannerSetupModal(true)
  const closePlannerSetupModal = () => setShowPlannerSetupModal(false)

  return (
    <CreateModalsContext.Provider
      value={{
        showCreateFactoryModal,
        showCreateAreaModal,
        showCreateProductionLineModal,
        showCreateSectionModal,
        showCreateAssetModal,
        showCreatePartModal,
        showCreatePlacementModal,
        showCreateSensorModal,
        showCreateGatewayModal,
        showCreatePlcModal,
        showCreateDashboardModal,
        showEntityDetailModal,
        showPlannerSetupModal,
        openCreateFactoryModal,
        closeCreateFactoryModal,
        openCreateAreaModal,
        openCreateAssetModalFromArea,
        initialFactory,
        closeCreateAreaModal,
        openCreateProductionLineModal,
        initialArea,
        closeCreateProductionLineModal,
        openCreateSectionModal,
        initialProductionLine,
        closeCreateSectionModal,
        openCreateAssetModal,
        initialSection,
        closeCreateAssetModal,
        openCreatePartModal,
        initialAsset,
        closeCreatePartModal,
        openCreatePlacementModal,
        openCreatePlacementModalFromProductionLine,
        openCreatePlacementModalFromArea,
        initialPart,
        closeCreatePlacementModal,
        openCreateSensorModal,
        initialPlacement,
        closeCreateSensorModal,
        openCreateGatewayModal,
        closeCreateGatewayModal,
        openCreatePlcModal,
        closeCreatePlcModal,
        openEntityDetailModal,
        detailEntity,
        closeEntityDetailModal,
        openPlannerSetupModal,
        closePlannerSetupModal,
      }}
    >
      {children}
    </CreateModalsContext.Provider>
  )
}

export {CreateModalsProvider, useCreateModals}
