import {defaultCreateEnergyPiechartWidget, IEnergyPiechartWidget} from './_models'
import {useDashboardGrid} from '../../../../../providers/DashboardGridProvider'
import {useQueryClient} from 'react-query'
import StepModalWrapper, {Step} from '../../../../../components/StepModalWrapper'
import {createWidget, updateWidget} from '../../../../../core/requests/template'
import {BaseModel, Widget} from '../../../../../core/_models'
import CategoriesStep from './steps/CategoriesStep'
import NameStep from './steps/NameStep'
import TimeRangeStep from './steps/TimeRangeStep'
import RangesStep from './steps/RangesStep'
import AreaFilterStep from './steps/AreaFilterStep'
import React from 'react'

interface Props {
  show: boolean
  hide: () => void
  widgetData?: Widget & BaseModel
}

const EnergyPiechartWidgetModal = ({show, hide, widgetData}: Props) => {
  const queryClient = useQueryClient()
  const {activeDashboardId} = useDashboardGrid()
  const [data, setData] = React.useState<IEnergyPiechartWidget>({
    ...defaultCreateEnergyPiechartWidget,
    ...widgetData,
    running_minimum:
      widgetData?.operational_state_intervals && widgetData?.operational_state_intervals[0]
        ? widgetData?.operational_state_intervals[0][0]
        : undefined,
    running_maximum:
      widgetData?.operational_state_intervals && widgetData?.operational_state_intervals[0]
        ? widgetData?.operational_state_intervals[0][1]
        : undefined,
    standby_minimum:
      widgetData?.operational_state_intervals && widgetData?.operational_state_intervals[1]
        ? widgetData?.operational_state_intervals[1][0]
        : undefined,
    standby_maximum:
      widgetData?.operational_state_intervals && widgetData?.operational_state_intervals[1]
        ? widgetData?.operational_state_intervals[1][1]
        : undefined,
    shutdown_minimum:
      widgetData?.operational_state_intervals && widgetData?.operational_state_intervals[2]
        ? widgetData?.operational_state_intervals[2][0]
        : undefined,
    shutdown_maximum:
      widgetData?.operational_state_intervals && widgetData?.operational_state_intervals[2]
        ? widgetData?.operational_state_intervals[2][1]
        : undefined,
  })

  React.useEffect(() => {
    if (!data.name) {
      setData({
        ...data,
        name: 'Energy Piechart',
      })
    }
  }, [data])

  const steps: Step<IEnergyPiechartWidget>[] = [
    {
      title: 'Categories',
      description: 'Configure Categories',
      component: CategoriesStep,
    },
    {
      title: 'Area Filter',
      description: 'Select Area Filter',
      component: AreaFilterStep,
      validation: (data) => !data.entity,
    },
    {
      title: 'Define Ranges',
      description: 'Set ranges for the master',
      component: RangesStep,
      validation: (data) => !data.running_minimum || !data.standby_minimum,
    },
    {
      title: 'Time Frame Settings',
      description: 'Configure Time Frame Settings',
      component: TimeRangeStep,
      validation: (data) => (!!data.start_time || !!data.end_time) && !!data.dynamic_time_range,
    },
    {
      title: 'Name',
      description: 'Configure Name',
      component: NameStep,
      validation: (data) => data.name === undefined,
    },
  ]

  const submit = async (data: IEnergyPiechartWidget) => {
    data.dashboard = activeDashboardId!
    data.operational_state_intervals = [
      [data.running_minimum || 0, data.running_maximum || null],
      [data.standby_minimum || 0, data.standby_maximum || null],
      [data.shutdown_minimum || 0, data.shutdown_maximum || null],
    ]
    delete data.running_minimum
    delete data.running_maximum
    delete data.standby_minimum
    delete data.standby_maximum
    delete data.shutdown_minimum
    delete data.shutdown_maximum
    const widget = data._id ? await updateWidget(data._id, data) : await createWidget(data)
    await queryClient.invalidateQueries({queryKey: 'widgets'})
    return widget
  }

  return (
    <StepModalWrapper<IEnergyPiechartWidget, Widget>
      id='production_state_widget_modal'
      title={`${data._id ? 'Update' : 'Create'} Energy Piechart Widget`}
      show={show}
      close={hide}
      steps={steps}
      data={data}
      defaultData={defaultCreateEnergyPiechartWidget}
      setData={setData}
      submitData={submit}
    />
  )
}

export default EnergyPiechartWidgetModal
