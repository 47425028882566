import DisabledWrapper from '../../../components/DisabledWrapper'
import {DaysOfMonthField} from '../../../components/form-components/DaysOfMonthField'
import NumberField from '../../../components/form-components/NumberField'
import SelectField from '../../../components/form-components/SelectField'
import SelectFieldBoxes from '../../../components/form-components/SelectFieldBoxes'
import SelectFieldBoxesMultiple from '../../../components/form-components/SelectFieldBoxesMultiple'
import TimeField from '../../../components/form-components/TimeField'
import {StepProps} from './_models'

const Step2 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxes
          name='Recurrence Pattern'
          optionNames={['Operational Hours', 'Calendar Days']}
          optionValues={['operational_hours', 'calendar_days']}
          data={data}
          dataField='recurrence_pattern'
          updateData={updateData}
          required
          hasError={hasError}
          updatesOnChange={{
            recurring_hourly_interval: undefined,
            recurring_periods: undefined,
            recurring_dates: undefined,
            recurring_months: undefined,
            recurring_weekdays: undefined,
          }}
        />

        {data.recurrence_pattern !== undefined && <div className='separator mb-10' />}

        {data.recurrence_pattern === 'operational_hours' && (
          <>
            <NumberField
              name='Recurrence Interval (Hours)'
              data={data}
              updateData={updateData}
              dataField='recurring_hourly_interval'
              required
              hasError={hasError}
              isInteger
            />
          </>
        )}

        {data.recurrence_pattern === 'calendar_days' && (
          <>
            <TimeField
              name='Recurring Start Time'
              data={data}
              updateData={updateData}
              dataField='recurring_start_time'
              id='recurring_start_time'
              required
              hasError={hasError}
            />

            <div className='row align-items-center'>
              <div className='col'>
                <DisabledWrapper disabled={data.recurring_weekdays !== undefined}>
                  <DaysOfMonthField
                    name='Recurring Dates'
                    data={data}
                    updateData={updateData}
                    dataField='recurring_dates'
                    id='recurring_dates'
                  />
                  {hasError &&
                    data.recurring_weekdays === undefined &&
                    data.recurring_dates === undefined &&
                    data.recurring_months !== undefined && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>Please enter the Recurring Dates</div>
                      </div>
                    )}

                  <SelectFieldBoxesMultiple
                    name='Recurring Months'
                    optionNames={[
                      'Jan',
                      'Feb',
                      'Mar',
                      'Apr',
                      'May',
                      'Jun',
                      'Jul',
                      'Aug',
                      'Sept',
                      'Oct',
                      'Nov',
                      'Dec',
                    ]}
                    optionValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                    data={data}
                    dataField='recurring_months'
                    updateData={updateData}
                    colSpace={4}
                    lastField
                  />
                  {hasError &&
                    data.recurring_weekdays === undefined &&
                    data.recurring_dates !== undefined &&
                    data.recurring_months === undefined && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          Please enter either the recurring months
                        </div>
                      </div>
                    )}
                </DisabledWrapper>
              </div>
              <div className='col-md-auto'>
                <div className='text-muted'>Or</div>
              </div>
              <div className='col'>
                <DisabledWrapper
                  disabled={
                    data.recurring_dates !== undefined || data.recurring_months !== undefined
                  }
                >
                  <SelectFieldBoxesMultiple
                    name='Recurring Weekdays'
                    optionNames={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']}
                    optionValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                    data={data}
                    dataField='recurring_weekdays'
                    updateData={updateData}
                    colSpace={6}
                    lastField
                  />
                </DisabledWrapper>
              </div>
            </div>
            {hasError &&
              data.recurring_weekdays === undefined &&
              data.recurring_dates === undefined &&
              data.recurring_months === undefined && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    Please enter either the recurring dates and months or weekdays
                  </div>
                </div>
              )}
          </>
        )}
        <div className={`mt-5`}>
          <SelectField
            name='Future work orders'
            optionNames={['1', '2', '3', '4', '5']}
            optionValues={['1', '2', '3', '4', '5']}
            data={data}
            updateData={updateData}
            dataField='max_future_jobs'
            lastField={true}
            hasError={hasError}
            required={true}
            disableBaseOption={true}
          />
        </div>
      </div>
    </div>
  )
}

export default Step2
