import React from 'react'
import {Asset} from '../../../core/_models'
import {useParts, usePartsPaginated} from '../../../core/requests/factory'
import PartNode from './PartNode'
import TreeNode from './TreeNode'

interface AssetNodeProps {
  asset: Asset
}

export const AssetNode = ({asset}: AssetNodeProps) => {
  const [opened, setOpen] = React.useState(false)
  const {data: assets} = useParts({asset: asset._id})
  const {items, isFetching, hasNextPage, fetchNextPage} = usePartsPaginated(
    {asset: asset._id},
    {
      enabled: opened,
    }
  )

  React.useEffect(() => {
    if (!isFetching && hasNextPage) {
      ;(async () => {
        await fetchNextPage()
      })()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  const nodes = items?.map((part) => <PartNode part={part} />)

  return (
    <TreeNode
      name={asset.name}
      iconPath={`/media/icons/duotune/general/gen017.svg`}
      opened={opened}
      setOpen={setOpen}
      nodes={nodes}
      isExpandable={!!assets?.total}
    />
  )
}

export default AssetNode
