import React, {useEffect} from 'react'
import StepModalWrapper, {Step} from '../../../../../components/StepModalWrapper'
import {useDashboardGrid} from '../../../../../providers/DashboardGridProvider'
import {createWidget, updateWidget} from '../../../../../core/requests/template'
import {BaseModel, Widget} from '../../../../../core/_models'
import {useQueryClient} from 'react-query'
import {IOeeWidget, defaultCreateOeeWidget} from './_models'
import {WidgetType, getDisplayName} from '../../widgets/_models'
import {
  eventLogsSteps,
  oeeMetricsSteps,
  operationalStateSteps,
  operationalStatsSteps,
  paretoSteps,
  productionStatsSteps,
  stopRegistrationSteps,
  timelineSteps,
} from './steps/_steps'

interface Props {
  show: boolean
  hide: () => void
  cancel?: () => void
  type: WidgetType
  widgetData?: Widget & BaseModel
}

const OeeWidgetModal = ({show, hide, cancel, type, widgetData}: Props) => {
  const queryClient = useQueryClient()
  const {activeDashboardId} = useDashboardGrid()
  const [data, setData] = React.useState<IOeeWidget>({
    ...defaultCreateOeeWidget,
    type,
    ...widgetData,
  })

  const getStepsForType = (type: WidgetType): Step<IOeeWidget>[] => {
    switch (type) {
      case 'Production Stats':
        return productionStatsSteps
      case 'Operational State':
        return operationalStateSteps
      case 'Operational Stats':
        return operationalStatsSteps
      case 'Stop Registration':
        return stopRegistrationSteps
      case 'OEE Availability':
      case 'OEE':
      case 'OEE Performance':
      case 'OEE Quality':
        return oeeMetricsSteps
      case 'Timeline':
        return timelineSteps
      case 'Event Logs':
        return eventLogsSteps
      default:
        return paretoSteps
    }
  }

  const steps = React.useMemo(() => getStepsForType(type), [type])

  const submit = async (data: IOeeWidget) => {
    data.dashboard = activeDashboardId!
    const widget = data._id ? await updateWidget(data._id, data) : await createWidget(data)
    await queryClient.invalidateQueries({queryKey: 'widgets'})
    return widget
  }

  return (
    <StepModalWrapper<IOeeWidget, Widget>
      id='oee_widget_modal'
      title={`${data._id ? 'Update' : 'Create'} ${getDisplayName(type)} Widget`}
      show={show}
      close={hide}
      cancel={cancel}
      steps={steps}
      data={data}
      defaultData={defaultCreateOeeWidget}
      setData={setData}
      submitData={submit}
    />
  )
}

export default OeeWidgetModal
