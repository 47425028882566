import {IWidget, defaultCreateWidget, defaultLayout} from '../../../widgets/_models'

export type IPowerConsumptionWidget = IWidget & {
  visualization?: 'line' | 'column'
  nameIsDefault?: boolean
}

export const defaultCreatePowerConsumptionWidget: IPowerConsumptionWidget = {
  ...defaultCreateWidget,
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
    min_w: 4,
    min_h: 7,
  },
  type: 'Power Consumption',
  multiplier: 1,
  nameIsDefault: true,
}

export type StepProps = {
  data: IPowerConsumptionWidget
  updateData: (fieldsToUpdate: Partial<IPowerConsumptionWidget>) => void
  hasError: boolean
}
