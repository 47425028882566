import {ContentProps, FormModalWrapper} from '../../components/FormModalWrapper'
import LoadingWrapper from '../../components/LoadingWrapper'
import MultiSelectFieldSymbols from '../../components/form-components/MultiSelectFieldSymbols'
import MultiTextField from '../../components/form-components/MultiTextField'
import NumberField from '../../components/form-components/NumberField'
import {useProfile, useUsers} from '../../core/requests/auth'

export type CreateSetupProps = {
  actions?: string[]
  assignees?: string[]
  duration?: number
  productionLineId?: string
  startTime?: number
}

type Props = {
  show: boolean
  handleClose: () => void
  onSubmit: (data: CreateSetupProps) => void
}

const CreatePlannerSetupModal = ({show, handleClose, onSubmit}: Props) => {
  const submit = async (data: CreateSetupProps) => {
    onSubmit(data)
  }

  return (
    <FormModalWrapper<CreateSetupProps>
      id='hs_create_planner_setup_modal'
      title='Create Planner Setup'
      show={show}
      close={handleClose}
      defaultData={{}}
      validation={({duration}) => duration !== undefined && duration > 0}
      submit={submit}
      body={{content: Content}}
    />
  )
}

const Content = ({data, updateData, hasError}: ContentProps<CreateSetupProps>) => {
  const {data: profile} = useProfile()
  const {data: users, isSuccess: usersReady} = useUsers({
    organization: profile!.organization!._id,
    options: {
      enabled: !!profile?.organization,
    },
  })

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={!usersReady}>
        <div className='w-100'>
          <div className='row justify-content-between'>
            <div className='col'>
              <NumberField
                name='Duration (minutes)'
                data={data}
                updateData={updateData}
                dataField='duration'
                required
                hasError={hasError}
              />
            </div>
            <div className='col-md-auto'>
              <MultiSelectFieldSymbols
                name='Assignees'
                optionNames={users?.items?.map((user) => user.email) || []}
                optionValues={users?.items?.map((user) => user.email) || []}
                data={data}
                updateData={updateData}
                dataField='assignees'
              />
            </div>
          </div>

          <MultiTextField
            name='Actions'
            data={data}
            updateData={updateData}
            dataField='actions'
            lastField
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default CreatePlannerSetupModal
