import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type CopyClipboardTextProps = {
  text: string
}

const CopyClipboardText = ({text}: CopyClipboardTextProps) => {
  const [copied, setCopied] = React.useState(false)
  React.useEffect(() => {
    if (!copied) {
      return
    }

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }, [copied])

  return (
    <div className='position-relative pe-15'>
      <OverlayTrigger placement='top' overlay={<Tooltip>Copy</Tooltip>}>
        <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
          <span className='badge badge-sm text-bg-secondary cursor-pointer position-absolute end-0'>
            {copied ? 'copied' : 'copy'}
          </span>
        </CopyToClipboard>
      </OverlayTrigger>
      {text}
    </div>
  )
}

export {CopyClipboardText}
