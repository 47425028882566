interface Props<DataType extends Record<string, any>> {
  name: string
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
}

const SwitchField = <DataType extends Record<string, any>>({
  name,
  data,
  dataField,
  updateData,
}: Props<DataType>) => (
  <label className='form-check form-switch form-check-custom form-check-solid mb-3'>
    <input
      className='form-check-input'
      type='checkbox'
      checked={data[dataField]}
      onChange={(e) => updateData({[dataField]: e.target.checked} as Partial<DataType>)}
    />
    <span className='form-check-label fw-semibold text-muted'>{name}</span>
  </label>
)

export default SwitchField
