import {
  CmmsRelation,
  EntityGroup,
  FileAttachment,
  Priority,
  WorkOrderCategory,
} from '../../../core/_models'

export type IWorkOrder = {
  planned_start_time?: number
  // organization?: string
  expected_duration?: number
  title?: string
  description?: string
  factory_entity?: string
  actions?: string[]
  work_order_template?: string
  cmms_relation?: CmmsRelation
  start_time?: number
  end_time?: number
  assignees?: string[]
  priority?: Priority
  category?: WorkOrderCategory
  sub_category?: string
  include_diagnosis?: boolean

  attachments?: FileAttachment[]

  // non-api fields
  expected_end_time?: number
  selected_entity_type?: EntityGroup
  is_editing: boolean
}

export const defaultCreateWorkOrder: IWorkOrder = {
  // organization: undefined,
  planned_start_time: undefined,
  expected_duration: undefined,
  title: undefined,
  factory_entity: undefined,
  actions: undefined,
  work_order_template: undefined,
  cmms_relation: undefined,
  start_time: undefined,
  end_time: undefined,
  assignees: undefined,
  priority: undefined,
  category: undefined,
  sub_category: undefined,
  description: undefined,
  include_diagnosis: undefined,

  // non-api fields
  expected_end_time: undefined,
  selected_entity_type: undefined,
  is_editing: false,
  attachments: [],
}

export type StepProps = {
  data: IWorkOrder
  updateData: (fieldsToUpdate: Partial<IWorkOrder>) => void
  hasError: boolean
}
