import React from 'react'
import {useState} from 'react'

interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  disabled?: boolean
}

const MultiTextField = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
  disabled = false,
}: Props<DataType>) => {
  const [fields, setFields] = useState<string[]>([])

  const updateFields = (newFields: string[]) => {
    setFields(newFields)
    if (newFields.length === 1 && newFields[0] === '') {
      updateData({[dataField]: undefined} as Partial<DataType>)
    } else if (newFields.length === 1) {
      updateData({[dataField]: [...newFields]} as Partial<DataType>)
    } else {
      updateData({[dataField]: newFields.slice(0, -1)} as Partial<DataType>)
    }
  }

  React.useEffect(() => {
    setFields(data[dataField] && data[dataField].length > 0 ? [...data[dataField], ''] : [''])
  }, [data, dataField])

  return (
    <>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>
        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>

      {fields.map((field, index) => (
        <div
          className={`${
            !lastField && index === fields.length - 1
              ? 'mb-10'
              : index !== fields.length - 1
              ? 'mb-3'
              : ''
          }`}
          key={index}
        >
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={dataField}
            value={field}
            disabled={disabled}
            onChange={(e) => {
              const inputVal = e.target.value

              if (inputVal === '') {
                if (fields.length <= 2) updateFields([''])
                else updateFields([...fields.slice(0, index), ...fields.slice(index + 1)])
                return
              }

              if (inputVal !== '' && fields[index] === '') {
                if (fields.length === 1) updateFields([inputVal, ''])
                else updateFields([...fields.slice(0, index), inputVal, ''])
                return
              }

              if (fields.length === 1) updateFields([inputVal])
              else updateFields([...fields.slice(0, index), inputVal, ...fields.slice(index + 1)])
            }}
          />
        </div>
      ))}
      {required && data[dataField] === undefined && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </>
  )
}

export default MultiTextField
