import {Axis, Manufacturer, PartModel, SensorType, Voltage} from '../../core/_models'

export type ICreateSensor = {
  placement?: string
  device_id?: string
  data_types?: SensorType[]
  manufacturer?: Manufacturer
  model?: PartModel
  x?: Axis
  y?: Axis
  z?: Axis
  is_vertical?: boolean
  wakeup_threshold?: number
  track_scrap?: boolean
  // Not model fields
  mountingChoice?: 'dot-based' | 'manual'
  voltage?: Voltage
}

export const defaultCreateSensor: ICreateSensor = {
  placement: undefined,
  device_id: undefined,
  data_types: undefined,
  manufacturer: undefined,
  model: undefined,
  x: undefined,
  y: undefined,
  z: undefined,
  is_vertical: undefined,
  track_scrap: undefined,
  wakeup_threshold: undefined,
  mountingChoice: undefined,
}

export type StepProps = {
  data: ICreateSensor
  updateData: (fieldsToUpdate: Partial<ICreateSensor>) => void
  hasError: boolean
}
