import {useEffect, useState} from 'react'
import Select from 'react-select'

interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  optionNames: readonly string[]
  optionValues: readonly string[]
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  updatesOnChange?: (newValue: string) => Partial<DataType>
  disableBaseOption?: boolean
  isLoading?: boolean
  gotoNextPage?: () => void
}

const SelectField = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  optionNames,
  optionValues,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
  updatesOnChange,
  disableBaseOption = false,
  isLoading = false,
  gotoNextPage,
}: Props<DataType>) => {
  const [internalValue, setInternalValue] = useState<string>(data[dataField] || '')

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setInternalValue(value)
    updateData({
      [dataField]: value !== '' ? value : undefined,
      ...(updatesOnChange !== undefined ? updatesOnChange(value) : {}),
    } as Partial<DataType>)
  }

  // Handle when the data changes from outside
  useEffect(() => {
    if (data[dataField] === internalValue) return
    setInternalValue(data[dataField] || '')
  }, [data[dataField]])

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>
        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>

      <Select
        className='form-select-solid'
        placeholder={`Select ${name.toLowerCase()}...`}
        data-hide-search='true'
        name={dataField}
        value={
          internalValue
            ? {
                value: internalValue,
                label: optionNames[optionValues.indexOf(internalValue)],
              }
            : null
        }
        options={optionNames.map((optionName, index) => ({
          value: optionValues[index],
          label: optionName,
        }))}
        onChange={(newValue) =>
          handleChange({target: {value: newValue?.value}} as React.ChangeEvent<HTMLSelectElement>)
        }
        isLoading={isLoading}
        onMenuScrollToBottom={gotoNextPage}
        isClearable={!disableBaseOption}
      />

      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export default SelectField
