interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  columnNames: string[]
  optionRows: any[][]
  optionValues: string[]
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  updatesOnChange?: (newValue?: string | string[]) => Partial<DataType>
  height?: string
  gotoNextPage?: () => void
  loading?: boolean
}

const SelectFieldTable = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  columnNames,
  optionRows,
  optionValues,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
  updatesOnChange,
  height,
  gotoNextPage,
  loading,
}: Props<DataType>) => {
  const isMultiple = Array.isArray(data[dataField])

  const handleChange = (optionValue: string) => {
    if (isMultiple) {
      const newData: string[] = data[dataField]
      if (newData.includes(optionValue)) {
        newData.splice(newData.indexOf(optionValue), 1)
      } else {
        newData.push(optionValue)
      }
      updateData({
        [dataField]: newData,
        ...(updatesOnChange ? updatesOnChange(newData) : {}),
      } as Partial<DataType>)
    } else {
      const newValue = optionValue !== data[dataField] ? optionValue : undefined
      updateData({
        [dataField]: newValue,
        ...(updatesOnChange ? updatesOnChange(newValue) : {}),
      } as Partial<DataType>)
    }
  }

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - Math.ceil(e.currentTarget.scrollTop) ===
      e.currentTarget.clientHeight

    if (bottom && !loading && gotoNextPage) {
      gotoNextPage()
    }
  }

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>
        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>
      <div className={`${height ? `mh-${height} scroll-y` : ''}`} onScroll={handleScroll}>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-hover align-middle gs-0 gy-3 my-0'>
            <thead>
              <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                {columnNames.map((columnName, index) => (
                  <th key={index} className='p-0 pb-3 text-start'>
                    {columnName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {optionRows.map((optionRow, index) => {
                const isSelected = isMultiple
                  ? data[dataField].includes(optionValues[index])
                  : optionValues[index] === data[dataField]
                return (
                  <tr
                    className={`cursor-pointer hover:bg-light ${isSelected ? 'table-primary' : ''}`}
                    onClick={() => handleChange(optionValues[index])}
                    key={index}
                  >
                    {optionRow.map((optionValue, colIndex) => (
                      <td key={colIndex}>
                        <span
                          className={`text-gray-${
                            colIndex === 0 ? '800 fw-bold' : '600 fw-semibold'
                          } fs-6 ps-1`}
                        >
                          {optionValue}
                        </span>
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export default SelectFieldTable
