import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {ParetoGraph} from '../../components/charts/ParetoGraph'
import {WidgetProps} from './Widget'

const ParetoWidget = ({widget, height}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  return (
    <ParetoGraph
      productionLineId={widget.entity}
      eventCategoryIds={widget.operational_event_categories}
      height={height}
      startTime={startTime}
      endTime={endTime}
      chartId={widget._id}
    />
  )
}

export default ParetoWidget
