import axios, {AxiosError} from 'axios'
import {
  UseMutationResult,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import {
  Automation,
  AutomationEvent,
  BaseModel,
  DeletionConfirmation,
  OrganizationQueryProps,
  OrganizationRequestParams,
  PaginatedQueryProps,
  PaginatedRequestParams,
  PaginatedResponse,
} from '../_models'
import {Action} from '../../modals/automations/actions/actions'

const AUTOMATIONS_API_BASE_URL = process.env.REACT_APP_WA_AUTOMATIONS_URL

console.log('AUTOMATIONS_API_BASE_URL', AUTOMATIONS_API_BASE_URL)

// ----- ACTIONS ----- //

const ACTIONS_URL = `${AUTOMATIONS_API_BASE_URL}/actions`

// ----- EVENTS ----- //

const EVENTS_URL = `${AUTOMATIONS_API_BASE_URL}/events`

type UseEventsProps = {
  options?: UseQueryOptions<AutomationEvent[], Error>
}

export const useEvents = ({options}: UseEventsProps = {}) => {
  return useQuery<AutomationEvent[], Error>(
    'events',
    async () => (await axios.get<AutomationEvent[]>(EVENTS_URL)).data,
    options
  )
}

// ----- AUTOMATIONS ----- //

const AUTOMATIONS_URL = `${AUTOMATIONS_API_BASE_URL}/automations`

type UseAutomationsProps = PaginatedQueryProps<Automation & BaseModel> &
  OrganizationQueryProps & {
    enabled?: boolean
    events?: string[]
  }

type UseAutomationsRequestParams = PaginatedRequestParams &
  OrganizationRequestParams & {
    enabled?: boolean
    events?: string[]
  }

export const fetchAutomations = async <T = Automation>(params?: UseAutomationsRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(AUTOMATIONS_URL, {params})).data

export const useAutomations = (props: UseAutomationsProps = {}) => {
  const {organization, enabled, events, page, size, options} = props
  const params: UseAutomationsRequestParams = {organization, enabled, events, page, size}
  return useQuery<PaginatedResponse<Automation & BaseModel>, Error>(
    ['automations', ...Object.values(params)],
    async () => fetchAutomations(params),
    options
  )
}

type CreateAutomationProps = {
  organization?: string
  user?: string
  enabled: boolean
  description?: string
  event: string
  conditions?: any
  actions: Action[]
  cooldownMinutes?: number
}

type CreateAutomationRequestParams = {
  organization?: string
  user?: string
  enabled: boolean
  description?: string
  event: string
  conditions?: any
  actions: Action[]
  cooldown_minutes?: number
}

export const createAutomation = async (props: CreateAutomationProps) => {
  const requestParams: CreateAutomationRequestParams = {
    organization: props.organization,
    user: props.user,
    enabled: props.enabled,
    description: props.description,
    event: props.event,
    conditions: props.conditions,
    actions: props.actions,
    cooldown_minutes: props.cooldownMinutes,
  }
  const {data} = await axios.post<Automation>(AUTOMATIONS_URL, requestParams)
  return data
}

type UpdateAutomationProps = {
  id: string
  organization?: string
  user?: string
  enabled?: boolean
  description?: string
  event?: string
  conditions?: any
  actions?: Action[]
  cooldownMinutes?: number
}

type UpdateAutomationRequestParams = {
  organization?: string
  user?: string
  enabled?: boolean
  description?: string
  event?: string
  conditions?: any
  actions?: Action[]
  cooldown_minutes?: number
}

export const updateAutomation = async (props: UpdateAutomationProps) => {
  const requestParams: UpdateAutomationRequestParams = {
    organization: props.organization,
    user: props.user,
    enabled: props.enabled,
    description: props.description,
    event: props.event,
    conditions: props.conditions,
    actions: props.actions,
    cooldown_minutes: props.cooldownMinutes,
  }
  const {data} = await axios.put<Automation>(`${AUTOMATIONS_URL}/${props.id}`, requestParams)
  return data
}

export async function deleteAutomation(automationId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${AUTOMATIONS_URL}/${automationId}`)
  return data
}
