import React from 'react'
import {Sensor} from '../../../core/_models'
import TreeNode from './TreeNode'

interface SensorNodeProps {
  sensor: Sensor
}

export const SensorNode = ({sensor}: SensorNodeProps) => (
  <TreeNode
    name={sensor.device_id}
    iconPath={`/media/icons/duotune/electronics/elc007.svg`}
    opened={false}
    setOpen={() => {}}
    isExpandable={false}
  />
)

export default SensorNode
