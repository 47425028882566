import {IWidget, defaultCreateWidget, defaultLayout} from '../../widgets/_models'

export type ITagWidget = IWidget & {}

export const defaultCreateTextWidget: ITagWidget = {
  ...defaultCreateWidget,
  type: 'Tag',
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
    min_w: 4,
    min_h: 7,
  },
}

export type StepProps = {
  data: ITagWidget
  updateData: (fieldsToUpdate: Partial<ITagWidget>) => void
  hasError: boolean
}
