import {useMemo} from 'react'

type CardWrapperProps = {
  children: React.ReactNode
} & ({title?: string; titleComponent?: never} | {title?: never; titleComponent?: React.ReactNode})

const CardWrapper = ({title, titleComponent, children}: CardWrapperProps) => {
  const renderTitle = () => {
    if (title)
      return (
        <div className='card-header justify-content-center mb-n10'>
          <h3 className='card-title align-items-center flex-column'>
            <span className='fw-bold text-dark fs-3 text-center'>{title}</span>
          </h3>
        </div>
      )

    if (titleComponent)
      return <div className='card-header justify-content-center mb-n10'>{titleComponent}</div>

    return null
  }

  const memoizedChildren = useMemo(
    () => (
      <div
        className='card-body p-0'
        style={{
          height: 'calc(100% - 70px)',
        }}
      >
        {children}
      </div>
    ),
    [children]
  )

  return (
    <div className='card card-flush overflow-hidden h-lg-100'>
      {renderTitle()}
      {memoizedChildren}
    </div>
  )
}

export {CardWrapper}
