import {useState} from 'react'
import {CURRENCIES, Currency, StepProps} from './_models'
import SelectField from '../../components/form-components/SelectField'
import NumberField from '../../components/form-components/NumberField'

const Step3 = ({data, updateData, hasError}: StepProps) => {
  const currencies = [...CURRENCIES]

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* Currency */}
        <SelectField
          name='Currency'
          optionNames={currencies}
          optionValues={currencies}
          data={data}
          updateData={updateData}
          dataField='currency'
          updatesOnChange={() => ({useFixedPowerPrice: false, fixed_power_price: undefined})}
          lastField={!data.currency}
        />

        {/* Toggle - Fixed Power Price */}
        {data.currency && (
          <div className='fv-row'>
            <div className='d-flex flex-stack mb-8'>
              <div className='me-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Use Fixed Power Price?</span>
                </label>
                <div className='fs-7 fw-semibold text-muted'>
                  More information about fixed power price...
                </div>
              </div>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={data.useFixedPowerPrice}
                  onChange={(e) => {
                    if (!e.target.checked)
                      updateData({fixedPowerPrice: undefined, useFixedPowerPrice: false})
                    else updateData({useFixedPowerPrice: true})
                  }}
                />
                <span className='form-check-label fw-semibold text-muted'>Yes</span>
              </label>
            </div>
          </div>
        )}

        {/* Fixed Power Price */}
        {data.useFixedPowerPrice && data.currency && (
          <NumberField
            name='Fixed Power Price'
            data={data}
            updateData={updateData}
            dataField='fixedPowerPrice'
            required
            hasError={hasError}
            lastField
          />
        )}
      </div>
    </div>
  )
}

export default Step3
