import ZonesSelectField from '../fields/ZonesSelectField'
import {HealthMatchCondition} from './conditions'

type Props = {
  condition: HealthMatchCondition
  setCondition: (condition: HealthMatchCondition) => void
  errorOccurred?: boolean
}

const HealthMatch = ({condition, setCondition, errorOccurred}: Props) => (
  <ZonesSelectField
    name='States'
    initial={condition.states}
    onChange={(values) => setCondition({...condition, states: values})}
    className='pt-3'
    errorOccurred={errorOccurred}
  />
)

export default HealthMatch
