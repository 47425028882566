import {useEffect} from 'react'
import LoadingWrapper from '../../components/LoadingWrapper'
import {useCreateModals} from '../CreateModalsProvider'
import {StepProps} from './_models'
import TextField from '../../components/form-components/TextField'
import {useIntl} from 'react-intl'
import SelectField from '../../components/form-components/SelectField'
import {useProductionLinesPaginated} from '../../core/requests/factory'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  const {initialProductionLine} = useCreateModals()
  const {items, isLoading, isFetching, fetchNextPage} = useProductionLinesPaginated()

  useEffect(() => {
    if (!data.production_line && initialProductionLine)
      updateData({production_line: initialProductionLine._id})
  }, [initialProductionLine])

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={isLoading}>
        <div className='w-100'>
          {/* Section Name */}
          <TextField
            name='Section Name'
            data={data}
            updateData={updateData}
            dataField='name'
            hasError={hasError}
            tooltip={intl.formatMessage({id: 'TOOLTIP.NAMED_ENTITY.SECTION'})}
            required
          />

          {/* Production Line */}
          <SelectField
            name='Production Line'
            optionNames={items?.map((productionLine) => productionLine.name) || []}
            optionValues={items?.map((productionLine) => productionLine._id) || []}
            data={data}
            updateData={updateData}
            dataField='production_line'
            lastField
            hasError={hasError}
            tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.PRODUCTION_LINE'})}
            required
            isLoading={isFetching}
            gotoNextPage={fetchNextPage}
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step1
