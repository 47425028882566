import {Modal} from 'react-bootstrap'
import {EventCategoryGroup} from '../../../core/_models'
import {
  DeleteEventCategoryGroupProps,
  deleteEventCategoryGroup,
  useEventCategories,
  useEventCategoryGroups,
  useEventLogs,
} from '../../../core/requests/oee'
import {KTSVG} from '../../../../_metronic/helpers'
import LoadingOverlayWrapper from '../../../components/LoadingOverlayWrapper'
import {useState} from 'react'
import {useCategorySelection} from '../CategorySelectionContext'
import {PROTECTED_CATEGORIES} from '../EventCategoriesModal'
import clsx from 'clsx'
import Select from 'react-select'
import {useQueryClient} from 'react-query'

type EventCategoryGroupDeleteModalProps = {
  show: boolean
  close: () => void
  eventCategoryGroup: EventCategoryGroup
}

const EventCategoryGroupDeleteModal = ({
  show,
  close,
  eventCategoryGroup,
}: EventCategoryGroupDeleteModalProps) => {
  const [deleting, setDeleting] = useState(false)
  const [chosenOption, setChosenOption] = useState<'migrate' | 'delete' | null>(null)

  const queryClient = useQueryClient()
  const {setActiveCategoryGroup} = useCategorySelection()
  const {data: eventCategories, isLoading: eventCategoriesAreLoading} = useEventCategories({
    eventCategoryGroups: [eventCategoryGroup._id],
  })
  const {data: eventLogs, isLoading: eventLogsAreLoading} = useEventLogs({
    categories: eventCategories?.items.map((c) => c._id),
    options: {enabled: eventCategories !== undefined && eventCategories.items.length > 0},
  })

  const loading = eventCategoriesAreLoading || eventLogsAreLoading || deleting
  const hasNoEvents =
    (eventCategories !== undefined && eventCategories.items.length === 0) ||
    (eventLogs && eventLogs.items.length === 0)
  const deletionAllowed = eventCategories?.items.every((c) =>
    PROTECTED_CATEGORIES.includes(c.title)
  )

  const handleDelete = async (props: DeleteEventCategoryGroupProps) => {
    setDeleting(true)
    try {
      const status = await deleteEventCategoryGroup(props)
      await queryClient.invalidateQueries('eventCategoryGroups')
      await queryClient.invalidateQueries('eventCategories')
      if (status.was_deleted) setActiveCategoryGroup(undefined)
      else console.error('Failed to delete event category group')
    } catch (error) {
      console.error(error)
    } finally {
      setDeleting(false)
      close()
    }
  }

  return (
    <Modal
      show={show}
      tabIndex={-1}
      aria-hidden='true'
      size='lg'
      dialogClassName='modal-dialog modal-dialog-centered'
      onHide={close}
      backdrop={loading ? 'static' : true}
    >
      <LoadingOverlayWrapper loading={loading}>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={close}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        {!deletionAllowed ? (
          <DeletionNotAllowed eventCategoryGroup={eventCategoryGroup} close={close} />
        ) : !hasNoEvents ? (
          <>
            {chosenOption === null && (
              <ChooseToMigrate
                eventCategoryGroup={eventCategoryGroup}
                setChosenOption={setChosenOption}
              />
            )}
            {chosenOption === 'delete' && (
              <ConfirmDeletionWithLogs
                eventCategoryGroup={eventCategoryGroup}
                handleDelete={handleDelete}
                close={close}
              />
            )}
            {chosenOption === 'migrate' && (
              <MigrateAndDelete
                eventCategoryGroup={eventCategoryGroup}
                close={close}
                handleDelete={handleDelete}
              />
            )}
          </>
        ) : (
          <ConfirmDeletionNoLogs
            eventCategoryGroup={eventCategoryGroup}
            handleDelete={handleDelete}
            close={close}
          />
        )}
      </LoadingOverlayWrapper>
    </Modal>
  )
}

const ConfirmDeletionNoLogs = ({
  eventCategoryGroup,
  handleDelete,
  close,
}: {
  eventCategoryGroup: EventCategoryGroup
  handleDelete: (props: DeleteEventCategoryGroupProps) => Promise<void>
  close: () => void
}) => (
  <div className='modal-body mx-5 mx-xl-18 pt-0'>
    <h1 className='text-center mb-8'>Delete Category Group?</h1>

    <div className='notice bg-light-danger rounded border-danger border border-dashed p-6 mb-3'>
      <div className='d-flex'>
        <KTSVG
          className='svg-icon-2tx svg-icon-danger me-4'
          path='/media/icons/duotune/general/gen044.svg'
        />
        <div className='d-flex flex-row flex-stack flex-grow-1'>
          <div className='fw-semibold'>
            <h4 className='text-gray-900 fw-bold'>
              {`Are you sure you want to delete the category group "${eventCategoryGroup.title}"? This group only contains system-standard categories and no event logs will be lost`}
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div className='d-flex flex-center pt-5'>
      <button className='btn btn-light me-3' onClick={close}>
        Cancel
      </button>
      <button className='btn btn-danger' onClick={() => handleDelete({id: eventCategoryGroup._id})}>
        Confirm Deletion
      </button>
    </div>
  </div>
)

const ConfirmDeletionWithLogs = ({
  eventCategoryGroup,
  handleDelete,
  close,
}: {
  eventCategoryGroup: EventCategoryGroup
  handleDelete: (props: DeleteEventCategoryGroupProps) => Promise<void>
  close: () => void
}) => (
  <div className='modal-body mx-5 mx-xl-18 pt-0'>
    <h1 className='text-center mb-8'>Confirm Permanent Deletion</h1>

    <div className='notice bg-light-danger rounded border-danger border border-dashed p-6 mb-3'>
      <div className='d-flex'>
        <KTSVG
          className='svg-icon-2tx svg-icon-danger me-4'
          path='/media/icons/duotune/general/gen044.svg'
        />
        <div className='d-flex flex-row flex-stack flex-grow-1'>
          <div className='fw-semibold'>
            <h4 className='text-gray-900 fw-bold'>
              {`You are about to permanently delete the Event Category Group "${eventCategoryGroup.title}" and all associated event logs under "Running" and "Auto-detected" categories. This action will result in irreversible loss of historical data`}
            </h4>

            <div className='mt-5 fw-bold'>Please confirm your understanding that:</div>
            <ul className='list list-check fw-bold'>
              <li>
                <span className='text-danger'>{`All event logs within "${eventCategoryGroup.title}" will be permanently removed.`}</span>
              </li>
              <li>
                <span className='text-danger'>
                  This action cannot be undone, and the lost data cannot be recovered.
                </span>
              </li>
            </ul>

            <div className='fw-bold text-gray-800 mt-5'>
              If you have not already, consider migrating event logs to another group to preserve
              historical data.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='d-flex flex-center pt-5'>
      <button className='btn btn-light me-3' onClick={close}>
        Cancel
      </button>
      <button className='btn btn-danger' onClick={() => handleDelete({id: eventCategoryGroup._id})}>
        Confirm Deletion
      </button>
    </div>
  </div>
)

const MigrateAndDelete = ({
  eventCategoryGroup,
  handleDelete,
  close,
}: {
  eventCategoryGroup: EventCategoryGroup
  handleDelete: (props: DeleteEventCategoryGroupProps) => Promise<void>
  close: () => void
}) => {
  const [targetGroupId, setTargetGroupId] = useState<string | undefined>(undefined)

  const {data: eventCategoryGroups, isLoading: eventCategoryGroupsLoading} =
    useEventCategoryGroups()
  const targetGroups = eventCategoryGroups?.items.filter((g) => g._id !== eventCategoryGroup._id)

  return (
    <div className='modal-body mx-5 mx-xl-18 pt-0'>
      <h1 className='text-center mb-8'>Migrate Event Logs Before Deletion</h1>

      <div className='fw-semibold fs-6 mb-5'>
        {`Before deleting the category group "${eventCategoryGroup.title}", please select a destination group to migrate existing event logs. All logs from 'Running' and 'Auto-detected' categories will be transferred to the corresponding categories in the selected group`}
      </div>

      <Select
        placeholder='Select a target category group...'
        onChange={(e) => setTargetGroupId(e?.value)}
        options={
          targetGroups?.map((g) => ({
            value: g._id,
            label: g.title,
          })) || []
        }
        isClearable
        className='me-3'
        isLoading={eventCategoryGroupsLoading}
      />

      <div className='d-flex flex-center pt-5'>
        <button className='btn btn-light me-3' onClick={close}>
          Cancel
        </button>
        <button
          className='btn btn-danger'
          disabled={!targetGroupId}
          onClick={() => handleDelete({id: eventCategoryGroup._id, migrateTo: targetGroupId!})}
        >
          Migrate & Delete
        </button>
      </div>
    </div>
  )
}

const ChooseToMigrate = ({
  eventCategoryGroup,
  setChosenOption,
}: {
  eventCategoryGroup: EventCategoryGroup
  setChosenOption: (value: React.SetStateAction<'migrate' | 'delete' | null>) => void
}) => (
  <div className='modal-body mx-5 mx-xl-18 pt-0'>
    <h1 className='text-center mb-8'>{`Action Required: Migrating Event Logs from ${eventCategoryGroup.title}`}</h1>

    <div className='fw-semibold fs-6 mb-5'>
      {`The Event Category Group "${eventCategoryGroup.title}" is set to be deleted but contains essential event logs under "Running" and "Auto-detected" categories. Since all category groups include these categories, you can easily migrate the logs to another group, preserving their categorization`}
    </div>

    <div className='row'>
      <div className='col-6'>
        <label
          className={clsx(
            'btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center h-100'
          )}
          onClick={() => setChosenOption('migrate')}
        >
          <span className='me-5'>
            <i className='fa-solid fa-share' style={{fontSize: '2.5rem'}} />
          </span>

          <span className='d-block fw-semibold text-start'>
            <span className='text-dark fw-bold d-block fs-4 mb-2'>
              Migrate Logs to Another Group
            </span>
            <span className='text-muted fw-semibold fs-6'>
              Select a target category group where these event logs will be transferred. Each log
              will be automatically mapped to the corresponding "Running" or "Auto-detected"
              category within the chosen group
            </span>
          </span>
        </label>
      </div>
      <div className='col-6'>
        <label
          className={clsx(
            'btn btn-outline btn-active-light-danger p-7 d-flex align-items-center h-100'
          )}
          onClick={() => setChosenOption('delete')}
        >
          <span className='me-5'>
            <i className='fa-solid fa-trash' style={{fontSize: '2.5rem'}} />
          </span>

          <span className='d-block fw-semibold text-start'>
            <span className='text-dark fw-bold d-block fs-4 mb-2'>Permanently Delete Logs</span>
            <span className='text-muted fw-semibold fs-6'>
              {`If you proceed with deletion without migration, all event logs in "${eventCategoryGroup.title}" will be permanently removed. This action is irreversible and results in the loss of historical data`}
            </span>
          </span>
        </label>
      </div>
    </div>
  </div>
)

const DeletionNotAllowed = ({
  eventCategoryGroup,
  close,
}: {
  eventCategoryGroup: EventCategoryGroup
  close: () => void
}) => (
  <div className='modal-body mx-5 mx-xl-18 pt-0'>
    <h1 className='text-center mb-13'>{`Cannot Delete ${eventCategoryGroup.title}`}</h1>

    <div className='notice bg-light-warning rounded border-warning border border-dashed p-6 mb-3'>
      <div className='d-flex'>
        <KTSVG
          className='svg-icon-2tx svg-icon-warning me-4'
          path='/media/icons/duotune/general/gen044.svg'
        />
        <div className='d-flex flex-row flex-stack flex-grow-1'>
          <div className='fw-semibold'>
            <h4 className='text-gray-900 fw-bold'>
              {`The Event Category Group "${eventCategoryGroup.title}" cannot be deleted because it contains non-protected categories. Only groups with exclusively protected categories like "Running" and "Auto-detected" can be removed. Please reassign or delete the non-protected categories within "${eventCategoryGroup.title}" before attempting to delete the group again.`}
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div className='d-flex flex-center pt-5'>
      <button className='btn btn-light me-3' onClick={close}>
        Cancel
      </button>
    </div>
  </div>
)

export default EventCategoryGroupDeleteModal
