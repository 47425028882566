import LoadingWrapper from '../../components/LoadingWrapper'
import {StepProps} from './_models'
import SelectField from '../../components/form-components/SelectField'
import {useIntl} from 'react-intl'
import {useAreasPaginated, useSectionsPaginated} from '../../core/requests/factory'

const Step3 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  const {
    items: areas,
    isLoading: areasLoading,
    isFetching: areasFetching,
    fetchNextPage: areasNextPage,
  } = useAreasPaginated()

  const {
    items: sections,
    isLoading: sectionsLoading,
    isFetching: sectionsFetching,
    fetchNextPage: sectionsNextPage,
  } = useSectionsPaginated()

  return (
    <div data-kt-stepper-element='content'>
      <LoadingWrapper loading={areasLoading || sectionsLoading}>
        <div className='w-100'>
          <div className='fv-row'>
            {/* Area */}
            {data.locationChoice === 'area' && (
              <SelectField
                name='Area'
                optionNames={areas?.map((area) => area.name) || []}
                optionValues={areas?.map((area) => area._id) || []}
                data={data}
                updateData={updateData}
                dataField='area'
                lastField
                hasError={hasError}
                required
                tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.AREA'})}
                isLoading={areasFetching}
                gotoNextPage={areasNextPage}
              />
            )}

            {/* Section */}
            {data.locationChoice === 'section' && (
              <SelectField
                name='Section'
                optionNames={sections?.map((section) => section.name) || []}
                optionValues={sections?.map((section) => section._id) || []}
                data={data}
                updateData={updateData}
                dataField='section'
                lastField
                hasError={hasError}
                required
                tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.SECTION'})}
                isLoading={sectionsFetching}
                gotoNextPage={sectionsNextPage}
              />
            )}
          </div>
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step3
