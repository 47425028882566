import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {PlannerToolbar} from '../../../_metronic/layout/components/toolbar/PlannerToolbar'
import PageNavigationBar from '../../components/PageNavigationBar'
import {PlannerWrapper} from './PlannerWrapper'

const PlannerPage = () => (
  <Routes>
    <Route
      path=''
      element={
        <>
          <PageNavigationBar title={`Planner`}>
            <PlannerToolbar />
          </PageNavigationBar>
          <PlannerWrapper />
        </>
      }
    />
  </Routes>
)

export default PlannerPage
