import {GraphQLClient} from 'graphql-request'
import {useAuth} from '../../modules/auth'
import {WA_CONDITION_GRAPHQL_URL, WA_DASHBOARDS_GRAPHQL_URL, WA_ENERGY_GRAPHQL_URL} from '../config'

export enum GraphQLURL {
  CONDITION = 'CONDITION',
  DASHBOARDS = 'DASHBOARDS',
  ENERGY = 'ENERGY',
}

const GraphQLURLMap: Record<GraphQLURL, string> = {
  CONDITION: WA_CONDITION_GRAPHQL_URL,
  DASHBOARDS: WA_DASHBOARDS_GRAPHQL_URL,
  ENERGY: WA_ENERGY_GRAPHQL_URL,
} as const

interface Props {
  url: GraphQLURL
}

const useBaseGraphQLClient = ({url}: Props) => {
  const {authToken} = useAuth()
  return new GraphQLClient(GraphQLURLMap[url], {
    errorPolicy: 'all',
    headers: {
      Authorization: `Bearer ${authToken.access_token}`,
    },
  })
}

export default useBaseGraphQLClient
