import React from 'react'
import {CopyClipboardText} from '../../components/content/CopyClipboardText'
import {Factory} from '../../core/_models'
import {deleteFactory, useUpdateFactoryMutation} from '../../core/requests/factory'
import {NameImageCell} from '../../pages/overview/core/columns/ImageStringCell'
import useEntityDeleteModal from '../entity-delete/useEntityDeleteModal'
import EntityInlineTextFieldForm from '../../components/form-components/EntityInlineTextFieldForm'
import OverviewDetailsModal, {BaseOverviewDetailsModalProps} from './OverviewDetailsModal'

const FactoryOverviewDetailsModal = ({
  entity,
  onHide,
  show = true,
}: BaseOverviewDetailsModalProps<Factory>) => {
  const deleteModal = useEntityDeleteModal({
    entity,
    deleteHandler: deleteFactory,
    queryToInvalidate: 'factories',
  })
  let location
  if (entity.location?.coordinates) {
    location = <CopyClipboardText text={entity.location?.coordinates.join(', ')} />
  } else {
    location = '-'
  }
  return (
    <OverviewDetailsModal
      show={show}
      onHide={onHide}
      title={
        <>
          <span className={`text-nowrap`}>Factory Details</span> ・
          <EntityInlineTextFieldForm
            data={entity}
            dataField={'name'}
            useMutation={useUpdateFactoryMutation}
            queryToInvalidate={'factories'}
          />
          {deleteModal.handler}
        </>
      }
      objectId={entity._id}
      attributes={[
        ['ID', <CopyClipboardText text={entity._id} />],
        [
          'Gallery',
          <NameImageCell
            index={1}
            str={''}
            images={
              entity.gallery
                ? [entity.gallery.profile_image, ...(entity.gallery.images ?? [])]
                : undefined
            }
            entityId={entity._id}
            queryToInvalidate='factories'
          />,
        ],
        ['Organization Name', entity.organization.name],
        ['Name', entity.name],
        ['Location Coordinates', location],
        ['Zone Country', entity.zone?.country_name || '-'],
        ['Zone Name', entity.zone?.name || '-'],
        ['Currency', entity.currency],
        ['Fixed Power Price', entity.fixed_power_price || '-'],
      ]}
    />
  )
}

export {FactoryOverviewDetailsModal}
