import Select from 'react-select'
import {PartModel, partModels} from '../../../../core/_models'

type PartModelSelectProps = {
  onChange: (model?: PartModel) => void
}

const PartModelSelect = ({onChange}: PartModelSelectProps) => (
  <Select
    placeholder={`Model`}
    onChange={(e) => onChange(e?.value)}
    options={
      partModels.map((m) => ({
        value: m,
        label: m,
      })) || []
    }
    isClearable
    className={'me-3'}
  />
)

export default PartModelSelect
