import {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {axes, Axis} from '../../core/_models'
import {StepProps} from './_models'
import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'

const Step3 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxes
          name='Choose Mounting Method'
          optionNames={['Mounting Based on Dot-Alignment', 'Manual Axis Input']}
          optionValues={['dot-based', 'manual']}
          data={data}
          updateData={updateData}
          dataField='mountingChoice'
          lastField
          hasError={hasError}
          required
          updatesOnChange={{is_vertical: undefined, x: undefined, y: undefined, z: undefined}}
          colSpace={12}
        />

        <div className='text-muted fw-semibold fs-6 my-2'>
          If you need more info, please check out this
          {/* <a href='#' className='link-primary fw-bold'> */}
          <a className='link-primary fw-bold'> Help Page</a>.
        </div>
      </div>
    </div>
  )
}

export default Step3
