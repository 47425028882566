import {useEffect, useState} from 'react'
import {SketchPicker} from 'react-color'

type ColorPickerProps = {
  color?: string
  show: boolean
  setShow: (show: boolean) => void
  setColor?: (color: string) => void
  onClose?: (color: string) => void
  className?: string
}

export const ColorPicker = ({
  color,
  show,
  setShow,
  setColor,
  onClose,
  className = '',
}: ColorPickerProps) => {
  const [internalColor, setInternalColor] = useState(color || '#FF0000')

  const isControlled = setColor !== undefined
  const currentColor = isControlled ? color : internalColor
  const changeColor = (color: string) => {
    if (isControlled && setColor) setColor(color)
    else setInternalColor(color)
  }

  const handleClose = () => {
    if (!currentColor) return
    if (onClose) onClose(currentColor)
    setShow(false)
  }

  useEffect(() => {
    if (color) setInternalColor(color)
  }, [color, show])

  return (
    <>
      {show && (
        <div style={{position: 'absolute', zIndex: '2'}} className={className}>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClose}
          />
          <SketchPicker color={currentColor} onChange={(color) => changeColor(color.hex)} />
        </div>
      )}
    </>
  )
}
