import React from 'react'
import {useFactoriesPaginated} from '../../core/requests/factory'
import FactoryNode from './nodes/FactoryNode'

import '../../../sass/hexastate/_tree.scss'

export const FactoryTree = () => {
  const {items: factories, fetchNextPage, hasNextPage, isFetching} = useFactoriesPaginated()
  React.useEffect(() => {
    if (!isFetching && hasNextPage) {
      ;(async () => {
        await fetchNextPage()
      })()
    }
  }, [fetchNextPage, hasNextPage, isFetching])
  const nodes = factories?.map((factory) => <FactoryNode factory={factory} />)
  return <div className={`factory-tree`}>{nodes}</div>
}

export default FactoryTree
