import {User} from '@sentry/react'
import React from 'react'
import {KTCard} from '../../../_metronic/helpers'
import LoadingWrapper from '../../components/LoadingWrapper'
import {useInvitationsPaginated, useProfile, useUsers} from '../../core/requests/auth'
import {useSelectedRows} from '../../providers/SelectedRowsProvider'
import {TableBody} from '../overview/core/columns/TableBody'
import {TableToolbar} from '../overview/core/columns/TableToolbar'
import {ListSearchComponent} from '../overview/core/TableSearchComponent'
import InviteUsersModal from './InviteUsersModal'
import {Columns} from './table/_columns'
import {InvitationProfileTableRow} from './table/InvitationProfileTableRow'

export const OrganizationWrapper = () => {
  const {setDataItems} = useSelectedRows()
  const [showInviteModal, setShowInviteModal] = React.useState(false)
  const {data: profile} = useProfile()
  const {data: users, isLoading: usersLoading} = useUsers({
    organization: profile?.organization?._id,
    options: {enabled: !!profile?.organization},
    size: 100,
  })

  React.useEffect(() => {
    if (users?.items !== undefined) {
      setDataItems(users?.items)
    }
  }, [setDataItems, users?.items])

  const columns = React.useMemo(() => Columns, [])

  const {
    hasMore,
    items: invitations,
    gotoNextPage,
    isFetching: invitationsLoading,
  } = useInvitationsPaginated({
    organization: profile?.organization?._id,
    size: 100,
  })

  React.useEffect(() => {
    if (!invitationsLoading && hasMore) {
      gotoNextPage()
    }
  }, [invitationsLoading, hasMore])

  const emails = users?.items?.map((u) => u.email) || []
  const invitationProfiles: User[] = invitations
    .filter((invitation) => !emails.includes(invitation.user))
    .map((invitation) => ({
      _id: invitation._id,
      email: invitation.user,
      name: invitation.user,
    }))

  return (
    <>
      <KTCard>
        {/* Header */}
        <div className='card-header border-0 justify-content-end pt-6'>
          {/* <ListSearchComponent /> */}
          <div className='card-toolbar'>
            {/* Standard toolbar */}
            <TableToolbar
              buttonText='Invite'
              onButtonClick={() => setShowInviteModal(true)}
              buttonIcon='/media/icons/duotune/communication/com002.svg'
            />
          </div>
        </div>

        {/* Body */}
        <LoadingWrapper loading={usersLoading || invitationsLoading}>
          <TableBody
            tableId='hs_organization_table'
            columns={columns}
            dataList={[...(users?.items || []), ...invitationProfiles]}
            loading={usersLoading}
            RowComponent={InvitationProfileTableRow}
          />
        </LoadingWrapper>
      </KTCard>
      <InviteUsersModal show={showInviteModal} hide={() => setShowInviteModal(false)} />
    </>
  )
}
