import {useEffect, useMemo, useRef, useState} from 'react'
import {useUsers} from '../../../core/requests/auth'
import Select, {MultiValue} from 'react-select'

type Props = {
  initial?: string[]
  name?: string
  onchange: (value: string[]) => void
  className?: string
}

type OptionType = {
  value: string
  label: string
}

const UsersSelectField = ({initial = [], name = 'Users', onchange, className}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([])
  const initialOptionsSet = useRef(false)

  const {data: users, isFetching: usersFetching} = useUsers()

  const userOptions = useMemo(() => {
    return users?.items.map((user) => ({label: user.email, value: user._id})) || []
  }, [users])

  useEffect(() => {
    if (!users || !initial.length || initialOptionsSet.current) return
    const initialOptions = initial.map((value) => {
      const user = users.items.find((user) => user._id === value)
      return {value: value, label: user?.email || 'Unknown user'}
    })
    setSelectedOptions(initialOptions)
    initialOptionsSet.current = true
  }, [users, initial])

  const handleChange = (selected: MultiValue<OptionType>) => {
    const values = selected.map((option) => option.value)
    onchange(values)
    setSelectedOptions(selected)
  }

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={selectedOptions}
        options={userOptions}
        onChange={handleChange}
        isLoading={usersFetching}
        isDisabled={usersFetching}
        isMulti
        isClearable
      />
    </>
  )
}

export default UsersSelectField
