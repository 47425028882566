import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {flatten} from 'lodash'
import {showNoDataDisplay} from '../../../../core/chart-util'
import {TimedValue} from '../../../../core/_models'

export type LineData = {
  dataType: string
  unit?: string
  data: TimedValue[]
  name?: string
  legend?: string
}

export function lineConfig(chartId: string, data: Record<string, LineData[]>) {
  const root = am5.Root.new(chartId)
  root.setThemes([am5themes_Animated.new(root)])

  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: true, // pan horizontal (drag) enabled
      panY: true, // pan vertical (drag) enabled
      wheelX: 'panX', // pan horizontal on scroll
      wheelY: 'zoomX', // zoom horizontal on scroll
      pinchZoomX: true, // zoom horizontal on pinch
    })
  )

  const flattenData = flatten(Object.values(data))

  if (!flattenData.length) return showNoDataDisplay(chartId, root, chart)

  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      maxDeviation: 0, // no zooming out of actual data range
      baseInterval: {
        timeUnit: 'second', // time unit of data
        count: 1,
      },
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {}), // tooltip for axis
      groupData: true,
    })
  )

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
    })
  )

  // create series for each data type
  const allSeries: Record<string, am5xy.LineSeries[]> = {}
  flattenData.forEach((line) => {
    const name = line?.name || line.dataType
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        calculateAggregates: true,
        legendLabelText: line.legend ? line.legend : `${name}: ${line.dataType}`,
        tooltip: am5.Tooltip.new(root, {
          labelText: `${name}: {value} ${line.unit || ''}`,
        }),
      })
    )

    series.strokes.template.set('strokeWidth', 1)

    const data = line.data.map((dataPoint) => ({
      name: line.dataType,
      date: new Date(dataPoint.time).getTime(),
      value: dataPoint.value,
    }))

    series.data.setAll(data)
    series.appear(2000)

    if (allSeries[line.dataType]) {
      allSeries[line.dataType].push(series)
    } else {
      allSeries[line.dataType] = [series]
    }
  })

  const flattenAllSeries = flatten(Object.values(allSeries))

  const cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      snapToSeries: flattenAllSeries, // snap cursor line to series
      snapToSeriesBy: 'xy',
    })
  )
  cursor.lineY.set('visible', false) // hide vertical cursor line

  chart.set(
    'scrollbarX', // horizontal scrollbar
    am5.Scrollbar.new(root, {
      orientation: 'horizontal', // horizontal scrollbar
    })
  )

  chart.set(
    'scrollbarY', // vertical scrollbar
    am5.Scrollbar.new(root, {
      orientation: 'vertical', // vertical scrollbar
    })
  )

  // if there are more than 1 series, show legend
  if (flattenData.length > 1) {
    let legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        width: 200,
        height: am5.percent(100),
        paddingLeft: 15,
        nameField: 'name',
      })
    )

    legend.itemContainers.template.events.on('pointerover', function (e) {
      const series = e.target.dataItem?.dataContext as am5xy.LineSeries
      chart.series.each(function (chartSeries: any) {
        if (chartSeries !== series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(0x000000),
          })
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 3,
          })
        }
      })
    })

    legend.itemContainers.template.events.on('pointerout', function (e) {
      chart.series.each(function (chartSeries: any) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 2,
          stroke: chartSeries.get('fill'),
        })
      })
    })

    legend.itemContainers.template.set('width', am5.p100)
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: 'right',
    })

    legend.data.setAll(chart.series.values)
  }

  chart.appear(1000, 100)

  return root
}
