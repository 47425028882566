import DisabledWrapper from '../../../../../../components/DisabledWrapper'
import DateTimeField from '../../../../../../components/form-components/DateTimeField'
import TimeIntervalField from '../../../../../../components/form-components/TimeIntervalField'
import VerticalSeparator from '../../../../../../components/form-components/VerticalSeparator'
import {StepProps} from '../_models'

const TimeRangeStep = ({data, updateData, hasError}: StepProps) => {
  const usingExactDates = !!data.start_time || !!data.end_time
  const usingRelativeTimeframe = !!data.dynamic_time_range
  const bothTimeframesSelected = usingExactDates && usingRelativeTimeframe

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='mb-10'>
          <h2 className='fw-bold d-flex align-items-center text-dark'>
            Optional: Choose Timeframe
          </h2>
          <div className='text-muted fw-semibold fs-6'>
            Optionally, select an exact timeframe by specifying start and end dates or choose a
            relative timeframe.
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <DisabledWrapper disabled={usingRelativeTimeframe && !bothTimeframesSelected}>
              <div className='row'>
                <div className='col-6'>
                  <DateTimeField
                    name='Start Time'
                    data={data}
                    updateData={(data) =>
                      updateData({
                        ...data,
                        start_time: data.start_time
                          ? new Date(data.start_time).toISOString()
                          : undefined,
                      })
                    }
                    dataField='start_time'
                    id='create-widget-start-time'
                    lastField
                  />
                </div>

                <div className='col-6'>
                  <DateTimeField
                    name='End Time'
                    data={data}
                    updateData={(data) =>
                      updateData({
                        ...data,
                        end_time: data.end_time ? new Date(data.end_time).toISOString() : undefined,
                      })
                    }
                    dataField='end_time'
                    id='create-widget-end-time'
                    lastField
                  />
                </div>
              </div>
            </DisabledWrapper>
          </div>

          <VerticalSeparator text='Or' />

          <div className='col-auto'>
            <DisabledWrapper disabled={usingExactDates && !bothTimeframesSelected}>
              <TimeIntervalField
                name='Interval'
                data={data}
                updateData={updateData}
                dataField='dynamic_time_range'
                lastField
              />
            </DisabledWrapper>
          </div>

          {hasError && bothTimeframesSelected && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                Please select either exact dates or a relative timeframe
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TimeRangeStep
