import clsx from 'clsx'
import {useLayout} from '../../../layout/core'
import {lookbackValues, useLookback} from './LookbackProvider'
import {useState} from 'react'
import ForceStopModal from '../../../../app/pages/batches/ForceStopModal'

const ProductionLineToolbar = () => {
  const {values, updateLookback, activebatch} = useLookback()
  const [showForceStopModal, setShowForceStopModal] = useState(false)
  const {config} = useLayout()
  const displayDateRangePickerButton = config.app?.toolbar?.daterangepickerButton

  return (
    <>
      <div className='d-flex align-items-center gap-5'>
        {activebatch && activebatch.status === 'running' && (
          <>
            <a className='btn fw-bold btn-danger' onClick={() => setShowForceStopModal(true)}>
              Stop Batch
            </a>

            <ForceStopModal
              batch={activebatch}
              show={showForceStopModal}
              hide={() => setShowForceStopModal(false)}
            />
          </>
        )}

        {displayDateRangePickerButton ? (
          <>
            <a
              className='btn btn-active-light-primary'
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
            >
              {values.productionLine}
            </a>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-125px'
              data-kt-menu='true'
            >
              {lookbackValues.map((lookbackValue) => (
                <div className='menu-item px-3 my-0' key={lookbackValue}>
                  <a
                    href='#'
                    className={clsx('menu-link px-3 py-2', {
                      active: values.productionLine === lookbackValue,
                    })}
                    onClick={() => updateLookback({productionLine: lookbackValue})}
                  >
                    <span className='menu-title'>{lookbackValue}</span>
                  </a>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}

export {ProductionLineToolbar}
