import React from 'react'
import Dropzone from 'react-dropzone'
import {FileAttachment} from '../../core/_models'
import AttachmentList from '../uploader/AttachmentList'

interface Props<DataType extends Record<string, any>> {
  id?: string
  name: string
  required?: boolean
  tooltip?: string
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  many?: boolean
  customErrorFunction?: (data: DataType) => string | undefined
}

const AttachmentField = <DataType extends Record<string, any>>({
  name,
  required,
  tooltip,
  lastField,
  updateData,
  dataField,
  data,
}: Props<DataType>) => {
  const handleFileChange = (acceptedFiles: File[]) =>
    updateStateData([
      ...data[dataField],
      ...acceptedFiles.map((file) => ({
        file,
      })),
    ])

  const updateStateData = (attachments: FileAttachment[]) => {
    updateData({
      [dataField]: attachments,
    } as Partial<DataType>)
  }

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>
        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>
      <div>
        <Dropzone onDrop={handleFileChange}>
          {({getRootProps, getInputProps}) => (
            <section
              className={`py-5 border border-dashed rounded d-flex justify-content-center align-items-center cursor-pointer`}
              style={{height: 100}}
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={`text-center`}>
                  <div className={`text-primary`}>
                    Drag 'n' drop some files here, or click to select files
                  </div>
                  <span className={`btn btn-primary mt-5`}>Upload</span>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      {data[dataField] && (
        <AttachmentList
          attachments={data[dataField] as FileAttachment[]}
          actions={[
            {
              getElement: (attachment, index) => (
                <i
                  className={`fa fa-trash me-5 link-text-primary cursor-pointer`}
                  onClick={() => {
                    const attachments = [...data.attachments]
                    attachments.splice(index, 1)
                    updateStateData([...attachments])
                  }}
                />
              ),
            },
          ]}
        />
      )}
    </div>
  )
}

export default AttachmentField
