import {useState} from 'react'
import {BaseModel, Widget} from '../../../../../../core/_models'
import {IPowerConsumptionWidget, defaultCreatePowerConsumptionWidget} from './_models'
import StepModalWrapper, {Step} from '../../../../../../components/StepModalWrapper'
import {useDashboardGrid} from '../../../../../../providers/DashboardGridProvider'
import {createWidget, updateWidget} from '../../../../../../core/requests/template'
import {useQueryClient} from 'react-query'
import {getDisplayName} from '../../../widgets/_models'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

interface Props {
  show: boolean
  hide: () => void
  cancel?: () => void
  widgetData?: Widget & BaseModel
}

const PowerConsumptionWidgetModal = ({show, hide, cancel, widgetData}: Props) => {
  const queryClient = useQueryClient()
  const {activeDashboardId} = useDashboardGrid()

  const [data, setData] = useState<IPowerConsumptionWidget>({
    ...defaultCreatePowerConsumptionWidget,
    ...widgetData,
    visualization: widgetData?.data_types?.at(0) === 'Power Consumption Column' ? 'column' : 'line',
  })

  const steps: Step<IPowerConsumptionWidget>[] = [
    {
      title: 'Chart Type',
      description: 'Select chart style for data display',
      component: Step1,
      validation: (data) => data.visualization === undefined,
    },
    {
      title: 'Production Scope',
      description: 'Choose a production line or section to monitor',
      component: Step2,
      validation: (data) => data.entity_group === undefined || data.entity === undefined,
    },
    {
      title: 'Timeframe',
      description: 'Specify the data range',
      component: Step3,
      validation: (data) =>
        (data.start_time === undefined && data.dynamic_time_range === undefined) ||
        ((!!data.start_time || !!data.end_time) && !!data.dynamic_time_range),
    },
    {
      title: 'Data Aggregation',
      description: 'Set data grouping interval',
      component: Step4,
    },
    {
      title: 'Name',
      description: 'Finalize the widget',
      component: Step5,
      validation: (data) => data.name === undefined,
    },
  ]

  const submit = async (data: IPowerConsumptionWidget) => {
    data.dashboard = activeDashboardId!
    data.data_types =
      data.visualization === 'line' ? ['Power Consumption'] : ['Power Consumption Column']
    const widget = data._id ? await updateWidget(data._id, data) : await createWidget(data)
    await queryClient.invalidateQueries({queryKey: 'widgets'})
    return widget
  }

  return (
    <StepModalWrapper<IPowerConsumptionWidget, Widget>
      id='power_consumption_widget_modal'
      title={`${data._id ? 'Update' : 'Create'} ${getDisplayName('Power Consumption')} Widget`}
      show={show}
      close={hide}
      cancel={cancel}
      steps={steps}
      data={data}
      defaultData={defaultCreatePowerConsumptionWidget}
      setData={setData}
      submitData={submit}
    />
  )
}

export default PowerConsumptionWidgetModal
