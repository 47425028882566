import {Column} from 'react-table'
import {User} from '../../../core/_models'
import {deleteInvitation} from '../../../core/requests/auth'
import useEntityDeleteModal from '../../../modals/entity-delete/useEntityDeleteModal'
import {BadgeCell} from '../../overview/core/columns/BadgeCell'
import {CustomHeader} from '../../overview/core/columns/CustomHeader'
import {SelectionCell} from '../../overview/core/columns/SelectionCell'
import {SelectionHeader} from '../../overview/core/columns/SelectionHeader'
import {StringCell} from '../../overview/core/columns/StringCell'

const Columns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} tableId='hs_organization_table' />,
    id: 'selection',
    Cell: ({...props}) => {
      const profile = props.data[props.row.index]
      const isInvitation = !profile.current_role
      return !isInvitation ? (
        <SelectionCell id={props.data[props.row.index].email} tableId='hs_organization_table' />
      ) : null
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    id: 'email',
    Cell: ({...props}) => <StringCell str={props.data[props.row.index].email} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Role' className='min-w-125px' />,
    id: 'role',
    Cell: ({...props}) => {
      const user = props.data[props.row.index]
      return <BadgeCell str={user.role ? user.role.name : 'Invitation sent'} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Role' className='min-w-125px' />,
    id: 'actions',
    Cell: ({...props}) => {
      const profile = props.data[props.row.index]
      const {handler} = useEntityDeleteModal({
        entity: profile,
        deleteHandler: deleteInvitation,
        queryToInvalidate: ['invitations'],
        message: `Confirm to cancel invitation for ${profile.email}`,
      })
      const isInvitation = !profile.role
      return isInvitation ? handler : null
    },
  },
]

export {Columns}
