import {useState} from 'react'
import FileUploadButton from '../../../../app/components/uploader/FileUploadButton'
import {uploadBatch} from '../../../../app/core/requests/oee'
import {KTSVG} from '../../../helpers'
import CreateBatchTemplateModal from '../../../../app/pages/batch-templates/CreateTemplateModal'
import ProductsModal from '../../../../app/pages/planner/ProductsModal'
import {useCreateModals} from '../../../../app/modals/CreateModalsProvider'

const PlannerToolbar = () => {
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
  const [showProductsModal, setShowProductsModal] = useState(false)

  const {openPlannerSetupModal} = useCreateModals()

  return (
    <>
      <div className='d-flex align-items-center gap-3'>
        <FileUploadButton
          uploadHandler={uploadBatch}
          className={`btn-sm`}
          contentType={`application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`}
        />
        <a className='btn btn-sm fw-bold btn-secondary' onClick={openPlannerSetupModal}>
          Setup
        </a>
        <a className='btn btn-sm fw-bold btn-primary' onClick={() => setShowProductsModal(true)}>
          Products
        </a>
        <a
          className='btn btn-sm fw-bold btn-primary'
          onClick={() => setShowCreateTemplateModal(true)}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add Template
        </a>
      </div>
      <CreateBatchTemplateModal
        show={showCreateTemplateModal}
        hide={() => setShowCreateTemplateModal(false)}
      />
      <ProductsModal show={showProductsModal} hide={() => setShowProductsModal(false)} />
    </>
  )
}

export {PlannerToolbar}
