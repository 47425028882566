import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {Checklist} from '../../../gql/graphql'
import LoadingWrapper from '../../components/LoadingWrapper'
import {KTSVG} from '../../../_metronic/helpers'

const modalsRoot = document.getElementById('root-modals') || document.body

type Props = {
  checklists?: Checklist[]
  show: boolean
  handleClose: () => void
  handleCreateWorkOrder: () => void
}

const AssetChecklistModal = ({checklists, show, handleClose, handleCreateWorkOrder}: Props) => {
  return createPortal(
    <Modal
      id='hs_asset_checklist_modal'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='xl'
      show={show}
      onHide={handleClose}
      backdrop='static'
    >
      <div className='modal-header'>
        <h2>Asset Checklist</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body'>
        <LoadingWrapper loading={checklists === undefined}>
          {checklists && checklists.length === 0 && (
            <span className='fs-4 text-gray-800'>No faults detected for this asset.</span>
          )}
          {checklists &&
            checklists.length > 0 &&
            checklists.map((checklist, idx) => <ChecklistCard checklist={checklist} idx={idx} />)}
        </LoadingWrapper>
      </div>
      <div className='modal-footer'>
        <button
          id='submit'
          type='button'
          className='btn btn-primary'
          onClick={handleCreateWorkOrder}
          disabled={checklists === undefined || !checklists.length}
        >
          Create Work Order
        </button>
      </div>
    </Modal>,
    modalsRoot
  )
}

const ChecklistCard = ({checklist, idx}: {checklist: Checklist; idx: number}) => {
  return (
    <div className={`${idx > 0 ? 'mt-5' : ''}`}>
      <div className='card border'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='fw-bold'>{checklist.partType}</h3>
          </div>
        </div>
        <div className='card-body py-3'>
          <span className='fs-6 text-gray-800 mb-5'>
            {`For the ${checklist.partType} we have detected symptoms of the following faults: `}
            <span className='fw-bold'>{checklist.potentialFaults.join(', ')}</span>
            {`.`}
          </span>
          {checklist.actions.map((action, idx) => (
            <div className={`row ${idx > 0 ? 'mt-2' : 'mt-5'}`} key={idx}>
              <span className='fw-bold fs-6 text-gray-800'>{`- ${action}`}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AssetChecklistModal
