import {Column} from 'react-table'
import {StringCell} from '../core/columns/StringCell'
import {SelectionCell} from '../core/columns/SelectionCell'
import {CustomHeader} from '../core/columns/CustomHeader'
import {SelectionHeader} from '../core/columns/SelectionHeader'
import {Part} from '../../../core/_models'
import {BadgeCell} from '../core/columns/BadgeCell'
import {NameImageCell} from '../core/columns/ImageStringCell'
import ProductionLineCell from '../core/columns/ProductionLineCell'

const Columns: ReadonlyArray<Column<Part>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} tableId='hs_parts_table' />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index]._id} tableId='hs_parts_table' />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Manufacturer' className='min-w-125px' />
    ),
    id: 'manufacturer',
    Cell: ({...props}) => (
      <NameImageCell
        str={props.data[props.row.index].manufacturer || '-'}
        images={
          props.data[props.row.index].gallery
            ? [
                props.data[props.row.index].gallery!.profile_image,
                ...props.data[props.row.index].gallery!.images,
              ]
            : undefined
        }
        index={props.row.index}
        entityId={props.data[props.row.index]._id}
        queryToInvalidate='parts'
      />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Model Number' className='min-w-125px' />
    ),
    id: 'modelNumber',
    Cell: ({...props}) => <StringCell str={props.data[props.row.index].model_number || '-'} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Type' className='min-w-125px' />,
    id: 'status',
    Cell: ({...props}) =>
      props.data[props.row.index].type ? (
        <BadgeCell str={props.data[props.row.index].type || ''} />
      ) : (
        <StringCell str='-' />
      ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Production Line' className='min-w-125px' />
    ),
    id: 'productionLine',
    Cell: ({...props}) =>
      props.data[props.row.index].production_line ? (
        <ProductionLineCell
          productionLineReference={props.data[props.row.index].production_line!}
        />
      ) : (
        <StringCell str='-' />
      ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Section' className='min-w-125px' />,
    id: 'section',
    Cell: ({...props}) => <StringCell str={props.data[props.row.index].section?.name || '-'} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Asset' className='min-w-125px' />,
    id: 'asset',
    Cell: ({...props}) => <StringCell str={props.data[props.row.index].asset?.name || '-'} />,
  },
]

export {Columns}
