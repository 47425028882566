import {useState} from 'react'
import {entityToString} from '../../../../core/name-util'
import {useFactoryEntities} from '../../../../core/requests/factory'
import {useEventCategories, useEventLogsPaginated} from '../../../../core/requests/oee'
import {rfc3339ToString, startTimeFromIso8601Duration} from '../../../../core/time-util'
import useEventLogsFilter from '../../../work-orders/hooks/useEventLogsFilter'
import {WidgetProps} from './Widget'
import useModalState from '../../../../hooks/UseModalState'
import {EventCategory, OeeEventLog, SubEventCategory} from '../../../../core/_models'
import LogEventModal from '../../../../modals/log-event/LogEventModal'
import {mapEventCategories} from '../../components/charts/ProductionStatusTimelineGraph'

const EventLogsWidget = ({widget}: WidgetProps) => {
  const [selectedEventLog, setSelectedEventLog] = useState<OeeEventLog>()
  const [selectedEventLogCategory, setSelectedEventLogCategory] = useState<
    EventCategory | SubEventCategory
  >()
  const eventModal = useModalState()

  const {entity, entitySelect, categories, categoriesSelect} = useEventLogsFilter({
    entity: widget.entity,
    categories: widget.operational_event_categories,
  })

  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const {
    items: eventLogs,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useEventLogsPaginated({
    entities: entity ? [entity] : undefined,
    start_time: startTime,
    end_time: endTime,
    order: '-time',
  })

  const {data: eventCategories} = useEventCategories({
    ids: Array.from(categories),
  })
  const categoryMap = mapEventCategories(eventCategories?.items || [])

  const {data: factoryEntities} = useFactoryEntities({
    entityIds: Array.from(new Set(eventLogs?.map((eventLog) => eventLog.entity) || [])),
    options: {enabled: eventLogs !== undefined && eventLogs.length > 0},
  })

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - Math.ceil(e.currentTarget.scrollTop) ===
      e.currentTarget.clientHeight

    if (bottom && !isFetching && hasNextPage) {
      await fetchNextPage()
    }
  }

  return (
    <>
      <div className='d-flex flex-column align-items-center justify-content-center h-100'>
        <div className={`d-flex flex-row py-5`}>
          {entitySelect}
          {categoriesSelect}
        </div>
        <div className='overflow-scroll w-100 px-5' onScroll={handleScroll}>
          {eventLogs?.map((eventLog) => {
            const category = categoryMap.get(eventLog.categories.at(-1) || '')
            const entity = factoryEntities?.items.find(
              (entity) => entity.entity._id === eventLog.entity
            )
            if (!category || !entity) return null

            return (
              <div
                className='d-flex flex-row justify-content-between border-bottom cursor-pointer py-1 bg-hover-secondary'
                key={eventLog._id}
                onClick={() => {
                  setSelectedEventLog(eventLog)
                  setSelectedEventLogCategory(category)
                  eventModal.open()
                }}
              >
                <div className='flex-grow-1'>
                  <div className='d-flex align-items-center'>
                    <div
                      className='me-2 shadow'
                      style={{
                        backgroundColor: category.color,
                        width: '1em',
                        height: '1em',
                        borderRadius: '0.25em',
                      }}
                    />
                    <div className='fw-bold me-1'>{category.title}</div>
                    <i className={`${category.icon}`} />
                  </div>
                </div>
                <div className='text-nowrap pe-10'>{entityToString(entity)}</div>
                <div className='text-nowrap w-125px'>{rfc3339ToString(eventLog.utc_timestamp)}</div>
              </div>
            )
          })}
          {eventLogs?.length === 0 && (
            <div className='d-flex justify-content-center'>No event logs found</div>
          )}
        </div>
      </div>

      {selectedEventLog && (
        <LogEventModal
          isEditMode
          show={eventModal.isOpen}
          handleClose={() => {
            setSelectedEventLog(undefined)
            eventModal.close()
          }}
          productionLineId={selectedEventLog.entity}
          eventLogToEdit={selectedEventLog}
          categoryOfEventLogToEdit={selectedEventLogCategory}
        />
      )}
    </>
  )
}

export default EventLogsWidget
