import {useSelectedRows} from '../../../../providers/SelectedRowsProvider'

type Props = {
  id: string
}

const ExpandCell = ({id}: Props) => {
  const {expanded, onExpand} = useSelectedRows()

  return (
    <div
      className='btn btn-icon w-30px h-30px'
      onClick={() => onExpand(expanded === id ? undefined : id)}
    >
      {expanded !== id && <i className='fa-solid fs-3 fa-chevron-right' />}
      {expanded === id && <i className='fa-solid fs-3 fa-chevron-down' />}
    </div>
  )
}

export {ExpandCell}
