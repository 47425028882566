import LoadingWrapper from '../../../components/LoadingWrapper'
import SelectField from '../../../components/form-components/SelectField'
import {
  Asset,
  EntityGroup,
  EntityGroupPretty,
  Gateway,
  Part,
  Placement,
  ProductionLine,
  Section,
  Sensor,
  entityGroups,
  entityGroupsPretty,
} from '../../../core/_models'
import {
  entityToString,
  gatewayToString,
  partToString,
  placementToString,
  sensorToString,
} from '../../../core/name-util'
import {
  useAssets,
  useFactoryEntities,
  useGateways,
  useParts,
  usePlacements,
  useProductionLines,
  useSections,
  useSensors,
} from '../../../core/requests/factory'
import {StepProps} from './_models'
import {KTSVG} from '../../../../_metronic/helpers'

const ENTITY_NAMES: EntityGroupPretty[] = entityGroupsPretty.filter(
  (entityGroup) => entityGroup !== 'Factory' && entityGroup !== 'Area'
)
const ENTITY_VALUES: EntityGroup[] = entityGroups.filter(
  (entityGroup) => entityGroup !== 'factory' && entityGroup !== 'area'
)

const Step2 = ({data, updateData, hasError}: StepProps) => {
  const {data: productionLines, isFetching: productionLinesFetching} = useProductionLines({
    options: {enabled: data.selected_entity_type === 'production_line'},
  })
  const {data: sections, isFetching: sectionsFetching} = useSections({
    options: {enabled: data.selected_entity_type === 'section'},
  })
  const {data: assets, isFetching: assetsFetching} = useAssets({
    options: {enabled: data.selected_entity_type === 'asset'},
  })
  const {data: parts, isFetching: partsFetching} = useParts({
    options: {enabled: data.selected_entity_type === 'part'},
  })
  const {data: placements, isFetching: placementsFetching} = usePlacements({
    options: {enabled: data.selected_entity_type === 'placement'},
  })
  const {data: sensors, isFetching: sensorsFetching} = useSensors({
    options: {enabled: data.selected_entity_type === 'sensor'},
  })
  const {data: gateways, isFetching: gatewaysFetching} = useGateways({
    options: {enabled: data.selected_entity_type === 'gateway'},
  })

  const {data: factoryEntities, isLoading: factoryEntitiesLoading} = useFactoryEntities({
    entityIds: data.factory_entities,
    options: {enabled: data.factory_entities !== undefined},
  })

  const entities =
    data.selected_entity_type === 'production_line'
      ? productionLines?.items
      : data.selected_entity_type === 'section'
      ? sections?.items
      : data.selected_entity_type === 'asset'
      ? assets?.items
      : data.selected_entity_type === 'part'
      ? parts?.items
      : data.selected_entity_type === 'placement'
      ? placements?.items
      : data.selected_entity_type === 'sensor'
      ? sensors?.items
      : data.selected_entity_type === 'gateway'
      ? gateways?.items
      : undefined

  const filteredEntities = (
    entities as
      | Array<ProductionLine | Section | Asset | Part | Placement | Sensor | Gateway>
      | undefined
  )?.filter((entity) => {
    if (data.factory_entities === undefined) return true
    else return !data.factory_entities.find((factoryEntity) => factoryEntity === entity._id)
  })

  const entity_type: EntityGroup | undefined =
    data.selected_entity_type === 'production_line'
      ? 'production_line'
      : data.selected_entity_type === 'section'
      ? 'section'
      : data.selected_entity_type === 'asset'
      ? 'asset'
      : data.selected_entity_type === 'part'
      ? 'part'
      : data.selected_entity_type === 'placement'
      ? 'placement'
      : data.selected_entity_type === 'sensor'
      ? 'sensor'
      : data.selected_entity_type === 'gateway'
      ? 'gateway'
      : undefined

  const loadingEntities =
    productionLinesFetching ||
    sectionsFetching ||
    assetsFetching ||
    partsFetching ||
    placementsFetching ||
    sensorsFetching ||
    gatewaysFetching

  return (
    <div data-kt-stepper-element='content'>
      <LoadingWrapper loading={factoryEntitiesLoading}>
        <div className='w-100'>
          <div className={`d-flex w-100`}>
            <div className={`w-50 px-2`}>
              <SelectField
                name='Entity Type'
                data={data}
                updateData={updateData}
                dataField='selected_entity_type'
                hasError={hasError}
                optionNames={ENTITY_NAMES}
                optionValues={ENTITY_VALUES}
                updatesOnChange={() => ({factory_entity: undefined})}
              />
            </div>
            <div className={`w-50 px-2`}>
              {data.selected_entity_type !== undefined && (
                <LoadingWrapper loading={loadingEntities}>
                  <SelectField
                    name='Entity'
                    data={data}
                    updateData={(partialData) => {
                      if (partialData['factory_entity']) {
                        updateData({
                          factory_entities: [
                            ...(data.factory_entities ?? []),
                            partialData['factory_entity'],
                          ],
                        })
                      }
                    }}
                    dataField='factory_entity'
                    hasError={hasError}
                    optionNames={
                      filteredEntities?.map((entity) =>
                        entity_type === 'part'
                          ? partToString(entity as Part)
                          : entity_type === 'placement'
                          ? placementToString(entity as Placement)
                          : entity_type === 'sensor'
                          ? sensorToString(entity as Sensor)
                          : entity_type === 'gateway'
                          ? gatewayToString(entity as Gateway)
                          : (entity as Asset).name
                      ) ?? []
                    }
                    optionValues={filteredEntities?.map((entity) => entity._id) ?? []}
                  />
                </LoadingWrapper>
              )}
            </div>
          </div>

          <hr className='bg-gray-300 mb-10' />

          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Added Entities</span>
          </label>
          {factoryEntities?.items.map((factoryEntity, index) => {
            const entityName = entityToString(factoryEntity)
            return (
              <div className='d-flex align-items-center' key={index}>
                <button
                  type='button'
                  className='btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger me-2 mb-2'
                  onClick={() => {
                    const updatedEntities = [...(data.factory_entities || [])]
                    updatedEntities.splice(index, 1)
                    updateData({
                      factory_entities: updatedEntities,
                    })
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1' />
                </button>
                <div>{entityName}</div>
              </div>
            )
          })}
          {(!data.factory_entities || data.factory_entities.length === 0) && hasError && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>'Added Entities is required!'</div>
            </div>
          )}
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step2
