import {useEffect, useRef, useState} from 'react'
import Select, {MultiValue} from 'react-select'
import {usePlacementsPaginated} from '../../../core/requests/factory'
import {placementToString} from '../../../core/name-util'

type Props = {
  initial?: string[]
  name?: string
  onchange: (values: string[]) => void
  errorOccurred?: boolean
  className?: string
}

const PlacementsSelectField = ({
  initial = [],
  name = 'Placements',
  onchange,
  errorOccurred,
  className,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([])
  const initialOptionsSet = useRef(false)

  const {items: placements, isFetching, fetchNextPage} = usePlacementsPaginated()

  useEffect(() => {
    if (!placements || !initial.length || initialOptionsSet.current) return
    const initialOptions = initial.map((id) => {
      const placement = placements.find((placement) => placement._id === id)
      return {
        value: id,
        label: placement ? placementToString(placement) : 'Unknown placement',
      }
    })
    setSelectedOptions(initialOptions)
    initialOptionsSet.current = true
  }, [placements, initial])

  const handleChange = (selected: MultiValue<OptionType>) => {
    const values = selected.map((option) => option.value)
    onchange(values)
    setSelectedOptions(selected)
  }

  const error = errorOccurred && !selectedOptions.length

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={selectedOptions}
        options={
          placements?.map((placement) => ({
            label: placementToString(placement),
            value: placement._id,
          })) || []
        }
        onChange={handleChange}
        isLoading={isFetching}
        isDisabled={isFetching}
        isMulti
        isClearable
        onMenuScrollToBottom={() => fetchNextPage()}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? 'red' : baseStyles.borderColor,
          }),
        }}
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}
    </>
  )
}

type OptionType = {
  value: string
  label: string
}

export default PlacementsSelectField
