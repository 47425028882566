import React from 'react'
import SelectFieldBoxes from '../../../../../../components/form-components/SelectFieldBoxes'
import SelectFieldTable from '../../../../../../components/form-components/SelectFieldTable'
import LoadingWrapper from '../../../../../../components/LoadingWrapper'
import {useSensorsPaginated} from '../../../../../../core/requests/factory'
import AreaSelect from '../../../../../overview/core/components/AreaSelect'
import AssetSelect from '../../../../../overview/core/components/AssetSelect'
import ProductionLineSelect from '../../../../../overview/core/components/ProductionLineSelect'
import useSensorsFilter from '../../../../../overview/core/hooks/useSensorsFilter'
import {StepProps} from '../_models'

const AreaFilterStep = ({data, updateData, hasError, current}: StepProps) => {
  const {filterState: sensorsFilterState, sensorFilterProps} = useSensorsFilter()

  const {
    items: sensors = [],
    isLoading: sensorsLoading,
    fetchNextPage: sensorsFetchNextPage,
  } = useSensorsPaginated(
    {
      production_line: sensorFilterProps.productionLine,
      ...sensorFilterProps,
    },
    {
      enabled: !!data.entity_group,
    }
  )

  React.useEffect(() => {
    sensorsFilterState.area.setValue(undefined)
    sensorsFilterState.productionLine.setValue(undefined)
    sensorsFilterState.asset.setValue(undefined)
    updateData({entities: [], entity: undefined})
  }, [data.entity_group])

  const filterComponent = React.useMemo(() => {
    let filterComponent
    switch (data.entity_group) {
      case 'area':
        filterComponent = <AreaSelect onChange={sensorsFilterState.area.setValue} />
        break
      case 'production_line':
        filterComponent = (
          <ProductionLineSelect onChange={sensorsFilterState.productionLine.setValue} />
        )
        break
      case 'asset':
        filterComponent = <AssetSelect onChange={sensorsFilterState.asset.setValue} />
        break
      default:
        break
    }
    return filterComponent
  }, [
    data.entity_group,
    sensorsFilterState.area.setValue,
    sensorsFilterState.asset.setValue,
    sensorsFilterState.productionLine.setValue,
  ])

  const SENSOR_COLUMNS = ['Placement', 'ID', 'Types', 'Master']
  const optionData = {
    columns: SENSOR_COLUMNS,
    rows:
      sensors?.map((sensor) => [
        sensor.placement.name,
        sensor.device_id,
        sensor.data_types.join(', '),
        <input
          type='radio'
          name='sensor_master_state'
          value={sensor.placement._id}
          checked={data.entity === sensor.placement._id}
          onChange={(e) => {
            e.stopPropagation()
            updateData({
              entities: data['entities'].includes(e.target.value)
                ? data['entities']
                : [...data['entities'], e.target.value],
              entity: e.target.value,
            })
          }}
        />,
      ]) || [],
    values: sensors?.map((sensor) => sensor.placement._id) || [],
  }

  return (
    <div className={current ? 'current' : undefined} data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectFieldBoxes
          name={'Entity Group'}
          required
          optionNames={['Area', 'Production Line', 'Asset']}
          optionValues={['area', 'production_line', 'asset']}
          data={data}
          dataField='entity_group'
          updateData={updateData}
          updatesOnChange={{entities: [], entity: undefined}}
          hasError={hasError}
        />
        {data.entity_group && (
          <>
            <div className={`mb-5`}>{filterComponent}</div>
            <LoadingWrapper loading={sensorsLoading}>
              {sensors.length ? (
                <SelectFieldTable
                  name='Choose Specific Entity'
                  required
                  columnNames={optionData.columns}
                  optionRows={optionData.rows}
                  optionValues={optionData.values}
                  data={data}
                  dataField='entities'
                  updateData={updateData}
                  updatesOnChange={(newValue) =>
                    data.entity && !newValue?.includes(data.entity)
                      ? {
                          entity: undefined,
                        }
                      : {}
                  }
                  hasError={hasError}
                  height='400px'
                  gotoNextPage={sensorsFetchNextPage}
                />
              ) : (
                'No sensors'
              )}
              {hasError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{`Master is required!`}</div>
                </div>
              )}
            </LoadingWrapper>
          </>
        )}
      </div>
    </div>
  )
}

export default AreaFilterStep
