import {ICreateProductionLine, StepProps} from './_models'
import SelectFieldBoxes from '../../components/form-components/SelectFieldBoxes'
import NumberField from '../../components/form-components/NumberField'
import {useIntl} from 'react-intl'

const Step3 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='d-flex flex-stack mb-10'>
          <div className='me-5 fw-semibold'>
            <label className='fs-5'>Auto Start Detection</label>
            <div className='fs-7 text-muted'>
              Should the system automatically start the production line when a product is observed?
            </div>
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={data.auto_start}
              onChange={(e) =>
                updateData({auto_start: e.target.checked} as Partial<ICreateProductionLine>)
              }
            />
            <span className='form-check-label fw-semibold text-muted'>Enabled</span>
          </label>
        </div>

        <div className='d-flex flex-stack mb-5'>
          <div className='me-5 fw-semibold'>
            <label className='fs-5'>Auto Stop Detection</label>
            <div className='fs-7 text-muted'>
              Should the system automatically stop the production line when no new products are
              observed?
            </div>
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={data.auto_stop}
              onChange={(e) =>
                updateData({auto_stop: e.target.checked} as Partial<ICreateProductionLine>)
              }
            />
            <span className='form-check-label fw-semibold text-muted'>Enabled</span>
          </label>
        </div>

        {data.auto_stop && (
          <NumberField
            name='Auto Stop Detection Tolerance (minutes)'
            data={data}
            updateData={updateData}
            dataField='auto_stop_detection_tolerance'
            lastField
            tooltip={intl.formatMessage({id: 'TOOLTIP.AUTO_STOP_DETECTION'})}
          />
        )}
      </div>
    </div>
  )
}

export default Step3
