import LoadingWrapper from '../../components/LoadingWrapper'
import {StepProps} from './_models'
import SelectField from '../../components/form-components/SelectField'
import {useIntl} from 'react-intl'
import {
  useAssetsPaginated,
  useProductionLinesPaginated,
  useSectionsPaginated,
} from '../../core/requests/factory'

const Step2 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  const {
    items: productionLines,
    isLoading: productionLinesLoading,
    isFetching: productionLinesFetching,
    fetchNextPage: productionLinesNextPage,
  } = useProductionLinesPaginated()

  const {
    items: sections,
    isLoading: sectionsLoading,
    isFetching: sectionsFetching,
    fetchNextPage: sectionsNextPage,
  } = useSectionsPaginated()

  const {
    items: assets,
    isLoading: assetsLoading,
    isFetching: assetsFetching,
    fetchNextPage: assetsNextPage,
  } = useAssetsPaginated()

  return (
    <div data-kt-stepper-element='content'>
      <LoadingWrapper loading={productionLinesLoading || sectionsLoading || assetsLoading}>
        <div className='w-100'>
          <div className='fv-row'>
            {/* Production Line */}
            {data.locationChoice === 'production_line' && (
              <SelectField
                name='Production Line'
                optionNames={productionLines?.map((productionLine) => productionLine.name) || []}
                optionValues={productionLines?.map((productionLine) => productionLine._id) || []}
                data={data}
                updateData={updateData}
                dataField='production_line'
                lastField
                hasError={hasError}
                required
                tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.PRODUCTION_LINE'})}
                isLoading={productionLinesFetching}
                gotoNextPage={productionLinesNextPage}
              />
            )}

            {/* Section */}
            {data.locationChoice === 'section' && (
              <SelectField
                name='Section'
                optionNames={sections?.map((section) => section.name) || []}
                optionValues={sections?.map((section) => section._id) || []}
                data={data}
                updateData={updateData}
                dataField='section'
                lastField
                hasError={hasError}
                required
                tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.SECTION'})}
                isLoading={sectionsFetching}
                gotoNextPage={sectionsNextPage}
              />
            )}

            {/* Asset */}
            {data.locationChoice === 'asset' && (
              <SelectField
                name='Asset'
                optionNames={assets?.map((asset) => asset.name) || []}
                optionValues={assets?.map((asset) => asset._id) || []}
                data={data}
                updateData={updateData}
                dataField='asset'
                lastField
                hasError={hasError}
                required
                tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.ASSET'})}
                isLoading={assetsFetching}
                gotoNextPage={assetsNextPage}
              />
            )}
          </div>
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step2
