import React from 'react'
import {UseQueryResult} from 'react-query'
import usePagination from './usePagination'
import {BaseModel, PaginatedQueryProps, PaginatedResponse} from '../../core/_models'

type UseQueryType<
  T extends BaseModel = BaseModel,
  P extends PaginatedQueryProps<T> | undefined = PaginatedQueryProps<T>
> = (props?: P) => UseQueryResult<PaginatedResponse<T>, Error>

type UsePaginatedQueryProps<
  T extends BaseModel,
  P extends PaginatedQueryProps<T> | undefined = PaginatedQueryProps<T>
> = {
  useQuery: UseQueryType<T, P>
  props?: P
}

const usePaginatedQuery = <T extends BaseModel, Q extends UseQueryType<T> = UseQueryType<T>>({
  useQuery,
  props,
}: UsePaginatedQueryProps<T, Parameters<Q>[0]>) => {
  const pagination = usePagination({
    size: props?.size,
  })
  const [items, setItems] = React.useState<T[]>([])

  const {
    isLoading,
    isFetching,
    data: response,
    isRefetching,
  } = useQuery({
    page: pagination.activePage,
    size: pagination.pageSize,
    ...props,
    options: {
      keepPreviousData: true,
      ...props?.options,
      onSuccess: (response) => {
        pagination.setActivePage(response.page)
        setItems([...items.filter((i) => !response.items.includes(i)), ...response.items])
        if (props?.options?.onSuccess) {
          props?.options?.onSuccess(response)
        }
      },
    },
  })

  React.useEffect(() => {
    if (isRefetching) {
      setItems([])
    }
  }, [isRefetching])

  const hasMore =
    response?.total && !isLoading
      ? pagination.activePage * pagination.pageSize < response.total
      : false

  const gotoNextPage = () => {
    if (hasMore) {
      pagination.setActivePage(pagination.activePage + 1)
    }
  }

  return {
    isLoading,
    isFetching,
    items,
    hasMore,
    gotoNextPage,
  }
}

export default usePaginatedQuery
