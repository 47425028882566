import React from 'react'
import NumberField from '../../../../../../components/form-components/NumberField'
import {StepProps} from '../_models'

const RangesStep = ({data, updateData, hasError, current}: StepProps) => (
  <div className={current ? 'current' : undefined} data-kt-stepper-element='content'>
    <div className='w-100'>
      <div className='row'>
        <div className='col-6'>
          <NumberField
            name='Running Minimum'
            data={data}
            updateData={updateData}
            dataField={'running_minimum'}
            required={true}
          />
        </div>
        <div className='col-6'>
          <NumberField
            name='Running Maximum'
            data={data}
            updateData={updateData}
            dataField={'running_maximum'}
            required={true}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <NumberField
            name='Standby Minimum'
            data={data}
            updateData={updateData}
            dataField={'standby_minimum'}
            required={true}
          />
        </div>
        <div className='col-6'>
          <NumberField
            name='Standby Maximum'
            data={data}
            updateData={updateData}
            dataField={'standby_maximum'}
            required={true}
          />
        </div>
      </div>
      {data.operational_states?.includes('shutdown') ? (
        <div className='row'>
          <div className='col-6'>
            <NumberField
              name='Shutdown Minimum'
              data={data}
              updateData={updateData}
              dataField={'shutdown_minimum'}
              lastField
              required={true}
            />
          </div>
          <div className='col-6'>
            <NumberField
              name='Shutdown Maximum'
              data={data}
              updateData={updateData}
              dataField={'shutdown_maximum'}
              lastField
              required={true}
            />
          </div>
        </div>
      ) : null}
      {hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`Need to set minimum values`}</div>
        </div>
      )}
    </div>
  </div>
)

export default RangesStep
