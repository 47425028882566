import {useQuery} from 'react-query'
import {graphql} from '../../../../../gql'
import useConditionClient from '../UseConditionClient'

const getAssetStatesWithVariablesQueryDocument = graphql(`
  query GetAssetStates($ids: [ID!]) {
    getAssetStates(ids: $ids) {
      id
      firstStateAt
      lastStateAt
      lastStateChangeAt
      title
    }
  }
`)

interface Props {
  ids?: string[] | null
}

const useGetAssetStates = ({ids = null}: Props = {}) => {
  const client = useConditionClient()
  return useQuery({
    queryKey: ['condition.GetAssetStates', ...ids],
    queryFn: async () =>
      (
        await client.request(getAssetStatesWithVariablesQueryDocument, {
          ids,
        })
      ).getAssetStates,
    enabled: ids === null || !!ids.length,
  })
}

export default useGetAssetStates
