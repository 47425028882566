import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import Pagination from '../../../components/pagination/Pagination'
import {getAssetStatus} from '../../../core/asset-util'
import useGetAssetStates from '../../../core/graphql/condition/queries/GetAssetStates'
import {deleteAsset, useAssets} from '../../../core/requests/factory'
import useGridSwitch from '../../../hooks/UseGridSwitch'
import {useCreateModals} from '../../../modals/CreateModalsProvider'
import {AssetOverviewDetailsModal} from '../../../modals/overview/AssetOverviewDetailsModal'
import {useSelectedRows} from '../../../providers/SelectedRowsProvider'
import {TableBody} from '../core/columns/TableBody'
import {TableSelectionToolbar} from '../core/columns/TableSelectionToolbar'
import {TableToolbar} from '../core/columns/TableToolbar'
import AreaSelect from '../core/components/AreaSelect'
import ListFilter from '../core/components/ListFilter'
import OrganizationSelect from '../core/components/OrganizationSelect'
import {OverviewGridItem} from '../core/components/overview/OverviewGridItem'
import SectionSelect from '../core/components/SectionSelect'
import useAssetsFilter from '../core/hooks/useAssetsFilter'
import {Columns} from './Columns'

export const AssetsWrapper = () => {
  const {isGridView} = useGridSwitch()

  const {selected, setDataItems} = useSelectedRows()
  const {openCreateAssetModal} = useCreateModals()
  const {filterState, filterCount, activePage, setActivePage, assetFilterProps} = useAssetsFilter()
  const {data: assets, isLoading: assetsLoading} = useAssets(assetFilterProps)
  const {data: healths = []} = useGetAssetStates({ids: assets?.items.map((a) => a._id) ?? []})

  const items = React.useMemo(
    () =>
      assets?.items.map((asset) => {
        asset.health = healths.find((a) => asset._id === a.id)
        return asset
      }),
    [assets?.items, healths]
  )

  React.useEffect(() => {
    if (items !== undefined) {
      setDataItems(items)
    }
  }, [items])

  const columns = React.useMemo(() => Columns, [])

  const [uuid, setUUID] = React.useState<string>()
  const selectedAsset = items?.find((a) => a._id === uuid)

  return (
    <KTCard className={`${isGridView ? 'bg-transparent' : undefined}`}>
      <div
        className={`card-header border-0 justify-content-end pt-6 bg-white ${
          isGridView ? 'mb-5 pb-5 rounded' : ''
        }`}
      >
        {/* <ListSearchComponent /> */}
        <div className='card-toolbar'>
          <ListFilter
            count={filterCount}
            filters={[
              <OrganizationSelect onChange={filterState.organization.setValue} />,
              <AreaSelect onChange={filterState.area.setValue} />,
              <SectionSelect onChange={filterState.section.setValue} />,
            ]}
          />

          {/* Standard toolbar */}
          {selected.length === 0 && (
            <TableToolbar buttonText='Add' onButtonClick={openCreateAssetModal} />
          )}

          {/* Selection toolbar */}
          {selected.length > 0 && (
            <TableSelectionToolbar onDelete={deleteAsset} queryKey='assets' />
          )}
        </div>
      </div>

      {isGridView ? (
        <div className={`row g-6 g-xl-9 mb-4`} id='hs_assets_table'>
          {items?.map((item) => (
            <OverviewGridItem
              item={item}
              title={item.name}
              description={`${item.area.name} - ${item.factory.name}`}
              actions={
                item.health ? (
                  <div className={`badge bg-state-${item.health.title.toLowerCase()}`}>
                    {getAssetStatus(item.health)}
                  </div>
                ) : null
              }
              imageUrl={item.gallery?.profile_image.thumbnail_url}
              onClick={setUUID}
            />
          ))}
        </div>
      ) : (
        <TableBody
          tableId='hs_assets_table'
          columns={columns}
          dataList={assets?.items}
          loading={assetsLoading}
          onCellClick={(uuid) => setUUID(uuid)}
        />
      )}
      {assets?.total ? (
        <Pagination
          page={activePage}
          total={assets.total}
          size={assets.size}
          onClick={(p) => setActivePage(p)}
        />
      ) : null}

      {selectedAsset && (
        <AssetOverviewDetailsModal entity={selectedAsset} onHide={() => setUUID(undefined)} />
      )}
    </KTCard>
  )
}
