import {useQueryClient} from 'react-query'
import {Modal} from 'react-bootstrap'
import {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {InitateBatchProps, initiateBatch} from '../../core/requests/oee'

type Props = {
  show: boolean
  handleClose: () => void
  batchId: string
}

const BatchStartModal = ({show, handleClose, batchId}: Props) => {
  const [starting, setStarting] = useState(false)

  const queryClient = useQueryClient()

  const handleStart = async (props: InitateBatchProps) => {
    setStarting(true)
    try {
      await initiateBatch(props)
      queryClient.invalidateQueries('batches')
    } catch (error) {
      console.error(error)
    } finally {
      setStarting(false)
      handleClose()
    }
  }

  return (
    <Modal
      show={show}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      onHide={handleClose}
      backdrop={starting ? 'static' : true}
    >
      <div className='modal-header pb-0 border-0 justify-content-end'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body mx-5 mx-xl-18 pt-0'>
        <h1 className='text-center mb-8'>Start Batch</h1>

        <div className='d-flex flex-center pt-5'>
          <button className='btn btn-light me-3' onClick={handleClose}>
            Cancel
          </button>
          <button className='btn btn-primary' onClick={() => handleStart({batchId})}>
            Confirm Start
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default BatchStartModal
