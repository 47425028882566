import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../_metronic/helpers'

interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  optionNames: string[]
  optionValues: string[]
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  updatesOnChange?: Partial<DataType>
}

const COLOR_THEMES = ['primary', 'success', 'info', 'warning', 'danger', 'dark']

const MultiSelectFieldSymbols = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  optionNames,
  optionValues,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
  updatesOnChange,
}: Props<DataType>) => {
  const [selectValue, setSelectValue] = useState<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newData = !data[dataField] ? [] : data[dataField]
    updateData({
      [dataField]: [...newData, event.target.value],
      ...(updatesOnChange || {}),
    } as Partial<DataType>)
  }

  const handleSymbolClick = (index: number) => {
    const emptyAfterRemoval = (data[dataField] as string[]).length === 1
    updateData({
      [dataField]: emptyAfterRemoval
        ? undefined
        : (data[dataField] as string[]).filter((_, i) => i !== index),
      ...(updatesOnChange || {}),
    } as Partial<DataType>)
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>
        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>
      <div className='symbol-group symbol-hover'>
        {data[dataField] &&
          (data[dataField] as string[]).map((value, index) => (
            <div
              className='symbol symbol-45px symbol-circle'
              key={index}
              onClick={() => handleSymbolClick(index)}
            >
              <span
                className={`symbol-label bg-light-${
                  COLOR_THEMES[index % COLOR_THEMES.length]
                } text-${COLOR_THEMES[index % COLOR_THEMES.length]} 40px`}
                // className={`symbol-label bg-light-primary text-primary 40px`}
                title={value}
              >
                {value.charAt(0).toUpperCase()}
              </span>
            </div>
          ))}
        <div
          className='symbol symbol-45px symbol-circle'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom'
        >
          <span className='symbol-label'>
            <i className='fa-solid fs-3 fa-plus' />
          </span>
        </div>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-250px'
          data-kt-menu='true'
          id='add_assignee_menu'
        >
          <div className='menu-item px-3 mt-2'>
            <select
              className='form-select form-select-solid'
              data-hide-search='true'
              name={dataField}
              value={selectValue}
              onChange={handleChange}
            >
              <option value=''>{`Select ${name.toLowerCase()}...`}</option>
              {optionValues
                .filter((optionValue) => !data[dataField]?.includes(optionValue))
                .map((optionValue, index) => (
                  <option key={index} value={optionValue}>
                    {optionNames[optionValues.indexOf(optionValue)]}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultiSelectFieldSymbols
