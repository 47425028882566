import SelectField from '../../../../../components/form-components/SelectField'
import {workOrderCategories, workOrderStatuses} from '../../../../../core/_models'
import {useAssetsPaginated, useProductionLinesPaginated} from '../../../../../core/requests/factory'
import {StepProps} from './_models'
import Select from 'react-select'

const FiltersStep = ({data, updateData, hasError}: StepProps) => {
  const {
    items: assets,
    isLoading: isAssetsLoading,
    fetchNextPage: fetchNextAssetPage,
  } = useAssetsPaginated()
  const {
    items: productionLines,
    isLoading: isProductionLinesLoading,
    fetchNextPage: fetchNextProductionLinePage,
  } = useProductionLinesPaginated()

  const entityOptions = [...(productionLines || []), ...(assets || [])]
  const selectedOptions = entityOptions
    .filter((option) => data.entities.includes(option._id))
    .map((option) => ({
      value: option._id,
      label: option.name,
    }))

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='mb-10'>
          <h2 className='fw-bold d-flex align-items-center text-dark'>
            Configure Default Filter Options
          </h2>
          <div className='text-muted fw-semibold fs-6'>
            Customize your Work Orders widget by setting up default filters. Choose the categories,
            entities, and status types you want to focus on. These filters will define the initial
            view of your widget. You can always temporarily adjust these filters while using the
            widget. However, any changes made will revert back to your default settings upon
            reloading the dashboard.
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span>Entities</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Select any entities you want to focus on.'
            />
          </label>
          <Select
            placeholder='Entities'
            isMulti
            options={entityOptions.map((entity) => ({
              value: entity._id,
              label: entity.name,
            }))}
            onChange={(e) => updateData({entities: e.map((entity) => entity.value) || []})}
            value={selectedOptions}
            isLoading={isProductionLinesLoading || isAssetsLoading}
            onMenuScrollToBottom={() => {
              fetchNextAssetPage()
              fetchNextProductionLinePage()
            }}
          />
        </div>

        <div className='row'>
          <div className='col'>
            <SelectField
              name='Category'
              optionNames={workOrderCategories}
              optionValues={workOrderCategories}
              data={data}
              updateData={updateData}
              dataField='category'
              tooltip='Select a category to focus on.'
              lastField
            />
          </div>

          <div className='col'>
            <SelectField
              name='Status'
              optionNames={workOrderStatuses}
              optionValues={workOrderStatuses}
              data={data}
              updateData={updateData}
              dataField='status'
              tooltip='Select a status type to focus on.'
              lastField
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FiltersStep
