import {useState} from 'react'
import StepModalWrapper, {Step} from '../../components/StepModalWrapper'
import {Section} from '../../core/_models'
import {useCreateModals} from '../CreateModalsProvider'
import Step1 from './Step1'
import {defaultCreateSection, ICreateSection} from './_models'
import {createSection} from '../../core/requests/factory'

const CreateSectionModal = () => {
  const [data, setData] = useState<ICreateSection>(defaultCreateSection)
  const {showCreateSectionModal, closeCreateSectionModal, openCreateAssetModal} = useCreateModals()

  const steps: Step<ICreateSection>[] = [
    {
      title: 'Name',
      description: 'Name Your Section',
      component: Step1,
      validation: (data) => data.name === undefined || data.production_line === undefined,
    },
  ]

  return (
    <StepModalWrapper<ICreateSection, Section>
      id='hs_create_section'
      objectName='Section'
      show={showCreateSectionModal}
      close={closeCreateSectionModal}
      steps={steps}
      data={data}
      defaultData={defaultCreateSection}
      setData={setData}
      submitData={createSection}
      openNextModals={[openCreateAssetModal]}
      nextObjectNames={['Asset']}
    />
  )
}

export default CreateSectionModal
