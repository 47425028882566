import {Column} from 'react-table'
import {StringCell} from '../core/columns/StringCell'
import {SelectionCell} from '../core/columns/SelectionCell'
import {CustomHeader} from '../core/columns/CustomHeader'
import {SelectionHeader} from '../core/columns/SelectionHeader'
import {Placement} from '../../../core/_models'
import {NameImageCell} from '../core/columns/ImageStringCell'
import {CellClickContext} from '../core/columns/TableBody'
import AlertsCell from './AlertsCell'
import FactoryCell from '../core/columns/FactoryCell'
import AreaCell from '../core/columns/AreaCell'
import AssetCell from '../core/columns/AssetCell'
import ProductionLineCell from '../core/columns/ProductionLineCell'

const Columns: ReadonlyArray<Column<Placement>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} tableId='hs_placements_table' />,
    id: 'selection',
    Cell: ({...props}) => (
      <SelectionCell id={props.data[props.row.index]._id} tableId='hs_placements_table' />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => (
      <CellClickContext.Consumer>
        {({onCellClick}) => (
          <NameImageCell
            str={props.data[props.row.index].name}
            images={
              props.data[props.row.index].gallery
                ? [
                    props.data[props.row.index].gallery!.profile_image,
                    ...props.data[props.row.index].gallery!.images,
                  ]
                : undefined
            }
            index={props.row.index}
            entityId={props.data[props.row.index]._id}
            queryToInvalidate='placements'
            onClick={() => (onCellClick ? onCellClick(props.data[props.row.index]._id) : undefined)}
          />
        )}
      </CellClickContext.Consumer>
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Factory' className='min-w-125px' />,
    id: 'factory',
    Cell: ({...props}) => <FactoryCell factoryReference={props.data[props.row.index].factory} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Area' className='min-w-125px' />,
    id: 'area',
    Cell: ({...props}) => <AreaCell areaReference={props.data[props.row.index].area} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Asset/Production Line' className='min-w-125px' />
    ),
    id: 'asset',
    Cell: ({...props}) =>
      props.data[props.row.index].asset ? (
        <AssetCell assetReference={props.data[props.row.index].asset!} />
      ) : props.data[props.row.index].production_line ? (
        <ProductionLineCell
          productionLineReference={props.data[props.row.index].production_line!}
        />
      ) : (
        <StringCell str='' />
      ),
  },
  /*
  {
    Header: (props) => <CustomHeader tableProps={props} title='Alerts' />,
    id: 'alerts',
    Cell: ({...props}) => <AlertsCell placement={props.data[props.row.index]} />,
  },
  */
]

export {Columns}
