import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {KTSVG} from '../../../_metronic/helpers'
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {PasswordMeterComponent} from '../../../_metronic/assets/ts/components'
import {useAuth} from '../../modules/auth'
import {changePassword} from '../../core/requests/auth'

interface ChangePasswordFormValues {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

type Props = {
  show: boolean
  handleClose: () => void
}

const ChangePasswordModal = ({show, handleClose}: Props) => {
  const {logout} = useAuth()

  const [passwordChanged, setPasswordChanged] = useState(false)

  const formik = useFormik<ChangePasswordFormValues>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Required'),
      newPassword: Yup.string()
        .min(3, 'Must be at least 3 characters long')
        .max(50, 'Must be 50 characters or less')
        .notOneOf([Yup.ref('currentPassword'), null], 'New password must be different')
        .required('Required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values, {setStatus}) => {
      console.log('values', values)
      try {
        await changePassword(values)
        setPasswordChanged(true)
      } catch (error) {
        console.error('Failed to change password:', error)
        setStatus('Failed to change password')
      }
    },
  })

  const hideModal = () => {
    setPasswordChanged(false)
    formik.resetForm()
    handleClose()
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const successScreen = (
    <div className='text-center'>
      <div className='symbol symbol-50px symbol-circle mb-2 mx-auto'>
        <span className='symbol-label fs-1 fw-bold text-success'>✓</span>
      </div>
      <div className='fs-3 fw-bold text-gray-700 mb-5'>Password Change Successful</div>
      <div className='fs-6 fw-bold text-muted'>
        Your password has been successfully updated. For security reasons, you will be logged out of
        this session. Please use your new password to log in again.
      </div>
      <div className='text-center mt-10'>
        <button type='button' className='btn btn-lg btn-primary' onClick={logout}>
          Log In Again
        </button>
      </div>
    </div>
  )

  return (
    <>
      <Modal
        id='hs_change_password_modal'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog-centered'
        show={show}
        onHide={passwordChanged ? logout : hideModal}
        container={document.getElementById('root-modals')}
      >
        <div className='modal-header'>
          <h2>Change Password</h2>
          <div>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={passwordChanged ? logout : hideModal}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
        </div>

        <div className='modal-body m-5'>
          {passwordChanged && successScreen}
          {!passwordChanged && (
            <form noValidate onSubmit={formik.handleSubmit}>
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='fv-row mb-8'>
                <label className='form-label required'>Old Password</label>
                <input
                  type='password'
                  placeholder='Old Password'
                  {...formik.getFieldProps('currentPassword')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.currentPassword && formik.errors.currentPassword},
                    {
                      'is-valid': formik.touched.currentPassword && !formik.errors.currentPassword,
                    }
                  )}
                />
                {formik.touched.currentPassword && formik.errors.currentPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.currentPassword}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                  <div className='position-relative mb-3'>
                    <label className='form-label required'>New Password</label>
                    <input
                      type='password'
                      placeholder='Password'
                      autoComplete='off'
                      {...formik.getFieldProps('newPassword')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                        },
                        {
                          'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                        }
                      )}
                    />
                    {formik.touched.newPassword && formik.errors.newPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.newPassword}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className='d-flex align-items-center mb-3'
                    data-kt-password-meter-control='highlight'
                  >
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px' />
                  </div>
                </div>

                <div className='text-muted'>
                  Use 8 or more characters with a mix of letters, numbers & symbols.
                </div>
              </div>

              <div className='fv-row mb-8'>
                <label className='form-label required'>Confirm Password</label>
                <input
                  type='password'
                  placeholder='Confirm Password'
                  {...formik.getFieldProps('confirmPassword')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                    },
                    {
                      'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                    }
                  )}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirmPassword}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='d-grid mb-10'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!formik.isSubmitting && <span className='indicator-label'>Sign Up</span>}
                  {formik.isSubmitting && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Changing Password...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    </span>
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </>
  )
}

export default ChangePasswordModal
