import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {HealthGraph} from '../../components/charts/HealthGraph'
import {WidgetProps} from './Widget'

const HealthWidget = ({widget, height}: WidgetProps) => {
  const type = widget.data_types?.includes('Simple') ? 'simple' : 'advanced'
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  return (
    <HealthGraph
      assetId={widget.entity}
      type={type}
      height={height}
      startTime={startTime}
      endTime={endTime}
      chartId={widget._id}
    />
  )
}

export default HealthWidget
