import React from 'react'
import LoadingWrapper from '../../../../components/LoadingWrapper'
import WorkOrderStatusBadge from '../../../../components/work-orders/WorkOrderStatusBadge'
import {useWorkOrdersPaginated} from '../../../../core/requests/maintenance'
import {rfc3339ToString, startTimeFromIso8601Duration} from '../../../../core/time-util'
import useWorkOrdersFilter from '../../../work-orders/hooks/useWorkOrdersFilter'
import ViewWorkOrderModal from '../../../work-orders/modal/ViewWorkOrderModal'
import {WidgetProps} from './Widget'

const WorkOrdersWidget = ({widget}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const {status, statusSelect, category, categorySelect, entities, entitiesSelect} =
    useWorkOrdersFilter({
      entities: widget.entities,
      category: widget.category,
      status: widget.status,
    })

  const [selectedWorkOrderId, setSelectedWorkOrderId] = React.useState<string | undefined>()
  const {
    items: workOrders,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useWorkOrdersPaginated({
    statuses: status ? [status] : undefined,
    category: category ? [category] : undefined,
    factory_entities: entities,
  })

  const filteredWorkOrders = workOrders?.filter((workOrder) => {
    const workOrderStartDate = new Date(workOrder.start_time || workOrder.planned_start_time)
    const startDate = startTime ? new Date(startTime) : undefined
    const endDate = endTime ? new Date(endTime) : undefined
    const isAfterStart = startDate ? workOrderStartDate.getTime() >= startDate.getTime() : true
    const isBeforeEnd = endDate ? workOrderStartDate.getTime() <= endDate.getTime() : true
    return isAfterStart && isBeforeEnd
  })

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - Math.ceil(e.currentTarget.scrollTop) ===
      e.currentTarget.clientHeight

    if (bottom && !isFetching && hasNextPage) {
      await fetchNextPage()
    }
  }

  return (
    <div className='d-flex flex-column align-items-center justify-content-center h-100'>
      <div className={`d-flex flex-row py-5`}>
        {statusSelect}
        {categorySelect}
        {entitiesSelect}
      </div>
      <div className={`overflow-scroll w-100 px-5`} onScroll={handleScroll}>
        {filteredWorkOrders?.map((workOrder) => (
          <div
            className={`d-flex flex-row justify-content-between border-bottom cursor-pointer py-1`}
            key={workOrder._id}
            onClick={() => setSelectedWorkOrderId(workOrder._id)}
          >
            <div className={`flex-grow-1`}>{workOrder.title}</div>
            <div className={`text-nowrap pe-5`}>
              {rfc3339ToString(workOrder.planned_start_time)}
            </div>
            <div className={`w-50px`}>
              <WorkOrderStatusBadge status={workOrder.status} />
            </div>
          </div>
        ))}
        {filteredWorkOrders?.length === 0 && (
          <div className={`d-flex justify-content-center`}>No work orders found</div>
        )}
      </div>
      {selectedWorkOrderId && (
        <ViewWorkOrderModal
          show={!!selectedWorkOrderId}
          workOrderId={selectedWorkOrderId}
          hide={() => setSelectedWorkOrderId(undefined)}
        />
      )}
    </div>
  )
}

export default WorkOrdersWidget
