import Select from 'react-select'
import {SensorType, sensorTypes} from '../../../../core/_models'

type SensorDataTypeSelectProps = {
  onChange: (dataType?: SensorType) => void
}

const SensorDataTypeSelect = ({onChange}: SensorDataTypeSelectProps) => (
  <Select
    placeholder={`Data Type`}
    onChange={(e) => onChange(e?.value)}
    options={
      sensorTypes.map((s) => ({
        value: s,
        label: s,
      })) || []
    }
    isClearable
    className={'me-3'}
  />
)

export default SensorDataTypeSelect
