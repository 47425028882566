import LoadingWrapper from '../../../components/LoadingWrapper'
import MultiSelectFieldSymbols from '../../../components/form-components/MultiSelectFieldSymbols'
import MultiTextField from '../../../components/form-components/MultiTextField'
import NumberField from '../../../components/form-components/NumberField'
import TextAreaField from '../../../components/form-components/TextAreaField'
import TextField from '../../../components/form-components/TextField'
import {useProfile, useUsers} from '../../../core/requests/auth'
import {StepProps} from './_models'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const {data: profile} = useProfile()
  const {data: users} = useUsers({
    organization: profile?.organization?._id,
    options: {
      enabled: !!profile?.organization,
    },
  })

  return (
    <div className='current' data-kt-stepper-element='content'>
      <LoadingWrapper loading={users === undefined}>
        <div className='w-100'>
          <div className='row justify-content-between'>
            <div className='col'>
              <TextField
                name='Name'
                data={data}
                updateData={updateData}
                dataField='title'
                hasError={hasError}
                required
              />
            </div>
            <div className='col-md-auto'>
              <MultiSelectFieldSymbols
                name='Assignees'
                optionNames={users?.items.map((user) => user.email) || []}
                optionValues={users?.items.map((user) => user.email) || []}
                data={data}
                updateData={updateData}
                dataField='assignees'
              />
            </div>
          </div>

          <TextAreaField
            name='Description'
            data={data}
            updateData={updateData}
            dataField='description'
            placeholder='Add a description'
          />

          <NumberField
            name='Expected Duration (minutes)'
            data={data}
            updateData={updateData}
            dataField='expected_duration'
            isInteger
            required
            hasError={hasError}
          />

          <MultiTextField
            name='Actions'
            data={data}
            updateData={updateData}
            dataField='actions'
            // disabled={data.is_editing}
            lastField
          />
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Step1
