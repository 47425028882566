import LoadingWrapper from '../../../components/LoadingWrapper'
import SelectField from '../../../components/form-components/SelectField'
import {
  Asset,
  EntityGroup,
  EntityGroupPretty,
  Gateway,
  Part,
  Placement,
  Sensor,
  entityGroups,
  entityGroupsPretty,
} from '../../../core/_models'
import {
  gatewayToString,
  partToString,
  placementToString,
  sensorToString,
} from '../../../core/name-util'
import {
  useAssets,
  useGateways,
  useParts,
  usePlacements,
  useProductionLines,
  useSections,
  useSensors,
} from '../../../core/requests/factory'
import {StepProps} from './_models'

const ENTITY_NAMES: EntityGroupPretty[] = entityGroupsPretty.filter(
  (entityGroup) => entityGroup !== 'Factory' && entityGroup !== 'Area'
)
const ENTITY_VALUES: EntityGroup[] = entityGroups.filter(
  (entityGroup) => entityGroup !== 'factory' && entityGroup !== 'area'
)

const Step2 = ({data, updateData, hasError}: StepProps) => {
  const {data: productionLines, isFetching: productionLinesFetching} = useProductionLines({
    options: {enabled: data.selected_entity_type === 'production_line'},
  })
  const {data: sections, isFetching: sectionsFetching} = useSections({
    options: {enabled: data.selected_entity_type === 'section'},
  })
  const {data: assets, isFetching: assetsFetching} = useAssets({
    options: {enabled: data.selected_entity_type === 'asset'},
  })
  const {data: parts, isFetching: partsFetching} = useParts({
    options: {enabled: data.selected_entity_type === 'part'},
  })
  const {data: placements, isFetching: placementsFetching} = usePlacements({
    options: {enabled: data.selected_entity_type === 'placement'},
  })
  const {data: sensors, isFetching: sensorsFetching} = useSensors({
    options: {enabled: data.selected_entity_type === 'sensor'},
  })
  const {data: gateways, isFetching: gatewaysFetching} = useGateways({
    options: {enabled: data.selected_entity_type === 'gateway'},
  })

  const entities =
    data.selected_entity_type === 'production_line'
      ? productionLines?.items
      : data.selected_entity_type === 'section'
      ? sections?.items
      : data.selected_entity_type === 'asset'
      ? assets?.items
      : data.selected_entity_type === 'part'
      ? parts?.items
      : data.selected_entity_type === 'placement'
      ? placements?.items
      : data.selected_entity_type === 'sensor'
      ? sensors?.items
      : data.selected_entity_type === 'gateway'
      ? gateways?.items
      : undefined

  const loading =
    productionLinesFetching ||
    sectionsFetching ||
    assetsFetching ||
    partsFetching ||
    placementsFetching ||
    sensorsFetching ||
    gatewaysFetching

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        <SelectField
          name='Entity Type'
          data={data}
          updateData={updateData}
          dataField='selected_entity_type'
          hasError={hasError}
          optionNames={ENTITY_NAMES}
          optionValues={ENTITY_VALUES}
          updatesOnChange={() => ({factory_entity: undefined})}
        />

        {data.selected_entity_type !== undefined && (
          <LoadingWrapper loading={loading}>
            <SelectField
              name='Entity'
              data={data}
              updateData={updateData}
              dataField='factory_entity'
              hasError={hasError}
              optionNames={
                entities?.map((entity) =>
                  data.selected_entity_type === 'part'
                    ? partToString(entity as Part)
                    : data.selected_entity_type === 'placement'
                    ? placementToString(entity as Placement)
                    : data.selected_entity_type === 'sensor'
                    ? sensorToString(entity as unknown as Sensor)
                    : data.selected_entity_type === 'gateway'
                    ? gatewayToString(entity as Gateway)
                    : (entity as Asset).name
                ) ?? []
              }
              optionValues={entities?.map((entity) => entity._id) ?? []}
            />
          </LoadingWrapper>
        )}
      </div>
    </div>
  )
}

export default Step2
