import {Asset} from '../../../../core/_models'
import {useEventsLog} from '../../../../core/requests/notification'
import Notification from './Notification'

type NotificationsProps = {
  asset: Asset
}

const Notifications = ({asset}: NotificationsProps) => {
  const {data: eventLogs} = useEventsLog({entities: [asset._id]})
  return (
    <div className='timeline mh-400px scroll-y'>
      {eventLogs?.items.map((e) => (
        <Notification eventLog={e} />
      ))}
    </div>
  )
}

export default Notifications
