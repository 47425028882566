import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {logoPath} from '../../core/branding'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-lg-row flex-column-fluid h-100'
      style={{
        backgroundColor: '#0b1642',
        backgroundImage: `url(${toAbsoluteUrl('/media/hexastate/auth-bg.png')})`,
      }}
    >
      <div className='d-flex flex-column flex-column-fluid flex-lg-row'>
        <div className='d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10'>
          <div className='d-flex flex-center flex-lg-start flex-column'>
            {/* <Link to='/' className='mb-7'> */}
            <img alt='Logo' width={700} src={toAbsoluteUrl(logoPath)} />
            {/* </Link> */}
          </div>
        </div>

        <div className='d-flex flex-center w-lg-50 p-10'>
          <div className='card rounded-3 w-md-550px'>
            <div className='card-body p-10 p-lg-20'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  // return (
  //   <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
  //     {/* begin::Body */}
  //     <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
  //       {/* begin::Form */}
  //       <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
  //         {/* begin::Wrapper */}
  //         <div className='w-lg-500px p-10'>
  //           <Outlet />
  //         </div>
  //         {/* end::Wrapper */}
  //       </div>
  //       {/* end::Form */}
  //     </div>
  //     {/* end::Body */}

  //     {/* begin::Aside */}
  //     <div
  //       className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
  //       style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
  //     >
  //       {/* begin::Content */}
  //       <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
  //         {/* begin::Logo */}
  // <Link to='/' className='mb-12'>
  //   <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-75px' />
  // </Link>
  //         {/* end::Logo */}

  //         {/* begin::Image */}
  //         <img
  //           className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
  //           src={toAbsoluteUrl('/media/misc/auth-screens.png')}
  //           alt=''
  //         />
  //         {/* end::Image */}

  //         {/* begin::Title */}
  //         <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
  //           Fast, Efficient and Productive
  //         </h1>
  //         {/* end::Title */}

  //         {/* begin::Text */}
  //         <div className='text-white fs-base text-center'>
  //           In this kind of post,{' '}
  //           <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
  //             the blogger
  //           </a>
  //           introduces a person they’ve interviewed <br /> and provides some background information
  //           about
  //           <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
  //             the interviewee
  //           </a>
  //           and their <br /> work following this is a transcript of the interview.
  //         </div>
  //         {/* end::Text */}
  //       </div>
  //       {/* end::Content */}
  //     </div>
  //     {/* end::Aside */}
  //   </div>
  // )
}

export {AuthLayout}
