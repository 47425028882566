import SelectField from '../../components/form-components/SelectField'
import TextField from '../../components/form-components/TextField'
import {partTypes, PartType} from '../../core/_models'
import {StepProps} from './_models'

const Step3 = ({data, updateData, hasError}: StepProps) => {
  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* Type */}
        <SelectField
          name='Type'
          optionNames={[...partTypes]}
          optionValues={[...partTypes]}
          data={data}
          updateData={updateData}
          dataField='type'
          required
          hasError={hasError}
        />

        {/* Manufacturer */}
        <TextField
          name='Manufacturer'
          data={data}
          updateData={updateData}
          dataField='manufacturer'
        />

        {/* Model Number */}
        <TextField
          name='Model Number'
          data={data}
          updateData={updateData}
          dataField='model_number'
          lastField
        />
      </div>
    </div>
  )
}

export default Step3
