import {useQueryClient} from 'react-query'
import {UpdateUserProps, getProfile, updateUser} from '../../core/requests/auth'
import {ContentProps, FormModalWrapper} from '../../components/FormModalWrapper'
import TextField from '../../components/form-components/TextField'
import {Profile} from '../../core/_models'
import {useAuth} from '../../modules/auth'

type Props = {
  user: Profile
  show: boolean
  handleClose: () => void
}

const EditUserModal = ({user, show, handleClose}: Props) => {
  const {setCurrentProfile} = useAuth()
  const queryClient = useQueryClient()

  const submit = async (data: UpdateUserProps) => {
    await updateUser(data)
    const profile = await getProfile()
    setCurrentProfile(profile)
    queryClient.invalidateQueries('profile')
  }

  return (
    <FormModalWrapper<UpdateUserProps>
      id='hs_edit_user_modal'
      title='Edit User'
      show={show}
      close={handleClose}
      defaultData={{phoneNumber: user.phone_number}}
      submit={submit}
      body={{content: Content}}
    />
  )
}

const Content = ({data, updateData, hasError}: ContentProps<UpdateUserProps>) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <TextField
          name='Phone Number'
          data={data}
          updateData={updateData}
          dataField='phoneNumber'
          lastField
        />
      </div>
    </div>
  )
}

export {EditUserModal}
