import {useIntl} from 'react-intl'
import SelectBoxes from '../../components/form-components/SelectBoxes'

import {
  useAreasPaginated,
  usePartsPaginated,
  useProductionLinesPaginated,
  useSectionsPaginated,
} from '../../core/requests/factory'
import {StepProps} from './_models'
import SelectField from '../../components/form-components/SelectField'
import DisabledWrapper from '../../components/DisabledWrapper'
import {partToString} from '../../core/name-util'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  const {
    items: areas,
    isLoading: areasLoading,
    isFetching: areasFetching,
    fetchNextPage: areasNextPage,
  } = useAreasPaginated()

  const {
    items: productionLines,
    isLoading: productionLinesLoading,
    isFetching: productionLinesFetching,
    fetchNextPage: productionLinesNextPage,
  } = useProductionLinesPaginated()

  const {
    items: sections,
    isLoading: sectionsLoading,
    isFetching: sectionsFetching,
    fetchNextPage: sectionsNextPage,
  } = useSectionsPaginated()

  const {
    items: parts,
    isLoading: partsLoading,
    isFetching: partsFetching,
    fetchNextPage: partsNextPage,
  } = usePartsPaginated()

  const loading = areasLoading || productionLinesLoading || sectionsLoading || partsLoading

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='mb-10'>
          <h2 className='fw-bold d-flex align-items-center text-dark'>Location Specification</h2>
          <div className='text-muted fw-semibold fs-6'>
            Choose the type and specific location within the factory for the new placement. Begin by
            selecting the entity type, then pick the exact entity from the filtered list.
          </div>
        </div>

        <SelectBoxes
          required
          optionNames={['Area', 'Production Line', 'Section', 'Part']}
          optionValues={['area', 'production_line', 'section', 'part']}
          data={data}
          dataField='locationChoice'
          updateData={updateData}
          updatesOnChange={{area: undefined, production_line: undefined, part: undefined}}
          colSpace={6}
          hasError={hasError}
        />

        <DisabledWrapper disabled={loading}>
          {data.locationChoice === 'area' && (
            <SelectField
              name='Area'
              optionNames={areas?.map((area) => area.name) || []}
              optionValues={areas?.map((area) => area._id) || []}
              data={data}
              updateData={updateData}
              dataField='area'
              lastField
              hasError={hasError}
              required
              tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.AREA'})}
              isLoading={areasFetching}
              gotoNextPage={areasNextPage}
            />
          )}

          {data.locationChoice === 'production_line' && (
            <SelectField
              name='Production Line'
              optionNames={productionLines?.map((productionLine) => productionLine.name) || []}
              optionValues={productionLines?.map((productionLine) => productionLine._id) || []}
              data={data}
              updateData={updateData}
              dataField='production_line'
              lastField
              hasError={hasError}
              required
              tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.PRODUCTION_LINE'})}
              isLoading={productionLinesFetching}
              gotoNextPage={productionLinesNextPage}
            />
          )}

          {data.locationChoice === 'section' && (
            <SelectField
              name='Section'
              optionNames={sections?.map((section) => section.name) || []}
              optionValues={sections?.map((section) => section._id) || []}
              data={data}
              updateData={updateData}
              dataField='section'
              lastField
              hasError={hasError}
              required
              tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.PART'})}
              isLoading={partsFetching}
              gotoNextPage={partsNextPage}
            />
          )}

          {data.locationChoice === 'part' && (
            <SelectField
              name='Part'
              optionNames={parts?.map((part) => partToString(part)) || []}
              optionValues={parts?.map((part) => part._id) || []}
              data={data}
              updateData={updateData}
              dataField='part'
              lastField
              hasError={hasError}
              required
              tooltip={intl.formatMessage({id: 'TOOLTIP.ENTITY_SELECTION.PART'})}
              isLoading={partsFetching}
              gotoNextPage={partsNextPage}
            />
          )}
        </DisabledWrapper>
      </div>
    </div>
  )
}

export default Step1
