const Beta = () => (
  <span
    className={`badge-outline badge position-absolute top-0 start-100 translate-middle rounded-pill bg-warning`}
  >
    <i className='fa-solid fa-flask text-white'></i>
    Beta
  </span>
)

export default Beta
