import {IWidget, defaultCreateWidget, defaultLayout} from '../../widgets/_models'

export type ITextWidget = IWidget & {}

export const defaultCreateTextWidget: ITextWidget = {
  ...defaultCreateWidget,
  type: 'Text',
  layout: {
    ...defaultLayout,
    w: 4,
    h: 7,
    min_w: 4,
    min_h: 7,
  },
}

export type StepProps = {
  data: ITextWidget
  updateData: (fieldsToUpdate: Partial<ITextWidget>) => void
  hasError: boolean
}
