import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive'
import {TimedValue, Timespan} from '../../../../core/_models'
import {showNoDataDisplay} from '../../../../core/chart-util'

export type ColumnData = {
  unit?: string
  data: TimedValue[]
  timespan?: Timespan
}

export function columnConfig(chartId: string, data: ColumnData) {
  const root = am5.Root.new(chartId)
  const myTheme = am5.Theme.new(root)
  myTheme.rule('AxisLabel', ['minor']).setAll({dy: 1})
  root.setThemes([am5themes_Animated.new(root), myTheme, am5themes_Responsive.new(root)])

  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'panX',
      wheelY: 'zoomX',
    })
  )

  const cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'zoomX',
    })
  )
  cursor.lineY.set('visible', false)

  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      maxDeviation: 0,
      baseInterval: {
        timeUnit: (data.timespan as am5.time.TimeUnit) || 'second',
        count: 1,
      },
      renderer: am5xy.AxisRendererX.new(root, {
        // minorGridEnabled: true,
        // minorLabelsEnabled: true,
      }),
      tooltip: am5.Tooltip.new(root, {}),
    })
  )
  // xAxis.set('minorDateFormats', {
  //   day: 'dd',
  //   month: 'MM',
  // })

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
    })
  )

  const series = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      name: 'Series',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'value',
      valueXField: 'time',
      tooltip: am5.Tooltip.new(root, {
        labelText: `{valueY} ${data.unit || ''}`,
      }),
    })
  )
  // series.columns.template.setAll({strokeOpacity: 0})

  series.data.setAll(data.data.map((d) => ({time: new Date(d.time).getTime(), value: d.value})))

  chart.set(
    'scrollbarX',
    am5.Scrollbar.new(root, {
      orientation: 'horizontal',
    })
  )

  if (!data.data.length) return showNoDataDisplay(chartId, root, chart)

  series.appear(1000)
  chart.appear(1000, 100)
  return root
}
