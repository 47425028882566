import {useQuery} from 'react-query'
import {graphql} from '../../../../../gql'
import useConditionClient from '../UseConditionClient'

const getAdvancedHealthHistoryWithVariablesQueryDocument = graphql(`
  query AdvancedHealthHistory($id: String!, $startTime: DateTime, $endTime: DateTime) {
    advancedHealthHistory(id: $id, startTime: $startTime, endTime: $endTime) {
      time
      value
    }
  }
`)

interface Props {
  id?: string | null
  startTime?: string | null
  endTime?: string | null
}

const useAdvancedHealthHistory = ({id = null, startTime = null, endTime = null}: Props = {}) => {
  const client = useConditionClient()
  return useQuery({
    queryKey: ['condition.AdvancedHealthHistory', id, startTime, endTime],
    queryFn: async () =>
      (
        await client.request(getAdvancedHealthHistoryWithVariablesQueryDocument, {
          id,
          startTime,
          endTime,
        })
      ).advancedHealthHistory,
    enabled: !!id,
  })
}

export default useAdvancedHealthHistory
