import React, {createContext, useContext, useEffect, useState} from 'react'
import GridLayout from 'react-grid-layout'
import {updateWidget} from '../core/requests/template'
import {IWidget} from '../pages/dashboard/dashboard-grid/widgets/_models'

const ROW_HEIGHT = 30
const MARGIN = 10

const REFRESH_INTERVAL = Number(process.env.REACT_APP_DASHBOARD_AUTO_REFRESH_INTERVAL as string)

interface DashboardGridContextType {
  activeDashboardId?: string
  setActiveDashboardId: React.Dispatch<React.SetStateAction<string | undefined>>
  layouts: GridLayout.Layout[]
  setLayouts: React.Dispatch<React.SetStateAction<GridLayout.Layout[]>>
  heights: number[]
  setHeights: React.Dispatch<React.SetStateAction<number[]>>
  handleGridResize: (newLayout: GridLayout.Layout[]) => void
  updateLayouts: (newLayout: GridLayout.Layout[]) => void
  handleChangeStart: (newLayout: GridLayout.Layout[]) => void
  widgetsLocked: boolean
  toggleWidgetsLocked: () => void
  refreshInterval?: number
  setRefreshInterval: React.Dispatch<React.SetStateAction<number | undefined>>
}

const DashboardGridContext = createContext<DashboardGridContextType>({
  activeDashboardId: undefined,
  setActiveDashboardId: () => {},
  layouts: [],
  setLayouts: () => {},
  heights: [],
  setHeights: () => {},
  handleGridResize: () => {},
  updateLayouts: () => {},
  handleChangeStart: () => {},
  widgetsLocked: true,
  toggleWidgetsLocked: () => {},
  refreshInterval: REFRESH_INTERVAL,
  setRefreshInterval: () => {},
})

const DashboardGridProvider = ({children}: {children: React.ReactNode}) => {
  const [activeDashboardId, setActiveDashboardId] = useState<string | undefined>(undefined)
  const [layouts, setLayouts] = useState<GridLayout.Layout[]>([])
  const [layoutsOnChangeStart, setLayoutsOnChangeStart] = useState<GridLayout.Layout[]>([])
  const [heights, setHeights] = useState<number[]>([])
  const [widgetsLocked, setWidgetsLocked] = useState<boolean>(true)
  const [refreshInterval, setRefreshInterval] = useState<number | undefined>(REFRESH_INTERVAL)

  const handleGridResize = (newLayout: GridLayout.Layout[]) => {
    const newHeights: number[] = newLayout.map(
      (layout) => ROW_HEIGHT * layout.h + MARGIN * (layout.h - 1)
    )
    setLayouts(newLayout)
    setHeights(newHeights)
  }

  const toggleWidgetsLocked = () => {
    const newLayouts = layouts.map((layout) => {
      return {...layout, isDraggable: widgetsLocked, isResizable: widgetsLocked}
    })
    setLayouts(newLayouts)

    setWidgetsLocked(!widgetsLocked)
  }

  const handleChangeStart = (newLayout: GridLayout.Layout[]) => {
    setLayoutsOnChangeStart(newLayout)
  }

  const updateLayouts = async (newLayout: GridLayout.Layout[]) => {
    const changedLayouts = newLayout.filter(
      (layout, index) =>
        layout.x !== layoutsOnChangeStart[index].x ||
        layout.y !== layoutsOnChangeStart[index].y ||
        layout.w !== layoutsOnChangeStart[index].w ||
        layout.h !== layoutsOnChangeStart[index].h
    )

    await Promise.all(
      changedLayouts.map(async (layout) => {
        const widgetId = layout.i
        const data: Partial<IWidget> = {
          layout: {i: layout.i, x: layout.x, y: layout.y, w: layout.w, h: layout.h},
        }
        await updateWidget(widgetId, data)
      })
    )
  }

  useEffect(() => {}, [refreshInterval])

  return (
    <DashboardGridContext.Provider
      value={{
        activeDashboardId,
        setActiveDashboardId,
        layouts,
        setLayouts,
        heights,
        setHeights,
        handleGridResize,
        handleChangeStart,
        updateLayouts,
        widgetsLocked,
        toggleWidgetsLocked,
        refreshInterval,
        setRefreshInterval,
      }}
    >
      {children}
    </DashboardGridContext.Provider>
  )
}

const useDashboardGrid = () => useContext(DashboardGridContext)

export {DashboardGridProvider, useDashboardGrid}
