import React, {useEffect, useRef} from 'react'
import * as am5 from '@amcharts/amcharts5'
import useAnomalyDetectionHistory from '../../../../core/graphql/condition/queries/AnomalyDetectionHistory'
import {anomalyChartConfig} from '../../core/_chartConfigs/anomalyChartConfig'
import {ChartLoader} from '../ChartLoader'

type AnomalyGraphProps = {
  assetId: string
  startTime?: string
  endTime?: string
  height: string
  chartId?: string
}

const AnomalyGraph = ({
  assetId,
  startTime,
  endTime,
  height,
  chartId = `anomaly-${assetId}`,
}: AnomalyGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)

  const {data: anomalyHistory = [], isLoading} = useAnomalyDetectionHistory({
    id: assetId,
    startTime,
    endTime,
  })

  const data = React.useMemo(
    () =>
      anomalyHistory.map((h) => ({
        time: h.time * 1000,
        value: h.value,
      })),
    [anomalyHistory]
  )

  useEffect(() => {
    if (isLoading) return

    chartRef.current = anomalyChartConfig(chartId, data)

    return () => {
      chartRef.current?.dispose()
    }
  }, [chartId, data, isLoading])

  return <ChartLoader loading={isLoading} chartId={chartId} height={height} />
}

export {AnomalyGraph}
