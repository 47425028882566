import {useQuery} from 'react-query'
import {graphql} from '../../../../../gql'
import useConditionClient from '../UseConditionClient'

const getChecklistWithVariablesQueryDocument = graphql(`
  query Checklist($id: ID!) {
    checklist(id: $id) {
      partType
      placement
      potentialFaults
      actions
    }
  }
`)

interface Props {
  id?: string | null
}

const useChecklist = ({id = null}: Props = {}) => {
  const client = useConditionClient()
  return useQuery({
    queryKey: ['condition.Checklist', id],
    queryFn: async () =>
      (
        await client.request(getChecklistWithVariablesQueryDocument, {
          id,
        })
      ).checklist,
    enabled: !!id,
  })
}

export default useChecklist
