import useModalState from '../../../../app/hooks/UseModalState'
import {KTSVG} from '../../../helpers'
import {MenuComponent} from '../../../assets/ts/components'
import {EventCategoriesModal} from '../../../../app/modals/event-categories/EventCategoriesModal'
import TagsModal from '../../../../app/modals/tags/TagsModal'
import AutomationsModal from '../../../../app/modals/automations/AutomationsModal'

const OrganizationToolbar = () => {
  const eventCategoriesModal = useModalState()
  const tagsModal = useModalState()
  const automationsModal = useModalState()

  const handleModalClose = () => {
    eventCategoriesModal.close()
    tagsModal.close()
    automationsModal.close()
    // Ensures menu is responsive on next click by reinitializing after closing modal
    setTimeout(() => MenuComponent.reinitialization(), 0)
  }

  const handleMenuItemClick = (action: () => void) => {
    // Prevents menu freeze by hiding dropdowns before performing action.
    MenuComponent.hideDropdowns(undefined)
    action()
  }

  const ActionsMenu = () => (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-4 w-250px fs-6'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <div className='menu-content text-muted pb-2 px-5 fs-7 text-uppercase'>Actions</div>
      </div>
      <div className='menu-item px-5'>
        <a
          className='menu-link px-5'
          onClick={() => handleMenuItemClick(eventCategoriesModal.open)}
        >
          Event Categories
        </a>
      </div>
      <div className='menu-item px-5'>
        <a className='menu-link px-5' onClick={() => handleMenuItemClick(tagsModal.open)}>
          Tags
        </a>
      </div>
      <div className='menu-item px-5'>
        <a className='menu-link px-5' onClick={() => handleMenuItemClick(automationsModal.open)}>
          Automations
        </a>
      </div>
    </div>
  )

  return (
    <>
      <a
        className='btn btn-primary ps-7'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2 me-0' />
      </a>
      <ActionsMenu />

      <EventCategoriesModal
        show={eventCategoriesModal.isOpen}
        open={eventCategoriesModal.open}
        hide={handleModalClose}
      />
      <TagsModal show={tagsModal.isOpen} open={tagsModal.open} hide={handleModalClose} />
      <AutomationsModal show={automationsModal.isOpen} handleClose={handleModalClose} />
    </>
  )
}

export {OrganizationToolbar}
