import usePagination, {UsePaginationProps} from '../../../../components/pagination/usePagination'
import {Organization, ProductionLine} from '../../../../core/_models'
import {UseSectionsProps} from '../../../../core/requests/factory'
import {useFilterState} from './useFilterState'

const useSectionsFilter = (props: UsePaginationProps = {}) => {
  const {activePage, setActivePage, pageSize} = usePagination({
    initialPage: props.initialPage,
    size: props.size,
  })

  const filterState = {
    organization: useFilterState<Organization>(),
    productionLine: useFilterState<ProductionLine>(),
  }

  const sectionFilterProps: UseSectionsProps = {
    organization: filterState.organization.value?._id,
    productionLine: filterState.productionLine.value?._id,
    page: activePage,
    size: pageSize,
    options: {
      keepPreviousData: true,
    },
  }
  return {
    filterState,
    filterCount: Object.values(filterState).filter((v) => v.value !== undefined).length,
    sectionFilterProps,
    activePage,
    setActivePage,
    pageSize,
  }
}

export default useSectionsFilter
