import {CSSProperties, ReactNode} from 'react'
import {PulseLoader} from 'react-spinners'

interface Props {
  loading: boolean
  children: ReactNode
  className?: string
}

const LoadingOverlayWrapper: React.FC<Props> = ({loading, children, className = ''}) => {
  return (
    <div className={`opacity-${loading ? 50 : 100} ${className}`}>
      {loading && (
        <div className='d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0 z-2 pe-none'>
          <PulseLoader color='#009ef7' />
        </div>
      )}
      <div style={{pointerEvents: loading ? 'none' : 'initial'}}>{children}</div>
    </div>
  )
}

export default LoadingOverlayWrapper
