import {useEffect, useState} from 'react'

interface Props<DataType extends Record<string, any>> {
  name: string
  required?: boolean
  tooltip?: string
  optionNames: readonly string[]
  optionValues: readonly string[] | readonly number[] | readonly any[]
  optionIcons?: {icon: string; color?: string}[]
  data: DataType
  updateData: (fieldsToUpdate: Partial<DataType>) => void
  dataField: Extract<keyof DataType, string>
  hasError?: boolean
  lastField?: boolean
  updatesOnChange?: (newValue?: any[]) => Partial<DataType>
  colSpace?: 1 | 2 | 3 | 4 | 6 | 12
}

const SelectFieldBoxesMultiple = <DataType extends Record<string, any>>({
  name,
  required = false,
  tooltip,
  optionNames,
  optionValues,
  optionIcons,
  data,
  updateData,
  dataField,
  hasError = false,
  lastField = false,
  updatesOnChange,
  colSpace,
}: Props<DataType>) => {
  const [selected, setSelected] = useState<string[]>(
    data[dataField] ? data[dataField].map((value: string) => value) : []
  )

  // update selected when data changes
  useEffect(() => {
    setSelected(data[dataField] ? data[dataField].map((value: string) => value) : [])
  }, [data[dataField]])

  const onSelected = (value: string) => {
    const newSelected = [...selected]
    const selectedIndex = newSelected.indexOf(value)
    if (selectedIndex === -1) {
      newSelected.push(value)
    } else {
      newSelected.splice(selectedIndex, 1)
    }
    setSelected(newSelected)

    const newValues = newSelected.length ? newSelected : undefined
    updateData({
      [dataField]: newValues,
      ...(updatesOnChange !== undefined ? updatesOnChange(newValues) : {}),
    } as Partial<DataType>)
  }

  return (
    <div className={`fv-row ${!lastField ? 'mb-10' : ''}`}>
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={` ${required ? 'required' : ''}`}>{name}</span>
        {tooltip && (
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={tooltip}
          />
        )}
      </label>
      <div className='row'>
        {optionNames.map((optionName, index) => (
          <div className={`col${colSpace ? `-${colSpace}` : ''}`} key={index}>
            <label
              className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 mb-2 ${
                selected.includes(optionValues[index]) ? 'active' : ''
              }`}
            >
              <input
                type='radio'
                className='btn-check'
                onClick={() => onSelected(optionValues[index])}
                name={dataField}
              />
              <span className='flex fw-bold fs-5'>
                <div className='d-flex align-items-center justify-content-center'>
                  <div>{optionName}</div>
                  {optionIcons && (
                    <i
                      className={`fa-solid fs-2 ms-3 ${optionIcons[index].icon}`}
                      style={{
                        color: optionIcons[index].color || '#000000',
                      }}
                    />
                  )}
                </div>
              </span>
            </label>
          </div>
        ))}
      </div>

      {required && !data[dataField] && hasError && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{`${name} is required!`}</div>
        </div>
      )}
    </div>
  )
}

export default SelectFieldBoxesMultiple
